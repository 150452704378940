import React, { useEffect } from "react";
// import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { alpha, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";
import { Box, Button, CircularProgress, Input, InputAdornment, TextField, Tooltip } from "@material-ui/core";
import FilterImage from "../../../public/img/common/Vector.svg";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: "2px",
    border: "1px solid lightgray",
    // backgroundColor: '#EFF1F8',
    "&:hover": {
      backgroundColor: "white",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      marginTop: "5px",
      width: "100%",
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    height: "100%",
    // position: 'absolute',
    pointerEvents: "none",
    display: "flex",
    float: "left",
    paddingTop: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "8px 12px",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "40ch",
      "&:focus": {
        width: "40ch",
      },
    },
  },
}));

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function SearchBar(props) {
  const { placeholder, value, name, label } = props;
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState(props.searchInput);

  // useEffect(() => {
  //   setSelectedValue(props.searchInput);
    
  // }, [props.searchInput]);
 const onInputChange=(event,value)=>{
  
   //setIsStateChanged(true);
  //setSearchInput(event.target.value);
  //setSelectedValue(value.companyName);
  props.onInputChange(event, value);
  }
const handleChange =(event, val)=>
{
  setSelectedValue(val.companyName || val.buildingName);
  props.handleChange(event, val, "searchInput");
}


  return (
    <div  >
{props.isOwner == true ?
(
  <Autocomplete
  key={`searchInput:`}
  freeSolo
    size="small"
    underlineStyle={{ display: "none" }}
    multiple={false}
    id="tags-standard"
    searchText={ selectedValue  }
    // {props.searchInput && props.searchInput.length >0 ?
    // props.searchInput:""}
    loading={props.loading}
    onChange={(e, val) =>
     {  //setSelectedValue(val.companyName);
      //setSelectedValue(val.companyName);
      handleChange(e, val, "searchInput");}
    }
    onInputChange={(event, value) => {                     
      event && (event.type == 'change' || event.type == 'input' ) && value != "" && value != null && value.length > 2 ? onInputChange(event, value):null;

     }}
     disableClearable
    options={props.companyMasterDetails}
    limitTags={2}
    getOptionLabel={(option) => (option.companyName || option.buildingName)}
    renderInput={(params) => (
             <TextField
          {...params}
          variant="outlined"
          margin="normal"
          hiddenLabel
          //inputRef={selectedValue}
          // value={ props.searchInput != null && props.searchInput != undefined && 
          //   props.searchInput.length > 0 ? props.searchInput:null  }
         // value={searchInput}
          placeholder="Enter atlest 3 character to search and then select"
          
InputProps={{
...params.InputProps,
endAdornment: (
  <React.Fragment>
    {props.searchInput != null && props.searchInput != undefined && 
    props.searchInput.length > 0 ? <Button onClick={(event,value)=>{setSelectedValue(null); 
      props.reloadData()}}>Reset</Button>:null}
  { props.loading ? <CircularProgress  size={20} /> : null}
  {params.InputProps.endAdornment}
  </React.Fragment>
),
}}
        />
    
    )}
  />
) : (
  <div className={classes.search} >
      <TextField
        placeholder={placeholder}
        onKeyPress={(e) => e.key === 'Enter' && props.isOwner == true ? props.onChange(e,value,1) :null}

        onChange={(event,value) => props.onChange(event,event.target.value) }
        name={name}
       // value={searchInput}
        style={{
          // textOverflow: 'ellipsis !important',
        width: '100%',
        // overflow: 'hidden',
        // whiteSpace: 'nowrap'
      }}
       // className="search-box"
        // classes={{
        //   root: classes.inputRoot,
        //   input: classes.inputInput,
        // }}
        value={value}
        InputProps={{
          endAdornment: (
        props.isOwner && <InputAdornment position="start">
            <Tooltip title="Search Company">
              <img
                src={FilterImage}
                alt="logo"
                className={"stream-logo"}
                style={{ paddingLeft: "10px" , cursor: 'pointer'}}
                onKeyPress={(e) => e.key === 'Enter' && props.isOwner == true ? props.onChange(e,value,1) :null}
                // onKeyPress={(event) => props.isOwner == true ? props.onChange(event,value,1).bind(this):null}
                onClick={(event) => props.isOwner == true ? props.onChange(event,value,1) :null}
              />
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      </div>
      )}
      {/* <InputBase
              placeholder={placeholder}
              onChange={(event)=>props.onChange(event)}
              name={name}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={value}
              inputProps={{  startAdornment: <InputAdornment position="start">
              <img src={FilterImage} alt="logo" className={'stream-logo'} style={{paddingLeft: '10px'}}/>
            </InputAdornment>, }}
            /> */}
      {/* <div className={classes.searchIcon}>
             <img src={FilterImage} alt="logo" className={'stream-logo'} />
             
            </div> */}
    </div>
  );
}

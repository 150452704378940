import React from "react";
import "./index.less";
import { convertHexToRgb } from "../../../utils/generic";
import { DATA_FORMAT } from "../../../constants/constants";
import { getFormattedCellData } from "../../../utils/currency";

function getSign(dataFormat, value) {
	switch (dataFormat) {
		case DATA_FORMAT.DecimalCurrency:
		case DATA_FORMAT.Currency:
			return `${getFormattedCellData(
				value,
				DATA_FORMAT.DecimalCurrency,
				true
			)}`;
		case DATA_FORMAT.DecimalNumber: {
			return `${getFormattedCellData(value, DATA_FORMAT.DecimalNumber, true)}`;
		}
		case DATA_FORMAT.PerDecimal: {
			return `${getFormattedCellData(value, DATA_FORMAT.PerDecimal, true)}`;
		}
		case DATA_FORMAT.Month:
			return `${getFormattedCellData(value, DATA_FORMAT.Month, true)} Months`;
		case DATA_FORMAT.FormatSQF:
			return `${getFormattedCellData(
				value,
				DATA_FORMAT.NumberFormat,
				true
			)} SF`;
		case DATA_FORMAT.Factor: {
			return `${getFormattedCellData(value, DATA_FORMAT.Factor, true)}`;
		}
		default: {
			return value;
		}
	}
}

export default function BoxSummary({
	color,
	value,
	display = "",
	subTitle = "",
	dataFormat
}) {
	const detailBoxStyle = {
		backgroundColor: convertHexToRgb(color, 50)
	};

	return (
		<div className="info-box-container" style={detailBoxStyle}>
			<div className="display-text">{display}</div>
			<div className="single-section">
				<div className="single-value-text">{getSign(dataFormat, value)}</div>
				<div className="single-display-text">{subTitle}</div>
			</div>
		</div>
	);
}

/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import PersonIcon from '@material-ui/icons/Person';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { grey } from '@material-ui/core/colors';
import { Icon, InlineIcon } from '@iconify/react';
import ticketAlt from '@iconify/icons-fa-solid/ticket-alt';
import SettingIcon from '@material-ui/icons/Settings';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Receipt from '@material-ui/icons/Receipt';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import TableChartOutlined from '@material-ui/icons/TableChartOutlined'; 
import ListAltIcon from '@material-ui/icons/ListAlt';
import Person from '@material-ui/icons/Person';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import Transition from '@material-ui/icons/TransferWithinAStationOutlined';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CompareIcon from '@material-ui/icons/Compare';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import Reports from '@material-ui/icons/ListAlt';
import '../../../public/css/style.less';
import './SideNav.less';
import { NavLink, useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import ApartmentIcon from '@material-ui/icons/Apartment';
import BusinessIcon from '@material-ui/icons/Business';
import { ExpandLess, ExpandMore, MonetizationOnOutlined } from '@material-ui/icons';
import { IsSectionVisible, IsAdmin } from '../../../utils/helper';
import { SECTIONS } from '../../../constants/appConstants';
import DashBoardIcon from '../../../public/img/common/overview.svg';
import Articles from '../../../public/img/common/articles.svg';
import { Collapse } from '@material-ui/core';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
const drawerWidth = 192;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: '#064473',
    color: '#FFFFFF',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#064473',
    color: '#FFFFFF',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));
export default function MiniDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [selectedMenu, setSelected] = React.useState(0);
  const [setToInitialState, setInitialState] = useState(true);
  const history = useHistory();
  const layout = '';
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = (selectedIndex) => {
    if (selectedIndex !== undefined) {
      setSelected(selectedIndex);
    }
    setSelected(!setToInitialState);
    setOpen(false);
  };

  useEffect(() => {
    switch (history.location.pathname) {
      // case '/officedashboard':
      //   setSelected(0);
      //   break;
      case '/officeproperties':
        setSelected(1);
        break;
      case '/officecompanies':
        setSelected(2);
        break;
      case '/officecompanies1':
        setSelected(3);
        break;
      case '/officetenants':
        setSelected(4);
        break;
      case '/officeleasecomps':
        setSelected(5);
        break;
      case '/officesalescomps':
        setSelected(6);
        break;
      case '/officespecsuite':
        setSelected(7);
        break;
      case '/officereports':
        setSelected(8);
        break;
      case '/industrialproperties':
        setSelected(9);
        break;
      case '/industrialproperyowners':
        setSelected(10);
        break;
      case '/industrialleasecomps':
        setSelected(11);
        break;
      case '/industrialsalescomps':
        setSelected(12);
        break;
      case '/industrialtenants':
        setSelected(13);
        break;
      case '/industrialreports':
        setSelected(14);
        break;
      case '/industrialdashboard':
        setSelected(15);
        break;
      case '/officeconfiguration':
        setSelected(16);
        break;
      case '/industrialconfiguration':
        setSelected(17);
        break;
      case '/officespecsuitedashboard':
        setSelected(18);
        break;
      case '/aboutUs':
        setSelected(10);
        break;
      case '/industrialAboutUs':
        setSelected(20);
        break;
        case '/OfficeStatistics':
          setSelected(21);
          break;
          case '/CompSets':
            setSelected(22);
            break;
            case '/RcaLoan':
            setSelected(23);
            break;
    }
    setInitialState(!setToInitialState);
  }, [history.location.pathname]);

  const handleClick = () => {
    setOpenSubMenu(!openSubMenu);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen()}
          className={clsx({
            [classes.hide]: open,
          })}
        >
          <MenuIcon className="white-button" style={{ color: grey[50] }} />
        </IconButton>
        <span>
          <IconButton
            onClick={() => handleDrawerClose()}
            className={clsx({
              [classes.hide]: !open,
            })}
          >
            {theme.direction === 'rtl'
              ? (
                <ChevronRightIcon
                  className="white-button"
                  style={{ color: grey[50] }}
                />
              )
              : (
                <ChevronLeftIcon
                  className="white-button"
                  style={{ color: grey[50] }}
                />
              )}
          </IconButton>
        </span>
        <Divider />

        {
          props.group == 'Industrial' &&
          (<List>
            {/* <NavLink
              onClick={() => handleDrawerClose(15)}
              to={layout + '/industrialdashboard'}
            >
              <ListItem button selected={selectedMenu === 15}>
                <ListItemIcon>
                  <Tooltip title="Dashboard" placement="right">
                    <img src={DashBoardIcon} alt="dashboard" className="icons" style={{ color: grey[50] }} />

                  </Tooltip>
                </ListItemIcon>

                <ListItemText primary="Dashboard" style={{ color: grey[50] }} />
              </ListItem>
            </NavLink> */}

            <NavLink
              onClick={() => handleDrawerClose(9)}
              to={layout + '/industrialproperties'}
            >
              <ListItem button selected={selectedMenu === 9}>
                <ListItemIcon>
                  <Tooltip title="Properties" placement="right">
                    <LocationCityIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText primary="Properties" style={{ color: grey[50] }} />
              </ListItem>
            </NavLink>
            <NavLink
              onClick={() => handleDrawerClose(10)}
              to={{
                pathname: layout + '/industrialpropertyowners',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 10}>
                <ListItemIcon>
                  <Tooltip title="Companies" placement="right">
                    <AccountBalanceIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Companies"
                  style={{ color: grey[50] }}
                ></ListItemText>

              </ListItem>
            </NavLink>
            {/* <NavLink
              onClick={() => handleDrawerClose(13)}
              to={{
                pathname: layout + '/industrialtenants',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 13}>
                <ListItemIcon>
                  <Tooltip title="Tenants" placement="right">
                    <SupervisorAccountOutlinedIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Tenants"
                  style={{ color: grey[50] }}
                ></ListItemText>

              </ListItem>
            </NavLink> */}
           <NavLink
              onClick={() => handleDrawerClose(11)}
              to={{
                pathname: layout + '/industrialleasecomps',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 11}>
                <ListItemIcon>
                  <Tooltip title="Lease Comps" placement="right">
                    <CompareIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Lease Comps"
                  style={{ color: grey[50] }}
                ></ListItemText>

              </ListItem>
            </NavLink>

            <NavLink
              onClick={() => handleDrawerClose(12)}
              to={{
                pathname: layout + '/industrialsalescomps',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 12}>
                <ListItemIcon>
                  <Tooltip title="Sales Comps" placement="right">
                    <MonetizationOnOutlined style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Sales Comps"
                  style={{ color: grey[50] }}
                ></ListItemText>

              </ListItem>
            </NavLink> 

            {/* <NavLink
              onClick={() => handleDrawerClose(14)}
              to={{
                pathname: layout + '/industrialreports',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 14}>
                <ListItemIcon>
                  <Tooltip title="Stats & Reports" placement="right">
                    <AssessmentOutlinedIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Stats & Reports"
                  style={{ color: grey[50] }}
                ></ListItemText>

              </ListItem>
            </NavLink> */}
            {IsSectionVisible(SECTIONS.Ind_Configuration) && IsAdmin() &&
              <NavLink
                onClick={() => handleDrawerClose(16)}
                to={layout + '/industrialconfiguration'}
              >
                {' '}
                <ListItem button selected={selectedMenu === 17}>
                  <ListItemIcon>
                    <Tooltip title="Configuration Settings" placement="right">
                      <SettingIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Configuration"
                    style={{ color: grey[50] }}
                  ></ListItemText>
                  {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
              </NavLink>
            }
            <NavLink
                onClick={() => handleDrawerClose(20)}
                to={layout + '/industrialAboutUs'}
              >
                {' '}
                <ListItem button selected={selectedMenu === 20}>
                  <ListItemIcon>
                    <Tooltip title="About Us" placement="right">
                      <InfoIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="About Us"
                    style={{ color: grey[50] }}
                  ></ListItemText>
                  {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
              </NavLink>
          </List>
          )
        }
        {
          props.group == 'Office' &&
          (
            <List>
              {/* <NavLink
                onClick={() => handleDrawerClose(0)}
                to={layout + '/officedashboard'}
              >
                <ListItem button selected={selectedMenu === 0}>
                  <ListItemIcon>
                    <Tooltip title="Dashboard" placement="right">
                      <img src={DashBoardIcon} alt="dashboard" className="icons" style={{ color: grey[50] }} />

                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText primary="Dashboard" style={{ color: grey[50] }} />
                </ListItem>
              </NavLink>             */}

              <NavLink
                onClick={() => handleDrawerClose(1)}
                to={{
                  pathname: layout + '/officeproperties',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 1}>
                  <ListItemIcon>
                    <Tooltip title="Properties" placement="right">
                      <LocationCityIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Properties"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>

              <NavLink
                onClick={() => handleDrawerClose(2)}
                to={{
                  pathname: layout + '/officecompanies',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 2}>
                  <ListItemIcon>
                    <Tooltip title="Companies" placement="right">
                      <AccountBalanceIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Companies"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>
 <NavLink
                onClick={() => handleDrawerClose(22)}
                to={layout + '/CompSets'}
              >
                <ListItem button selected={selectedMenu === 22}>
                  <ListItemIcon>
                    <Tooltip title="Comp Sets" placement="right">                     
                      <TableChartOutlined style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText primary="Comp Sets" style={{ color: grey[50] }} />
                </ListItem>
              </NavLink>

              <NavLink
                onClick={() => handleDrawerClose(23)}
                to={layout + '/RcaLoan'}
              >
                <ListItem button selected={selectedMenu === 23}>
                  <ListItemIcon>
                    <Tooltip title="Building Loan" placement="right">                     
                      <Receipt style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText primary="Building Loan" style={{ color: grey[50] }} />
                </ListItem>
              </NavLink>

              {/* <NavLink
              onClick={() => handleDrawerClose(2)}
              to={{
                pathname: layout + '/companies',
                navProps: {
                  reloadPage: true,
                },
              }}
            >
              {' '}
              <ListItem button selected={selectedMenu === 3}>
                <ListItemIcon>
                  <Tooltip title="Companies" placement="right">
                    <BusinessIcon style={{ color: grey[50] }} />
                  </Tooltip>
                </ListItemIcon>

                <ListItemText
                  primary="Companies"
                  style={{ color: grey[50] }}
                ></ListItemText>
              
              </ListItem>
            </NavLink> */}

              {/* <NavLink
                onClick={() => handleDrawerClose(3)}
                to={{
                  pathname: layout + '/officetenants',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 4}>
                  <ListItemIcon>
                    <Tooltip title="Tenants" placement="right">
                      <SupervisorAccountOutlinedIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Tenants"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink> */}

              <NavLink
                onClick={() => handleDrawerClose(4)}
                to={{
                  pathname: layout + '/officeleasecomps',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 5}>
                  <ListItemIcon>
                    <Tooltip title="Lease Comps" placement="right">
                      <CompareIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Lease Comps"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>

              <NavLink
                onClick={() => handleDrawerClose(5)}
                to={{
                  pathname: layout + '/officesalescomps',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 6}>
                  <ListItemIcon>
                    <Tooltip title="Sales Comps" placement="right">
                      <MonetizationOnOutlined style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Sales Comps"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>

              {/* <NavLink
                onClick={() => handleDrawerClose(6)}
                to={{
                  pathname: layout + '/officespecsuite',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '} */}
              {/* {IsSectionVisible(SECTIONS.Spec_Suite, SECTIONS.Spec_Suite_Listing) &&
                <ListItem button onClick={handleClick}>

                  <ListItemIcon>
                    <Tooltip title="Spec Suite" placement="right">
                      <UnfoldMoreIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Spec Suite"
                    style={{ color: grey[50] }}
                  ></ListItemText>
                  {openSubMenu ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              }
              {IsSectionVisible(SECTIONS.Spec_Suite, SECTIONS.Spec_Suite_Listing) &&
                <Collapse in={openSubMenu} style={{ padding: '10px' }} timeout="auto" unmountOnExit>
                  <NavLink
                    onClick={() => handleDrawerClose(7)}
                    to={{
                      pathname: layout + '/officespecsuite',
                      navProps: {
                        reloadPage: true,
                      },
                    }}
                  >
                    <ListItem button selected={selectedMenu === 7}>
                      <ListItemIcon>
                        <Tooltip title="Spec Suite" placement="right">
                          <ListAltIcon style={{ color: grey[50] }} />                          
                        </Tooltip>
                      </ListItemIcon>

                      <ListItemText
                        primary="Spec Suite"
                        style={{ color: grey[50] }}
                      ></ListItemText>
                    </ListItem>
                  </NavLink>
                  <NavLink
                    onClick={() => handleDrawerClose(18)}
                    to={{
                      pathname: layout + '/officespecsuitedashboard',
                      navProps: {
                        reloadPage: true,
                      },
                    }}
                  >
                    <ListItem button selected={selectedMenu === 18}>
                      <ListItemIcon>
                        <Tooltip title="Spec Suite Dashboard" placement="right">
                          <DashboardOutlinedIcon style={{ color: grey[50] }} />
                        </Tooltip>
                      </ListItemIcon>

                      <ListItemText
                        primary="Dashboard"
                        style={{ color: grey[50] }}
                      ></ListItemText>

                    </ListItem>
                  </NavLink>
                </Collapse>
              } */}
              {/* </NavLink> */}
              {/*<ListItem button selected={selectedMenu === 7}>
                  <ListItemIcon>
                    <Tooltip title="SpecSuite" placement="right">
                      <Icon icon={ticketAlt} className="icons" style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="SpecSuite"
                    style={{ color: grey[50] }}
                  ></ListItemText>

<ListItemIcon>
                    <Tooltip title="SpecSuite Dashboard" placement="right">
                      <Icon icon={ticketAlt} className="icons" style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>*/}

              {/*<ListItemText
                    primary="SpecSuite Dashboard"
                    style={{ color: grey[50] }}
                  ></ListItemText>
                </ListItem>
				
              </NavLink>*/}

              {/*<NavLink
                onClick={() => handleDrawerClose(7)}
                to={{
                  pathname: layout + '/officespecsuitedashboard',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 18}>
                  <ListItemIcon>
                    <Tooltip title="SpecSuite Dashboard" placement="right">
                      <Icon icon={ticketAlt} className="icons" style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="SpecSuite Dashboard"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>*/}

              <NavLink
                onClick={() => handleDrawerClose(8)}
                to={{
                  pathname: layout + '/officereports',
                  navProps: {
                    reloadPage: true,
                  },
                }}
              >
                {' '}
                <ListItem button selected={selectedMenu === 8}>
                  <ListItemIcon>
                    <Tooltip title="Stats & Reports" placement="right">
                      <AssessmentOutlinedIcon style={{ color: grey[50] }} />
                      {/* <img src={Articles} alt="reports" className="icons"  /> */}

                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="Stats & Reports"
                    style={{ color: grey[50] }}
                  ></ListItemText>

                </ListItem>
              </NavLink>
              {IsAdmin() && IsSectionVisible(SECTIONS.Configuration) &&
                <NavLink
                  onClick={() => handleDrawerClose(16)}
                  to={layout + '/officeconfiguration'}
                >
                  {' '}
                  <ListItem button selected={selectedMenu === 16}>
                    <ListItemIcon>
                      <Tooltip title="Configuration Settings" placement="right">
                        <SettingIcon style={{ color: grey[50] }} />
                      </Tooltip>
                    </ListItemIcon>

                    <ListItemText
                      primary="Configuration"
                      style={{ color: grey[50] }}
                    ></ListItemText>
                    {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                  </ListItem>
                </NavLink>



              }

              <NavLink
                onClick={() => handleDrawerClose(10)}
                to={layout + '/aboutUs'}
              >
                {' '}
                <ListItem button selected={selectedMenu === 10}>
                  <ListItemIcon>
                    <Tooltip title="About Us" placement="right">
                      <InfoIcon style={{ color: grey[50] }} />
                    </Tooltip>
                  </ListItemIcon>

                  <ListItemText
                    primary="About Us"
                    style={{ color: grey[50] }}
                  ></ListItemText>
                  {/* {open ? <ExpandLess /> : <ExpandMore />} */}
                </ListItem>
              </NavLink>
            </List>
          )}

      </Drawer>
    </div>
  );
}

import React from "react";
import "../../public/css/style.less";
import {
  apiBatchRequest,
  apiBatchRequestWithoutLoader,
  apiPost,
  apiPut,
  apiRequest,
  apiRequestNoLoadder,
  CancelRequest,
  RenewCancelToken,
} from "../../utils/api_service";
import ReportButtons from "../../utils/ReportButtons";
import { API_URL } from "../../constants/api";
import PageFilter from "../common/PageFilter";
import { controlType } from "../../constants/common";
import PageHeader from "../common/PageHeader";
import { Tooltip, Button, Checkbox, FormControl, FormLabel, FormControlLabel, ownerWindow, ownerDocument, FormGroup } from "@material-ui/core";
import GridTable from "../common/Table/Table";
import {
  applyFilterOnData,
  generatePDFFromHtml,
  //GetUserMarketAccess,
  IsSectionVisible,
  UpdateColumnWithConfiguration,
} from "../../utils/helper";
import EditBuildingOwners from "./EditBuildingOwners";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../common/AutoSearch/SearchBar";
import ViewHistoryData from "../common/ViewHistoryData/ViewHistoryData";
import SettingScreenDialog from "../common/SettingScreenDialog/SettingScreenDialog";
import { ShowToast, toastType } from "../../utils/toast-service";
import commonLabels from "../../constants/messageConstants";
import { downloadExcelReportByAPI } from "../../utils/generic";
import { SECTIONS } from "../../constants/appConstants";

const uploadColumns = [
  {
    key: "companyName",
    name: "Company Name",
  },
  {
    key: "add1",
    name: "Address",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "subMarket",
    name: "Sub Market",
  },
  {
    key: "productType",
    name: "Product Type",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export default class BuildingOwners extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectedData:[],
      selectedMarketId: this.props.globalMarketId,
      reloadTable: false,
      companyData: [],
      configuration: [],
      companyMasterDetails: [],
      ownerTypeData: [],
      getIndustrySector: [],
      selectedEditRowId: undefined,
      detailView: false,
      ViewTitle: undefined,
      actionType: undefined,
      initialSummaryData: [],
      getSubMarketMaster: [],
      getProductTypeMaster: [],
      getMarketMaster: [],
      responseCompanyData: [],
      openFilter: false,
     // searchInput: "",
      filteredData: [],
      lstActiveFilters: [],
      subMarketData: [],
      subMarketFilteredData: [],
      isInAddMode: false,
      filterSelectedData: [],
      userConfiguration: [],
      openSlidingPanel: false,
      dbColumnList: [],
      rowsPerPage: 20,
      listOfColumns: [],
      enableFilterButton: false,
      owner:true,
      tenant:false,
      valueOfCompanyRole:[],
      companyRoleId:null,  
    };
    this.baseState = this.state;
  }
  onRestoreToDefault = (config) => {
    let param = config;
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: { restoreDefault: 1 },
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingRestored, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(
            commonLabels.settingNotRestored,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };

  onSettingChange = (config, pageSize) => {
    let param = [];
    if (config.length > 0) {
      param = config.filter((m) => {
        if (pageSize != 0) m.rowPerPage = pageSize;
        return m;
      });
    } else {
      param = this.state.userConfiguration.filter((m) => {
        if (m.dataFormat == "MultiText") {
          {
            m.minTag = 1;
            m.tagCount = true;
          }
          if (m.dataFormat == "Number") {
            m.headcellClass = "headcell-number-text";
          }
          m.tagCount = true;
        }
        if (m.sectionId == 1 && m.isVisible == true && m.key == "companyName") {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          if (pageSize != 0) m.rowPerPage = pageSize;
          //pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
      });
    }
    // this.setState(
    //   {
    //     selectedConfigData: config,
    // 	openSlidingPanel:false,
    //      },
    // );
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: {},
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId(this.state.selectedMarketId);
          ShowToast(commonLabels.settingUpdated, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(commonLabels.settingNotUpdated, toastType.error, 5000, 500);
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };
  getUserConfigurationBySectionId = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 1 },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((userConfiguration) => {
        this._asyncRequest = null;
        if (userConfiguration.userConfiguration.success == true) {
          this.setState({
            userConfiguration:
              camelcaseKeys(userConfiguration.userConfiguration.data).sort(
                (a, b) => (a.attributeSortOrder > b.attributeSortOrder ? 1 : -1)
              ) || [],
            rowsPerPage: userConfiguration.userConfiguration.data[2].rowPerPage,
            //reloadRequired:true,
            reloadTable: !this.state.reloadTable,
            listOfColumns: userConfiguration.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
          });
          this.forceUpdate();
          //this.child.setRowsPerPage(this.state.rowsPerPage);
          //this.childRef.current.handleChangeRowsPerPage(this.state.rowsPerPage);
        } else {
          ShowToast(
            userConfiguration.userConfiguration.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  onCompanyLoad=(companyRoleId,defaultMode=1,searchText=null)=>{  
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.BuildingOwners.buildingOwnersSummaryData,
          key: "companyData",
          params: { marketId: this.state.selectedMarketId,
            companyRoleId:companyRoleId,defaultMode:defaultMode ,searchText:searchText != null ? searchText : null },
        
        },
       
      ])
        .then((companyData) => {
          this._asyncRequest = null;
          if (companyData.companyData.success == true) {
           
            const tempVariable =
              camelcaseKeys(companyData.companyData.data) || [];
            this.setState(
              {
                companyData: tempVariable,
                responseCompanyData: _.cloneDeep(tempVariable),
                initialSummaryData: tempVariable,               
                configuration:
                  camelcaseKeys(companyData.companyData.configuration) || [],
                  companyRoleId:companyRoleId,
                },
              () => {
                this.forceUpdate();
              }
            );
         
          } else {
            ShowToast(
              companyData.companyData.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
   // }
  }
  componentDidMount() {
    //const listOfMarketId=GetUserMarketAccess();
    //let lstMarketId="";
    //const reducedArray = listOfMarketId.reduce((x,) => `${x}${x.marketId}|` ,'')

    // listOfMarketId.forEach((m)=>{
    // 	lst
    // 	lstMarketId.join('|')m.marketId
    // });
    //console.log('listOfMarketId',reducedArray);
    RenewCancelToken();
    this.getRequiredData(this.state.selectedMarketId);
    //this.getRequiredDataWithoutLoader();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }
  componentWillUnmount() {
    CancelRequest();
  }

  // apiRequestWithoutLoadderForMasters()
  // {
  //   apiRequestNoLoadder(
  // 	{
  // 	  url: API_URL.Master.getMarketMaster,
  // 	  key: "getMarketMaster",
  // 	  params: { businessLineId: 1 },
  // 	  fromCache:false,
  // 	}
  //   )
  // 	.then((getMarketMaster) => {
  // 	  this._asyncRequest = null;
  // 	  if (getMarketMaster.success == true) {
  // 		this.setState({
  // 		  getMarketMaster:
  // 			camelcaseKeys(getMarketMaster.data) || [],

  // 		});
  // 		this.forceUpdate();

  // 	  } else {
  // 		ShowToast(
  // 		 'Error',
  // 		  toastType.ERROR,
  // 		  5000,
  // 		  500,
  // 		  "Server error"
  // 		);
  // 	  }
  // 	})

  // 	apiRequestNoLoadder(

  // 	  {
  // 		url: API_URL.Master.getSubMarketMaster,
  // 		  key: "getSubMarketMaster",
  // 		   params: { businessLineId: 1 },
  // 		fromCache:false,
  // 	  }
  // 	)
  // 	  .then((getSubMarketMaster) => {
  // 		this._asyncRequest = null;
  // 		if (getSubMarketMaster.success == true) {
  // 		  this.setState({
  // 			getSubMarketMaster:
  // 			  camelcaseKeys(getSubMarketMaster.data) || [],
  //  subMarketData:
  // 			camelcaseKeys(getSubMarketMaster.data) || [],

  // 		  });
  // 		  this.forceUpdate();

  // 		} else {
  // 		  ShowToast(
  // 		   'Error',
  // 			toastType.ERROR,
  // 			5000,
  // 			500,
  // 			"Server error"
  // 		  );
  // 		}
  // 	  })

  // 	  apiRequestNoLoadder(

  // 		{
  // 			url: API_URL.Master.buildingOwnerMasterDetails,
  // 			key: "companyMasterDetails",
  // 			params: { businessLineId: 1 },
  // 		  fromCache:false,
  // 		}
  // 	  )
  // 		.then((companyMasterDetails) => {
  // 		  this._asyncRequest = null;
  // 		  if (companyMasterDetails.success == true) {
  // 			this.setState({
  // 				companyMasterDetails:
  // 				camelcaseKeys(companyMasterDetails.data) || [],
  // 			});
  // 			this.forceUpdate();

  // 		  } else {
  // 			ShowToast(
  // 			 'Error',
  // 			  toastType.ERROR,
  // 			  5000,
  // 			  500,
  // 			  "Server error"
  // 			);
  // 		  }
  // 		})

  // 		apiRequestNoLoadder(

  // 		  {
  // 			url: API_URL.Master.ownerTypeData,
  // 			key: "ownerTypeData",
  // 			params: { businessLineId: 1 },
  // 			fromCache:false,
  // 		  }
  // 		)
  // 		  .then((ownerTypeData) => {
  // 			this._asyncRequest = null;
  // 			if (ownerTypeData.success == true) {
  // 			  this.setState({
  // 				ownerTypeData:
  // 				  camelcaseKeys(ownerTypeData.data) || [],
  // 			  });
  // 			  this.forceUpdate();

  // 			} else {
  // 			  ShowToast(
  // 			   'Error',
  // 				toastType.ERROR,
  // 				5000,
  // 				500,
  // 				"Server error"
  // 			  );
  // 			}
  // 		  })

  // 		  apiRequestNoLoadder(

  // 			{
  // 				url: API_URL.Master.getProductTypeMaster,
  // 				key: "getProductTypeMaster",
  // 			  fromCache:false,
  // 			}
  // 		  )
  // 			.then((getProductTypeMaster) => {
  // 			  this._asyncRequest = null;
  // 			  if (getProductTypeMaster.success == true) {
  // 				this.setState({
  // 					getProductTypeMaster:
  // 					camelcaseKeys(getProductTypeMaster.data) || [],
  // 				});
  // 				this.forceUpdate();

  // 			  } else {
  // 				ShowToast(
  // 				 'Error',
  // 				  toastType.ERROR,
  // 				  5000,
  // 				  500,
  // 				  "Server error"
  // 				);
  // 			  }
  // 			})
  // }
  reloadPropertyCompanyData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.buildingOwnerMasterDetails,
        key: "companyMasterDetails",
        params: { businessLineId: 1 },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 1, marketId: this.state.selectedMarketId },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ]).then((companyMasterDetails) => {
      this._asyncRequest = null;
      if (companyMasterDetails.companyMasterDetails.success == true) {
        this.setState({
          companyMasterDetails:
            camelcaseKeys(companyMasterDetails.companyMasterDetails.data) || [],
          getBuildingMaster:
            camelcaseKeys(companyMasterDetails.getBuildingMaster.data) || [],
        });
        this.forceUpdate();
      } else {
        ShowToast("Error", toastType.ERROR, 5000, 500, "Server error");
      }
    });
  };
  getRequiredDataWithoutLoader = (selectedMarketId, cache) => {
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequestWithoutLoader([
        // {
        // 	url: API_URL.Master.buildingOwnerMasterDetails,
        // 	key: "companyMasterDetails",
        // 	params: { businessLineId: 1 },
        // 	fromCache: true,
        // 	duration: 60,
        // 	clearCache:cache
        // },
        {
          url: API_URL.Master.ownerTypeData,
          key: "ownerTypeData",
          params: { businessLineId: 1 },
          fromCache: true,
          duration: 60,
        },
        // {
        // 	url: API_URL.Master.getIndustrySector,
        // 	key: "getIndustrySector",
        // 	params: { businessLineId: 1 },
        // 	fromCache: true,
        // 	duration: 60
        // },
        // {
        // 	url: API_URL.Master.getProductTypeMaster,
        // 	key: "getProductTypeMaster",
        // 	fromCache: true,
        // 	duration: 60
        // },
        // {
        // 	url: API_URL.Master.getMarketMaster,
        // 	key: "getMarketMaster",
        // 	params: { businessLineId: 1 ,marketId: selectedMarketId},
        // 	fromCache: true,
        // 	duration: 60
        // },
        // {
        // 	url: API_URL.Master.getSubMarketMaster,
        // 	key: "getSubMarketMaster",
        // 	params: { businessLineId: 1,marketId: selectedMarketId  },
        // 	fromCache: true,
        // 	duration: 60
        // },
      ])
        .then((companyData) => {
          this._asyncRequest = null;
          if (companyData.ownerTypeData.success == true) {
            this.setState({
              // companyMasterDetails:
              // 	camelcaseKeys(companyData.companyMasterDetails.data) || [],
              ownerTypeData:
                camelcaseKeys(companyData.ownerTypeData.data) || [],
              // getIndustrySector:
              // 	camelcaseKeys(companyData.getIndustrySector.data) || [],
              // getSubMarketMaster:
              // 	camelcaseKeys(companyData.getSubMarketMaster.data) || [],
              // subMarketData:
              // 	camelcaseKeys(companyData.getSubMarketMaster.data) || [],
              // getProductTypeMaster:
              // 	camelcaseKeys(companyData.getProductTypeMaster.data) || [],
              // getMarketMaster:
              // 	camelcaseKeys(companyData.getMarketMaster.data) || [],
              enableFilterButton: true,
            });
            this.forceUpdate();
          } else {
            ShowToast(
              companyData.companyMasterDetails.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  getRequiredData = (selectedMarketId) => {
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.BuildingOwners.buildingOwnersSummaryData,
          key: "companyData",
          params: { marketId: selectedMarketId ,companyRoleId:"1",defaultMode:1},
                 },       
        {
          url: API_URL.Master.GetUserConfigurations,
          key: "userConfiguration",
          params: { sectionId: 1 },
          fromCache: true,
          duration: 60,
        },
      ])
        .then((companyData) => {
          this._asyncRequest = null;
          if (companyData.companyData.success == true) {
            this.getRequiredDataWithoutLoader(
              this.state.selectedMarketId,
              false
            );
            const tempVariable =
              camelcaseKeys(companyData.companyData.data) || [];
              const selectedData=[];
              selectedData.push({
                key: 'companyRole',
                selected: [{ companyRole: "Owner",CompanyRoleId:1 }]
              });
            this.setState(
              {
                defaultSelectedData:selectedData,
                companyData: tempVariable,
                responseCompanyData: _.cloneDeep(tempVariable),
                initialSummaryData: tempVariable,               
                configuration:
                  camelcaseKeys(companyData.companyData.configuration) || [],                
                userConfiguration: camelcaseKeys(
                  companyData.userConfiguration.data != null
                    ? companyData.userConfiguration.data
                    : JSON.parse(
                        window.sessionStorage.getItem("userConfigurations")
                      )
                ),
                listOfColumns: companyData.userConfiguration.data.filter(
                  (m) => {
                    if (m.isVisible == true) return m.key;
                  }
                ),
                rowsPerPage: companyData.userConfiguration.data[2].rowPerPage,
              },
              () => {
                this.forceUpdate();
              }
            );           
          } else {
            ShowToast(
              companyData.companyData.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

 

  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
              //this.setState({ detailView: true, selectedEditRowId: cellInfo.CompanyId });
            }}
          >
            <span className="default-text span-link">
              {cellInfo.companyName ? cellInfo.companyName : "---"}
            </span>
          </a>
        </Tooltip>
        {/* <span>  {cellInfo.CompanyName}</span> */}
      </>
    );
  };

  getListOfSubMarket() {}

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  onInputChange = (filter, value) => {
    this.setState({
      loading:true
    });
    const params = {
      businessLineId: 1,
      searchText: value,
    };
    
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
           loading:false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
    //}
  };
  onFilterChange = (selectedData) => {
    if (selectedData.length > 0) {
      let data = [];
      data.push(selectedData);

      this.setState({
        filterSelectedData: data,
        //defaultSelectedData: data.length == 0 ? [] : this.state.defaultSelectedData,
      });      
    }
    selectedData.length== 0 ?  this.setState({      
      defaultSelectedData:  [],
    }): null;
    let companyRoleId=[];
    if(selectedData && selectedData.length == 0){
      companyRoleId.push('-1');
    }
    if (selectedData && selectedData.length > 0) {
      selectedData.forEach((ele) => {
        // if(ele && ele.selected && ele.selected.length == 0 && ele.key == "companyRole"){
        //   companyRoleId.push('-1');
        // }
        if (ele && ele.selected && ele.selected.length > 0) {
    	        if (ele.key == "companyRole") {
              
                ele.selected.forEach((element) => {

                companyRoleId.push(element.companyRoleId);
              });
              }
    			
    		}
    	});
    }
    if(companyRoleId.length >0)
    {
      this.onCompanyLoad(companyRoleId.join('|'),0);
    }
   
    if (selectedData.length > 0) {
     
      this.filterData(this.state.responseCompanyData, selectedData);
    } else {
      this.setState({
        getSubMarketMaster: this.state.subMarketData,
      });
      this.filterData(this.state.responseCompanyData, selectedData);
    }
  };

  filterData(companyData, selectedData) {
    const filteredData = applyFilterOnData([...companyData], selectedData);
    this.setState(
      {
        companyData: filteredData,
        selectedData: selectedData,
        reloadTable: !this.state.reloadTable,
      },
      () => {
        this.forceUpdate();
      }
    );
  }

 
  resetPage = () => {
    //if()
    let resp = this.state.responseCompanyData;
    this.setState({
      companyData: resp,
      lstActiveFilters: [],
      openFilter: false,
    });
  };
  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  onAddNewClick = () => {
    this.setState({
      isInAddMode: true,
      detailView: true,
      ViewTitle: "Add",
      actionType: "Add",
    });
  };
  onRowClick = (data) => {
    this.setState({
      detailView: true,
      selectedEditRowId: data.companyId,
      ViewTitle: "Edit",
      actionType: "Edit",
    });
  };

  onBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: undefined });
  };
resetAppliedFilter=()=>
{
  this.setState({ ["searchInput"]:null,defaultSelectedData:[],  filterSelectedData: null,
  });
  this.reloadData();
}
  reloadData = () => {
    let companyRoleId=this.state.companyRoleId == null && this.state.defaultSelectedData.length > 0
      ? "1" : this.state.companyRoleId;
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.BuildingOwners.buildingOwnersSummaryData,
        key: "companyData",
        params: { marketId: this.state.selectedMarketId,companyRoleId:companyRoleId ,defaultMode:0},
        // fromCache: true,
        // duration: 60,
        // clearCache:true
      },
    ])
      .then((companyData) => {
        this._asyncRequest = null;
        if (companyData.companyData.success == true) {
          this.setState({
            companyData: camelcaseKeys(companyData.companyData.data) || [],
            responseCompanyData: _.cloneDeep(
              camelcaseKeys(companyData.companyData.data) || []
            ),
            configuration:
              camelcaseKeys(companyData.companyData.configuration) || [],
            detailView: false,
            selectedEditRowId: undefined,
            companyMasterDetails:[],
          });
          this.forceUpdate();
        } else {
          ShowToast(
            companyData.companyData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
    //this.reloadPropertyCompanyData();
    //this.getRequiredDataWithoutLoader(this.state.selectedMarketId);
  };

  exportPDF = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.Company,
      "CompaniesExport.pdf",
      this.state.selectedData == undefined ? this.state.defaultSelectedData : this.state.selectedData,
      this.state.selectedMarketId,
      "application/pdf",
      "company"
    );
  };

  handleSearchChange = (e, value, name) => {
    this.setState({ [name]: value.companyName });
    let searchTest=value != null && value != undefined ? value.companyName :null
    this.handleChange(e,searchTest,1);
  };
  handleChange = (event,value,bit) => {
     this.setState({ defaultSelectedData:[],companyRoleId:null,  filterSelectedData: null},()=>{this.forceUpdate();});
     bit == 1 ? this.onCompanyLoad(null,0,value):null;
    //});
    if(value == '' || value == null || value == undefined)
    this.onCompanyLoad(null,0,value);

  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  downloadReport = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.Company,
      "CompaniesExport.xls",
      this.state.selectedData == undefined ? this.state.defaultSelectedData : this.state.selectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "company"
    );
  };

  resetFilters = () => {
    this.setState({
      filterSelectedData: null,
     // defaultSelectedData: null,
    });
  };
  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };
  handleCheck(val) {
    return this.state.listOfColumns.some((item) => item.key === val);
  }
  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };
 
  render() {
    const {
      loading,
      companyData,
      searchInput,
      getSubMarketMaster,
      companyMasterDetails,
      ownerTypeData,
      //getIndustrySector,
      getProductTypeMaster,
      getMarketMaster,
      openFilter,
      lstActiveFilters,
      userConfiguration,
      openSlidingPanel,
      settingConfig,
      listOfColumns,
      enableFilterButton,
    } = this.state;
    let pageSize = 0;
    let userConfig = userConfiguration.filter((m) => {
      if (m.sectionId == 1 && m.isVisible == true) {
        if (m.dataFormat == "MultiText") {
          {
            m.minTag = 1;
            m.tagCount = true;
          }
          if (m.dataFormat == "Number") {
            m.headcellClass = "headcell-number-text";
          }
          m.tagCount = true;
        }
        if (m.key == "companyName") {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
        return m;
      }
      //this.refs.gridTable.setRowsPerPage(pageSize);
    });
    userConfig.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );
    const dbColumnList = userConfiguration.filter((m) => {
      if (m.sectionId == 1 && m.attributeSortOrder != -1 && !m.renderComponent)
        return m; //
    });
    dbColumnList.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );

    companyData.forEach((item, index) => {
      item.serial = index + 1;
    });

    return (
      <>
        {this.state.detailView ? (
          <>
            <EditBuildingOwners
              globalMarketId={this.props.globalMarketId}
              selectedCompanyId={this.state.selectedEditRowId}
              onBackClick={this.onBackClick}
              getProductTypeMaster={getProductTypeMaster}
              ViewTitle={this.state.ViewTitle}
              actionType={this.state.actionType}
              isInAddMode={this.state.isInAddMode}
              reloadData={this.reloadData}
              getSubMarketMaster={getSubMarketMaster}
              // companyMasterDetails={companyMasterDetails}
              ownerTypeData={ownerTypeData}
              getMarketMaster={getMarketMaster}
              companySummaryData={this.state.initialSummaryData}
            />
          </>
        ) : (
          <>
            <div className="row no-print">
              <div className="col-md-4 col-lg-4">
                <PageHeader title="Companies"></PageHeader>
              </div>
              <div>
                {dbColumnList.length > 0 && (
                  <SettingScreenDialog
                    filterSetting={JSON.parse(
                      JSON.stringify([...dbColumnList])
                    )}
                    onRestoreToDefault={this.onRestoreToDefault}
                    onApplySetting={this.onSettingChange}
                    openSlidingPanel={openSlidingPanel}
                    handleClose={this.handleClose}
                  />
                )}
              </div>

              <div className="col-md-5 col-lg-5">
                <SearchBar
                reloadData={this.resetAppliedFilter}
                loading={loading}
                handleChange={this.handleSearchChange}
                onInputChange={this.onInputChange}
                searchInput={searchInput}
                companyMasterDetails={this.state.companyMasterDetails}
                isOwner={true}
                  size="large"
                  name="searchInput"
                  value={searchInput || ""}
                  onChange={this.handleChange}
                  label="Search"
                  placeholder="Click on icon to Search Company."
                ></SearchBar>
              </div>

              <div className="col-md-3 col-lg-3">
                <ReportButtons
                  resetPage={this.resetPage}
                  openFilter={this.state.openFilter}
                  enableFilter={this.enableFilter}
                  handleSubmit={[]}
                  handleClickOpen={this.handleClickOpen}
                  handlePrintClick={this.handlePrintClick}
                  exportPDF={this.exportPDF}
                  data={companyData}
                  columnWidths={columnWidths}
                  uploadColumns={uploadColumns}
                  fileName={"Companies.xlsx"}
                  downloadExcelReport={this.downloadReport}
                  enableFilterButton={this.state.enableFilterButton}
                  ParentSection={SECTIONS.Company}
                  ChildSectionForDownload={SECTIONS.Company_Download}
                  ChildSectionForPrint={SECTIONS.Company_Print}
                  ChildSectionForSettings={SECTIONS.Company_Settings}
                />
              </div>
            </div>

            {companyData && listOfColumns && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PageFilter
                    resetFilters={this.resetFilters}
                    onInputChange={this.onInputChange}
                    autoFilter={true}
                    openAppliedFilterPane={true}
                    filterSelectedData={this.state.filterSelectedData}
                    filterApplied={true}
                    filterAlwaysVisible={openFilter}
                    openFilter={true}
                    onFilterChanged={this.onFilterChange}
                      defaultSelectedData={[]}
                    filterConfig={[
                      // {
                      //   multiple: false,
                      //   type: controlType.Autofill,
                      //   minTag: 1,
                      //   source: companyMasterDetails,
                      //   key: "companyName",
                      //   placeholder: "",
                      //   title: "Company Name",
                      //   isVisible: this.handleCheck("companyName"),
                      // },
                     
                      // {
                      // 	multiple: true,
                      // 	type: controlType.DropDown,
                      // 	source: getMarketMaster,
                      // 	key: "market",
                      // 	placeholder: "",
                      // 	title: "Market",
                      // 	isVisible:this.handleCheck("market"),
                      // },
                      // {
                      //   multiple: true,
                      //   type: controlType.DropDown,
                      // 	source: [...new Map(getSubMarketMaster.map(item => [item["subMarket"], item])).values()],
                      // 	key: "subMarket",
                      // 	placeholder: "",
                      // 	title: "Sub Market",
                      // 	isVisible:this.handleCheck("subMarket"),
                      // },
                      // {
                      //   multiple: true,
                      //   type: controlType.DropDown,
                      //   source: getIndustrySector,
                      //   key: 'industryName',
                      //   placeholder: '',
                      //   title: 'Industry'
                      // },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: ownerTypeData,
                        key: "companyRole",
                        placeholder: "",
                        title: "Company Type",
                        isVisible: this.handleCheck("companyRole"),
                      },
                      // {
                      // 	multiple: true,
                      // 	type: controlType.DropDown,
                      // 	source: getProductTypeMaster,
                      // 	key: "productType",
                      // 	placeholder: "Stream Property Type",
                      // 	title: "Stream Property Type",
                      // 	isVisible:this.handleCheck("productType"),
                      // },
                    ]}
                    //defaultSelectedData={[]}
                    selectedData={this.state.defaultSelectedData}
                    gridColumn={4}
                  ></PageFilter>
                  {/* <Button className="btn-clear" onClick={this.resetPage}>
                          Clear All
                        </Button> */}
                </div>
              </div>
            )}

            {companyData && userConfig.length > 0 && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <GridTable
                    validationMessage={[]}
                    rowCount={
                      this.state.rowsPerPage > 0 ? this.state.rowsPerPage : 20
                    }
                    config={userConfig}
                    columns={UpdateColumnWithConfiguration(
                      userConfig,
                      this.state.configuration,
                      []
                    )}
                    selectedEditRowId={this.state.selectedEditRowId}
                    data={companyData}
                    renderActionColumn={this.renderActionColumn}
                    reload={this.state.reloadTable}
                    AddNewRecord={{
                      action: this.onAddNewClick,
                      title: "Add Company",
                      visible: IsSectionVisible(
                        SECTIONS.Company,
                        SECTIONS.Company_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                  ></GridTable>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

import * as actionType from './actionTypes';

const initialState = {
  cachedData: []
};

const reducer = (state = initialState, action) => {
  const cachedData = state.cachedData;
  switch (action.type) {
    case actionType.SET_CACHE_DATA:
      if (action.payload !== undefined) {
        cachedData.push(action.payload);
        return {
          ...state,
          cachedData: cachedData
        };
      }
      break;
    case actionType.GET_CACHE_DATA:
      return state.cachedData.find(x => x.key === action.payload);
    case actionType.ClEAR_CACHE_DATA:
      return {
        ...state,
        cachedData: []
      };
    case actionType.CLEAR_CACHE_DATA_BY_KEY:
      if (action.payload !== undefined) {
        return {
          ...state,
          cachedData: state.cachedData.filter(x => x.key !== action.payload)
        };
      }
      break;
    case actionType.ClEAR_MODULE_CACHE_DATA:
      if (action.payload !== undefined) {
        return {
          ...state,
          cachedData: state.cachedData.filter(x => x.module !== action.payload)
        };
      }
      break;
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import "./style.less";

export default function RadioGroup({
	radioArr = [],
	selected,
	onChangeRadio,
	groupName = "radio",
	
}) {
	return (
		<>
			{radioArr.map((radio, i) => {
				return (
					<label className="Radiocontainer " key={i}>
						{radio.label}
						<input
							type="radio"
							checked={selected === radio.key}
							name={groupName}
							onChange={() => onChangeRadio(radio.key)}
						/>
						<span className="checkmark" />
					</label>
				);
			})}
		</>
	);
}

import React from 'react';
import { toast, ToastType } from 'react-toastify';
import { MdError } from 'react-icons/md';
import { TiWarning } from 'react-icons/ti';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';

const iconSize = '1.5em';

function GetTostMsgComponent(message, title, toastType) {
  return (
    <div className="row">
      {toastType && (
        <div className="col-xs-1 no-padding">
          {toastType === ToastType.ERROR && <MdError size={iconSize} />}
          {toastType === ToastType.SUCCESS && (
            <IoMdCheckmarkCircle size={iconSize} />
          )}
          {toastType === ToastType.INFO && <FaInfoCircle size={iconSize} />}
          {toastType === ToastType.WARNING && <TiWarning size={iconSize} />}
          {toastType === ToastType.DEFAULT && <FaInfoCircle size={iconSize} />}
        </div>
      )}
      {title && <div className="col-xs-11 toast-title no-padding">{title}</div>}
      {toastType && title ? <div className="col-xs-2">&nbsp;</div> : ''}
      <div className="col-xs-11 toast-sub-message">{message}</div>
    </div>
  );
}

export function ShowToast(
  message,
  toastType,
  autoclose = 1000,
  messageId = 0,
  title = ''
) {
  if (message) {
    if (!toast.isActive(messageId)) {
      switch (toastType) {
        case ToastType.ERROR:
          toast.error(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId,
          });
          break;
        case ToastType.SUCCESS:
          toast.success(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId,
          });
          break;
        case ToastType.WARNING:
          toast.warn(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId,
          });
          break;
        case ToastType.INFO:
          toast.info(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId,
          });
          break;
        case ToastType.DEFAULT:
        default:
          toast(() => GetTostMsgComponent(message, title, toastType), {
            autoClose: autoclose,
            toastId: messageId,
          });
          break;
      }
    }
  }
}

export const toastType = ToastType;

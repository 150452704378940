import React from "react";
import "./style.less";
import { Report, Tile, Dashboard } from "powerbi-report-component";
//import { MdFullscreen, MdFullscreenExit } from "../../../constants/icons";
import Popup from "reactjs-popup";
// import jQuery from 'jquery';
// import models from 'powerbi-models';

import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { isObjectEquivalent } from "../../../utils/generic";
//import { deviceDetection } from "../../../constants/constants";
const { isMobile } = false;

const ColumnsNumber = {
  One: 1,
  Two: 2,
  Three: 3,
};

const LayoutShowcaseConsts = {
  margin: 15,
  minPageWidth: 270,
};

var LayoutShowcaseState = {
  columns: ColumnsNumber.Three,
  layoutVisuals: null,
  layoutReport: null,
  layoutPageName: null,
  //Reportmodels:models.VisualContainerDisplayMode
};

export default class PowerBIEmbedControl extends React.Component {
  constructor(props) {
    super(props);
    this.report = null;
    this.fullScreenReport = null;
    this.embedContainer = null;
    this.state = { fullScreenOn: false }; // to store the loaded report's object to perform operations like print, full screen etc..
  }

  handleReportLoad = (report) => {
    // will be called when report loads:
    // - scripts and data received from server, visuals are rendered on the browser
    // - flickering Power BI logo stops appearing but report is not fully ready to be consumed

    const isFullScreenOn = this.state.fullScreenOn;
    const container = isFullScreenOn
      ? this.fullScreenContainer
      : this.embedContainer;
    const { customVisualRender, setShowChartPrintBtn } = this.props;

    if (isFullScreenOn) {
      this.fullScreenReport = report;
    } else {
      this.report = report; // get the report object from callback and store it.(optional)
    }

    setShowChartPrintBtn && report.getFilters()
    .then(filters => {
    //   console.log(filters,"on load");
      setTimeout(() => setShowChartPrintBtn(true), 5000);
    });

    report
      .setFilters(this.getReportFilters(this.props.params))
      .catch((errors) => {
        //console.log(errors);
      });

    LayoutShowcaseState.layoutReport = report;

    if (isFullScreenOn == true) {
      report.getPages().then(function(pages) {
        // Retrieve active page
        // let activePage = jQuery.grep(pages, function (page) { return page.isActive })[0];
        let activePage = pages.filter((x) => x.isActive)[0];

        // Set layoutPageName to active page name
        LayoutShowcaseState.layoutPageName = activePage.name;

        // Retrieve active page visuals.

        activePage.getVisuals().then(function(visuals) {
          var reportVisuals = visuals.map(function(visual) {
            return {
              name: visual.name,
              title: visual.title,
              checked: true,
              layout: visual.layout,
              page: visual.page,
            };
          });

          // Create visuals array from the visuals of the active page
          createVisualsArray(reportVisuals);

          function createVisualsArray(reportVisuals) {
            // Remove all visuals without titles (i.e cards)
            LayoutShowcaseState.layoutVisuals = reportVisuals;

            if (customVisualRender == undefined || customVisualRender == true) {
              renderVisuals();
            }
          }

          function renderVisuals() {
            // render only if report and visuals initialized
            if (
              !LayoutShowcaseState.layoutReport ||
              !LayoutShowcaseState.layoutVisuals
            )
              return;

            let pageWidth = container.offsetWidth;
            let pageHeight = container.offsetHeight - 50;
            var isSmallScreen = true;
            if (isFullScreenOn == true && pageWidth > 786) {
              pageWidth = 786;
              isSmallScreen = false;
            }

            pageWidth = pageWidth - 40;
            LayoutShowcaseState.layoutReport.element.style.height =
              height + 20 + "px";
            document.getElementById(
              LayoutShowcaseState.layoutReport.element.id
            ).style.height = height + 20 + "px";
            // Set visuals height according to width - 9:16 ratio
            const height = pageWidth * (9 / 16);

            // Filter the visuals list to display only the checked visuals
            let checkedVisuals = LayoutShowcaseState.layoutVisuals;
            // Building visualsLayout object
            // You can find more information at https://github.com/Microsoft/PowerBI-JavaScript/wiki/Custom-Layout
            let visualsLayout = {};

            if (checkedVisuals.length > 1) {
              for (let i = 0; i < checkedVisuals.length; i++) {
                if (!isSmallScreen && isFullScreenOn == true) {
                  if (checkedVisuals[i].layout.width < pageWidth) {
                    checkedVisuals[i].layout.height += 20; // 20 + 20
                    if (checkedVisuals[i].layout.y > 0) {
                      checkedVisuals[i].layout.y += 20; //0+20
                    }
                  }
                }
                checkedVisuals[i].layout.width = pageWidth;
                visualsLayout[checkedVisuals[i].name] =
                  checkedVisuals[i].layout;
              }
            } else {
              visualsLayout[checkedVisuals[0].name] = {
                x: 1,
                y: 1,
                width: pageWidth,
                height: height,
                displayState: {
                  // Change the selected visuals display mode to visible
                  mode: 0,
                },
              };
            }

            // Building pagesLayout object
            let pagesLayout = {};
            pagesLayout[LayoutShowcaseState.layoutPageName] = {
              defaultLayout: {
                displayState: {
                  // Default display mode for visuals is hidden
                  mode: 0,
                },
              },
              visualsLayout: visualsLayout,
            };

            // Building settings object
            let settings = {
              layoutType: 0, //models.LayoutType.Custom,
              customLayout: {
                pageSize: {
                  type: 4, //models.PageSizeType.Custom,
                  width: pageWidth - 10,
                  height: height,
                },
                displayOption: 0, //models.DisplayOption.FitToPage,
                //pagesLayout: pagesLayout
              },
            };

            // If pageWidth or pageHeight is changed, change display option to actual size to add scroll bar
            //if (pageWidth !== $('#embedContainer').width() || pageHeight !== $('#embedContainer').height()) {
            settings.customLayout.displayOption = 0; //models.DisplayOption.ActualSize;
            //}

            // Change page background to transparent on Two / Three columns configuration
            settings.background = 1; // (LayoutShowcaseState.columns === ColumnsNumber.One) ?  models.BackgroundType.Default : models.BackgroundType.Transparent;

            if (isFullScreenOn == true) settings.filterPaneEnabled = true;
            // Call updateSettings with the new settings object
            LayoutShowcaseState.layoutReport.updateSettings(settings);
          }
        });
      });
    }
  };

  
  componentWillReceiveProps(newProps) {
    if (!isObjectEquivalent(newProps.params, this.props.params)) {
      let report1 = this.state.fullScreenOn
        ? this.fullScreenReport
        : this.report;
      if (report1) {
        //this.report.removeFilters();
        // this.report.getFilters()
        // .then(filters => {
        // console.log(filters,"on re-load");
        // });
        try {
          report1
            .setFilters(this.getReportFilters(newProps.params))
            .catch((errors) => {
            //  console.log(errors);
            });
        } catch (er) {
        //  console.log(er);
        }
      }
    }
  }

  getReportFilters = (params) => {
    const { configuration } = (this.props || {}).config || {};
    if (
      configuration &&
      configuration.filterEnabled &&
      (configuration.filttersKeyIndex || []).length > 0
    ) {
      var filters = configuration.filters;
      configuration.filttersKeyIndex.forEach((element) => {
        var value = params[element.keycloumn];
        if (element.type == "basic") {
          filters[element.index][element.valueField] = [];
          if(Array.isArray(value))
          {
            value.forEach((x, i) => 
            filters[element.index][element.valueField].push(x));            
          }
          else
          {
          filters[element.index][element.valueField].push(value);
          }
        } else {
          filters[element.index].conditions[element.subindex][
            element.valueField
          ] = value;
        }
      });
    }
    // console.log(filters, "get filters");
    return filters || [];
  };

  renderComponent = (component, fullScreencomponent) => {
    const { fullScreenOn } = this.state;
    const { isHideZoom } = this.props;
    return (
      <div ref={(el) => (this.embedContainer = el)} className="chartBlock">
        <div className="powerBIBlock">
          {!isMobile && !isHideZoom ? (
            fullScreenOn == false ? (
              <MdFullscreen
                size={"1.3rem"}
                onClick={() => {
                  this.setState({ fullScreenOn: true });
                }}
              />
            ) : (
              <MdFullscreenExit
                size={"1.3rem"}
                onClick={() => {
                  this.setState({ fullScreenOn: false });
                }}
              />
            )
          ) : (
            ""
          )}
        </div>
        {component}
        <Popup
          open={fullScreenOn}
          closeOnDocumentClick={true}
          onClose={() => {
            this.setState({ fullScreenOn: false });
          }}
          contentStyle={{ width: "90% !important" }}
          className="performa-popup"
        >
          <>
            <div className=" powerBIBlock">
              <MdFullscreenExit
                size={"1.3rem"}
                onClick={() => {
                  this.setState({ fullScreenOn: false });
                }}
              />
            </div>
            <div
              id="embedContainer"
              ref={(el) => (this.fullScreenContainer = el)}
            >
              {fullScreencomponent}
            </div>
          </>
        </Popup>
      </div>
    );
  };
  render() {
    const {
      config,
      type,
      extraSettings,
      style,
      tokenType,
      filters,
      showExport,
    } = this.props;

    const fullScreenReportStyle = {
      // style object for report component
      background: "transparent",
      height: "94vh",
      overflow: "hidden",
      width: "100%",
      //borderRadius: "15px",
      marginTop: "20px",
    };
    const { fullScreenOn } = this.state;
    let component = null;
    let fullScreencomponent = null;

    if (config && config.accessToken) {
      let isExportData = config.configuration.isExportData;
      if (!isMobile && (fullScreenOn || isExportData || showExport)) {
        if (extraSettings.commands) {
          if (extraSettings.commands[0].exportData) {
            delete extraSettings.commands[0].exportData;
          }
        }
      } else {
        if (!extraSettings.commands) {
          extraSettings.commands = [];
        }
        if (
          extraSettings.commands.length &&
          extraSettings.commands[0].exportData
        ) {
          extraSettings.commands[0].exportData = { displayOption: 2 };
        } else {
          extraSettings.commands = [{ exportData: { displayOption: 2 } }];
        }
      }
      const { customVisualRender } = this.props;
      if (fullScreenOn && customVisualRender) {
        extraSettings.filterPaneEnabled = true;
      }
      switch (type) {
        case 1:
          component = (
            <Dashboard
              key={"dashboard" + config.embedId}
              tokenType={tokenType} //"Embed" // Embed or "Aad"
              accessToken={config.accessToken} // accessToken goes here
              embedUrl={config.embedUrl} // embedUrl goes here
              embedId={config.embedId} // Report or Dashboard ID goes here
              permissions="All" // or "View"
              style={style}
              extraSettings={extraSettings}
            />
          );
          if (fullScreenOn) {
            fullScreencomponent = (
              <Dashboard
                key={"full_dashboard" + config.embedId}
                tokenType={tokenType} //"Embed" // Embed or "Aad"
                accessToken={config.accessToken} // accessToken goes here
                embedUrl={config.embedUrl} // embedUrl goes here
                embedId={config.embedId} // Report or Dashboard ID goes here
                permissions="All" // or "View"
                style={fullScreenReportStyle}
                extraSettings={extraSettings}
              />
            );
          }
          break;
        case 2:
          component = (
            <Report
              key={"report" + config.embedId}
              tokenType={tokenType} //"Embed" // Embed or "Aad"
              accessToken={config.accessToken} // accessToken goes here
              embedUrl={config.embedUrl} // embedUrl goes here
              embedId={config.embedId} // Report or Dashboard ID goes here
              permissions="All" // or "View"
              style={style}
              extraSettings={extraSettings}
              onLoad={this.handleReportLoad}
              parms={this.props.parms}
              className={config.embedId}
            />
          );
          if (fullScreenOn) {
            fullScreencomponent = (
              <Report
                key={"full_report" + config.embedId}
                tokenType={tokenType} //"Embed" // Embed or "Aad"
                accessToken={config.accessToken} // accessToken goes here
                embedUrl={config.embedUrl} // embedUrl goes here
                embedId={config.embedId} // Report or Dashboard ID goes here
                permissions="All" // or "View"
                style={fullScreenReportStyle}
                extraSettings={extraSettings}
                onLoad={this.handleReportLoad}
                parms={this.props.parms}
              />
            );
          }
          break;
        case 3:
          component = (
            <Tile
              key={"tile" + config.embedId}
              tokenType={tokenType} //"Embed" // Embed or "Aad"
              accessToken={config.accessToken} // accessToken goes here
              embedUrl={config.embedUrl} // embedUrl goes here
              embedId={config.embedId} // Report or Dashboard ID goes here
              permissions="All" // or "View"
              dashboardId={config.dashboardId}
              style={style}
              extraSettings={extraSettings}
            />
          );

          fullScreencomponent = (
            <Tile
              key={"full_tile" + config.embedId}
              tokenType={tokenType} //"Embed" // Embed or "Aad"
              accessToken={config.accessToken} // accessToken goes here
              embedUrl={config.embedUrl} // embedUrl goes here
              embedId={config.embedId} // Report or Dashboard ID goes here
              permissions="All" // or "View"
              dashboardId={config.dashboardId}
              style={fullScreenReportStyle}
              extraSettings={extraSettings}
            />
          );
        default:
          break;
      }
    }
    return this.renderComponent(component, fullScreencomponent);
  }
}

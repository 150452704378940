/* eslint-disable space-before-function-paren */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
export const API_VERSION = "/v1";
export const ALC_API_VERSION = "/v1";
export const DEV_URL =
	"https://streamdatum-dev-groundcontrol-apis.azurewebsites.net/";
export const TEST_URL =
	"https://streamdatum-test-groundcontrol-apis.azurewebsites.net/";
export const PROD_URL =
	"https://streamdatum-prod-groundcontrol-apis.azurewebsites.net/";
export const STAGE_URL =
	"https://streamdatum-stage-groundcontrol-apis.azurewebsites.net/";

export const SPEC_REPORT_DOWNLOAD = `http://${getCurrentEnvironment(
	true
).environment.replace(
	"stage",
	"stg"
)}azsql01/ReportServer/Pages/ReportViewer.aspx?/Specs Suites/Lease Specs Suites Report&rs:Command=Render&rs:Format=Excel&rs:ClearSession=true&`;

export const API_HEADERS = {
	authName: "Bearer",
	authToken: "msal." + getCurrentEnvironment().clientId + ".idtoken",
	CID: "CID",
};
export function getCurrentEnvironment() {
	let environment = "dev";
	let clientId = "6986f1fe-94b5-4297-b93d-2d67da97dcd1";
	let isLocal = false;
	let url = DEV_URL;
	let sharePointLibrary = "GC-DEV";

	if (window.location.href.indexOf("localhost") >= 0) {
		environment = "dev";
		sharePointLibrary = "GC-DEV";
		url = "https://localhost:44371/";
		//url='https://streamdatum-dev-groundcontrol-apis.azurewebsites.net/';
	} else if (
		window.location.href.indexOf("streamdatum-dev-groundcontrol") > 0
	) {
		environment = "dev";
		sharePointLibrary = "GC-DEV";
		isLocal = true;
		url = "https://streamdatum-dev-groundcontrol-apis.azurewebsites.net/";
	} else if (
		window.location.href.indexOf("streamdatum-test-groundcontrol") >= 0
	) {
		environment = "test";
		sharePointLibrary = "GC-QA";
		url = TEST_URL;
	} else if (
		window.location.href.indexOf("streamdatum-stage-groundcontrol") >= 0
	) {
		environment = "stage";
		sharePointLibrary = "GC-STG";
		clientId = "33940d6c-92ef-4681-bcb9-a117a5dbf304";
		url = STAGE_URL;
	} else if (
		window.location.href.indexOf("streamdatum-prod-groundcontrol") >= 0 ||
		window.location.href.indexOf("groundcontrol.streamrealty") >= 0
	) {
		clientId = "4d328ce1-30ee-4edb-8cc5-62299605ac26";
		environment = "prod";
		sharePointLibrary = "GC-PROD";
		url = PROD_URL;
	}

	return {
		environment: environment,
		isLocal: isLocal,
		clientId: clientId,
		baseUrl: url,
		sharePointLibrary: sharePointLibrary,
	};
}
function getBaseUrl() {
	const env = getCurrentEnvironment();
	return `${env.baseUrl}api`;
}

function getReportEnvironment() {
	const env = getCurrentEnvironment();
	if (env.environment === "stage") {
		return "stg";
	} else {
		return env.environment;
	}
}

export function createApiUrl(apiName) {
	return `${getBaseUrl()}${API_VERSION}/${apiName}`;
}

export const API_URL = {
	// graph: {
	//   userProfilePhoto: 'https://graph.microsoft.com/v1.0/me/photo/$value',
	// },

	powerBi: {
		QuarterlyCapitalAmount:
			"https://streamdatum-dev-apis.azurewebsites.net/api/v1/PowerBI/GetReportEmbedInfoByUser?groupId=38fbf39b-3549-45d2-b2c4-0ccfcd04c889&reportId=cf9fc72b-c12e-4b0d-aa60-32d1ab2d9bda&isHideLoader=true",
		// totalOccupancyReports:'https://streamdatum-dev-apis.azurewebsites.net/api/v1/PowerBI/GetReportEmbedInfoByUser?groupId=38fbf39b-3549-45d2-b2c4-0ccfcd04c889&reportId=af61baee-835c-4718-a5f9-5ac1933f3a2e&isHideLoader=true',
		// totalOccupancyReports:'https://streamdatum-dev-apis.azurewebsites.net/api/v1/PowerBI/GetReportEmbedInfoByUser?groupId=38fbf39b-3549-45d2-b2c4-0ccfcd04c889&reportId=42074f60-a2a3-4d27-abbf-1fb2b0688d68&isHideLoader=true',
		// houstonOfficeStats: 'https://streamdatum-dev-apis.azurewebsites.net/api/v1/PowerBI/GetReportEmbedInfoByUser?groupId=38fbf39b-3549-45d2-b2c4-0ccfcd04c889&reportId=1d7f6939-6441-4a90-ace0-955e73711b57&isHideLoader=true',
		// houstonOfficeStats: createApiUrl('PowerBi/Get'),
		// houstonIndustrialStats: 'https://streamdatum-dev-apis.azurewebsites.net/api/v1/PowerBI/GetReportEmbedInfoByUser?groupId=38fbf39b-3549-45d2-b2c4-0ccfcd04c889&reportId=fa285b33-d52a-4ef7-b050-b1b7948ff528&isHideLoader=true',
		houstonIndustrialStats: createApiUrl("PowerBi/Get"),
		houstonOfficeStats: createApiUrl("PowerBi/Get"),
		reportInfo: createApiUrl("PowerBi/Get"),
		AcquireSPToken: createApiUrl("PowerBi/GetSPServiceToken"),
	},
	SSRSExport: {
		Property:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/PropertyListing",
		IndustrialProperty:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/IndustrialPropertyListing",
		Company:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/CompanyListing",
		Stats:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/OfficeStatistics",
		IndustrialStats:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/IndustrialStatistics",
		Tenant:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/TenantListing",
		LeaseComp:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/LeaseCompListing",
		IndustrialLeaseComp:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/IndustrialLeaseCompListing",
		SaleComp:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/SalesCompListing",
		IndustrialSaleComp:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/IndustrialSalesCompListing",
		SpecSuite:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/LeaseSpecsSuitesReport",
		VacancyReport:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/MarketVacancyReport",
		MarketAbsorptionReport:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/MarketAbsorptionReport",
		ClassficationBreakdownReport:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/MarketClassificationBreakdown",
		PropertyLoan:
			"http://" +
			getReportEnvironment() +
			"azsql01/ReportServer?/GroundControl/BuildingLoans",
	},
	Export: {
		Building: createApiUrl("SSRSReport/Property"),
		MarketStats: createApiUrl("SSRSReport/MarketStats"),
		GetReport: createApiUrl("SSRSReport/GetReport"),
	},
	Master: {
		companyMasterDetails: createApiUrl("Master/Companies"),
		ownerTypeData: createApiUrl("Master/CompanyRoles"),
		getIndustrySector: createApiUrl("Master/Industries"),
		getSubMarketMaster: createApiUrl("Master/SubMarkets"),
		getProductTypeMaster: createApiUrl("Master/ProductType"),
		getMarketMaster: createApiUrl("Master/Market"),
		getMasterZip: createApiUrl("Master/Zip"),
		buildingMasterData: createApiUrl("Master/BuildingClasses"),
		getBuildingMaster: createApiUrl("Master/Buildings"),
		getBuildingNameAddressCostarMaster: createApiUrl("Master/BuildingNameAddressCostar"),
		getSpecSuiteBuildingMaster: createApiUrl("Master/SpecSuiteBuildings"),
		getBrokerMaster: createApiUrl("Master/OfficeBrokers"),
		industrialBuildingMasterPropertyData: createApiUrl("Master/Properties"),
		industrialBuildingMasterDistClassData: createApiUrl(
			"Master/DistributionClass"
		),
		industrialBuildingMasterBuildingParkData: createApiUrl(
			"Master/BuildingParks"
		),
		industrialBuildingMasterAgentData: createApiUrl("Master/Agents"),
		industrialBuildingMasterMaterialTypeData: createApiUrl(
			"Master/MaterialTypes"
		),
		industrialBuildingMasterData: createApiUrl("Master/IndustrialBuildings"),
		getAllCompType: createApiUrl("Master/CompTypes"),
		getAllLeaseType: createApiUrl("Master/LeaseTypes"),
		getALLNNNFS: createApiUrl("Master/NNNFS"),
		getALLEscalationMarker: createApiUrl("Master/EscalationMarkers"),
		getPropertyOwnerUserOptions: createApiUrl("Master/PropertyOwnerUserOptions"),
		getAllRelocationReason: createApiUrl("Master/RelocationReasons"),
		getALLBrokerFirm: createApiUrl("Master/BrokerFirms"),
		getALLSellerFirm: createApiUrl("Master/SellerFirms"),
		getALLTenantCompany: createApiUrl("Master/TenantCompanies"),
		getAllMSalesStatus: createApiUrl("Master/SalesStatus"),
		buildingOwnerMasterDetails: createApiUrl("Master/BuildingOwners"),
		propertyOwnerUserMasterData: createApiUrl("Master/PropertyOwnerUsers"),
		industrialBuildingPropertyFirmData: createApiUrl("Master/PropertyFirms"),
		industrialBuildingSprinklerData: createApiUrl("Master/SprinklerTypes"),
		getCity: createApiUrl("Master/City"),
		getState: createApiUrl("Master/State"),
		getMasterDetail: createApiUrl("Master/MasterDetail"),
		addMasterDetail: createApiUrl("Master/AddMasterDetail"),
		AddMarketMasterDetail: createApiUrl("Master/AddMarketMasterDetail"),
		UpdateMarketMasterDetail: createApiUrl("Master/UpdateMarketMasterDetail"),
		getConstructionStatus: createApiUrl("Master/ConstructionStatus"),
		GetUserConfigurations: createApiUrl("Master/GetUserConfigurations"),
		addUserConfiguration: createApiUrl("Master/AddUserConfiguration"),
		getAllTenancy: createApiUrl("Master/GetAllTenancy"),
		getAllRentType: createApiUrl("Master/GetAllRentType"),
		getAllRepresentationType: createApiUrl("Master/GetAllRepresentationType"),
		getAllGeneration: createApiUrl("Master/GetAllGeneration"),
		getAllCompSource: createApiUrl("Master/GetAllCompSource"),
		getAllDirectSubLease: createApiUrl("Master/GetAllDirectSubLease"),
		getAllSourceSystem: createApiUrl("Master/GetAllSourceSystem"),
		getAllNegotation: createApiUrl("Master/GetAllNegotation"),
		softDeleteComps: createApiUrl("Master/SoftDeleteComps"),
		AddSubMarketMasterDetail: createApiUrl("Master/AddSubMarketMasterDetail"),
		UpdateSubMarketMasterDetail: createApiUrl(
			"Master/UpdateSubMarketMasterDetail"
		),
		AddBuildingClassDetail: createApiUrl("Master/AddBuildingClassDetail"),
		UpdateBuildingClassDetail: createApiUrl("Master/UpdateBuildingClassDetail"),
		getAllCostarSubMarket: createApiUrl("Master/GetCostarSubMarket"),
		getAllCostarBuildingClass: createApiUrl("Master/GetCostarBuildingClass"),
		getBuildingMasters: createApiUrl("Master/GetBuildingMasters"),
		getMarketSubmarket: createApiUrl("Master/MarketSubmarket"),
		getLeaseCompsMasters: createApiUrl("Master/GetLeaseCompsMasters"),
		getCompSourceAndRepresentationTypeMasters: createApiUrl("Master/GetCompSourceAndRepresentationTypeMasters"),
		getIndustrialBuildingMasters: createApiUrl("Master/GetIndustrialBuildingMasters"),
		getMarketCostar: createApiUrl("Master/MarketCostar"),
		getMasterLoanPurpose: createApiUrl("Master/LoanPurpose"),
		getMasterLoanStatus: createApiUrl("Master/LoanStatus"),
		PropertyOwnershipStatus: createApiUrl("Master/GetPropertyOwnershipStatus"),
		getPrimaryResearchMetric: createApiUrl("Master/PrimaryResearchMetric"),
		marketStatsRefreshData: createApiUrl("Master/MarketStatsRefreshData"),
		cancelMarketStatsRefresh: createApiUrl("Master/CancelMarketStatsRefresh"),
		getSubmarketClusterMaster: createApiUrl("Master/SubmarketCluster"),
		AddSubmarketClusterMasterDetail: createApiUrl("Master/AddSubmarketClusterMasterDetail"),

		UpdateSubmarketClusterMasterDetail: createApiUrl("Master/UpdateSubmarketClusterMasterDetail"),

		getTenantIndustryMaster: createApiUrl("Master/TenantIndustry"),
		AddIndustryTenantMasterDetail: createApiUrl("Master/AddIndustryTenantMasterDetail"),

		UpdateIndustryTenantMasterDetail: createApiUrl("Master/UpdateIndustryTenantMasterDetail"),
		GetAllInvestmentType: createApiUrl("Master/GetAllInvestmentType"),
		GetAllDockHeight: createApiUrl("Master/GetAllDockHeight"),
		GetAllDockConfiguration: createApiUrl("Master/GetAllDockConfiguration"),
	},
	Companies: {
		companyData: createApiUrl("OfficeCompanies/List"),
		// companyMasterDetails: createApiUrl('Master/Companies'),
		// ownerTypeData: createApiUrl('Master/CompanyRoles'),
		// getIndustrySector: createApiUrl('Master/Industries'),
		getCompaniesDetails: createApiUrl("OfficeCompanies/Get"),
		// getSubMarketMaster: createApiUrl('Master/SubMarkets'),
		// getProductTypeMaster: createApiUrl('Master/ProductType'),
		// getMarketMaster: createApiUrl('Master/Market'),
		// getMasterZip: createApiUrl('Master/Zip'),
		UpdateCompanyDetail: createApiUrl("OfficeCompanies/Update"),
		InsertCompany: createApiUrl("OfficeCompanies/Add"),
	},
	BuildingOwners: {
		buildingOwnersSummaryData: createApiUrl("OfficePropertyOwner/List"),
		buildingOwnersExport: createApiUrl("OfficePropertyOwner/Export"),
		// buildingOwnerMasterDetails: createApiUrl('Master/BuildingOwners'),
		getBuildingOwnersDetails: createApiUrl("OfficePropertyOwner/Get"),
		updateBuildingOwnersDetail: createApiUrl("OfficePropertyOwner/Update"),
		addBuildingOwnersDetail: createApiUrl("OfficePropertyOwner/Add"),
		getOtherCompanyAddress: createApiUrl(
			"OfficePropertyOwner/GetOtherCompanyAddress"
		),
		getOtherCompanyContact: createApiUrl(
			"OfficePropertyOwner/GetOtherCompanyContact"
		),
		updateOtherCompanyAddress: createApiUrl(
			"OfficePropertyOwner/UpdateOtherCompanyAddress"
		),
		updateOtherCompanyContact: createApiUrl(
			"OfficePropertyOwner/UpdateOtherCompanyContact"
		),
		getOtherCompanyContactAndAddress: createApiUrl(
			"OfficePropertyOwner/GetOtherCompanyContactAndAddress"
		),
	},
	IndustrialBuildingOwners: {
		buildingOwnersSummaryData: createApiUrl("IndustrialPropertyOwner/List"),
		industrialBuildingOnwerExport: createApiUrl(
			"IndustrialPropertyOwner/Export"
		),
		// businessLineforBelow
		buildingOwnerMasterDetails: createApiUrl(
			"IndustrialPropertyOwner/GetBuildingOwnersMasterData"
		),
		// ownerType: createApiUrl('IndustrialPropertyOwner/CompanyRoles'),
		getBuildingOwnersDetails: createApiUrl("IndustrialPropertyOwner/Get"),
		updateBuildingOwnersDetail: createApiUrl("IndustrialPropertyOwner/Update"),
		addBuildingOwnersDetail: createApiUrl("IndustrialPropertyOwner/Add"),
		getOtherCompanyAddress: createApiUrl(
			"IndustrialPropertyOwner/GetOtherCompanyAddress"
		),
		getOtherCompanyContact: createApiUrl(
			"IndustrialPropertyOwner/GetOtherCompanyContact"
		),
		updateOtherCompanyAddress: createApiUrl(
			"IndustrialPropertyOwner/UpdateOtherCompanyAddress"
		),
		updateOtherCompanyContact: createApiUrl(
			"IndustrialPropertyOwner/UpdateOtherCompanyContact"
		),
	},
	Building: {
		buildingSummaryData: createApiUrl("OfficeProperty/List"),
		allBuildingSummaryData: createApiUrl("OfficeProperty/ListAll"),
		buildingExport: createApiUrl("OfficeProperty/Export"),
		getBuildingDetails: createApiUrl("OfficeProperty/Get"),
		getBuildingDetailsForQAReport: createApiUrl(
			"OfficeProperty/GetBuildingDetailsForQAReport"
		),
		// getBuildingMaster: createApiUrl('Master/Buildings'),
		// getBrokerMaster: createApiUrl('Master/OfficeBrokers'),
		updateBuilding: createApiUrl("OfficeProperty/Update"),
		insertBuilding: createApiUrl("OfficeProperty/Add"),
		getHistoryDataByUser: createApiUrl("User/GetHistoryDataByUser"),
		getHistoryDetailByField: createApiUrl("User/GetHistoryDetailByField"),
		getHistoricalOwnership: createApiUrl(
			"OfficeProperty/GetHistoricalOwnership"
		),
		getBuildingCompSet: createApiUrl(
			"OfficeProperty/GetBuildingCompSet"
		),
		getPropertyOfNonInterest: createApiUrl(
			"OfficeProperty/GetPropertyOfNonInterest"
		),
		updatePropertyOfNonInterest: createApiUrl(
			"OfficeProperty/UpdatePropertyOfNonInterest"
		),
		checkIfCoStarExist: createApiUrl("OfficeProperty/CheckCoStar"),
		UpdateCoStarId: createApiUrl("OfficeProperty/UpdateCoStarId"),
		GetReportPropertyAbsorptionAndVacancy: createApiUrl(
			"OfficeProperty/GetReportPropertyAbsorptionAndVacancy"
		),
		GetReportPropertyMajorMoveIns: createApiUrl(
			"OfficeProperty/GetReportPropertyMajorMoveIns"
		),
		GetReportPropertyMajorMoveOuts: createApiUrl(
			"OfficeProperty/GetReportPropertyMajorMoveOuts"
		),
		UpdateReportPropertyAbsorptionAndVacancy: createApiUrl(
			"OfficeProperty/UpdateReportPropertyAbsorptionAndVacancy"
		),
		UpdateReportTenantMoveIn:createApiUrl(
			"OfficeProperty/UpdateReportTenantMoveIn"
		),
		UpdateReportTenantMoveOut:createApiUrl(
			"OfficeProperty/UpdateReportTenantMoveOut"
		),
		GetPropertyOwnerObject: createApiUrl(
			"OfficeProperty/GetPropertyOwnerObject"
		),
		UpdatePropertyOwnerObject:createApiUrl(
			"OfficeProperty/UpdatePropertyOwnerObject"
		)
	},


	IndustrialBuilding: {
		industrialBuildingSummaryData: createApiUrl("IndustrialProperty/List"),
		industrialBuildingExport: createApiUrl("IndustrialProperty/Export"),
		// industrialBuildingSprinklerData: createApiUrl('Master/SprinklerTypes'),
		// industrialBuildingPropertyFirmData: createApiUrl('Master/PropertyFirms'),
		// industrialBuildingMasterData: createApiUrl('Master/IndustrialBuildings'),
		industrialBuildingDetail: createApiUrl("IndustrialProperty/Get"),
		// industrialBuildingMasterPropertyData: createApiUrl('Master/Properties'),
		// industrialBuildingMasterDistClassData: createApiUrl('Master/DistributionClass'),
		// industrialBuildingMasterBuildingParkData: createApiUrl('Master/BuildingParks'),
		// industrialBuildingMasterAgentData: createApiUrl('Master/Agents'),
		// industrialBuildingMasterMaterialTypeData: createApiUrl('Master/MaterialTypes'),
		industrialBuildingUpdate: createApiUrl("IndustrialProperty/Update"),
		industrialBuildingAdd: createApiUrl("IndustrialProperty/Add"),
		industrialGetPropertyOfNonInterest: createApiUrl(
			"IndustrialProperty/GetPropertyOfNonInterest"
		),
		industrialUpdatePropertyOfNonInterest: createApiUrl(
			"IndustrialProperty/UpdatePropertyOfNonInterest"
		),
		industrialCheckIfCoStarExist: createApiUrl(
			"IndustrialProperty/CheckCoStar"
		),
		industrialUpdateCoStarId: createApiUrl("IndustrialProperty/UpdateCoStarId"),
		industrialGetHistoryDetailByField: createApiUrl(
			"User/GetHistoryDetailByField"
		),
		industrialGetHistoryDataByUser: createApiUrl("User/GetHistoryDataByUser"),
		industrialGetHistoricalOwnership: createApiUrl(
			"IndustrialProperty/GetHistoricalOwnership"
		),
		industrialGetReportPropertyAbsorptionAndVacancy: createApiUrl(
			"IndustrialProperty/GetReportPropertyAbsorptionAndVacancy"
		),
		getBuildingDetailsForQAReport: createApiUrl(
			"IndustrialProperty/GetBuildingDetailsForQAReport"
		),
	},

	Tenants: {
		tenantsSummaryData: createApiUrl("OfficeTenant/List"),
		tenantsExport: createApiUrl("OfficeTenant/Export"),
		tenantDetail: createApiUrl("OfficeTenant/Get"),
		tenantUpdate: createApiUrl("OfficeTenant/Update"),
		tenantAdd: createApiUrl("OfficeTenant/Add"),
	},
	IndustrialTenants: {
		tenantsSummaryData: createApiUrl("IndustrialTenant/List"),
		tenantsExport: createApiUrl("IndustrialTenant/Export"),
		tenantDetail: createApiUrl("IndustrialTenant/Get"),
		tenantUpdate: createApiUrl("IndustrialTenant/Update"),
		tenantAdd: createApiUrl("IndustrialTenant/Add"),
	},

	ImportExport: {
		getImportfileData: createApiUrl("ImportExport/GetFileImportDetail"),
		getImportfileDataForIndividualProperty: createApiUrl("ImportExport/GetFileImportDetailForIndividualProperty"),
		addFileImportDetail: createApiUrl("ImportExport/AddFileImportDetail"),
		AddFileImportDetailIndividualProperty: createApiUrl("ImportExport/AddFileImportDetailIndividualProperty"),
		AddFileImportDetailIndustrial: createApiUrl(
			"ImportExport/AddFileImportDetailIndustrial"
		),
		clearImport: createApiUrl("ImportExport/ClearMarketStatsOffice"),
		clearImportIndustrial: createApiUrl(
			"ImportExport/ClearMarketStatsIndustrial"
		),
		addLeaseFileImportDetail: createApiUrl(
			"ImportExport/AddLeaseCompFileImportDetail"
		),
		addSalesFileImportDetail: createApiUrl(
			"ImportExport/AddSalesCompFileImportDetail"
		),
		addIndLeaseFileImportDetail: createApiUrl(
			"ImportExport/AddIndLeaseCompFileImportDetail"
		),
		addIndSalesFileImportDetail: createApiUrl(
			"ImportExport/AddIndSalesCompFileImportDetail"
		),
    updateBulkProperty: createApiUrl('ImportExport/UpdateBulkProperty'),
    updateBulkPropertyIndustrial: createApiUrl('ImportExport/UpdateBulkPropertyIndustrial'),
	InsertBulkLoans: createApiUrl('ImportExport/InsertBulkLoans'),
	
	},

	Dashboard: {
		refreshMarketStatsdata: createApiUrl("Dashboard/RefreshMarketStatsDataOffice")
	},
	LeaseComps: {
		leaseCompsSummaryData: createApiUrl("OfficeLeaseComp/List"),
		leaseCompsExport: createApiUrl("OfficeLeaseComp/Export"),
		// getAllCompType: createApiUrl('Master/CompTypes'),
		// getAllLeaseType: createApiUrl('Master/LeaseTypes'),
		getLeaseCompDetail: createApiUrl("OfficeLeaseComp/Get"),
		// getALLNNNFS: createApiUrl('Master/NNNFS'),
		// getALLEscalationMarker: createApiUrl('Master/EscalationMarkers'),
		// getAllRelocationReason: createApiUrl('Master/RelocationReasons'),
		updateLeaseCompDetail: createApiUrl("OfficeLeaseComp/Update"),
		addLeaseCompDetail: createApiUrl("OfficeLeaseComp/Add"),
		// getALLBrokerFirm: createApiUrl('Master/BrokerFirms'),
		// getALLTenantCompany:createApiUrl('Master/TenantCompanies'),
		getUnmappedLeaseComps: createApiUrl("OfficeLeaseComp/GetUnmappedLeaseComps"),
		updateUnmappedLeaseComp: createApiUrl("OfficeLeaseComp/UpdateUnmappedLeaseComp"),
	},
	IndustrialLeaseComps: {
		leaseCompsSummaryData: createApiUrl("IndustrialLeaseComp/List"),
		leaseCompsExport: createApiUrl("IndustrialLeaseComp/Export"),
		getLeaseCompDetail: createApiUrl("IndustrialLeaseComp/Get"),
		updateLeaseCompDetail: createApiUrl("IndustrialLeaseComp/Update"),
		addLeaseCompDetail: createApiUrl("IndustrialLeaseComp/Add"),
		getUnmappedLeaseComps: createApiUrl("IndustrialLeaseComp/GetUnmappedLeaseComps"),
		updateUnmappedLeaseComp: createApiUrl("IndustrialLeaseComp/UpdateUnmappedLeaseComp"),
	},

	SalesComps: {
		salesCompsSummaryData: createApiUrl("OfficeSalesComp/List"),
		salesCompsExport: createApiUrl("OfficeSalesComp/Export"),
		// getAllCompType: createApiUrl('Master/CompTypes'),
		// getAllMSalesStatus: createApiUrl('Master/SalesStatus'),
		updateSalesCompDetail: createApiUrl("OfficeSalesComp/Update"),
		getSalesCompDetails: createApiUrl("OfficeSalesComp/Get"),
		addSalesCompDetail: createApiUrl("OfficeSalesComp/Add"),
	},

	IndustrialSalesComps: {
		getSalesCompSummary: createApiUrl("IndustrialSalesComp/List"),
		salesCompsExport: createApiUrl("IndustrialSalesComp/Export"),
		updateSalesCompDetail: createApiUrl("IndustrialSalesComp/Update"),
		getSalesCompDetails: createApiUrl("IndustrialSalesComp/Get"),
		addSalesCompDetail: createApiUrl("IndustrialSalesComp/Add"),
	},

	Reports: {
		propertyVacancyReport: createApiUrl("Report/ReportPropertyVacancy"),
		marketAbsorptionReport: createApiUrl("Report/ReportMarketAbsorption"),
		subMarketLeasingStatsReport: createApiUrl(
			"Report/ReportSubMarketLeasingStats"
		),
		subMarketPositiveAbsReport: createApiUrl(
			"Report/ReportSubMarketPositiveAbsorptionQtr"
		),
		subMarketNegativeAbsReport: createApiUrl(
			"Report/ReportSubMarketNegativeAbsorptionQtr"
		),
		ytdAbsorptionSubmarket: createApiUrl("Report/ReportYtdAbsorptionSubmarket"),
		classificationBreakdownByClassTier: createApiUrl(
			"Report/ReportClassificationBreakdownClassTier"
		),
		classificationBreakdownByCompetetive: createApiUrl(
			"Report/ReportClassificationBreakdownCompetetive"
		),
		classificationBreakdownBySubmarket: createApiUrl(
			"Report/ReportClassificationBreakdownSubmarket"
		),
		covidSubAbs: createApiUrl("Report/ReportCovidSubAbs"),
		covidOfficeDelivered: createApiUrl("Report/ReportCovidSubAbsorptionByYear"),
		currentDevelopmentPipeline: createApiUrl(
			"Report/ReportCurrentDevelopmentPipeline"
		),
		getLeaseCompReport: createApiUrl("Report/ReportLeaseCompQAReport"),
		getMonthRecorded: createApiUrl("Report/GetMonthRecorded"),
		GetMarketStatsRefreshData: createApiUrl("Report/GetMarketStatsRefreshData"),
		
	},
	IndustrialReports: {
		// propertyVacancyReport: createApiUrl('IndustrialReport/ReportPropertyVacancy'),
		// marketAbsorptionReport: createApiUrl('IndustrialReport/ReportMarketAbsorption'),
		propertyVacancyReport: createApiUrl(
			"IndustrialReport/ReportIndPropertyVacancy"
		),
		marketAbsorptionReport: createApiUrl(
			"IndustrialReport/ReportIndMarketAbsorption"
		),
		subMarketLeasingStatsReport: createApiUrl(
			"IndustrialReport/ReportIndSubMarketLeasingStats"
		),
		subMarketPositiveAbsReport: createApiUrl(
			"IndustrialReport/ReportIndSubMarketPositiveAbsorptionQtr"
		),
		subMarketNegativeAbsReport: createApiUrl(
			"IndustrialReport/ReportIndSubMarketNegativeAbsorptionQtr"
		),
		ytdAbsorptionSubmarket: createApiUrl(
			"IndustrialReport/ReportIndYtdAbsorptionSubmarket"
		),
		classificationBreakdownByClassTier: createApiUrl(
			"IndustrialReport/ReportIndClassificationBreakdownClassTier"
		),
		classificationBreakdownByCompetetive: createApiUrl(
			"IndustrialReport/ReportIndClassificationBreakdownCompetetive"
		),
		classificationBreakdownBySubmarket: createApiUrl(
			"IndustrialReport/ReportIndClassificationBreakdownSubmarket"
		),
		covidSubAbs: createApiUrl("IndustrialReport/ReportIndCovidSubAbs"),
		covidOfficeDelivered: createApiUrl(
			"IndustrialReport/ReportIndCovidSubAbsorptionByYear"
		),
		currentDevelopmentPipeline: createApiUrl(
			"IndustrialReport/ReportIndCurrentDevelopmentPipeline"
		),
		getLeaseCompReport: createApiUrl(
			"IndustrialReport/ReportIndLeaseCompQAReport"
		),
	},
	Users: {
		GetUserOrganizationDetails: createApiUrl("User/GetUserOrganizationDetails"),
		GetUserConfig: createApiUrl("User/GetUserConfig"),
		GetAccessGroups: createApiUrl("User/GetAccessGroups"),
		GetPermissionByGroup: createApiUrl("User/GetPermissionByGroup"),
		GetAccessType: createApiUrl("User/GetAccessType"),
		UpdatePermission: createApiUrl("User/UpdatePermission"),
		GetEmployeeMasterData: createApiUrl("User/GetEmployeeListingData"),
		GetAllMarketAccessPermission: createApiUrl(
			"User/GetAllMarketAccessPermission"
		),
		InsertMarketAccessPermission: createApiUrl(
			"User/InsertMarketAccessPermission"
		),
		// GetUserConfigurations: createApiUrl('User/GetUserConfigurations'),
	},
	SpecSuites: {
		getSpecProperty: createApiUrl("values/GetAllPropertyByMarketSubMarket"),
		getDDMaster: createApiUrl("MasterData/GetStateCityMarketDetails"),
		getMarketMasterOnly: createApiUrl("MasterData/GetMasterAllMarketsMSA"),
		getSubMarketMaster: createApiUrl("MasterData/GetMasterSubMarket"),
		getMasterAllData: createApiUrl("values/GetMasterData"),
		specSummary: createApiUrl("Dashboard/GetDashboardData"),
		specChartData: createApiUrl("Dashboard/GetDashboardChartData"),
		assetSummaryTable: createApiUrl(
			"values/GetSpecLeaseSuiteStateIdCityIdByPropertyId"
		),
		updateSpecLease: createApiUrl("values/UpdateSpecLeaseSuiteByPropertyId"),
		insertPropertySpecLease: createApiUrl(
			"values/CreateSpecPropertySpecLeaseSuite"
		),
		insertSpecLease: createApiUrl("values/CreateSpecLeaseSuiteByPropertyId"),
		deleteSpecLease: createApiUrl("values/DeleteSpecLeaseSuiteByPropertyId"),
		readExcel: createApiUrl("FileUpload/ReadFile"),
		uploadExcel: createApiUrl("FileUpload/UploadExcel"),
	},

	ChartPoc:{
		getChartData:createApiUrl("ChartPoc/GetChartData"),
		updateChartData:createApiUrl("ChartPoc/AddChartDataDetail"),
		getStatsData:createApiUrl("ChartPoc/GetRptStatisticsDetail"),
		updateStatsData:createApiUrl("ChartPoc/UpdateStatsDetail"),
		getYearData:createApiUrl("ChartPoc/GetYearRecorded"),
		getQuarterData:createApiUrl("ChartPoc/GetQuarterRecorded"),
		getBigDealData:createApiUrl("ChartPoc/GetRptBigDealsReport"),
		updateBigDealData:createApiUrl("ChartPoc/UpdateBigDealsImportDetail"),
		getLeasingData:createApiUrl("ChartPoc/GetRptLeasingActivityReportDetail"),
		updateLeasingData:createApiUrl("ChartPoc/UpdateRptLeasingActivityImportReportDetail"),
		getInvestementData:createApiUrl("ChartPoc/GetRptInvestmentSalesReportDetail"),
		updateInvestementData:createApiUrl("ChartPoc/UpdateRptInvestmentSalesImportReportDetail"),
		getDevelopmentData:createApiUrl("ChartPoc/GetRptDevelopmentDetail"),
		updateDevelopmentData:createApiUrl("ChartPoc/UpdateRptDevelopmentImportReportDetail"),
		getVacancyData:createApiUrl("ChartPoc/GetRptVacanyAtGlanceReportDetail"),
		updateVacancyData:createApiUrl("ChartPoc/UpdateRptVacanyAtGlanceReportDetail"),

	},
	CompSet:{
		CompSetData: createApiUrl("CompSet/List"),	
		CompSetDetails: createApiUrl("CompSet/Get"),	
		updateCompSet: createApiUrl("CompSet/Update"),
		insertCompSet: createApiUrl("CompSet/Add"),
		CompSetProperties: createApiUrl("CompSet/GetProperties"),
		AddCompSetProperties : createApiUrl("CompSet/AddCompSetProperties"),
		SoftDeleteCompSetPropertyLink: createApiUrl("CompSet/SoftDeleteCompSetPropertyLink"),
		UpdateCompSetDetail:createApiUrl("CompSet/UpdateCompSetDetail"),
	},
	RcaLoan:{
		RcaLoanData: createApiUrl("RcaLoan/List"),	
		LoanDetails: createApiUrl("RcaLoan/Get"),	
		updateLoanDetails: createApiUrl("RcaLoan/Update"),
		insertRcaLoan: createApiUrl("RcaLoan/Add"),
		CompSetProperties: createApiUrl("CompSet/GetProperties"),
		AddCompSetProperties : createApiUrl("CompSet/AddCompSetProperties"),
		SoftDeleteCompSetPropertyLink: createApiUrl("CompSet/SoftDeleteCompSetPropertyLink"),
		UpdateCompSetDetail:createApiUrl("CompSet/UpdateCompSetDetail"),
		getUnmappedLoan: createApiUrl("RcaLoan/GetUnmappedLoans"),
		UpdateUnmappedLoan: createApiUrl("RcaLoan/UpdateUnmappedLoan"),
	}
};


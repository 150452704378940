import React from "react";
import PropTypes from "prop-types";
import "./style.less";
import { Tooltip, IconButton } from "@material-ui/core";
import { pure } from "recompose";
function PageHeader({
  title,
  actionsConfig,
  classNames,
  btnText,
  onBackClick,
  isReportPage,
}) {
  return (
    <div className="row pageheader-container start-xs pageheader-padding no-print">
      {btnText != null ? (
        <div className={isReportPage == true ? "col-xs-auto pageheader" :"col-xs-auto pageheader pageheader-displayName"}>
          <button 
          className="header-btn"
          onClick={() => onBackClick()}
          >{btnText}
          </button>
          <span>{title}</span>
        </div>
      ) : (
        <div className="col-xs-auto pageheader">{title}</div>
      )}
      <div
        className="col-xs pageheader pull-right end-xs"
        key={"container-action-"}
      >
        {actionsConfig &&
          actionsConfig.map((action, index) => {
            return (
              <>
              <Tooltip title={action.title}>
                <IconButton
                  key={"container-action-IC-" + index}
                  aria-label={"newWidget"}
                  size="small"
                  onClick={action.onClick}
                >
                  {action.icon}
                </IconButton>
                </Tooltip>
                <span
                  key={"container-action-span-" + index}
                  className="action-label"
                >
                  {action.name}
                </span>
              </>
            );
          })}
      </div>
    </div>
  );
}
PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionsConfig: PropTypes.array,
  btnText: PropTypes.string,
  onBackClick: PropTypes.func,
};
export default pure(PageHeader);

import { getCurrentEnvironment } from "./api";
import { SPSDrive } from "../constants/constants";

const sharePointSiteId = {
  streamrealty: {
    dev: "2c5031d8-d91f-496a-b645-0c1a603edde0,6c05be92-50e0-405e-9dc7-b8fd4ca147fc", //'e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc',
    test: "2c5031d8-d91f-496a-b645-0c1a603edde0,6c05be92-50e0-405e-9dc7-b8fd4ca147fc",
    stage:
      "2c5031d8-d91f-496a-b645-0c1a603edde0,6c05be92-50e0-405e-9dc7-b8fd4ca147fc",
    prod: "e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc",
  },
};

const folderIds = {
  dev: {
    Lease: "01KONIAUBHSA3KXRF72BD2GW6ASQBTTR66", //'e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc',
    MarketStats: "01KONIAUC2NA5X6Y2IMZE2DLN6WAKDTT7T",
    Property: "01KONIAUDHPCHEAOBCLRB3SRPIITRX3QPE",
    Sales: "01KONIAUHEKRQ3W5D4M5BIZ4FDY4J5CPB6",
  },
  test: {
    Lease: "01KONIAUFEL2RTVELHMJEKLBNZ7N64V7KA", //'e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc',
    MarketStats: "01KONIAUBSXTQP7U3QNND3U5OBV524A3FR",
    Property: "01KONIAUCZXDAIPP737JDJFWSDELKVY7NW",
    Sales: "01KONIAUGPMNSBULRNFVFKTSDXBYCAMECV",
  },
  stage: {
    Lease: "01KONIAUG4VTISCMGGUJC3SDP4YJANMZ26", //'e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc',
    MarketStats: "01KONIAUCMBOJOWUHMLRFIOL643KITGC6Z",
    Property: "01KONIAUGWWQJ2Y6SFONDY2HJRKS36BCFN",
    Sales: "01KONIAUHKKVGRDEK6CBALP3ADWVUZA2T4",
  },
  prod: {
    Lease: "01PAQGSXFEJAVAHCREJFCK5WT5FCNXSLMF", //'e3756b4c-dbc4-4bff-aba0-cfad6687e17c,6c05be92-50e0-405e-9dc7-b8fd4ca147fc',
    MarketStats: "01PAQGSXEEJXR4336DMJCZFXS6GM4J63V4",
    Property: "01PAQGSXGKZTX4MWOXCFFKAMQIDAFETCC3",
    Sales: "01PAQGSXG5O6DH6EHZXRC2VKWZYWARVZSA",
    },
};

function getCurrentEnvironmentSiteId(tenant = "streamrealty") {
  if (window.location.href.indexOf("streamdatum-test-groundcontrol") > 0) {
    return sharePointSiteId[tenant].test;
  } else if (window.location.href.indexOf("streamdatum-dev-groundcontrol") > 0) {
    return sharePointSiteId[tenant].dev;
  } else if (window.location.href.indexOf("streamdatum-stage-groundcontrol") > 0) {
    return sharePointSiteId[tenant].stage;
  } else if (window.location.href.indexOf("localhost") > 0) {
    return sharePointSiteId[tenant].dev;
  } else if ((window.location.href.indexOf("streamdatum-prod-groundcontrol") > 0) || (window.location.href.indexOf("groundcontrol.streamrealty") > 0)) {
    return sharePointSiteId[tenant].prod;
  }
  return sharePointSiteId[tenant].dev;
}

function getFolderId(domain, key) {
  const env = getCurrentEnvironment().environment;
  if (key == "Lease") {
    return folderIds[env].Lease;
  } else if (key == "Sales") {
    return folderIds[env].Sales;
  } else if (key == "MarketStats") {
    return folderIds[env].MarketStats;
  } else if (key == "Property") {
    return folderIds[env].Property;
  }
}

export function getSharePointUrl(
  domain,
  name = "getSps",
  Key,
  fileName = "",
  driveId,
  FileId
) {
  switch (name) {
    case "createFolder":
      //return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(domain)}/drive/root/children`;
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists`;
    case "getFolder":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists`;
    case "getInnerFolder":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/items/${getFolderId(domain, fileName)}/children`;
    case "getChildItem":
      //return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(domain)}/drives/${driveId}/items/${Key}/children?expand=listItem($expand=fields)`;
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/root/children?expand=listItem($expand=fields)`;
    case "uploadNew":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/drives/${driveId}/items/${Key}/children`;
    case "createLink":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/items/${FileId}/createLink`;
    case "getSps":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/root/children?expand=listItem($expand=fields)`;
    case "createDrive":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists`;
    case "uploadFile":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/root:/${fileName}:/content?expand=listItem($expand=fields)`;

    case "uploadMetadata":
      // Here fileName is a itemId
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/items/${fileName}/fields`;

    case "uploadFileSession":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/drive/root:/${driveId}/${fileName}:/createUploadSession`;
    case "deleteFile":
      // Here fileName is a itemId
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/${Key}/items/${fileName}`;
    case "getDriveList":
      // Here fileName is a itemId
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/drives`;
    case "deleteFileByDrive":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/drives/${getDriveId(Key)}/root:/${fileName}`;
    case "aboutUsDetails":
      return `https://graph.microsoft.com/v1.0/sites/${getCurrentEnvironmentSiteId(
        domain
      )}/lists/About/Items?expand=listItem($expand=fields)`;
  }
}

function getDriveId(Key) {
  try {
    if (SPSDrive && SPSDrive.value) {
      return SPSDrive.value.filter((data) => {
        return data.name === Key;
      })[0].id;
    }
  } catch (e) {}
}

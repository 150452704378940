import {
  TableHead,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Box,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import TablePagination from "@material-ui/core/TablePagination";

import { apiBatchRequest, apiBatchRequestWithoutLoader, apiRequestNoLoadder, CancelRequest, RenewCancelToken } from "../../utils/api_service";
import { API_URL } from "../../constants/api";
import commonLabels from "../../constants/messageConstants";
import { ShowToast, toastType } from "../../utils/toast-service";

import Reports from "./Reports";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "./report.less";
import DateFnsUtils from "@date-io/date-fns";
import { DateFormat, SECTIONS } from "../../constants/appConstants";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageHeader from "../common/PageHeader";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  generatePDFFromHtml,
  getCurrentPayCycleEndDateByDate,
  getCurrentPayCycleStartDateByDate,
  removeDuplicates,
  calculateAvgerage,
  getTotalCount,
} from "../../utils/helper";

import camelcaseKeys from "camelcase-keys";
import AdvancedFilter from "./AdvancedFilter";
import { downloadExcelReportByAPI, getMultilevelGroupedData } from "../../utils/generic";
import { getFormattedValuesForReports } from "../../utils/formatData";
import ReportButtons from "../../utils/ReportButtons";
import { visuallyHidden } from '@material-ui/utils';
import { Icon } from "@iconify/react";
import bxFilterAlt from "@iconify/icons-bx/bx-filter-alt";
import filterIcon from "@iconify/icons-ci/filter";
import SearchBar from "../common/AutoSearch/SearchBar";
const uploadColumns = [
  {
    key: "buildingClass",
    name: "BuildingClass",
  },
  {
    key: "market",
    name: "Department",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "buildingOwner",
    name: "BuildingOwner",
  },
  {
    key: "subMarket",
    name: "SubMarket",
  },
  {
    key: "buildingName",
    name: "buildingName",
  },
  {
    key: "floorplate",
    name: "Hire date",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
const headCells = [
  {
    id: 'buildingOwner',
    numeric: false,
    disablePadding: false, //JT
    label: 'Company',
  },
  {
    id: 'buildingName',
    numeric: false,
    disablePadding: false, //JT
    label: 'Property Name',
  },
  {
    id: 'monthRecorded',
    numeric: false,
    disablePadding: false, //JT
    label: 'Month Recorded',
  },
  {
    id: 'floorplate',
    numeric: true, //JT
    disablePadding: false,
    label: 'Floorplate',
  },
  {
    id: 'totalRsf',
    numeric: true, //JT
    disablePadding: false,
    label: 'TotalRSF',
  },
  {
    id: 'leasedRsf',
    numeric: true, //JT
    disablePadding: false,
    label: 'LeasedRSF',
  },
  {
    id: 'vacancyPercent',
    numeric: true, //JT
    disablePadding: false,
    label: 'Vacancy',
  },
];
export default class VaccancyReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId:this.props.globalMarketId,
      showMain: false,
      enableFilterButton:false,
      openFilter: false,
      vaccancyData: [],
      employeeSummary: [],
      startDate: moment(this.props.monthRecorded).subtract(4, "month").format(DateFormat),
      endDate: moment(this.props.monthRecorded).format(DateFormat),
      showReport: false,
      getMarketMaster: [],
      companyMasterDetails: [],
      getSubMarketMaster: [],
      getBuildingMaster: [],
      market: [],
      subMarket: [],
      buildingName: [],
      companyName: [],
      openSlidingPanel: false,
      isTotal: false,
      searchInput: '',
      responseVaccancyData :[],
      rowsPerPage: 100,
      rowsPerPageOptions: [100, 200, 300, 400, 500],
      page: 0,
      order:'asc',
      orderBy:'nam',
      topvalue:68,
      selected:[],
      dense:false,
      loading:false,
      advanceFilterConfig: {
        groupBy: {
          masterData: [
            //  { key: 'Market', value: 'market'},
            { key: "Stream Submarket", value: "submarket" , id: 'submarket',
            numeric: false,
            disablePadding: false,
            label: 'Stream Submarket',},
            // { key: 'Company', value: 'buildingOwner'},
            { key: "Class", value: "buildingClass" , id: 'buildingClass',
            numeric: false,
            disablePadding: false,
            label: 'Class',},
          ],
          selected: [
            { key: "Stream Market", value: "market" },
            //  { key: 'Sub Market', value: 'submarket'}
          ],
        },
        selectedDate: {},
        suppressGraph: false,
        suppressZero: true,
        summaryRows: false,
        showZeroNGraph: true,
      },
      summaryCols: ["floorplate", "totalRsf", "leasedRsf", "vacancyPercent"],
    };

    this.baseState = this.state;
  }
   handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({
      order:isAsc ? 'desc' : 'asc',
      orderBy:property,
    });
    // setOrder(isAsc ? 'desc' : 'asc');
    // setOrderBy(property);
  };
  componentWillReceiveProps(nextProps) {    
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {    
      this.setState({selectedMarketId: nextProps.globalMarketId });
      this.GetMasterData(nextProps.globalMarketId); 		  
		}
	  }
  componentDidMount() {
    RenewCancelToken();
    //this.props.setNewMarketId --todo(this.GetMasterData);
    this.GetMasterData(this.state.selectedMarketId);
  }
  componentWillUnmount()
  {
    CancelRequest();
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.forceUpdate();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  
 descendingComparator (a, b, orderBy) {
  // if(isNaN(a[orderBy]) && isNaN(b[orderBy]))
  if (typeof a[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    const lowerCaseB = b[orderBy] != undefined ? b[orderBy].toString().toLowerCase() : b[orderBy];
    const lowerCaseA = a[orderBy] != undefined ? a[orderBy].toString().toLowerCase() : a[orderBy];
    if (lowerCaseB < lowerCaseA) {
      return -1;
    }
    if (lowerCaseB > lowerCaseA) {
      return 1;
    }
  } else if (!isNaN(b[orderBy]) && !isNaN(a[orderBy])) {
    const numA = Number(a[orderBy]).toFixed(2);
    const numB = Number(b[orderBy]).toFixed(2);
    if (Number(numB) < Number(numA)) {
      return -1;
    }
    if (Number(numB) > Number(numA)) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}
   getComparator (order, orderBy) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  }
  
   stableSort (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  goBack = () => {
    this.setState({
      showMain: true,
    });
  };

  handleDateChange = (date, name) => {
    if (name === "startDate") {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    } else {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    }
  };

  GetMasterData = (selectedMarketId) => {
    
    
    this.getReport(selectedMarketId);    
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1 ,marketId:selectedMarketId},
        fromCache: true,
				duration: 60
      },
      // {
      //   url: API_URL.Master.companyMasterDetails,
      //   key: "companyMasterDetails",
      //   params: { businessLineId: 1 },
      //   fromCache: true,
			// 	duration: 60
      // },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1,marketId:selectedMarketId },
        fromCache: true,
				duration: 60
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 1 ,marketId:selectedMarketId},
        fromCache: true,
				duration: 60
      },
    ])
      .then(
        ({
          getMarketMaster,
          getSubMarketMaster,
          getBuildingMaster,
          //companyMasterDetails,
        }) => {
          this._asyncRequest = null;
          if (getMarketMaster.success !== true) {
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
          this.setState(
            {
              getMarketMaster: camelcaseKeys(getMarketMaster.data) || [],
              getSubMarketMaster: camelcaseKeys(getSubMarketMaster.data) || [],
              getBuildingMaster: camelcaseKeys(getBuildingMaster.data) || [],
              // companyMasterDetails:
              //   camelcaseKeys(companyMasterDetails.data) || [],
                enableFilterButton:true,
            },           
          );
        }
      )
      .catch(() => {
        this._asyncRequest = null;
      });
      
  };

  getReport = (marketId) => {
    const { market, buildingName, subMarket, companyName } = this.state;
    //const marketId = this.state.selectedMarketId ;//_.cloneDeep(market.map((x) => x.marketId));
    const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = companyName != null && companyName != undefined ? companyName.companyId : -1;//_.cloneDeep(companyName.map((x) => x.companyId));

    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const params = {
      startDate: moment(startDate).format("YYYY-MM-01"),
      endDate: moment(endDate).format("YYYY-MM-01"),
      marketId: marketId,//.join("|"),
      buildingId: buildingId.join("|"),
      subMarketId: subMarketId.join("|"),
      companyId: companyId,//.join("|"),
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.propertyVacancyReport,
        params,
        key: "vaccancyData",
      },
    ])
      .then(({ vaccancyData }) => {
        this._asyncRequest = null;
        if (vaccancyData.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          vaccancyData: camelcaseKeys(vaccancyData.data || []),
          responseVaccancyData:camelcaseKeys(vaccancyData.data || []),
          showReport: true,
          page:0
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }; 

  renderTable = (vaccancyData) => {
    const groupSequence = this.getColumnSequence();
    return (
      <Table className="reportTable" id="rptTable" stickyHeader aria-label="sticky table">
        <EnhancedTableHead
              numSelected={this.state.selected.length}
              order={this.state.order}
              orderBy={this.state.orderBy}            
              onRequestSort={this.handleRequestSort}
              rowCount={vaccancyData.length}
              columnSequence={groupSequence}
            />      
        <TableBody>          
          {   
         
       vaccancyData.length == 0 ?( <TableRow >
<TableCell><span>No Data Available</span></TableCell></TableRow>
        ):
            (this.renderTableRow(  (
              this.state.rowsPerPage > 0 ? this.stableSort(vaccancyData || [], this.getComparator(this.state.order, this.state.orderBy)).
            slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage) : vaccancyData
              )
           ))
            
          }
          
  
            
         </TableBody>
      </Table>
    );
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };

  resetPage = () => {
    this.setState({...this.baseState},this.componentDidMount);
    //this.componentDidMount();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    this.getReport(this.state.selectedMarketId);
  };

  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  handleChange = (e, value, name) => {
    this.setState({ [name]: value });
  };

  onAddvanceFilterChange = (advanceFilterConfig) => {
    this.setIsTotal();
    this.setState(
      {
        advanceFilterConfig: advanceFilterConfig,
        openSlidingPanel: false,
        //startDate: getCurrentPayCycleStartDateByDate(this.state.advanceFilterConfig.startDate),
        //endDate: getCurrentPayCycleEndDateByDate(this.state.advanceFilterConfig.end)
      },
      () => {
        this.getReport(this.state.selectedMarketId);
      }
    );
  };

  getColumnSequence = () => {
    return removeDuplicates(
      [
        ...this.state.advanceFilterConfig.groupBy.selected,
        ...this.state.advanceFilterConfig.groupBy.masterData,
      ],
      "key"
    );
  };

  adjustColumnSequence = (currentGroupedRow) => {
    this.state.advanceFilterConfig.groupBy.masterData.forEach((x) => {
      currentGroupedRow.push({ key: x.value });
    });
    return currentGroupedRow;
  };

  renderTableRow = (data, onlydata = false) => {
    return this.renderTableCell(
      getMultilevelGroupedData(
        data,
        this.state.advanceFilterConfig.groupBy.selected.map((x) => x.value),
        this.state.isTotal,
        this.state.summaryCols
      ),
      onlydata
    );
  };

  exportPDF = () => {
	const { market, buildingName, subMarket, companyName } = this.state;
	const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = companyName != null && companyName != undefined ? companyName.companyId : -1;//_.cloneDeep(companyName.map((x) => x.companyId));

	let startDate = moment(this.state.startDate).format("MM/01/YYYY");
    let endDate = moment(this.state.endDate).format("MM/01/YYYY");

	let SelectedData = [];
	if (buildingId && buildingId.length) {
		let temp = buildingId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			buildingId: temp[i],
			buildingName: "",
			buildingLeavingId: 0,
			buildingLeavingName: ""
		  });
		}
		let propObj = {
		  key: "buildingName",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  if (subMarketId && subMarketId.length) {
		let temp = subMarketId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			subMarketId: temp[i],
			subMarket: "",
			market: "",
			marketId: ""
		  });
		}
		let propObj = {
		  key: "subMarket",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	
	  if (companyId ) {//&& companyId.length
		let temp = companyId;
		let selectedProps = [];
	//	for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			companyId: temp != null && temp != undefined ? temp :-1,
			businessLineId: 1,
			companyName: ""
		  });
	//	}
		let propObj = {
		  key: "companyName",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	  
	  if (startDate != null && startDate != undefined) {
		let temp = startDate;
		let selectedProps = [];
		  selectedProps.push({
			startDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "startDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  if (endDate != null && endDate != undefined) {
		let temp = endDate;
		let selectedProps = [];
		  selectedProps.push({
			endDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "endDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  downloadExcelReportByAPI(
		API_URL.SSRSExport.VacancyReport,
		"VacancyReportExport.pdf",
		SelectedData,
		this.state.selectedMarketId,
		"application/pdf",
		"report"
	  );  
  };

  downloadReport = () => {
	const { market, buildingName, subMarket, companyName } = this.state;
	const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = companyName != null && companyName != undefined ? companyName.companyId : -1;//_.cloneDeep(companyName.map((x) => x.companyId));

	let startDate = moment(this.state.startDate).format("MM/01/YYYY");
    let endDate = moment(this.state.endDate).format("MM/01/YYYY");

	let SelectedData = [];
	if (buildingId && buildingId.length) {
		let temp = buildingId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			buildingId: temp[i],
			buildingName: "",
			buildingLeavingId: 0,
			buildingLeavingName: ""
		  });
		}
		let propObj = {
		  key: "buildingName",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  if (subMarketId && subMarketId.length) {
		let temp = subMarketId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			subMarketId: temp[i],
			subMarket: "",
			market: "",
			marketId: ""
		  });
		}
		let propObj = {
		  key: "subMarket",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	
	  if (companyId ) {//&& companyId.length
		let temp = companyId;
		let selectedProps = [];
		//for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			companyId: temp != null && temp != undefined ? temp :-1,
			businessLineId: 1,
			companyName: ""
		  });
		//}
		let propObj = {
		  key: "companyName",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	  
	  if (startDate != null && startDate != undefined) {
		let temp = startDate;
		let selectedProps = [];
		  selectedProps.push({
			startDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "startDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  if (endDate != null && endDate != undefined) {
		let temp = endDate;
		let selectedProps = [];
		  selectedProps.push({
			endDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "endDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

    downloadExcelReportByAPI(
		API_URL.SSRSExport.VacancyReport,
      "VacancyReportExport.xls",
      SelectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "report"
    );
  };

  renderTableCell = (
    groupedData,
    onlydata,
    currentGroupedRow = [],
    tableCell = [],
    rowCount = 0
  ) => {
    const{page,rowsPerPage}=this.state;
    let column1 = "";
    let column2 = "";
    let column3 = "";
    // let column4 = '';
    // let column5 = '';
    (
      // this.state.rowsPerPage > 0 &&
      //  this.stableSort(groupedData || [], this.getComparator(this.state.order, this.state.orderBy)).
      // slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)     
      groupedData.map((d,i) => 
    // (row.forEach((d, i) =>
     {
      if (d.data !== undefined) {
        currentGroupedRow = currentGroupedRow.filter((x) => x.key !== d.key);
        currentGroupedRow.push({ key: d.key, value: d[d.key] });
        
        this.renderTableCell(
          d.data,
          onlydata,
          currentGroupedRow,
          tableCell,
          rowCount
        );
      }
      const keyvalue = this.adjustColumnSequence(
        _.cloneDeep(currentGroupedRow)
      );
      const sequence = this.getColumnSequence();
      const evenOddClass =
        rowCount % 2 === 0 ? " rpt rpt-even " : " rpt rpt-odd ";
      const topBorderClass =
        column1 !==
          (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? ""
          ? " rpt-topBorder "
          : " ";

      const key = d.market + d.submarket + "_" + rowCount + "_" + i;

      if (d.data && d.summaryData != undefined) {
        if (
          d.summaryData.length > 0 &&
          (d.summaryData[0].isSummary === undefined ||
            (d.summaryData.length > 0 &&
              d.summaryData[0].isSummary === true &&
              this.state.advanceFilterConfig.summaryRows === true))
        ) {
          tableCell.push(
           
            <TableRow
              key={key}
              className={
                topBorderClass + (d.isGroup === true ? " parent-row" : "")
              }
            >
              <TableCell
                className={"st sticky " + evenOddClass + topBorderClass}
              >
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky-1 " +
                  evenOddClass +
                  (column2 !== d[sequence[1].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column2 !== d[sequence[1].value] && d[sequence[1].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky-2 " +
                  evenOddClass +
                  (column3 !== d[sequence[2].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column3 !== d[sequence[2].value] && d[sequence[2].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky rpt-topBorder " + evenOddClass + topBorderClass
                }
              >
                {" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky rpt-topBorder " + evenOddClass + topBorderClass
                }
              >
                {" "}
              </TableCell>
              {d.summaryData.map((m, ind) =>
                this.renderSummaryColumn(
                  d.summaryData,
                  m,
                  key + ind,
                  evenOddClass,
                  d.data.length,
                  d.data
                )
              )}
            </TableRow>
          );
        }
      }
      if (d.data === undefined) {
        if (onlydata) {
          const rowData = {};
          rowData[sequence[0].value] = getFormattedValuesForReports(
            column1 !== d[sequence[0].value] ? d[sequence[0].value] : "",
            "Text",
            true
          );
          rowData[sequence[1].value] = getFormattedValuesForReports(
            column2 !== d[sequence[1].value] ? d[sequence[1].value] : "",
            "Text",
            true
          );
          rowData[sequence[2].value] = getFormattedValuesForReports(
            column3 !== d[sequence[2].value] ? d[sequence[2].value] : "",
            "Text",
            true
          );
          // rowData[sequence[3].value] = getFormattedValuesForReports(column4 !== d[sequence[3].value] ? d[sequence[3].value] : '', 'Text', true);
          //rowData[sequence[4].value] = getFormattedValuesForReports(column5 !== d[sequence[4].value] ? d[sequence[4].value] : '', 'Text', true);
          //rowData.submarket = d.submarket;
          rowData.buildingOwner = d.buildingOwner;
          //rowData.buildingClass = d.buildingClass;
          rowData.buildingName = d.buildingName;
          rowData.monthRecorded=d.monthRecorded;
          rowData.floorplate = d.floorplate;
          rowData.leasedRsf = d.leasedRsf;
          rowData.totalRsf = d.totalRsf;
          rowData.vacancyPercent = d.vacancyPercent;
          tableCell.push(rowData);
        } else {
          tableCell.push(
            
            <TableRow key={key} className={topBorderClass}>
              <TableCell className={evenOddClass + topBorderClass}>
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  evenOddClass +
                  (column2 !== d[sequence[1].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column2 !== d[sequence[1].value] && d[sequence[1].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  evenOddClass +
                  (column3 !== d[sequence[2].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column3 !== d[sequence[2].value] && d[sequence[2].value],
                  "Text"
                )}{" "}
              </TableCell>
              {/* <TableCell
                className={
                  evenOddClass +
                  (column4 !== d[sequence[3].value]
                    ? ' rpt-topBorder '
                    : ' ')
                }
              >
                {getFormattedValuesForReports(column4 !== d[sequence[3].value] && d[sequence[3].value], 'Text')}{' '}
              </TableCell> */}
              {/*  <TableCell
                className={
                  evenOddClass +
                  (column5 !== d[sequence[4].value]
                    ? ' rpt-topBorder '
                    : ' ')
                }
              >
                {getFormattedValuesForReports(column5 !== d[sequence[4].value] && d[sequence[4].value], 'Text')}{' '}
              </TableCell> */}
              {/* <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.submarket === null
                  ? "NA"
                  : getFormattedValuesForReports(d.submarket, "Text")}
              </TableCell> */}
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingOwner === null
                  ? "NA"
                  : getFormattedValuesForReports(d.buildingOwner, "Text")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingName === null ? "NA" : d.buildingName}
              </TableCell>
              <TableCell
              className={evenOddClass + 'rpt-topBorder'}
            >
              {d.monthRecorded === null
                ? 'NA'
                : getFormattedValuesForReports(d.monthRecorded, 'Date')}
            </TableCell>

              {/* <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingOwner === null
                  ? "NA"
                  : getFormattedValuesForReports(d.buildingOwner, "Text")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingClass === null
                  ? "NA"
                  : getFormattedValuesForReports(d.buildingClass, "Text")}
              </TableCell> */}

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                { getFormattedValuesForReports(d.floorplate, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.totalRsf, "Number")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                { getFormattedValuesForReports(d.leasedRsf, "Number")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {
                  // d.vacancy === null
                  //   ? "NA"
                  //   :
                  getFormattedValuesForReports(d.vacancyPercent, "Percent")
                }
              </TableCell>
            </TableRow>
          );
        }
      }
      rowCount++;
      column1 =
        (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? "";
      column2 =
        (keyvalue.find((x) => x.key === sequence[1].value) || {}).value ?? "";
      column3 =
        (keyvalue.find((x) => x.key === sequence[2].value) || {}).value ?? "";
      // column4 = (keyvalue.find(x => x.key === sequence[3].value) || {}).value ?? '';
      // column5 = (keyvalue.find(x => x.key === sequence[4].value) || {}).value ?? '';
    }
    ));
    //));
    return tableCell;
  };

  renderSummaryColumn = (summaryDetails, n, index, evenOddClass,count,data) => {
    const summaryDetail = summaryDetails.filter((x) => x === n);
    const tableCell = [];
    //  summaryDetail.forEach(m=>{
    tableCell.push(
      <>
       <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "monthRecorded"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "monthRecorded" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.monthRecorded === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.monthRecorded, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "floorPlate"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "floorPlate" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.floorPlate === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.floorPlate, "Number")
              )}
            </div>
          ))}
        </TableCell>

        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "totalRsf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "totalRsf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.totalRsf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.totalRsf, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "leasedRsf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "leasedRsf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.leasedRsf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.leasedRsf, "Number")
              )}
            </div>
          ))}
        </TableCell>

        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "vacancyPercent"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "vacancyPercent" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.vacancyPercent === null ? (
                <span className="number-text">-</span>
              ) : (                

                getFormattedValuesForReports(calculateAvgerage(n.vacancyPercent,getTotalCount(data,count,'vacancyPercent')), "Percent")
              )}
            </div>
          ))}
        </TableCell>
      </>
    );
    //});

    return tableCell;
  };
  setIsTotal = () => {
    this.setState({ isTotal: true });
  };

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let searchData = this.state.responseVaccancyData;
    let filteredData = searchData.filter((value) => {
    //  if (
    //    value.market != null &&
    //    value.buildingClass != null &&
    //    value.buildingName != null &&
    //    value.monthRecorded != null &&
    //    value.buildingOwner != null &&
    //    value.floorplate != null &&
    //    value.vacancy != null &&
    //    value.submarket != null &&
    //    value.totalRsf != null &&
    //    value.leasedRsf != null
    //  ) {
        return (
			(value.market ? value.market.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
			(value.submarket ? value.submarket.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
			(value.buildingClass ? value.buildingClass
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.buildingOwner ? value.buildingOwner.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
			(value.buildingName ? value.buildingName
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.monthRecorded ? value.monthRecorded
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.floorplate ? value.floorplate
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.totalRsf ? value.totalRsf
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.leasedRsf ? value.leasedRsf
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) || 
			(value.vacancyPercent ? value.vacancyPercent
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false)
        );
    //  }
    });	

    if (searchInput.length == 0)
      this.setState({ vaccancyData: this.state.responseVaccancyData });
    else this.setState({ vaccancyData: filteredData,page:0 });
  };

  onInputChange=(e,value)=>
	{
    this.setState({
      loading:true
    });
		const params={			
			businessLineId :1,
			searchText:value
		  };
		apiRequestNoLoadder(
			{
				url: API_URL.Master.companyMasterDetails,
				params
			  }
		).then(({data:companyMasterDetails}) => {				
				this.setState({
					companyMasterDetails:
					camelcaseKeys(companyMasterDetails) || [],
          loading:false
				},()=>{this.forceUpdate()});			
			})
			.catch((reason) => {
			  this._asyncRequest = null;
			  ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
			});
		
	}
  render() {
    const {
      loading,
      showMain,
      openFilter,
      vaccancyData,
      startDate,
      endDate,
      getMarketMaster,
      getSubMarketMaster,
      companyMasterDetails,
      getBuildingMaster,
      showReport,
      advanceFilterConfig,
      openSlidingPanel,
      isTotal,
      searchInput,
      rowsPerPageOptions, rowsPerPage, page
    } = this.state;

    return (
     
      <div style={{marginTop:`${this.props.marginTop}px`}}>
        {showMain ? (
          <Reports globalMarketId={this.state.selectedMarketId}></Reports>
        ) : (
          <>
            <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-6">
                {/* <PageHeader
                isReportPage={true}
                  key={"Vacancy_Report"}
                  btnText={"Reports"}
                  onBackClick={this.goBack}
                  title={"/ Vacancy"}
                  style={{ color: "#00B0B9" }}                
                >
                  Vaccancy Report
                </PageHeader> */}
              </div>
              <div className="col-sm-5 col-md-5 col-lg-4">
            <SearchBar
                      size="large"
                      name="searchInput"
                      value={searchInput || ""}
                      onChange={this.handleSearch}
                      label="Search"
                      placeholder="Search Company,market,etc."
                    ></SearchBar>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-2">
                <AdvancedFilter
                  filterConfig={advanceFilterConfig}
                  onApplyFilter={this.onAddvanceFilterChange}
                  openSlidingPanel={openSlidingPanel}
                  handleClose={this.handleClose}
                />
                <ReportButtons
                  resetPage={this.resetPage}
                  handleSubmit={this.handleSubmit}
                  handleClickOpen={this.handleClickOpen}
                  handlePrintClick={this.handlePrintClick}
                  exportPDF={this.exportPDF}
				  downloadExcelReport={this.downloadReport}
                  data={vaccancyData}
                  columnWidths={columnWidths}
                  uploadColumns={uploadColumns}
                  openFilter={this.state.openFilter}
                  enableFilter={this.enableFilter}
                  isReportPage={true}
                  isListingPage={false}
                  fileName={"VaccancyReport.xlsx"}
                  enableFilterButton={this.state.enableFilterButton}
				  ParentSection={SECTIONS.Html_Reports}
				  ChildSectionForDownload={SECTIONS.Html_Reports_Download}
                />
              </div>
            </div>
            {getMarketMaster.length > 0 && openFilter == true && (
              <div style={{ width : "98%" }}>
              <Grid
                container
                spacing={2}
                className="no-print row bottom-xs report-filter"
              >
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    value={this.state.market}
                    onChange={(e, val) => this.handleChange(e, val, "market")}
                    id="tags-standard"
                    options={getMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.market}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Stream Market
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Market"
                        />
                      </Box>
                    )}
                  />
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.buildingName}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "buildingName")
                    }
                    options={getBuildingMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.buildingName}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Property Name
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Property Name"
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "subMarket")
                    }
                    options={getSubMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.subMarket}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Stream Submarket
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Submarket"
                        />
                      </Box>
                    )}
                  />
                  <Autocomplete
                  freeSolo
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple={false}
                    id="tags-standard"
                    value={this.state.companyName}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "companyName")
                    }
                    onInputChange={(event, value) => {                     
                      event && (event.type == 'change' || event.type == 'input' ) && value != "" && value != null && value.length > 2 ? this.onInputChange(event, value):null;
       
                     }}
                     loading={loading}
                    options={companyMasterDetails}
                    limitTags={2}
                    getOptionLabel={(option) => option.companyName}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Company
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Enter atlest 3 character to search and then select"
                          
							InputProps={{
								...params.InputProps,
								endAdornment: (
								  <React.Fragment>
									{ loading ? <CircularProgress  size={20} /> : null}
									{params.InputProps.endAdornment}
								  </React.Fragment>
								),
							  }}
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                   
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      className="filter-label"
                    >
                      <label htmlFor="filled-hidden-label-small">
                        Start Date
                      </label>
                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                       */}
                      <Grid container>
                        <KeyboardDatePicker
                          style={{
                            border: "1px solid #cecdcd",
                            borderRadius: "4px",
                          }}
                          // variant="inline"
                          views={["year", "month"]}
                          margin="normal"
                          id="date-picker-dialog"
                          //label="Start Date"
                          variant="inline"
                          autoOk
                          format="MM/01/yyyy"
                          maxDate={endDate}
                          name="startDate"
                          value={startDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "startDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          className="w-100"
                        />
                      </Grid>
                    </Box>
                    {/* </Box> */}
                  </MuiPickersUtilsProvider>
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className="filter-label"
                  >
                    <label htmlFor="filled-hidden-label-small">End Date</label>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container>
                        <KeyboardDatePicker
                          style={{
                            border: "1px solid #cecdcd",
                            borderRadius: "4px",
                          }}
                          views={["year", "month"]}
                          margin="normal"
                          id="date-picker-dialog"
                          // label="End Date"
                          autoOk
                          format="MM/01/yyyy"
                          minDate={startDate}
                          name="endDate"
                          variant="inline"
                          value={endDate}
                          onChange={(e) => this.handleDateChange(e, "endDate")}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          className="w-100"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={3}
                   
                >
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => this.handleSubmit()}
                  >
                    <span class="MuiButton-label">
                       <span class="tool-icon-text">FILTER REPORT</span>
                    </span>

                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </Grid>
              </Grid>
              </div>
            )}
            {showReport && vaccancyData == null && (
              <Grid container spacing={4}>
                <Grid
                  container
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                >
                  <label className="item-big">No Data Available.</label>
                </Grid>
              </Grid>
            )}
            {showReport && (
              <>
                {vaccancyData !== null && (
                  <div className="container" style={{ marginTop: "1rem" }}>
                     <TablePagination
              className="pager no-print"
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={vaccancyData.length }
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
                    <TableContainer className="report-table-scroll" component={Paper}>
                      {this.renderTable(vaccancyData)}
                    </TableContainer>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const groupSequence = props.columnSequence;
  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
{/* 
<TableRow>
            {groupSequence.map((col, index) => (
              <TableCell key={"th" + col.value + index}
               sortDirection={orderBy === col.key ? order : false}>{col.key}</TableCell>
            ))}
          
            <TableCell >Company</TableCell>
            <TableCell>Property Name</TableCell>
          
            <TableCell style={{ textAlign: "revert" }}>Floor Plate</TableCell>
            <TableCell style={{ textAlign: "revert" }}>TotalRSF</TableCell>
            <TableCell style={{ textAlign: "revert" }}>LeasedRSF</TableCell>
            <TableCell style={{ textAlign: "revert" }}>Vacancy %</TableCell>
          </TableRow> */}
 {groupSequence.map((col, index) => (
              <TableCell key={"th" + col.value + index}
              align={col.numeric ? 'right' : 'left'}
              padding={col.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === col.id ? order : false}
              >
                {/* {col.key} */}
               <TableSortLabel
             active={orderBy === col.id}
             direction={orderBy === col.id ? order : 'asc'}
             onClick={createSortHandler(col.id)}
           >
             {col.key}
             {orderBy === col.id ? (
               <Box component="span" sx={visuallyHidden}>
                 {order === 'desc' ? '' : ''}
               </Box>
             ) : null}
           </TableSortLabel>
              </TableCell>
            ))}
        {headCells.map((headCell) => (
           <TableCell
           key={headCell.id}
           align={headCell.numeric ? 'right' : 'left'}
           padding={headCell.disablePadding ? 'none' : 'normal'}
           sortDirection={orderBy === headCell.id ? order : false}
         >
           <TableSortLabel
             active={orderBy === headCell.id}
             direction={orderBy === headCell.id ? order : 'asc'}
             onClick={createSortHandler(headCell.id)}
           >
             {headCell.label}
             {orderBy === headCell.id ? (
               <Box component="span" sx={visuallyHidden}>
                 {order === 'desc' ? '' : ''}
               </Box>
             ) : null}
           </TableSortLabel>
         </TableCell>
          // <TableCell
          //   key={headCell.key}
          //   align={headCell.numeric ? 'right' : 'left'}
          //   padding={headCell.disablePadding ? 'none' : 'normal'}
          //   sortDirection={orderBy === headCell.key ? order : false}
          // >
          //   <TableSortLabel
          //     active={orderBy === headCell.key}
          //     direction={orderBy === headCell.key ? order : 'asc'}
          //     onClick={createSortHandler(headCell.key)}
          //   >
          //     {headCell.label}
          //     {orderBy === headCell.key ? (
          //       <Box component="span" sx={visuallyHidden}>
          //         {order === 'desc' ? '' : ''}
          //       </Box>
          //     ) : null}
          //   </TableSortLabel>
          // </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnSequence: PropTypes.array,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

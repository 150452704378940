export function downloadFile (url, filename) {
  fetch(url).then(response => {
    response.blob().then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      a.click()
    })
    // window.location.href = response.url;
  })
}

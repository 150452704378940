import React from 'react';
import PageHeader from '../common/PageHeader';
export default class MasterCompsIndustrial extends React.PureComponent
{
constructor(props)
{
    super(props);
    this.state = {
        
}

}

componentDidMount () {
  }


render()
{
    
    return(<>
     <PageHeader title="MasterCompsIndustrial"></PageHeader>

   
    </>);
}
}
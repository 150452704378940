import React from "react";
import "../common/react-table/style.less";
import {
  apiBatchRequestWithoutLoader,
  apiRequest,
} from "../../utils/api_service";
import { API_URL } from "../../constants/api";
import PageHeader from "../common/PageHeader";
import "./index.less";
import {
  convertIntoDDFormat,
  getAllKeysValue,
  scrollToTop,
} from "../../utils/generic";
import camelcaseKeys from "camelcase-keys";
import { ShowToast } from "../../utils/toast-service";
import AutocompleteMultiSelect from "../common/AutocompleteMultiselect/autocomplete-multiselect";
import FundWisePerformance from "./FundWisePerformance";
import { AssetTableColumns } from "../../constants/MockData";
import { downloadExcelFile, IsSectionVisible, removeDuplicates } from "../../utils/helper";
import { Button, Chip, Paper } from "@material-ui/core";
import SearchBar from "../common/AutoSearch/SearchBar";

export default class SpecSuite extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      specPropertyList: [],
      ddMasterData: {},
      marketMasterData: [],
      subMarketMasterData: [],
      industryMasterData: [],
      percentageOfficevsMaster: [],
      priorPremisesMaster: [],
      constructionTypeMaster: [],
      relocationReasonMaster: [],
      downtimeMaster: [],
      nnnPerFSMaster: [],
      compTypeMaster: [],
      marketInfo: "",
      subMarketInfo: "",
      propertyInfo: "",
      uploadingCount: [],
      isSelectAllMarket: false,
      getMarketMaster: [],
      getSubMarketMaster: [],
      getBuildingMaster: [],
      allMarket: [],
      searchInput: "",
	  selectedData: [],
	  defaultSelectedData: [],
	  filteredTitle: [],
    };
  }

  componentWillMount() {
    this.getMasterData(this.state.selectedMarketId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getMasterData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  getAllPropertyList = () => {
    const { marketInfo } = this.state;
    if (!marketInfo || (marketInfo && marketInfo.length < 1)) {
      this.setState({ specPropertyList: [] });
      return;
    }
    this.setState({ specPropertyList: [] });
    const mIds = getAllKeysValue(marketInfo, "id");
    apiRequest({
      url: API_URL.SpecSuites.getSpecProperty,
      params: { marketIds: mIds.toString() },
    }).then((data) => {
      if (data) {
        this.setState({ specPropertyList: data.data });
      } else {
        this.setState({ specPropertyList: [] });
      }
    });
  };

  getMasterData = (selectedMarketId) => {
    this._asyncRequest = apiBatchRequestWithoutLoader([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1 },
      },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1 },
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 1 },
      },
    ])
      .then((getMarketMaster) => {
        this._asyncRequest = null;
        if (getMarketMaster.getMarketMaster.success == true) {
          this.setState({
            getMarketMaster:
              //camelcaseKeys(getMarketMaster.getMarketMaster.data) || [],
              convertIntoDDFormat(
                camelcaseKeys(getMarketMaster.getMarketMaster.data),
                "marketId",
                "market"
              ),
            getSubMarketMaster:
              //camelcaseKeys(getMarketMaster.getSubMarketMaster.data) || [],
              convertIntoDDFormat(
                camelcaseKeys(getMarketMaster.getSubMarketMaster.data),
                "subMarketId",
                "subMarket",
                "marketId"
              ),
            getBuildingMaster: convertIntoDDFormat(
              camelcaseKeys(getMarketMaster.getBuildingMaster.data),
              "buildingId",
              "buildingName"
            ),
          });
        } else {
          ShowToast(
            getMarketMaster.getMarketMaster.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    //apiRequest({ url: API_URL.SpecSuites.getDDMaster }).then((data) => {
    //	if (data) {
    //		this.setState({
    //	  		ddMasterData: {
    //				cityDetails: convertIntoDDFormat(
    //		  			data.data.cityDetails,
    //		  			"cityId",
    //		  			"cityName",
    //		  			"stateId"
    //				),
    //				marketDetails: convertIntoDDFormat(
    //		  			data.data.marketDetails,
    //		  			"marketId",
    //		  			"marketName"
    //				),
    //				stateDetails: convertIntoDDFormat(
    //		  			data.data.stateDetails,
    //		  			"stateId",
    //		  			"stateName"
    //				),
    //	  		},
    //		});
    //  	}
    //});

    // All Master Data
    apiRequest({ url: API_URL.SpecSuites.getMasterAllData }).then((data) => {
      if (data) {
        this.setState({
          percentageOfficevsMaster: convertIntoDDFormat(
            data.data.percentageOfficevsOpen,
            "id",
            "value"
          ),
          priorPremisesMaster: convertIntoDDFormat(
            data.data.poorPremisesCondition,
            "id",
            "value"
          ),
          constructionTypeMaster: convertIntoDDFormat(
            data.data.constructionType,
            "id",
            "value"
          ),
          relocationReasonMaster: convertIntoDDFormat(
            data.data.relocationReason,
            "id",
            "value"
          ),
          downtimeMaster: convertIntoDDFormat(
            data.data.downtime,
            "id",
            "value"
          ),
          compTypeMaster: convertIntoDDFormat(
            data.data.compTypeMaster,
            "id",
            "value"
          ),
          nnnPerFSMaster: convertIntoDDFormat(
            data.data.rentType,
            "id",
            "value"
          ),
          industryMasterData: convertIntoDDFormat(
            data.data.industryType,
            "industryTypeId",
            "type"
          ),
        });
      }
    });
  };

  getAllUpdatedProeprties = () => {
    this.setState({ uploadingCount: [...this.state.uploadingCount, 1] });
    this.getAllPropertyList();
  };

  onJumpToPage = (page) => {
    this.props.history.push(page);
  };
  downloadTemplate = () => {
    //downloadExcelFile('src//components//Dashboard//PropertyOwnersExport.xlsx','MarketStats_ImportTemplate');
    downloadExcelFile(
      "public//SpecSuiteTemplate//SampleUpload.xlsx",
      "SampleUpload"
    );
  };

  setDDValues = (p, key) => {
    let SelectedData = JSON.parse(JSON.stringify([...this.state.selectedData]));
	if (key === "marketInfo") {
      let allMarket = [];
      allMarket = p.filter((m) => {
        if (m.value == "All Markets") return m;
      });
      this.setState({ allMarket: allMarket });
    }
    this.setState({ [key]: p && p.length ? p : "" }, () => {
      if (key === "marketInfo") {
        const isAllMarket =
          p && p.length && p[p.length - 1].value === "All Markets";
        this.setState({
          subMarketInfo: "",
          propertyInfo: "",
          isSelectAllMarket: isAllMarket,
        });
		let data = SelectedData.filter((a)=>{if(a.key=="market") return a});
		if(data.length > 0)
			this.setState({selectedData : data}); 
        this.getAllPropertyList();
      } else if (key === "subMarketInfo") {
        this.setState({ propertyInfo: "" });
		let data = SelectedData.filter((a)=>{if(a.key=="submarket" || a.key=="market") return a});
		if(data.length > 0)
			this.setState({selectedData : data});
        // this.getAllPropertyList();
      }
    });

	if (key === "marketInfo") {
		let temp = []; 
		if(p.length == 0){
			for(let i=0; i < SelectedData.length; i++){
				let index = SelectedData.indexOf(SelectedData.find(x=>x.key == "market"));
				if(index !== -1)
				SelectedData.splice(index);
			}
      this.setState({searchInput: ""}); 
		}
		else{
			if(this.state.selectedData.length > 0){
				this.state.selectedData.find((x)=>
				{			
					for(let i=0; i<p.length; i++) {
						if(p[i].value !== x.selected[0][x.key]){
							temp.unshift(p[i])
						} 	
					}
				});
			}
			else{
				temp = p;
			}
			if(p.length != temp.length)
			{
				let diff = temp.length - p.length;
				if(p.length > 1)
					diff = diff + (p.length - 1);
				for(let i=0; i<diff; i++)
					temp.pop();
			}
			let selectedProps = [];
			for (var i = 0; i < temp.length; i++) {
			  selectedProps.push({
				market: temp[i].value
			  });
			}
			let propObj = {
				key: "market",
				type: "Dropdown",
				isComaSeprated: false,
				selected: selectedProps
			}
			SelectedData.push(propObj);
		}
	}

	if (key === "subMarketInfo") {
		let temp = [];
		if(p.length == 0){
			for(let i=0; i < SelectedData.length; i++){
				let index = SelectedData.indexOf(SelectedData.find(x=>x.key == "submarket"));
				if(index !== -1)
				SelectedData.splice(index);
			}
      this.setState({searchInput: ""}); 
		}
		else{
			if(this.state.selectedData.length > 0){
				this.state.selectedData.find((x)=>
				{
					for(let i=0; i<p.length; i++) {
						if(p[i].value !== x.selected[0][x.key]) {
							temp.unshift(p[i]);
						}
						
					}
				});
			}
			else{
				temp = p;
			}
			if(p.length != temp.length)
			{
				let diff = temp.length - p.length;
				if(p.length > 1)
					diff = diff + (p.length - 1);
				for(let i=0; i<diff; i++)
					temp.pop();
			}
			let selectedProps = [];
			for (var i = 0; i < temp.length; i++) {
			  selectedProps.push({
				submarket: temp[i].value
			  });
			}
			let propObj = {
				key: "submarket",
				type: "Dropdown",
				isComaSeprated: false,
				selected: selectedProps
			}
			SelectedData.push(propObj);
		} 
	}

	if (key === "propertyInfo") {
		let temp = []; 
		if(p.length == 0){
			for(let i=0; i < SelectedData.length; i++){
				let index = SelectedData.indexOf(SelectedData.find(x=>x.key == "property"));
				if(index !== -1)
				SelectedData.splice(index);
			}
      this.setState({searchInput: ""}); 
		}
		else{
			if(this.state.selectedData.length > 0){
				this.state.selectedData.find((x)=>
				{
					for(let i=0; i<p.length; i++) {
						if(p[i].propertyName !== x.selected[0][x.key]) {
							temp.unshift(p[i]);
						}
						
					}
				});
			}
			else{
				temp = p;
			}
			if(p.length != temp.length)
			{
				let diff = temp.length - p.length;
				if(p.length > 1)
					diff = diff + (p.length - 1);
				for(let i=0; i<diff; i++)
					temp.pop();
			}
			let selectedProps = [];
			for (var i = 0; i < temp.length; i++) {
			  selectedProps.push({
				property: temp[i].propertyName
			  });
			}
			let propObj = {
				key: "property",
				type: "Dropdown",
				isComaSeprated: false,
				selected: selectedProps
			}
			SelectedData.push(propObj);
		}
	}
	
	this.setState({selectedData: SelectedData})
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      //  this.globalSearch();
    });
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  handleDelete = (item, data) => {
    const { marketInfo, subMarketInfo, propertyInfo, filteredTitle, selectedData } = this.state;
	let markets = marketInfo;
	let subMarkets = subMarketInfo;
	let properties = propertyInfo;
	let SelectedData = selectedData; 
	let titles = filteredTitle;
	if(titles.length > 0){
		var index = this.state.filteredTitle.indexOf(this.state.filteredTitle.find((x)=>x.props.children.props.label.toLowerCase() == data.key +": "+ item["submarket"].toLowerCase()));
		if(index !== -1)
		titles.splice(index, 1);
	}

	this.setState({filteredTitle: titles});

    if (SelectedData.length > 0) {
      let key = data.key == undefined ? data.slice(0, data.length - 4) : data.key;
      var items = SelectedData.filter(x => { if (x.key == key.toLowerCase()) return x });
      if (items.length > 0) {
        if (data.key == "market" || data == "marketInfo") {
          let market = item.market == undefined ? item.value : item.market;
          let idx = SelectedData.indexOf(items.find(x => x.selected[0].market == market));
          let index = markets.indexOf(markets.find(x => x.value == market));
          if (idx !== -1)
            SelectedData.splice(idx, 1);
          if (index !== -1)
            markets.splice(index, 1);
        }

        else if (data.key == "submarket" || data == "subMarketInfo") {
          let submarket = item.submarket == undefined ? item.value : item.submarket;
          let idx = SelectedData.indexOf(items.find(x => x.selected[0].submarket == submarket));
          let index = subMarkets.indexOf(subMarkets.find(x => x.value == submarket));
          if (idx !== -1)
            SelectedData.splice(idx, 1);
          if (index !== -1)
            subMarkets.splice(index, 1);
        }

        else if (data.key == "property" || data == "propertyInfo") {
          let property = item.property == undefined ? item.propertyName : item.property;
          let idx = SelectedData.indexOf(items.find(x => x.selected[0].property == property));
          let index = properties.indexOf(properties.find(x => x.propertyName == property));
          if (idx !== -1)
            SelectedData.splice(idx, 1);
          if (index !== -1)
            properties.splice(index, 1);
        }
      }
    }

	this.setState({selectedData: SelectedData, marketInfo: markets, subMarketInfo: subMarkets, propertyInfo: properties});
	this.forceUpdate();
	
  };


  renderFilterAppliedPane = (data) => {
    const { marketInfo, subMarketInfo, propertyInfo } = this.state;
    const appliedFilter = [];
    if (data.selected && data.selected.length > 0) {
      let marketFilterTitle= data.key == "market" ? "Market" : "" ;
	  let submarketFilterTitle = data.key == "submarket" ? "Submarket" : "";
	  let propertyFilterTitle = data.key == "property" ? "Property" : "";//filterConfig.find((y) => y.key === data.key).title;
      
	  const values = [];

      data.selected.forEach((x) => {
		let filterTitle = "";
		if((marketFilterTitle !== "") && (submarketFilterTitle == "" && propertyFilterTitle == ""))
		filterTitle = this.capitalizeFirstLetter(marketFilterTitle) + ': ' + x[data.key]
		else if ((submarketFilterTitle !== "") && (marketFilterTitle == "" && propertyFilterTitle == ""))
		filterTitle = this.capitalizeFirstLetter(submarketFilterTitle) + ': ' + x[data.key]
		else if((propertyFilterTitle !== "") && (submarketFilterTitle == "" && marketFilterTitle == ""))
		filterTitle = this.capitalizeFirstLetter(propertyFilterTitle) + ': ' + x[data.key]
        values.push(
          <div className="col-xs-auto filter-pane">
          <Chip
            label={ filterTitle}
            onDelete={() => {
              this.handleDelete(x, data);
            }}
            color="primary"
            variant="outlined"
            className="filter-chip"
          />
        </div>
        );

      });
	  var title = this.state.filteredTitle;
	  
	//  this.setState({filteredTitle:values});
	//  if(values.map((x)=>x.props.children.props.label.includes(data.selected.map((x)=> x[data.key]))) == false)
	if(this.state.filteredTitle.length > 0){
		var val = this.state.filteredTitle.map((x)=>x.props.children.props.label.includes(values.map((a)=>a.props.children.props.label)));
		if(val[val.length-1] == false){}
		//title.push(values[0]);
		else if(val[val.length-1] == true)
		{}
		//else
		//for(let i=0; i<title.length; i++){
		//	let a = title[i];
		//	let isDuplicate = values.map((x)=>x.props.children.props.label.includes(a.props.children.props.label));
		//	if(isDuplicate[isDuplicate.length - 1] == false)
		//		values.push(a);
		//}
			
	}
	else{
		//title.push(values[0]);
	}
		let valuesNonDuplicate = removeDuplicates(values.map(x=>x));
	  this.setState({filteredTitle : title});
      appliedFilter.push(<>{valuesNonDuplicate} </>);
    }
    return <>{appliedFilter}</>;

  }

  onFilterChange = (marketInfo, subMarketInfo, propertyInfo) => {
    if (marketInfo.length > 0 || subMarketInfo.length > 0 || propertyInfo.length > 0) {
      this.setState({
		selectedData: [],
        marketInfo: [],
		subMarketInfo: [],
		propertyInfo: [],
		searchInput: ""
      });
    }
  };

  resetFilters = () => {
    this.setState({
      selectedData: [],
	  marketInfo: [],
	  subMarketInfo: [],
	  propertyInfo: [],
	  searchInput: ""
    });
  };

  render() {
    const {
      marketInfo,
      subMarketInfo,
      specPropertyList,
      propertyInfo,
      uploadingCount,
      ddMasterData,
      industryMasterData,
      isSelectAllMarket,
      percentageOfficevsMaster,
      priorPremisesMaster,
      constructionTypeMaster,
      nnnPerFSMaster,
      relocationReasonMaster,
      downtimeMaster,
      compTypeMaster,
      getMarketMaster,
      getSubMarketMaster,
      getBuildingMaster,
      searchInput,
	  selectedData
    } = this.state;
    let propertyList =
      marketInfo &&
      specPropertyList &&
      specPropertyList.filter(
        (x) =>
          getAllKeysValue(marketInfo, "id").indexOf(parseInt(x.marketId)) !== -1
      );

    let filterPropertyist =
      propertyList != "" &&
      propertyList != null &&
      propertyList != undefined &&
      subMarketInfo != "" &&
      subMarketInfo != null &&
      marketInfo &&
      specPropertyList
        ? propertyList.filter(
            (x) =>
              getAllKeysValue(subMarketInfo, "id").indexOf(
                parseInt(x.subMarketId)
              ) !== -1
          )
        : propertyList;

	let uniqueFilterPropertyList = [];
	if(specPropertyList.length > 0 && filterPropertyist.length > 0)
		filterPropertyist.map((x)=>{
			let findItem = uniqueFilterPropertyList.find((y)=>x.propertyId == y.propertyId);
			if(!findItem)
				uniqueFilterPropertyList.push(x);
		});

    let allMarketpropertyList =
      marketInfo &&
	  marketInfo.length == 1 &&
      marketInfo[0].value == "All Markets" &&
      specPropertyList
        ? specPropertyList
        : [];

    let allMarketFilterPropertyist =
      allMarketpropertyList != "" &&
      allMarketpropertyList != null &&
      allMarketpropertyList != undefined &&
      subMarketInfo != "" &&
      subMarketInfo != null &&
      marketInfo &&
      specPropertyList
        ? allMarketpropertyList.filter(
            (x) =>
              getAllKeysValue(subMarketInfo, "id").indexOf(
                parseInt(x.subMarketId)
              ) !== -1
          )
        : allMarketpropertyList;

	let uniqueAllMarketFilterPropertyList = [];
	if(specPropertyList.length > 0 && allMarketFilterPropertyist.length > 0)
		allMarketFilterPropertyist.map((x)=>{
			let findItem = uniqueAllMarketFilterPropertyList.find((y)=>x.propertyId == y.propertyId);
			if(!findItem)
				uniqueAllMarketFilterPropertyList.push(x);
		})
    //const {
    //	//fundsNameList = [],
    //	//selectedOrgId,
    //	tenantInfo: {
    //	//  permission: { funds },
    //	  userHasEditPermission,
    //	  landingColumns: { AssetTableColumns },
    //	}
    //} = this.props;

    return (
      <>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <PageHeader title="Spec Suite"></PageHeader>
          </div>
          <div className="col-md-5 col-lg-5">
            <SearchBar
              size="large"
              name="searchInput"
              value={searchInput || ""}
              onChange={this.handleChange}
              label="Search"
              placeholder="Search specsuite market, specsuite submarket, property etc."
            ></SearchBar>
          </div>
          <div className="col-md-3 col-lg-3">
            {/* {IsSectionVisible(
						SECTIONS.Spec_Suite,
						SECTIONS.Spec_Suite_Add_Edit,
						SECTIONS.ADD_EDIT
					) &&
						<div className="row end-sm" style={{ marginTop: "4px" }}>
							<div className="col-md-2 col-lg-2">
							<DownloadFile
								url={"public//config//Spec_upload_sample.xlsx"}
								filename={"Spec_upload_sample"}
								isFetchDownload={true}
								isShowIcon
							/>
							</div>
							{
								
								<div className="col-md-4 col-lg-4">
								<FileUpload
									specPropertyList={specPropertyList}
									propertyId={propertyInfo && propertyInfo.propertyId}
									marketMasterData={getMarketMaster}
									subMarketMasterData={getSubMarketMaster}
									percentageOfficevsMaster={percentageOfficevsMaster}
									compTypeMaster={compTypeMaster}
									updatedRecord={this.getAllUpdatedProeprties}
								/>
								</div>
								
							}
							<div className="col-md-2 col-lg-2">
							<span onClick={() => downloadExcelFile("public//config//UploadFileUserGuide.pdf", "UploadFileUserGuide")} style={{ padding: '4px' }}>
								<DarkTooltip title={"Spec suite upload file user guide"}>
									<span>
										<BiHelpCircle fontSize="1.5rem" color="#00B0B9" />
									</span>
								</DarkTooltip>
							</span>
							</div>

						</div>
					} */}
          </div>
        </div>

		{selectedData != null && selectedData.length > 0 && (
          <div style={{
            backgroundColor: '#ffff',
            paddingBottom: '7px'
          }}>

            <div className="col-xs-12">

              <div className="row start-xs">
               
                  <span className="applied-filter-header" style={{ paddingTop: '8px' }}>Active Filters : &nbsp;</span>

                {selectedData.map((data) => {
                  return this.renderFilterAppliedPane(data);
                })}
                <Button                  
                  className="btn-clear"                  
                  onClick={() => {
                    this.setState({
                      selectedData: [],
                      marketInfo: [],
                      subMarketInfo:[],
                      propertyInfo:[],
					  searchInput: ""
                    });
                    this.resetFilters();
                    this.onFilterChange(marketInfo, subMarketInfo, propertyInfo);
                  }}
				  
                >
                  Clear All
                </Button>    
              </div>

            </div>
          </div>
        )}

        <div className="row" style={{ paddingBottom: "8px", backgroundColor: "#F5F5F5", marginLeft:"0.5rem", marginRight:"0.5rem" }}>
          <div className="col-sm-3 col-lg-3 col-md-3">
            <AutocompleteMultiSelect
              lbl="Market/Major Service Area"
              labelKey="value"
              value={marketInfo ? marketInfo : []}
              configData={
                getMarketMaster.length
                  ? [
                      {
                        id: getMarketMaster.map((x) => x.id).toString(),
                        value: "All Markets",
                      },
                      ...getMarketMaster,
                    ]
                  : []
              }
              setProperty={this.setDDValues}
              defaultValue=""
              extraParam="marketInfo"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-sm-3 col-lg-3 col-md-3">
            <AutocompleteMultiSelect
              lbl="Submarket"
              labelKey="value"
              value={subMarketInfo ? subMarketInfo : []}
              configData={
                isSelectAllMarket
                  ? getSubMarketMaster
                  : getSubMarketMaster.filter(
                      (x) =>
                        getAllKeysValue(marketInfo, "id").indexOf(
                          parseInt(x.marketId)
                        ) !== -1
                    )
              }
              setProperty={this.setDDValues}
              defaultValue=""
              extraParam="subMarketInfo"
              onDelete={this.handleDelete}
            />
          </div>
          <div className="col-sm-3 col-lg-3 col-md-3">
            <AutocompleteMultiSelect
              configData={
                marketInfo &&
				marketInfo.length == 1 &&
                marketInfo[0].value == "All Markets" &&
                specPropertyList
                  ? uniqueAllMarketFilterPropertyList
                  : marketInfo && specPropertyList
                  ? uniqueFilterPropertyList
                  : []
              }
              value={propertyInfo ? propertyInfo : []}
              setProperty={this.setDDValues}
              defaultValue=""
              extraParam="propertyInfo"
              onDelete={this.handleDelete}
            />
          </div>
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                <label htmlFor="filled-hidden-label-small">Upload/Download File</label> */}

          {/* </Box> */}
        </div>
        {/* </div> */}
        <Paper>
          {this.state.selectedMarketId && (
            <FundWisePerformance
              onPropertyClick={this.onPropertyClick}
              onJumpToPage={this.onJumpToPage}
              userHasEditPermission={true}
              AssetTableColumns={AssetTableColumns}
              allMarket={this.state.allMarket}
              propertyId={
                propertyInfo && getAllKeysValue(propertyInfo, "propertyId")
              }
              marketId={marketInfo && getAllKeysValue(marketInfo, "id")}
              subMarketId={
                subMarketInfo && getAllKeysValue(subMarketInfo, "id")
              }
              propertyInfo={propertyInfo}
              uploadingCount={uploadingCount}
              getAllPropertyList={this.getAllPropertyList}
              ddMasterData={ddMasterData}
              propertyMasterData={getBuildingMaster}
              marketMasterData={getMarketMaster}
              subMarketMasterData={getSubMarketMaster}
              industryMasterData={industryMasterData}
              percentageOfficevsMaster={percentageOfficevsMaster}
              priorPremisesMaster={priorPremisesMaster}
              constructionTypeMaster={constructionTypeMaster}
              relocationReasonMaster={relocationReasonMaster}
              downtimeMaster={downtimeMaster}
              nnnPerFSMaster={nnnPerFSMaster}
              compTypeMaster={compTypeMaster}
              searchInput={searchInput}
            />
          )}
        </Paper>
      </>
    );
  }
}

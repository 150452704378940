import React from "react";
import "./spec-summary.less";
import BoxSummary from "../common/box-summary";
import BarChart from "../common/Chart/BarChart";

const colorCode = [
	"#1A8FBF",
	"#993299",
	"#E59400",
	"#3DA88C",
	"#6c903ccc",
	"#365e7e",
	"#4b636dcc",
	"#010101",
	"#160f56cc",
	"#100552",
	"#354d56",
	"#365e7e",
	"#1A8FBF",
	"#993299",
	"#E59400",
	"#3DA88C"
];

class SpecSummaryInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const { specSummaryData, costPsfChart, tenantRsfChart } = this.props;

		return (
			<div className="spec-summary-group">
				<div className="average-group">
					{specSummaryData &&
						specSummaryData.dashboardDataSettings.map((box, i) => (
							<BoxSummary
								key={"boxSummary_" + i}
								color={colorCode[i] || "red"}
								value={specSummaryData.dashboardData[0][box.DataKey]}
								display={box.DisplayName}
								subTitle={box.ShortDisplayMsg}
								dataFormat={box.DataFormat}
							/>
						))}
				</div>
				<br />
				<br />
				<div className="row">
					<div className="col-xs-6">
						<BarChart
							data={costPsfChart}
							height={300}
							colors={[
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" }
							]}
							yLabel="$"
							precededBarText="$"
							chartTitle="Cost PSF $"
							NoDataMsg="Cost PSF chart data not available."
						/>
					</div>
					<div className="col-xs-6">
						<BarChart
							data={tenantRsfChart}
							height={300}
							colors={[
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" },
								{ barColor: "#115241cc" }
							]}
							yLabel="RSF"
							chartTitle="Tenant RSF"
							NoDataMsg="Tenant RSF chart data not available."
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default SpecSummaryInfo;

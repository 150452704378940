import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import { LabelImportant } from "@material-ui/icons";
import React from "react";
import _ from "lodash";
import moment from "moment";
import TablePagination from "@material-ui/core/TablePagination";
import { getFormattedValuesForHistory } from "../../../utils/formatData";
export default class ViewHistoryData extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropDownSourceData: [...(this.props.dropDownSource || [])],
      rowsPerPage: 10,
      rowsPerPageOptions: [5, 10, 20, 35, 50],
      page: 0,
    };
  }
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.forceUpdate();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  componentDidMount() {}
  getSourceColumnData = (
    data,
    source,
    dbColumn,
    attributeNewValue,
    attributeOldValue
  ) => {
    let sourceColumnData = [];
    let oldValue = null;
    let newValue = null;
    if (source != undefined && source.length > 0) {
      let key = data.key;
      newValue =
        attributeNewValue != null && key != null
          ? source.find((m) => m[dbColumn] == attributeNewValue)
          : [];
      oldValue =
        attributeOldValue != null && key != null
          ? source.find((m) => m[dbColumn] == attributeOldValue)
          : [];
      if (
        newValue != undefined &&
        newValue != null &&
        oldValue != undefined &&
        oldValue != null
      ) {
        sourceColumnData.push(newValue[key]);
        sourceColumnData.push(oldValue[key]);
        // newValue != null ?  sourceColumnData.push(newValue): null;
        // oldValue != null ? sourceColumnData.push(oldValue) : null;
      } else if (
        newValue != undefined &&
        newValue != null &&
        oldValue == undefined
      ) {
        sourceColumnData.push(newValue[key]);
      } else if (
        oldValue != undefined &&
        oldValue != null &&
        newValue == undefined
      ) {
        sourceColumnData.push("");
        sourceColumnData.push(oldValue[key]);
      }
      else if (newValue != undefined &&
        newValue != null
         && oldValue == undefined
          ) {
        sourceColumnData.push(newValue[key]);
        // newValue != null ?  sourceColumnData.push(newValue): null;
        // oldValue != null ? sourceColumnData.push(oldValue) : null;
      }
      else if (oldValue != undefined &&
        oldValue != null
         && newValue == undefined
          ) {
            sourceColumnData.push('');
        sourceColumnData.push(oldValue[key]);
        // newValue != null ?  sourceColumnData.push(newValue): null;
        // oldValue != null ? sourceColumnData.push(oldValue) : null;
      }
    }
    return sourceColumnData;
  };

  renderRow(formFields = []) {
    return (
      // return formFields
      //   .filter((x) => x.isRequired === true)
      //   .map((obj, index) => (
      <>
        <TableBody key={"tableBody"}>
          {formFields && formFields.length > 0 ? (
            (this.state.rowsPerPage > 0
              ? formFields.slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
              : formFields
            ).map((obj, index) =>
              this.renderCell(obj, index)
              // ) : (
              //   <TableRow>No Data available</TableRow>
              // )
            )
          ) : (
            <TableRow key={"RowNoDataAvailable"}>
              <TableCell key={"ColNoDataAvailable"} colSpan="4">
                <span style={{ paddingTop: "2px" }}>No Data available</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </>
    );
    // ));
  }
  renderCell(obj, i) {
    const { dropDownSourceData } = this.state;
    let sourceFilteredData = [];
    const source =
      (dropDownSourceData || []).find(
        (x) =>
          _.camelCase(x.idField).toLowerCase().trim() ===
          _.camelCase(obj.dbColumn || "")
            .toLowerCase()
            .trim()
      ) || {};
    if (source != undefined && source != null) {
      sourceFilteredData = this.getSourceColumnData(
        source,
        source.source,
        _.camelCase(obj.dbColumn),
        obj.attributeNewValue,
        obj.attributeOldValue
      );
    }
    return (
      //   <div className="row" style={{ padding: "1px" }}>
      //   <TableBody>
      <TableRow className={i % 2 === 0 ? " evenRow" : " oddRow"}>
        {/* {obj.createdBy} */}
        <TableCell key={"tableColOne" + i}>
          <label
            disabled={true}
            className="search-box"
            // value={
            //   moment(moment.utc(obj.createdDate).toDate()).format(
            //     "MM/DD/YYYY hh:mm:ss"
            //   ) || ""
            // }
          >
            {moment(moment.utc(obj.createdDate).toDate()).format(
              "MM/DD/YYYY hh:mm:ss"
            ) || ""}
          </label>
        </TableCell>
        <TableCell key={"tableColTwo" + i}>
          <label
            className="search-box"
            //value={obj.createdBy || ""}
          >
            {obj.createdBy || ""}
          </label>
        </TableCell>
        {this.props.isHistoryByField == false && (
          <TableCell key={"tableColThree" + i}>
            <label
              className="search-box"
              // value={obj.uiColumn || ""}
            >
              {obj.uiColumn || ""}
            </label>
          </TableCell>
        )}
        <TableCell key={"tableColFour" + i}>
          <label
            className="search-box"
            // value={
            //   sourceFilteredData.length > 0
            //     ? sourceFilteredData[0]
            //     : obj.attributeNewValue
            // }
          >
            {sourceFilteredData.length > 0
              ? sourceFilteredData[0]
              : obj.attributeNewValue != null &&
                obj.attributeNewValue != undefined &&
                obj.attributeNewValue != ""
              ? getFormattedValuesForHistory(
                  obj.attributeNewValue,
                  obj.dataFormat,
                  false
                )
              : obj.attributeNewValue}
          </label>
        </TableCell>
        <TableCell key={"tableColFive" + i}>
          <label
            className="search-box"
            // value={
            //   sourceFilteredData.length > 0
            //     ? sourceFilteredData[1]
            //     : obj.attributeOldValue
            // }
          >
            {sourceFilteredData.length > 0
              ? sourceFilteredData[1]
              : obj.attributeOldValue != null &&
                obj.attributeOldValue != undefined &&
                obj.attributeOldValue != ""
              ? getFormattedValuesForHistory(
                  obj.attributeOldValue,
                  obj.dataFormat,
                  false
                )
              : obj.attributeOldValue}
          </label>
        </TableCell>
      </TableRow>

      // </TableBody>
      //   </div>
    );
  }

  renderHeader() {
    return (
      <TableHead style={{ padding: "17px" }}>
        <TableRow key={"historyTableheader"}>
          <TableCell key={"modifiedDate"}>
            <label className="history-label">Modified Date</label>
          </TableCell>
          <TableCell>
            <label className="history-label" key={"modifiedByCol"}>
              Modified By
            </label>
          </TableCell>
          {this.props.isHistoryByField == false && (
            <TableCell key={"fieldCol"}>
              <label className="history-label">Field</label>
            </TableCell>
          )}
          <TableCell key={"updatedValueCol"}>
            <label className="history-label">Updated Value</label>
          </TableCell>
          <TableCell key={"oldValueCol"}>
            <label className="history-label">Old Value</label>
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
  render() {
    let formElement = <></>;
    let formHeader = <></>;
    const { rowsPerPageOptions, rowsPerPage, page } = this.state;

    formElement = this.renderRow(
      this.props.isHistoryByField == true
        ? this.props.getHistoryDetailByField
        : this.props.sourceData
    );
    formHeader = this.renderHeader();

    return (
      <>
        {
          <div style={{ padding: "17px" }}>
            <TablePagination
              className="pager no-print"
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={
                this.props.isHistoryByField == true
                  ? (this.props.getHistoryDetailByField || []).length
                  : (this.props.sourceData || []).length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
            <TableContainer>
              <Table key={"tableViewHistory"}>
                {formHeader}
                {formElement}
              </Table>
            </TableContainer>
          </div>
        }
      </>
    );
  }
}

import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Checkbox,
  IconButton,
  Tooltip,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import { apiRequest, apiPost, apiBatchRequest } from '../../utils/api_service';
import { API_URL } from '../../constants/api';
import SaveIcon from '@material-ui/icons/Save';
import './configuration.less';
import commonLabels from '../../constants/messageConstants';
import { ShowToast, toastType } from '../../utils/toast-service';
import PageHeader from '../common/PageHeader';

export default class GroupSectionPermission extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      accessGroups: [],
      selectedGroup: '',
      sectionPermissions: [],
      globalAccess: [],
      accessTypeData: []
    };
  }

  componentDidMount () {
    this.GetMaster();
  }

  GetSectionPermissionList = () => {
    const { selectedGroup } = this.state;

    apiRequest({
      url:
        API_URL.Users.GetPermissionByGroup +
        '?groupId=' +
        selectedGroup + '&businessLineId=' + 1
    }).then(( response) => {
      this.setState({
        sectionPermissions: response.data || [],
        //globalAccess: response.globalAccess || []
      });
    });
  };

  GetMaster = () => {
    const params = {
      onlyParentGroup: false
    };
    apiBatchRequest([
      {
        url: API_URL.Users.GetAccessGroups,
        key: 'accessGroupData',
        params:{businessLineId:1}
      },
      {
        url: API_URL.Users.GetAccessType,
        key: 'accessType'
      }
    ]).then(( accessGroupData ) => {
      if(accessGroupData.accessGroupData.success== true)
      {
      const groups = (accessGroupData.accessGroupData.data || []);
      this.setState(
        {
          accessGroups: groups,
          selectedGroup: (groups[0] || {}).objectId,
          accessTypeData: accessGroupData.accessType.data
        },
        () => {
          this.GetSectionPermissionList();
        }
      );
      }
    });
  
  };

  handleValueChange = (value, key, index) => {
    const { sectionPermissions } = this.state;

    sectionPermissions[index][key] = value;

    if (sectionPermissions[index].subSection === null) {
      const parentSectionId = sectionPermissions[index].parentSectionId;
      sectionPermissions.forEach((x) => {
        if (x.parentSectionId === parentSectionId) {
          x[key] = value;
        }
      });
    }
    this.setState({ sectionPermissions: sectionPermissions });
  };

  globalHandleValueChange = (value, key, index) => {
    const { globalAccess } = this.state;
    globalAccess[index][key] = value;
    this.setState({ globalAccess });
  };

  SaveGroupPermissionMapping = () => {
    const { sectionPermissions, selectedGroup } = this.state;
    if (selectedGroup === 0 || selectedGroup === undefined) {
      ShowToast(
        'Please select user group',
        toastType.ERROR,
        5000,
        commonLabels.somethingWrong.code
      );
      return;
    }
    sectionPermissions.forEach(function (x, i) {
      x.objectId = selectedGroup;
    });
    const param = sectionPermissions;
     // SectionMapping: sectionPermissions,
     // globalAccess: globalAccess
   // };
    apiPost({
      url: API_URL.Users.UpdatePermission,
      params: {},
			postBody: param,
    }).then(({ success: isExecuted }) => {
      if (isExecuted === true) {
        ShowToast(
          commonLabels.sectionPermissionUpdateSuccess.message,
          toastType.SUCCESS,
          5000,
          commonLabels.sectionPermissionUpdateSuccess.code
        );
      } else {
        ShowToast(
          commonLabels.somethingWrong.message,
          toastType.ERROR,
          5000,
          commonLabels.somethingWrong.code
        );
      }
      this.GetSectionPermissionList();
    });
  };

  render () {
    const {
      accessGroups,
      sectionPermissions,
      selectedGroup,
      globalAccess,
      accessTypeData
    } = this.state;
    return (
      <>
       <div className="row">
         <div className="col-md-4 col-lg-4">
                <PageHeader title="Role Based Access"></PageHeader>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
              {/* <SearchBar
                  size="large"
                  name="searchInput"
                  value={this.state.searchInput || ""}
                  onChange={this.handleChange}
                  label="Search"
                  placeholder="Search employees."
                ></SearchBar> */}
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4"></div>
         </div>
        <Table
          aria-labelledby="tableMenuTitle"
          size={'small'}
          aria-label="configuratin table"
        >
          {/* <TableHead>
            <TableRow>
              <TableCell key={'configuerHeader'} className="content-header">
                {' '}
                <div className="content-header">
                  Configure Group Access Permissions
                </div>{' '}
              </TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell key={'configuertableCell'}>
                <div className="col-xs-12">
                  <FormControl style={{ width: '100%' }}>
                    <InputLabel size="small" id={'WidgetTypelabel'}>
                      User Group
                    </InputLabel>
                    <Select
                      required
                      style={{ width: '100%' }}
                      labelId={'WidgetTypelabel'}
                      id={'WidgetTypelabelSelect'}
                      value={selectedGroup}
                      defaultValue={selectedGroup}
                      onChange={(event) => {
                        this.setState(
                          { selectedGroup: event.target.value },
                          () => this.GetSectionPermissionList()
                        );
                      }}
                      placeholder={'Select User Group'}
                    >
                      {accessGroups.map((group, index) => (
                        <MenuItem
                          key={'MenuUG' + index}
                          size="medium"
                          value={group.objectId}
                        >
                          {group.groupName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>{' '}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                key={'configuerHeader'}
                className="content-header sub-section row co-xs-12"
              >
                <div className="content-header row col-xs-12">
                  Access Permission
                  <div className="end-xs col-xs">
                    <Tooltip title="Save">
                      <IconButton
                        className="white-button"
                        color="inherit"
                        size="small"
                        onClick={this.SaveGroupPermissionMapping}
                      >
                        <SaveIcon fontSize="small"></SaveIcon>
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell key={'configureHeadercell2'}>
                <div className="col-xs-12">
                <Paper fontSize="small">
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell key={'SectionName'}>
                              {' '}
                              Global Permission{' '}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {globalAccess.map((row, index) => {
                            return (
                              <TableRow key={'tr_SectionName' + index}>
                               <TableCell key={'tc_showData' + index}>
                                  <Checkbox
                                    id={'showData' + index}
                                    key={'showData' + index}
                                    color="primary"
                                    size="small"
                                    defaultValue={row.showData}
                                    checked={row.showData}
                                    onChange={(event) => {
                                      event.target.checked;
                                      this.globalHandleValueChange(
                                        event.target.checked,
                                        'showData',
                                        index
                                      );
                                    }}
                                  />{ row.accessType}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                  <Paper fontSize="small">
                    <TableContainer>
                      <Table
                        aria-labelledby="tableTitle"
                        size={'small'}
                        aria-label="enhanced table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell key={'SectionName'}>
                              {' '}
                              Section Name{' '}
                            </TableCell>
                            <TableCell key={'SubSectionName'}>
                              {' '}
                              Sub Section Name{' '}
                            </TableCell>
                            <TableCell key={'accessType'}>
                              {' '}
                              Access Type{' '}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sectionPermissions.map((row, index) => {
                            return (
                              <TableRow key={'tr_SectionName' + index}>
                                <TableCell key={'SectionName' + index}>
                                  {' '}
                                  {row.subSection == null
                                    ? row.parentSection
                                    : ''}{' '}
                                </TableCell>
                                <TableCell key={'subSectionName' + index}>
                                  {' '}
                                  {row.subSection}{' '}
                                </TableCell>
                                <TableCell key={'accessType' + index}>
                                  <FormControl style={{ width: '100%' }}>
                                    <Select
                                      size="small"
                                      fontSize="small"
                                      required
                                      style={{ width: '100%' }}
                                      id={'accessTypeIdSelect'}
                                      value={row.accessTypeId}
                                      defaultValue={row.accessTypeId}
                                      onChange={(event) => {
                                        this.handleValueChange(
                                          event.target.value,
                                          'accessTypeId',
                                          index
                                        );
                                      }}
                                      placeholder={'Select Access Type'}
                                    >
                                       {accessTypeData.map((accessType1, index) => (
                                          <MenuItem
                                            key={'MenuUG' + index}
                                            size="medium"
                                            value={accessType1.accessTypeId}
                                          >
                                            {accessType1.accessType}
                                          </MenuItem>
                                       ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }
}

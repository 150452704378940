import React, { useEffect } from "react";
import "./index.less";
import Button from "@material-ui/core/Button";
import { FiDownload } from "react-icons/fi";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import cx from "classnames";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { DarkTooltip } from "../material-tooltip";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Zoom } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		"& .MuiFormControlLabel-label.Mui-disabled": {
			color: "#fff"
		}
	},
	paper: {
		marginRight: theme.spacing(2)
	}
}));

export default function MenuListComposition({
	buttonLabel = "Default",
	fundsList = [],
	reportList = [],
	maxChar = 24,
	pdfDownload,
	excelDownload,
	dataTip = "Tooltip Here",
	downloadBtnLabel = "Download",
	currentFundId = ""
}) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [fundValue, setFundValue] = React.useState(currentFundId);
	const [reportFormat, setReportFormat] = React.useState("excel");
	const [reportType, setReportType] = React.useState(1);
	const [selectedReport, setSelectedReport] = React.useState(null);
	const anchorRef = React.useRef(null);

	useEffect(() => {
		onReportSelect(reportType);
	}, [reportType]);

	useEffect(() => {
		setFundValue(currentFundId);
	}, [currentFundId]);

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen);
	};

	const handleClose = event => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const handleDownloadReport = event => {
		switch (reportFormat) {
			case "pdf":
				if (selectedReport.pdfDownload) {
					pdfDownload(selectedReport, fundValue);
				}
				break;
			case "excel":
				if (selectedReport.excelDownload) {
					excelDownload(selectedReport, fundValue);
				}
				break;
			default:
				break;
		}
		setOpen(false);
	};

	const onReportSelect = id => {
		const report = reportList.filter(report => report.id === id)[0];
		setSelectedReport(report);

		if (!report.pdfDownload && reportFormat === "pdf") {
			setReportFormat("excel");
		}
		if (!report.excelDownload && reportFormat === "excel") {
			setReportFormat("pdf");
		}
	};

	return (
		<div className={`${classes.root} download-popup`}>
			<DarkTooltip title={dataTip} arrow>
				<Button
					ref={anchorRef}
					aria-controls={open ? "menu-list-grow" : undefined}
					aria-haspopup="true"
					onClick={handleToggle}
					className="downloadIconBtn"
				>
					<>
						<FiDownload size="1.2rem" />
					</>
				</Button>
			</DarkTooltip>
			<Popper
				open={open}
				className="popup-downloadOptions"
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Zoom
						in={open}
						{...TransitionProps}
						timeout={{ enter: 300, exit: 300 }}
					>
						<Paper className="paperContainer">
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									className="downloadOptions"
								>
									<div className="controlParent">
										{fundsList.length ? (
											<FormControl component="fieldset" className="fieldset">
												<RadioGroup
													aria-label="Report List"
													name="report"
													className="radioGroup"
													value={fundValue}
													onChange={event =>
														setFundValue(parseInt(event.target.value))
													}
												>
													{fundsList.length &&
														fundsList.map(({ id, label }, i) => {
															return (
																<FormControlLabel
																	key={`menulist${i}`}
																	value={id}
																	control={<Radio color="primary" />}
																	label={
																		label && label.length > maxChar
																			? `${label.substring(0, maxChar)}...`
																			: label
																	}
																/>
															);
														})}
												</RadioGroup>
											</FormControl>
										) : (
											""
										)}
										<div className="reportTypeParent">
											<FormControl component="fieldset" className="fieldset">
												<RadioGroup
													aria-label="Portfolio Type"
													name=""
													className="radioGroup"
													value={reportType}
													onChange={event =>
														setReportType(parseInt(event.target.value))
													}
												>
													{reportList.length &&
														reportList.map(({ id, label }, i) => {
															return (
																<FormControlLabel
																	key={`reportist${i}`}
																	value={id}
																	control={<Radio color="primary" />}
																	label={
																		label && label.length > maxChar
																			? `${label.substring(0, maxChar)}...`
																			: label
																	}
																/>
															);
														})}
												</RadioGroup>
											</FormControl>
											<FormControl
												component="fieldset"
												className="fieldset"
												style={{
													marginTop: "0.5rem"
												}}
											>
												<RadioGroup
													aria-label="Report Type"
													name="reportFormat"
													className="radioGroup"
													style={{
														display: "inline-block"
													}}
													value={reportFormat}
													onChange={event =>
														setReportFormat(event.target.value)
													}
												>
													<FormControlLabel
														value="pdf"
														control={<Radio color="primary" />}
														label="PDF"
														className={cx(
															selectedReport &&
																!selectedReport.pdfDownload &&
																"disabled"
														)}
													/>
													<FormControlLabel
														value="excel"
														control={<Radio color="primary" />}
														label="Excel"
														className={cx(
															selectedReport &&
																!selectedReport.excelDownload &&
																"disabled"
														)}
													/>
												</RadioGroup>
											</FormControl>
											<Button
												onClick={handleDownloadReport}
												className="download-button"
											>
												<span>{downloadBtnLabel}</span>
											</Button>
										</div>
									</div>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Zoom>
				)}
			</Popper>
		</div>
	);
}

import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { Paper } from "@material-ui/core";
import "./configuration.less";
import GroupSectionPermission from "./GroupSectionPermission";
import { SECTIONS } from "../../constants/appConstants";
import { IsAdmin, IsSectionVisible } from "../../utils/helper";
import ConfigureGroupMarket from "./ConfigureGroupMarket";
import ConfigureMarket from "./ConfigureMarket";
import ConfigureSubMarket from "./ConfigureSubMarket";
import ConfigureBuildingClass from "./ConfigureBuildingClass";
import ConfigureRefreshData from "./ConfigureRefreshData";
import ConfigureSubmarketCluster from "./ConfigureSubmarketCluster";
import ConfigureTenantIndustry from "./ConfigureTenantIndustry";

export default class Configurations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMarketId: this.props.globalMarketId,
			tabs: [],
			selectedMenu: 0,
			children: "",
		};
	}

	setConfigurations() {
		const tabs = [];
		// if (IsSectionVisible(SECTIONS.AddMarketDetails, 0)) {

		// tabs.push({ id: 1, name: 'Configure Master', children: <AddUpdateMaster/> });
		//   }
		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.Configuration,
				SECTIONS.Configure_Group_Access_Permission
			)
		) {
			tabs.push({
				id: 2,
				name: "Configure Group Access Permissions",
				children: <GroupSectionPermission />,
			});
		}

		// if (IsAdmin() && IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION)) {
		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.Configuration,
				SECTIONS.Configure_Market_Access_Permissions
			)
		) {
			tabs.push({
				id: 8,
				name: "Configure Market Access Permissions",
				children: <ConfigureGroupMarket />,
			});
		}

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Market_Details
			)
		) {
			tabs.push({
				id: 9,
				name: "Configure Market",
				children: <ConfigureMarket />,
			});
		}

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Submarket_Details
			)
		) {
			tabs.push({
				id: 10,
				name: "Configure Submarket",
				children: (
					<ConfigureSubMarket globalMarketId={this.props.globalMarketId} />
				),
			});
		}

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Submarket_Details
			)
		) {
			tabs.push({
				id: 13,
				name: "Configure Submarket Cluster",
				children: (
					<ConfigureSubmarketCluster globalMarketId={this.props.globalMarketId} />
				),
			});
		}

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Submarket_Details
			)
		) {
			tabs.push({
				id: 14,
				name: "Configure Tenant Industry",
				children: (
					<ConfigureTenantIndustry globalMarketId={this.props.globalMarketId} />
				),
			});
		}
		

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Building_Class_Details
			)
		) {
			tabs.push({
				id: 11,
				name: "Configure Building Class",
				children: (
					<ConfigureBuildingClass globalMarketId={this.props.globalMarketId} />
				),
			});
		}

		if (
			IsAdmin() &&
			IsSectionVisible(
				SECTIONS.AdminSection,
				SECTIONS.Configure_Add_Market_Details
			)
		) {
			tabs.push({
				id: 12,
				name: "Configure Refresh Data",
				children: <ConfigureRefreshData />,
			});
		}

		this.setState({
			tabs,
			selectedMenu: tabs.length > 0 ? tabs[0].id : 0,
			children: tabs.length > 0 ? tabs[0].children : "",
		});
	}

	componentDidMount() {
		this.setConfigurations();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {
			if (nextProps.globalMarketId != "") {
				this.setConfigurations();
			}
		}
	}

	render() {
		const { tabs, selectedMenu, children } = this.state;
		return (
			<div className="row configuration-container">
				<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
					<div className="addwidget-container">
						<div className="header row">
							{" "}
							<div className="col-xs-11">Configurations</div>
						</div>
						<div className="row widget-content">
							<List
								component="nav"
								aria-label="mailbox folders"
								className="ConfigureMenu"
							>
								{" "}
								{tabs.map((tab) => {
									return (
										<>
											<ListItem
												button
												selected={tab.id === selectedMenu}
												onClick={() =>
													this.setState({
														selectedMenu: tab.id,
														children: tab.children,
													})
												}
											>
												<ListItemText fontSize="small" primary={tab.name} />
											</ListItem>
											<Divider />
										</>
									);
								})}								
							</List>
						</div>
					</div>
				</div>
				<div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
					<div className="addwidget-container row">
						<Paper className="col-xs-12">{children}</Paper>
					</div>
				</div>
			</div>
		);
	}
}

import { isMobile, deviceDetect, isAndroid, isIOS } from "react-device-detect";
const deviceInfo = deviceDetect();

export let deviceDetection = { isMobile: false };
(function getDeviceInfo() {
	const deviceOs = (deviceInfo.osName || deviceInfo.os || "").toLowerCase();
	const siteOpenInIphone = isIOS || deviceOs === "ios" || deviceOs === "mac os";
	const siteOpenInAndroid =
		isAndroid || deviceOs === "android" || deviceOs === "linux";
	const iPadmodel =
		(!deviceInfo.isMobile && deviceOs === "mac os") ||
		deviceInfo.model === "iPad";

	// alert(JSON.stringify(deviceInfo));

	deviceDetection = {
		...deviceDetection,
		isMobile: isMobile || siteOpenInIphone || siteOpenInAndroid,
		isIPad: iPadmodel
	};
})();

export let standardDateFormat = "MM/DD/YYYY";

export const cardLandingView = "card";
export const listLandingView = "list";
export let SPSToken = "";
export let SPSDrive = null;

export const setDateFormatByPreference = prefFormat => {
	standardDateFormat = prefFormat;
};

export const setSPSToken = token => {
	SPSToken = token;
};

export const getSPSTokenData = token => {
	return SPSToken;
};

export const setSPSDrive = driveList => {
	SPSDrive = driveList;
};

export const dateMonthFormat = "MMM YYYY";
export const IDLE_TIME = 30 * 60 * 1000;

export const getUserEditPermission = perObj => {
	const readOnly = ["r", "i"];
	const isUserHasEditPermission =
		readOnly.indexOf(perObj.idKey) !== -1 ? false : true;
	return isUserHasEditPermission;
};

export const PROPERTY_EDIT = {
	edit: 1
};

export const PROPERTY_SOLD_STATUS = 3;
export const MIN_MONTH_DATE = 5;

export const PERFORMANCE_API_CONTEXT = "/Performance";
export const PROPERTY_API_CONTEXT = "/Property";

export const DOWNLOAD_STATUS = {
	inProgress: "progress",
	downloading: "downloading",
	completed: "completed",
	failed: "failed",
	uploading: "uploading",
	uploaded: "uploaded",
	processing: "processing",
};

export const DATA_FORMAT = {
	NumberFormat: "number",
	DecimalNumber: "decimalnumber",
	Header: "headers",
	SectionHeader: "sectionheader",
	Text: "text",
	Currency: "currency",
	DecimalCurrency: "decimalcurrency",
	TotalCurrency: "totalcurrency",
	TotalCurrencyDecimal: "totalcurrencydecimal",
	Year: "year",
	Month: "month",
	Percent: "percent",
	Factor: "factor",
	PerDecimal: "percentdecimal",
	PercentOnly: "percentonly",
	DateFormat: "date",
	Dropdown: "dropdown",
	MultiSelect: "multiselect",
	MonthYear: "monthyear",
	DispositionMonth: "dispositionmonth",
	YardiCode: "yardicode",
	AvgRentCurrencyDecimal: "avgrentcurrencydecimal",
	CurrencyDecimalPSF: "currencydecimalpsf",
	BlankRow: "blankrow",
	CheckBox: "checkbox",
	KWNumber: "kwnumber",
	LRate: "l + rate",
	DeleteAction: "deleteaction"
};

export const regXForYear = /^(18|19|20)\d{2}$/;
export const regXForMonth = /^.{0,4}/;
export const regXForYardiCode = /^[a-zA-Z]{5}[0-9]{3}/;

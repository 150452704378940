import axios from 'axios';
import { ShowToast, toastType } from './toast-service';
import { API_HEADERS, getCurrentEnvironment } from '../constants/api';
import commonLabels from '../constants/messageConstants';
import store from '../store/appStore';
import * as actionType from '../store/layout/actionTypes';
import * as loaderActionType from '../store/loader/actionTypes';
import * as LocalCacheActionType from '../store/localCache/actionTypes';
import { authenticationParameters, authProvider, config } from '../authProvider';
import 'regenerator-runtime/runtime';
import moment from 'moment';
import camelCaseKeys from 'camelcase-keys';
import { SPSToken } from '../constants/constants';

function diffInMinutes (dt2, dt1) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}
function getHeaders () {
  // authProvider.acquireTokenSilent(authenticationParameters).then(AuthResponse => {
  //   console.log(AuthResponse);
  //   // const result = {
  //   //   authorization: `${API_HEADERS.authName} ${token.accessToken}`,
  //   //   cloudOrgId: 1
  //   // };
  //   // //return resolve(result);
  // });
  return new Promise(function (resolve, reject) {
    if (authProvider &&
      authProvider.account &&
      authProvider.account.idTokenClaims &&
      authProvider.account.idTokenClaims.exp) {
      const expiration = new Date(new Date(0).setUTCSeconds(authProvider.account.idTokenClaims.exp));
      const dateToCompare = new Date();
      const diff = diffInMinutes(dateToCompare, expiration);
      if (diff > 10) {
        return resolve({
          authorization: `${API_HEADERS.authName} ${localStorage.getItem(API_HEADERS.authToken)}`,
          cloudOrgId: 1,
		//  CID:'l9M1U7NU4YWy9UbzzZr6jTEWle0KQf1s7NmRKVJmtz2j/OgysWUtv8buaXKyvMNkmkRSdEPoriO057jts8pUYVlSdUG8OUpLIjCGJp+dNxmY8OAyB1xfsiDHVMs+hmEw'
        });
      }
    }
    authProvider.acquireTokenSilent(authenticationParameters)
      .then(token => {
        const result = {
          authorization: `${API_HEADERS.authName} ${token.idToken.rawIdToken}`,
          cloudOrgId: 1,
		//  CID:'l9M1U7NU4YWy9UbzzZr6jTEWle0KQf1s7NmRKVJmtz2j/OgysWUtv8buaXKyvMNkmkRSdEPoriO057jts8pUYVlSdUG8OUpLIjCGJp+dNxmY8OAyB1xfsiDHVMs+hmEw'
        };
        return resolve(result);
      }).catch(() => {
        return resolve({
          authorization: `${API_HEADERS.authName} ${localStorage.getItem(API_HEADERS.authToken)}`,
          cloudOrgId: 1,
		//  CID:'l9M1U7NU4YWy9UbzzZr6jTEWle0KQf1s7NmRKVJmtz2j/OgysWUtv8buaXKyvMNkmkRSdEPoriO057jts8pUYVlSdUG8OUpLIjCGJp+dNxmY8OAyB1xfsiDHVMs+hmEw'
        });
      });
  });
}

function getToken () {
  return new Promise(function (resolve, reject) {
    authProvider.getAccessToken().then(token => {
      localStorage.setItem(API_HEADERS.authToken, token);
      const result = {
        authorization: `${API_HEADERS.authName} ${token.accessToken}`,
        cloudOrgId: 1,
		//CID:'l9M1U7NU4YWy9UbzzZr6jTEWle0KQf1s7NmRKVJmtz2j/OgysWUtv8buaXKyvMNkmkRSdEPoriO057jts8pUYVlSdUG8OUpLIjCGJp+dNxmY8OAyB1xfsiDHVMs+hmEw'
      };
      return resolve(result);
    }).catch(() => {
      return resolve({
        authorization: `${API_HEADERS.authName} ${localStorage.getItem(API_HEADERS.authToken)}`,
        cloudOrgId: 1,
		//CID:'l9M1U7NU4YWy9UbzzZr6jTEWle0KQf1s7NmRKVJmtz2j/OgysWUtv8buaXKyvMNkmkRSdEPoriO057jts8pUYVlSdUG8OUpLIjCGJp+dNxmY8OAyB1xfsiDHVMs+hmEw'
      });
    });
  });
}
// cloudOrgId: localStorage.getItem(
//   API_HEADERS.CID
// )
axios.interceptors.request.use(  
  (config) => {    
    
    store.dispatch({
      type: actionType.CLEAR_ERROR_PAGE,
      payload: null
    });
    if (config.headers.async === undefined) {      
      store.dispatch({
        type: loaderActionType.SET_VISIBILITY,
        payload: true
      });
    }
    return config;
  },
  (error) => {
    //console.log("Request was sent", error);
    return Promise.reject(error.response);
  }
);

axios.interceptors.response.use(
  (response) => {
    
    store.dispatch({
      type: loaderActionType.SET_VISIBILITY,
      payload: false
    });
    //console.log("response received", response);
    return response;
  },
  (error) => {
    store.dispatch({
      type: loaderActionType.SET_VISIBILITY,
      payload: false
    });
    // console.log("response received", error);
    if (error.message === 'Network Error') {
      store.dispatch({
        type: actionType.SET_ERROR_PAGE,
        payload: commonLabels.serverError
      });
    }

    if (error.response) {
      if (error.response.status === 401) {
        //console.log('logged out');
        getToken();
        localStorage.removeItem('adal.idtoken');
        store.dispatch({
          type: actionType.SET_ERROR_PAGE,
          payload: commonLabels.sessionTimeOut
        });
      } else if (error.response.status === 403) {
        if (error.response.data.isValidImUser === false || error.response.data.hasAccessToSection === false) {
          // window.location = routes.unAuthorizedAccess;
          store.dispatch({
            type: actionType.SET_ERROR_PAGE,
            payload: commonLabels.unAuthorizedAccess
          });
          // return Promise.reject(error.response);
        } else {
          error.response.data.message =
            error.response.data.message +
            " You don't have permission to access this resource/ perform an action. Please contact site administrator or Helpdesk team.";
        }
      } else if (error.response.status === 400) {
        const error1 = error.response.data.title ? error.response.data.title : '';
        const error2 = error.response.data.message ? error.response.data.message : '';
        error.response.data = {
          message: error1 || error2 || 'Something went wrong...!'
        };
      } else if (error.response.status === 404) {
        if (((error.response && error.response.config && error.response.config.url) || '').indexOf('graph') === -1) {
          error.response.data = {
            message: 'The object referenced by the path does not exist.'
          };
        } else {
          error.response.data = {
            message: ''
          };
        }
      }
    }
    errorHandler(error.response.data, error.response.status);
    return Promise.reject(error.response);
  }
);

export async function apiRequest ({ url, params }) {
  const headers = await getHeaders();
  try {
    const responses = await axios.get(url, {
      headers,
      ...{ params }
    });
    return responses.data;
  } catch (e) {
    return {};
  }
}



export async function apiRequest1 ({ url, params }) {
  store.dispatch({
    type: loaderActionType.SET_VISIBILITY,
    payload: true
  });
  const headers = await getHeaders();
  headers.async = true;
  try {
    const responses = await axios.get(url, {
      headers,
      ...{ params }
    });
    return camelCaseKeys(responses.data);
  } catch (e) {
    return {};
  }
}

export async function apiPost ({ url, postBody, params }) {
  // debugger;
  const headers = await getHeaders();
  // headers.async = true;
  try {
    const { data } = await axios.post(url, postBody, {
      headers,
      ...{ params }
    });
    return { data, success: data.success };
  } catch (e) {
    return { success: statusHandler(400), data: {} };
  }
}

export async function apiPostForExport ({ url, postBody, params }) {
  // debugger;
  const headers = await getHeaders();
  headers.async = true;
  try {
    const { data } = await axios.post(url, postBody, {
      headers,
      ...{ params },
      responseType: 'blob'
    });    
    return { success: true, data };
  } catch (e) {
    return { success: statusHandler(400), data: {} };
  }
}

// export async function apiRequestNoLoadder ({ url, params }) {
//   const headers = await getHeaders();
//   try {
//     const responses = await axios.get(url, {
//       headers,
//       ...{ params }
//     });
//     return camelCaseKeys(responses.data);
//   } catch (e) {
//     return {};
//   }
// }

export let cancelTokenSource = axios.CancelToken.source();

export function RenewCancelToken()
{
  cancelTokenSource = axios.CancelToken.source();
}

export function CancelRequest()
{
  cancelTokenSource.cancel();
  store.dispatch({
    type: loaderActionType.SET_VISIBILITY,
    payload: null
  });
}

export async function apiRequestNoLoadder({ url, params, fromCache = false, duration = 1 }) {
  const headers = await getHeaders();
  headers.async = true;
  try {
    if (fromCache === true) {
      return getDataFromStore(url, params, headers, duration);
    }
    const responses = await axios.get(url, {
      headers,      
      ...{ params }
    });

    return responses.data;
  } catch (e) {
    return {};
  }
}

export async function apiBatchRequestForExport (apiList) {
  const apiAxiosArr = [];
  const headers = await getHeaders();

  apiList.forEach(({ url, params }) => {
    apiAxiosArr.push(
      axios.get(url, {
        headers,
        ...{ params },
        responseType: 'blob'
      })
    );
  });

  const response = genericAPIForExport(apiAxiosArr, apiList);

  return response;
}

function genericAPIForExport (apiAxiosArr, apiList) {
  const responseData = {};
  return axios
    .all(apiAxiosArr)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res, i) => {
          responseData[apiList[i].key] = res;
        });

        return responseData;
      })
    )
    .catch(({ response }) => {
      // errorHandler(response);
      return response;
    });
}



// export async function apiBatchRequest (apiList) {
//   const apiAxiosArr = [];
//   const headers = await getHeaders();
//   // const data = await Promise.all(
//   //   apiList.map(({ url, params }) =>
//   //     axios.get(url, {
//   //       headers,
//   //       ...{ params }
//   //     })));

//   apiList.forEach(({ url, params }) => {
//     apiAxiosArr.push(
//       axios.get(url, {
//         headers,
//         ...{ params }
//       })
//     );
//   });

//   const response = genericAPI(apiAxiosArr, apiList);

//   return response;
// }
// export async function apiBatchRequestWithoutLoader (apiList) {
//   const apiAxiosArr = [];
//   const headers = await getHeaders();
//   headers.async = true;
//   // const data = await Promise.all(
//   //   apiList.map(({ url, params }) =>
//   //     axios.get(url, {
//   //       headers,
//   //       ...{ params }
//   //     })));

//   apiList.forEach(({ url, params }) => {
//     apiAxiosArr.push(
//       axios.get(url, {
//         headers,
//         cancelToken: cancelTokenSource.token,
//         ...{ params }
//       })
//     );
//   });

//   const response = genericAPI(apiAxiosArr, apiList);

//   return response;
// }

const localStore = [];
let currentlyExecuting = [];
export async function clearCacheData(key)
{
   store.dispatch({
    type: LocalCacheActionType.CLEAR_CACHE_DATA_BY_KEY,
    payload: key
  });
}
async function getDataFromStore(url, params, headers, duration = 1,clearCache = false,refreshDuration=1) {
  return new Promise(function (resolve, reject) {
    const key = url + JSON.stringify(params);
    if(clearCache == true)
    {
      clearCacheData(key);
    }
    if (currentlyExecuting.find(x => x.key === key) === undefined && getCachedData(key) === undefined) {
      axios.get(url, {
        headers,
        ...{ params }
      }).then((responses) => {
        if (responses.data.data.length > 0) {
        currentlyExecuting.push({ key: key });
        localStore.push({ key: key, data: responses.data });
        store.dispatch({
          type: LocalCacheActionType.SET_CACHE_DATA,
          payload: { key: key, data: responses.data }
        });
        setTimeout((data) => {
          data && store.dispatch({
            type: LocalCacheActionType.CLEAR_CACHE_DATA_BY_KEY,
            payload: data.key
          });
        }, duration * 60 * 1000);
        currentlyExecuting = currentlyExecuting.filter(x => x.key !== key);
        return resolve(_.cloneDeep(responses.data));
       } });
    } else {
      return resolve(waitFor(getCachedData, key));
    }
  }
  );
}

async function waitFor(f, key) {
  let r = f(key);
  while (!r) {
    // console.log('Not yet, waiting more');
    await delay(500);
    r = f(key);
  }
  return _.cloneDeep(r.data);
}
function delay(delay) {
  return new Promise((resolve, reject) => setTimeout(resolve, delay));
}

function getCachedData(key) {
  return (((store.getState() || {}).localCache || {}).cachedData || []).find(x => x.key === key);
}
export async function apiBatchRequest(apiList) {
  const apiAxiosArr = [];
  const headers = await getHeaders();
  // const data = await Promise.all(
  //   apiList.map(({ url, params }) =>
  //     axios.get(url, {
  //       headers,
  //       ...{ params }
  //     })));
  const cachedResponse = {};
  const cachedRequest = [];
  const nonCachedRequest = [];
  const cachedRequestKey = [];
  apiList.forEach(({ url, params, key, fromCache = false, duration = 10,clearCache = false ,refreshDuration=1}) => {
    if (fromCache === false) {
		if(params !== undefined && params.sectionId == -1)
			return;
      apiAxiosArr.push(
        axios.get(url, {
          headers,
          ...{ params }
        })
      );
      nonCachedRequest.push({ key: key });
    } else {
      cachedRequest.push(getDataFromStore(url, params, headers, duration,clearCache,refreshDuration));
      cachedRequestKey.push(key);
    }
  });

  const response = genericAPI(apiAxiosArr, nonCachedRequest);
  const arrayResponse = await Promise.all(cachedRequest);
  arrayResponse.forEach((x, i) => {
    cachedResponse[cachedRequestKey[i]] = x;
  });
  const result = await response;
  return { ...result, ...cachedResponse };
}

export async function apiBatchRequestWithoutLoader(apiList) {
  const apiAxiosArr = [];
  const headers = await getHeaders();
  headers.async=true;
  // const data = await Promise.all(
  //   apiList.map(({ url, params }) =>
  //     axios.get(url, {
  //       headers,
  //       ...{ params }
  //     })));
  const cachedResponse = {};
  const cachedRequest = [];
  const nonCachedRequest = [];
  const cachedRequestKey = [];
  apiList.forEach(({ url, params, key, fromCache = false, duration = 10,clearCache=false,refreshDuration=1 }) => {
    if (fromCache === false) {
      apiAxiosArr.push(
        axios.get(url, {
          headers,
          ...{ params }
        })
      );
      nonCachedRequest.push({ key: key });
    } else {
      cachedRequest.push(getDataFromStore(url, params, headers, duration,clearCache ,refreshDuration));
      cachedRequestKey.push(key);
    }
  });

  const response = genericAPI(apiAxiosArr, nonCachedRequest);
  const arrayResponse = await Promise.all(cachedRequest);
  arrayResponse.forEach((x, i) => {
    cachedResponse[cachedRequestKey[i]] = x;
  });
  const result = await response;
  return { ...result, ...cachedResponse };
}
function genericAPI (apiAxiosArr, apiList) {
  const responseData = {};
  return axios
    .all(apiAxiosArr)
    .then(
      axios.spread((...responses) => {
        responses.forEach((res, i) => {
          responseData[apiList[i].key] = res.data;
        });

        return camelCaseKeys(responseData);
      })
    )
    .catch(({ response }) => {
      // errorHandler(response);
      return response;
    });
}

export async function apiPut (url, postBody, params) {
  const headers = await getHeaders();
  try {
    const { data, status } = await axios.put(url, postBody, {
      headers,
      ...{ params }
    });
    return { data, success: statusHandler(status) };
  } catch (e) {
    return { success: statusHandler(400), data: {} };
  }
}

export async function apiPostNoLoader ({ url, postBody, params }) {
  const headers = await getHeaders();
  headers.async = true;
  try {
    const { data } = await axios.post(url, postBody, {
      headers,
      ...{ params }
    });
    return { data, success: data.success };
  } catch (e) {
    return { success: statusHandler(400), data: {} };
  }
}

export async function apiDelete ({ url, params }) {
  const headers = await getHeaders();
  const response = await axios.delete(url, {
    headers,
    ...{ params }
  });
  return response;
}

export function apiDeleteRequest({ url, params = {} }) {
	return axios
		.delete(url, {
			headers: getHeaders(),
			...{ params },
			cancelToken: source.token
		})
		.then(responses => {
			return responses.data;
		})
		.catch(({ response }) => {
			return response;
		});
}

function errorHandler (response, code) {
  if (!response) return;
  const errorMsg1 = response.message ? response.message : '';
  const errorMsg2 = response.error && response.error.message ? response.error.message : '';

  ShowToast(errorMsg1 || errorMsg2, toastType.ERROR, 5000, code);
}

function statusHandler (status) {
  if (!status) return;
  switch (status) {
    case 200:
      return true;

    default:
      return false;
  }
}

export function getSpsAPiRequest (url) {
	    const spsHeaders = {
	      authorization: `${API_HEADERS.authName} ${SPSToken}`,
        async: true
	    };
  //spsHeaders.
	    return axios
	      .get(url, {
	        headers: spsHeaders
	      })
	      .then(responses => {
	        return responses.data;
	      })
	      .catch(data => {
	        return data;
	      });
	  }
	  
	  export function spsApiPost ({ url, postBody, params }) {
	    const spsHeaders = {
	      authorization: `${API_HEADERS.authName} ${SPSToken}`
	    };
      spsHeaders.async = true;
	    return axios
	      .post(url, postBody, {
	        headers: spsHeaders,
	        ...{ params }
	      })
	      .then(({ data, status }) => {
	        return { data, success: statusHandler(status) };
	      })
	      .catch(({ error }) => {
	        // return { success: statusHandler(400), data: {} };
	      });
	  }
	  
	  export function uploadSpsAPiRequest (url, file, isAddContent = false) {
	    const spsHeaders = {
	      authorization: `${API_HEADERS.authName} ${SPSToken}`,
	      ...(isAddContent && {
	        'Content-Length': file.size,
	        'Content-Range': `bytes 0-${file.size - 1}/${file.size}`
	      })
	    };
  spsHeaders.async = true;
	    return axios
	      .put(url, file, {
	        headers: spsHeaders
	      })
	      .then(responses => {
	        return responses.data;
	      })
	      .catch(({ response }) => {
	        return response;
	      });
	  }
	  
	  export function uploadSpsMetadataAPiRequest (url, metadata) {
	    const spsHeaders = {
	      authorization: `${API_HEADERS.authName} ${SPSToken}`
	    };
  spsHeaders.async = true;
	    return axios
	      .patch(url, metadata, {
	        headers: spsHeaders
	      })
	      .then(responses => {
	        this.props.getSpsData();
	        return responses.data;
	      })
	      .catch(({ response }) => {
	        return response;
	      });
	  }
	  
	  export function deleteSpsApiRequest (url) {
	    const spsHeaders = {
	      authorization: `${API_HEADERS.authName} ${SPSToken}`
	    };
	    return axios
	      .delete(url, {
	        headers: spsHeaders
	      })
	      .then(responses => {
	        // this.props.getSpsData();
	        return true;
	      })
	      .catch(error => {
	//        console.log(error, 'Delete API');
	        return true;
	      });
	  }
	

import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  TextField,
  Tooltip,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import PageHeader from "../../common/PageHeader";
import {
  apiBatchRequest,
  apiPost,
  apiPut,
  apiRequestNoLoadder,
  CancelRequest,
  RenewCancelToken,
} from "../../../utils/api_service";
import {
  isInputValid,
  IsSectionVisible,
  UpdateColumnWithConfiguration,
  UpdateFieldsWithConfiguration,
} from "../../../utils/helper";
import FormGenerator from "../../common/FormGenerator";
import { API_URL } from "../../../constants/api";
import { controlType } from "../../../constants/common";
import { camelCase } from "lodash";
import camelcaseKeys from "camelcase-keys";
import AutoSearch from "../../common/AutoSearch/AutoSearch";
import { ShowToast, toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import ViewHistoryData from "../../common/ViewHistoryData/ViewHistoryData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SECTIONS } from "../../../constants/appConstants";
import { GridTable } from "../../common/Table/Table";
import {
  buildingColumnsForDetail,
  leaseCompsColumnsForDetails,
  otherCompanyAddressColumns,
  otherCompanyContactColumns,
} from "../../../constants/MockData";
import EditBuilding from "../Building/EditBuilding";
const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};
export default class EditIndBuildingOwners extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      getCompaniesDetails: [],
      companySummaryData: [],
      companyMasterDetails: [],
      companyMasterData: [],
      ownerTypeData:
        props.ownerTypeData != undefined ? props.ownerTypeData : [],
      getIndustrySector: [],
    //  getProductTypeMaster:
    //    props.getProductTypeMaster != undefined
    //      ? props.getProductTypeMaster
    //      : [],
      lastModifiedValue: "",
      isEditEnabled: this.props.isInAddMode,
      openDialog: true,
      getCompaniesDetailsResponse: [],
      isDataSaved: false,
      isSearchedClicked: false,
      showConfirmDialog: false,
      companyId: -1,
      getMasterDetail: [],
      getCity: [],
      getState: [],
      readOnly: false,
      reloadZipChanges: false,
      defaultOwnerType: [{ companyRole: "Owner", companyRoleId: 6 }],
      getHistoryDetailByField: [],
      openHistoryform: false,
      isHistoryByField: false,
      showProperty: false,
      showLeaseComps: false,
      showEdit: true,
      getHistory: [],
      propertySummaryData: [],
      leaseCompSummaryData: [],
      selectedBuildingRowId: -1,
      ViewBuildingTitle: "Edit",
      actionBuildingType: "Edit",
      isBuildingInAddMode: false,
      openaddform: false,
      openBuildingAddform: false,
      getOtherCompanyAddress: [],
      getOtherCompanyAddressConfiguration: [],
      getOtherCompanyContact: [],
      getOtherCompanyContactConfiguration: [],
      selectedReplaceId: -1,
      isClear: false,
      selectedContactId: -1,
      selectedId: this.props.selectedCompanyId,
      isOwnedPropertiesChanged: false,
      isOwnedPropertiesApiCalled: false,
      isLeaseCompsChanged: false,
      isLeaseCompsApiCalled: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.loadMarketSubMarket(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  getCityByState = (state, sourceIdField) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getCity,
        key: "getCity",
        params: { stateId: sourceIdField.value },
      },
    ])
      .then(({ getCity }) => {
        if (getCity.success) {
          this.setState({
            getCity: camelcaseKeys(getCity.data) || [],
            reloadZipChanges: !this.state.reloadZipChanges,
            reloadCompany: !this.state.reloadCompany,
            addNewformComponent: this.getNewZipdetailForm(
              this.state.getMasterDetail,
              camelcaseKeys(getCity.data) || [],
              !this.state.reloadZipChanges
            ),
          });
        }
      })
      .catch(() => {});
  };

  onBuildingBackClick = () => {
    this.getOwnedPropertyData(this.props.selectedCompanyId);
    this.setState({
      openBuildingAddform: false,
      selectedBuildingRowId: undefined,
      reloadTable: !this.state.reloadTable,
    });
  };

  handleChange = (event) => {
    setState({ lastModifiedValue: event.target.value });
  };
  getData = (companyId) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuildingOwners.getBuildingOwnersDetails,
        key: "getCompaniesDetails",
        params: { companyId: companyId },
      },
    //  {
    //    url: API_URL.IndustrialBuildingOwners.getOtherCompanyAddress,
    //    key: "getOtherCompanyAddress",
    //    params: { companyId: companyId },
    //  },
    //  {
    //    url: API_URL.IndustrialBuildingOwners.getOtherCompanyContact,
    //    key: "getOtherCompanyContact",
    //    params: { companyId: companyId },
    //  },
	{
        url: API_URL.BuildingOwners.getOtherCompanyContactAndAddress,
        key: "getOtherCompanyContactAndAddress",
        params: { companyId: companyId },
      },
    ])
      .then((getCompaniesDetails) => {
        this._asyncRequest = null;
        if (getCompaniesDetails.getCompaniesDetails.success == true) {
          this.setState({
            getCompaniesDetails:
              camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
            getHistory:
              camelcaseKeys(
                getCompaniesDetails.getCompaniesDetails.configuration
              ) || [],
            getOtherCompanyAddress:
              camelcaseKeys(getCompaniesDetails.getOtherCompanyContactAndAddress.companyAddress.data) ||
              [],
            getOtherCompanyAddressConfiguration:
              camelcaseKeys(
                getCompaniesDetails.getOtherCompanyContactAndAddress.companyAddress.configuration
              ) || [],
            getOtherCompanyContact:
              camelcaseKeys(getCompaniesDetails.getOtherCompanyContactAndAddress.companyContact.data) ||
              [],
            getOtherCompanyContactConfiguration:
              camelcaseKeys(
                getCompaniesDetails.getOtherCompanyContactAndAddress.companyContact.configuration
              ) || [],
            reloadCompany: !this.state.reloadCompany,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getRequiredData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.buildingOwnerMasterDetails,
        key: "companyMasterData",
        params: { businessLineId: 2 },
      },
    //  {
    //    url: API_URL.Master.getIndustrySector,
    //    key: "getIndustrySector",
    //    params: { businessLineId: 2 },
    //  },
    //  {
    //    url: API_URL.IndustrialBuildingOwners.buildingOwnersSummaryData,
    //    key: "companySummaryData",
    //    params: { marketId: this.state.selectedMarketId },
    //  },
    ])
      .then((getCompaniesDetails) => {
        this._asyncRequest = null;
        if (getCompaniesDetails.companyMasterData.success == true) {
          this.setState({
            //companySummaryData:
            //  camelcaseKeys(getCompaniesDetails.companySummaryData.data) || [],
            //getIndustrySector:
            //  camelcaseKeys(getCompaniesDetails.getIndustrySector.data) || [],
            companyMasterData:
              camelcaseKeys(getCompaniesDetails.companyMasterData.data) || [],
            reloadCompany: !this.state.reloadCompany,
          });
          if (
            this.state.ownerTypeData.length == 0 ||
            this.state.ownerTypeData == undefined
          )
            this.loadOwnerTypeData();
        //  if (
        //    this.state.getProductTypeMaster.length == 0 ||
        //    this.state.getProductTypeMaster == undefined
        //  )
        //    this.loadProductTypeMaster();
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  componentWillUnmount() {
    CancelRequest();
  }

  componentDidMount() {
    RenewCancelToken();
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuildingOwners.getBuildingOwnersDetails,
        key: "getCompaniesDetails",
        params: { companyId: this.props.selectedCompanyId },
      },
    //  {
    //    url: API_URL.IndustrialBuildingOwners.getOtherCompanyAddress,
    //    key: "getOtherCompanyAddress",
    //    params: { companyId: this.props.selectedCompanyId },
    //  },
    //  {
    //    url: API_URL.IndustrialBuildingOwners.getOtherCompanyContact,
    //    key: "getOtherCompanyContact",
    //    params: { companyId: this.props.selectedCompanyId },
    //  },
	{
        url: API_URL.BuildingOwners.getOtherCompanyContactAndAddress,
        key: "getOtherCompanyContactAndAddress",
        params: { companyId: this.props.selectedCompanyId },
      },
      {
        url: API_URL.Master.getIndustrySector,
        key: "getIndustrySector",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getCompaniesDetails) => {
        this._asyncRequest = null;
        if (getCompaniesDetails.getCompaniesDetails.success == true) {
          this.setState({
            getCompaniesDetails:
              camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
            getCompaniesDetailsResponse:
              camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
            getHistory:
              camelcaseKeys(
                getCompaniesDetails.getCompaniesDetails.configuration
              ) || [],
            getOtherCompanyAddress:
              camelcaseKeys(getCompaniesDetails.getOtherCompanyContactAndAddress.companyAddress.data) ||
              [],
            getOtherCompanyAddressConfiguration:
              camelcaseKeys(
                getCompaniesDetails.getOtherCompanyContactAndAddress.companyAddress.configuration
              ) || [],
            getOtherCompanyContact:
              camelcaseKeys(getCompaniesDetails.getOtherCompanyContactAndAddress.companyContact.data) ||
              [],
            getOtherCompanyContactConfiguration:
              camelcaseKeys(
                getCompaniesDetails.getOtherCompanyContactAndAddress.companyContact.configuration
              ) || [],
            getIndustrySector:
              camelcaseKeys(getCompaniesDetails.getIndustrySector.data) || [],
          });
          if (this.props.isInAddMode == true) this.getRequiredData();
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }

  getHistoryDataByField = (val) => {
    const params = {
      entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
      sectionDetailId: val.sectionDetailId,
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      dbColumn: val.dbColumn,
      businessLineId: 2,
    };

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetHistoryDetailByField,
        key: "getHistoryDetailByField",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDetailByField) => {
        this._asyncRequest = null;
        if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
          this.setState({
            getHistoryDetailByField:
              camelcaseKeys(
                getHistoryDetailByField.getHistoryDetailByField.data
              ) || [],
            openHistoryform: true,
            isHistoryByField: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  handleClose = (e, val) => {
    if (val == "blur") return;
    this.setState({ openHistoryform: true });
  };

  handleChange = (e, val) => {
    const params = {
      entityPrimaryKey: val.entityPrimaryKey,
      modifiedDate: moment(moment(val.createdDate).toDate())
        .format("MM-DD-YYYY HH:mm:ss")
        .toString(),
      sectionId: val.sectionId,
      dbTable: null,
      businessLineId: 2,
    };
    //console.log("e", e, "val", val);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuilding.industrialGetHistoryDataByUser,
        key: "getHistoryDataByUser",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDataByUser) => {
        this._asyncRequest = null;
        if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
          this.setState({
            getHistoryDataByUser:
              camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
              [],
            openHistoryform: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  enableEdit = () => {
    this.setState({
      isEditEnabled: true,
    });
    this.getRequiredData();
    this.forceUpdate();
  };

  getCompaniesChanges = (formFields) => {
    this.setState(
      {
        companyMasterDetails: formFields,
        reloadCompany: !this.state.reloadCompany,
        isDataSaved: true,
        isSearchedClicked: false,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  onBackClick = () => {
    this.props.onBackClick();
  };
  validateForm = (actionType) => {
    const formData = this.state.companyMasterDetails;
    let isValid = true;
    let breakCondition = false;
    if (formData.length == 0 && actionType == "Edit") {
      const msg =
        "No change detected. Please make necessary changes and try again.";
      isValid = false;
      breakCondition = true;
      ShowToast(msg, toastType.ERROR, 5000, 500);
    }
    if (formData.length == 0 && actionType == "Add") {
      const msg = "Please fill all the mandatory fields.";
      isValid = false;
      breakCondition = true;
      ShowToast(msg, toastType.ERROR, 5000, 500);
    }
    formData.forEach((x) => {
      if (
        x.isRequired == true &&
        (x.attributeValue == undefined ||
          x.attributeValue == null ||
          x.attributeValue.trim() == "") &&
        breakCondition != true
      ) {
        const msg = "Please fill all the mandatory fields";
        isValid = false;
        breakCondition = true;
        ShowToast(msg, toastType.ERROR, 5000, 500);
      }
    });
    return isValid;
  };
  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
    //console.log(formElementFields[0].attributeValue);
    let IsValid = true;
    formElementFields.forEach((ele) => {
      isInputValid(ele.attributeValue, ele, this.state.companyMasterDetails);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }
    });
    //console.log(formElementFields);
    stateToBeUpdate = {
      ...stateToBeUpdate,
      companyMasterDetails: formElementFields,
      reloadCompany: !this.state.reloadCompany,
    };
    this.setState(stateToBeUpdate);

    return IsValid;
  };
  saveCompanyDetails = () => {
    const companyData = this.state.companyMasterDetails;
    let isValid = this.validateForm(this.props.actionType);
    let isInputValid = this.validateRequiredFields(companyData);
    if (
      (isValid == false && isInputValid == false) ||
      (isValid == true && isInputValid == false) ||
      (isValid == false && isInputValid == true)
    ) {
      isInputValid == false
        ? ShowToast(
            "There is validation error on page.",
            toastType.ERROR,
            5000,
            500
          )
        : null;
      return false;
    } else {
      companyData.forEach((x) => {
        x.attributeValue =
          x.attributeValue != null
            ? x.attributeValue.toString().trim()
            : x.attributeValue;

        if (
          _.camelCase(x.dbColumn) === _.camelCase("ZipId") ||
          _.camelCase(x.dbColumn) === _.camelCase("MarketId") ||
          _.camelCase(x.dbColumn) === _.camelCase("SubMarketId")
        ) {
          if (x.attributeValue != null)
            x.attributeValue = x.attributeValue.toString();
        }
      });

      if (this.props.actionType === "Edit") {
        const companyId = companyData.filter(
          (x) => _.camelCase(x.dbColumn) === _.camelCase("CompanyId")
        );

        apiPut(
          API_URL.IndustrialBuildingOwners.updateBuildingOwnersDetail,
          companyData,
          {
            CompanyId: companyId[0].attributeValue,
          }
        )
          .then(({ data: response }) => {
            if (response.success === true) {
              ShowToast(
                "Company updated successfully",
                toastType.SUCCESS,
                5000,
                500
              );
              this.setState({
                isDataSaved: false,
              });
              this.props.reloadData();
              //this.getData();
            } else {
              ShowToast(response.message, toastType.error, 5000, 500);
            }
          })
          .catch(() => {
            ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
          });
      } else {
        //companyData[0].attributeValue=-1;

        apiPost({
          url: API_URL.IndustrialBuildingOwners.addBuildingOwnersDetail,
          params: {},
          postBody: companyData,
        })
          .then(({ data: response }) => {
            if (response.success === true) {
              ShowToast(
                "Company added successfully",
                toastType.SUCCESS,
                5000,
                500
              );
              if (this.props.isRedirectedFromTenant == true) {
                this.props.onBackClick();
                this.props.reloadPropertyOwnerData(companyData);
              } else {
                this.props.reloadData();
              }
            } else {
              ShowToast(response.message, toastType.error, 5000, 500);
            }
          })
          .catch(() => {
            ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
          });
      }
    }
  };

  onSearch = (event, value, e) => {
    const { isDataSaved } = this.state;
    let companyId = this.state.companySummaryData.find(
      (m) => m.companyName == value
    ).companyId;
    this.setState({
      companyId: companyId,
      isSearchedClicked: true,
      isOwnedPropertiesChanged: true,
      isOwnedPropertiesApiCalled: false,
      isLeaseCompsChanged: true,
      isLeaseCompsApiCalled: false,
    });

    if (isDataSaved == false) {
      this.setState({ getHistory: [] });
      this.getData(companyId);
      this.setState({
        selectedId: companyId,
      });
    }
    //console.log(event);
  };
  onSearchBuildingOwner = () => {
    this.setState({
      isSearchedClicked: false,
      isDataSaved: false,
    });
    this.setState({ getHistory: [] });
    this.getData(this.state.companyId);
  };
  onClickHandel(e) {
    if (e == true) {
      this.onSearchBuildingOwner();
      this.setState({
        isSearchedClicked: false,
      });
    } else {
      this.setState({
        isSearchedClicked: false,
      });
    }
  }

  getTenantData = (companyId) => {
    if (companyId) {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.IndustrialLeaseComps.leaseCompsSummaryData,
          key: "leaseCompSummaryData",
          params: {
            companyId: companyId,
            marketId: this.state.selectedMarketId,
          },
        },
      ])
        .then((leaseCompSummaryData) => {
          this._asyncRequest = null;
          if (leaseCompSummaryData.leaseCompSummaryData.success == true) {
            this.setState({
              leaseCompSummaryData:
                camelcaseKeys(leaseCompSummaryData.leaseCompSummaryData.data) ||
                [],
              configuration:
                camelcaseKeys(
                  leaseCompSummaryData.leaseCompSummaryData.configuration
                ) || [],
              reloadTable: !this.state.reloadTable,
              detailView: false,
              selectedEditRowId: undefined,
              isLeaseCompsChanged: false,
              isLeaseCompsApiCalled: true,
            });
            this.forceUpdate();
          } else {
            ShowToast(
              "Something went wrong",
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  getOwnedPropertyData = (companyId) => {
    if (companyId) {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.IndustrialBuilding.industrialBuildingSummaryData,
          key: "propertySummaryData",
          params: {
            companyId: companyId,
            marketId: this.state.selectedMarketId,
          },
        },
      ])
        .then((propertySummaryData) => {
          this._asyncRequest = null;
          if (propertySummaryData.propertySummaryData.success == true) {
            this.setState({
              propertySummaryData:
                camelcaseKeys(propertySummaryData.propertySummaryData.data) ||
                [],
              configuration:
                camelcaseKeys(
                  propertySummaryData.propertySummaryData.configuration
                ) || [],
              reloadTable: !this.state.reloadTable,
              detailView: false,
              selectedEditRowId: undefined,
              isOwnedPropertiesChanged: false,
              isOwnedPropertiesApiCalled: true,
            });
            this.forceUpdate();
          } else {
            ShowToast(
              "Something went wrong",
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  onRowClick = (data) => {
    this.setState({
      openBuildingAddform: true,
      selectedBuildingRowId: data.buildingId,
      ViewBuildingTitle: "Edit",
      actionBuildingType: "Edit",
    });
  };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
            }}
          >
            <span className="default-text span-link">
              {cellInfo.buildingName ? cellInfo.buildingName : "---"}
            </span>
          </a>
        </Tooltip>
      </>
    );
  };

  renderActionColumn2 = (cellInfo) => {
    if (cellInfo.companyContactmappingId != undefined) {
      // id= cellInfo.companyContactmappingId ;
      this.setState({
        selectedContactId: cellInfo.companyContactmappingId,
      });
    }

    //this.forceUpdate();
    return (
      <>
        <Tooltip title="Use this Record">
          <IconButton aria-label={"refreshData"} size="small">
            <CheckIcon
              fontSize="small"
              size="small"
              onClick={(e) => {
                // this.setState({isClear:false})
                this.replaceContactRecord(e, cellInfo);
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton aria-label={"refreshData"} size="small">
            <DeleteIcon
              fontSize="small"
              size="small"
              onClick={(e) => {
                //this.setState({isClear:true})
                this.deleteContactRecord(e, cellInfo);
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  replaceRecord = (e, cellInfo) => {
    apiPost({
      url: API_URL.IndustrialBuildingOwners.updateOtherCompanyAddress,
      params: {
        companyAddressmappingId: cellInfo.companyaddressmappingId,
        isClear: false,
      },
      postBody: {},
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        this.getData(this.props.selectedCompanyId);
        ShowToast(
          "Record replaced successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.setState({
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isClear: false,
        });
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  deleteRecord = (e, cellInfo) => {
    apiPost({
      url: API_URL.IndustrialBuildingOwners.updateOtherCompanyAddress,
      params: {
        companyAddressmappingId: cellInfo.companyaddressmappingId,
        isClear: true,
      },
      postBody: {},
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        this.getData(this.props.selectedCompanyId);
        ShowToast("Record deleted successfully.", toastType.SUCCESS, 5000, 500);

        this.setState({
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isClear: false,
        });
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  replaceContactRecord = (e, cellInfo) => {
    apiPost({
      url: API_URL.IndustrialBuildingOwners.updateOtherCompanyContact,
      params: {
        companyContactmappingId: cellInfo.companyContactmappingId,
        isClear: false,
      },
      postBody: {},
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        this.getData(this.props.selectedCompanyId);
        ShowToast(
          "Record replaced successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.setState({
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isClear: false,
        });
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  deleteContactRecord = (e, cellInfo) => {
    apiPost({
      url: API_URL.IndustrialBuildingOwners.updateOtherCompanyContact,
      params: {
        companyContactmappingId: cellInfo.companyContactmappingId,
        isClear: true,
      },
      postBody: {},
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        this.getData(this.props.selectedCompanyId);
        ShowToast("Record deleted successfully.", toastType.SUCCESS, 5000, 500);

        this.setState({
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isClear: false,
        });
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  renderActionColumn1 = (cellInfo) => {
    let id = -1;
    if (cellInfo.companyaddressmappingId != undefined) {
      id = cellInfo.companyaddressmappingId;
      this.setState({
        selectedReplaceId: id,
      });
    }

    //this.forceUpdate();
    return (
      <>
        <Tooltip title="Use this Record">
          <IconButton aria-label={"refreshData"} size="small">
            <CheckIcon
              fontSize="small"
              size="small"
              onClick={(e) => {
                //this.setState({isClear:false})
                this.replaceRecord(e, cellInfo);
              }}
            />
          </IconButton>
        </Tooltip>

        <Tooltip title="Delete">
          <IconButton aria-label={"refreshData"} size="small">
            <DeleteIcon
              fontSize="small"
              size="small"
              onClick={(e) => {
                // this.setState({isClear:true})
                this.deleteRecord(e, cellInfo);
              }}
            />
          </IconButton>
        </Tooltip>
      </>
    );
  };

  onInputChange = (value) => {
    const params = {
      businessLineId: 2,
      searchText: value,
    };
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companySummaryData }) => {
        this.setState(
          {
            companySummaryData: camelcaseKeys(companySummaryData) || [],
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
    //}
  };

  getNewZipdetailForm = (newZipDetail, cityList, reloadZipChanges) => {
    // const reloadZip=reloadZipChanges;

    const newZipDetailConfig = [
      { key: "city", type: controlType.DropDown, multiple: false },
      { key: "zip", type: controlType.TextBox, multiple: false },
      { key: "state", type: controlType.DropDown, multiple: false },
    ];
    const newZipDropdownSource = [
      {
        key: "city",
        option: "city",
        idField: "cityId",
        source: cityList,
        // onChange: this.newClientOptionChange
      },
      {
        key: "state",
        option: "state",
        idField: "stateId",
        source: this.state.getState,
        onChange: this.getCityByState,
      },
    ];
    return (
      <FormGenerator
        key={"addNewClient"}
        parentKey={"addNewClient"}
        gridColumn={4}
        dropDownSource={newZipDropdownSource}
        formFields={UpdateFieldsWithConfiguration(
          newZipDetail,
          newZipDetailConfig
        )}
        validationMessages={[]}
        ReloadForm={!reloadZipChanges} //this.state.reloadCompany
        saveChanges={this.SaveZipDetails}
        Editable={this.state.readOnly !== true}
        partialForm={false}
      ></FormGenerator>
    );
  };

  loadOwnerTypeData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.ownerTypeData,
        key: "ownerTypeData",
        params: { businessLineId: 2 },
      },
    ])
      .then((ownerTypeData) => {
        this._asyncRequest = null;
        if (ownerTypeData.ownerTypeData.success == true) {
          this.setState(
            {
              ownerTypeData:
                camelcaseKeys(ownerTypeData.ownerTypeData.data) || [],
              reloadCompany: !this.state.reloadCompany,
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

//  loadProductTypeMaster = () => {
//    this._asyncRequest = apiBatchRequest([
//      {
//        url: API_URL.Master.getProductTypeMaster,
//        key: "getProductTypeMaster",
//      },
//    ])
//      .then((getProductTypeMaster) => {
//        this._asyncRequest = null;
//        if (getProductTypeMaster.getProductTypeMaster.success == true) {
//          this.setState(
//            {
//              getProductTypeMaster:
//                camelcaseKeys(getProductTypeMaster.getProductTypeMaster.data) ||
//                [],
//              reloadCompany: !this.state.reloadCompany,
//            },
//            () => {
//              this.forceUpdate();
//            }
//          );
//        } else {
//          ShowToast(
//            "Something went wrong",
//            toastType.ERROR,
//            5000,
//            500,
//            "Server error"
//          );
//        }
//      })
//      .catch(() => {
//        this._asyncRequest = null;
//      });
//  };

  render() {
    const {
      propertySummaryData,
      leaseCompSummaryData,
      isEditEnabled,
      companySummaryData,
      lastModifiedValue,
      getCompaniesDetails,
      ownerTypeData,
      getIndustrySector,
    //  getProductTypeMaster,
      companyMasterData,
      getState,
      getHistoryDataByUser,
      getHistoryDetailByField,
      isHistoryByField,
      getHistory,
      getOtherCompanyAddress,
      getOtherCompanyContact,
    } = this.state;

    const filterConfig = [
      {
        multiple: true,
        type: controlType.DropDown,
        minTag: 1,
        source: companySummaryData,
        key: "companyName",
        placeholder: "",
        title: "Company Name",
      },
    ];
    var ownerName = "";
    if (getCompaniesDetails.length > 0) {
      ownerName = getCompaniesDetails.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }

    const addnewOption = true;
    //const ServiceTypeMasterData = this.props.serviceTypeMasterData;
    const companySeparatorConfig = [
      {
        separatorBeforeKey: "CompanyName",
        order: 1,
        row: 1,
        sectionColumn: 3,
        label: "Company Details",
      },
      {
        separatorBeforeKey: "Add1",
        order: 1,
        row: 1,
        sectionColumn: 3,
        label: "Address Details",
      },
      {
        separatorBeforeKey: "Phone1",
        order: 1,
        row: 1,
        sectionColumn: 3,
        label: "Contact Details",
      },
      {
        separatorBeforeKey: "Notes",
        order: 1,
        row: 1,
        sectionColumn: 3,
        label: "Other Details",
      },
    ];

    const dropDownSource = [
      {
        key: "companyRole",
        option: "companyRole",
        idField: "companyRoleId",
        source: ownerTypeData,
        //defaultData:{defaultOwnerType}
      },

      {
        key: "industryName",
        option: "industryName",
        idField: "industryId",
        source: getIndustrySector,
      },
    //  {
    //    key: "productType",
    //    option: "productType",
    //    idField: "productTypeId",
    //    source: getProductTypeMaster,
    //  },
    ];

    const DetailsFormConfig = [
      {
        key: "companyName",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "add1",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "add2",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "zip",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "notes",
        type: controlType.TextArea,
        multiple: false,
      },
      { key: "companyRole", type: controlType.DropDown, multiple: true },
      { key: "industryName", type: controlType.DropDown, multiple: true },

      { key: "businessLineName", type: controlType.DropDown, multiple: true },
      { key: "productType", type: controlType.DropDown, multiple: true },
      { key: "market", type: controlType.DropDown, multiple: false },
      { key: "subMarket", type: controlType.DropDown, multiple: false },
    ];
    const ownerNam = ownerName != undefined ? ownerName : this.props.ViewTitle;
    return (
      <>
        {this.state.openHistoryform && (
          <Dialog open={this.state.openHistoryform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Update History
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openHistoryform: false,
                      isHistoryByField: false,
                    });
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <ViewHistoryData
                sourceData={getHistoryDataByUser}
                getHistoryDetailByField={getHistoryDetailByField}
                dropDownSource={dropDownSource}
                isHistoryByField={isHistoryByField}
              ></ViewHistoryData>
            </div>
          </Dialog>
        )}
        {this.state.isDataSaved && this.state.isSearchedClicked && (
          <ConfirmDialog
            IsOpen={this.state.isSearchedClicked}
            Message={
              "There are unsaved changes. Do you still want to search another Company"
            }
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandel(isValid);
              } else {
                this.onClickHandel(isValid);
              }
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}

        {this.state.openaddform && (
          <Dialog open={this.state.openaddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  {this.state.addformtitle}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openaddform: false,
                      addNewformComponent: undefined,
                    });
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <Paper>{this.state.addNewformComponent}</Paper>
            </div>
          </Dialog>
        )}

        {isEditEnabled || this.props.isInAddMode ? (
          <div className="row header-container">
            <div className="col-md-4 col-lg-4">
              <div className="row">
                <PageHeader
                  btnText={"Companies"}
                  onBackClick={this.onBackClick}
                  title={
                    this.props.isInAddMode
                      ? "/ " + this.props.ViewTitle
                      : "/ " + ownerNam
                  }
                  style={{ color: "#00B0B9" }}
                ></PageHeader>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              {this.state.showEdit == true && (
                <AutoSearch
                  placeholder="Type at least 3 characters to Search another Company"
                  //  placeholder="Search another Company"
                  isEditEnabled={this.state.isEditEnabled}
                  sourceData={this.state.companySummaryData}
                  filterConfig={filterConfig}
                  onSearch={this.onSearch}
                  onInputChange={this.onInputChange}
                  isRedirect={true}
				  isBuildingOwner={true}
                ></AutoSearch>
              )}
              {/* <SearchBar></SearchBar> */}
            </div>
            <div className="col-md-4 col-lg-4 end-sm">
              {this.state.showEdit == true && (
                <>
                  <Button
                    size="small"
                    style={{ marginTop: "5px" }}
                    className="btn-clear"
                    onClick={() => {
                      this.setState({
                        getCompaniesDetails: JSON.parse(
                          JSON.stringify([
                            ...this.state.getCompaniesDetailsResponse,
                          ])
                        ),
                        reloadCompany: !this.state.reloadCompany,
                      });
                    }}
                  >
                    <span className="tool-icon-text">
                      {this.props.isInAddMode == true ? "Clear All" : "Undo"}
                    </span>
                  </Button>
                  <Button
                    size="small"
                    className="notop-action-button"
                    onClick={() => {
                      this.props.onBackClick();
                    }}
                  >
                    <span className="tool-icon-text">Cancel</span>
                  </Button>
                  {IsSectionVisible(
                    SECTIONS.Industrial_Company,
                    SECTIONS.Industrial_Company_Details,
                    SECTIONS.ADD_EDIT
                  ) && (
                    <Button
                      size="small"
                      className="notop-action-button"
                      onClick={() => {
                        this.saveCompanyDetails();
                      }}
                    >
                      <span className="tool-icon-text">Save</span>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="row header-container">
            <div className="col-md-4 col-lg-4">
              <PageHeader
                btnText={"Companies"}
                onBackClick={this.onBackClick}
                title={"/ " + ownerNam}
                style={{ color: "#00B0B9" }}
              ></PageHeader>
            </div>

            <div className="col-md-5 col-lg-5">
              {this.state.showEdit == true && (
                <AutoSearch
                  placeholder="Search another Company"
                  isEditEnabled={this.state.isEditEnabled}
                  sourceData={this.state.companySummaryData}
                  onSearch={this.onSearch}
                  filterConfig={filterConfig}
                  onInputChange={this.onInputChange}
                  isRedirect={true}
				  isBuildingOwner={true}
                ></AutoSearch>
              )}
            </div>

            <div className="col-md-3 col-lg-3 end-sm">
              {this.state.showEdit == true && (
                <>
                  <Button
                    size="small"
                    className="notop-action-button"
                    onClick={() => {
                      this.props.onBackClick();
                    }}
                  >
                    <span className="tool-icon-text">Cancel</span>
                  </Button>
                  {IsSectionVisible(
                    SECTIONS.Industrial_Company,
                    SECTIONS.Industrial_Company_Details,
                    SECTIONS.ADD_EDIT
                  ) && (
                    <Button
                      size="small"
                      className="notop-action-button"
                      onClick={() => {
                        this.enableEdit();
                      }}
                    >
                      <span className="tool-icon-text">Edit</span>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div className="row header-container">
          <div className="col-sm-7 col-lg-7 tabs-nomargin">
            {!this.props.isInAddMode && (
              <>
                <div>
                  <Tooltip title="Show Company">
                    {getCompaniesDetails && (
                      <a
                        className={
                          this.state.showEdit
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule selected"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            showLeaseComps: false,
                            showProperty: false,
                            showEdit: true,
                          });
                        }}
                      >
                        Company Details
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Show Properties">
                    {getCompaniesDetails && (
                      <a
                        className={
                          this.state.showProperty
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //buildingSummaryData:[],
                            showLeaseComps: false,
                            showProperty: true,
                            showEdit: false,
                          });
                          (this.state.propertySummaryData.length > 0 &&
                            this.state.isOwnedPropertiesChanged == false) ||
                          this.state.isOwnedPropertiesApiCalled == true
                            ? this.state.propertySummaryData
                            : this.getOwnedPropertyData(this.state.selectedId);
                        }}
                      >
                        Owned Properties
                      </a>
                    )}
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Show Lease Comps">
                    {getCompaniesDetails && (
                      <a
                        className={
                          this.state.showLeaseComps
                            ? "tab-capsule selected"
                            : "tab-capsule"
                        } //"tab-capsule"
                        aria-label={"refreshData"}
                        size="small"
                        onClick={() => {
                          this.setState({
                            //leaseCompSummaryData:[],
                            showLeaseComps: true,
                            showProperty: false,
                            showEdit: false,
                          });
                          (this.state.leaseCompSummaryData.length > 0 &&
                            this.state.isLeaseCompsChanged == false) ||
                          this.state.isLeaseCompsApiCalled == true
                            ? this.state.leaseCompSummaryData
                            : this.getTenantData(this.state.selectedId);
                        }}
                      >
                        Lease Comps
                      </a>
                    )}
                  </Tooltip>
                </div>
              </>
            )}
          </div>

          {getHistory.length > 0 && isEditEnabled && (
            <>
              <div
                className="col-md-2 col-lg-2 end-sm"
                style={{ padding: "8px" }}
              >
                <span>Last Modified:</span>
              </div>
              <div className="col-md-3 col-lg-3 end-sm">
                <Autocomplete
                  key={`lastModifiedCompany:${getHistory[0].createdBy}`}
                  size="small"
                  underlineStyle={{ display: "none" }}
                  defaultValue={{
                    createdBy: getHistory[0].createdBy,
                    createdDate: moment(getHistory[0].createdDate).format(
                      "MM-DD-YYYY HH:mm"
                    ),
                  }}
                  onClose={(e, val) => this.handleClose(e, val)}
                  onChange={(e, val) => this.handleChange(e, val)}
                  id="tags-standard"
                  options={getHistory}
                  getOptionLabel={(option) =>
                    option.createdBy +
                    "," +
                    moment(moment(option.createdDate).toDate())
                      .format("MM-DD-YYYY HH:mm:ss")
                      .toString()
                  }
                  renderInput={(params) => (
                    <TextField
                      key={`lastModifiedTextCompany:${getHistory[0].createdBy}`}
                      {...params}
                      variant="outlined"
                      margin="normal"
                      defaultValue={{
                        createdBy: getHistory[0].createdBy,
                        createdDate: moment(getHistory[0].createdDate).format(
                          "MM-DD-YYYY HH:mm"
                        ),
                      }}
                      hiddenLabel
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>

        <div>
          {getCompaniesDetails && this.state.showEdit == true && (
            <FormGenerator
              gridColumn={4}
              ReloadForm={this.state.reloadCompany}
              formFields={UpdateFieldsWithConfiguration(
                getCompaniesDetails,
                DetailsFormConfig
              )}
              Editable={this.state.isEditEnabled}
              dropDownSource={dropDownSource}
              saveChanges={this.getCompaniesChanges}
              partialForm={true}
              validationMessages={[]}
              formType={"Section"}
              getHistoryDataByField={this.getHistoryDataByField}
              separatorConfig={companySeparatorConfig}
              sectionRowConfig={[{ row: 1, column: 12 }]}
              viewPDF={[]}
            ></FormGenerator>
          )}
        </div>

        <div className="row">
          <div className="col-md-7 col-lg-7">
            {getOtherCompanyAddress.length > 0 && this.state.showEdit == true && (
              <div>
                <h3 style={{ textAlign: "center", color: "#004EA8" }}>
                  Other Address Details
                </h3>

                <GridTable
                  columns={UpdateColumnWithConfiguration(
                    otherCompanyAddressColumns,
                    this.state.getOtherCompanyAddressConfiguration,
                    []
                  )}
                  data={getOtherCompanyAddress}
                  config={otherCompanyAddressColumns}
                  renderActionColumn={this.renderActionColumn1}
                  reload={this.state.reloadTable}
                ></GridTable>
              </div>
            )}
          </div>
          <div className="col-md-5 col-lg-5">
            {getOtherCompanyContact.length > 0 && this.state.showEdit == true && (
              <div>
                <h3 style={{ textAlign: "center", color: "#004EA8" }}>
                  Other Contact Details
                </h3>
                <GridTable
                  columns={UpdateColumnWithConfiguration(
                    otherCompanyContactColumns,
                    this.state.getOtherCompanyContactConfiguration,
                    []
                  )}
                  data={getOtherCompanyContact}
                  config={otherCompanyContactColumns}
                  renderActionColumn={this.renderActionColumn2}
                  //  selectedEditRowId={selectedEditRowId}
                  //  isInAddMode={isInAddMode}
                  //  EditSource={EditSource}
                  //  handleTableEditChange={this.handleTableEditChange}
                  //  updateData={updateData}
                  //  isInline={true}
                  reload={this.state.reloadTable}
                  //  AddNewRecord={{ action: this.AddNewRecord, title: 'Add User Market',
                  //  visible:true,
                  // visible: IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT)
                  //}}
                ></GridTable>
              </div>
              //	  </TableCell>
              //	</TableRow>
              //  </TableBody>
              //</Table>
            )}
          </div>
        </div>

        {this.state.openBuildingAddform && (
          <Dialog open={this.state.openBuildingAddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Edit Property
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openBuildingAddform: false,
                    });
                    this.forceUpdate();
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <EditBuilding
                globalMarketId={this.props.globalMarketId}
                selectedBuildingId={this.state.selectedBuildingRowId}
                onBackClick={this.onBuildingBackClick}
                ViewTitle={this.state.ViewBuildingTitle}
                actionType={this.state.actionBuildingType}
                isInAddMode={this.state.isBuildingInAddMode}
                reloadData={this.onBuildingBackClick}
                //	isPropertyOfInterest={true}
              />
            </div>
          </Dialog>
        )}

        {propertySummaryData && this.state.showProperty == true && (
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  buildingColumnsForDetail,
                  this.state.configuration,
                  []
                )}
                renderActionColumn={this.renderActionColumn}
                reload={this.state.reloadTable}
                data={propertySummaryData}
              ></GridTable>
            </div>
          </div>
        )}

        {this.state.leaseCompSummaryData && this.state.showLeaseComps == true && (
          <div className="row" style={{ marginTop: "0px" }}>
            <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  leaseCompsColumnsForDetails,
                  this.state.configuration,
                  []
                )}
                //renderActionColumn={this.renderActionColumn2}
                reload={this.state.reloadTable}
                data={this.state.leaseCompSummaryData}
              ></GridTable>
            </div>
          </div>
        )}
      </>
    );
  }
}

import {
  TableHead,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Box,
  TablePagination,
} from "@material-ui/core";
import React, { Component } from "react";
import {
  apiBatchRequest,
  apiBatchRequestWithoutLoader,
  CancelRequest,
  RenewCancelToken,
} from "../../../utils/api_service";
import { API_URL } from "../../../constants/api";
import commonLabels from "../../../constants/messageConstants";
import { ShowToast, toastType } from "../../../utils/toast-service";

import IndReports from "./IndReports";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "../../Reports/report.less";
import DateFnsUtils from "@date-io/date-fns";
import { DateFormat } from "../../../constants/appConstants";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageHeader from "../../common/PageHeader";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  EnhancedTableHead,
  generatePDFFromHtml,
  getComparator,
  stableSort,
  getCurrentPayCycleEndDateByDate,
  getCurrentPayCycleStartDateByDate,
  removeDuplicates,
  calculateAvgerage,
  getTotalCount,
} from "../../../utils/helper";
import { Icon } from "@iconify/react";
import bxFilterAlt from "@iconify/icons-bx/bx-filter-alt";
import filterIcon from "@iconify/icons-ci/filter";
import camelcaseKeys from "camelcase-keys";
import AdvancedFilter from "./AdvancedFilter";
import { getMultilevelGroupedData } from "../../../utils/generic";
import { getFormattedValuesForReports } from "../../../utils/formatData";
import ReportButtons from "../../../utils/ReportButtons";
import SearchBar from "../../common/AutoSearch/SearchBar";

const uploadColumns = [
  {
    key: "buildingClass",
    name: "BuildingClass",
  },
  {
    key: "market",
    name: "Department",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "buildingOwner",
    name: "BuildingOwner",
  },
  {
    key: "subMarket",
    name: "SubMarket",
  },
  {
    key: "buildingName",
    name: "buildingName",
  },
  {
    key: "floorplate",
    name: "Hire date",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
const headCells = [
  {
    id: "buildingName",
    numeric: false,
    disablePadding: false, //JT
    label: "Property Name",
  },
 

  {
    id: "absPrevQtr",
    numeric: true, //JT
    disablePadding: false,
    label: "Abs Prev Qtr",
  },
];
export default class IndSubMarketBreakdownPosNegAbsQtr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      openFilter: false,
      showMain: false,
      enableFilterButton: false,
      marketAbsorptionReport: [],
      marketAbsorptionReport2: [],
      employeeSummary: [],
      //startDate: moment("2020-12-01").startOf("month").format(DateFormat),
      //endDate: moment("2021-02-01").endOf("month").format(DateFormat),
      startDate: moment(this.props.monthRecorded).format(DateFormat), 
       endDate: moment(this.props.monthRecorded).format(DateFormat), 
      showReport: false,
      getMarketMaster: [],
     // companyMasterDetails: [],
      getSubMarketMaster: [],
      getBuildingMaster: [],
      market: [],
      subMarket: [],
      buildingName: [],
      companyName: [],
      openSlidingPanel: false,
      isTotal: false,
      searchInput: "",
      rowsPerPage: 100,
      rowsPerPageOptions: [100, 200, 300, 400, 500],
      page: 0,
      order: "asc",
      orderBy: "nam",
      rowsNPerPage: 100,
      npage: 0,
      norder: "asc",
      norderBy: "nam",
      topvalue: 68,
      selected: [],
      dense: false,
      responseMarketAbsorptionReport: [],
      responseMarketAbsorptionReport2: [],
      advanceFilterConfig: {
        groupBy: {
          masterData: [
            {
              key: "Stream Submarket",
              value: "subMarket",
              id: "subMarket",
              numeric: false,
              disablePadding: false,
              label: "Stream Submarket",
            },
          ],
          selected: [{ key: "Stream Market", value: "market" }],
        },
        selectedDate: {},
        suppressGraph: false,
        suppressZero: true,
        summaryRows: false,
        showZeroNGraph: true,
      },
      summaryCols: [ 'absPrevQtr'],
    };

    this.baseState = this.state;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.GetMasterData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }
  componentDidMount() {
    RenewCancelToken();
    //this.props.setNewMarketId --todo(this.GetMasterData);
    this.GetMasterData(this.state.selectedMarketId);
  }
  componentWillUnmount() {
    CancelRequest();
  }
  handleRequestSort = (e, property) => {
    if (e == "Pos") {
      const isAsc =
        this.state.orderBy === property && this.state.order === "asc";
      this.setState({
        order: isAsc ? "desc" : "asc",
        orderBy: property,
      });
    } else {
      const isAsc =
        this.state.norderBy === property && this.state.norder === "asc";
      this.setState({
        norder: isAsc ? "desc" : "asc",
        norderBy: property,
      });
    }
  };
  handleChangePage = (event, newPage, e) => {
    if (newPage != undefined) {
      if (e == "Pos") {
        this.setState({ page: newPage }, () => {
          this.forceUpdate();
        });
      } else {
        this.setState({ npage: newPage }, () => {
          this.forceUpdate();
        });
      }
    }
  };

  handleChangeRowsPerPage = (event, e) => {
    if (e == "Pos") {
      this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    } else {
      this.setState({
        rowsNPerPage: parseInt(event.target.value, 10),
        npage: 0,
      });
    }
  };
  goBack = () => {
    this.setState({
      showMain: true,
    });
  };
  setIsTotal = () => {
    this.setState({ isTotal: true });
  };
  handleDateChange = (date, name) => {
    if (name === "startDate") {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    } else {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    }
  };

  GetMasterData = (selectedMarketId) => {
    this.getReport(selectedMarketId);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 2, marketId: selectedMarketId },
        fromCache: true,
        duration: 60,
      },
     
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 2, marketId: selectedMarketId },
        fromCache: true,
        duration: 60,
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 2, marketId: selectedMarketId },
        fromCache: true,
        duration: 60,
      },
    ])
      .then(
        ({
          getMarketMaster,
          getSubMarketMaster,
          getBuildingMaster,
          //companyMasterDetails,
        }) => {
          this._asyncRequest = null;
          if (getMarketMaster.success !== true) {
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
          this.setState({
            getMarketMaster: camelcaseKeys(getMarketMaster.data) || [],
            getSubMarketMaster: camelcaseKeys(getSubMarketMaster.data) || [],
            getBuildingMaster: camelcaseKeys(getBuildingMaster.data) || [],
            // companyMasterDetails:
            //   camelcaseKeys(companyMasterDetails.data) || [],
            enableFilterButton: true,
          });
        }
      )
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getReport = (marketId) => {
    const { market, buildingName, subMarket, companyName } = this.state;
    //const marketId = this.state.selectedMarketId ;//_.cloneDeep(market.map((x) => x.marketId));
    const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = _.cloneDeep(companyName.map((x) => x.companyId));

    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const params = {
      startDate: moment(startDate).format("YYYY-MM-01"),
      endDate: moment(endDate).format(DateFormat),
      marketId: marketId, //.join("|"),
      buildingId: buildingId.join("|"),
      subMarketId: subMarketId.join("|"),
      companyId: companyId.join("|"),
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialReports.subMarketPositiveAbsReport,
        params,
        key: " marketAbsorptionReport",
      },
    ])
      .then(({ marketAbsorptionReport }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport: camelcaseKeys(
            marketAbsorptionReport.data || []
          ),
          responseMarketAbsorptionReport: camelcaseKeys(
            marketAbsorptionReport.data || []
          ),
          // employeeSummary:   marketAbsorptionReport.config,
          showReport: true,
          page:0,
          npage: 0
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialReports.subMarketNegativeAbsReport,
        params,
        key: " marketAbsorptionReport2",
      },
    ])
      .then(({ marketAbsorptionReport2 }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport2.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport2: camelcaseKeys(
            marketAbsorptionReport2.data || []
          ),
          responseMarketAbsorptionReport2: camelcaseKeys(
            marketAbsorptionReport2.data || []
          ),
          // employeeSummary:   marketAbsorptionReport.config,
          showReport: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  renderTable = (marketAbsorptionReport, e, count) => {
    const groupSequence = this.getColumnSequence();
    return (
      <Table
        className="reportTable"
        id={"rptTable" + count}
        stickyHeader
        aria-label="sticky table"
      >
        <EnhancedTableHead
          numSelected={this.state.selected.length}
          order={e == "Pos" ? this.state.order : this.state.norder}
          orderBy={e == "Pos" ? this.state.orderBy : this.state.norderBy}
          onRequestSort={(event, property) => {
            this.handleRequestSort(e, property);
          }}
          rowCount={marketAbsorptionReport.length}
          columnSequence={groupSequence}
          headCells={headCells}
        />
       
        <TableBody>
          {
            marketAbsorptionReport.length == 0 ?( <TableRow >
              <TableCell><span>No Data Available</span></TableCell></TableRow>
                      ):(this.renderTableRow(
            this.state.rowsPerPage > 0
              ? stableSort(
                  marketAbsorptionReport || [],
                  getComparator(
                    e == "Pos" ? this.state.order : this.state.norder,
                    e == "Pos" ? this.state.orderBy : this.state.norderBy
                  )
                ).slice(
                  e == "Pos"
                    ? this.state.page * this.state.rowsPerPage
                    : this.state.npage * this.state.rowsNPerPage,
                  e == "Pos"
                    ? this.state.page * this.state.rowsPerPage +
                        this.state.rowsPerPage
                    : this.state.npage * this.state.rowsNPerPage +
                        this.state.rowsNPerPage
                )
              : marketAbsorptionReport
          ))}
        </TableBody>
      </Table>
    );
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };

  resetPage = () => {
    this.setState({ ...this.baseState }, this.componentDidMount);
    //this.componentDidMount();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    this.getReport(this.state.selectedMarketId);
  };

  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };
  handleChange = (e, value, name) => {
    this.setState({ [name]: value });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let searchData = this.state.responseMarketAbsorptionReport;
    let searchData2 = this.state.responseMarketAbsorptionReport2;
    let filteredData = searchData.filter((value) => {
      return (
        (value.market
          ? value.market
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.subMarket
          ? value.subMarket
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.buildingName
          ? value.buildingName
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.absPrevQtr
          ? value.absPrevQtr
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false)
      );
    });

    let filteredData2 = searchData2.filter((value) => {
      return (
        (value.market
          ? value.market
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.subMarket
          ? value.subMarket
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.buildingName
          ? value.buildingName
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false) ||
        (value.absPrevQtr
          ? value.absPrevQtr
              .toString()
              .toLowerCase()
              .includes(searchInput.toString().toLowerCase())
          : false)
      );
    });

    if (searchInput.length == 0) {
      this.setState({
        marketAbsorptionReport: this.state.responseMarketAbsorptionReport,
      });
      this.setState({
        marketAbsorptionReport2: this.state.responseMarketAbsorptionReport2,
      });
    } else {
      this.setState({ marketAbsorptionReport: filteredData, page: 0 ,npage: 0});
      this.setState({ marketAbsorptionReport2: filteredData2, npage: 0,page: 0 });
    }
  };

  onAddvanceFilterChange = (advanceFilterConfig) => {
    this.setIsTotal();
    this.setState(
      {
        advanceFilterConfig: advanceFilterConfig,
        openSlidingPanel: false,
      
      },
      () => {
        this.getReport(this.state.selectedMarketId);
      }
    );
  };

  getColumnSequence = () => {
    return removeDuplicates(
      [
        ...this.state.advanceFilterConfig.groupBy.selected,
        ...this.state.advanceFilterConfig.groupBy.masterData,
      ],
      "key"
    );
  };

  adjustColumnSequence = (currentGroupedRow) => {
    this.state.advanceFilterConfig.groupBy.masterData.forEach((x) => {
      currentGroupedRow.push({ key: x.value });
    });
    return currentGroupedRow;
  };

  renderTableRow = (data, onlydata = false) => {
    return this.renderTableCell(
      getMultilevelGroupedData(
        data,
        this.state.advanceFilterConfig.groupBy.selected.map((x) => x.value),
        this.state.isTotal,
        this.state.summaryCols
      ),
      onlydata,
      []
    );
  };

  exportPDF = () => {
    generatePDFFromHtml(
      "Employee New Hire Report",
      "#rptTable",
      "EmployeeNewHireReport"
    );
  };

  // Export PDF
  exportPDFUsingData = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Market Absorption Report";
    const sequence = this.getColumnSequence();
    const columns = this.getColumnSequence().map((x) => x.key);
    const headers = [[...columns, "Property Name", "Abs Prev Qtr"]];

    const data = this.renderTableRow(
      this.state.marketAbsorptionReport,
      true
    ).map((m) => [
      m[sequence[0].value],
      m[sequence[1].value],
      m.buildingName,
      m.absPrevQtr,
    ]);

    const content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.setFontSize(9);
    doc.autoTable(content);
    // doc.fromHTML(ReactDOMServer.renderToStaticMarkup(this.render()));
    doc.save("NewHireEmployeeReport.pdf");
  };

  renderTableCell = (
    groupedData,
    onlydata,
    currentGroupedRow = [],
    tableCell = [],
    rowCount = 0
  ) => {
    let column1 = "";
    let column2 = "";
    let column3 = "";
    //  let column4 = '';
    // let column5 = '';
    groupedData.map((d, i) => {
      if (d.data !== undefined) {
        currentGroupedRow = currentGroupedRow.filter((x) => x.key !== d.key);
        currentGroupedRow.push({ key: d.key, value: d[d.key] });
        this.renderTableCell(
          d.data,
          onlydata,
          currentGroupedRow,
          tableCell,
          rowCount
        );
      }
      const keyvalue = this.adjustColumnSequence(
        _.cloneDeep(currentGroupedRow)
      );
      const sequence = this.getColumnSequence();
      const evenOddClass =
        rowCount % 2 === 0 ? " rpt rpt-even " : " rpt rpt-odd ";
      const topBorderClass =
        column1 !==
          (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? ""
          ? " rpt-topBorder "
          : " ";

      const key = d.market + d.submarket + "_" + rowCount + "_" + i;
      if (d.data && d.summaryData != undefined) {
        if (
          d.summaryData.length > 0 &&
          (d.summaryData[0].isSummary === undefined ||
            (d.summaryData.length > 0 &&
              d.summaryData[0].isSummary === true &&
              this.state.advanceFilterConfig.summaryRows === true))
        ) {
          tableCell.push(
            <TableRow
              key={key}
              className={
                topBorderClass + (d.isGroup === true ? " parent-row" : "")
              }
            >
              <TableCell
                className={"st sticky " + evenOddClass + topBorderClass}
              >
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky-1 " +
                  evenOddClass +
                  (column2 !== d[sequence[1].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column2 !== d[sequence[1].value] && d[sequence[1].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  "st sticky rpt-topBorder " + evenOddClass + topBorderClass
                }
              >
                {" "}
              </TableCell>
              {d.summaryData.map((m, ind) =>
                this.renderSummaryColumn(
                  d.summaryData,
                  m,
                  key + ind,
                  evenOddClass,
                  d.data.length,
                  d.data
                )
              )}
            </TableRow>
          );
        }
      }
      if (d.data === undefined) {
        if (onlydata) {
          const rowData = {};
          rowData[sequence[0].value] = getFormattedValuesForReports(
            column1 !== d[sequence[0].value] ? d[sequence[0].value] : "",
            "Text",
            true
          );
          rowData[sequence[1].value] = getFormattedValuesForReports(
            column2 !== d[sequence[1].value] ? d[sequence[1].value] : "",
            "Text",
            true
          );
          //rowData[sequence[2].value] = getFormattedValuesForReports(column3 !== d[sequence[2].value] ? d[sequence[2].value] : '', 'Text', true);

          rowData.buildingName = d.buildingName;
          rowData.absPrevQtr = d.absPrevQtr;

          tableCell.push(rowData);
        } else {
          tableCell.push(
            <TableRow key={key} className={topBorderClass}>
              <TableCell className={evenOddClass + topBorderClass}>
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  evenOddClass +
                  (column2 !== d[sequence[1].value] ? " rpt-topBorder " : " ")
                }
              >
                {getFormattedValuesForReports(
                  column2 !== d[sequence[1].value] && d[sequence[1].value],
                  "Text"
                )}{" "}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingName === null
                  ? "NA"
                  : getFormattedValuesForReports(d.buildingName, "Text")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.absPrevQtr, "Number")}
              </TableCell>
            </TableRow>
          );
        }
      }
      rowCount++;
      column1 =
        (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? "";
      column2 =
        (keyvalue.find((x) => x.key === sequence[1].value) || {}).value ?? "";
    });
    return tableCell;
  };

  renderSummaryColumn = (summaryDetails, n, index, evenOddClass,count,data) => {
    const summaryDetail = summaryDetails.filter((x) => x === n);
    const tableCell = [];
    //  summaryDetail.forEach(m=>{
    tableCell.push(
      <>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "absPrevQtr"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "absPrevQtr" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.absPrevQtr === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.absPrevQtr,getTotalCount(data,count,'absPrevQtr')), "Number")

                //getFormattedValuesForReports(n.absPrevQtr, "Number")
              )}
            </div>
          ))}
        </TableCell>
      </>
    );
    //});

    return tableCell;
  };

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };
  render() {
    const {
      showMain,
      openFilter,
      marketAbsorptionReport,
      marketAbsorptionReport2,
      startDate,
      endDate,
      getMarketMaster,
      getSubMarketMaster,
     // companyMasterDetails,
      getBuildingMaster,
      showReport,
      searchInput,
      advanceFilterConfig,
      openSlidingPanel,
    } = this.state;

    return (
      <div style={{ marginTop: `${this.props.marginTop}px` }}>
        {showMain ? (
          <IndReports globalMarketId={this.state.selectedMarketId}></IndReports>
        ) : (
          <>
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-6">
                            </div>
              <div className="col-sm-5 col-md-5 col-lg-4">
                <SearchBar
                  size="large"
                  name="searchInput"
                  value={searchInput || ""}
                  onChange={this.handleSearch}
                  label="Search"
                  placeholder="Search property name,market,etc."
                ></SearchBar>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <AdvancedFilter
                  filterConfig={advanceFilterConfig}
                  onApplyFilter={this.onAddvanceFilterChange}
                  openSlidingPanel={openSlidingPanel}
                  handleClose={this.handleClose}
                  setIsTotal={this.setIsTotal}
                />
                <ReportButtons
                  resetPage={this.resetPage}
                  handleSubmit={this.handleSubmit}
                  handleClickOpen={this.handleClickOpen}
                  handlePrintClick={this.handlePrintClick}
                  exportPDF={this.exportPDF}
                  data={marketAbsorptionReport}
                  columnWidths={columnWidths}
                  uploadColumns={uploadColumns}
                  openFilter={this.state.openFilter}
                  enableFilter={this.enableFilter}
                  isReportPage={true}
                  isListingPage={false}
                  fileName={"AnnualAbsorptionReport.xlsx"}
                  enableFilterButton={this.state.enableFilterButton}
                />
              </div>
            </div>
            {getMarketMaster.length > 0 && openFilter == true && (
              <div style={{ width: "98%" }}>
                <Grid
                  container
                  spacing={2}
                  className="no-print row bottom-xs report-filter"
                >
                  <Grid item xs={12} lg={3}>
                    <Autocomplete
                      size="small"
                      underlineStyle={{ display: "none" }}
                      multiple
                      value={this.state.market}
                      onChange={(e, val) => this.handleChange(e, val, "market")}
                      id="tags-standard"
                      options={getMarketMaster}
                      limitTags={2}
                      getOptionLabel={(option) => option.market}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Stream Market
                          </label>
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            hiddenLabel
                            placeholder="Stream Market"
                          />
                        </Box>
                      )}
                    />
                     <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.buildingName}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "buildingName")
                    }
                    options={getBuildingMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.buildingName}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Property Name
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Property Name"
                        />
                      </Box>
                    )}
                  />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                 
                    <Autocomplete
                      size="small"
                      underlineStyle={{ display: "none" }}
                      multiple
                      id="tags-standard"
                      value={this.state.subMarket}
                      onChange={(e, val) =>
                        this.handleChange(e, val, "subMarket")
                      }
                      options={getSubMarketMaster}
                      limitTags={2}
                      getOptionLabel={(option) => option.subMarket}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Stream Submarket
                          </label>
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            hiddenLabel
                            placeholder="Stream Submarket"
                          />
                        </Box>
                      )}
                    />
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Month Recorded
                        </label>

                        
                        <Grid container>
                          <KeyboardDatePicker
                            style={{
                              border: "1px solid #cecdcd",
                              borderRadius: "4px",
                            }}
                            // variant="inline"
                            margin="normal"
                            views={["year", "month"]}
                            id="date-picker-dialog"
                            //label="Start Date"
                            variant="inline"
                            format="MM/01/yyyy"
                            autoOk
                            name="startDate"
                            value={startDate}
                            onChange={(e) =>
                              this.handleDateChange(e, "startDate")
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            className="w-100"
                          />
                        </Grid>
                      </Box>
                     
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <button
                      className="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                      onClick={() => this.handleSubmit()}
                    >
                      <span class="MuiButton-label">
                        {" "}
                        <span class="tool-icon-text">FILTER REPORT</span>
                      </span>

                      <span class="MuiTouchRipple-root"></span>
                    </button>
                  </Grid>
                </Grid>
              </div>
            )}

            {showReport && marketAbsorptionReport == null && (
              <Grid container spacing={4}>
                <Grid
                  container
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                >
                  <label className="item-big">No Data Available.</label>
                </Grid>
              </Grid>
            )}
            {showReport && (
              <div className="row">
                {marketAbsorptionReport !== null && (
                  <div className="col-md-6 col-lg-6">
                    <div
                      style={{
                        paddingTop: "15px",
                        marginBottom: "5px",
                        marginLeft: "5px",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      <b> Positive Absorption in qtr</b>{" "}
                    </div>
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(event, newPage, e) => {
                        this.handleChangePage(event, newPage, "Pos");
                      }}
                      onChangeRowsPerPage={(event, e) => {
                        this.handleChangeRowsPerPage(event, "Pos");
                      }}
                    />
                    <TableContainer
                      className="report-table-scroll"
                      component={Paper}
                    >
                      {this.renderTable(marketAbsorptionReport, "Pos")}
                    </TableContainer>
                  </div>
                )}
                {marketAbsorptionReport2 !== null && (
                  <div className="col-md-6 col-lg-6">
                    <div
                      style={{
                        paddingTop: "15px",
                        marginBottom: "5px",
                        marginLeft: "5px",
                        fontSize: "15px",
                      }}
                    >
                      {" "}
                      <b> Negative Absorption in qtr</b>{" "}
                    </div>
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport2.length}
                      rowsPerPage={this.state.rowsNPerPage}
                      page={this.state.npage}
                      onChangePage={(event, newPage, e) => {
                        this.handleChangePage(event, newPage, "Neg");
                      }}
                      onChangeRowsPerPage={(event, e) => {
                        this.handleChangeRowsPerPage(event, "Neg");
                      }}
                    />
                    <TableContainer
                      className="report-table-scroll"
                      component={Paper}
                    >
                      {this.renderTable(marketAbsorptionReport2, "Neg")}
                    </TableContainer>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

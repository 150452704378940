import {
	Button,
	Divider,
	MenuItem,
	Select,
	TextField,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../common/PageHeader";
import { apiBatchRequest, apiPost, apiPut } from "../../utils/api_service";
import BackspaceIcon from "@material-ui/icons/BackspaceRounded";
import { UpdateFieldsWithConfiguration } from "../../utils/helper";
import FormGenerator from "../common/FormGenerator";
import { API_URL } from "../../constants/api";
import { controlType } from "../../constants/common";
import camelCaseKeys from "camelcase-keys";
import { camelCase } from "lodash";
import SearchBar from "../common/AutoSearch/SearchBar";
import camelcaseKeys from "camelcase-keys";
import AutoSearch from "../common/AutoSearch/AutoSearch";
import Autocomplete from "react-autocomplete";
import ControllableStates from "../common/AutoSearch/SimpleSelect";
import SimpleSelect from "../common/AutoSearch/SimpleSelect";
import { ShowToast, toastType } from "../../utils/toast-service";
import commonLabels from "../../constants/messageConstants";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
const camelizeKeys = (obj) => {
	if (Array.isArray(obj)) {
		return obj.map((v) => camelizeKeys(v));
	} else if (obj != null && obj.constructor === Object) {
		return Object.keys(obj).reduce(
			(result, key) => ({
				...result,
				[camelCase(key)]: camelizeKeys(obj[key]),
			}),
			{}
		);
	}
	return obj;
};
export default class EditCompanies extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			getCompaniesDetails: [],
			companySummaryData: [],
			companyMasterDetails: [],
			ownerTypeData: [],
			getIndustrySector: [],
			getProductTypeMaster: [],
			lastModifiedValue: "",
			isEditEnabled: this.props.isInAddMode,
			getMasterZip: [],
			getSubMarketMaster: [],
			getMarketMaster: [],
			openDialog: true,
			getCompaniesDetailsResponse: [],
			isDataSaved: false,
			isSearchedClicked: false,
			showConfirmDialog: false,
			companyId: -1,
		};
	}

	handleChange = (event) => {
		setState({ lastModifiedValue: event.target.value });
	};
	getData = (companyId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Companies.getCompaniesDetails,
				key: "getCompaniesDetails",
				params: { companyId: companyId },
			},
		])
			.then((getCompaniesDetails) => {
				this._asyncRequest = null;
				if (getCompaniesDetails.getCompaniesDetails.success == true) {
					this.setState({
						getCompaniesDetails:
							camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};
	componentDidMount() {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Companies.getCompaniesDetails,
				key: "getCompaniesDetails",
				params: { companyId: this.props.selectedCompanyId },
			},
			{
				url: API_URL.Master.getProductTypeMaster,
				key: "getProductTypeMaster",
			},
			{
				url: API_URL.Master.ownerTypeData,
				key: "ownerTypeData",
				params: { businessLineId: 1 },
			},
			{
				url: API_URL.Master.getIndustrySector,
				key: "getIndustrySector",
				params: { businessLineId: 1 },
			},
			{
				url: API_URL.Master.getMasterZip,
				key: "getMasterZip",
				params: { businessLineId: 1 },
			},
			{
				url: API_URL.Master.getSubMarketMaster,
				key: "getSubMarketMaster",
				params: { businessLineId: 1 },
			},
			{
				url: API_URL.Master.getMarketMaster,
				key: "getMarketMaster",
				params: { businessLineId: 1 },
			},
			{
				url: API_URL.Companies.companyData,
				key: "companySummaryData",
			},
		])
			.then((getCompaniesDetails) => {
				this._asyncRequest = null;
				if (getCompaniesDetails.getCompaniesDetails.success == true) {
					this.setState({
						companySummaryData:
							camelcaseKeys(getCompaniesDetails.companySummaryData.data) || [],
						getCompaniesDetails:
							camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
						getCompaniesDetailsResponse:
							camelcaseKeys(getCompaniesDetails.getCompaniesDetails.data) || [],
						ownerTypeData:
							camelcaseKeys(getCompaniesDetails.ownerTypeData.data) || [],
						getProductTypeMaster:
							camelcaseKeys(getCompaniesDetails.getProductTypeMaster.data) ||
							[],
						getIndustrySector:
							camelcaseKeys(getCompaniesDetails.getIndustrySector.data) || [],
						getMasterZip:
							camelcaseKeys(getCompaniesDetails.getMasterZip.data) || [],
						getSubMarketMaster:
							camelcaseKeys(getCompaniesDetails.getSubMarketMaster.data) || [],
						getMarketMaster:
							camelcaseKeys(getCompaniesDetails.getMarketMaster.data) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}

	enableEdit = () => {
		this.setState({
			isEditEnabled: true,
		});
		this.forceUpdate();
	};

	getCompaniesChanges = (formFields) => {
		//     const subMarketData=this.state.getSubMarketMaster;
		//     const marketId = formFields.filter(
		//       (x) => _.camelCase(x.dbColumn) === _.camelCase('MarketId')
		//     );
		//     const subMarket=subMarketData.filter(x=>{
		// x.marketId==marketId[0].attributeValue
		//     });
		this.setState(
			{
				companyMasterDetails: formFields,
				reloadCompany: !this.state.reloadCompany,
				isDataSaved: true,
				isSearchedClicked: false,

				// getSubMarketMaster:subMarket
			},
			() => {
				this.forceUpdate();
			}
		);
	};
	onBackClick = () => {
		this.props.onBackClick();
	};
	saveCompanyDetails = () => {
		const companyData = this.state.companyMasterDetails;
		companyData.forEach((x) => {
			if (
				_.camelCase(x.dbColumn) === _.camelCase("ZipId") ||
				_.camelCase(x.dbColumn) === _.camelCase("MarketId") ||
				_.camelCase(x.dbColumn) === _.camelCase("SubMarketId")
			) {
				if (x.attributeValue != null)
					x.attributeValue = x.attributeValue.toString();
			}
		});

		if (this.props.actionType === "Edit") {
			const companyId = companyData.filter(
				(x) => _.camelCase(x.dbColumn) === _.camelCase("CompanyId")
			);

			apiPut(API_URL.Companies.UpdateCompanyDetail, companyData, {
				CompanyId: companyId[0].attributeValue,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Record updated successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.setState({
							isDataSaved: false,
						});
						this.props.reloadData();
						//this.getData();
					} else {
						ShowToast("Error", toastType.ERROR, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		} else {
			//companyData[0].attributeValue=-1;

			apiPost({
				url: API_URL.Companies.InsertCompany,
				params: {},
				postBody: companyData,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Record inserted successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.props.reloadData();
					} else {
						ShowToast(response.message, toastType.error, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		}
	};

	// onClickHandel(e) {
	//   this.setState({
	//     openDialog: false,
	//   });
	// }

	onSearch = (event, value, e) => {
		const { isDataSaved } = this.state;
		let companyId = this.state.companySummaryData.find(
			(m) => m.companyName == value
		).companyId;
		this.setState({
			companyId: companyId,
			isSearchedClicked: true,
		});

		if (isDataSaved == false) {
			this.getData(companyId);
		}
		//console.log(event);
	};
	onSearchBuildingOwner = () => {
		this.setState({
			isSearchedClicked: false,
			isDataSaved: false,
		});
		this.getData(this.state.companyId);
	};
	onClickHandel(e) {
		if (e == true) {
			this.onSearchBuildingOwner();
			this.setState({
				isSearchedClicked: false,
			});
		} else {
			this.setState({
				isSearchedClicked: false,
			});
		}
	}

	render() {
		const {
			isEditEnabled,
			companySummaryData,
			lastModifiedValue,
			getCompaniesDetails,
			ownerTypeData,
			getIndustrySector,
			getProductTypeMaster,
			getMasterZip,
			getMarketMaster,
			getSubMarketMaster,
		} = this.state;

		const filterConfig = [
			{
				multiple: true,
				type: controlType.DropDown,
				minTag: 1,
				source: companySummaryData,
				key: "companyName",
				placeholder: "",
				title: "Company Name",
			},
		];
		var ownerName = "";
		if (getCompaniesDetails.length > 0) {
			ownerName = getCompaniesDetails.find(
				(x) => x.dbColumn == "CompanyName"
			).attributeValue;
		}
		const addnewOption = true;

		//const ServiceTypeMasterData = this.props.serviceTypeMasterData;
		const companySeparatorConfig = [
			{
				separatorBeforeKey: "CompanyName",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Owner Details",
			},
			{
				separatorBeforeKey: "Add1",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Address Details",
			},
			{
				separatorBeforeKey: "Notes",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Other Details",
			},
		];

		const dropDownSource = [
			{
				key: "companyRole",
				option: "companyRole",
				idField: "companyRoleId",
				source: ownerTypeData,
				// AddNewOption: true,
				// AddOptionType: 'Button',
				// AddNewOptiontitle: 'New Owner',
			},

			{
				key: "industryName",
				option: "industryName",
				idField: "industryId",
				source: getIndustrySector,
			},
			{
				key: "zip",
				option: "zip",
				idField: "zipId",
				source: getMasterZip,
				// AddNewOption: addnewOption,
				// AddOptionType: 'Button',
				// AddNewOptiontitle: 'New Zip',
				// AddOptionComponent: (
				//   <ConfirmDialog
				//   IsOpen={true}
				//   Message="Add Zip"
				//   OnHandelClick={(isValid) => {
				//     if (isValid) {
				//       this.onClickHandel(isValid);
				//     } else {
				//       this.onClickHandel(isValid);
				//     }
				//   }}
				//   AgreeText="Save"
				//   DisAgreeText="Cancel"
				// ></ConfirmDialog>
				// )
			},
			{
				key: "productType",
				option: "productType",
				idField: "productTypeId",
				source: getProductTypeMaster,
			},
			{
				key: "market",
				option: "market",
				idField: "marketId",
				source: getMarketMaster,
			},
			{
				key: "subMarket",
				option: "subMarket",
				idField: "subMarketId",
				source: getSubMarketMaster,
				filterSourceOn: [{ key: "marketId", filterBy: "marketId" }],
				applyFilter: true,
			},
			// {
			//     key: 'businessLineName',
			//     option: 'businessLineName',
			//     idField: 'businessLineId',
			//     source: this.props.getProductTypeMaster,
			//     // onChange: this.getSelectedTransitionDetails
			//   }
		];

		const DetailsFormConfig = [
			{
				key: "companyName",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "add1",
				type: controlType.TextBox,
				multiple: false,
				//columnWidth:6
			},
			{
				key: "add2",
				type: controlType.TextBox,
				multiple: false,
				//columnWidth:6
			},
			{
				key: "zip",
				type: controlType.DropDown,
				multiple: false,
				//columnWidth:6
			},
			{
				key: "add2",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "notes",
				type: controlType.TextArea,
				multiple: false,
			},
			{ key: "companyRole", type: controlType.DropDown, multiple: true },
			{ key: "industryName", type: controlType.DropDown, multiple: true },

			{ key: "businessLineName", type: controlType.DropDown, multiple: true },
			{ key: "productType", type: controlType.DropDown, multiple: true },
			{ key: "market", type: controlType.DropDown, multiple: false },
			{ key: "subMarket", type: controlType.DropDown, multiple: false },
		];
		const ownerNam = ownerName != undefined ? ownerName : this.props.ViewTitle;
		return (
			<>
				{this.state.isDataSaved && this.state.isSearchedClicked && (
					<ConfirmDialog
						IsOpen={this.state.isSearchedClicked}
						Message={
							"There are unsaved changes. Do you still want to search another Company"
						}
						OnHandelClick={(isValid) => {
							if (isValid) {
								this.onClickHandel(isValid);
							} else {
								this.onClickHandel(isValid);
							}
						}}
						AgreeText="Yes"
						DisAgreeText="No"
					></ConfirmDialog>
				)}

				{isEditEnabled || this.props.isInAddMode ? (
					<div className="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Companies"}
								onBackClick={this.onBackClick}
								title={
									this.props.isInAddMode
										? "/ " + this.props.ViewTitle
										: "/ " + ownerNam
								}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>

						<div className="col-md-4 col-lg-4">
							{companySummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another Company"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={this.state.companySummaryData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
							{/* <SearchBar></SearchBar> */}
						</div>
						<div className="col-md-4 col-lg-4 end-sm">
							{/* style={{marginTop:"-7px"}} */}
							<Button
								size="small"
								style={{ marginTop: "5px" }}
								className="btn-clear"
								onClick={() => {
									this.setState({
										getCompaniesDetails: this.state.getCompaniesDetailsResponse,
										reloadCompany: !this.state.reloadCompany,
									});
								}}
							>
								<span className="tool-icon-text">Clear All</span>
							</Button>
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.props.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.saveCompanyDetails();
								}}
							>
								<span className="tool-icon-text">Save</span>
							</Button>
						</div>
					</div>
				) : (
					<div className="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Companies"}
								onBackClick={this.onBackClick}
								title={"/ " + ownerNam}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>

						<div className="col-md-5 col-lg-5">
							{companySummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another Company"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={this.state.companySummaryData}
									onSearch={this.onSearch}
									filterConfig={filterConfig}
								></AutoSearch>
							)}
						</div>

						<div className="col-md-3 col-lg-3 end-sm">
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.props.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.enableEdit();
								}}
							>
								<span className="tool-icon-text">Edit</span>
							</Button>
						</div>
					</div>
				)}

				{/* <div className="row">
          <div className="col-sm-7 col-lg-7"></div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
            style={{ marginTop: "1px" }}
          >
            <span>Last Modified:</span>
          </div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
            //style={{ marginTop: "9px", marginLeft: "8px" }}
          >
            <SimpleSelect></SimpleSelect>
          </div>
        </div> */}

				{/* <div style={{float: 'right'}}>
        <Button
                  variant="contained"
                  color="primary"
                 className="addedit-buttons"
                  size="small"
                  onClick={() => {
                   
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                 className="addedit-buttons"
                  onClick={() => {
                   
                  }}
                >
                  Save & Exit
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                 className="addedit-buttons"
                  onClick={() => {
                    this.props.onBackClick()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className="addedit-buttons"
                  onClick={() => {
                    
                  }}
                >
                  Reset
                </Button>
        </div> */}
				{/* <div className="row row col-xs-9" style={{marginTop:'49px',marginLeft:'3.8%'}}>
       <div  className={'col-xs-2 filter-label'} style={{marginTop: 20,textAlign:'left'}}>Search</div>
        <div  style={{marginLeft:'-1%',width: '30.8%'}}  > 
       <SearchBar></SearchBar>
        </div>
         </div> */}
				{/* <div className="div-searchBar">
          <SearchBar></SearchBar>
        </div> */}
				<div>
					{getCompaniesDetails && (
						<FormGenerator
							gridColumn={4}
							ReloadForm={this.state.reloadCompany}
							formFields={UpdateFieldsWithConfiguration(
								getCompaniesDetails,
								DetailsFormConfig
							)}
							Editable={this.state.isEditEnabled}
							dropDownSource={dropDownSource}
							saveChanges={this.getCompaniesChanges}
							partialForm={true}
							validationMessages={[]}
							formType={"Section"}
							separatorConfig={companySeparatorConfig}
							sectionRowConfig={[{ row: 1, column: 12 }]}
							// sectionRowConfig={[{row:1,column:5},{row:2,column:4,labelAlign:'alignText-Right'}]}
							viewPDF={[]}
						></FormGenerator>
					)}
				</div>
			</>
		);
	}
}

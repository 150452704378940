import React from 'react';
import IdleTimer from 'react-idle-timer';
import { ShowToast, toastType } from '../../../utils/toast-service';
//import { authContext } from '../../../configurations/adalConfig';
//import { IDLE_TIME } from '../../../constants/appConstants';

export default function IdleSessionTimer () {
  // function onAction(e) {
  // console.log("user did something", e);
  // }

  // function onActive() {
  // // console.log('user is active', e)
  // }

  function onIdle () {
    //console.log('Idle - relogin in process')
    //window.location = '/';
    ShowToast("Your session has expired due to inactivity. Please refresh the page to continue.", toastType.WARNING, false, 500);
  }

  return (
    <IdleTimer
      // ref={ref => {
      // this.idleTimer = ref;
      // }}
      element={document}
      // onActive={onActive}
      onIdle={onIdle}
      // onAction={onAction}
      debounce={250}
      timeout={3540000} // After 59 minutes it auto log off
      
    />
  );
}

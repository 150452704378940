import * as d3 from "d3";
import React from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import {
  Box,
  IconButton,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import _ from "lodash";
import { controlType } from "../constants/common";
import moment from "moment";
import StreamLogo from "../public/img/common/Logo.png";
import { visuallyHidden } from "@material-ui/utils";
import { getFieldValueByFormat, getFormattedValues } from "./formatData";
import {
  DateFormat,
  SECTIONS,
  ADMIN_OBJECT_ID,
} from "../constants/appConstants";
import store from "../store/appStore";
import "jspdf-autotable";
import jsPDF from "jspdf";

function useQuery() {
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  return params;
}
function ConvertToCurrencyAbbreviation(number, decPlaces) {
  // 2 decimal places => 100, 3 => 1000, etc
  decPlaces = Math.pow(10, decPlaces);

  // Enumerate number abbreviations
  const abbrev = [" K", " M", " B", " T"];

  // Go through the array backWards, so we do the largest first
  for (let i = abbrev.length - 1; i >= 0; i--) {
    // Convert array index to "1000", "1000000", etc
    const size = Math.pow(10, (i + 1) * 3);

    // If the number is bigger or equal do the abbreviation
    if (size <= number) {
      // Here, we multiply by decPlaces, round, and then divide by decPlaces.
      // This gives us nice rounding to a particular decimal place.
      number = Math.round((number * decPlaces) / size) / decPlaces;

      // Handle special case where we round up to the next abbreviation
      if (number === 1000 && i < abbrev.length - 1) {
        number = 1;
        i++;
      }

      // Add the letter for the abbreviation
      number += abbrev[i];

      // We are done... stop
      break;
    }
  }

  return number;
}
export { ConvertToCurrencyAbbreviation };

function wrapText(text, width, hideXAxisLabel) {
  text.each(function () {
    const text = d3.select(this);
    const words =
      hideXAxisLabel === true ? [""] : text.text().split(/\s+/).reverse();
    let word;
    let line = [];
    let lineNumber = 0;
    const lineHeight = 1.1; // ems
    const y = text.attr("y");
    const dy = parseFloat(text.attr("dy"));
    let tspan = text
      .text(null)
      .append("tspan")
      .attr("x", 0)
      .attr("y", y)
      .attr("dy", dy + "em");
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + dy + "em")
          .text(word);
      }
    }
  });
}
export { wrapText };

function removeDuplicates(myArr, prop) {
  if (myArr === undefined) {
    return [];
  }

  if (prop === undefined) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj).indexOf(obj) === pos;
    });
  }
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
}

const PaperComponent = (props) => {
  return (
    <Draggable
      handle="#responsive-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
};

const numberWithCommas = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

function applyFilterOnData(
  _dataToBeFiltered = [],
  selectedData,
  ignoreFilter = ""
) {
  let filteredData = [..._dataToBeFiltered];
  (selectedData || []).forEach((element) => {
    if (element.selected !== undefined && element.selected.length > 0) {
      if (element.key !== ignoreFilter) {
        filteredData = getFilteredData(filteredData, element, element.key);
      }
    }
  });
  return filteredData;

  function getFilteredData(filteredData, filter, fieldKey) {
    // if(filter.key=='address')
    // {
    //   return checkMatching(filter, x, fieldKey);
    // }
    // else
    // {
    return filteredData.filter((x) => {
      return (filter.key in x || fieldKey in x) === true
        ? checkMatching(filter, x, fieldKey)
        : true;
    });
    // }
  }
  function checkMatching(element, x, fieldKey) {
    if (element.type === controlType.DatePicker) {
      if (element.operator) {
        let valueA = getFormattedValues(x[element.key], "Date", true);
        let valueB = getFormattedValues(
          element.selected[0][fieldKey],
          "Date",
          true
        );
        valueA = valueA !== "" ? new Date(valueA).getTime() : 0;
        valueB = valueB !== "" ? new Date(valueB).getTime() : 0;

        const res = element.operator(valueA, valueB);
        return res.result;
      }
      return (element.selected || []).find(
        (j) =>
          j[element.key] ===
          (element.type === controlType.DatePicker
            ? moment(x[element.key]).format("MM/DD/YYYY")
            : x[element.key])
      );
    } else if (element.type === controlType.DateRangePicker) {
      if (element.operator) {
        let valueA = getFormattedValues(x[element.key], "Date", true);
        if (element.selected[0][fieldKey].indexOf(" - ") != -1) {
          let firstDate = element.selected[0][fieldKey].split(" - ")[0];
          let secondDate = element.selected[0][fieldKey].split(" - ")[1];
          let valueB = getFormattedValues(firstDate, "Date", true);
          let valueC = getFormattedValues(secondDate, "Date", true);
          valueA = valueA !== "" ? new Date(valueA).getTime() : 0;
          valueB = valueB !== "" ? new Date(valueB).getTime() : 0;
          valueC = valueC !== "" ? new Date(valueC).getTime() : 0;
          const res =
            element.operator[0](valueA, valueB).result &&
            element.operator[1](valueA, valueC).result;
          return res;
        }
      }
      return (element.selected || []).find(
        (j) =>
          j[element.key] ===
          (element.type === controlType.DatePicker
            ? moment(x[element.key]).format("MM/DD/YYYY")
            : x[element.key])
      );
    } else if (element.type === controlType.NumericRange) {
      if (element.operator) {
        let valueA = x[element.key]; //getFormattedValues(x[element.key], 'Number', true);
        if (element.selected[0][fieldKey].toString().indexOf(" - ") != -1) {
          let firstDate = parseInt(
            element.selected[0][fieldKey].split(" - ")[0]
          );
          let secondDate = parseInt(
            element.selected[0][fieldKey].split(" - ")[1]
          );
          let valueB = firstDate; //getFormattedValues(firstDate, 'Number', true);
          let valueC = secondDate; //getFormattedValues(secondDate, 'Number', true);
          valueA = valueA !== "" ? valueA : 0;
          valueB = valueB !== "" ? valueB : 0;
          valueC = valueC !== "" ? valueC : 0;
          const res =
            valueB != 0 && valueC != 0
              ? element.operator[0](valueA, valueB).result &&
                element.operator[1](valueA, valueC).result
              : valueC != 0
              ? valueA == valueC
              : valueB == valueA;
          return res;
        }
        else if (element.selected[0][fieldKey].toString().indexOf(" - ") == -1) {
          let firstDate = parseInt(
            element.selected[0][fieldKey]
          );
         
          let valueB = firstDate; //getFormattedValues(firstDate, 'Number', true);
         // let valueC = secondDate; //getFormattedValues(secondDate, 'Number', true);
          valueA = valueA !== "" ? valueA : 0;
          valueB = valueB !== "" ? valueB : 0;
         // valueC = valueC !== "" ? valueC : 0;
          const res =element.operator[0](valueB, valueA).result && element.operator[0](valueA, valueB).result ;
          return res;
        }
      }
      return (element.selected || []).find(
        (j) =>
          j[element.key] ===
          (element.type === controlType.DatePicker
            ? moment(x[element.key]).format("MM/DD/YYYY")
            : x[element.key])
      );
    } else if (element.isComaSeprated === true) {
      return (element.selected || []).find(
        (j) =>
          ((x[element.key] || "").split("|") || []).find(
            (item) =>
              (item || "").toString().trim().toLowerCase() ===
              (j[element.key] || "").toString().trim().toLowerCase()
          ) ||
          ((x[fieldKey] || "").split("|") || []).find(
            (item) =>
              (item || "").toString().trim().toLowerCase() ===
              (j[element.key] || "").toString().trim().toLowerCase()
          )
      );
    } else if (element.type === controlType.TextBox) {
     
       if(element.selected && x[fieldKey]) 
       {
        let temp =  x[element.key]
            .toLowerCase()
            .includes(element.selected.toString().toLowerCase()) ||          
          x[fieldKey]
            .toLowerCase()
            .includes(element.selected.toString().toLowerCase())
        return temp;
       }
    } else {
      return (element.selected || []).find(
        (j) =>
          j[element.key] === x[element.key] || j[element.key] === x[fieldKey]
      );
    }
  }
}

function UpdateColumnWithConfiguration(
  columns,
  configuration,
  hiddenConfig = undefined
) {
  const filteredCols = [];
  (columns || []).forEach((c, i) => {
    const config = (configuration || []).find(
      (x) =>
        _.camelCase(x.dbColumn).toString().toLowerCase().trim() ===
        _.camelCase(c.key).toString().toLowerCase().trim()
    );
    if (config !== undefined);
    c = { ...c, ...config };
    if (hiddenConfig !== undefined && c.hideWhen) {
      (c.hideWhen || []).forEach((hideField, j) => {
        const hiddenConfigItem = hiddenConfig.find(
          (y) => y.key === hideField.key
        );
        if (hiddenConfigItem) {
          if (hiddenConfigItem.value !== hideField.value) {
            filteredCols.push(c);
          }
        } else {
          filteredCols.push(c);
        }
      });
    } else {
      filteredCols.push(c);
    }
  });
  return filteredCols;
}

function UpdateFieldsWithConfiguration(fields, configuration) {
  (fields || []).forEach((c, i) => {
    const config = (configuration || []).find(
      (x) =>
        _.camelCase(x.key).toLowerCase().trim() ===
        _.camelCase(c.dbColumn).toLowerCase().trim()
    );
    if (config !== undefined) {
      c = { ..._.cloneDeep(c), ..._.cloneDeep(config) };
    }
    if (c.dataFormat && c.dataFormat.trim() === "Boolean") {
      c.type = controlType.Switch;
    }
    if (c.dataFormat && c.dataFormat.trim() === "Checkbox") {
      c.type = controlType.Checkbox;
    }
    if (c.dataFormat && c.dataFormat.trim() === "Date") {
      c.type = controlType.DatePicker;
    }
    if (c.dataFormat && c.dataFormat.trim() === "FileInput") {
      c.type = controlType.FileInput;
    }
    if (c.dataFormat && c.dataFormat.trim() === "File") {
      c.type = controlType.File;
    }
    if (c.dataFormat && c.dataFormat.trim() === "PhoneNumber") {
      c.type = controlType.PhoneNumber;
    }
    if (c.dataFormat && c.dataFormat.trim() === "Email") {
      c.type = controlType.Email;
    }
    fields[i] = c;
  });
  return fields;
}
function isGridFormValid(value, obj, formData) {
  if (obj.isVisible === true) {
    const isRequiredField = checkRequiredValidation(value, obj, true);
    if (isRequiredField !== null) return isRequiredField;
    if (
      obj.dependentValidation !== undefined &&
      obj.dependentValidation.value === formData[obj.dependentValidation.key]
    ) {
      if (value === null || value === undefined || value === "") {
        obj.isInErrorState = true;
        obj.errorMessage = obj.name + " field is required.";
        return obj;
      }
    }
    obj = checkRegValidation(value, obj);
  }
  return CompareValue(value, obj, formData);
}
function CompareValue(value, obj, formData) {
  let isValid = true;
  if (value != null && value !== "" && value !== undefined) {
    ((obj.rules || {}).And || []).forEach((rule) => {
      if (rule && isValid) {
        if (
          formData[rule.key] === undefined ||
          formData[rule.key] === null ||
          formData[rule.key] === ""
        ) {
          return obj;
        }
        let valueA = null;
        let valueB = null;
        if (
          obj.dataFormat === "Date" ||
          (obj.editConfig || {}).type === controlType.DatePicker
        ) {
          valueA = getFormattedValues(value, "Date", true);
          valueB = getFormattedValues(formData[rule.key], "Date", true);
          valueA = valueA !== "" ? new Date(valueA).getTime() : 0;
          valueB = valueB !== "" ? new Date(valueB).getTime() : 0;
        } else {
          valueA = value;
          valueB = formData[rule.key];
          if (valueA === 0 && valueB === 0) {
            return obj;
          }
        }
        const res = rule.operator(valueA, valueB);
        if (!res.result) {
          obj.isInErrorState = true;
          obj.errorMessage = res.message(obj.name, rule.displayName || "");
          isValid = false;
          return obj;
        } else {
          obj.isInErrorState = false;
          obj.errorMessage = "";
          return obj;
        }
      }
    });
    if (isValid) {
      ((obj.rules || {}).Or || []).forEach((rule) => {
        if (rule) {
          if (!rule.operator(value, formData[rule.key])) {
            obj.isInErrorState = true;
            obj.errorMessage = rule.message(obj.name, rule.displayName || "");
          } else {
            obj.isInErrorState = false;
            obj.errorMessage = "";
          }
        }
      });
    }
  } else {
    obj.isInErrorState = false;
    obj.errorMessage = "";
  }
  return obj;
}

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}
function isInputValid(value, obj, formFields) {
  if (obj.isVisible === true) {
    if (
      obj.isRequired === false &&
      (value === null || value === undefined || value === "")
    ) {
      return obj;
    }
    const isRequiredField = checkRequiredValidation(value, obj);
    if (isRequiredField !== null) return isRequiredField;
    obj = checkRegValidation(value, obj);
  }
  return validateRules(obj, formFields);
}
function checkRegValidation(value, obj) {
  let isValid = true;
  if (obj.isVisible === true) {
    if (
      //obj.dataFormat === 'Number' ||
      obj.dataFormat === "MoneyNumber" ||
      obj.dataFormat === "Percent" ||
      obj.dataFormat === "DecimalPercent"
    ) {
      const numericInput = new RegExp(
        "^\\s*((\\d+(\\.\\d{0,2})?)|((\\d*(\\.\\d{1,2}))))\\s*$"
      ); // '/^[0-9\b]+$/');
      let hasS = hasWhiteSpace(value);
      isValid = numericInput.test(value) && !hasS;
    } else if (obj.dataFormat === "Year") {
      const numericInput = new RegExp("^([0-9]{4})?$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj.dataFormat === "Number") {
      const numericInput = new RegExp("^[0-9]*$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj.dataFormat === "Text" || obj.dataFormat === "MultiText") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("[0-9a-zA-Z_]*");
        isValid = textInput.test(value);
      }
    } else if (obj.dataFormat === "AlphaNumeric") {
      const alphaNumInput = new RegExp("^[a-zA-Z0-9]*$");
      isValid = alphaNumInput.test(value);
    } else if (obj.dataFormat === "CityState") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("^[a-zA-Z][a-zA-Z ]+$");

        isValid = textInput.test(value);
      }
    } else if (obj.dataFormat === "Zip") {
      const zipInput = new RegExp("/(^d{5}$)|(^d{5}-d{4}$)/"); // '/^[0-9\b]+$/');
      isValid = zipInput.test(value);
    } else if (obj.dataFormat === "Boolean") {
      if (
        value !== null &&
        (value === "0" || value === "1" || value === true || value === false)
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } else if (obj.dataFormat === "Email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test((value || "").toString().toLowerCase());
    } else if (obj.dataFormat === "PhoneNumber") {
      const textInput = /(?=(?:\D*\d){10,15}\D*$)/;
      isValid = textInput.test(value);
    } else if (obj.dataFormat === "Latitude") {
      const textInput =
        /^(\+|-)?(?:90(?:(?:\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,7})?))$/;
      isValid = textInput.test(value);
    } else if (obj.dataFormat === "Longitude") {
      const textInput =
        /^(\+|-)?(?:180(?:(?:\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,7})?))$/;
      isValid = textInput.test(value);
    } else if (obj.dataFormat === "Decimal") {
      const textInput = /[+-]?([0-9]*[.])?[0-9]+/;
      let hasS = hasWhiteSpace(value);
      isValid = textInput.test(value) && !hasS;
    } else if (obj.dataFormat === "ZipCode") {
      if (value.includes("-")) value = value.replace("-", "");
      if (!isNaN(parseInt(value))) {
        if (parseInt(value) === 0 || (value.length > 4 && value.length < 6)) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }

    if (isValid === false) {
      obj.isInErrorState = true;
      obj.errorMessage = "Please enter valid data in field.";
    } else {
      obj.isInErrorState = false;
      obj.errorMessage = "";
    }
  }
  return obj;
}

function checkFileValidation(value, obj) {
  let isValid = true;
  if (value != null || value != undefined) {
    if (
      obj === "MoneyNumber" ||
      obj === "Percent" ||
      obj === "DecimalPercent"
    ) {
      const numericInput = new RegExp(
        "^\\s*((\\d+(\\.\\d{0,2})?)|((\\d*(\\.\\d{1,2}))))\\s*$"
      ); // '/^[0-9\b]+$/');
      if (obj === "MoneyNumber" && value.includes("$"))
        value = value.replace("$", "");
      isValid = numericInput.test(value);
    } else if (obj === "Year") {
      const numericInput = new RegExp("^([0-9]{4})?$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj === "Number") {
      const numericInput = new RegExp("^[0-9]*$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj === "Text" || obj === "MultiText") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("[0-9a-zA-Z_]*");
        isValid = textInput.test(value);
      }
    } else if (obj === "AlphaNumeric") {
      const alphaNumInput = new RegExp("^[a-zA-Z0-9]*$");
      isValid = alphaNumInput.test(value);
    } else if (obj === "CityState") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("^[a-zA-Z][a-zA-Z ]+$");

        isValid = textInput.test(value);
      }
    } else if (obj === "Zip") {
      const zipInput = new RegExp("/(^d{5}$)|(^d{5}-d{4}$)/"); // '/^[0-9\b]+$/');
      isValid = zipInput.test(value);
    } else if (obj === "Boolean") {
      if (
        value &&
        (value === "0" || value === "1" || value === true || value === false)
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } 
    else if (obj === "Checkbox") {
      if (
        value &&
        (value.toLowerCase() === "yes" || value.toLowerCase() === "no")
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } 
    else if (obj === "Email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test((value || "").toString().toLowerCase());
    } else if (obj.dataFormat === "PhoneNumber") {
      const textInput = /(?=(?:\D*\d){10,15}\D*$)/;
      isValid = textInput.test(value);
    } else if (obj === "Latitude") {
      const textInput =
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
      isValid = textInput.test(value);
    } else if (obj === "Longitude") {
      const textInput =
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
      isValid = textInput.test(value);
    } else if (obj === "Decimal") {
      const textInput = /[+-]?([0-9]*[.])?[0-9]+/;
      isValid = textInput.test(value);
    } else if (obj === "Date" || obj === "date") {
      let enterValue = moment(value, "YYYY-MM-DD", true).isValid();
      if (enterValue == false || value == "Invalid Date") {
        isValid = false;
      } else isValid = true;
    } else if (obj === "ZipCode") {
      if (value.includes("-")) value = value.replace("-", "");
      if (!isNaN(parseInt(value))) {
        if (parseInt(value) === 0 || (value.length > 4 && value.length < 6)) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }
  }
  //  let returnObj=[];
  //     if (isValid === false) {
  //       returnObj.isInErrorState = true;
  //       returnObj.errorMessage = 'Please enter valid data in field.';
  //     } else {
  //       returnObj.isInErrorState = false;
  //       returnObj.errorMessage = '';
  //     }

  return isValid;
}


function loanFileValidation(value, obj) {
  let isValid = true;
  if (value != null || value != undefined) {
    if (
      obj === "MoneyNumber" ||
      obj === "Percent" ||
      obj === "DecimalPercent"
    ) {
      const numericInput = new RegExp(
        "^\\s*((\\d+(\\.\\d{0,2})?)|((\\d*(\\.\\d{1,20}))))\\s*$"
      ); // '/^[0-9\b]+$/');
      if (obj === "MoneyNumber" && value.includes("$"))
        value = value.replace("$", "");
      isValid = numericInput.test(value);
    } else if (obj === "Year") {
      const numericInput = new RegExp("^([0-9]{4})?$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj === "Number") {
      const numericInput = new RegExp("^[0-9]*$"); // '/^[0-9\b]+$/');

      isValid = numericInput.test(value);
    } else if (obj === "Text" || obj === "MultiText") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("[0-9a-zA-Z_]*");
        isValid = textInput.test(value);
      }
    } else if (obj === "AlphaNumeric") {
      const alphaNumInput = new RegExp("^[a-zA-Z0-9]*$");
      isValid = alphaNumInput.test(value);
    } else if (obj === "CityState") {
      if (value === "---" || value === "--") {
        isValid = true;
      } else {
        const textInput = new RegExp("^[a-zA-Z][a-zA-Z ]+$");

        isValid = textInput.test(value);
      }
    } else if (obj === "Zip") {
      const zipInput = new RegExp("/(^d{5}$)|(^d{5}-d{4}$)/"); // '/^[0-9\b]+$/');
      isValid = zipInput.test(value);
    } else if (obj === "Boolean") {
      if (
        value &&
        (value === "0" || value === "1" || value === true || value === false)
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } 
    else if (obj === "Checkbox") {
      if (
        value &&
        (value.toLowerCase() === "yes" || value.toLowerCase() === "no")
      ) {
        isValid = true;
      } else {
        isValid = false;
      }
    } 
    else if (obj === "Email") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test((value || "").toString().toLowerCase());
    } else if (obj.dataFormat === "PhoneNumber") {
      const textInput = /(?=(?:\D*\d){10,15}\D*$)/;
      isValid = textInput.test(value);
    } else if (obj === "Latitude") {
      const textInput =
        /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/;
      isValid = textInput.test(value);
    } else if (obj === "Longitude") {
      const textInput =
        /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/;
      isValid = textInput.test(value);
    } else if (obj === "Decimal") {
      const textInput = /[+-]?([0-9]*[.])?[0-9]+/;
      isValid = textInput.test(value);
    } else if (obj === "Date" || obj === "date") {
      let enterValue = moment(value, "YYYY-MM-DD", true).isValid();
      if (enterValue == false || value == "Invalid Date") {
        isValid = false;
      } else isValid = true;
    } else if (obj === "ZipCode") {
      if (value.includes("-")) value = value.replace("-", "");
      if (!isNaN(parseInt(value))) {
        if (parseInt(value) === 0 || (value.length > 4 && value.length < 6)) {
          isValid = true;
        } else {
          isValid = false;
        }
      } else {
        isValid = false;
      }
    }
  }
  //  let returnObj=[];
  //     if (isValid === false) {
  //       returnObj.isInErrorState = true;
  //       returnObj.errorMessage = 'Please enter valid data in field.';
  //     } else {
  //       returnObj.isInErrorState = false;
  //       returnObj.errorMessage = '';
  //     }

  return isValid;
}
function checkRequiredValidation(value, obj, isCallFromGrid = false) {
  if (obj.isVisible === true) {
    if (
      obj.isRequired === true &&
      (value === null || value === undefined || value === "")
    ) {
      obj.isInErrorState = true;
      obj.errorMessage =
        (isCallFromGrid ? obj.name : obj.displayName) + " field is required.";
      return obj;
    }
  }
  return null;
}
function validateRules(field, formFields) {
  ((field.rules || {}).And || []).forEach((rule) => {
    if (rule) {
      const targetField =
        formFields.find(
          (x) => _.camelCase(x.dbColumn) === _.camelCase(rule.key)
        ) || {};
      if (
        targetField.isRequired === false &&
        (targetField.attributeValue === undefined ||
          targetField.attributeValue === null ||
          targetField.attributeValue === "")
      ) {
        return field;
      }
      let valueA = getFieldValueByFormat(field);
      let valueB = getFieldValueByFormat(targetField);
      valueA = valueA !== "" ? new Date(valueA).getTime() : 0;
      valueB = valueB !== "" ? new Date(valueB).getTime() : 0;
      const res = rule.operator(valueA, valueB);
      if (!res.result) {
        field.isInErrorState = true;
        field.errorMessage = res.message(
          field.displayName,
          targetField.displayName || ""
        );
        return field;
      }
    }
  });
  ((field.rules || {}).Or || []).forEach((rule) => {
    if (rule) {
      const targetField = formFields.find((x) => x.dbColumn === rule.key) || {};
      if (!rule.operator(field.attributeValue, targetField.attributeValue)) {
        field.isInErrorState = true;
        field.errorMessage = rule.message(
          field.displayName,
          targetField.displayName || ""
        );
      } else {
        field.isInErrorState = false;
        field.errorMessage = "";
      }
    }
  });
  return field;
}

function getSectionAttributeValueByKey(data, key, returnDefaultValue = true) {
  const value =
    (
      (data || []).find((x) => _.camelCase(x.dbColumn) === _.camelCase(key)) ||
      {}
    ).attributeValue || (returnDefaultValue ? "" : undefined);
  return value;
}

function getSectionAttributesByKeyValue(data, key, value) {
  const filterData = _.cloneDeep(
    (data || []).filter(
      (x) =>
        (x[_.camelCase(key)] || "").toString().toLowerCase() ===
        (value || "").toString().toLowerCase()
    )
  );

  return filterData;
}
function getSectionAttributeFieldValueByKeyValue(data, key, value, field) {
  const filterData = _.cloneDeep(
    (data || []).find(
      (x) =>
        _.camelCase(x.dbColumn) === _.camelCase(key) &&
        (x.attributeValue || "").toString().toLowerCase() ===
          (value || "").toString().toLowerCase()
    )
  );

  return filterData[_.camelCase(field)];
}
function RemoveSectionAttributeByKeyValue(data, key, value) {
  return (
    (data || []).filter(
      (x) =>
        (x[_.camelCase(key)] || "").toString().toLowerCase() !==
        (value || "").toString().toLowerCase()
    ) || []
  );
}

function getSectionFieldByKey(data, key) {
  const field =
    (data || []).find((x) => _.camelCase(x.dbColumn) === _.camelCase(key)) ||
    {};
  return field;
}
function setSectionAttributeValueByKey(
  data,
  key,
  value,
  setDefaultValue = true
) {
  const field =
    (data || []).find((x) => _.camelCase(x.dbColumn) === _.camelCase(key)) ||
    {};
  field.attributeValue = value || (setDefaultValue ? "" : undefined);
  return data;
}
function setAllSectionAttributeValueByKey(
  data,
  key,
  value,
  setDefaultValue = true
) {
  const field =
    (data || []).filter((x) => _.camelCase(x.dbColumn) === _.camelCase(key)) ||
    [];
  field.forEach((fi) => {
    fi.attributeValue = value || (setDefaultValue ? "" : undefined);
  });
  return data;
}

function setSectionFieldAttributeByKey(data, key, attribute, value) {
  const field =
    (data || []).find((x) => _.camelCase(x.dbColumn) === _.camelCase(key)) ||
    {};
  field[attribute] = value;
  return field;
}
function isDateInPreviousPayCycle(date) {
  let isPrevious = false;
  isPrevious = isDateInCurrentPayCycle(date);
  if (isPrevious === false && date < moment().format(DateFormat)) {
    isPrevious = true;
  } else {
    isPrevious = false;
  }
  return isPrevious;
}
function isDateInNextPayCycle(date) {
  let isNext = false;
  isNext = isDateInCurrentPayCycle(date);
  if (isNext === false && date > moment().format(DateFormat)) {
    isNext = true;
  } else {
    isNext = false;
  }
  return isNext;
}
function isDateInCurrentPayCycle(date) {
  let isValid = false;
  const currentMonthStartDate = moment().startOf("month").format(DateFormat);
  const currentMonthMidDate = moment(currentMonthStartDate, DateFormat).add(
    14,
    "days"
  );
  const currentMonthLastDate = moment().endOf("month").format(DateFormat);
  if (
    (date >= currentMonthStartDate &&
      date <= currentMonthMidDate.format(DateFormat) &&
      moment().format(DateFormat) >= currentMonthStartDate &&
      moment().format(DateFormat) <= currentMonthMidDate.format(DateFormat)) ||
    (date >= currentMonthMidDate.add(1, "days").format(DateFormat) &&
      date <= currentMonthLastDate &&
      moment().format(DateFormat) >=
        currentMonthMidDate.add(1, "days").format(DateFormat) &&
      moment().format(DateFormat) <= currentMonthLastDate)
  ) {
    isValid = true;
  }
  return isValid;
}

function getCurrentPayCycleStartDate() {
  const currentMonthStartDate = moment(
    moment().startOf("month").format(DateFormat)
  );
  const currentMonthMidDate = moment(currentMonthStartDate, DateFormat).add(
    14,
    "days"
  );
  if (moment() <= currentMonthMidDate) {
    return currentMonthStartDate;
  } else {
    return currentMonthMidDate.add(1, "days");
  }
}
function getCurrentPayCycleStartDateByDate(date) {
  const currentMonthStartDate = moment(
    moment(date).startOf("month").format(DateFormat)
  );
  const currentMonthMidDate = moment(currentMonthStartDate, DateFormat).add(
    14,
    "days"
  );
  if (moment(date) <= currentMonthMidDate) {
    return currentMonthStartDate;
  } else {
    return currentMonthMidDate.add(1, "days");
  }
}

function getCurrentPayCycleEndDate() {
  const currentMonthStartDate = moment(
    moment().startOf("month").format(DateFormat)
  );
  const currentMonthMidDate = moment(currentMonthStartDate, DateFormat).add(
    14,
    "days"
  );
  if (moment() <= currentMonthMidDate) {
    return currentMonthMidDate.add(1, "days");
  } else {
    return moment(moment().endOf("month").format(DateFormat));
  }
}

function getCurrentPayCycleEndDateByDate(date) {
  const currentMonthStartDate = moment(
    moment(date).startOf("month").format(DateFormat)
  );
  const currentMonthMidDate = moment(currentMonthStartDate, DateFormat).add(
    14,
    "days"
  );
  if (
    moment(date).format(DateFormat) <=
    moment(currentMonthMidDate).format(DateFormat)
  ) {
    // return currentMonthMidDate.add(1, 'days');
    return currentMonthMidDate;
  } else {
    return moment(moment(date).endOf("month").format(DateFormat));
  }
}

function getDistinctByProperty(data, key) {
  const distinct = [];
  (data || []).forEach((obj) => {
    if (
      distinct.find((x) => x[_.camelCase(key)] === obj[_.camelCase(key)]) ===
      undefined
    ) {
      distinct.push(_.cloneDeep(obj));
    }
  });
  return distinct;
}

// function getUSerConfigData()
// {

//   let group=window.sessionStorage.getItem("changedGroup") ;
//   let businessLineId=1;

//   if(group == "Office" || group == null )
//   businessLineId=1;
//   else
//   businessLineId=2;

//    apiBatchRequest(
//      [
//      {
//        url: API_URL.Users.GetUserOrganizationDetails,
//        key:'userConfigurationdata'
//      },
//      {
//        url: API_URL.Users.GetUserConfig,
//        key: 'userConfig',
//        params:{
//          businessLineId:businessLineId}
//      }
//    ]
//    ).then(( userConfigurationdata ) => {
//      const cloudOrg = ((userConfigurationdata.userConfigurationdata.data || [])[0] || {});
//      localStorage.setItem(
//        API_HEADERS.CID, cloudOrg.cloudOrgId
//      );
//      dispatch({
//        type:actionType.GET_USER_CONFIG,
//        payload:userConfigurationdata.userConfig.data,

//      });

//      apiBatchRequest([
//        {
//          url: API_URL.Master.GetUserConfigurations,
//          key: 'userData',
//          params: { sectionId: -1 },
//        },
//        // {
//        //   url: API_URL.Users.GetUserConfig,
//        //   key: 'userConfig',
//        // }
//      ]).then(({ userData,userConfig }) => {
//        window.sessionStorage.setItem("userConfigurations", JSON.stringify(userData.data));
//       // window.sessionStorage.setItem("userMarketbasedAccess", JSON.stringify(userConfig.data.userMarket));
//        dispatch({
//          type: actionType.GET_USER_DATA,
//          payload: userData.data,
//          // type:actionType.GET_USER_CONFIG,
//          // payload:userConfig.data,

//        });

//      });
//    });
// }

function IsAdmin() {
  // let userConfig=store.getState();
  // if (userConfig.user.userConfig === null || userConfig.user.userConfig === undefined ) {
  //   window.location = "/";
  // }

  const { user } = store.getState();
  if ((user || {}).userConfig) {
    if (
      (((user.userConfig || {}).groups || [])[0] || {}).accessGroupId === 1 ||
      (((user.userConfig || {}).groups || [])[0] || {}).accessGroupId === 4
    ) {
      return true;
    }
  }
  return false;
}
function GetUserConfiguration() {
  const { user } = store.getState();
  return user.userConfig || {};
}
function GetUserMarketAccess() {
  const { user } = store.getState();
  return user.userConfig.userMarket || {};
}
function GetSectionPermission(parentSectionId, subSectionId = 0) {
  const { user } = store.getState();
  const permission = ((user.userConfig || {}).userPermissions || []).find(
    (x) =>
      x.parentSectionId === parentSectionId &&
      (x.subSectionId === subSectionId ||
        (subSectionId === 0 && x.subSectionId === parentSectionId))
  );

  if (permission !== undefined) {
    return permission;
  }
  return {};
}
function renameKey(obj, oldKey, newKey) {
  if (Array.isArray(oldKey)) {
    for (var i = 0; i < oldKey.length; i++) {
      var k = oldKey[i];
      if (k in obj) {
        obj[newKey] = obj[k];
        delete obj[k];
      }
    }
  } else if (newKey == oldKey) {
    obj[newKey] = obj[oldKey];
  } else {
    if (newKey != oldKey) {
      obj[newKey] = obj[oldKey];
      delete obj[oldKey];
    }
  }
}
function IsSectionVisible(parentSectionId, subSectionId = 0, action = null) {
  const permission = GetSectionPermission(parentSectionId, subSectionId);
  if (permission !== undefined) {
    if (action != null) {
      if (action === SECTIONS.ADD_EDIT) {
        return permission.accessTypeId > 1 && permission.accessTypeId <= 3;
      } else if (action === SECTIONS.VIEW) {
        return permission.accessTypeId === 1 || permission.accessTypeId === 3;
      }
    }
    if (permission.accessTypeId > 0 && permission.accessTypeId < 4) return true;
  }
  return false;
}

function buildWidgetUrlWithParameter(url, arg) {
  if (arg && arg.length > 0) {
    arg.forEach((a) => {
      url = url.replace("#" + a.key + "#", a.value);
    });
  }

  return url;
}

function calculateAvgerage(value, count, divideBy = 100) {
  return value != null && count != null ? value / count : value;
}
function getTotalCount1(data, count, key) {
  let rowCount = 0;
  let rowLength = 0;
  let counter = 0;
  data.forEach((m) => {
    if (m.data == undefined && m[key] != null) {
      counter = counter + 1;
    }
    if (m.data != undefined && m.data[0].data != undefined) {
      m.data.forEach((n) => {
        n.data.forEach((y) => {
          rowLength = y[key] != null ? rowLength + 1 : rowLength;
        });
        //rowLength=n[key] != null ? rowLength + n.data.length:rowLength
      });
    }
    rowCount =
      m.data != undefined
        ? m.data[0].data != undefined
          ? rowLength
          : rowCount + m.data.length
        : counter;
  });
  return rowCount;
}
function getTotalCount(data, count, key) {
  let rowCount = 0;
  let rowLength = 0;
  let counter = 0;
  data.forEach((m) => {
    if (m.data == undefined && m[key] != null) {
      counter = counter + 1;
    }
    if (m.data != undefined && m.data[0].data != undefined) {
      m.data.forEach((n) => {
        n.data.forEach((y) => {
          rowLength = y[key] != null ? rowLength + 1 : rowLength;
        });
        //rowLength=n[key] != null ? rowLength + n.data.length:rowLength
      });
    }
    if (m.data != undefined && m.data[0].data == undefined) {
      m.data.forEach((n) => {
        rowLength = n[key] != null ? rowLength + 1 : rowLength;

        //rowLength=n[key] != null ? rowLength + n.data.length:rowLength
      });
    }
    rowCount =
      m.data != undefined
        ? m.data[0].data != undefined
          ? rowLength
          : rowLength
        : counter;
  });
  return rowCount;
}
function generatePDFFromHtml(title, element, fileName, headers, data) {
  const unit = "pt";
  const size = "A4"; // Use A1, A2, A3 or A4
  const orientation = "landscape"; // portrait or landscape

  const marginLeft = 40;
  const doc = new jsPDF(orientation, unit, size);
  doc.setFontSize(15);
  const content = {
    startY: 50,
    head: headers,
    body: data,
  };

  doc.text(title, marginLeft, 40);
  doc.autoTable(content);

  doc.save(fileName ? fileName + ".pdf" : title + ".pdf");
  // doc.text(title, 300, 40);
  // const img = new Image();

  //   doc.autoTable({ html: '#gridTable' });
  //   doc.save(fileName ? fileName + '.pdf' : title + '.pdf');

  // const img = new Image();
  // img.onload = function () {
  //   doc.addImage(StreamLogo, 'jpg', marginLeft, 40, 100, 200);
  //   doc.autoTable({ html: '#gridTable' });
  //   doc.save(fileName ? fileName + '.pdf' : title + '.pdf');
  // };
  // img.crossOrigin = '';
  // img.src = StreamLogo;

  // img.crossOrigin = '';
  // img.src = StreamLogo;
}
function downloadExcelFile(absoluteUrl, fileName) {
  var link = document.createElement("a");
  link.href = absoluteUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  //delete link;
}
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    conditionalHeadCells,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const groupSequence = props.columnSequence;
  return (
    <TableHead>
      <TableRow>
        {groupSequence.map((col, index) => (
          <TableCell
            key={"th" + col.value + index}
            align={col.numeric ? "right" : "left"}
            padding={col.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === col.id ? order : false}
          >
            {/* {col.key} */}
            <TableSortLabel
              active={orderBy === col.id}
              direction={orderBy === col.id ? order : "asc"}
              onClick={createSortHandler(col.id)}
            >
              {col.key}
              {orderBy === col.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {conditionalHeadCells != undefined &&
          conditionalHeadCells.length > 0 &&
          conditionalHeadCells.map((col, index) => (
            <TableCell
              key={"th" + col.value + index}
              align={col.numeric ? "right" : "left"}
              padding={col.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === col.id ? order : false}
            >
              {/* {col.key} */}
              <TableSortLabel
                active={orderBy === col.id}
                direction={orderBy === col.id ? order : "asc"}
                onClick={createSortHandler(col.id)}
              >
                {col.label}
                {orderBy === col.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "" : ""}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
          // <TableCell
          //   key={headCell.key}
          //   align={headCell.numeric ? 'right' : 'left'}
          //   padding={headCell.disablePadding ? 'none' : 'normal'}
          //   sortDirection={orderBy === headCell.key ? order : false}
          // >
          //   <TableSortLabel
          //     active={orderBy === headCell.key}
          //     direction={orderBy === headCell.key ? order : 'asc'}
          //     onClick={createSortHandler(headCell.key)}
          //   >
          //     {headCell.label}
          //     {orderBy === headCell.key ? (
          //       <Box component="span" sx={visuallyHidden}>
          //         {order === 'desc' ? '' : ''}
          //       </Box>
          //     ) : null}
          //   </TableSortLabel>
          // </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnSequence: PropTypes.array,
  conditionalHeadCells: PropTypes.array,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
  // if(isNaN(a[orderBy]) && isNaN(b[orderBy]))
  if (typeof a[orderBy] === "string" && typeof a[orderBy] === "string") {
    const lowerCaseB =
      b[orderBy] != undefined
        ? b[orderBy].toString().toLowerCase()
        : b[orderBy];
    const lowerCaseA =
      a[orderBy] != undefined
        ? a[orderBy].toString().toLowerCase()
        : a[orderBy];
    if (lowerCaseB < lowerCaseA) {
      return -1;
    }
    if (lowerCaseB > lowerCaseA) {
      return 1;
    }
  } else if (!isNaN(b[orderBy]) && !isNaN(a[orderBy])) {
    const numA = Number(a[orderBy]).toFixed(2);
    const numB = Number(b[orderBy]).toFixed(2);
    if (Number(numB) < Number(numA)) {
      return -1;
    }
    if (Number(numB) > Number(numA)) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function fileDateAppender() {
  var currentdate = new Date();
  var datetime =
    "_" +
    (currentdate.getMonth() + 1) +
    "" +
    currentdate.getDate() +
    "" +
    currentdate.getFullYear() +
    "_" +
    currentdate.getHours() +
    "" +
    currentdate.getMinutes() +
    "" +
    currentdate.getSeconds();
  return datetime;
}

export {
  descendingComparator,
  getComparator,
  stableSort,
  generatePDFFromHtml,
  removeDuplicates,
  PaperComponent,
  numberWithCommas,
  applyFilterOnData,
  useQuery,
  UpdateColumnWithConfiguration,
  UpdateFieldsWithConfiguration,
  isInputValid,
  getSectionAttributeValueByKey,
  isGridFormValid,
  setSectionAttributeValueByKey,
  getSectionFieldByKey,
  setSectionFieldAttributeByKey,
  isDateInCurrentPayCycle,
  isDateInPreviousPayCycle,
  isDateInNextPayCycle,
  getCurrentPayCycleStartDate,
  getDistinctByProperty,
  setAllSectionAttributeValueByKey,
  getCurrentPayCycleEndDate,
  buildWidgetUrlWithParameter,
  RemoveSectionAttributeByKeyValue,
  getSectionAttributesByKeyValue,
  getSectionAttributeFieldValueByKeyValue,
  IsAdmin,
  IsSectionVisible,
  GetUserConfiguration,
  GetUserMarketAccess,
  getCurrentPayCycleStartDateByDate,
  getCurrentPayCycleEndDateByDate,
  EnhancedTableToolbar,
  EnhancedTableHead,
  downloadExcelFile,
  renameKey,
  fileDateAppender,
  checkFileValidation,
  loanFileValidation,
  calculateAvgerage,
  getTotalCount,
};

import { Divider } from "@material-ui/core";
import React from "react";
import {
	applyFilterOnData,
	generatePDFFromHtml,
	IsSectionVisible,
	UpdateColumnWithConfiguration,
} from "../../../utils/helper";
import PageHeader from "../../common/PageHeader";
import GridTable from "../../common/Table/Table";
import "../../../public/css/style.less";
import { apiBatchRequest,apiBatchRequestWithoutLoader, apiPost, apiPut, CancelRequest, cancelTokenSource, RenewCancelToken } from "../../../utils/api_service";
import ReportButtons from "../../../utils/ReportButtons";
import { API_URL } from "../../../constants/api";
import { industrialTenantColumns } from "../../../constants/MockData";
import {
	FormControlLabel,
	IconButton,
	TextField,
	Tooltip,
	Button,
} from "@material-ui/core";
import { ShowToast,toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import PageFilter from "../../common/PageFilter";
import { controlType } from "../../../constants/common";
import EditIndTenants from "./EditIndTenants";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../../common/AutoSearch/SearchBar";
import SettingScreenDialog from "../../common/SettingScreenDialog/SettingScreenDialog";
import { downloadExcelReportByAPI } from "../../../utils/generic";
import { SECTIONS } from "../../../constants/appConstants";

const uploadColumns = [
	{
		key: "companyName",
		name: "Tenant Name",
	},
	{
		key: "buildingName",
		name: "Property Name",
	},

	{
		key: "rsf",
		name: "RSF",
	},
	{
		key: "market",
		name: "Market",
	},
	{
		key: "subMarket",
		name: "Sub Market",
	},

	{
		key: "lcd",
		name: "LCD",
	},
	{
		key: "productType",
		name: "Type",
	},
	{
		key: "lxDte",
		name: "Lx Date",
	},
	{
		key: "rateAtLcd",
		name: "Rate At LCD",
	},
];

const columnWidths = [
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
	{ wch: 20 },
];
export default class IndTenants extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMarketId:this.props.globalMarketId,
			reloadTable: false,
			subMarketFilteredData: [],
			subMarketData: [],
			openFilter: false,
			isInAddMode: false,
			tenantSummaryData: [],
			responseBuildingData: [],
			configuration: [],
			buildingMasterData: [],
			companyMasterDetails: [],
			getSubMarketMaster: [],
			selectedEditRowId: undefined,
			detailView: false,
			ViewTitle: undefined,
			actionType: undefined,
			getBuildingMaster: [],
			getMarketMaster: [],
			searchInput: "",
			filterSelectedData: [],
			userConfiguration: [],
			openSlidingPanel: false,
			dbColumnList: [],
			rowsPerPage: 20,
			listOfColumns: [],
			enableFilterButton:false,
		};
	}

	onSettingChange = (config, pageSize) => {
		let param = [];
		if (config.length > 0) {
			param = config.filter((m) => {
				if (pageSize != 0) m.rowPerPage = pageSize;
				return m;
			});
		} else {
			param = this.state.userConfiguration.filter((m) => {
				if (m.dataFormat == 'MultiText') m.minTag = 1;
				if (
					m.sectionId == 17 &&
					m.isVisible == true &&
					m.key == "companyName"
				) {
					m.renderComponent = "renderActionColumn";
					// this.setState({
					if (pageSize != 0) m.rowPerPage = pageSize;
					//pageSize = m.rowPerPage;
					//reloadTable:!this.state.reloadTable,
					//});
					return m;
				}
			});
		}
		// this.setState(
		//   {
		//     selectedConfigData: config,
		// 	openSlidingPanel:false,
		//      },
		// );
		apiPost({
			url: API_URL.Master.addUserConfiguration,
			params: {},
			postBody: param,
		})
		  .then(({ data: response }) => {
			if (response.success === true) {
			  this.setState({
				// userConfiguration: camelcaseKeys(response.data),
				// rowsPerPage: response.data[0].rowPerPage,
				reloadTable: !this.state.reloadTable,
				openSlidingPanel: false,
			  });
			  // this.forceUpdate();
			  this.getUserConfigurationBySectionId();
			  ShowToast(
				commonLabels.settingUpdated,
				toastType.SUCCESS,
				5000,
				500
			  );
			  this.getRequiredData(this.state.selectedMarketId);
			} else {
			  ShowToast(commonLabels.settingNotUpdated, toastType.error, 5000, 500);
			}
		  })
		  .catch(() => {
			ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
		  });
	  };

	  componentDidMount() {
		RenewCancelToken();
		this.getRequiredData(this.state.selectedMarketId);
		//this.getRequiredDataWithoutLoader();
	}

	componentWillReceiveProps(nextProps) {    
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {      
		  this.getRequiredData(nextProps.globalMarketId);
		  this.setState({ selectedMarketId: nextProps.globalMarketId });
		}
	  }

	componentWillUnmount()
  	{
    	CancelRequest();
  	}

	getUserConfigurationBySectionId = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.GetUserConfigurations,
				key: "userConfiguration",
				params: { sectionId: 17 },
			},
		])
		  .then((userConfiguration) => {
			this._asyncRequest = null;
			if (userConfiguration.userConfiguration.success == true) {
			  this.setState({
				userConfiguration:
				  camelcaseKeys(userConfiguration.userConfiguration.data).sort(
					(a, b) => (a.attributeSortOrder > b.attributeSortOrder ? 1 : -1)
				  ) || [],
				rowsPerPage: userConfiguration.userConfiguration.data[2].rowPerPage,
				//reloadRequired:true,
				reloadTable: !this.state.reloadTable,
				listOfColumns: userConfiguration.userConfiguration.data.filter(
				  (m) => {
					if (m.isVisible == true) return m.key;
				  }
				),
			  });
			  this.forceUpdate();
			  //this.child.setRowsPerPage(this.state.rowsPerPage);
			  this.childRef.current.handleChangeRowsPerPage(this.state.rowsPerPage);
			} else {
			  ShowToast(
				userConfiguration.userConfiguration.message,
				toastType.ERROR,
				5000,
				500,
				"Server error"
			  );
			}
		  })
		  .catch(() => {
			this._asyncRequest = null;
		  });
	  };

	getRequiredDataWithoutLoader = (selectedMarketId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.industrialBuildingMasterData,
				key: "buildingMasterData",
				params: {  marketId: selectedMarketId },
			},
			{
				url: API_URL.Master.getALLTenantCompany,
				key: "companyMasterDetails",
				params: { businessLineId: 2 },
				// url: API_URL.IndustrialBuildingOwners.buildingOwnerMasterDetails,
				// key: "companyMasterDetails",
			},
			{
				url: API_URL.Master.getSubMarketMaster,
				key: "getSubMarketMaster",
				params: { businessLineId: 2, marketId: selectedMarketId },
			},
			{
				url: API_URL.Master.getBuildingMaster,
				key: "getBuildingMaster",
				params: { businessLineId: 2, marketId: this.state.selectedMarketId },
			},
			{
				url: API_URL.Master.getMarketMaster,
				key: "getMarketMaster",
				params: { businessLineId: 2, marketId: selectedMarketId },
			},
			{
				url: API_URL.Master.getProductTypeMaster,
				key: "getProductTypeMaster",
			},
		])
		.then((tenantSummaryData) => {
			this._asyncRequest = null;
			if (tenantSummaryData.buildingMasterData.success == true) {
				this.setState({
					buildingMasterData:
							camelcaseKeys(tenantSummaryData.buildingMasterData.data) || [],
						companyMasterDetails:
							camelcaseKeys(tenantSummaryData.companyMasterDetails.data) || [],
						getSubMarketMaster:
							camelcaseKeys(tenantSummaryData.getSubMarketMaster.data) || [],
						subMarketData:
							camelcaseKeys(tenantSummaryData.getSubMarketMaster.data) || [],
						getBuildingMaster:
							camelcaseKeys(tenantSummaryData.getBuildingMaster.data) || [],
						getMarketMaster:
							camelcaseKeys(tenantSummaryData.getMarketMaster.data) || [],
						getProductTypeMaster:
							camelcaseKeys(tenantSummaryData.getProductTypeMaster.data) || [],
						enableFilterButton: true
				})
				} else {
					ShowToast(
						tenantSummaryData.tenantSummaryData.message,
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}

	getRequiredData = (selectedMarketId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.IndustrialTenants.tenantsSummaryData,
				key: "tenantSummaryData",
				params: { marketId: selectedMarketId },
			},
			//{
			//	url: API_URL.Master.industrialBuildingMasterData,
			//	key: "buildingMasterData",
			//},
			//{
			//	url: API_URL.Master.getALLTenantCompany,
			//	key: "companyMasterDetails",
			//	params: { businessLineId: 2 },
			//	// url: API_URL.IndustrialBuildingOwners.buildingOwnerMasterDetails,
			//	// key: "companyMasterDetails",
			//},
			//{
			//	url: API_URL.Master.getSubMarketMaster,
			//	key: "getSubMarketMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getBuildingMaster,
			//	key: "getBuildingMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getMarketMaster,
			//	key: "getMarketMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getProductTypeMaster,
			//	key: "getProductTypeMaster",
			//},
			{
				url: API_URL.Master.GetUserConfigurations,
				key: "userConfiguration",
				params: { sectionId: 17 },
			},
		])
			.then((tenantSummaryData) => {
				this._asyncRequest = null;
				if (tenantSummaryData.tenantSummaryData.success == true) {
					this.getRequiredDataWithoutLoader(selectedMarketId);
					this.setState({
						tenantSummaryData:
							camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || [],
						responseBuildingData: _.cloneDeep(
							camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || []
						),

						configuration:
							camelcaseKeys(
								tenantSummaryData.tenantSummaryData.configuration
							) || [],
						//buildingMasterData:
						//	camelcaseKeys(tenantSummaryData.buildingMasterData.data) || [],
						//companyMasterDetails:
						//	camelcaseKeys(tenantSummaryData.companyMasterDetails.data) || [],
						//getSubMarketMaster:
						//	camelcaseKeys(tenantSummaryData.getSubMarketMaster.data) || [],
						//subMarketData:
						//	camelcaseKeys(tenantSummaryData.getSubMarketMaster.data) || [],
						//getBuildingMaster:
						//	camelcaseKeys(tenantSummaryData.getBuildingMaster.data) || [],
						//getMarketMaster:
						//	camelcaseKeys(tenantSummaryData.getMarketMaster.data) || [],
						//getProductTypeMaster:
						//	camelcaseKeys(tenantSummaryData.getProductTypeMaster.data) || [],
						userConfiguration: camelcaseKeys(
							tenantSummaryData.userConfiguration.data != null
								? tenantSummaryData.userConfiguration.data
								: JSON.parse(
									window.sessionStorage.getItem("userConfigurations")
								)
						),
						listOfColumns: tenantSummaryData.userConfiguration.data.filter(
							(m) => {
								if (m.isVisible == true) return m.key;
							}
						),
						rowsPerPage: tenantSummaryData.userConfiguration.data[2].rowPerPage,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						tenantSummaryData.tenantSummaryData.message,
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	renderActionColumn = (cellInfo) => {
		return (
			<>
				<Tooltip title="View">
					<a
						className="default-text"
						aria-label={"refreshData"}
						size="small"
						onClick={() => {
							this.setState({
								isInAddMode: false,
							});
							this.onRowClick(cellInfo);
						}}
					>
						<span className="default-text span-link">
							{cellInfo.companyName ? cellInfo.companyName : '---'}
						</span>
					</a>
				</Tooltip>
			</>
		);
	};
	onRowClick = (data) => {
		this.setState({
			detailView: true,
			selectedEditRowId: data.compId,
			ViewTitle: "Edit",
			actionType: "Edit",
		});
	};
	// onFilterChange = (selectedData) => {
	//   const { tenantSummaryData } = this.state;
	//     const filteredData = applyFilterOnData([...tenantSummaryData], selectedData);
	//     this.setState({ tenantSummaryData: filteredData, selectedData: selectedData},
	//       () => { this.forceUpdate(); });
	//  }
	onFilterChange = (selectedData) => {
		// if (selectedData.length > 0) {
		//   let data = [];

		//   // selectedData.forEach((ele) =>{
		//   //   let selData=[];
		//   //  if(ele.key=='address' && ele.selected=='')
		//   //  {
		//   var selectedItem = selectedData; // make a separate copy of the array
		//   var index = selectedItem.indexOf(
		//     selectedData.find((m) => m.selected == "")
		//   );
		//   if (index !== -1) {
		//     selectedItem.splice(index, 1);
		//     data.push(selectedItem);
		//   }

		//   //  }
		//   // });

		//   this.setState({
		//     filterSelectedData: data,
		//   });
		// }

		if (selectedData.length > 0) {
			let data = [];
			data.push(selectedData);

			this.setState({
				filterSelectedData: data,
			});
		}

		if (selectedData.length > 0) {
			//selectedData.forEach((ele) => {
			//	if (ele && ele.selected && ele.selected.length > 0) {
			//		ele.selected.forEach((element) => {
			//			if (ele.key == "market") {
			//				var selectedSubMarket = this.state.subMarketData.filter(
			//					(x) => x.marketId == element.marketId
			//				);
			//				this.setState({
			//					getSubMarketMaster: selectedSubMarket,
			//				});
			//			}
			//		})
			//	}
			//	//if (ele.key == "address" && ele.selected == "") {
			//	//	selectedData = null;
			//	//	this.filterData(this.state.responseBuildingData, selectedData);
			//	//}
			//});
			this.filterData(this.state.responseBuildingData, selectedData);
		} else {
			this.filterData(this.state.responseBuildingData, selectedData);
		}
	};

	filterData(tenantSummaryData, selectedData) {
		const filteredData = applyFilterOnData(
			[...tenantSummaryData],
			selectedData
		);
		this.setState(
			{
				tenantSummaryData: filteredData,
				selectedData: selectedData,
				reloadTable: !this.state.reloadTable,
			},
			() => {
				this.forceUpdate();
			}
		);
	}
	onBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: undefined });
	};
	enableFilter = () => {
		let openFilter = this.state.openFilter;
		this.setState({ openFilter: !openFilter });
	};

	handlePrintClick = () => {
		this.setState(
			{
				printPage: true,
			},
			window.print()
		);
	};

	exportPDF = () => {
		const headers = [
			[
				"Tenant Name",
				"Property Name",
				"Rsf",
				"Market",
				"Sub Market",
				"LCD",
				"Type",
				"Lx Date",
				"Rate At LCD",
			],
		];

		const data = this.state.tenantSummaryData.map((elt) => [
			elt.companyName,
			elt.buildingName,
			elt.rsf,
			elt.market,
			elt.subMarket,
			elt.lcd,
			elt.productType,
			elt.lxDte,
			elt.rateAtLcd,
		]);
		generatePDFFromHtml(
			"Building Detail",
			"#gridTable",
			"Building",
			headers,
			data
		);
	};
	handleChange = (event) => {
		this.setState({ searchInput: event.target.value }, () => {
			this.globalSearch();
		});
	};
	globalSearch = () => {
		let { searchInput, tenantSummaryData } = this.state;
		let searchData = this.state.responseBuildingData;
		let filteredData = searchData.filter((value) => {
			//   if (
			//     value.buildingName != null &&
			//     value.companyName != null &&
			//     value.market != null &&
			//     value.subMarket != null &&
			//     value.rsf != null &&
			//     value.lcd != null //&&
			//     //value.lxDte != null &&
			//     //value.rateAtLcd != null
			//   ) {
			return (
				(this.handleCheck("companyName") && value.companyName
					? value.companyName.toLowerCase().includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("buildingName") && value.buildingName
					? value.buildingName.toLowerCase().includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("rsf") && value.rsf
					? value.rsf.toString().toLowerCase().includes(searchInput)
					: false) ||
				(this.handleCheck("market") && value.market
					? value.market.toLowerCase().includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("subMarket") && value.subMarket
					? value.subMarket.toLowerCase().includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("lcd") && value.lcd
					? value.lcd.toString().toLowerCase().includes(searchInput)
					: false) ||
				(this.handleCheck("productType") && value.productType
					? value.productType.toLowerCase().includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("lxDte") && value.lxDte
					? value.lxDte
						.toString()
						.toLowerCase()
						.includes(searchInput.toLowerCase())
					: false) ||
				(this.handleCheck("rateAtLcd") && value.rateAtLcd
					? value.rateAtLcd.toString().toLowerCase().includes(searchInput)
					: false) ||
				(this.handleCheck("compType") && value.compType
					? value.compType.toString().toLowerCase().includes(searchInput)
					: false) ||
				(this.handleCheck("notes") && value.notes
					? value.notes.toString().toLowerCase().includes(searchInput)
					: false)
			);
			//   }
		});
		if (searchInput.length == 0)
			this.setState({ tenantSummaryData: this.state.responseBuildingData });
		else this.setState({ tenantSummaryData: filteredData });
	};

	// globalSearch = () => {
	//   let { searchInput, tenantSummaryData } = this.state;
	//   let searchData = this.state.responseBuildingData;
	//   let filteredData = searchData.filter((value) => {
	//     // if (
	//     //   value.buildingName != null &&
	//     //   value.companyName != null &&
	//     //   value.market != null &&
	//     //   value.subMarket != null &&
	//     //   value.rsf != null &&
	//     //   value.lcd != null &&
	//     //   value.lxDte != null &&
	//     //   value.rateAtLcd != null
	//     // ) {
	//       return( value.lxDte != null
	//         ? value.lxDte
	//             .toString()
	//             .toLowerCase()
	//             .includes(searchInput.toLowerCase())
	//         : value.lxDte ||
	//         value.lcd != null ?  value.lcd.includes(searchInput) : value.lcd ||
	//           value.buildingName != null
	//         ? value.buildingName.toLowerCase().includes(searchInput.toLowerCase())
	//         : value.buildingName||
	//           value.companyName != null
	//         ? value.companyName.toLowerCase().includes(searchInput.toLowerCase())
	//         : value.companyName || value.market != null
	//         ? value.market.toLowerCase().includes(searchInput.toLowerCase())
	//         : value.market ||
	//         value.rateAtLcd != null ? value.rateAtLcd.includes(searchInput) : value.rateAtLcd ||
	//           value.subMarket != null
	//         ? value.subMarket.toLowerCase().includes(searchInput.toLowerCase())
	//         : value.subMarket ||
	//         value.rsf != null ?   value.rsf.includes(searchInput) :value.rsf ||
	//           value.productType != null
	//         ? value.productType.toLowerCase().includes(searchInput.toLowerCase())
	//         : value.productType
	//       );
	//    // }
	//   });
	//   if (searchInput.length == 0)
	//     this.setState({ tenantSummaryData: this.state.responseBuildingData });
	//   else this.setState({ tenantSummaryData: filteredData });
	// };

	onAddNewClick = () => {
		this.setState({
			isInAddMode: true,
			detailView: true,
			ViewTitle: "Add",
			actionType: "Add",
		});
	};

	reloadData = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.IndustrialTenants.tenantsSummaryData,
				key: "tenantSummaryData",
				params: { marketId: this.state.selectedMarketId },
			},
		])
			.then((tenantSummaryData) => {
				this._asyncRequest = null;
				if (tenantSummaryData.tenantSummaryData.success == true) {
					this.setState({
						tenantSummaryData:
							camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || [],
						responseBuildingData: _.cloneDeep(
							camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || []
						),
						configuration:
							camelcaseKeys(
								tenantSummaryData.tenantSummaryData.configuration
							) || [],

						detailView: false,
						selectedEditRowId: undefined,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						tenantSummaryData.tenantSummaryData.message,
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	downloadReport = () => {    
		downloadExcelReportByAPI(
		  API_URL.IndustrialTenants.tenantsExport,
				"TenantExport"
			);
	  };

	resetFilters = () => {
		this.setState({
			filterSelectedData: null,
			//getSubMarketMaster:[],
		});
	};
	handleClickOpen = () => {
		this.setState({
			openSlidingPanel: true,
		});
	};
	handleClose = () => {
		this.setState({
			openSlidingPanel: false,
		});
	};
	handleCheck(val) {
		return this.state.listOfColumns.some((item) => item.key === val);
	}
	render() {
		const {
			tenantSummaryData,
			buildingMasterData,
			companyMasterDetails,
			getSubMarketMaster,
			getBuildingMaster,
			getMarketMaster,
			getProductTypeMaster,
			openFilter,
			searchInput,
			userConfiguration,
			openSlidingPanel,
			listOfColumns,
		} = this.state;
		let pageSize = 0;
		let userConfig = userConfiguration.filter((m) => {
			if (m.sectionId == 17 && m.isVisible == true) {
				if (m.dataFormat == 'MultiText') m.minTag = 1;
				if (m.key == "companyName") {
					m.renderComponent = "renderActionColumn";
					// this.setState({
					pageSize = m.rowPerPage;
					//reloadTable:!this.state.reloadTable,
					//});
					return m;
				}
				return m;
			}
			//this.refs.gridTable.setRowsPerPage(pageSize);
		});
		userConfig.sort((a, b) =>
			a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
		);
		const dbColumnList = userConfiguration.filter((m) => {
			if (m.sectionId == 17 && m.attributeSortOrder != -1 && !m.renderComponent)
				return m; //
		});
		dbColumnList.sort((a, b) =>
			a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
		);

		tenantSummaryData.forEach((item, index) => {
			item.serial = index + 1;
		});
		return (
			<>
				{this.state.detailView ? (
					<>
						<EditIndTenants
							selectedTenantsId={this.state.selectedEditRowId}
							onBackClick={this.onBackClick}
							ViewTitle={this.state.ViewTitle}
							actionType={this.state.actionType}
							isInAddMode={this.state.isInAddMode}
							reloadData={this.reloadData}
							companyMasterDetails={this.state.companyMasterDetails}
							getBuildingMaster={this.state.buildingMasterData}
						/>
					</>
				) : (
					<>
						<div className="row no-print">
							<div className="col-md-3 col-lg-3">
								<PageHeader title="Tenants"></PageHeader>
								{/* <Button
                  aria-label={"addnewrow"}
                  className="action-button"
                  size="small"
                  onClick={() => {
                    this.setState({
                      isInAddMode: true,
                      selectedEditRowId:-1,
                    });
                    this.onAddNewClick();
                  }}
                >
                  <span className="tool-icon-text">Add New Building</span>
                </Button> */}
							</div>
							<div>
								{dbColumnList.length > 0 && (
									<SettingScreenDialog
										filterSetting={dbColumnList}
										onApplySetting={this.onSettingChange}
										openSlidingPanel={openSlidingPanel}
										handleClose={this.handleClose}
									/>
								)}
							</div>
							<div className="col-md-9 col-lg-9">
								<div className="row">
									<div className="col-md-6 col-lg-6">
										<SearchBar
											size="large"
											name="searchInput"
											value={searchInput || ""}
											onChange={this.handleChange}
											label="Search"
											placeholder="Search Company,address,market,etc."
										></SearchBar>
									</div>
									<div className="col-md-3 col-lg-3"></div>		
									<div className="col-md-3 col-lg-3">
										<ReportButtons
											resetPage={[]}
											openFilter={this.state.openFilter}
											enableFilter={this.enableFilter}
											handleSubmit={[]}
											handleClickOpen={this.handleClickOpen}
											handlePrintClick={this.handlePrintClick}
											exportPDF={this.exportPDF}
											data={tenantSummaryData}
											columnWidths={columnWidths}
											uploadColumns={uploadColumns}
											fileName={"Report.xlsx"}
											downloadExcelReport={this.downloadReport}
											enableFilterButton={this.state.enableFilterButton}
											ParentSection={SECTIONS.Industrial_Tenant}
											ChildSectionForDownload={SECTIONS.Industrial_Tenant_Download}
											ChildSectionForPrint={SECTIONS.Industrial_Tenant_Print}
											ChildSectionForSettings={SECTIONS.Industrial_Tenant_Settings}
										/>
									</div>
								</div>
							</div>
						</div>
						{/* <Divider className="divider-bg" orientation="vertical" flexItem /> */}

						{tenantSummaryData && listOfColumns && (
							<div className="row" style={{ marginTop: "0px" }}>
								<div className="col-sm-12 col-lg-12">
									<PageFilter
										resetFilters={this.resetFilters}
										autoFilter={true}
										openAppliedFilterPane={true}
										filterSelectedData={this.state.filterSelectedData}
										filterApplied={true}
										filterAlwaysVisible={openFilter}
										openFilter={true}
										onFilterChanged={this.onFilterChange}
										filterConfig={[
											{
												multiple: true,
												type: controlType.DropDown,
												minTag: 1,
												source: companyMasterDetails,
												key: "companyName",
												placeholder: "",
												title: "Tenant Name",
												isVisible: this.handleCheck("companyName"),

											},
											{
												multiple: true,
												type: controlType.DropDown,
												minTag: 1,
												source: [...new Map(buildingMasterData.map(item => [item["buildingName"], item])).values()],
												key: "buildingName",
												placeholder: "",
												title: "Property Name",
												isVisible: this.handleCheck("buildingName"),

											},
											// {
											// 	multiple: true,
											// 	type: controlType.DropDown,
											// 	source: getMarketMaster,
											// 	key: "market",
											// 	placeholder: "",
											// 	title: "Market",
											// 	isVisible: this.handleCheck("market"),

											// },
											{
												multiple: true,
												type: controlType.DropDown,
												source: [...new Map(getSubMarketMaster.map(item => [item["subMarket"], item])).values()],
												key: "subMarket",
												placeholder: "",
												title: "Sub Market",
												isVisible: this.handleCheck("subMarket"),

											},

											{
												multiple: false,
												type: controlType.DatePicker,
												key: "lcd",
												placeholder: "",
												title: "LCD",
												isVisible: this.handleCheck("lcd"),

											},
											{
												multiple: true,
												type: controlType.DropDown,
												source: getProductTypeMaster,
												key: "productType",
												placeholder: "Type",
												title: "Type",
												isVisible: this.handleCheck("productType"),

											},
										]}
										defaultSelectedData={[]}
										selectedData={[]}
										gridColumn={4}
									></PageFilter>
								</div>
							</div>
						)}

						{tenantSummaryData && userConfig.length > 0 && (
							<div className="row" style={{ marginTop: "0px" }}>
								<div className="col-sm-12 col-lg-12">
									<GridTable
										validationMessage={[]}
										rowCount={this.state.rowsPerPage}
										config={userConfig}
										columns={UpdateColumnWithConfiguration(
											userConfig,
											this.state.configuration,
											[]
										)}
										reload={this.state.reloadTable}
										data={tenantSummaryData}
										renderActionColumn={this.renderActionColumn}
										AddNewRecord={{
											action: this.onAddNewClick,
											title: "Add Tenant",
											visible: IsSectionVisible(SECTIONS.Industrial_Tenant,SECTIONS.Industrial_Tenant_Listing, SECTIONS.ADD_EDIT),

										}}
									></GridTable>
								</div>
							</div>
						)}
					</>
				)}
			</>
		);
	}
}

import * as actionType from "./actionTypes";

export const setLoaderVisibility = (isloading) => dispatch => {
	dispatch({
		type: actionType.SET_VISIBILITY,
		payload: isloading
	});
};

export const setDownloaderVisibility = isloading => dispatch => {
	dispatch({
		type: actionType.SET_DOWNLOADING_VISIBILITY,
		payload: isloading
	});
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  hidePadding: {
    "& .MuiOutlinedInput-input": {
      //paddingRight: "14px",
      paddingLeft: "​14px",
      paddingTop: "0px",
      paddingBottom: "04px",
    }
  }
}));

export default function SimpleSelect() {
  const classes = useStyles();
  const [lastModified, setLastModified] = React.useState('');

  const handleChange = (event) => {
    setLastModified(event.target.value);
  };

  return (
    <div>
      
      <FormControl variant="outlined" className={classes.hidePadding}>
        <InputLabel id="demo-simple-select-filled-label"></InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={10}
          onChange={handleChange}
          style={{fontSize: '14px',paddingTop:'4px'}}
        >
           <MenuItem value={10}>10/12/2021, Danielle Rotchild</MenuItem>
          <MenuItem value={20}>11/12/2021, Danielle Rotchild</MenuItem>
          <MenuItem value={30}>12/12/2021, Danielle Rotchild</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
import React from "react";
import "./style.less";
import cx from "classnames";
import { DarkTooltip } from "../material-tooltip";

export default function ButtonIcon({
	children,
	label = "Default",
	onClick,
	isSelected = false,
	disabled = false,
	isUpperCase,
	styleClass = "IconButton",
	dataTip = ""
}) {
	return (
		<DarkTooltip title={dataTip} arrow>
			<button
				className={cx(
					styleClass,
					isSelected && "selected",
					isUpperCase && "uppercase",
					disabled && "disabled"
				)}
				onClick={onClick}
			>
				{children}
			</button>
		</DarkTooltip>
	);
}

import React from "react";
import "../../../public/css/style.less";
import {
	apiBatchRequest,
	apiBatchRequestWithoutLoader,
	apiPost,
	apiPostNoLoader,
	apiRequest,
	apiRequestNoLoadder,
	CancelRequest,
	getSpsAPiRequest,
	RenewCancelToken,
	spsApiPost,
	uploadSpsAPiRequest,
	uploadSpsMetadataAPiRequest,
} from "../../../utils/api_service";
import ReportButtons from "../../../utils/ReportButtons";
import { API_URL,getCurrentEnvironment } from "../../../constants/api";
import PageFilter from "../../common/PageFilter";
import { controlType } from "../../../constants/common";
import PageHeader from "../../common/PageHeader";
import {  Tooltip,
	Button,
	Dialog,
	AppBar,
	Toolbar,
	Typography,
	IconButton,
	Box,
	TextField,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	FormControl,
	Radio,
	TableHead,
	Table,
	TableBody,
	TableRow,
	TableCell, } from "@material-ui/core";
import GridTable from "../../common/Table/Table";
import * as XLSX from "xlsx";
import {
	applyFilterOnData,
	checkFileValidation,
	checkRegValidation,
	downloadExcelFile,
	generatePDFFromHtml,
	getSectionAttributeValueByKey,
	IsSectionVisible,
	renameKey,
	UpdateColumnWithConfiguration,
} from "../../../utils/helper";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../../common/AutoSearch/SearchBar";
import EditIndLeaseComps from "./EditIndLeaseComps";
import SettingScreenDialog from "../../common/SettingScreenDialog/SettingScreenDialog";
import { ShowToast, toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import { downloadExcelReportByAPI,
	generateString,
	orderKey,
	setDownloadingStatus, } from "../../../utils/generic";
import {  DateFormat, Operators, SECTIONS } from "../../../constants/appConstants";
import { IoMdCloseCircleOutline } from "react-icons/io";
import DownloadTemplate from "../../common/ExportImport/DownloadTemplate";
import moment from "moment-mini";
import { getSPSTokenData, setSPSToken } from "../../../constants/constants";
import { getSharePointUrl } from "../../../constants/sharepointApi";
import { Autocomplete } from "@material-ui/lab";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import UnmappedComps from "./UnmappedComps";
import { PaginatedGridTable } from "../../common/Table/PaginatedTable";
const uploadColumns = [
  {
    key: "companyName",
    name: "Tenant Name",
  },
  {
    key: "buildingName",
    name: "Property Name",
  },
  {
    key: "rsf",
    name: "RSF",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "subMarket",
    name: "Sub Market",
  },
  {
    key: "lcd",
    name: "LCD",
  },
  {
    key: "industryName",
    name: "Industry",
  },
  {
    name: "Comp Type",
    key: "compType",
  },
  {
    key: "rate",
    name: "Rate",
  },
  {
    key: "buildingClass",
    name: "Class",
  },
  {
    key: "leaseType",
    name: "Lease Type",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];

export default class IndLeaseComps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.childRef = React.createRef();
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      leaseCompsSummaryData: [],
      configuration: [],
      selectedEditRowId: undefined,
      detailView: false,
      ViewTitle: undefined,
      actionType: undefined,
      getSubMarketMaster: [],
      getProductTypeMaster: [],
      getMarketMaster: [],
      responseleaseCompsSummaryData: [],
      openFilter: false,
      searchInput: "",
      filteredData: [],
      lstActiveFilters: [],
      subMarketData: [],
      subMarketFilteredData: [],
      isInAddMode: false,
      filterSelectedData: [],
    //  getAllCompType: [],
      getAllLeaseType: [],
    //  getLeaseCompDetail: [],
      getBuildingMaster: [],
      companyMasterDetails: [],
      userConfiguration: [],
      openSlidingPanel: false,
      dbColumnList: [],
      selectedConfigData: [],
      rowsPerPage: 20,
      listOfColumns: [],
      enableFilterButton: false,
      listOfCompColumns: [],
      getImportfileData: [],
      listOfDbColumn: [],
      openDialog: false,
      listOfHeader: [],
      isUpload: false,
      enableFilterButton: false,
      lstRequiredField: [],
      spsData: [],
      spListkey: null,
      downloadFileUrl: null,
      selectedFile: null,
      selectedFileName: null,
      selectedFileSize: null,
      breakCondition: false,
      uKey: null,
      uFile: null,
      selectedValue: null,
      selectedValueId: -1,
      validFormattedData: [],
      invalidFormattedData: [],
      importedFileData: [],
      invalidRecordCount: 0,
      totalRecordCount: 0,
      value: "Stream",
      templateRecordNotPresent: [],
      userFileColumnNotPresentInTemplate: [],
      openColumnMappingDialog: false,
      matchedColumns: [],
      unMatchedColumns: [],
      fileData: [],
      openUserMappedConfirmDialog: false,
      confirmUserForNotMatchedData: false,
      notMatchedByUser: [],
      userFileData: [],
      getAllCostarSubMarket:[],
      getAllCostarBuildingClass:[],
      pageNumber:0,
      pageSize:10,
      companyId:"",
      closeDate:"",
      lcd:"",
      lxdte:"",
      rsf:"",
      propertyNameAddrCostar:[]
    };
  }

  
  handleRadioChange = (event) => {
    this.setState({ value: event.target.value });
  };
  onRestoreToDefault = (config) => {
    let param = config;
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: { restoreDefault: 1 },
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingRestored, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(
            commonLabels.settingNotRestored,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };

  onSettingChange = (config, pageSize) => {
    let param = [];
    if (config.length > 0) {
      param = config.filter((m) => {
        if (pageSize != 0) m.rowPerPage = pageSize;
        return m;
      });
    } else {
      param = this.state.userConfiguration.filter((m) => {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (
          m.dataFormat == "Number" ||
          m.dataFormat == "MoneyNumber" ||
          m.dataFormat == "Decimal"
        ) {
          m.headcellClass = "headcell-number-text";
        }
        if (
          m.sectionId == 19 &&
          m.isVisible == true &&
          m.key == "companyName"
        ) {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          if (pageSize != 0) m.rowPerPage = pageSize;

          return m;
        }
      });
    }
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: {},
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingUpdated, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(commonLabels.settingNotUpdated, toastType.error, 5000, 500);
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };


  componentDidMount() {
    RenewCancelToken();
    this.getRequiredData(this.state.selectedMarketId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
	  let selMarket = null;
      let selMarketId = -1;
      this.setState({ selectedMarketId: nextProps.globalMarketId,
		selectedValue: selMarket,
        selectedValueId: selMarketId,
	});
    }
  }

  componentWillUnmount() {
    CancelRequest();
  }

  getUserConfigurationBySectionId = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 19 },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((userConfiguration) => {
        this._asyncRequest = null;
        if (userConfiguration.userConfiguration.success == true) {
          this.setState({
            userConfiguration:
              camelcaseKeys(userConfiguration.userConfiguration.data).sort(
                (a, b) => (a.attributeSortOrder > b.attributeSortOrder ? 1 : -1)
              ) || [],
            rowsPerPage: userConfiguration.userConfiguration.data[2].rowPerPage,
            //reloadRequired:true,
            reloadTable: !this.state.reloadTable,
            listOfColumns: userConfiguration.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
          });
          this.forceUpdate();
          //this.child.setRowsPerPage(this.state.rowsPerPage);
        //  this.childRef.current.handleChangeRowsPerPage(this.state.rowsPerPage);
        } else {
          ShowToast(
            userConfiguration.userConfiguration.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getRequiredDataWithoutLoader = (selectedMarketId) => {
    this._asyncRequest = apiBatchRequestWithoutLoader([
    //  {
    //    url: API_URL.Master.getAllCompType,
    //    key: "getAllCompType",
    //    fromCache: true,
    //    duration: 60,
    //  },
      {
        url: API_URL.Master.getAllLeaseType,
        key: "getAllLeaseType",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
      },
    //  {
    //    url: API_URL.IndustrialLeaseComps.getLeaseCompDetail,
    //    key: "getLeaseCompDetail",
    //  },
    //  {
    //    url: API_URL.Master.getMarketMaster,
    //    key: "getMarketMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //    fromCache: true,
    //    duration: 60,
    //  },
    //  {
    //    url: API_URL.Master.getSubMarketMaster,
    //    key: "getSubMarketMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //    fromCache: true,
    //    duration: 60,
    //  },
	{
		    url: API_URL.Master.getMarketSubmarket,
		    key: "getMarketSubmarket",
		    params: { businessLineId: 2, marketId: selectedMarketId },
		    //fromCache: true,
		    //duration: 60,
		  },
    //  {
    //    url: API_URL.Master.industrialBuildingMasterData,
    //    key: "getBuildingMaster",
    //    params: { businessLineId: 2, marketId: selectedMarketId },
    //    fromCache: true,
    //    duration: 60,
    //  },
      // {
      //   url: API_URL.Master.getAllDirectSubLease,
      //   key: "getAllDirectSubLease",
      //   params: { businessLineId: 2 },
      //   fromCache: true,
      // duration: 60
      // },
      // {
      //   url: API_URL.Master.getAllCostarBuildingClass,
      //   key: "getAllCostarBuildingClass",
      //   params: { businessLineId: 2 },
      //   fromCache: true,
      // duration: 60
      // },
      // {
      //   url: API_URL.Master.getAllCostarSubMarket,
      //   key: "getAllCostarSubMarket",
      //   params: { businessLineId: 2},
      //   fromCache: true,
      // duration: 60
      // },
    ])
      .then((leaseCompsSummaryData) => {
        this._asyncRequest = null;
        if (leaseCompsSummaryData.getMarketSubmarket.submarket.success == true) {
          this.setState({
            getMarketMaster:
              camelcaseKeys(leaseCompsSummaryData.getMarketSubmarket.market.data) || [],
            getSubMarketMaster:
              camelcaseKeys(leaseCompsSummaryData.getMarketSubmarket.submarket.data) ||
              [],
            subMarketData:
              camelcaseKeys(leaseCompsSummaryData.getMarketSubmarket.submarket.data) ||
              [],
            //getAllCompType:
            //  camelcaseKeys(leaseCompsSummaryData.getAllCompType.data) || [],
            getAllLeaseType:
              camelcaseKeys(leaseCompsSummaryData.getAllLeaseType.data) || [],
            //getLeaseCompDetail:
            //  camelcaseKeys(leaseCompsSummaryData.getLeaseCompDetail.data) ||
            //  [],
			selectedValueId: !this.props.globalMarketId.includes("|")
              ? this.props.globalMarketId
              : -1,
           
						   
            enableFilterButton: true,
          });
        } else {
          ShowToast(
            leaseCompsSummaryData.getMarketSubmarket.submarket.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getRequiredData = (selectedMarketId) => {
	if (selectedMarketId != "") {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialLeaseComps.leaseCompsSummaryData,
        key: "leaseCompsSummaryData",
        params: { marketId: selectedMarketId,
          companyId: this.state.companyId,
          closeDate: this.state.closeDate,
          lcd: this.state.lcd, 
          lxdte: this.state.lxdte,
          rsf: this.state.rsf,
          pageNumber: this.state.pageNumber + 1,
          pageSize: this.state.rowsPerPage },
      },
   
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 19 },
        fromCache: true,
        duration: 60,
      },
      {
        url: API_URL.Master.getBuildingNameAddressCostarMaster,
        key: "propertyNameAddrCostar",
        params: { businessLineId :2,
          searchText:null, marketId: this.state.selectedMarketId},		
      },
    ])
      .then((leaseCompsSummaryData) => {
        this._asyncRequest = null;
        if (leaseCompsSummaryData.leaseCompsSummaryData.success == true) {
          this.getRequiredDataWithoutLoader(selectedMarketId);
          this.setState({
            leaseCompsSummaryData:
              camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
              [],
            responseleaseCompsSummaryData: _.cloneDeep(
              camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
                []
            ),
           
            configuration:
              camelcaseKeys(
                leaseCompsSummaryData.leaseCompsSummaryData.configuration
              ) || [],
           
            userConfiguration: camelcaseKeys(
              leaseCompsSummaryData.userConfiguration.data != null
                ? leaseCompsSummaryData.userConfiguration.data
                : JSON.parse(
                    window.sessionStorage.getItem("userConfigurations")
                  )
            ),
            listOfColumns: leaseCompsSummaryData.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
            rowsPerPage:
              leaseCompsSummaryData.userConfiguration.data[2].rowPerPage,
              propertyNameAddrCostar: camelcaseKeys(leaseCompsSummaryData.propertyNameAddrCostar.data) || [],
          });
          //this.forceUpdate();
        } else {
          ShowToast(
            leaseCompsSummaryData.leaseCompsSummaryData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
	}
  };


  
  reloadGridData = (selectedMarketId) => {
    
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.IndustrialLeaseComps.leaseCompsSummaryData,
          key: "leaseCompsSummaryData",
          params: { marketId: selectedMarketId,
            companyId: this.state.companyId,
            closeDate: this.state.closeDate,
            lcd: this.state.lcd, 
            lxdte: this.state.lxdte,
            rsf: this.state.rsf,
            pageNumber: this.state.pageNumber + 1,
            pageSize: this.state.rowsPerPage },
        },
       
      ])
        .then((leaseCompsSummaryData) => {
          this._asyncRequest = null;
          if (leaseCompsSummaryData.leaseCompsSummaryData.success == true) {
            
            this.setState(
              {
                leaseCompsSummaryData:
                  camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
                  [],
                responseleaseCompsSummaryData: _.cloneDeep(
                  camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
                    []
                ),
              },
              () => {
                this.forceUpdate();
              }
            );
            
          } else {
            ShowToast(
              buildingSummaryData.buildingSummaryData.message,
              toastType.error,
              5000,
              500
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };


  onFilterChange = (selectedData) => {
    
    if (selectedData.length > 0) {
     
      let closeDate = "";
      let lcd = "";
      let lxdte = "";
      let rsf = "";
      for (var i = 0; i < selectedData.length; i++) 
      { 
        if(selectedData[i].key === "closedate")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            closeDate += selectedData[i].selected[j].closedate + '|';
          }          
        }
        if(selectedData[i].key === "lcd")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            lcd += selectedData[i].selected[j].lcd + '|';
          }          
        }
        if(selectedData[i].key === "lxDte")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            lxdte += selectedData[i].selected[j].lxDte + '|';
          }          
        }
        if(selectedData[i].key === "rsf")
        {            
          for(var j = 0; j < selectedData[i].selected.length; j++)
          {
            rsf += selectedData[i].selected[j].rsf + '|';
          }          
        }
      }


      
      let data = [];
      data.push(selectedData);

      this.setState({
        filterSelectedData: data,        
        closeDate:closeDate,
        lcd:lcd,
        lxdte:lxdte,
        rsf:rsf,
        pageNumber:0
      }, () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    }    
    else {
      this.setState({
        filterSelectedData: null,
        companyId:"",
        closeDate:"",
        lcd:"",
        lxdte:"",
        rsf:"",
        pageNumber:0
      }, () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    }
  }; 

  
  onSearchInputChange = (filter, value) => {
    this.setState({
      loading:true
    });
    const params = {
      businessLineId: 2,
      searchText: value,
    };
    
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
           loading:false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
    //}
  };

  resetAppliedFilter = () => {
    document.getElementById("tags-standard").value = '';
    this.setState({
      searchInput:"",
      filterSelectedData: null,
      companyId:"",
      closeDate:"",
      lcd:"",
      lxdte:"",
      rsf:"",
      pageNumber:0      
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });

  }

  handleSearchChange = (e, value, name) => {
   
    let companyId=value != null && value != undefined ? value.companyId :null
    let companyName=value != null && value != undefined ? value.companyName :null
    this.setState({
      searchInput: companyName
    }, () => {
      this.handleChange(e,companyId,1);
    });
    
    
  };

  handleChange = (event,companyId,bit) => { 
     
    this.setState({
      filterSelectedData: null,
      companyId:companyId,
      closeDate:"",
      lcd:"",
      lxdte:"",
      rsf:"",
      pageNumber:0
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });

  };

  filterData(leaseCompsSummaryData, selectedData) {
    const filteredData = applyFilterOnData(
      [...leaseCompsSummaryData],
      selectedData
    );
    this.setState(
      {
        leaseCompsSummaryData: filteredData,
        selectedData: selectedData,
        reloadTable: !this.state.reloadTable,
      }
      //() => {
      //	this.forceUpdate();
      //}
    );
  }
  exportPDF = () => {
   
    downloadExcelReportByAPI(
      API_URL.SSRSExport.IndustrialLeaseComp,
      "LeaseCompsExport.pdf",
      this.state.selectedData,
      this.state.selectedMarketId,
      "application/pdf",
      "industrialleasecomp"
    );
  };

  globalSearch = () => {
    let { searchInput, leaseCompsSummaryData } = this.state;
    let searchData = this.state.responseleaseCompsSummaryData;
    let filteredData = searchData.filter((value) => {
     
      
        return (
          (this.handleCheck("companyName") && value.companyName
            ? value.companyName.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("buildingName") && value.buildingName
            ? value.buildingName.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("rsf") && value.rsf
            ? value.rsf
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("market") && value.market
            ? value.market.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("subMarket") && value.subMarket
            ? value.subMarket.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("lcd") && value.lcd
            ? value.lcd
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("industryName") && value.industryName
            ? value.industryName.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("compType") && value.compType
            ? value.compType.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("rate") && value.rate
            ? value.rate
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("buildingClass") && value.buildingClass
            ? value.buildingClass
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("leaseType") && value.leaseType
            ? value.leaseType.toLowerCase().includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("generation") && value.generation
            ? value.generation
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("brokerFirmName") && value.brokerFirmName
            ? value.brokerFirmName
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("buildingLeavingName") && value.buildingLeavingName
            ? value.buildingLeavingName
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("compCreatedBy") && value.compCreatedBy
            ? value.compCreatedBy
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("escalation") && value.escalation
            ? value.escalation
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("escalationMarker") && value.escalationMarker
            ? value.escalationMarker
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("free") && value.free
            ? value.free
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("monthsInSpace") && value.monthsInSpace
            ? value.monthsInSpace
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("nnnfs") && value.nnnfs
            ? value.nnnfs
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("notes") && value.notes
            ? value.notes
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("relocationReason") && value.relocationReason
            ? value.relocationReason
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("ti") && value.ti
            ? value.ti
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
          (this.handleCheck("term") && value.term
            ? value.term
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false) ||
            (this.handleCheck("coStarBuildingClass") && value.coStarBuildingClass
					  ? value.coStarBuildingClass.toLowerCase().includes(searchInput.toLowerCase())
					  : false) ||
          (this.handleCheck("costarPropId") && value.costarPropId
            ? value.costarPropId
                .toString()
                .toLowerCase()
                .includes(searchInput.toLowerCase())
            : false)
        );
      //   }
    });

    if (searchInput.length == 0)
      this.setState({
        leaseCompsSummaryData: this.state.responseleaseCompsSummaryData,
      });
    else this.setState({ leaseCompsSummaryData: filteredData });
  };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
              //this.setState({ detailView: true, selectedEditRowId: cellInfo.CompanyId });
            }}
          >
            <span className="default-text span-link">
              {cellInfo.companyName ? cellInfo.companyName : "---"}
            </span>
          </a>
        </Tooltip>
        {/* <span>  {cellInfo.CompanyName}</span> */}
      </>
    );
  };

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  onAddNewClick = () => {
    this.setState({
      isInAddMode: true,
      detailView: true,
      ViewTitle: "Add",
      actionType: "Add",
    });
  };
  onRowClick = (data) => {
    this.setState({
      detailView: true,
      selectedEditRowId: data.compId,
      ViewTitle: "Edit",
      actionType: "Edit",
    });
  };

  onBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: undefined });
  };

  // handleChange = (event) => {
  //   this.setState({ searchInput: event.target.value }, () => {
  //     this.globalSearch();
  //   });
  // };

  onInputChange = (filter, value) => {
    const params = {
      businessLineId: 2,
      searchText: value,
    };
	if(filter.key == 'buildingName'){
		apiRequestNoLoadder({
			url: API_URL.Master.getBuildingMaster,
			params,
		  })
			.then(({ data: getBuildingMaster }) => {
			  this.setState(
				{
				  getBuildingMaster: camelcaseKeys(getBuildingMaster) || [],
				},
				() => {
				  this.forceUpdate();
				}
			  );
			})
			.catch((reason) => {
			  this._asyncRequest = null;
			  ShowToast(
				commonLabels.somethingWrong,
				toastType.ERROR,
				5000,
				500,
				"Server error"
			  );
			});
	}
	else{
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
	}
  };

  reloadData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialLeaseComps.leaseCompsSummaryData,
        key: "leaseCompsSummaryData",
        params: {  marketId: this.state.selectedMarketId,
          companyId: this.state.companyId,
          closeDate: this.state.closeDate,
            lcd: this.state.lcd, 
            lxdte: this.state.lxdte,
            rsf: this.state.rsf,
          pageNumber: this.state.pageNumber + 1,
          pageSize: this.state.rowsPerPage  },
      },
      {
        url: API_URL.Master.getBuildingNameAddressCostarMaster,
        key: "propertyNameAddrCostar",
        params: { businessLineId :2,
          searchText:null, marketId: this.state.selectedMarketId},		
      },
      {
        url: API_URL.Master.companyMasterDetails,
        key: "companyMasterDetails",
        params: { businessLineId: 2 },
        // fromCache: true,
      	// duration: 60,
      	// clearCache:true
         },
   
    ])
      .then((leaseCompsSummaryData) => {
        this._asyncRequest = null;
        if (leaseCompsSummaryData.leaseCompsSummaryData.success == true) {
          this.setState({
            leaseCompsSummaryData:
              camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
              [],
            responseleaseCompsSummaryData: _.cloneDeep(
              camelcaseKeys(leaseCompsSummaryData.leaseCompsSummaryData.data) ||
                []
            ),
            configuration:
              camelcaseKeys(
                leaseCompsSummaryData.leaseCompsSummaryData.configuration
              ) || [],
              companyMasterDetails: camelcaseKeys(leaseCompsSummaryData.companyMasterDetails.data) || [],
            //  getBuildingMaster: camelcaseKeys(
            //    leaseCompsSummaryData.getBuildingMaster.data
            //  ) || [],  
            propertyNameAddrCostar: camelcaseKeys(leaseCompsSummaryData.propertyNameAddrCostar.data) || [], 
            detailView: false,
            selectedEditRowId: undefined,
            companyMasterDetails:[]
          });
          this.forceUpdate();
        } else {
          ShowToast(
            leaseCompsSummaryData.leaseCompsSummaryData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  downloadReport = () => {
   
    downloadExcelReportByAPI(
      API_URL.SSRSExport.IndustrialLeaseComp,
      "LeaseCompsExport.xls",
      this.state.selectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "industrialleasecomp"
    );
  };

  resetFilters = () => {
    this.setState({
      filterSelectedData: null,
      companyId:"",
      closeDate:"",
      lcd:"",
      lxdte:"",
      rsf:"",
      pageNumber:0
    }, () => {
      this.forceUpdate();
      this.reloadGridData(this.state.selectedMarketId);
    });
  };

  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };
  getPagesize = (count) => {
    this.setState(
      {
        rowsPerPage: count,
        reloadTable: !this.state.reloadTable,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  handleCheck(val) {
    return this.state.listOfColumns.some((item) => item.key === val);
    //return this.state.listOfColumns.indexOf(val) > -1
  }

  handleRequiredFieldCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.lstRequiredField.includes(val);
  }

  getDbColumn(val) {
    let dbColumn = this.state.getImportfileData.find((x) => x.uiColumn == val);
    let dbColumnName =
      dbColumn != undefined && dbColumn != null ? dbColumn.dbColumn : null;
    // dbColumnName == null ? this.setState({breakCondition : true}) : null;
    //return this.state.listOfHeader.some((item) => item === val);
    return dbColumnName;
  }
  onCancel = () => {
    this.setState({
      openDialog: false,
    });
  };
  enableDownload = () => {
    this.setState({
      openDialog: true,
    });
  };

  downloadTemplate = () => {
    //downloadExcelFile('src//components//Dashboard//PropertyOwnersExport.xlsx','MarketStats_ImportTemplate');
    downloadExcelFile(
      "public//ImportTemplate//IndLeaseCmps_ImportTemplate.xlsx",
      "IndLeaseCmps_ImportTemplate"
    );
  };
  importFile = () => {
    this.setState({ isUpload: true, openDialog: false });
  };
  onFileChange = (event) => {
    // Update the state
    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
      selectedFileSize: event.target.files[0].size,
    });
    this.getImportFileDetails();
  };
  handleHeaderCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.listOfHeader.includes(val);
  }

  processExcel = (data, uploadedFileName, uploadingKey) => {
    // this.setState({ upKey: uploadingKey, upfileName: uploadedFileName });
    this.setState({ breakCondition: false });
    let errorMessage = "";
    const workbook = XLSX.read(data, {
      type: "binary",
      cellDates: true,
      cellText: false,
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { defval: null, raw: false, dateNF: "mm/dd/yyyy hh:mm:ss" }
    );
    if (
      this.state.selectedValue == null ||
      this.state.selectedValue == undefined ||
      this.state.selectedValue == ""
    ) {
      this.setState({ breakCondition: true, selectedFile: null });
      errorMessage = "Please select market before import.";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    const formattedData = excelRows.filter((m) => {
      let commencementDate = m["Commencement Date"];
      let moveInDate = m["Move-in Date"];
      let expDate = m["Expiration Date"];
      let closeDate = m["Close Date"];
      let dateAdded = m["Date Added"];

      m["Commencement Date"] != null
        ? (m["Commencement Date"] = moment(m["Commencement Date"]).format(
            DateFormat
          ))
        : null;
      m["Move-in Date"] != null
        ? (m["Move-in Date"] = moment(m["Move-in Date"]).format(DateFormat))
        : null;
      m["Expiration Date"] != null
        ? (m["Expiration Date"] = moment(m["Expiration Date"]).format(
            DateFormat
          ))
        : null;
      m["Close Date"] != null
        ? (m["Close Date"] = moment(m["Close Date"]).format(DateFormat))
        : null;

      m["Date Added"] != null
        ? (m["Date Added"] = moment(m["Date Added"]).format(DateFormat))
        : null;
      m["Commencement Date"] == "Invalid date"
        ? (m["Commencement Date"] = commencementDate)
        : m["Commencement Date"];
      m["Move-in Date"] == "Invalid date"
        ? (m["Move-in Date"] = moveInDate)
        : m["Move-in Date"];
      m["Expiration Date"] == "Invalid date"
        ? (m["Expiration Date"] = expDate)
        : m["Expiration Date"];
      m["Close Date"] == "Invalid date"
        ? (m["Close Date"] = closeDate)
        : m["Close Date"];

        m["Date Added"] == "Invalid date"
        ? (m["Date Added"] = dateAdded)
        : m["Date Added"];

      return m;
    });
    let headers = [];
    if (formattedData.length == 0) {
      this.setState({ breakCondition: true, selectedFile: null });
      errorMessage =
        "The import file is empty. Please upload file with valid data";

      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return;
    }

    formattedData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value

        i == 0 && key != "Comments" ? headers.push(key) : null;
      });
    });
    if (this.state.breakCondition == false) {
      let market = formattedData[0]["CoStar Market"];
      this.updateHeaderColumn(headers.sort());
      this.setState({
        importedFileData: formattedData,
        uKey: uploadingKey,
        uFile: uploadedFileName,
        openConfirmDialog: true,
        importMarket: market,
      });
    }
  };

  processFileImportAfterConfirm = () => {
    let formattedData = this.state.importedFileData;
    let uploadingKey = this.state.uKey;
    let uploadedFileName = this.state.uFile;
    let ValidObj = [];
    let errorMessage = "";
    let isColumnMissing = false;

    let count = this.state.listOfCompColumns.length;
    let isColumnPresent = true;
    let columnNotFound = null;
    for (var i = 0; i < count; i++) {
      if (this.state.breakCondition != true && isColumnMissing != true)
        isColumnPresent = this.handleHeaderCheck(
          this.state.listOfCompColumns[i]
        );
      if (isColumnPresent == false) {
        //  this.setState({ breakCondition: true });
        isColumnMissing = true;
        columnNotFound =
          columnNotFound != null
            ? columnNotFound + "," + this.state.listOfCompColumns[i]
            : this.state.listOfCompColumns[i];

        errorMessage =
          "The import file does not contain columns specifiying the following data: [" +
          columnNotFound +
          "].Please re-import the file with this column included";
        // setDownloadingStatus(
        //   uploadingKey,
        //   uploadedFileName,
        //   "failed",
        //   errorMessage,
        //   "Validation Failure"
        // );
        //return;
      }
    }
    if (isColumnMissing == true) {
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
    }
    if (isColumnMissing != true) {
      formattedData.forEach((obj) => {
        if (this.state.breakCondition == false && isColumnMissing == false) {
          let message = null;
          let extraColumn = null;
          for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
            let oldColName = this.state.listOfHeader[i];
            let newColName = this.getDbColumn(this.state.listOfHeader[i]);
            if (newColName == null) {
              isColumnMissing = true;
              extraColumn =
                extraColumn != null
                  ? extraColumn + "," + this.state.listOfHeader[i]
                  : this.state.listOfHeader[i];
              errorMessage =
                "There is extra column in template [" +
                extraColumn +
                "].Please remove this column and import again";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              //return false;
            }
            renameKey(obj, oldColName, newColName);

            // obj.dataFormat=this.state.getImportfileData[i].dataFormat;
            // console.log('dbcol',obj[this.state.listOfDbColumn[i]],'datf',this.state.getImportfileData[i]);
            let returnObj = checkFileValidation(
              obj[this.state.listOfDbColumn[i]],
              this.state.getImportfileData[i].dataFormat
            );

            if (returnObj === false) {
              // if(obj.Comment == undefined)
              // {
              let typeError = null;
              if (
                this.state.getImportfileData[i].dataFormat === "MoneyNumber" ||
                this.state.getImportfileData[i].dataFormat === "Percent" ||
                this.state.getImportfileData[i].dataFormat === "DecimalPercent"
              ) {
                typeError =
                  ". Expected value is Number with maximum 2 decimal digits";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Number"
              ) {
                typeError = ". Expected value is Number without decimal";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Date"
              ) {
                typeError = ". Expected value is 'mm/dd/yyyy'";
              } else {
                typeError = "";
              }
              message =
                message != null
                  ? message +
                    " ,Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError
                  : "Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError;
            }
            else {
              if (
                this.state.getImportfileData[i].dataFormat === "Checkbox"
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === "yes"
              ) {
                obj[this.state.getImportfileData[i].attributeName] = "1";
              }
              else if (
                this.state.getImportfileData[i].dataFormat === "Checkbox"
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === "no"
              ) {
                obj[this.state.getImportfileData[i].attributeName] = "0";
              }
              else if (
                this.state.getImportfileData[i].dataFormat === "Checkbox"
                && obj[this.state.getImportfileData[i].attributeName] != null && obj[this.state.getImportfileData[i].attributeName] != undefined
                && (
                  (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === null || (obj[this.state.getImportfileData[i].attributeName]).toLowerCase() === undefined)
              ) {
                obj[this.state.getImportfileData[i].attributeName] = null;
              }
            }
          }
        
          for (var i = 0; i < this.state.lstRequiredField.length; i++) {
            if (
              obj[this.state.lstRequiredField[i]] == null &&
              this.state.breakCondition == false
            ) {
              // this.setState({ breakCondition: true });
              message =
                message != null
                  ? message +
                    " ,Field is required [" +
                    this.getUIColumn(this.state.lstRequiredField[i]) +
                    "]"
                  : "Field is required [" +
                    this.getUIColumn(this.state.lstRequiredField[i]) +
                    "]";
             
            }
          }
          obj.Comments = message;

          // invalidObj.push(objInvalid);
        }
        // if(message != null)
        // obj.Comment=message;
      });
    }
    let ValidData = formattedData.filter((m) => {
      if (m.Comments == null) return m;
    });
    let invalidData = formattedData.filter((m) => {
      if (m.Comments != null) return m;
    });

    invalidData.forEach((obj) => {
      for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
        let oldColName = this.getDbColumn(this.state.listOfHeader[i]);
        let newColName = this.state.listOfHeader[i];
        renameKey(obj, oldColName, newColName);
      }
    });
    this.setState({
      formattedData: ValidData,
      uKey: uploadingKey,
      uFile: uploadedFileName,
      validFormattedData: ValidObj,
      invalidFormattedData: invalidData,
      invalidRecordCount: invalidData.length,
      totalRecordCount: formattedData.length,
    });
    if (this.state.breakCondition == false && isColumnMissing == false)
      this.getSpsData(true);
    else {
      errorMessage =
        errorMessage != null
          ? errorMessage
          : "Error while processing file. Please try again";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
    }
  };

  onClickHandelFileImport(e) {
    if (e == true) {
      this.processFileImportAfterConfirm();
      this.setState({
        openConfirmDialog: false,
      });
    } else {
      this.setState({
        openConfirmDialog: false,
      });

      setDownloadingStatus(
        this.state.uKey,
        this.state.uFile,
        "failed",
        "",
        "Import cancelled by user"
      );
    }
  }

  onClickHandelUserConfirm(e) {
    if (e == true) {
      this.setState({
        openUserMappedConfirmDialog: true,
        openColumnMappingDialog: false,
        confirmUserForNotMatchedData: false,
        fileData: this.state.userFileData,
        unMatchedColumns: [],
        matchedColumns:[],
        //matchedColumns:[],
      });
    } else {
      this.setState({
        confirmUserForNotMatchedData: false,
        //unMatchedColumns: [],
      });

      setDownloadingStatus(
        this.state.uKey,
        this.state.uFile,
        "failed",
        "",
        "Import cancelled by user"
      );
    }
  }
  onClickHandelUserFileImport(e) {
    if (e == true) {
      this.processUserExcel();
      this.setState({
        openUserMappedConfirmDialog: false,
      });
    } else {
      this.setState({
        openUserMappedConfirmDialog: false,
      });

      setDownloadingStatus(
        this.state.uKey,
        this.state.uFile,
        "failed",
        "",
        "Import cancelled by user"
      );
    }
  }
  saveImport = (formattedData, uploadingKey, uploadedFileName, spsFileName) => {
    formattedData[formattedData.length] = this.state.downloadFileUrl;
    formattedData[formattedData.length] = spsFileName;
    var colNotMatched=null;
if(this.state.notMatchedByUser.length >0)
{
  this.state.notMatchedByUser.forEach((m)=>{
colNotMatched=colNotMatched != null ? colNotMatched + ',' +this.getDbColumn(m) : this.getDbColumn(m)
  });
}
    apiPostNoLoader({
      url: API_URL.ImportExport.addIndLeaseFileImportDetail,
      params: {
        marketId: this.state.selectedValueId,
        invalidRecordCount: this.state.invalidRecordCount,
        notMappedColumns:colNotMatched
        //this.state.notMatchedByUser.length >0 ? this.state.notMatchedByUser.join(","):null
      },
      postBody: formattedData,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            selectedFile: null,
            matchedColumns: [],
            unMatchedColumns: [],
            notMatchedByUser:[],
            fileData: [],
            userFileData: [],
            importMarket:null,
          });
          let importedData = [];
          importedData = response.data;

          if (this.state.invalidFormattedData.length > 0) {
            this.state.invalidFormattedData.forEach((m) => {
              if (m != undefined) {
                importedData.push(m);
              }
            });
          }
          let validCount = this.state.totalRecordCount - importedData.length;
          let message =
            "Total Records : " +
            this.state.totalRecordCount +
            " \nInvalid Records : " +
            importedData.length +
            " \nValid Records : " +
            validCount;
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "completed",
            message,
            "Import Complete"
          );
          importedData.length > 0
            ? this.downloadImportFileData(importedData, uploadedFileName)
            : null;
          this.reloadData();
        } else {
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "failed",
            response.message,
            "Import Failed"
          );
        }
      })
      .catch(() => {
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          "",
          "Import Failed"
        );
      });
  };
  // On file upload (click the upload button)
  onFileUpload = () => {
    if (
      this.state.selectedFile == null ||
      this.state.selectedFile == undefined
    ) {
      ShowToast("Please select a file before upload.", toastType.ERROR, 5000);
    } else {
      const uploadingKey = generateString(6);
      // Create an object of formData
      setDownloadingStatus(
        uploadingKey,
        this.state.selectedFileName,
        "processing",
        "",
        "Processing import file"
      );
      const formData = new FormData();
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            this.state.value === "Stream"
              ? this.processExcel(
                  reader.result,
                  this.state.selectedFileName,
                  uploadingKey
                )
              : this.mapUserFileWithTemplate(
                  reader.result,
                  this.state.selectedFileName,
                  uploadingKey
                );
          };
          reader.readAsBinaryString(this.state.selectedFile);
        }
      }
      this.setState({ isUpload: false, openDialog: false });
    }
  };

  downloadImportFileData(importData, fileName) {
    const wb = XLSX.utils.book_new();
    let compHeader = JSON.parse(
      JSON.stringify([...this.state.listOfCompColumns])
    );
    compHeader.push("Comments");
    importData.map((obj) => {
      orderKey(obj, compHeader);
    });
    let fN = fileName.split(".")[0];
    let ext = fileName.split(".")[1];
    let newFileName = fN + "_InvalidRecords." + ext;

    var data = importData;
    var wsData = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, wsData, "Validation Error");
    XLSX.writeFile(wb, newFileName);
    this.uploadInvalidRecords(wb, newFileName);
  }

  uploadInvalidRecords(wb, newFileName) {
    var bin = XLSX.write(wb, { type: "array", bookType: "xlsx" });
    var blob = new Blob([bin], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    var file = new File([blob], newFileName, {
      lastModified: new Date().getTime(),
    });
    // console.log('downloadfile',XLSX.readFile(fileName))
    this.setState(
      {
        selectedFile: file,
        selectedFileName: newFileName,
        selectedFileSize: file.size,
      },
      this.getSpsData(false)
    );
  }

  getImportFileDetails = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.ImportExport.getImportfileData,
        key: "getImportfileData",
        params: { sectionId: 212 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getImportfileData) => {
        this._asyncRequest = null;
        if (getImportfileData.getImportfileData.success == true) {
          let temp = [];
          let dbColumn = [];
          let requiredColumn = [];
          getImportfileData.getImportfileData.data
            .sort((a, b) => (a.UIColumn > b.UIColumn ? 1 : -1))
            .filter((m) => {
              if (m.IsVisible == 1) {
                temp.push(m.UIColumn);
                dbColumn.push(m.DBColumn);
              }
              if (m.IsRequired == 1) {
                requiredColumn.push(m.DBColumn);
              }
            });
          this.setState(
            {
              getImportfileData:
                camelcaseKeys(getImportfileData.getImportfileData.data).sort(
                  (a, b) => (a.uiColumn > b.uiColumn ? 1 : -1)
                ) || [],

              listOfCompColumns: temp.sort(),
              listOfDbColumn: dbColumn,
              lstRequiredField: requiredColumn.sort(),
            },
            () => {
              this.forceUpdate();
            }
          );
        } else {
          ShowToast(
            "Something Went Wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getUploadedFile = (file, category) => {
    let { filesToBeUploaded } = this.state || [];
    filesToBeUploaded =
      (filesToBeUploaded || []).filter((x) => x.category !== category) || [];
    filesToBeUploaded.push({ category: category, file: file });
    this.setState({
      filesToBeUploaded,
      reloadBuilding: !this.state.reloadBuilding,
    });
  };

  cleanName = (name) => {
    name = (name || "").replace(/\s+/gi, "-"); // Replace white space with dash
    return name.replace(/[^a-zA-Z0-9]/gi, ""); // Strip any special charactere
  };

  getSpsData = (callSaveImport) => {
    let env = getCurrentEnvironment().sharePointLibrary;
    if (getSPSTokenData()) {
      this.setState({ spsData: {} });
      getSpsAPiRequest(getSharePointUrl("streamrealty", "getFolder")).then(
        (spsDataRes) => {
          //            console.log(res);
          //if (res.value !== null && res.value.length > 0 &&
          //	(res.value.find(x => x.displayName === env || x.name === env) !== undefined)) {
          //		const parentFolder = res.value.find(x => x.displayName === env || x.name === env);
          //		const folderKey = parentFolder.id;
          //		getSpsAPiRequest(
          //			getSharePointUrl(
          //		  	'streamrealty',
          //		  	'getInnerFolder',
          //		  	folderKey,
          //			'Lease'
          //			)
          //	  	).then((spsDataRes) => {
          const fileName = this.state.selectedFileName;
          const folderName = env; //this.cleanName(fileName);
          if (
            spsDataRes.value !== null &&
            spsDataRes.value.length > 0 &&
            spsDataRes.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            ) !== undefined
          ) {
            const folder = spsDataRes.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            );
            const driveId = folder.parentReference.driveId;
            const key = folder.id;
            this.setState({ spListkey: key });
            this.uploadFilesToSharePoint(callSaveImport);
          } else {
            if (folderName.length > 0) {
              spsApiPost({
                url: getSharePointUrl("streamrealty", "createFolder"),
                postBody: {
                  displayName: folderName,
                  columns: [
                    {
                      name: "FileType",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                  ],
                  list: {
                    template: "documentLibrary",
                  },
                },
              }).then((data) => {
                if (data) {
                  this.getSpsData(callSaveImport);
                }
              });
            }
          }
          //});
          //}
        }
      );
    } else {
      apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
        if (data) {
          setSPSToken(data);
          this.getSpsData(callSaveImport);
        }
      });
    }
  };

  uploadFilesToSharePoint(callSaveImport) {
    //        this.state.filesToBeUploaded.forEach(x => {
    let d = new Date();
    let dformat = `${
      d.getMonth() + 1
    }${d.getDate()}${d.getFullYear()}_${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    let fileName = this.state.selectedFileName.split(".")[0];
    let ext = this.state.selectedFileName.split(".")[1];
    fileName = fileName + "_" + dformat + "." + ext;
    spsApiPost({
      url: getSharePointUrl(
        "streamrealty",
        "uploadFileSession",
        this.state.spListkey,
        fileName,
        "LeaseComps"
        //              x.category + '_Document_' + this.cleanName(getSectionAttributeValueByKey(this.state.getBuildingDetails, 'coStarPropId')) + '.pdf'
      ),
      postBody: {
        fileSize: this.state.selectedFileSize, //x.file.size,
        item: {
          "@microsoft.graph.conflictBehavior": "replace",
        },
      },
    }).then((data) => {
      if (data) {
        uploadSpsAPiRequest(
          data.data.uploadUrl,
          this.state.selectedFile,
          true
        ).then((data) => {
          if (data) {
            ShowToast("File uploaded successfully.", toastType.SUCCESS);
            this.setState({
              selectedFile: null,
              downloadFileUrl: data["@content.downloadUrl"],
            });
            //console.log(this.state.downloadFileUrl);
            if (
              this.state.breakCondition == false &&
              this.state.downloadFileUrl != null
            ) {
              this.saveImport(
                callSaveImport && this.state.formattedData,
                this.state.uKey,
                this.state.uFile,
                fileName
              );
            }
            //                  this.getList(data.name,
            //                    (((store.getState() || {}).auth || {}).account || {}).userName || '');

            //this.updateMetaData(data.id, (((store.getState() || {}).auth || {}).account || {}).userName || '', 'LeaseComps');
          } else {
            let errorMessage =
              "The import file could not be uploaded to the server.";
            setDownloadingStatus(
              this.state.uKey,
              this.state.uFile,
              "failed",
              errorMessage,
              "Processing Failure"
            );
          }

          //                this.props.onViewChange();
        });
      }
    });
    //        });
  }

  getList = (fileName, emailId) => {
    getSpsAPiRequest(
      getSharePointUrl("streamrealty", "getSps", this.state.spListkey)
    ).then((spsDataRes) => {
      if (spsDataRes) {
        const { value } = spsDataRes;
        for (let i = 0; i < value.length; i++) {
          if (fileName === value[i].name) {
            this.updateMetaData(value[i].listItem.id, emailId);
            break;
          }
        }
      }
    });
  };

  updateMetaData = (itemid, emailId = "", category) => {
    const metadata = {
      FileType: category,
      UserEmail: emailId,
    };
    uploadSpsMetadataAPiRequest(
      getSharePointUrl(
        "streamrealty",
        "uploadMetadata",
        this.state.spListkey,
        itemid
      ),
      metadata
    ).then(() => {
      // this.setState({ filesToUpload: [], selectedFile: [] });
      // this.getSpsData();
    });
  };

  getBase64Content = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //          console.log('Error: ', error);
    };
  };

  viewFileData = async (obj, title) => {
    let url = "";
    if (obj.uploadToSp) {
      const { filesToBeUploaded, spsData } = this.state;
      const file = (filesToBeUploaded || []).find(
        (x) => x.category === obj.category
      );
      if (file && file.file) {
        this.getBase64Content(file.file, (result) => {
          //              console.log(result);
          url = result;
          this.showFile(url, title);
        });
      } else if (
        spsData &&
        spsData[obj.category] &&
        spsData[obj.category].length > 0
      ) {
        const spFile = _.orderBy(
          spsData[obj.category].filter((x) => x.name === title) || [],
          ["createdDateTime"],
          ["desc"]
        );
        if (spFile && spFile[0]) {
          this.showFile(
            await this.getSpBlob(spFile[0]["@microsoft.graph.downloadUrl"]),
            title,
            true
          );

          // this.generateShareLinkAndShow(spFile);
        } else {
          ShowToast("File not found!", toastType.INFO, 2000, 2000);
        }
      } else {
        ShowToast("File not found!", toastType.INFO, 2000, 2000);
      }
    } else {
      url = getSectionAttributeValueByKey(
        this.state.getBuildingDetails,
        obj.contentKey
      );
      this.showFile(url, title);
    }
  };

  getSpBlob = (url) => {
    return new Promise(function (resolve, reject) {
      fetch(url)
        .then((response) => response.blob())
        .then(function (blob) {
          const dataUrl = URL.createObjectURL(blob);
          return resolve(dataUrl);
        })
        .catch(() => {
          return resolve("");
        });
    });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  showFile = (url, title, isSpsFile = false) => {
    const contentType = "application/pdf";
    let blobUrl;
    if (isSpsFile === false) {
      const blob = this.b64toBlob(
        url.replace("data:application/pdf;base64,", ""),
        contentType
      );
      blobUrl = URL.createObjectURL(blob);
    } else {
      blobUrl = url;
    }
    this.setState({
      openaddform: true,
      addformtitle: title,
      addNewformComponent: (
        <div style={{ width: "800px", height: "530px", overflow: "hidden" }}>
          {/* <iframe src={url} width="700" height="700"></iframe> */}
          {/* <embed src={`data:application/pdf;base64,${url}`} type="application/pdf" width="100%"></embed> */}
          <iframe
            id="iframe"
            onLoad={(ev) => {
              const x = document.getElementById("iframe");
              (
                ((x.contentWindow || {}).document.getElementsByTagName(
                  "embed"
                ) || [])[0] || {}
              ).style.height = "500px";
            }}
            src={blobUrl}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "block",
            }}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      ),
    });
  };
  onColumnChange = (event, value, param) => {
    let templateRecord =
      this.state.unMatchedColumns.length > 0
        ? [...this.state.unMatchedColumns]
        : [];
    let x = templateRecord.find((m) => m.key == param);
    templateRecord.length > 0
      ? templateRecord.find((m) => m.key == param) == null ||
        templateRecord.find((m) => m.key == param) == undefined
        ? templateRecord.push({ key: param, value: value })
        : templateRecord.map((x) => {
            if (x.key == param) {
              (x.key = param), (x.value = value);
            }
          })
      : templateRecord.push({ key: param, value: value });
    //console.log('event, value,m',event, value,m);
    // const index = templateRecord.indexOf(value);
    // if (index > -1) {
    //   templateRecord.splice(index, 1); // 2nd parameter means remove one item only
    // }
    this.setState(
      { unMatchedColumns: templateRecord.filter((m) => m.value != null) },
      () => {
        this.forceUpdate();
      }
    );
  };
  hasDuplicate = (arrayObj, colName) => {
    var hash = Object.create(null);
    return arrayObj.some((arr) => {
      return (
        arr[colName] && (hash[arr[colName]] || !(hash[arr[colName]] = true))
      );
    });
  };
  getUIColumn = (val) => {
    return this.state.getImportfileData.find((x) => x.dbColumn == val).uiColumn;
    //return this.state.listOfHeader.some((item) => item === val);
  };
  updateHeaderColumn = (headers) => {
    this.setState({
      listOfHeader: headers.sort(),
    });
  };

  getMissing(a, b) {
    var missings = [];
    var matches = false;

    for (var i = 0; i < a.length; i++) {
      matches = false;
      for (var e = 0; e < b.length; e++) {
        if (a[i] === b[e]) matches = true;
      }
      if (!matches) missings.push(a[i]);
    }
    return missings;
  }
  handleHeaderUserFileCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.listOfCompColumns.includes(val);
  }
  mapUserFileWithTemplate = (data, uploadedFileName, uploadingKey) => {
    this.setState({
      breakCondition: false,
      uKey: uploadingKey,
      uFile: uploadedFileName,
    });
    let errorMessage = "";
    const workbook = XLSX.read(data, {
      type: "binary",
      cellDates: true,
      cellText: false,
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { defval: null, raw: false, dateNF: "mm/dd/yyyy hh:mm:ss" }
    );
    if (
      this.state.selectedValue == null ||
      this.state.selectedValue == undefined ||
      this.state.selectedValue == ""
    ) {
      this.setState({ breakCondition: true });
      errorMessage = "Please select market before import.";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }

    const formattedData = excelRows.map((m) => {
      return m;
    });

    let headers = [];
    if (formattedData.length == 0) {
      this.setState({ breakCondition: true, selectedFile: null });
      errorMessage =
        "The import file is empty. Please upload file with valid data";

      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return;
    }

    formattedData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        var replacedKey = key.toString().trim();
        i == 0 && key != "Comments" ? headers.push(replacedKey) : null;
        // var replacedKey = key.trim().replace(/\s\s+/g, "_");
        if (key !== replacedKey) {
          e[replacedKey] = e[key];
          delete e[key];
        }
      });
    });
    if (this.state.breakCondition == false) {
      //let market=formattedData[0]["CoStar Market"];
      this.updateHeaderColumn(headers.sort());
    }
    let isColumnPresent = true;
    let templateRecordNotPresent = this.getMissing(
      this.state.listOfCompColumns,
      headers.sort()
    );
    let templateRecordNotPresentReOrdered = [];

    for (var i = 0; i < templateRecordNotPresent.length ; i++) {
      this.handleRequiredFieldCheck(
        this.getDbColumn(templateRecordNotPresent[i])
      ) == true
        ? templateRecordNotPresentReOrdered.unshift(templateRecordNotPresent[i])
        : templateRecordNotPresentReOrdered.push(templateRecordNotPresent[i]);
    }

    let userFileColumnNotPresentInTemplate = this.getMissing(
      headers.sort(),
      this.state.listOfCompColumns
    );

    let matchedColumns = [];
    for (var i = 0; i < headers.length ; i++) {
      if (this.state.breakCondition != true)
        isColumnPresent = this.handleHeaderUserFileCheck(headers[i]);
      if (isColumnPresent == true) {
        this.handleRequiredFieldCheck(this.getDbColumn(headers[i])) == true
          ? matchedColumns.unshift({ key: headers[i], value: headers[i] })
          : matchedColumns.push({ key: headers[i], value: headers[i] });
      }
    }
    if (this.state.breakCondition == false) {
      this.setState({
        userFileColumnNotPresentInTemplate: userFileColumnNotPresentInTemplate,
        templateRecordNotPresent: templateRecordNotPresentReOrdered,
        matchedColumns: matchedColumns,
        fileData: formattedData,
        openColumnMappingDialog: true,
      });
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "completed",
        "",
        "Processing of User Import file Complete"
      );
    }
  };

  processUserExcel = () => {
    let formattedData = [...this.state.fileData];

    formattedData = formattedData.filter((m) => {
      let commencementDate = m["Commencement Date"];
      let moveInDate = m["Move-in Date"];
      let expDate = m["Expiration Date"];
      let closeDate = m["Close Date"];
      m["Commencement Date"] != null
        ? (m["Commencement Date"] = moment(m["Commencement Date"]).format(
            DateFormat
          ))
        : null;
      m["Move-in Date"] != null
        ? (m["Move-in Date"] = moment(m["Move-in Date"]).format(DateFormat))
        : null;
      m["Expiration Date"] != null
        ? (m["Expiration Date"] = moment(m["Expiration Date"]).format(
            DateFormat
          ))
        : null;
      m["Close Date"] != null
        ? (m["Close Date"] = moment(m["Close Date"]).format(DateFormat))
        : null;
      m["Commencement Date"] == "Invalid date"
        ? (m["Commencement Date"] = commencementDate)
        : m["Commencement Date"];
      m["Move-in Date"] == "Invalid date"
        ? (m["Move-in Date"] = moveInDate)
        : m["Move-in Date"];
      m["Expiration Date"] == "Invalid date"
        ? (m["Expiration Date"] = expDate)
        : m["Expiration Date"];
      m["Close Date"] == "Invalid date"
        ? (m["Close Date"] = closeDate)
        : m["Close Date"];

      return m;
    });

    formattedData.forEach((obj) => {
      if (this.state.breakCondition == false) {
        let message = null;
        //  let extraColumn=null;

        for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
          let oldColName = this.state.listOfCompColumns[i];
          let newColName = this.getDbColumn(this.state.listOfCompColumns[i]);
          if (
            oldColName != undefined &&
            oldColName != null &&
            newColName != undefined &&
            newColName != null
          ) {
            renameKey(obj, oldColName, newColName);

            // obj.dataFormat=this.state.getImportfileData[i].dataFormat;
            // console.log('dbcol',obj[this.state.listOfDbColumn[i]],'datf',this.state.getImportfileData[i]);
            let returnObj = checkFileValidation(
              obj[this.state.listOfDbColumn[i]],
              this.state.getImportfileData[i].dataFormat
            );

            if (returnObj === false) {
              // if(obj.Comment == undefined)
              // {
              let typeError = null;
              if (
                this.state.getImportfileData[i].dataFormat === "MoneyNumber" ||
                this.state.getImportfileData[i].dataFormat === "Percent" ||
                this.state.getImportfileData[i].dataFormat === "DecimalPercent"
              ) {
                typeError =
                  ". Expected value is Number with maximum 2 decimal digits";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Number"
              ) {
                typeError = ". Expected value is Number without decimal";
              } else if (
                this.state.getImportfileData[i].dataFormat === "Date"
              ) {
                typeError = ". Expected value is 'mm/dd/yyyy'";
              } else {
                typeError = "";
              }
              message =
                message != null
                  ? message +
                    " ,Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError
                  : "Data type error in [" +
                    this.state.getImportfileData[i].uiColumn +
                    "]" +
                    typeError;
            
            }
          }
          // }
          // else{
          //   objValid=obj;
          // }
        }
        //ValidObj.push(objValid);

        for (var i = 0; i < this.state.lstRequiredField.length; i++) {
          if (
            obj[this.state.lstRequiredField[i]] == null &&
            obj[this.state.lstRequiredField[i]] == undefined &&
            this.state.breakCondition == false
          ) {
            // this.setState({ breakCondition: true });
            message =
              message != null
                ? message +
                  " ,Field is required [" +
                  this.getUIColumn(this.state.lstRequiredField[i]) +
                  "]"
                : "Field is required [" +
                  this.getUIColumn(this.state.lstRequiredField[i]) +
                  "]";
          
          }
        }
        obj.Comments = message;

        // invalidObj.push(objInvalid);
      }
      // if(message != null)
      // obj.Comment=message;
    });

    let ValidData = formattedData.filter((m) => {
      if (m.Comments == null) return m;
    });
    let invalidData = formattedData.filter((m) => {
      if (m.Comments != null) return m;
    });

    invalidData.forEach((obj) => {
      for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
        let oldColName = this.getDbColumn(this.state.listOfCompColumns[i]);
        let newColName = this.state.listOfCompColumns[i];
        if (
          oldColName != undefined &&
          oldColName != null &&
          newColName != undefined &&
          newColName != null
        ) {
          renameKey(obj, oldColName, newColName);
        }
      }
    });
    this.setState({
      formattedData: ValidData,
      // validFormattedData:ValidObj,
      invalidFormattedData: invalidData,
      invalidRecordCount: invalidData.length,
      totalRecordCount: formattedData.length,
    });
    if (this.state.breakCondition == false) this.getSpsData(true);
  };
  validateUserFileColumns = () => {
    setDownloadingStatus(
      this.state.uKey,
      this.state.uFile,
      "processing",
      "",
      "Processing import file"
    );
    let notMatchedByUser1 = [];    
    let breakCondition = false;
    let unMatchedColumns = JSON.parse(
      JSON.stringify([...this.state.unMatchedColumns]));
    let listOfCompColumns = JSON.parse(
      JSON.stringify([...this.state.listOfCompColumns]));
    this.state.matchedColumns.forEach((m) => {
      if(unMatchedColumns.find((n) => n.key == m.key) == null ||
      unMatchedColumns.find((n) => n.key == m.key) == undefined)
      unMatchedColumns.push({ key: m.key, value: m.value });
    });

    let isDuplicate = false;
    isDuplicate = this.hasDuplicate(unMatchedColumns, "value");
    console.log(isDuplicate);
    if (isDuplicate == true) {
      setDownloadingStatus(
        this.state.uKey,
        this.state.uFile,
        "failed",
        "Duplicate columns are mapped",
        "Validation Failure"
      );
      return false;
      //ShowToast("Duplicate columns are mapped", toastType.Error, 5000, 500)
    }

    if (isDuplicate == false && breakCondition == false) {
      let userObjCreated = [];
      unMatchedColumns.forEach((obj) => {
        userObjCreated.push(this.getDbColumn(obj.key));
      });
      //userObjCreated.forEach((obj)=>{
      let mandatoryColumns = [];
      for (var i = 0; i < this.state.lstRequiredField.length; i++) {
        if (userObjCreated.includes(this.state.lstRequiredField[i]) == false) {
          mandatoryColumns.push(
            this.getUIColumn(this.state.lstRequiredField[i])
          );
          
        }
      }
      if (mandatoryColumns.length > 0) {
        breakCondition = true;
        let erorMessage =
          "Please map all the following mandatory columns : [" +
          mandatoryColumns +
          "]";
        setDownloadingStatus(
          this.state.uKey,
          this.state.uFile,
          "failed",
          erorMessage,
          "Validation Failure"
        );
        return false;        
      }
      //})
      if (breakCondition == false || isDuplicate == false) {
        let data = JSON.parse(JSON.stringify([...this.state.fileData]));
        let listOfHeader = JSON.parse(
          JSON.stringify([...this.state.listOfHeader]));
        listOfHeader.forEach((obj) => {
          let col = unMatchedColumns.find((m) => m.value === obj);
          if (col != undefined) {
            const index = listOfHeader.indexOf(col.value);
            if (index > -1) {
              notMatchedByUser1.push(col.key);
              listOfHeader.splice(index, 1, col.key);
            }
          }
        });
        let notMatchedByUser = this.getMissing(
            this.state.listOfCompColumns,
            notMatchedByUser1
        );
        // console.log(listOfHeader);
        data.forEach((obj) => {
          unMatchedColumns.forEach((m) => {
            renameKey(obj, m.value, m.key);
          });
        });

        data.forEach(function (e, i) {
          Object.keys(e).forEach(function (key) {
            if (listOfCompColumns.includes(key) == false) delete e[key];
          });
        });
        let market=data[0]["CoStar Market"];
        this.setState({
          importMarket:market,
          userFileData: data,
          fileData: notMatchedByUser.length > 0 ? this.state.fileData : data,
          matchedColumns: notMatchedByUser.length > 0 ? this.state.matchedColumns : [],
          unMatchedColumns: notMatchedByUser.length > 0 ? unMatchedColumns : [],
          openColumnMappingDialog: notMatchedByUser.length > 0 ? true : false,
          openUserMappedConfirmDialog:
            notMatchedByUser.length > 0 ? false : true,
          notMatchedByUser: notMatchedByUser,
          confirmUserForNotMatchedData:
            notMatchedByUser.length > 0 ? true : false,
        });
      }
      //this.processUserExcel(data);

      // Object.keys(data).splice(-1,1)
      //console.log("data",data);
    }
  };

  onCancelClickAfterPopupAdd = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.industrialBuildingMasterData,
        key: "getBuildingMaster",
        params: { businessLineId: 2, marketId: this.state.selectedMarketId },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((leaseCompData) => {
        this._asyncRequest = null;
        if (leaseCompData.getBuildingMaster.success == true) {
          this.setState({
            getBuildingMaster:
              camelcaseKeys(leaseCompData.getBuildingMaster.data) || [],
              companyMasterDetails: [],
          });
          this.forceUpdate();
        } else {
          ShowToast(
            leaseCompData.getBuildingMaster.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };


  onUnmappedLoanDetailsClick = () => {
    this.setState({
      openaddform: true,
      selectedBuilding: [],
      searchInputPopup: "",
    });
  };

  onPopupBackClick = () => {
    this.setState({ openaddform: false });
  };

  reloadPage = (page,rowcount) => {
    page = page;
    
    this.setState({pageNumber: page},
      () => {
        this.forceUpdate();
        this.reloadGridData(this.state.selectedMarketId);
      });
    this.setState({rowsPerPage: rowcount});
    
    
  };

  onUnmappedLoanDetailsClick = () => {
    this.setState({
      openaddform: true,
      selectedBuilding: [],
      searchInputPopup: "",
    });
  };

  onPopupBackClick = () => {
    this.setState({ openaddform: false });
  };

  render() {
    const {
      getAllDirectSubLease,
      getAllCostarSubMarket,
      getAllCostarBuildingClass,
      leaseCompsSummaryData,
      searchInput,
    //  getAllCompType,
      getAllLeaseType,
    //  getLeaseCompDetail,
      getMarketMaster,
      getSubMarketMaster,
      openFilter,
      getBuildingMaster,
      companyMasterDetails,
      userConfiguration,
      openSlidingPanel,
      settingConfig,
      listOfColumns,      
      pageNumber
    } = this.state;

    let pageSize = 0;
    let userConfig = userConfiguration.filter((m) => {
      if (m.sectionId == 19 && m.isVisible == true) {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (
          m.dataFormat == "Number" ||
          m.dataFormat == "MoneyNumber" ||
          m.dataFormat == "Decimal"
        ) {
          m.headcellClass = "headcell-number-text";
        }
        if (m.key == "companyName") {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
        return m;
      }
      //this.refs.gridTable.setRowsPerPage(pageSize);
    });
    userConfig.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );
    const dbColumnList = userConfiguration.filter((m) => {
      if (m.sectionId == 19 && m.attributeSortOrder != -1 && !m.renderComponent)
        return m; //
    });
    dbColumnList.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );

    leaseCompsSummaryData.forEach((item, index) => {
      item.serial = index + 1;
    });
	const filterConfig = [
		{
		  multiple: false,
		  type: controlType.DropDown,
		  minTag: 1,
		  source: this.state.getMarketMaster,
		  key: "market",
		  placeholder: "",
		  title: "Market",
		},
	  ];
    return (
      <>
        {this.state.detailView ? (
          <>
            <EditIndLeaseComps
             globalMarketId={this.props.globalMarketId}
              selectedCompId={this.state.selectedEditRowId}
              onBackClick={this.onBackClick}
              ViewTitle={this.state.ViewTitle}
              actionType={this.state.actionType}
              isInAddMode={this.state.isInAddMode}
              reloadData={this.reloadData}
              companyMasterDetails={this.state.companyMasterDetails}
              getBuildingMaster={this.state.getBuildingMaster}
              //getAllCompType={this.state.getAllCompType}
			  onCancelClickAfterPopupAdd={this.onCancelClickAfterPopupAdd}
            />
          </>
        ) : (
          <>
            <div className="row no-print">
              <div className="col-md-3 col-lg-3">
                <PageHeader title="Lease Comps"></PageHeader>
              </div>
              {this.state.openDialog == true && (
                <Dialog open={this.state.openDialog} maxWidth="lg">
                  <AppBar
                    style={{ position: "relative", backgroundColor: "#064473" }}
                  >
                    <Toolbar>
                      <Typography
                        variant="subtitle1"
                        className="app-header-white"
                        style={{
                          marginLeft: "-11px",
                          flex: "1",
                          color: "#FFFFF",
                          marginTop: "-2px",
                        }}
                      >
                        Manage Data
                      </Typography>
                      <IconButton
                        edge="start"
                        color="inherit"
                        style={{ marginTop: "-18px", marginRight: "-30px" }}
                        onClick={() => {
                          this.setState({
                            openDialog: false,
                          });
                        }}
                        aria-label="close"
                      >
                        <IoMdCloseCircleOutline />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <div>
                    <DownloadTemplate
                       importData={true}
                      downloadTemplate={this.downloadTemplate}
                      importFile={this.importFile}
                      enableClear={this.enableClear}
                      onCancel={this.onCancel}
                      exportData={this.exportData}
                      hideExportClearButton={true}
                      templateMessage={"Import Lease Comp.Click"}
                    />
                  </div>
                </Dialog>
              )}

              {this.state.openColumnMappingDialog == true && (
                <Dialog open={this.state.openColumnMappingDialog} maxWidth="lg">
                  <AppBar
                    style={{
                      position: "relative",
                      backgroundColor: "#064473",
                      height: "38px",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-md-6 col-lg-6"
                        style={{ marginTop: "13px" }}
                      >
                        <span> Map Columns</span>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <IconButton
                          style={{
                            float: "right",
                            marginTop: "-6px",
                            marginRight: "-15px",
                          }}
                          edge="start"
                          color="inherit"
                          onClick={() => {
                            this.setState({
                              openColumnMappingDialog: false,
                            });
                            setDownloadingStatus(
                              this.state.uKey,
                              this.state.uFile,
                              "failed",
                              "",
                              "Import cancelled by user"
                            );
                          }}
                          aria-label="close"
                        >
                          <IoMdCloseCircleOutline />
                        </IconButton>
                      </div>
                    </div>
                  </AppBar>
                  <div className="row" style={{ width: "514px" }}>
                    <div
                      className="row"
                      style={{ width: "514px", padding: "8px" }}
                    >
                      <div className="column-mapping-div">
                        {this.state.matchedColumns.length > 0 && (
                          <span style={{ color: "#004EA8", fontSize: "16px" }}>
                            Below columns matched with SRP template
                          </span>
                        )}
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Template File Column</TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                User File Column
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        {this.state.matchedColumns.map((m) => {
                          return (
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell style={{ width: "130px" }}>
                                    {m.value}{" "}
                                    {this.handleRequiredFieldCheck(
                                      this.getDbColumn(m.key)
                                    ) == true ? (
                                      <span className="span-mandatory">*</span>
                                    ) : null}
                                  </TableCell>
                                  <TableCell style={{ width: "80px" }}>
                                    {" "}
                                    <Autocomplete
                                      size="small"
                                      multiple={false}
                                      disabled
                                      underlineStyle={{ display: "none" }}
                                      limitTags={1}
                                      options={(
                                        this.state.listOfCompColumns || []
                                      ).map((option) => option)}
                                      autoHighlight
                                      value={m.value}
                                      // {this.state.matchedColumns.find((x)=>{
                                      //   return x.value == m.value ? m.value : null
                                      // })}
                                      key={"dropDown_filter_lease_import" + m}
                                      id={"dropDown_filter_lease_import" + m}
                                      onChange={(event, value) => {
                                        this.onColumnChange(event, value, m);
                                        // selectedValue: value,
                                        //selectedValueId:this.state.getMarketMaster.find((m)=>m.market == value).marketId,
                                      }}
                                      // this.onChange(filter, event, value);

                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          className="autosearc-text"
                                          hiddenLabel
                                          variant="outlined"
                                          margin="normal"
                                          // placeholder={}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          );
                        })}
                      </div>
                      <div className="column-mapping-div">
                        {this.state.templateRecordNotPresent.length > 0 && (
                          <span style={{ color: "#004EA8", fontSize: "16px" }}>
                            No match found for below columns in SRP template
                          </span>
                        )}
                        {this.state.templateRecordNotPresent.map((m) => {
                          return (
                            <Table id="User_Mapping_Key" key="User_Mapping_Key">
                              {/* <TableHead><TableRow>
                                
                                <TableCell>Template File Column</TableCell>
                                <TableCell>User File Column</TableCell>
                                </TableRow></TableHead> */}
                              <TableBody id="UserMappingTB" key="UserMappingTB">
                                <TableRow
                                  id={m + "UserMapping"}
                                  key={m + "UserMapping"}
                                >
                                  <TableCell style={{ width: "130px" }}>
                                    {m}
                                    {this.handleRequiredFieldCheck(
                                      this.getDbColumn(m)
                                    ) == true ? (
                                      <span className="span-mandatory">*</span>
                                    ) : null}
                                  </TableCell>
                                  <TableCell style={{ width: "80px" }}>
                                    {" "}
                                    <Autocomplete
                                      size="small"
                                      multiple={false}
                                      underlineStyle={{ display: "none" }}
                                      limitTags={1}
                                      options={(
                                        this.state.listOfHeader || []
                                      ).map((option) => option)}
                                      autoHighlight
                                      //value={m.value}
                                      // {this.state.matchedColumns.find((x)=>{
                                      //   return x.value == m.value ? m.value : null
                                      // })}
                                      key={"dropDown_filter_lease_import" + m}
                                      id={"dropDown_filter_lease_import" + m}
                                      onChange={(event, value) => {
                                        this.onColumnChange(event, value, m);
                                        // selectedValue: value,
                                        //selectedValueId:this.state.getMarketMaster.find((m)=>m.market == value).marketId,
                                      }}
                                      // this.onChange(filter, event, value);

                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          fullWidth
                                          className="autosearc-text"
                                          hiddenLabel
                                          variant="outlined"
                                          margin="normal"
                                          // placeholder={}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          );
                        })}
                      </div>
                      <Button
                        size="small"
                        className={
                          this.props.isInAddMode
                            ? "notop-action-button"
                            : "notop-action-button btn-small"
                        }
                        onClick={() => {
                          this.validateUserFileColumns();
                        }}
                      >
                        <span className="tool-icon-text">Validate</span>
                      </Button>
                      <Button
                        size="small"
                        className={
                          this.props.isInAddMode
                            ? "notop-action-button"
                            : "notop-action-button btn-small"
                        }
                        onClick={() => {
                          this.setState({
                            openColumnMappingDialog: false,
                          });
                          setDownloadingStatus(
                            this.state.uKey,
                            this.state.uFile,
                            "failed",
                            "",
                            "Import cancelled by user"
                          );
                        }}
                      >
                        <span className="tool-icon-text">Cancel</span>
                      </Button>
                    </div>
                  </div>
                </Dialog>
              )}

              {this.state.confirmUserForNotMatchedData == true && (
                <ConfirmDialog
                  IsOpen={this.state.confirmUserForNotMatchedData}
                  Message={
                    "There are un-mapped column identified for these columns [" +
                    this.state.notMatchedByUser +
                    "]. Do you Still want to continue ?"
                  }
                  OnHandelClick={(isValid) => {
                    if (isValid) {
                      this.onClickHandelUserConfirm(isValid);
                    } else {
                      this.onClickHandelUserConfirm(isValid);
                    }
                  }}
                  AgreeText="Accept"
                  DisAgreeText="Cancel"
                ></ConfirmDialog>
              )}
              {(this.state.openConfirmDialog == true ||
                this.state.openUserMappedConfirmDialog == true) && (
                <ConfirmDialog
                  IsOpen={
                    this.state.openConfirmDialog ||
                    this.state.openUserMappedConfirmDialog
                  }
                  Message={
                    this.state.importMarket == undefined ||
                    this.state.importMarket == null
                      ? "You are importing lease comps into Stream Market [" +
                        this.state.selectedValue +
                        "] . Do you want to continue?"
                      : "You are importing lease comps with CoStar Market [" +
                        this.state.importMarket +
                        "] into Stream Market [" +
                        this.state.selectedValue +
                        "] . Do you want to continue?"
                  }
                  OnHandelClick={(isValid) => {
                    if (isValid) {
                      this.state.openUserMappedConfirmDialog == true
                        ? this.onClickHandelUserFileImport(isValid)
                        : this.onClickHandelFileImport(isValid);
                    } else {
                      this.state.openUserMappedConfirmDialog == true
                        ? this.onClickHandelUserFileImport(isValid)
                        : this.onClickHandelFileImport(isValid);
                    }
                  }}
                  AgreeText="Accept"
                  DisAgreeText="Cancel"
                ></ConfirmDialog>
              )}
              {this.state.isUpload == true && (
                <Dialog open={this.state.isUpload} maxWidth="lg">
                  <AppBar
                    style={{
                      position: "relative",
                      backgroundColor: "#064473",
                      height: "38px",
                    }}
                  >
                    <div className="row">
                      <div
                        className="col-md-6 col-lg-6"
                        style={{ marginTop: "13px" }}
                      >
                        <span> Upload File</span>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <IconButton
                          style={{
                            float: "right",
                            marginTop: "-6px",
                            marginRight: "-15px",
                          }}
                          edge="start"
                          color="inherit"
                          onClick={() => {
                            this.setState({
                              isUpload: false,
                            });
                          }}
                          aria-label="close"
                        >
                          <IoMdCloseCircleOutline />
                        </IconButton>
                      </div>
                    </div>
                  </AppBar>
                  <div style={{ padding: "8px" }}>
                    {/* <div>
                      Import File template :{" "}
                      <span className="span-mandatory">*</span>
                    </div>
                    <FormControl>
                      <FormLabel id="File-import-radio-buttons-group">
                        {" "}
                      </FormLabel>
                      <RadioGroup
                        row
                        //aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={this.state.value}
                        onChange={this.handleRadioChange}
                      >
                        <FormControlLabel
                          value="Stream"
                          control={<Radio />}
                          label="Stream"
                        />
                        <FormControlLabel
                          value="Non-Stream"
                          control={<Radio />}
                          label="Non-Stream"
                        />
                      </RadioGroup>
                    </FormControl> */}
                    <Autocomplete
                      size="small"
                      multiple={filterConfig[0].isMultiple}
                      underlineStyle={{ display: "none" }}
                      limitTags={1}
                      options={(this.state.getMarketMaster || []).map(
                        (option) => option[filterConfig[0].key]
                      )}
                      autoHighlight
                      value={this.state.selectedValue}
                      key={"dropDown_filter_lease_import"}
                      id={"dropDown_filter_lease_import"}
                      onChange={(event, value) => {
                        this.setState({
                          selectedValue: value,
                          selectedValueId: this.state.getMarketMaster.find(
                            (m) => m.market == value
                          ).marketId,
                        });
                      }}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Market <span className="span-mandatory">*</span>
                          </label>
                          <TextField
                            {...params}
                            fullWidth
                            className="autosearc-text"
                            hiddenLabel
                            variant="outlined"
                            margin="normal"
                            placeholder={this.props.placeholder}
                          />
                        </Box>
                      )}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: "5px !important",
                      }}
                      className="filter-label"
                    >
                      <label htmlFor="filled-hidden-label-small">
                        Import File
                      </label>
                    </Box>
                    <input
                      type="file"
                      style={{ padding: "24px", backgroundColor: "lightgray" }}
                      onChange={this.onFileChange}
                    />
                  </div>
                  <div style={{}}>
                    <button
                      className="import-button"
                      onClick={this.onFileUpload}
                    >
                      Upload
                    </button>
                  </div>
                </Dialog>
              )}

              <div>
                {dbColumnList.length > 0 && (
                  <SettingScreenDialog
                    filterSetting={JSON.parse(
                      JSON.stringify([...dbColumnList])
                    )}
                    onRestoreToDefault={this.onRestoreToDefault}
                    onApplySetting={this.onSettingChange}
                    openSlidingPanel={openSlidingPanel}
                    handleClose={this.handleClose}
                  />
                )}
              </div>
              <div className="col-md-9 col-lg-9">
                <div className="row">
                  <div className="col-md-6 col-lg-6">
                    <SearchBar
                      size="large"
                      name="searchInput"
                      value={searchInput || ""}
                      onChange={this.handleChange}
                      label="Search"
                      placeholder="Search Property ,Tenant , market, etc."
                      searchInput={searchInput}
                      companyMasterDetails={this.state.companyMasterDetails}
                          isOwner={true}
                          handleChange={this.handleSearchChange}
                        onInputChange={this.onSearchInputChange}
                        reloadData={this.resetAppliedFilter}
                    ></SearchBar>
                  </div>
                  <div className="col-md-3 col-lg-3"></div>
                  <div className="col-md-3 col-lg-3">
                    <ReportButtons
                      resetPage={[]}
                      openFilter={this.state.openFilter}
                      enableFilter={this.enableFilter}
                      handleSubmit={[]}
                      handleClickOpen={this.handleClickOpen}
                      handlePrintClick={this.handlePrintClick}
                      exportPDF={this.exportPDF}
                      data={leaseCompsSummaryData}
                      columnWidths={columnWidths}
                      uploadColumns={uploadColumns}
                      fileName={"BuildingOwner.xlsx"}
                      downloadExcelReport={this.downloadReport}
                      enableFilterButton={this.state.enableFilterButton}
                      ParentSection={SECTIONS.Industrial_Lease_Comps}
                      ChildSectionForDownload={
                        SECTIONS.Industrial_Lease_comp_Download
                      }
                      ChildSectionForPrint={
                        SECTIONS.Industrial_Lease_Comp_Print
                      }
                      ChildSectionForSettings={
                        SECTIONS.Industrial_Lease_Comp_Settings
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            {leaseCompsSummaryData && listOfColumns && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PageFilter
				   onInputChange={this.onInputChange}
                    resetFilters={this.resetFilters}
                    autoFilter={true}
                    openAppliedFilterPane={true}
                    filterSelectedData={this.state.filterSelectedData}
                    filterApplied={true}
                    filterAlwaysVisible={openFilter}
                    openFilter={true}
                    onFilterChanged={this.onFilterChange}
                    filterConfig={[
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: [
                          ...new Map(
                            getSubMarketMaster.map((item) => [
                              item["subMarket"],
                              item,
                            ])
                          ).values(),
                        ],
                        key: "subMarket",
                        placeholder: "",
                        title: "Sub Market",
                        isVisible: this.handleCheck("subMarket"),
                      },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: getAllLeaseType,
                        key: "leaseType",
                        placeholder: "",
                        title: "Negotiation Type",
                        isVisible: this.handleCheck("leaseType"),
                      },
                      // {
                      //   multiple: true,
                      //   type: controlType.NumericRange,
                      //   key: "rsf",
                      //   placeholder: "",
                      //   title: "SF Leased",
                      //   isVisible: this.handleCheck("rsf"),
                      //   operator: [
                      //     Operators.GreaterThanEqualsTo,
                      //     Operators.LessThanEqualsTo,
                      //   ],
                      // },
                      // {
                      //   multiple: false,
                      //   type: controlType.Autofill,
                      //   minTag: 1,
                      //   source: companyMasterDetails,
                      //   key: "companyName",
                      //   placeholder: "",
                      //   title: "Tenant Name",
                      //   isVisible: this.handleCheck("companyName"),
                      // },
                      // {
                      //   multiple: false,
                      //   type: controlType.Autofill,
                      //   source: getBuildingMaster,
                      //   key: "buildingName",
                      //   placeholder: "",
                      //   title: "Property Name",
                      //   isVisible: this.handleCheck("buildingName"),
                      // }, 
                      {
                        multiple: true,
                        type: controlType.DateRangePicker,
                        key: "closedate",
                        placeholder: "",
                        title: "Close Date",
                        isVisible: this.handleCheck("closedate"),
                        operator: [
                          Operators.GreaterThanEqualsTo,
                          Operators.LessThanEqualsTo,
                        ],
                      },
                      {
                        multiple: true,
                        type: controlType.DateRangePicker,
                        key: "lcd",
                        placeholder: "",
                        title: "Lease Commencement Date",
                        isVisible: this.handleCheck("lcd"),
                        operator: [
                          Operators.GreaterThanEqualsTo,
                          Operators.LessThanEqualsTo,
                        ],
                      },
                      {
                        multiple: true,
                        type: controlType.DateRangePicker,
                        key: "lxDte",
                        placeholder: "",
                        title: "Lease Expiration Date",
                        isVisible: this.handleCheck("lxDte"),
                        operator: [
                          Operators.GreaterThanEqualsTo,
                          Operators.LessThanEqualsTo,
                        ],
                      },
                      
                    //  {
                    //    multiple: true,
                    //    type: controlType.DropDown,
                    //    source: getAllCompType,
                    //    key: "compType",
                    //    placeholder: "",
                    //    title: "Comp Type",
                    //    isVisible: this.handleCheck("compType"),
                    //  },
                     
                      // {
                      //   multiple: true,
                      //   type: controlType.DropDown,
                      //   source: getAllDirectSubLease,
                      //   key: "directSublease",
                      //   placeholder: "",
                      //   title: "Direct/Sublease",
                      //   isVisible: this.handleCheck("directSublease"),
                      // },
                                         
                      
                     
                     
                      // {
                      //   multiple: true,
                      //   type: controlType.DropDown,
                      //   source: getAllCostarSubMarket,
                      //   key: "coStarSubmarket",
                      //   placeholder: "",
                      //   title: "Costar Submarket",
                      //   isVisible: this.handleCheck("coStarSubmarket"),
                      // },
                      // {
                      //   multiple: true,
                      //   type: controlType.DropDown,
                      //   source: getAllCostarBuildingClass,
                      //   key: "coStarBuildingClass",
                      //   placeholder: "",
                      //   title: "Costar Building Class",
                      //   isVisible: this.handleCheck("coStarBuildingClass"),
                      // },
                      
                      // {
                      //   multiple: true,
                      //   type: controlType.NumericRange,
                      //   key: "baseRentInitial",
                      //   placeholder: "",
                      //   title: "Initial Base Rent ($/SF)",
                      //   isVisible: this.handleCheck("baseRentInitial"),
                      //   operator: [
                      //     Operators.GreaterThanEqualsTo,
                      //     Operators.LessThanEqualsTo,
                      //   ],
                      // },
                    ]}
                    defaultSelectedData={[]}
                    selectedData={[]}
                    gridColumn={4}
                  ></PageFilter>
                  {/* <Button className="btn-clear" onClick={this.resetPage}>
                          Clear All
                        </Button> */}
                </div>
              </div>
            )}

            {leaseCompsSummaryData && userConfig.length > 0 && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PaginatedGridTable
                    validationMessage={[]}
                    rowCount={this.state.rowsPerPage > 0 ? this.state.rowsPerPage : 20}
                    
                    columns={UpdateColumnWithConfiguration(
                      userConfig,
                      this.state.configuration,
                      []
                    )}
                    selectedEditRowId={this.state.selectedEditRowId}
                    data={leaseCompsSummaryData}
                    renderActionColumn={this.renderActionColumn}
                    reload={this.state.reloadTable}
                    config={userConfig}
                    AddNewRecord={{
                      action: this.onAddNewClick,
                      title: "Add Lease Comps",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Lease_Comps,
                        SECTIONS.Industrial_Lease_Comp_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    additionalAction={{
                      action: this.enableDownload,
                      title: "Import Lease Comps",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Lease_Comps,
                        SECTIONS.Industrial_Lease_Comp_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    ConvertToPOI={{
                      action: this.onUnmappedLoanDetailsClick,
                      title: "Show Unmapped comp details",
                      visible: IsSectionVisible(
                        SECTIONS.Lease_Comps,
                        SECTIONS.Lease_Comp_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                    reloadPaginatedData={this.reloadPage}
                    pageNumber={this.state.pageNumber}
                  ></PaginatedGridTable>
                </div>
              </div>
            )}

        {this.state.openaddform == true && (
              <Dialog open={this.state.openaddform} maxWidth="lg">
                <AppBar
                  style={{ position: "relative", backgroundColor: "#064473" }}
                >
                  <Toolbar className="custom-toolbar">
                    <Typography
                      variant="subtitle1"
                      className="app-header-white"
                      style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                    >
                      {"Unmapped Comp Details"}
                    </Typography>
                    <IconButton
                      edge="start"
                      color="inherit"
                      onClick={() => {
                        this.setState({
                          openaddform: false,                          
                        });
                        this.reloadData();
                      }}
                      aria-label="close"
                      style={{ padding: "0px" }}
                    >
                      <IoMdCloseCircleOutline />
                    </IconButton>
                  </Toolbar>
                </AppBar>
                <div className="row">
                  <div className="col-sm-2 col-md-3 col-lg-3"></div>
                  <div className="col-sm-4 col-md-4 col-lg-4">

                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-2 end-sm"></div>

                </div>
                <div className="row">
                  <div className="col-sm-12 col-lg-12">
                    <UnmappedComps />
                  </div>
                </div>
              </Dialog>
            )}

          </>
        )}
      </>
    );
  }
}

import React, { Component } from "react";
import * as d3 from "d3";
import { BARCHART_MAX_WIDTH } from "../../../constants/graph";
import "./index.less";
import { getFormattedCellData } from "../../../utils/currency";
import { DATA_FORMAT } from "../../../constants/constants";

function isAllChartDataNonZero(arr = []) {
	let isAllzero = true;
	arr.forEach(bar => {
		if (bar.value !== 0) {
			isAllzero = false;
		}
	});
	return isAllzero;
}
class BarChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// isChartEmpty: false
		};
	}

	componentDidMount() {
		this.createBarChart();
	}

	createBarChart = () => {
		const { height, data, colors, yLabel, precededBarText = "" } = this.props;
		const margin = { top: 20, right: 20, bottom: 20, left: 70 };
		const windowWidth = window.innerWidth;
		const calculatedWidth =
			(windowWidth - 40) / 2 - (margin.left + margin.right);
		const width =
			calculatedWidth > BARCHART_MAX_WIDTH
				? BARCHART_MAX_WIDTH
				: windowWidth > 1023
				? calculatedWidth
				: BARCHART_MAX_WIDTH;
		const node = this.node;

		if (!data || !node) return;

		if (node !== null) {
			while (node.firstChild) {
				node.removeChild(node.firstChild);
			}
		}

		const percentFormat = d3.format(".0%");
		const color = d3.scaleOrdinal(colors);

		const x = d3
			.scaleBand()
			.rangeRound([0, width])
			.padding(0.55);
		const y = d3.scaleLinear().rangeRound([height, 0]);

		const svg = d3
			.select(node)
			.append("svg")
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left + 10},${margin.top})`);

		const xAxis = d3.axisBottom(x);
		const yAxis = d3.axisLeft(y);

		x.domain(data.map(d => d.label));
		y.domain([0, d3.max(data, d => d.value)]).nice();

		svg
			.append("g")
			.attr("class", "x-axis")
			.attr("transform", `translate(0,${height})`)
			.call(xAxis);

		svg
			.append("g")
			.attr("class", "y-axis")
			.call(yAxis)
			.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", "-70")
			.attr("x", 0 - height / 2)
			.style("text-anchor", "middle")
			.style("fill", "#000000")
			.text(yLabel);

		svg
			.selectAll(".bar")
			.data(data)
			.enter()
			.append("rect")
			.attr("class", "bar")
			.attr("fill", (d, i) => color(i).barColor)
			.attr("x", d => x(d.label))
			.attr("width", x.bandwidth())
			.attr("y", d => y(d.value))
			.attr("height", d => height - y(d.value));

		// Add labels on top of bar chart
		svg
			.selectAll(".text")
			.data(data)
			.enter()
			.append("text")
			.attr("class", "bar-label")
			.attr("fill", (d, i) => color(i).textColor)
			.style("text-anchor", "middle")
			.attr("x", d => x(d.label) + x.bandwidth() / 2)
			.attr("y", d => y(d.value) - 4)
			.text(
				d =>
					`${precededBarText}${getFormattedCellData(
						Number(d.value).toFixed(2),
						DATA_FORMAT.NumberFormat,
						true
					)}`
			);
	}
	componentDidMount() {
		this.createBarChart();
	}
	componentDidUpdate() {
		this.createBarChart();
	}
	componentWillUnmount() {
		d3.select(this.node).remove();
	}
	render() {
		const { chartTitle = "", data, NoDataMsg = "No Data Available" } = this.props;
		return (
			<>
				{!isAllChartDataNonZero(data) ? (
					<>
						<div className="chartBox" ref={node => (this.node = node)} />
						<div className="chartTitle1">{chartTitle}</div>
					</>
				) : (
					<div className="no-data-found-block">{NoDataMsg}</div>
				)}
			</>
		);
	}
}

export default BarChart;

import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
import { Tooltip, IconButton } from '@material-ui/core';
import { pure } from 'recompose';
function SectionHeader ({ title, actionsConfig, classNames }) {
  return (
    <div className="row sectionheader-container start-xs pageheader-padding">
      <div className="col-xs-auto sectionheader">{title}</div>
      <div className="col-xs sectionheader pull-right end-xs" key={'container-action-'} >
          {actionsConfig && actionsConfig.map((action, index) => {
            return (<>
              <IconButton
                 key={'container-action-IC-' + index}
                      aria-label={'newWidget'}
                      size="small"
                      onClick={action.onClick}
                    >
                      {action.icon}
                    </IconButton>
                    <span key={'container-action-span-' + index} className="action-label">{action.name}</span>
                </>);
          })}
         </div>
    </div>
  );
}
SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actionsConfig: PropTypes.array
};
export default pure(SectionHeader);

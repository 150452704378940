import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import '../../../utils/date';
import './DatePicker.less';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';

class DatePicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      // startDate: this.props.startDate,
      // endDate: this.props.endDate,
      sDate: new moment().startOf('month').format('MM/DD/YYYY'),
      eDate: new moment().endOf('month').format('MM/DD/YYYY'),
      //dateSrting: this.props.startDate + ' - ' + this.props.endDate,
      dateSrting:  new moment().startOf('month').format('MM/DD/YYYY') + ' - ' + new moment().endOf('month').format('MM/DD/YYYY'),
      datePickers: {},
    };
    this.handleEvent = this.handleEvent.bind(this);
  }

  static getDerivedStateFromProps (newpros, state) {
    
    if (
      newpros.startDate !== state.startDate ||
      newpros.endDate !== state.endDate
    ) {
      return {
        startDate: newpros.startDate,
        endDate: newpros.endDate,
        dateSrting: newpros.startDate + ' - ' + newpros.endDate
      };
    }
    return null;
  }

  convertDateToFormat (today) {
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today;
  }

  handleEvent (event, picker) {
    const FormatedStartDate = this.convertDateToFormat(picker.startDate._d);
    const FormatedEndDate = this.convertDateToFormat(picker.endDate._d);
    const StartEndDate = FormatedStartDate + ' - ' + FormatedEndDate;
    console.log(StartEndDate);
    if (this.state.dateSrting !== StartEndDate) {
      this.setState({
        sDate: FormatedStartDate,
        eDate: FormatedEndDate,
        dateSrting: StartEndDate
      },
      () => {
        this.props.onDateChange(FormatedStartDate, FormatedEndDate);
        this.forceUpdate();
      }
      );
     
    }
  }

  render () {
    return (
      <DateRangePicker
        startDate={this.state.sDate}
        endDate={this.state.eDate}
        onEvent={this.handleEvent}
        showDropdowns={true}
        key={'dRP_filter_' + this.props.componentId}
        focusedInput={this.state.datePickers[this.props.componentKey]?.focusedInput}
      >
        <input
          type="text"
          name={"daterange"+this.props.componentId}
          className="date-picker-range"
          style={{width:"100%",border:"1px solid #C4C4C4"}}
          placeholder="Select Date Range"
          value={this.state.dateSrting}
          key={'dateRangePicker_filter_input_' + this.props.componentKey}
          id={'dateRangePicker_filter_input_' + this.props.componentId}
        />{' '}
      </DateRangePicker>
    );
  }
}
DatePicker.propTypes =
{
  //startDate: PropTypes.string.isRequired,
  //endDate: PropTypes.string.isRequired,
  onDateChange: PropTypes.func.isRequired,
  componentKey: PropTypes.number,
  componentId: PropTypes.number,
};
export default DatePicker;

import React, { useState } from "react";
import cx from "classnames";
import { connect } from "react-redux";
// import {
// 	FcCheckmark,
// 	MdClose,
// 	FaWindowMinimize,
// 	FaRegWindowRestore
// } from "../../../constants/icons";
import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import DoneIcon from "@material-ui/icons/Done";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MaximizeIcon from "@material-ui/icons/Maximize";
import CloseIcon from "@material-ui/icons/Close";
import {
  FaWindowMinimize,
  FaRegWindowRestore,
  FaInfoCircle,
} from "react-icons/fa";
import { FcCheckmark } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

//FaWindowMinimize
import { setDownloaderVisibility } from "../../../store/loader/actions";
import DownloadAnim from "../download-anim";
import "./index.less";
import { DOWNLOAD_STATUS } from "../../../constants/constants";
import { Timer } from "../Timer";
import { Button, IconButton, Tooltip } from "@material-ui/core";

function getDownloadStatusIcon(status = "") {
  switch (status) {
    case DOWNLOAD_STATUS.completed:
      return <FcCheckmark size={25} />;
    case DOWNLOAD_STATUS.failed:
      return <MdClose size={25} color="red" />;
    case DOWNLOAD_STATUS.downloading:
      return <DownloadAnim />;
    case DOWNLOAD_STATUS.uploading:
      return <DownloadAnim type="uploading" />;
    case DOWNLOAD_STATUS.processing:
      return (
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ); //<AiOutlineLoading3Quarters />;
    default:
      return <DownloadAnim />;
  }
}

function getTimerStatus(status = "") {
  switch (status) {
    case DOWNLOAD_STATUS.completed:
      return true;
    case DOWNLOAD_STATUS.failed:
      return true;
    case DOWNLOAD_STATUS.downloading:
      return false;
    case DOWNLOAD_STATUS.uploading:
      return false;
    default:
      return false;
  }
}

function getStatusIcon(message = "") {
  if (message) {
    if (
      message.includes("SP-api.ImportLeaseCompOffice") ||
      message.includes("SP-api.ImportSalesCompOffice") ||
      message.includes("SP-api.ImportMarketstatsOffice") ||
      message.includes("dbo.Building") ||
      message.includes("dbo.")
    ) {
      message = message
        .replace("SP-api.ImportLeaseCompOffice", "database object. ")
        .replace("SP-api.ImportSalesCompOffice", "database object. ")
        .replace("SP-api.ImportMarketstatsOffice", "database object. ")
        .replace("dbo.Building", "Property")
        .replace("dbo.", "");
    }
    return (
      <Tooltip
        placement="left"
        style={{ zIndex: "99999999 !important" }}
        title={
          <>
          <div style={{ whiteSpace: 'pre-line' }}><h3>{message}</h3></div>
          </>
        }
      >
        <IconButton edge="start" size="small">
          <FaInfoCircle color="red" />
        </IconButton>
      </Tooltip>
    );
    // return (
    // 	<>
    // 		<div className="tooltip-on-hover"><FaInfoCircle /></div>
    // 		<div className="tooltip">{message}</div>
    // 	</>
    // )
  }
}

export default function DownloadProgress({
  downloadingFiles,
  showDownlodProgress,
  setDownloaderVisibility,
}) {
  const [isMinimize, setIsMinimize] = useState(false);

  return (
    <>
      {downloadingFiles.length && showDownlodProgress ? (
        <div className={cx("download-progress", isMinimize && "minimizeBlock")}>
          <div className="row download-header">
            <div className="col-xs-10">
              <span>Request Status</span>
            </div>
            <div className="col-xs-1 end-xs">
              {!isMinimize ? (
                <span
                  onClick={() => setIsMinimize(!isMinimize)}
                  className="minimizeIcon"
                >
                  <FaWindowMinimize />
                </span>
              ) : (
                <span onClick={() => setIsMinimize(!isMinimize)}>
                  <FaRegWindowRestore />
                </span>
              )}
            </div>
            <div className="col-xs-1 end-xs">
              <span onClick={() => setDownloaderVisibility(false)}>
                <MdClose />
              </span>
            </div>
          </div>
          {!isMinimize ? (
            <div className="download-queue">
              {downloadingFiles.map(
                ({ dKey, fileName, status, errorMessage, statusMessage }) => (
                  <div className="row dInfo" key={dKey}>
                    <div className="col-xs-9">
                      <span>{fileName}</span>
                      <br />{" "}
                      <span style={{ fontWeight: "400" }}>{statusMessage}</span>
                    </div>
                    <div className="col-xs-1">
                      <span>{getStatusIcon(errorMessage)}</span>
                      {/* <Timer stopTimer={getTimerStatus(status)}/> */}
                    </div>
                    <div className="col-xs-2 center-xs">
                      {getDownloadStatusIcon(status)}
                    </div>
                  </div>
                )
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

// const mapStateToProps = state => {
// 	const {
// 		loaderConfig: { downloadingFiles, showDownlodProgress }
// 	} = state;
// 	return {
// 		downloadingFiles: downloadingFiles.slice(0, 4),
// 		showDownlodProgress
// 	};
// };

// const mapDispatchToProps = dispatch => {
// 	return {
// 		setDownloaderVisibility: param => dispatch(setDownloaderVisibility(param))
// 	};
// };

// export default connect(
// 	mapStateToProps,
// 	mapDispatchToProps
// )(DownloadProgress);

export default {
  ResetToDefault: {
    message: 'Are you sure you want to reset the dashboard to its default state? All the customized widget will be removed.'
  },
  widgetConfigurationUpdateSuccess: {
    message: 'Configuration updated successfully.', code: 10000
  },
  sectionPermissionsurationUpdateSuccess: {
    message: 'Permissions updated successfully.', code: 10000
  },
  sectionPermissionsurationUpdateFailed: {
    message: 'Unable to update permissions.', code: 10000
  },
  widgetConfigurationAddedSuccess: {
    message: 'Widget added successfully.', code: 10000
  },
  widgetConfigurationUpdateFail: {
    message: 'Unable to update widget configuration.', code: 500
  },
  widgetConfigurationAddFail: {
    message: 'Unable to save widget configuration.', code: 500
  },
  settingNotUpdated :'Server Error ! Settings not updated successfully',
  settingUpdated :'Settings updated successfully',
  settingRestored:'Settings restored successfully',
  settingNotRestored :'Server Error ! Settings not restored successfully',
  somethingWrong: { message: 'Something went wrong...', code: 500 },
  browserNotSupported: {
    message:
      'browser is not supported. Upgrade to the latest version of Chrome, Edge, or Mozilla.',
    code: 1003
  },
  noChange: { message: 'No changes made in data...', code: 1005 },
  valueMustBeSelected: { message: 'Value must be selected!', code: 1006 },
  alreadyExists: { message: 'already exists', code: 1008 },
  pageNotFound: {
    heading: 'Oops! Page Not Found',
    message:
      'Sorry but the page you are looking for does not exist, have been removed. name changed or is temporarily unavailable',
    code: 404,
    goToHomeEnabled: true
  },
  serverError: {
    heading: 'Oops! Something went wrong',
    message:
      'Sorry something went wrong at server side, either refresh the page or try after some time. If issue still persist please contact your site administrator.',
    code: 500,
    goToHomeEnabled: true
  },
  unAuthorizedAccess: {
    heading: 'Unable to Access',
    message:
      'Sorry it seems you are not able to access portal/resources, please contact site administrator.',
    code: 403,
    goToHomeEnabled: false
  },
  sessionTimeOut: {
    heading: 'Session Timeout',
    message:
      'Sorry it seems your session is no more active, please sign in again.',
    code: 401,
    goToHomeEnabled: true
  },
  sectionPermissionUpdateSuccess: { message: 'Permissions updated successfully.', code: 10000 }
};

import React from 'react'
// import { css,cx,caches } from "../../../create-nonce";
import './index.less'
// import PropagateLoader from "react-spinners/PropagateLoader";
import cx from 'classnames'
// import { StyleSheet } from '@emotion/sheet'
// import { CacheProvider  } from '@emotion/react'
// import createCache from '@emotion/cache'

// Can be a string as well. Need to ensure each key-value pair ends with ;
// const override = css`
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   z-index: 99999;
//   display: block;
//   margin: 0 auto;
//   text-align: center;
// `;
export const LOADER_COLOR = '#033967'
export default function Loader ({
  loading}) {
  return (
  // <CacheProvider value={caches}>
      <div className={cx('sweet-loading', !loading && 'hide')}>
      {/* {!message.length ? (
        <PropagateLoader
          css={override}
          size={22}
          // margin={4}
          // //size={"150px"} this also works
          height={35}
          width={3}
          radius={2}
          margin={2}
          color={loaderColor}
          loading={loading}
        />
      ) : (
        <div className="message">{message}</div>
      ) } */}
      <div className="loader-inner"></div>

      <div className="loader-bg" />
    </div>
    // </CacheProvider>
  )
}

import React from "react";
import "./fundwise.less";
import {
  apiBatchRequest,
  apiDelete,
} from "../../utils/api_service";
import {
  downloadExcelReportByAPI,
  removeTableColumn,
  scrollToTop,
} from "../../utils/generic";
import { API_URL } from "../../constants/api";
import FundWiseLandingView from "./fundwise-landing-view";
import AddSpecSuite from "./addSpec";
import { ShowToast, toastType } from "../../utils/toast-service";
import ConfirmPopup from "../common/confirm-popup";
import { AppBar, Dialog, IconButton, Toolbar, Typography } from "@material-ui/core";
import { IoMdCloseCircleOutline } from "react-icons/io";

let deleteRowId = "";

class FundWisePerformance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fundWiseBoxData: [],
      summaryTableData: [],
      assetSummaryTableData: [],
      editedRowData: {},
      isOpenAddPopUp: false,
      popupType: "spec",
      popupConfig: {
        isOpenPopup: false,
        header: "Delete Confirmation",
        confirmMsg: "Are you sure to delete?",
        onSubmit: "",
      },
      specSuitesTableColumns: [],
	  searchInput: this.props.searchInput,
    };
  }

  componentDidMount() {
    const { AssetTableColumns, userHasEditPermission } = this.props;
    this.setState({
      specSuitesTableColumns: userHasEditPermission
        ? AssetTableColumns
        : removeTableColumn(AssetTableColumns, { accessor: "actionBlock" }),
    });
    scrollToTop();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.marketId.length !== this.props.marketId.length ||
      nextProps.uploadingCount.length !== this.props.uploadingCount.length
    ) {
      this.getSpecSuiteData(nextProps.marketId);
    }
    if (nextProps.searchInput != "")
      this.globalSearch(nextProps.searchInput);
  }

  getSpecSuiteData = (marketId = []) => {
    if (!marketId || (marketId && marketId.length < 1)) {
      this.setState({ summaryTableData: [] }, ()=>{this.forceUpdate()});
      return;
    }
    this.setState({ summaryTableData: [] });
    apiBatchRequest([
      {
        url: API_URL.SpecSuites.assetSummaryTable,
        params: { marketIds: marketId.toString() },
        key: "assetSummaryData",
      },
    ]).then(({ assetSummaryData }) => {
      assetSummaryData.data &&
        this.setState({
          summaryTableData: assetSummaryData.data ? assetSummaryData.data : [],
		  assetSummaryTableData: assetSummaryData.data ? assetSummaryData.data : []
        });
    });
    // .catch((err) => this.setState({ isLoading: false }));
  };
    
  downloadExcel = () => {
    const { marketId, subMarketId, propertyId,allMarket } = this.props;

    const mId =allMarket.length > 0 ? -1 :  marketId
      .toString()
      .replaceAll(",", "|");

    let SelectedData = [];
    if (propertyId && propertyId.length) {
      
      //let temp = propertyId.indexOf(",") == -1? new Array(propertyId) : propertyId.split(",");
      let temp = propertyId;
      let selectedProps = [];
      for (var i = 0; i < temp.length; i++) {
        selectedProps.push({
          buildingId: temp[i],
          buildingName: "",
          buildingLeavingId: 0,
          buildingLeavingName: ""
        });
      }
      let propObj = {
        key: "buildingName",
        type: "Dropdown",
        isComaSeprated: false,
        selected: selectedProps
      }
      SelectedData.push(propObj);

    }
    if (subMarketId && subMarketId.length) {
      //let temp = subMarketId.indexOf(",") == -1? subMarketId : subMarketId.split(",");
      let temp = subMarketId;
      let selectedProps = [];
      for (var i = 0; i < temp.length; i++) {
        selectedProps.push({
          subMarketId: temp[i],
          subMarket: "",
          market: "",
          marketId: ""
        });
      }
      let propObj = {
        key: "subMarket",
        type: "Dropdown",
        isComaSeprated: false,
        selected: selectedProps
      }
      SelectedData.push(propObj);
    }
    
    downloadExcelReportByAPI(
        API_URL.SSRSExport.SpecSuite,
        "SpecSuiteExport.xls",
        SelectedData,
        mId,
        "application/vnd.ms-excel",
        "specsuite"
      );
    
    
  };


  onOpenPopup = (type = "", flag = true) => {
    this.setState({ isOpenAddPopUp: flag, editedRowData: {}, popupType: type });
  };

  rowEditAction = (op, data) => {
    if (op === "delete") {
      data && this.deleteRecord(data.leaseSpecId);
    } else if (op === "edit") {
      this.setState({
        editedRowData: data,
        isOpenAddPopUp: true,
        popupType: "spec",
      });
    }
  };

  getSpesuiteTableData = () => {
    this.onOpenPopup("", false);
    this.getSpecSuiteData(this.props.marketId);
  };

  deleteRecord = (id = "") => {
    deleteRowId = id;
    this.closeModal(true);
  };

  callDelete = () => {
    apiDelete({
      url: `${API_URL.SpecSuites.deleteSpecLease}`,
      params: { id: deleteRowId },
    })
      .then((response) => {
        if (response.data.success) {
          ShowToast("Record Deleted Successfully.", toastType.SUCCESS);
          this.getSpesuiteTableData();
          this.closeModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeModal = (flag) => {
    this.setState({
      popupConfig: { ...this.state.popupConfig, isOpenPopup: flag },
    });
  };

  handleCheck(val) {
    return this.state.specSuitesTableColumns.some((item) => item.accessor === val);
  }

  globalSearch = (searchInput) => {
	//let { searchInput } = this.props;
	let searchData = this.state.assetSummaryTableData;
	let filteredData = searchData.filter((value) => {
	  return (
		(this.handleCheck("propertyName") && value.propertyName
		  ? value.propertyName
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("buildingRSF") && value.buildingRSF
		  ? value.buildingRSF
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("class") && value.class
		  ? value.class
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("compTypeDisplayValue") && value.compTypeDisplayValue
		  ? value.compTypeDisplayValue
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("costPsf") && value.costPsf
		  ? value.costPsf
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("constructionMonths") && value.constructionMonths
		  ? value.constructionMonths
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("completionToLCD_Value") && value.completionToLCD_Value
		  ? value.completionToLCD_Value.toLowerCase().includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("downtimeValue") && value.downtimeValue
		  ? value.downtimeValue
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("monthsVacantPrior") && value.monthsVacantPrior
			? value.monthsVacantPrior.toString().toLowerCase().includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("elevatorLobbyExposurer") && value.elevatorLobbyExposurer
		  ? value.elevatorLobbyExposurer
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("constructionType") && value.constructionType
		  ? value.constructionType
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("priorPremisesCondition") && value.priorPremisesCondition
		  ? value.priorPremisesCondition
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("relocationReasonDisplayValue") && value.relocationReasonDisplayValue
		  ? value.relocationReasonDisplayValue
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		  (this.handleCheck("marketName") && value.marketName
		  ? value.marketName
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("subMarket") && value.subMarket
		  ? value.subMarket
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("preWired") && value.preWired
		  ? value.preWired
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("furnished") && value.furnished
		  ? value.furnished
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("percentageOfficevsOpenDisplayValue") && value.percentageOfficevsOpenDisplayValue
		  ? value.percentageOfficevsOpenDisplayValue
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("rapidUtilized") && value.rapidUtilized
		  ? value.rapidUtilized
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("led") && value.led
		  ? value.led
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("lcd") && value.lcd
		  ? value.lcd
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("type") && value.type
		  ? value.type
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("tenantRSF") && value.tenantRSF
		  ? value.tenantRSF
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("termsInMonth") && value.termsInMonth
		  ? value.termsInMonth
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("startingRate") && value.startingRate
		  ? value.startingRate
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("quotedRate") && value.quotedRate
		  ? value.quotedRate
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("nnnPerFS") && value.nnnPerFS
		  ? value.nnnPerFS
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		(this.handleCheck("annualEscalation") && value.annualEscalation
		  ? value.annualEscalation
			  .toString()
			  .toLowerCase()
			  .includes(searchInput.toLowerCase())
		  : false) ||
		  (this.handleCheck("annualEscalationType") && value.annualEscalationType
			? value.annualEscalationType
				.toString()
				.toLowerCase()
				.includes(searchInput.toLowerCase())
			: false) ||
			(this.handleCheck("freeRentInMonths") && value.freeRentInMonths
			  ? value.freeRentInMonths
				  .toString()
				  .toLowerCase()
				  .includes(searchInput.toLowerCase())
			  : false) ||
			  (this.handleCheck("additionalTI") && value.additionalTI
				? value.additionalTI
					.toString()
					.toLowerCase()
					.includes(searchInput.toLowerCase())
				: false)
	  );
	  // }
	});
	//if(this.state.summaryTableData.length > 0)
		this.setState({ summaryTableData: filteredData });
  };

  handleCheck(val, isFilter) {
    return this.state.specSuitesTableColumns.some((item) => item.accessor === val);
  }

  render() {
    const {
      //  fundsNameList,
      userHasEditPermission,
      marketId,
      subMarketId,
      propertyId,
      propertyInfo,
      getAllPropertyList,
      ddMasterData,
      propertyMasterData,
      marketMasterData,
      subMarketMasterData,
      industryMasterData,
      percentageOfficevsMaster,
      priorPremisesMaster,
      constructionTypeMaster,
      nnnPerFSMaster,
      relocationReasonMaster,
      downtimeMaster,
      compTypeMaster,
    } = this.props;

    const {
      summaryTableData = [],
      isOpenAddPopUp,
      editedRowData,
      popupType,
      popupConfig: { header, confirmMsg, isOpenPopup },
      specSuitesTableColumns,
    } = this.state;

    const filterOnSubMarket =
      subMarketId && subMarketId.length && summaryTableData
        ? summaryTableData.filter(
            (x) => subMarketId.indexOf(x.subMarketId) !== -1
          )
        : summaryTableData;
    const filterSummaryData =
      propertyId && propertyId.length && filterOnSubMarket
        ? filterOnSubMarket.filter(
            (x) => propertyId.indexOf(x.propertyId) !== -1
          )
        : filterOnSubMarket;

    return (
      <div className="tabBoxContainer">
        {isOpenPopup && (
          <ConfirmPopup
            header={header}
            confirmMsg={confirmMsg}
            openModel={isOpenPopup}
            closeModal={() => this.closeModal(false)}
            onModelSubmit={this.callDelete}
          />
        )}

        <FundWiseLandingView
          columnList={specSuitesTableColumns}
          onRowClick={this.onRowClick}
          tableData={filterSummaryData}
          //  fundsNameList={fundsNameList}
          userHasEditPermission={userHasEditPermission}
          showPortfolioLanding
          onOpenPopup={this.onOpenPopup}
          downloadExcel={
            marketId &&
            marketId.length &&
            filterSummaryData &&
            filterSummaryData.length
              ? this.downloadExcel
              : ""
          }
          showAddRecord={
            userHasEditPermission && propertyInfo && propertyInfo.length === 1
          }
          propertyInfo={propertyInfo}
          showAddProperty={userHasEditPermission}
          showPropertyFilter={userHasEditPermission}
          NoDataInitial={marketId}
          rowEditAction={this.rowEditAction}
        />
        {isOpenAddPopUp && (
			<Dialog open={this.state.isOpenAddPopUp} maxWidth="md">
			<AppBar
				style={{ position: "relative", backgroundColor: "#064473" }}
			>
				<Toolbar className="custom-toolbar">
					<Typography
						variant="subtitle1"
						className="app-header-white"
						style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
					>
						{editedRowData.propertyId ? "Update Spec Suite Information" : "Add Spec Suite Information"}	{/*{this.state.addformtitle}*/}
					</Typography>
					<IconButton
						edge="start"
						color="inherit"
						onClick={() => {
							this.onOpenPopup("", false);
						}}
						aria-label="close"
						style={{padding:"0px"}}
					>
						<IoMdCloseCircleOutline />
					</IconButton>
				</Toolbar>
			</AppBar>
          {/*<Popup
            open={isOpenAddPopUp}
            closeOnDocumentClick={false}
            onClose={() => this.onOpenPopup("", false)}
            contentStyle={{ width: "90% !important" }}
            className="performa-popup"
          >*/}
		  <div>
            <AddSpecSuite
              popupTitle={`${
                editedRowData.propertyId ? "Update" : ""
              } Spec Suite Information`}
              editedRowData={editedRowData}
              onOpenPopup={this.onOpenPopup}
              propertyId={propertyId}
              getSpesuiteTableData={this.getSpesuiteTableData}
              isShowAddProeprty={popupType === "property"}
              getAllPropertyList={getAllPropertyList}
              ddMasterData={ddMasterData}
              propertyMasterData={propertyMasterData}
              marketMasterData={marketMasterData}
              subMarketMasterData={subMarketMasterData}
              industryMasterData={industryMasterData}
              percentageOfficevsMaster={percentageOfficevsMaster}
              priorPremisesMaster={priorPremisesMaster}
              constructionTypeMaster={constructionTypeMaster}
              nnnPerFSMaster={nnnPerFSMaster}
              relocationReasonMaster={relocationReasonMaster}
              downtimeMaster={downtimeMaster}
              compTypeMaster={compTypeMaster}
              propertyInfo={
                propertyInfo && propertyInfo.length && propertyInfo[0]
              }
              isMarketEditable={editedRowData.propertyId ? true : false}
            />
			</div>
          {/*</Popup>*/}
		  </Dialog>
        )}
      </div>
    );
  }
}

export default FundWisePerformance;

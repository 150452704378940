import { Button, Chip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { getColumnControl, getFormattedData } from '../../../utils/formatData';
import FullScreenDialog from '../Dialog/Dialog';
import './Table.less';

function descendingComparator (a, b, orderBy) {
  // if(isNaN(a[orderBy]) && isNaN(b[orderBy]))
  if (typeof a[orderBy] === 'string' && typeof a[orderBy] === 'string') {
    const lowerCaseB = b[orderBy] != undefined ? b[orderBy].toString().toLowerCase() : b[orderBy];
    const lowerCaseA = a[orderBy] != undefined ? a[orderBy].toString().toLowerCase() : a[orderBy];
    if (lowerCaseB < lowerCaseA) {
      return -1;
    }
    if (lowerCaseB > lowerCaseA) {
      return 1;
    }
  } else if (!isNaN(b[orderBy]) && !isNaN(a[orderBy])) {
    const numA = Number(a[orderBy]).toFixed(2);
    const numB = Number(b[orderBy]).toFixed(2);
    if (Number(numB) < Number(numA)) {
      return -1;
    }
    if (Number(numB) > Number(numA)) {
      return 1;
    }
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  }
  return 0;
}
function getComparator (order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead (props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells,
    headcellClass,
    config,
    columnGrouping
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead style={{ top: props.topvalue + 'px',height:'39px' }}>
      <TableRow style={{background: "#e0e0e0 !important"}}>
        {(columnGrouping || []).map((group, i) => (
         <TableCell align="center" style={{ background: "#e0e0e0 !important", borderRight: '1px solid #e0e0e0',fontWeight: 'bold' }} className={group.headcellClass != undefined ? group.headcellClass : headcellClass || ''} colSpan={group.colSpan}>
         {group.name}
       </TableCell>
        ))}
        </TableRow>
        <TableRow>
          {(headCells || [])
            .filter((x) => x.showInEditOnly !== true)
            .map((headCell, i) => (
				headCell.renderControls === undefined
                ?
              <TableCell
                key={headCell.key}
                sortDirection={orderBy === headCell.key ? order : false}
                className={headCell.headcellClass != undefined ? headCell.headcellClass : headcellClass || ''}
                style={{
                  width:
                    (
                      props.updatedColumnsWidth.find(
                        (x) => x.key === headCell.key
                      ) || {}
                    ).width + 'px'
                }}
              >
             { headCell.name != 'Action' ? (<TableSortLabel
                  active={orderBy === headCell.key}
                  style={{
                    // textTransform: 'capitalize',
                    paddingLeft: (i === 0 && config.collapsible === true ? '20px' : undefined)
                  }}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                >
                  { headCell.name}
                  {orderBy === headCell.key
                    ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                      )
                    : null}
                </TableSortLabel>
             )
               : <span>{ headCell.name } </span>
             }
              </TableCell>

: headCell.renderControls !== undefined &&
<TableCell
  key={headCell.key + headCell.keyTag ?? ''}
  sortDirection={orderBy === headCell.key ? order : false}
  className={(headCell.headcellClass != undefined ? headCell.headcellClass : headcellClass || '') + (props.isSticky !== true ? ' no-sticky' : '')}
  style={{
	width: (headCell.minWidth
	  ? headCell.minWidth
	  : (
		props.updatedColumnsWidth.find(
		  (x) => x.key === headCell.key
		) || {}
	  ).width) + 'px'
  }}
>
  {getColumnControl(props.headerData, headCell, props.handleTableHeaderEditChange)}
</TableCell>	
            ))}
        </TableRow>
      </TableHead>
      {props.isSticky && (
        <TableHead id="staticHeader" className="normalheader">
          <TableRow>
            {(headCells || [])
              .filter((x) => x.showInEditOnly !== true)
              .map((headCell) => (
                <TableCell
                key={headCell.key}
                sortDirection={orderBy === headCell.key ? order : false}
                className={headCell.headcellClass != undefined ? headCell.headcellClass : headcellClass || ''}
                style={{
                  width:
                    (
                      props.updatedColumnsWidth.find(
                        (x) => x.key === headCell.key
                      ) || {}
                    ).width + 'px'
                }}
              >
                <div className="col-xs-12">
                <TableSortLabel
                  active={orderBy === headCell.key}
                  style={{
                    // textTransform: 'capitalize',
                    paddingLeft: (i === 0 && config.collapsible === true ? '20px' : undefined)
                  }}
                  direction={orderBy === headCell.key ? order : 'asc'}
                  onClick={createSortHandler(headCell.key)}
                >
                  {headCell.name}
                  {orderBy === headCell.key
                    ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                      )
                    : null}
                  </TableSortLabel>
                  </div>
              </TableCell>
              ))}
          </TableRow>
        </TableHead>
      )}
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array,
  headcellClass: PropTypes.string,
  isSticky: PropTypes.bool,
  updatedColumnsWidth: PropTypes.array,
  topvalue: PropTypes.number
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0
        ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
          )
        : (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
          )}

      {numSelected > 0
        ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
          )
        : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
          )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginTop: '5px'
  },
  paper: {
    width: '100%',
    marginBottom: '5px',
    marginTop: '5px',
    //marginLeft: '19px'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

subGridTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  config: PropTypes.object,
  selectedEditRowId: PropTypes.object,
  isInAddMode: PropTypes.bool,
  EditSource: PropTypes.array,
  handleTableEditChange: PropTypes.func,
  onRowClick: PropTypes.func,
  rowPerPage: PropTypes.func,
  rowCount: PropTypes.number,
  reloadRequired: PropTypes.bool,
  config: PropTypes.array,
  headerData: PropTypes.object,
  handleTableHeaderEditChange: PropTypes.func,
  reloadPaginatedData:PropTypes.func
};

export default function subGridTable (props) {
  // console.log(props);
  const {
    columns,
    data,
    config = {},
    selectedEditRowId,
    isInAddMode,
    EditSource,
    reloadPaginatedData,
    pageNumber
  } = props;
  
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(config.orderBy || 'nam');
  const [page, setPage] = React.useState(props.pageNumber);
  const [dense] = React.useState(true);
  const [openTagDataDialogConfig, setOpenTagDataDialogConfig] = React.useState({
    openTagDataDialog: true,
    title: '',
    children: undefined
  });

  const [rowsPerPage, setRowsPerPage] = React.useState(props.rowCount != undefined ? props.rowCount :20);
  const [rowsPerPageOptions] = React.useState([5, 10, 20,35, 50]);
  const [showPager] = React.useState(
    (config || {}).showPager !== undefined ? config.showPager : true
  );

  const viewTagData = (title, children) => {
    setOpenTagDataDialogConfig({
      openTagDataDialog: true,
      title,
      children
    });
  };
  const closeViewTagData = () => {
    setOpenTagDataDialogConfig({
      openTagDataDialog: false,
      title: '',
      children: undefined
    });
  };

  const [isSticky, setSticky] = useState(false);
  const [topvalue, setTopvalue] = useState(68);
  const [updatedColumnsWidth, setUpdatedColumnsWidth] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(undefined);
  const [selectedRow, setSelectedRow] = useState([]);
  const ref = useRef(null);
  const handleScroll = () => {
    // console.log(ref);
    const colwidth = [];
    // console.log(columns);
    if (props.StickyHeader) {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        setSticky(ref.current.getBoundingClientRect().top <= 63);
        if (top <= 63) {
          data &&
            data.length > 0 &&
            columns.forEach((col, index) => {
              // console.log(col.name, col);
              const width =
                ref.current.children[0].children[2].children[0].children[index]
                  .clientWidth;
              colwidth.push({ key: col.key, width: width });
            });

          const val = (top > 0 ? top : top * -1) + (top > 0 ? top - 63 : 63);
          if (parseInt(top) !== parseInt(val)) {
            setTopvalue(val > 0 ? val : 43);
          } else {
            setTopvalue(43);
          }
          // console.log(top, "|", val);
        } else {
          setTopvalue(undefined);
        }
        setUpdatedColumnsWidth(colwidth);

        // console.log(ref.current.getBoundingClientRect().top);
      }
    }
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  useEffect(() => {
    setPage(props.pageNumber);  
    setRowsPerPage(props.rowCount != undefined ? props.rowCount :20);
  }, [props.data]);

useEffect(()=>{
  setRowsPerPage(props.rowCount != undefined ? props.rowCount :20);
}, [props.rowCount]);

useEffect(()=>{
  setPage(0);
}, [props.config]);
  useEffect(() => {
    setPage(0);
    setForceUpdate(props.reload);
  }, [props.reload]);

  useEffect(() => {
    setPage(0);
  }, [props.EditSource]);

  useEffect(() => {
    setPage(props.pageNumber);    
  }, [props.pageNumber]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {  
    //console.log("=========event ", event);  
    //console.log("=========newPage newPage ", newPage);  
    //newPage = props.pageNumber; 
    //console.log("=========newPage ", newPage);  
    setPage(newPage);
    props.reloadPaginatedData(newPage,rowsPerPage);    
  };

  const handleChangeRowsPerPage = (event) => {
    
    setRowsPerPage(parseInt(event.target.value, 10));
    props.reloadPaginatedData(0,event.target.value);
    setPage(0);
  };


  const getGroupedData = (data, key) => {
    // data = stableSort(
    //   stableSort(data, getComparator(order, orderBy)),
    //   getComparator(order, key)
    // );
    if (Object.keys((data || [])[0] || {}).find(x => x === orderBy)) {
      data = stableSort(data, getComparator(order, orderBy));
    }
    data.forEach(x => {
      x.groupByKey = (x[config.groupBy] || 0) + 'Groupkey';
    });
    const groupedData = _.groupBy(data, function (da) {
      return da.groupByKey;
    });
    let orderGroupData = {};
    if (key === orderBy) {
      orderGroupData = Object.entries(groupedData).sort((a, b) =>
        order === 'desc' ? b - a : a - b
      );
    } else {
      Object.entries(groupedData).forEach((rows) => {
        const o = rows[1].sort(getComparator(order, orderBy));
        orderGroupData[rows[0]] = o;
      });
    }
    // Object.entries(groupedData)
    //   .sort(([, a], [, b]) => a - b)
    //   .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

    return orderGroupData;
  };

  const handleTableValueChange = (value, FieldName, row, addMode, index) => {
    props.handleTableEditChange(value, FieldName, row, addMode, index);
  };

  return (
    
    <div className={classes.root}>
      {openTagDataDialogConfig.openTagDataDialog && openTagDataDialogConfig.children &&
          <FullScreenDialog title={openTagDataDialogConfig.title} open={openTagDataDialogConfig.openTagDataDialog} close={() => {
            closeViewTagData('', undefined);
          }}>{openTagDataDialogConfig.children}</FullScreenDialog>}
      <Paper className={classes.paper}>
        {props.AddNewRecord && props.AddNewRecord.visible && (
          // <IconButton
          //   aria-label={'addnewrow'}
          //   className="action-button"
          //   size="small"
          //   onClick={() => {
          //     props.AddNewRecord.action();
          //   }}
          // >
            <Button
                        aria-label={'addnewrow'}
                        className="action-button no-print"
                        size="small"
                        onClick={() => {
                          props.AddNewRecord.action();}}
                      >
                       <span className="tool-icon-text">{props.AddNewRecord.title}</span>
                      </Button>
          //   <AddIcon fontSize="small" size="small" />
          //   <span className="tool-icon-text">{props.AddNewRecord.title}</span>
          // </IconButton>
        )}
		{props.ConvertToPOI && props.ConvertToPOI.visible && (
            <Button
                        aria-label={'addnewrow'}
                        className="action-button no-print"
                        size="small"
                        onClick={() => {
                          props.ConvertToPOI.action();}}
                      >
                       <span className="tool-icon-text">{props.ConvertToPOI.title}</span>
                      </Button>
          //   <AddIcon fontSize="small" size="small" />
          //   <span className="tool-icon-text">{props.AddNewRecord.title}</span>
          // </IconButton>
        )}

{props.additionalAction && props.additionalAction.visible && (
            <Button
                        aria-label={'addnewrow'}
                        className="action-button no-print"
                        size="small"
                        onClick={() => {
                          props.additionalAction.action();}}
                      >
                       <span className="tool-icon-text">{props.additionalAction.title}</span>
                      </Button>
          //   <AddIcon fontSize="small" size="small" />
          //   <span className="tool-icon-text">{props.AddNewRecord.title}</span>
          // </IconButton>
        )}
        {showPager && (
          <TablePagination
            className="pager no-print"
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={(data[0]?.totalCount || 0 ) || ((data || []).length)}
            rowsPerPage={rowsPerPage}
            page={props.pageNumber}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
        <TableContainer 
          className={`sticky-wrapper${isSticky ? ' sticky' : ''}`}
          ref={ref}
        >
          <Table id="gridTable" stickyHeader 
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="sticky table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              rowCount={(data || []).length}
              onRequestSort={handleRequestSort}
              headCells={columns}
              headcellClass={config.headcellClass}
              isSticky={isSticky}
              topvalue={topvalue}
              updatedColumnsWidth={updatedColumnsWidth}
              config={config}
			  {...props}
            />
            {(config === undefined ||
              (config.collapsibleMultilevel !== true && config.collapsible !== true &&
                config.inLineGroupBlock !== true)) && (
              <TableBody>
                {isInAddMode && (
                  <TableRow
                    className={
                      1 % 2 === 0
                        ? config.evenRowClass || 'evenRow add'
                        : config.oddRowClass || 'oddRow add'
                    }
                    key={'pagerfalsetable_data_add' + 0}
                  >
                    {}
                    {renderRow(
                      isInAddMode,
                      0,
                      columns,
                      props,
                      props.updateData,
                      EditSource,
                      selectedEditRowId,
                      updatedColumnsWidth,
                      handleTableValueChange,
                      viewTagData
                    )}
                  </TableRow>
                )}
                {showPager &&
                  stableSort(data || [], getComparator(order, orderBy))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? config.evenRowClass || ' evenRow'
                              : config.oddRowClass || ' oddRow'
                          }
                          key={'table_data' + index}
                        >
                          {renderRow(
                            isInAddMode,
                            index,
                            columns,
                            props,
                            row,
                            EditSource,
                            selectedEditRowId,
                            updatedColumnsWidth,
                            handleTableValueChange,
                            viewTagData
                          )}
                        </TableRow>
                      );
                    })}
                {showPager === false &&
                  stableSort(data || [], getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow
                          className={
                            index % 2 === 0
                              ? config.evenRowClass || 'evenRow'
                              : config.oddRowClass || 'oddRow'
                          }
                          key={'pagerfalsetable_data' + index}
                        >
                          {renderRow(
                            isInAddMode,
                            index,
                            columns,
                            props,
                            row,
                            EditSource,
                            selectedEditRowId,
                            updatedColumnsWidth,
                            handleTableValueChange,
                            viewTagData
                          )}
                        </TableRow>
                      );
                    }
                  )}
                {(data || []) === [] ||
                  ((data || []).length === 0 && (
                    <TableRow
                      key="emptyrow"
                      style={{ height: dense ? 33 : 53 }}
                    >
                      <TableCell key="emptyrowcell" colSpan={((columns || [])
                        .filter((x) => x.showInEditOnly !== true) || []).length}>
                        No data available
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
            {config.collapsibleMultilevel !== true && config.collapsible !== true && config.inLineGroupBlock === true && (
              <TableBody>

                {isInAddMode && (
                  <TableRow
                    className={
                      1 % 2 === 0
                        ? config.evenRowClass || 'evenRow add'
                        : config.oddRowClass || 'oddRow add'
                    }
                    key={'pagerfalsetable_data_add' + 0}
                  >
                    {renderRow(
                      isInAddMode,
                      0,
                      columns,
                      props,
                      props.updateData,
                      EditSource,
                      selectedEditRowId,
                      updatedColumnsWidth,
                      handleTableValueChange,
                      viewTagData
                    )}
                  </TableRow>
                )}
                {showPager &&
                  Object.entries(getGroupedData(data || []))
                    //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((rows, pIndex) =>
                      rows[1].map((row, index) => {
                        return (
                          <TableRow
                            className={
                              pIndex % 2 === 0
                                ? config.evenRowClass || ' evenRow'
                                : config.oddRowClass || ' oddRow'
                            }
                            key={'table_data' + index}
                            onClick={() => {
                              props.onRowClick && props.onRowClick(row);
                            }}
                          >
                            {renderRow(
                              isInAddMode,
                              index,
                              columns,
                              props,
                              row,
                              EditSource,
                              selectedEditRowId,
                              updatedColumnsWidth,
                              handleTableValueChange,
                              viewTagData
                            )}
                          </TableRow>
                        );
                      })
                    )}
                {showPager === false &&
                  Object.entries(getGroupedData(data || [])).map(
                    (rows, pIndex) =>
                      rows[1].map((row, index) => {
                        return (
                          <TableRow
                            className={
                              pIndex % 2 === 0
                                ? config.evenRowClass || 'evenRow'
                                : config.oddRowClass || 'oddRow'
                            }
                            key={'pagerfalsetable_data' + index}
                          >
                            {renderRow(
                              isInAddMode,
                              index,
                              columns,
                              props,
                              row,
                              EditSource,
                              selectedEditRowId,
                              updatedColumnsWidth,
                              handleTableValueChange,
                              viewTagData
                            )}
                          </TableRow>
                        );
                      })
                  )}
                {(data || []) === [] ||
                  ((data || []).length === 0 && (
                    <TableRow
                      key="emptyrow"
                      style={{ height: dense ? 33 : 53 }}
                    >
                      <TableCell key="emptyrowcell" colSpan={((columns || [])
                        .filter((x) => x.showInEditOnly !== true) || []).length}>
                        No data available
                      </TableCell>
                    </TableRow>
                  ))}
                {/* {showPager && emptyRows > 0 && (
                  <TableRow
                    key="emptyrow1"
                    style={{ height: (dense ? 33 : 53) * emptyRows }}
                  >
                    <TableCell key="emptyrowcell1" colSpan={6} />
                  </TableRow>
                )} */}
              </TableBody>
            )}
            {config.collapsibleMultilevel && (
              <TableBody>
                <CollapsibleMultilevel {...props} handleTableValueChange={handleTableValueChange} 
                selectedRow={selectedRow} 
                setSelectedRow={setSelectedRow} 
                 tdata={stableSort(data || [], getComparator(order, orderBy))}></CollapsibleMultilevel>
                {(data || []).length === 0 && (
                  <TableRow
                    key="emptyrow1"
                    style={{ height: (dense ? 33 : 53) }}
                  >
                    <TableCell key="emptyrowcell1" colSpan={((columns || [])
                      .filter((x) => x.showInEditOnly !== true) || []).length} >
                    No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
            {config.collapsible && (
              <TableBody>
                {Object.entries(getGroupedData(data || []))
                  //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((obj, index) => (
                    <CollapseableRow
                      key={obj[0]}
                      isInAddMode={isInAddMode}
                      EditSource={EditSource}
                      selectedEditRowId={selectedEditRowId}
                      row={obj[1]}
                      columns={columns}
                      config={config}
                      index={index}
                      updatedColumnsWidth={updatedColumnsWidth}
                      {...props}
                      viewTagData={viewTagData}
                    />
                  )
                  )}
                {(data || []).length === 0 && (
                  <TableRow
                    key="emptyrow1"
                    style={{ height: (dense ? 33 : 53) }}
                  >
                    <TableCell key="emptyrowcell1" colSpan={((columns || [])
                      .filter((x) => x.showInEditOnly !== true) || []).length} >
                    No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      {showPager && (
          <TablePagination
          className="pager no-print"
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={(data[0]?.totalCount || 0 ) || ((data || []).length)}
          rowsPerPage={rowsPerPage}
          page={props.pageNumber}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}          
          />
      )}
      </Paper>
    </div>
  );
}


export const PaginatedGridTable = React.memo(subGridTable);

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const CollapsibleMultilevel = (props) => {
  const {
    config,
    columns,
    isInAddMode,
    EditSource,
    selectedEditRowId,
    updatedColumnsWidth,
    handleTableValueChange,
    viewTagData,
    tdata,
    selectedRow,
    setSelectedRow
  } = props;

  const handelCollapsibleChange = (key, value, isOpen) => {
    const selectedRows = _.cloneDeep((selectedRow || []).filter(
      x => x.key !== key && x.value !== value
    ));
    selectedRows.push({ key: key, value: value, isOpen: isOpen });
    setSelectedRow(selectedRows);
  };

  const getGroupValues = (data, key, groupBy) => {
    groupBy = groupBy.filter(x => x !== key);
    const nextKey = groupBy[0];
    return _.chain(data).groupBy(key).map(function (v, i) {
      return {
        key: key,
        [key]: i,
        data: (nextKey !== undefined) ? getGroupValues(v, nextKey, groupBy) : v,
        variance: _.sumBy(v, function (el) {
          return el.variance;
        }),
        reimbursement: _.sumBy(v || [], function (el) {
          return el.reimbursement;
        }),
        reimburse: _.sumBy(v || [], function (el) {
          return el.reimburse;
        }),
        priorPeriodReimburse: _.sumBy(v || [], function (el) {
          return el.priorPeriodReimburse;
        }),
        variancePercent: v
          ? (_.sumBy(v || [], function (el) {
              return el.variancePercent;
            }) / v.length)
          : 0
      };
    }).value();
  };
  const getMultilevelGroupedData = () => {
    let groupedData = _.cloneDeep(tdata);
    if (config.groupBy !== undefined) {
      groupedData = getGroupValues(groupedData, config.groupBy[0], config.groupBy);
    }
    return renderTableCell(groupedData);
  };

  const renderTableCell = (groupedData, keyvalue = [], tableCell = [], rowCount = 0) => {
    // (data || []).forEach((gp, index) => {
    //   const rowClassName = index % 2 === 0
    //     ? config.evenRowClass || ' evenRow'
    //     : config.oddRowClass || ' oddRow';
    //   const rowkey = 'table_data' + index + gp.key;
    //   tableCell.push(<TableRow
    //     className={rowClassName}
    //     key={rowkey}
    //   >
    //     <TableCell>{gp[gp.key]}</TableCell>
    //     <TableCell colspan={10}>
    //       {gp.data.map((level2, in2) => (
    //         <Table>
    //         <TableRow className={rowClassName} key={rowkey + in2 + level2.key}>
    //           <TableCell>{level2[level2.key]}</TableCell>
    //           <TableCell colspan={9}>
    //             {level2.data.map((level3, l3) => (
    //                <Table> <TableRow className={rowClassName} key={rowkey + in2 + level2.key + l3 + level3.key}
    //               > <TableCell>{level3[level3.key]}</TableCell>
    //                 <TableCell colspan={8}>
    //             {level3.data.map((level4, l4) => (
    //                <Table>
    //               <TableRow className={rowClassName} key={rowkey + in2 + level2.key + l3 + level3.key + l4 + level4.key}
    //               >
    //                 {renderRow(
    //                   isInAddMode,
    //                   index,
    //                   columns.filter(x => config.groupBy.find(y => y === x.key) === undefined),
    //                   props,
    //                   level4,
    //                   EditSource,
    //                   selectedEditRowId,
    //                   updatedColumnsWidth,
    //                   handleTableValueChange,
    //                   viewTagData
    //                 )}
    //         </TableRow>
    //       </Table>)
    //             )
    //       }
    //           </TableCell>
    //               </TableRow>
    //                 </Table>
    //             )
    //             )
    //       }
    //           </TableCell>
    //         </TableRow>
    //      </Table>)
    //       )
    //       }
    //       </TableCell>
    //   </TableRow>);
    // });
    groupedData.forEach((d) => {
      keyvalue.push({ key: d.key, value: d[d.key] });
      const row = _.cloneDeep(d);
      const selected = selectedRow.find(x => x.key === d.key && x.value === d[d.key]) ||
      { key: d.key, value: d[d.key], isOpen: false };
      config.groupBy.forEach(key => {
        if (key !== d.key) {
          delete row[key];
        }
      });

      tableCell.push(
          <TableRow
            className={
              rowCount % 2 === 0
                ? config.evenRowClass || ' evenRow'
                : config.oddRowClass || ' oddRow'
            }
            key={'table_data' + rowCount + d.key + d[d.key]}
          >
            {renderRow(
              isInAddMode,
              rowCount,
              columns,
              props,
              row,
              EditSource,
              selectedEditRowId,
              updatedColumnsWidth,
              handleTableValueChange,
              viewTagData,
              d.data !== undefined,
              handelCollapsibleChange,
              selected
            )}
          </TableRow>
      );
      rowCount++;
      if (d.data !== undefined && (selected || {}).isOpen) {
        renderTableCell(d.data, keyvalue, tableCell, rowCount);
      }
    });
    return tableCell;
  };

  return (
    <React.Fragment>
      {getMultilevelGroupedData()}
    </React.Fragment>
  );
};

const CollapseableRow = (props) => {
  const {
    row,
    columns,
    config,
    index,
    isInAddMode,
    EditSource,
    selectedEditRowId,
    viewTagData

  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  let addExtraSpace = false;

  if (config.addExtraSpace !== undefined) {
    if (config.addExtraSpace.key !== undefined) {
      if ((row[0] || {})[config.addExtraSpace.key] > config.addExtraSpace.whenGreaterThan) {
        addExtraSpace = true;
      }
    }
  }

  return (
    <React.Fragment>
      <TableRow
        className={
          classes.root +
          (index % 2 === 0
            ? ' ' + (config.evenRowClass || 'evenRow')
            : ' ' + (config.oddRowClass || 'oddRow'))
        }
        onClick={() => {
          props.onRowClick && props.onRowClick(row[0]);
        }}
      >
        {(columns || []).map((col, i) => (
          <TableCell
            key={'tableRowCell_parent' + index + i + col.key}
            style={{
              width:
                (props.updatedColumnsWidth.find((x) => x.key === col.key) || {})
                  .width + 'px'
            }}
          >
            <div className="row">
              {i === 0 && (row.length > 1 || config.showEqualSpace === true) && config.showCollapsible !== 'end' && (
                <div className={addExtraSpace ? 'col-xs-2 addExtraSpace' : 'col-xs-2'}
                    style={{ paddingLeft: '0px' }}
                >
                  {row.length > 1 && <IconButton
                    key={col.key + i + row[col.key]}
                    aria-label="expand row"
                    size="small"
                    style={{ padding: '0px' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(!open);
                    }}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>}
                </div>
              )}

              <div className={i === 0 ? (addExtraSpace ? 'col-xs-10 addExtraSpace' : 'col-xs-10') : 'col-xs-12'}>
                {col.type === 'Parent' || config.inLineGroupBlock === true
                  ? (
                      col.renderComponent !== undefined
                        ? (
                            props[col.renderComponent](row[0], col.key, isInAddMode)
                          )
                        : row[0][col.key] === null
                          ? (
                              ''
                            )
                          : (
                              getFormattedData(
                                row[0],
                                col,
                                isInAddMode,
                                EditSource,
                                selectedEditRowId,
                                null,
                                false,
                                props,
                                undefined,
                                viewTagData
                              )
                            )
                    )
                  : (
                  <></>
                    )}
              </div>
            {i === 0 && (row.length > 1 || config.showEqualSpace === true) && config.showCollapsible === 'end' && (
                <div className={addExtraSpace ? 'col-xs-4 addExtraSpace' : 'col-xs-4' }>
                  {row.length > 1 && <IconButton
                    key={col.key + i + row[col.key]}
                    aria-label="expand row"
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpen(!open);
                    }}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                  }
                </div>
            )}
            </div>
          </TableCell>
        ))}
      </TableRow>
      {open &&
        row.map(
          (rowData, ir) =>
            (config.inLineGroupBlock !== true || ir !== 0) && (
              <TableRow key={'rowdata' + ir}>
                {(columns || []).map((col, i) => (
                  <TableCell
                    key={'tableRowCell_child' + index + i + col.key}
                    style={{
                      width:
                        (
                          props.updatedColumnsWidth.find(
                            (x) => x.key === col.key
                          ) || {}
                        ).width + 'px'
                    }}
                  >
                    <div className="row">
                      {i === 0 && <div className="col-xs-4"> &nbsp;</div>}

                      <div className={i === 0 ? 'col-xs-8' : 'col-xs-12'}>
                        {(col.type === 'Child' || config.inLineGroupBlock === true) &&
                           (col.editConfig === undefined || isHidden(rowData, col))
                          ? (
                              col.renderComponent !== undefined
                                ? (
                                    props[col.renderComponent](rowData, col.key, isInAddMode)
                                  )
                                : (
                                    getFormattedData(
                                      rowData,
                                      col,
                                      isInAddMode,
                                      EditSource,
                                      selectedEditRowId,
                                      null,
                                      false,
                                      props,
                                      undefined,
                                      viewTagData
                                    )
                                  )
                            )
                          : (
                          <></>
                            )}
                      </div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            )
        )}
    </React.Fragment>
  );
};

CollapseableRow.propTypes = {
  row: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  isInAddMode: PropTypes.bool,
  EditSource: PropTypes.array,
  handleTableEditChange: PropTypes.func,
  updatedColumnsWidth: PropTypes.array,
  selectedEditRowId: PropTypes.object
};
function isHidden (row, col) {
  let isValid = true;
  if (col.editConfig.hideWhen !== undefined && col.editConfig.hideWhen.length > 0) {
    col.editConfig.hideWhen.forEach(x => {
      isValid = row[x.key] !== x.value;
      if (!isValid) return false;
    });
  } else if (col.editConfig.isHidden !== undefined) { return !col.editConfig.isHidden; }
  return isValid;
}
function renderRow (isInAddMode,
  index,
  columns,
  props,
  row,
  EditSource,
  selectedEditRowId,
  updatedColumnsWidth,
  handleTableValueChange,
  viewTagData,
  showCollapsible,
  handelCollapsible,
  selected
) {
  if(selectedEditRowId && selectedEditRowId.value === row[selectedEditRowId.key] && props.isInline === true)
  {
    row=props.updateData;
  }
  return selectedEditRowId && selectedEditRowId.value === row[selectedEditRowId.key] && props.isInline !== true
    ? (
      <TableCell key={'tableRowCell' + index + isInAddMode.toString()} colSpan={((columns || [])
        .filter((x) => x.showInEditOnly !== true) || []).length}>
      <div className="row">
        <div className="col-xs-11">
          <div className="row">
              {_.orderBy((columns || []),
                ['editSortOrder'], ['asc']).map((col, i) => (
              <>
              {
                col.renderComponent === undefined && (col.editConfig === undefined || isHidden(row, col)) && props.isProperty ?
                (<div className={'col-xs-12 col-xs-6 col-lg-1 ' + (props.gridEditClass ? props.gridEditClass : 'editReport'  )} key={'edit-' + i + col.key}>
                 {getFormattedData(props.updateData, col, isInAddMode, EditSource, selectedEditRowId, handleTableValueChange, true, props, index, viewTagData) }
                </div>) : 
				(col.renderComponent === undefined && (col.editConfig === undefined || isHidden(row, col)) &&
                <div className="col-xs-12 col-lg-4" key={'edit-' + i + col.key}>
                 {getFormattedData(props.updateData, col, isInAddMode, EditSource, selectedEditRowId, handleTableValueChange, true, props, index, viewTagData) }
                </div>)
              }
              {col.dynamicRenderForm !== undefined && props.updateData[col.dynamicRenderForm] !== undefined && props.updateData[col.dynamicRenderForm].length > 0 && <><br/><br/> {props.renderDynamicForm(props.updateData)} </>}
              </>
              ))}
          </div>
        </div>
        <div className="col-xs-1 center-xs">
          {(columns || []).map((col) =>
            col.renderComponent !== undefined
              ? (
                  props[col.renderComponent](row, col.key, isInAddMode)
                )
              : (
              <></>
                )
          )}
        </div>
      </div>
    </TableCell>
      )
    : (
        (columns || []).filter((x) => x.showInEditOnly !== true).map((col, i) => (
        
      <TableCell
        key={'tableRowCell' + index + i + col.key}
        style={{
          width:( columns.width
            ? columns.width + 'px'
            : ((updatedColumnsWidth || []).find((x) => x.key === col.key) || {}).width +
              'px')
        }}
      >
        {i === 0 &&
          props.rowBadge &&
          row[props.rowBadge.key] === props.rowBadge.equalsTo && (
            <Chip
              className={props.rowBadge.class}
              size="small"
              label={props.rowBadge.title}
            ></Chip>
        )}
        {col.renderComponent !== undefined
          ? (showCollapsible !== true && props[col.renderComponent](row, col.key, isInAddMode))
          : getFormattedData(
            row,
            col,
            isInAddMode,
            EditSource,
            selectedEditRowId,
            handleTableValueChange,
            false,
            props,
            undefined,
            viewTagData,
            showCollapsible,
            handelCollapsible,
            selected
          )}
      </TableCell>
        ))
      );
}

import React, { useState } from "react";
import "./index.less";
import { withStyles } from "@material-ui/core/styles";
import ButtonIcon from "../icon-button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5"
	}
})(props => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right"
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right"
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles(theme => ({
	root: {
		"&:focus": {
			backgroundColor: theme.palette.primary.main,
			"& .MuiListItemIcon-root, & .MuiListItemText-primary": {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);

export default function ButtonPopup({
	buttonIcon,
	item1,
	item2,
	disabled = false,
	dataTip = ""
}) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<ButtonIcon
				onClick={handleClick}
				aria-controls="customized-menu"
				aria-haspopup="true"
				disabled={disabled}
				dataTip={dataTip}
			>
				{buttonIcon}
			</ButtonIcon>
			<StyledMenu
				id="customized-menu"
				className="buttonPopup"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem>{item1}</StyledMenuItem>
				<StyledMenuItem>{item2}</StyledMenuItem>
			</StyledMenu>
		</>
	);
}

export const controlType = {
  Autofill: 'Autofill',
  MUIDatePicker: 'MUIDatePicker',
  DateRangePicker: 'DateRangePicker',
  DatePicker: 'DatePicker',
  DateMonthPicker:'DateMonthPicker',
  DateYearPicker:'DateYearPicker',
  DropDown: 'Dropdown',
  TextBox: 'TextBox',
  NumericRange:'NumericRange',
  MultiSelectDropDown: 'MultiSelectDropDown',
  Checkbox: 'Checkbox',
  RadioButton: 'Radio',
  Switch: 'Switch',
  File: 'File',
  FileInput: 'FileInput',
  PhoneNumber: 'PhoneNumber',
  Email: 'Email',
  Search:'Search',
  TextArea:'TextArea',
  Link:'Link',
  AutofillWithAdd:'AutofillWithAdd'
};

export const ApprovedStatus = {
  PendingApproval: 3,
  Approved: 4,
  AutoAllocated: 2
};

/* eslint-disable no-use-before-define */
import React from "react";
import "./autocomplete.less";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Box, Chip } from "@material-ui/core";

export default function AutocompleteMultiSelect({
	className = "",
	value = [],
	configData = [],
	width = 270,
	labelKey = "propertyName",
	setProperty,
	lbl = "Property Name",
	defaultValue = [],
	isMultiple = true,
	limitTags = 5,
	extraParam = {},
	onDelete
}) {
	return (
		<Autocomplete
			// id="combo-box-demo"
			underlineStyle={{ display: 'none' }}
			size="small"
			multiple={isMultiple}
			limitTags={limitTags}
			className={className}
			defaultValue={defaultValue}
			value={value}
			options={configData}
			getOptionLabel={option => option[labelKey]}
			style={{ width: width }}
			renderInput={params => (
				<Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                <label htmlFor="filled-hidden-label-small">{lbl}</label>
				<TextField {...params}  hiddenLabel variant="outlined"/>
				</Box>
			)}
			onChange={(event, value) => setProperty(value, extraParam)}
			renderTags={(values)=>values.map((value)=>(
                <Chip
                className="MuiChip-sizeSmall"
                    key={(extraParam == "propertyInfo") ? value.propertyId : value.id}
                    label={(extraParam == "propertyInfo") ? value.propertyName : value.value}
                    onDelete={()=>{
                        onDelete(value, extraParam);
                    }}
                />
            ))}
		/>
	);
}

import {
    TableHead,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
    TextField,
    Box,
    TablePagination,
    Dialog,
    AppBar,
    Toolbar,
    Typography,
    IconButton,
  } from "@material-ui/core";
  import React, { Component } from "react";
  import { apiBatchRequest, CancelRequest, RenewCancelToken } from "../../utils/api_service";
  import { API_URL } from "../../constants/api";
  import commonLabels from "../../constants/messageConstants";
  import { ShowToast, toastType } from "../../utils/toast-service";
  
  import Reports from "./Reports";
  import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from "@material-ui/pickers";
  import "./report.less";
  import DateFnsUtils from "@date-io/date-fns";
  import { DateFormat } from "../../constants/appConstants";
  import moment from "moment";
  import Autocomplete from "@material-ui/lab/Autocomplete";
  import _ from "lodash";
  import jsPDF from "jspdf";
  import "jspdf-autotable";
  import PageHeader from "../common/PageHeader";
  import BackspaceIcon from "@material-ui/icons/Backspace";
  import {
    EnhancedTableHead,
    generatePDFFromHtml,
    getComparator,
    getCurrentPayCycleEndDateByDate,
    getCurrentPayCycleStartDateByDate,
    removeDuplicates,
    stableSort,
  } from "../../utils/helper";
  import { Icon } from "@iconify/react";
  import bxFilterAlt from "@iconify/icons-bx/bx-filter-alt";
  import filterIcon from "@iconify/icons-ci/filter";
  import camelcaseKeys from "camelcase-keys";
  import AdvancedFilter from "./AdvancedFilter";
  import { getMultilevelGroupedData } from "../../utils/generic";
  import { getFormattedValuesForReports } from "../../utils/formatData";
  import ReportButtons from "../../utils/ReportButtons";
  import SearchBar from "../common/AutoSearch/SearchBar";
import EditBuildings from "../Buildings/EditBuildings";
import { IoMdCloseCircleOutline } from "react-icons/io";
import AddEditLeaseComps from "../LeaseComps/AddEditLeaseComps";
import EditBuildingsDetails from "../Buildings/EditBuildingsDetails";
  
  const uploadColumns = [
    {
      key: "buildingClass",
      name: "BuildingClass",
    },
    {
      key: "market",
      name: "Department",
    },
    {
      key: "market",
      name: "Market",
    },
    {
      key: "buildingOwner",
      name: "BuildingOwner",
    },
    {
      key: "subMarket",
      name: "SubMarket",
    },
    {
      key: "buildingName",
      name: "buildingName",
    },
    {
      key: "floorplate",
      name: "Hire date",
    },
  ];
  
  const columnWidths = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];
  const headCells = [
    {
      id: "streamMarket",
      numeric: false,
      disablePadding: false, //JT
      label: "Stream Market",
    },
    {
      id: "streamSubmarket",
      numeric: false,
      disablePadding: false, //JT
      label: "Stream Submarket",
    },
    {
      id: "tenantName",
      numeric: false,
      disablePadding: false, //JT
      label: "Tenant Name",
    },
    {
      id: "closeDate",
      numeric: false, //JT
      disablePadding: false,
      label: "Close Date",
    },
    {
      id: "leaseExpirationDate",
      numeric: false, //JT
      disablePadding: false,
      label: "Expiration Date",
    },
    {
      id: "sfLeased",
      numeric: true, //JT
      disablePadding: false,
      label: "SF Leased",
    },
    {
      id: "term(months)",
      numeric: true, //JT
      disablePadding: false,
      label: "Term (Months)",
    }
    
  ];
  export default class LeaseCompQAReport extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedMarketId:this.props.globalMarketId,
        openFilter: false,
        enableFilterButton:false,
        showMain: false,        
        showReport: false,
        searchInput: "",
        rowsPerPage: 100,
        rowsPerPageOptions: [100, 200, 300, 400, 500],
        page: 0,
        order: "asc",
        orderBy: "nam",
        topvalue: 68,
        selected: [],
        dense: false,
        getLeaseCompReport:[],
        detailView:false,
        detailLeaseView:false,
        selectedEditRowId:-1,
        advanceFilterConfig: {
          groupBy: {
            masterData: [
                {
                    key: "Property",
                    value: "property",
                    id: "property",
                    numeric: false,
                    disablePadding: false,
                    label: "Property",
                  },
            //   {
            //     key: "Class",
            //     value: "buildingClass",
            //     id: "buildingClass",
            //     numeric: false,
            //     disablePadding: false,
            //     label: "Class",
            //   },
            ],
            selected: [
              {
                key: "Property",
                value: "property",
                id: "property",
                numeric: false,
                disablePadding: false,
                label: "Property",
              },
            ],
          },
          selectedDate: {},
          suppressGraph: false,
          suppressZero: true,
          summaryRows: false,
          showZeroNGraph: true,
        },
        summaryCols: [
        //   "floorplate",
        //   "totalRsf",
        //   "leasedRsf",
        //   //"startingLeasedRsf",
        //   "absorption",
        ],
      };
  
      this.baseState = this.state;
    }
  
    componentDidMount() {
      RenewCancelToken();
        this.getData(this.state.selectedMarketId);
      }
      componentWillUnmount()
  {
    CancelRequest();
  }
      getData = (selectedMarketId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Reports.getLeaseCompReport,
				key: "getLeaseCompReport",
				params: { marketId: selectedMarketId,businessLineId:1 },
			},
		])
			.then((getLeaseCompReport) => {
				this._asyncRequest = null;
				if (getLeaseCompReport.getLeaseCompReport.success == true) {

                                       

					this.setState({
						getLeaseCompReport:
							camelcaseKeys(getLeaseCompReport.getLeaseCompReport.data) || [],
                            configuration:camelcaseKeys(getLeaseCompReport.getLeaseCompReport.configuration) || [],
                            showReport: true,
                            reloadTable:!this.state.reloadTable,
                          });
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};
    componentWillReceiveProps(nextProps) {    
          if (nextProps.globalMarketId !== this.state.selectedMarketId) {    
        this.getData(nextProps.globalMarketId); 
            this.setState({ selectedMarketId: nextProps.globalMarketId });
          }
        }
    handleRequestSort = (event, property) => {
      const isAsc = this.state.orderBy === property && this.state.order === "asc";
      this.setState({
        order: isAsc ? "desc" : "asc",
        orderBy: property,
      });
    };
    handleChangePage = (event, newPage) => {
      this.setState({ page: newPage }, () => {
        this.forceUpdate();
      });
    };
  
    handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
    };
    goBack = () => {
      this.setState({
        showMain: true,
      });
    };
  
    renderTable = (getLeaseCompReport) => {
      const groupSequence = this.getColumnSequence();
      return (
        <Table
          className="reportTable"
          id="rptTable"
          stickyHeader
          aria-label="sticky table"
        >
          <EnhancedTableHead
            numSelected={this.state.selected.length}
            order={this.state.order}
            orderBy={this.state.orderBy}
            onRequestSort={this.handleRequestSort}
            rowCount={getLeaseCompReport.length}
            columnSequence={groupSequence}
            headCells={headCells}
          />
         
          <TableBody>
            {
              getLeaseCompReport.length == 0 ?( <TableRow >
                <TableCell><span>No Data Available</span></TableCell></TableRow>
                        ):(this.renderTableRow(
              this.state.rowsPerPage > 0
                ? stableSort(
                    getLeaseCompReport || [],
                    getComparator(this.state.order, this.state.orderBy)
                  ).slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                : getLeaseCompReport
            ))}
          </TableBody>
        </Table>
      );
    };
  
    handleClose = () => {
      this.setState({
        openSlidingPanel: false,
      });
    };
  
    resetPage = () => {
      this.setState({...this.baseState},this.componentDidMount);
      //this.componentDidMount();
    };
  
  
    handleClickOpen = () => {
      this.setState({
        openSlidingPanel: true,
      });
    };
  
    handlePrintClick = () => {
      this.setState(
        {
          printPage: true,
        },
        window.print()
      );
    };
  
    handleChange = (e, value, name) => {
      this.setState({ [name]: value });
    };
  
 
  
    getColumnSequence = () => {
      return removeDuplicates(
        [
          ...this.state.advanceFilterConfig.groupBy.selected,
          ...this.state.advanceFilterConfig.groupBy.masterData,
        ],
        "key"
      );
    };
  
    adjustColumnSequence = (currentGroupedRow) => {
      this.state.advanceFilterConfig.groupBy.masterData.forEach((x) => {
        currentGroupedRow.push({ key: x.value });
      });
      return currentGroupedRow;
    };
  
    renderTableRow = (data, onlydata = false) => {
      return this.renderTableCell(
        getMultilevelGroupedData(
          data,
          this.state.advanceFilterConfig.groupBy.selected.map((x) => x.value),
          this.state.isTotal,
          this.state.summaryCols
        ),
        onlydata,
        []
      );
    };
  
   
  
    // Export PDF
    // exportPDFUsingData = () => {
    //   const unit = "pt";
    //   const size = "A4"; // Use A1, A2, A3 or A4
    //   const orientation = "landscape"; // portrait or landscape
  
    //   const marginLeft = 40;
    //   const doc = new jsPDF(orientation, unit, size);
  
    //   doc.setFontSize(15);
    //   const title = "Market Absorption Report";
    //   const sequence = this.getColumnSequence();
    //   const columns = this.getColumnSequence().map((x) => x.key);
    //   const headers = [
    //     [...columns, "Property Name", "Floor Plate", "TotalRSF", "LeasedRSF"],
    //   ];
  
    //   const data = this.renderTableRow(
    //     this.state.marketAbsorptionReport,
    //     true
    //   ).map((m) => [
    //     m[sequence[0].value],
    //     m[sequence[1].value],
    //     m[sequence[2].value],
    //     m[sequence[3].value],
    //     m.buildingName,
    //     m.monthRecorded,
    //     m.floorplate,
    //     m.totalRSF,
    //     m.leasedRsf,
    //     //m.startingLeasedRsf,
    //     // m.rnk,
    //     m.absorption,
    //   ]);
  
    //   const content = {
    //     startY: 50,
    //     head: headers,
    //     body: data,
    //   };
  
    //   doc.text(title, marginLeft, 40);
    //   doc.setFontSize(9);
    //   doc.autoTable(content);
    //   // doc.fromHTML(ReactDOMServer.renderToStaticMarkup(this.render()));
    //   doc.save("NewHireEmployeeReport.pdf");
    // };
  
    onRowClick = (data) => {
        let id=this.state.getLeaseCompReport.find((m)=>{
            let breakCondition=false
            if (m.property == data && breakCondition == false) 
            {
                breakCondition=true;
            return m;
            }

        });
        this.setState({
          detailView: true,
          selectedEditRowId: id.buildingId,
          //selectedCompanyId: data.companyId,
          ViewTitle: "Edit",
          actionType: "Edit",
        });
      };
onPropertyBackClick=()=>
{
    this.getData(this.state.selectedMarketId);
    this.setState({
        detailView:false,
    });
}
onLeaseBackClick=()=>
{  this.getData(this.state.selectedMarketId);
    this.setState({
        detailLeaseView:false,
    });
}
      onLeaseRowClick = (data) => {
        let id=this.state.getLeaseCompReport.find((m)=>{
            if (m.tenantName == data) return m.compId;

        });
        this.setState({
          detailLeaseView: true,
          //selectedEditRowId: id,
          selectedCompanyId: id.compId,
          ViewTitle: "Edit",
          actionType: "Edit",
        });
      };
    renderTableCell = (
      groupedData,
      onlydata,
      currentGroupedRow = [],
      tableCell = [],
      rowCount = 0
    ) => {
      let column1 = "";
      let column2 = "";
      let column3 = "";
      //  let column4 = '';
      // let column5 = '';
      groupedData.map((d, i) => {
        if (d.data !== undefined) {
          currentGroupedRow = currentGroupedRow.filter((x) => x.key !== d.key);
          currentGroupedRow.push({ key: d.key, value: d[d.key] });
          this.renderTableCell(
            d.data,
            onlydata,
            currentGroupedRow,
            tableCell,
            rowCount
          );
        }
        const keyvalue = this.adjustColumnSequence(
          _.cloneDeep(currentGroupedRow)
        );
        const sequence = this.getColumnSequence();
        const evenOddClass =
          rowCount % 2 === 0 ? " rpt rpt-even " : " rpt rpt-odd ";
        const topBorderClass =
          column1 !==
            (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? ""
            ? " rpt-topBorder "
            : " ";
  
        const key = d.streamMarket + d.streamSubmarket + "_" + rowCount + "_" + i;
        if (d.data && d.summaryData != undefined) {
          if (
            d.summaryData.length > 0 &&
            (d.summaryData[0].isSummary === undefined ||
              (d.summaryData.length > 0 &&
                d.summaryData[0].isSummary === true &&
                this.state.advanceFilterConfig.summaryRows === true))
          ) {
            tableCell.push(
              <TableRow
                key={key}
                className={
                  topBorderClass + (d.isGroup === true ? " parent-row" : "")
                }
              >
                <TableCell
                  className={"st sticky " + evenOddClass + topBorderClass}
                >
                  {getFormattedValuesForReports(
                    column1 !== d[sequence[0].value] && d[sequence[0].value],
                    "Text"
                  )}{" "}
                </TableCell>
              
               
                <TableCell
                  className={
                    "st sticky rpt-topBorder " + evenOddClass + topBorderClass
                  }
                >
                  {" "}
                </TableCell>
                <TableCell
                  className={
                    "st sticky rpt-topBorder " + evenOddClass + topBorderClass
                  }
                >
                  {" "}
                </TableCell>
                {/* {d.summaryData.map((m, ind) =>
                  this.renderSummaryColumn(
                    d.summaryData,
                    m,
                    key + ind,
                    evenOddClass
                  )
                )} */}
              </TableRow>
            );
          }
        }
        if (d.data === undefined) {
          if (onlydata) {
            const rowData = {};
            rowData[sequence[0].value] = getFormattedValuesForReports(
              column1 !== d[sequence[0].value] ? d[sequence[0].value] : "",
              "Text",
              true
            );
            // rowData[sequence[1].value] = getFormattedValuesForReports(
            //   column2 !== d[sequence[1].value] ? d[sequence[1].value] : "",
            //   "Text",
            //   true
            // );
            // rowData[sequence[2].value] = getFormattedValuesForReports(
            //   column3 !== d[sequence[2].value] ? d[sequence[2].value] : "",
            //   "Text",
            //   true
            // );
            //rowData[sequence[3].value] = getFormattedValuesForReports(column4 !== d[sequence[3].value] ? d[sequence[3].value] : '', 'Text', true);
            // rowData[sequence[4].value] = getFormattedValuesForReports(column5 !== d[sequence[4].value] ? d[sequence[4].value] : '', 'Text', true);
            // rowData.submarket = d.submarket;
           // rowData.property = d.property;
            //rowData.buildingClass = d.buildingClass;
            rowData.streamMarket = d.streamMarket;
            rowData.streamSubmarket = d.streamSubmarket;
             rowData.tenantName = d.tenantName;
            // rowData.leasedRsf = d.leasedRsf;
            // rowData.term(months) = d.term(months);
            rowData.closeDate = d.closeDate;
            rowData.leaseExpirationDate = d.leaseExpirationDate;
            rowData.sfLeased = d.sfLeased;
            rowData["term(months)"] = d["term(months)"];
            
            tableCell.push(rowData);
          } else {
            tableCell.push(
              <TableRow key={key} className={topBorderClass}>
                <TableCell className={evenOddClass + topBorderClass}>
              
                    <button style={{ border: '0px',
    backgroundColor: 'transparent'}}
    onClick={(e)=>{this.onRowClick(d[sequence[0].value])}}>
                    <span className="default-text span-link">
                   {/* { d[sequence[0].value]}{" "} */}
                     {getFormattedValuesForReports(
                    column1 !== d[sequence[0].value] && d[sequence[0].value],
                    "Text"
                  )}{" "}
                    </span>
                  </button>
                
                </TableCell>
            
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {d.streamMarket === null
                    ? "NA"
                    : getFormattedValuesForReports(d.streamMarket, "Text")}
                </TableCell>
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {d.streamSubmarket === null ? "NA" : d.streamSubmarket}
                </TableCell>
               
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                <button 
                style={{ border: '0px',
                backgroundColor: 'transparent'}}
                onClick={(e)=>{this.onLeaseRowClick(d.tenantName)}}>
                <span className="default-text span-link">
                  {d.tenantName === null
                    ? "NA"
                    : getFormattedValuesForReports(d.tenantName, "Text")}
                    </span>
                    </button>
                </TableCell>
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {d.closeDate === null
                    ? "NA"
                    : getFormattedValuesForReports(d.closeDate, "Date")}
                </TableCell> 
                 <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {d.leaseExpirationDate === null
                    ? "NA"
                    : getFormattedValuesForReports(d.leaseExpirationDate, "Date")}
                </TableCell> 
  
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {d.sfLeased === null
                    ? "NA"
                    : getFormattedValuesForReports(d.sfLeased, "Decimal")}
                </TableCell>
  
                <TableCell className={evenOddClass + "rpt-topBorder"}>
                  {getFormattedValuesForReports(d["term(months)"], "Number")}
                </TableCell>
              </TableRow>
            );
          }
        }
        rowCount++;
        column1 =
          (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? "";
        // column2 =
        //   (keyvalue.find((x) => x.key === sequence[1].value) || {}).value ?? "";
        // column3 =
        //   (keyvalue.find((x) => x.key === sequence[2].value) || {}).value ?? "";
        // // column4 = (keyvalue.find(x => x.key === sequence[3].value) || {}).value ?? '';
        // column5 = (keyvalue.find(x => x.key === sequence[4].value) || {}).value ?? '';
      });
      return tableCell;
    };
  
   
    enableFilter = () => {
      let openFilter = this.state.openFilter;
      this.setState({ openFilter: !openFilter });
    };
    handleSearch = (event) => {
      this.setState({ searchInput: event.target.value }, () => {
        this.globalSearch();
      });
    };
  
    globalSearch = () => {
      let { searchInput } = this.state;
      let searchData = this.state.responseMarketAbsorptionReport;
      let filteredData = searchData.filter((value) => {
      //  if (
      //    value.market != null &&
      //    value.buildingClass != null &&
      //    value.buildingName != null &&
      //    value.buildingOwner != null &&
      //    value.monthRecorded != null &&
      //    value.floorplate != null &&
      //    value.submarket != null &&
      //    value.totalRsf != null &&
      //    value.leasedRsf != null &&
      //    //value.startingLeasedRsf != null &&
      //    value.absorption != null
      //  ) {
          return (
              (value.market ? value.market.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
              (value.submarket ? value.submarket.toLowerCase().includes(searchInput.toLowerCase()) : false) ||
              (value.buildingClass ? value.buildingClass
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false) ||
              (value.buildingOwner ? value.buildingOwner
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) : false) ||
              (value.buildingName ? value.buildingName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false) ||
              (value.monthRecorded ? value.monthRecorded.toString().includes(searchInput) : false) ||
              (value.floorplate ? value.floorplate
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false) ||
              (value.totalRsf ? value.totalRsf
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false) ||
              (value.leasedRsf ? value.leasedRsf
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false) ||
              (value.absorption ? value.absorption
              .toString()
              .toLowerCase()
              .includes(searchInput.toLowerCase()) : false)
            // value.startingLeasedRsf
            //   .toString()
            //   .toLowerCase()
            //   .includes(searchInput.toLowerCase()) ||
          );
      //  }
      });
  
      if (searchInput.length == 0)
        this.setState({
          marketAbsorptionReport: this.state.responseMarketAbsorptionReport,
        });
      else this.setState({ marketAbsorptionReport: filteredData });
    };
    render() {
      const {
        showMain,
        getLeaseCompReport,
       
        showReport,
      } = this.state;
  
      return (
        <div style={{marginTop:'-18px'}}>
          {showMain ? (
            <Reports globalMarketId={this.state.selectedMarketId}></Reports>
          ) : 
          
          (
 
            <>

{this.state.detailView && (
						<Dialog open={this.state.detailView} maxWidth="lg">
							<AppBar
								style={{ position: "relative", backgroundColor: "#064473" }}
							>
								<Toolbar className="custom-toolbar">
									<Typography
										variant="h6"
										className="app-header-white"
										style={{ flex: "1", color: "#FFFFF" }}
									>
										Edit Property
									</Typography>
									<IconButton
                  style={{ float: 'right',marginRight: "-4px" }}
										edge="start"
										color="inherit"
										onClick={() => {
                                            this.setState({
                                                detailView:false
                                            });
											//this.reloadPropertyData();
										}}
										aria-label="close"
									>
										<IoMdCloseCircleOutline />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div>
								<EditBuildingsDetails
								 globalMarketId={this.state.selectedMarketId}
									selectedBuildingId={this.state.selectedEditRowId}
									onBackClick={this.onPropertyBackClick}
									ViewTitle={this.state.ViewTitle}
									actionType={this.state.actionType}
									isInAddMode={true}
									reloadData={this.reloadData}
                  buildingSummaryData={getLeaseCompReport}
									// isRedirectedFromReport={true}
									// isPropertyOfInterest={false}
									//reloadPropertyData={this.reloadPropertyData}
									
								/>
							</div>
						</Dialog>
					)}

{this.state.detailLeaseView && (
					<Dialog open={this.state.detailLeaseView} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar className="custom-toolbar">
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ flex: "1", color: "#FFFFF" }}
								>
									Edit Lease Comp
								</Typography>
								<IconButton
                style={{ float: 'right',marginRight: "-4px" }}
									edge="start"
									color="inherit"
									onClick={() => {
										this.setState({
											detailLeaseView:false,
										});
										this.forceUpdate();
									}}
									aria-label="close"
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<AddEditLeaseComps
              globalMarketId={this.state.selectedMarketId}
								selectedCompId={this.state.selectedCompanyId}
								onBackClick={this.onLeaseBackClick}
								ViewTitle={this.state.ViewLeaseTitle}
							actionType="Edit"
							isInAddMode={false}
                            isRedirectedFromReport={true}
								//reloadData={this.reloadData}
								//isRedirectedFromTenantTab={true}
								
							// getALLNNNFS={getALLNNNFS}
							//getAllLeaseType={getAllLeaseType}
							
							//leaseCompsSummaryData={leaseCompsSummaryData}
								//reloadPropertyData={this.reloadPropertyData}
							/>
						</div>
					</Dialog>
				)}
              <div className="row">
                <div className="col-sm-3 col-md-3 col-lg-6">
                  {/* <PageHeader
                    isReportPage={true}
                    key={"LeaseCompQA_Report"}
                    btnText={"Reports"}
                    onBackClick={this.goBack}
                    title={"/ Lease Comp QA Report"}                   
                  >
                   Lease Comp Report
                  </PageHeader> */}
                </div>
                {/* <div className="col-sm-5 col-md-5 col-lg-4">
                  <SearchBar
                    size="large"
                    name="searchInput"
                    value={searchInput || ""}
                    onChange={this.handleSearch}
                    label="Search"
                    placeholder="Search Company,market,etc."
                  ></SearchBar>
                </div>
                <div className="col-sm-4 col-md-4 col-lg-2">
                  <AdvancedFilter
                    filterConfig={advanceFilterConfig}
                    onApplyFilter={this.onAddvanceFilterChange}
                    openSlidingPanel={openSlidingPanel}
                    handleClose={this.handleClose}
                    setIsTotal={this.setIsTotal}
                  />
                  <ReportButtons
                    resetPage={this.resetPage}
                    handleSubmit={this.handleSubmit}
                    handleClickOpen={this.handleClickOpen}
                    handlePrintClick={this.handlePrintClick}
                    exportPDF={this.exportPDF}
                    data={marketAbsorptionReport}
                    columnWidths={columnWidths}
                    uploadColumns={uploadColumns}
                    openFilter={this.state.openFilter}
                    enableFilter={this.enableFilter}
                    isReportPage={true}
                    isListingPage={false}
                    fileName={"AnnualAbsorptionReport.xlsx"}
                    enableFilterButton={this.state.enableFilterButton}
                  />
                </div> */}
              </div>
            
              {showReport && (
                <>
                  {getLeaseCompReport !== null && (
                    <div className="container" style={{ marginTop: "1rem" }}>
                      <TablePagination
                        className="pager no-print"
                        rowsPerPageOptions={this.state.rowsPerPageOptions}
                        component="div"
                        count={getLeaseCompReport.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                      />
                      {/* <div style={{ marginBottom:"5px", marginLeft:"5px", fontSize: "15px" }}> <b> For the period of {startDate} to {endDate}</b> </div> */}
                      <TableContainer
                        className="report-table-scroll"
                        component={Paper}
                      >
                        {this.renderTable(getLeaseCompReport)}
                      </TableContainer>
                    </div>
                  )}
                </>
              )}
            </>
           ) 
          
          }
        </div>
      );
    }
  }
  
import { findIndex, union } from "lodash-es/array";
import { find, map } from "lodash-es/collection";

// Return array index depends upon condition
function getArrayIndex(arr, condition) {
	return findIndex(arr, condition);
}

// Return array object depends upon condition
function getArrayObject(arr, condition) {
	return find(arr, condition);
}

// Return values array depends upon id
function getAllValuesOfId(arr = [], id) {
	return union([], map(arr, id));
}

export { getArrayIndex, getArrayObject, getAllValuesOfId };

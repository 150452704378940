import React from "react";
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	Tooltip,
} from "@material-ui/core";

import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { controlType } from "../../../constants/common";
import _ from "lodash";
import { Switch } from "../Switch";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ClearIcon from "@material-ui/icons/Clear";
import moment from "moment";
import {
	AddCircleOutlined,
	CloudUploadOutlined,
	Edit,
} from "@material-ui/icons";
import Masonry from "react-masonry-css";
import { isInputValid, removeDuplicates } from "../../../utils/helper";
import VisibilityIcon from "@material-ui/icons/Visibility";
import InputMask from "react-input-mask";
import SearchBar from "../AutoSearch/SearchBar";
import SectionHeader from "../PageHeader/SectionHeader";
import { CheckboxLabel } from "../CheckboxLabel/CheckboxLabel";
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
const FilterOptions = createFilterOptions();
const breakpointColumnsObj = {
	default: 2,
	1100: 2,
	700: 2,
	500: 1,
};

class FormGenerator extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedFile: [],
			formElementFields: this.props.formFields,
			updatedFields: [],
			dropDownSourceData: [...(this.props.dropDownSource || [])],
			partialForm: this.props.partialForm || false,
			addNewformComponent: undefined,
			openaddform: false,
			formType: this.props.formType,
			validationMessages: this.props.validationMessages || [],
			ReloadForm: this.props.ReloadForm,
			isDialogOpen: false,
			Editable: this.props.Editable,
			history: this.props.historyData,
			parentKey: this.props.parentKey || "",
			downloadURL : this.props.downloadURL || null,
			//selectedValue:null,
			open:false,
			//isMandatory:this.props.isMandatory || false,
			loading:false,
			loader:false,
		};
	}

	componentDidMount() {}
	setLoadingFlag(e,value,source)
	{
this.setState({
	loading:true
},()=>{this.forceUpdate()});
									source.onInputChange(value);

	}
unSetLoadingFlag()
{
this.setState({
loading:false
},()=>{this.forceUpdate()});
	}
	static getDerivedStateFromProps(newpros, state) {
		if (
			JSON.stringify(newpros.formFields) !==
				JSON.stringify(state.formElementFields) ||
			newpros.ReloadForm !== state.ReloadForm
		) {
			return {
				formElementFields:
					state.inErrorState === true
						? state.formElementFields
						: newpros.formFields,
				ReloadForm: newpros.ReloadForm,
				inErrorState: newpros.inErrorState,
				dropDownSourceData: newpros.dropDownSource,
				validationMessages:
					(newpros.validationMessages || []).length > 0
						? newpros.validationMessages
						: state.validationMessages,
			};
		}
		return null;
	}

	closeDialog = () => {
		this.setState({ isDialogOpen: false });
	};
	 handleScroll = (event) => {
		if (event.deltaY > 0) {
		  this.decreaseValue()
		} else {
		  this.increaseValue()
		}
	}
	 enableScroll = () => {
		document.removeEventListener('wheel', this.preventDefault, false)
	}
	 disableScroll = () => {
		document.addEventListener('wheel', this.preventDefault, {
		  passive: false,
		})
	}
	 preventDefault(e) {
		e = e || window.event
		if (e.preventDefault) {
		  e.preventDefault()
		}
		e.returnValue = false
	  }
	onInputChange = async (e,  value,source,filter) => {
		// // event.preventDefault();
		//  let res=[];
		//  res.push(value);
		// this.setState({selectedValue:res,loading:true});
		//  this.props.onInputChange(value);
		// // this.setState({loading:false});

		value.length > 2 ? this.setState({
			loading: source.key == 'buyer'  ? true : this.state.loading,
			loader: source.key == 'seller' ? true : this.state.loader
		}):null
									await source.onInputChange(value, source.key);
		
// if(isExecuted != undefined)
// {
	this.setState({
		loading:source.key == 'buyer'  ? false : this.state.loading,
		loader: source.key == 'seller' ? false : this.state.loader

	});
//}
	   };
	getChangedData = (obj) => {
		return {};
	};

	renderField = (obj) => {
		// console.log(obj);
		const isEditable =
			this.props.Editable === false ? true : obj.isEditable !== true;
		const { dropDownSourceData } = this.state;
		const changedData = this.getChangedData(obj);
		const { isInErrorState, errorMessage } = obj;
		let componentToBeRendered = <></>;
		const source =
			(dropDownSourceData || []).find(
				(x) =>
					_.camelCase(x.key).toLowerCase().trim() ===
					_.camelCase(obj.key || "")
						.toLowerCase()
						.trim()
			) || {};
		if (obj.attributeValue !== undefined && obj.attributeValue !== null) {
			// defaultValues = (obj.attributeValue || '').split('|');
			obj.attributeValue = obj.attributeValue.replace("&amp;", "&");
		}
		if (obj.renderAsWhen !== undefined) {
			const renderAsWhenkey = obj.renderAsWhen.key;
			const renderAsWhenValue = obj.renderAsWhen.value || "";
			if (
				(
					(
						this.state.formElementFields.find(
							(x) => _.camelCase(x.dbColumn) === _.camelCase(renderAsWhenkey)
						) || {}
					).attributeValue || ""
				).toString() === renderAsWhenValue.toString()
			) {
				obj.type = obj.renderAsWhen.type;
			}
		}

		if (obj.type === controlType.DropDown) {
			let sourceData = source.source || [];
			let defaultValues = [];
			if (obj.multiple) {
				if (obj.attributeValue !== undefined && obj.attributeValue !== null) {
					defaultValues = (obj.attributeValue || "").split("|");
				}
				obj.displayName = obj.displayName.replace("(s)", "") + "(s)";
			} else {
				obj.displayName = obj.displayName.replace("(s)", "");

				defaultValues.push(obj.attributeValue);
			}
			

			if (source.filterSourceOn && source.applyFilter !== false) {
				source.filterSourceOn.forEach((ele) => {
					const idValue = [];
					if (typeof ele.key === "string") {
						idValue.push(
							(
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(ele.key)
								) || {}
							).attributeValue
						);
					} else {
						ele.key.forEach((elementkey) => {
							const val = (
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(elementkey)
								) || {}
							).attributeValue;
							idValue.push(val);
						});
					}

					if (idValue || source.filterMode === "strict") {
						sourceData = sourceData.filter((x) =>
							(idValue || []).find(
								(val) =>
									(x[ele.filterBy] || "").toString() ===
										(val || "").toString() || x[source.idField] < 0
							)
						);
					}
				});
			}
			if (source.filterMode === "AppendExisting") {
				sourceData = source.source.filter((x) =>
					defaultValues.find((v) => v === x[source.option])
				);
			}
			
			defaultValues = defaultValues.filter((x) =>
				sourceData.find((y) => y[source.option] === x)
			);
			if(source.value !== undefined){
				if (source.value.indexOf('|') == -1)
					defaultValues.push(source.value);
			}
			
			defaultValues = removeDuplicates(defaultValues);
			
			// if (obj.attributeValue !== defaultValues)
			// {
			//         this.onChangeValues(defaultValues, obj.dbColumn, sourceIdField, obj.multiple, source);

			// }
			if (
				
				obj.isEditable === true &&
				source.AddNewOption === true &&
				source.AddOptionType !== "Button"
			) {
				const addOption = {};
				addOption[source.option] = source.AddNewOptiontitle;
				sourceData.push(addOption);
			}
			componentToBeRendered = (
				<>
					{isEditable == false && source.AddNewOption === true && source.AddOptionType === "Button" ? (
						<div className="row no-padding">
							<div className="col-xs-12 no-padding">
								{this.renderAutofill(
									isEditable,
									obj,
									sourceData,
									source,
									defaultValues,
									isInErrorState,
									errorMessage
								)}
							</div>
							{/* {obj.isEditable === true && (
								<div className="col-xs-1">
									<IconButton
										style={{ backgroundColor: "#ffff" }}
										aria-label={"addnewrow" + source.Key}
										size="small"
										onClick={() => {
											source.onAddClick &&
												source.onAddClick({ ...source }, "", false);
											// this.setState({ openaddform: true, addformtitle: source.AddNewOptiontitle, addNewformComponent: source.AddOptionComponent });
										}}
									>
										{obj.isAdded ? (
											<Edit
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										) : (
											<AddCircleOutlined
												style={{ backgroundColor: "#ffff" }}
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										)}
									</IconButton>
								</div>
							)} */}
						</div>
					) : (
						this.renderAutofill(
							isEditable,
							obj,
							sourceData,
							source,
							defaultValues,
							isInErrorState,
							errorMessage
						)
					)}
				</>
			);
		} else if (obj.type === controlType.Autofill) {
			let sourceData = source.source || [];
			let defaultValues = [];
			if (obj.multiple) {
				if (obj.attributeValue !== undefined && obj.attributeValue !== null) {
					defaultValues = (obj.attributeValue || "").split("|");
				}
				obj.displayName = obj.displayName.replace("(s)", "") + "(s)";
			} else {
				obj.displayName = obj.displayName.replace("(s)", "");

				defaultValues.push(obj.attributeValue);
			}

			if (source.filterSourceOn && source.applyFilter !== false) {
				source.filterSourceOn.forEach((ele) => {
					const idValue = [];
					if (typeof ele.key === "string") {
						idValue.push(
							(
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(ele.key)
								) || {}
							).attributeValue
						);
					} else {
						ele.key.forEach((elementkey) => {
							const val = (
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(elementkey)
								) || {}
							).attributeValue;
							idValue.push(val);
						});
					}

					if (idValue || source.filterMode === "strict") {
						sourceData = sourceData.filter((x) =>
							(idValue || []).find(
								(val) =>
									(x[ele.filterBy] || "").toString() ===
										(val || "").toString() || x[source.idField] < 0
							)
						);
					}
				});
			}
			if (source.filterMode === "AppendExisting") {
				sourceData = source.source.filter((x) =>
					defaultValues.find((v) => v === x[source.option])
				);
			}
			defaultValues = defaultValues.filter((x) =>
				sourceData.find((y) => y[source.option] === x)
			);
			defaultValues = removeDuplicates(defaultValues);
			// if (obj.attributeValue !== defaultValues)
			// {
			//         this.onChangeValues(defaultValues, obj.dbColumn, sourceIdField, obj.multiple, source);

			// }
			if (
			
				obj.isEditable === true &&
				source.AddNewOption === true &&
				source.AddOptionType !== "Button"
			) {
				const addOption = {};
				addOption[source.option] = source.AddNewOptiontitle;
				sourceData.push(addOption);
			}
			componentToBeRendered = (
				<>
					{isEditable == false && source.AddNewOption === true && source.AddOptionType === "Button" ? (
						<div className="row no-padding">
							<div className="col-xs-12 no-padding">
								{this.renderAutoComplete(
									isEditable,
									obj,
									sourceData,
									source,
									defaultValues,
									isInErrorState,
									errorMessage
								)}
							</div>
							{/* {obj.isEditable === true && (
								<div className="col-xs-1">
									<IconButton
										aria-label={"addnewrow" + source.Key}
									
										size="small"
										onClick={() => {
											source.onAddClick &&
												source.onAddClick({ ...source }, "", false);
											}}
									>
										{obj.isAdded ? (
											<Edit
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										) : (
											<AddCircleOutlined
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										)}
									</IconButton>
								</div>
							)} */}
						</div>
					) : (
						this.renderAutofillSearch(
							isEditable,
							obj,
							sourceData,
							source,
							defaultValues,
							isInErrorState,
							errorMessage
						)
					)}
				</>
			);
		} else if (obj.type === controlType.AutofillWithAdd) {
			let sourceData = source.source || [];
			let defaultValues = [];

				obj.displayName = obj.displayName.replace("(s)", "");
				defaultValues.push(obj.attributeValue);

			if (source.filterSourceOn && source.applyFilter !== false) {
				source.filterSourceOn.forEach((ele) => {
					const idValue = [];
					if (typeof ele.key === "string") {
						idValue.push(
							(
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(ele.key)
								) || {}
							).attributeValue
						);
					} else {
						ele.key.forEach((elementkey) => {
							const val = (
								this.state.formElementFields.find(
									(x) => _.camelCase(x.dbColumn) === _.camelCase(elementkey)
								) || {}
							).attributeValue;
							idValue.push(val);
						});
					}

					if (idValue || source.filterMode === "strict") {
						sourceData = sourceData.filter((x) =>
							(idValue || []).find(
								(val) =>
									(x[ele.filterBy] || "").toString() ===
										(val || "").toString() || x[source.idField] < 0
							)
						);
					}
				});
			}
			if (source.filterMode === "AppendExisting") {
				sourceData = source.source.filter((x) =>
					defaultValues.find((v) => v === x[source.option])
				);
			}
			defaultValues = defaultValues.filter((x) =>
				sourceData.find((y) => y[source.option] === x)
			);

			if(source.value !== undefined){
				defaultValues.push(source.value);
			}

			defaultValues = removeDuplicates(defaultValues);
			if (
				obj.isEditable === true &&
				source.AddNewOption === true &&
				source.AddOptionType !== "Button"
			) {
				const addOption = {};
				addOption[source.option] = source.AddNewOptiontitle;
				sourceData.push(addOption);
			}
			componentToBeRendered = (
				<>
					{isEditable == false && source.AddNewOption === true && source.AddOptionType === "Button" ? (
						<div className="row no-padding">
							<div className="col-xs-12 no-padding">
								{this.renderAutofillWithAdd(
									isEditable,
									obj,
									sourceData,
									source,
									defaultValues,
									isInErrorState,
									errorMessage
								)}
							</div>
						</div>
					) : (
						this.renderAutofill(
							isEditable,
							obj,
							sourceData,
							source,
							defaultValues,
							isInErrorState,
							errorMessage
						)
					)}
				</>
			);
		} 

		//     else if (obj.type === controlType.Autofill) {
		//       let defaultValues = [];
		//       if (obj.multiple) {
		//         if (obj.attributeValue !== undefined && obj.attributeValue !== null) {
		//           defaultValues = (obj.attributeValue || '').split('|');
		//         }
		//         obj.displayName = obj.displayName.replace('(s)', '') + '(s)';
		//       } else {
		//         obj.displayName = obj.displayName.replace('(s)', '');

		//         defaultValues.push(obj.attributeValue);
		//       }
		//       componentToBeRendered = (
		//         <Autocomplete
		//           freeSolo
		//           error={isInErrorState}
		//           helperText={errorMessage}
		//           disabled={isEditable}
		//           multiple={obj.multiple || false}
		//           value={obj.multiple === true ? defaultValues : obj.attributeValue || ''}
		//           key={
		//             this.state.parentKey +
		//             'Autocomplete_dropDown_filter' +
		//             obj.dbColumn +
		//             obj.sectionId
		//           }
		//           id={'AutoCompletedropDown_filter' + obj.dbColumn + obj.sectionId}

		// filterOptions={(options, params) => {
		//   const filtered = FilterOptions(options, params);

		//   if (params.inputValue !== '') {
		//     const a = {};
		//     a[source.option] = `Add "${params.inputValue}"`;
		//     a.inputValue = params.inputValue;
		//     filtered.push(a);
		//   }

		//   return filtered;
		// }}
		//           onChange={(event, newValue) => {
		//             const sourceIdField = {};
		//             if (typeof newValue === 'string') {
		//               sourceIdField.key = source.idField;
		//               sourceIdField.value = -1;
		//             } else if (newValue && newValue.inputValue) {
		//               // Create a new value from the user input
		//               sourceIdField.key = source.idField;
		//               sourceIdField.value = -1;
		//             }

		//             if (source.idField) {
		//               const selectedValue =
		//                 source.source.find(
		//                   (x) => x[_.camelCase(source.option)] === newValue.inputValue
		//                 ) || {};
		//               if (selectedValue) {
		//                 sourceIdField.key = source.idField;
		//                 sourceIdField.value =
		//                   selectedValue[
		//                     _.camelCase(source.optionIdField || source.idField)
		//                   ];
		//               }
		//             } else {
		//               sourceIdField.key = source.idField;
		//               sourceIdField.value = -1;
		//             }
		//             this.onChangeValues(
		//               newValue.inputValue,
		//               obj,
		//               sourceIdField,
		//               obj.multiple,
		//               source
		//             );
		//           }
		//           }
		//           options={(source.source || [])}
		//         getOptionLabel={(option) => {
		//           // e.g value selected with enter, right from the input
		//           if (typeof option === 'string') {
		//             return option;
		//           }
		//           if (option.inputValue) {
		//             return option.inputValue;
		//           }
		//           return option[source.option];
		//         }}
		//         selectOnFocus
		//         clearOnBlur
		//         handleHomeEndKeys
		//         renderOption={(option) => option[source.option]}
		//        // autoHighlight
		//         renderInput={(params) => (
		//           <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
		//           <label htmlFor="filled-hidden-label-small">{obj.displayName}</label>
		//           <TextField
		//             error={isInErrorState}
		//             errorMessage={errorMessage}
		//             {...params}
		//             style={{  border: this.props.Editable ? '1px solid': '0px' }}
		//              hiddenLabel
		//             margin="normal"
		//           />
		//           </Box>
		//         )}
		//         />
		//       );
		//     }
		else if (obj.type === controlType.DatePicker) {
			
			let selectedDate = obj.attributeValue == undefined || obj.attributeValue == null ? null :
			moment(obj.attributeValue).format("MM/DD/YYYY");
			if (moment(selectedDate).isValid() !== true) {
				selectedDate = null;
			}
			componentToBeRendered = (
				<MuiPickersUtilsProvider
					utils={DateFnsUtils}
					key={
						this.state.parentKey +
						"_datePicker_filter" +
						obj.dbColumn +
						obj.sectionId +
						obj.sectionDetailId
					}
				>
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">
							{obj.displayName}
							{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
							{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							
						</label>

						<Grid container>
							<KeyboardDatePicker
							   key={`datePicker:${selectedDate}`}
								inputProps={{ readOnly: true }}
								size="small"
								className={isEditable ? 'disabed-MuiIconButton-label':null}
								disabled={isEditable}
								error={isInErrorState}
								helperText={errorMessage}
								clearable={true.toString()}
								//disableToolbar
								variant="inline"
								format="MM/dd/yyyy"
								margin="normal"
								fullWidth
								style={{ border: this.props.Editable ? "1px solid" : "0px" }}
								id="date-picker-inline"
								value={selectedDate || ""}
								autoOk
								onChange={(date) => {
									if (
										date !== "" &&
										moment(date).format("MM/DD/YYYY") !== "Invalid date"
									) {
										this.onChangeValues(moment(date).format("MM/DD/YYYY"), obj);
									} else {
										this.onChangeValues(null, obj);
									}
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								InputProps={{
									endAdornment: obj.attributeValue && (
										<IconButton
											disabled={isEditable}
											size="small"
											onClick={() => {
												this.onChangeValues(null, obj);
											}}
										>
											<ClearIcon size="small" />
										</IconButton>
									),
								}}
								InputAdornmentProps={{
									position: "start",
								}}
							/>
						</Grid>
					</Box>
				</MuiPickersUtilsProvider>
			);
		} else if (obj.type === controlType.DateMonthPicker) {
			let selectedDate = moment(obj.attributeValue).format("MM/YYYY");
			if (moment(selectedDate).isValid() !== true) {
				selectedDate = null;
			}
			componentToBeRendered = (
				<MuiPickersUtilsProvider
					utils={DateFnsUtils}
					key={
						this.state.parentKey +
						"_datePicker_filter" +
						obj.dbColumn +
						obj.sectionId +
						obj.sectionDetailId
					}
				>
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">
							{obj.displayName}
							{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
							{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							
						</label>

						<Grid container>
							<KeyboardDatePicker
								inputProps={{ readOnly: true }}
								size="small"
								openTo="year"
								views={["year", "month"]}
								disabled={isEditable}
								error={isInErrorState}
								helperText={errorMessage}
								clearable={true.toString()}
								autoOk
								//disableToolbar
								variant="inline"
								format="MM/yyyy"
								margin="normal"
								fullWidth
								id="date-picker-inline"
								value={selectedDate || ""}
								onChange={(date) => {
									if (
										date !== "" &&
										moment(date).format("MM/DD/YYYY") !== "Invalid date"
									) {
										this.onChangeValues(moment(date).format("MM/DD/YYYY"), obj);
									} else {
										this.onChangeValues(null, obj);
									}
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								InputProps={{
									endAdornment: obj.attributeValue && (
										<IconButton
											disabled={isEditable}
											size="small"
											onClick={() => {
												this.onChangeValues(null, obj);
											}}
										>
											<ClearIcon size="small" />
										</IconButton>
									),
								}}
								InputAdornmentProps={{
									position: "start",
								}}
							/>
						</Grid>
					</Box>
				</MuiPickersUtilsProvider>
			);
		} else if (obj.type === controlType.DateYearPicker) {
			let selectedDate = moment(obj.attributeValue).format("MM/YYYY");
			if (moment(selectedDate).isValid() !== true) {
				selectedDate = null;
			}
			componentToBeRendered = (
				<MuiPickersUtilsProvider
					utils={DateFnsUtils}
					key={
						this.state.parentKey +
						"_datePicker_filter" +
						obj.dbColumn +
						obj.sectionId +
						obj.sectionDetailId
					}
				>
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">
							{obj.displayName}
							{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
							{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							
						</label>

						<Grid container>
							<KeyboardDatePicker
								inputProps={{ readOnly: true }}
								size="small"
								//openTo="year"
								views={["year"]}
								disabled={isEditable}
								error={isInErrorState}
								helperText={errorMessage}
								clearable={true.toString()}
								disableToolbar
								variant="inline"
								format="MM/yyyy"
								margin="normal"
								fullWidth
								id="date-picker-inline"
								value={selectedDate || ""}
								onChange={(date) => {
									if (
										date !== "" &&
										moment(date).format("MM/DD/YYYY") !== "Invalid date"
									) {
										this.onChangeValues(moment(date).format("MM/DD/YYYY"), obj);
									} else {
										this.onChangeValues(null, obj);
									}
								}}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								InputProps={{
									endAdornment: obj.attributeValue && (
										<IconButton
											disabled={isEditable}
											size="small"
											onClick={() => {
												this.onChangeValues(null, obj);
											}}
										>
											<ClearIcon size="small" />
										</IconButton>
									),
								}}
								InputAdornmentProps={{
									position: "start",
								}}
							/>
						</Grid>
					</Box>
				</MuiPickersUtilsProvider>
			);
		} else if (obj.type === controlType.Checkbox) {
			componentToBeRendered = (
				
				<CheckboxLabel
					isChecked={obj.attributeValue === "1"}
					isEditable={isEditable}
					onChangeValues={(checked, key) => {
						let value = "0";
						if (checked === true) {
							value = "1";
						}
						this.onChangeValues(value, obj);
					}}
					id={
						this.props.parentKey +
						`switch-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					Key={
						this.props.parentKey +
						`switch-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					obj={obj}
					labelText={obj.displayName}
					fieldKey={obj.dbColumn}
					fullWidth
				/>
			);
		} else if (obj.type === controlType.Switch) {
			componentToBeRendered = (
				<Switch
					isChecked={obj.attributeValue === "1"}
					disabled={isEditable}
					onChangeValues={(checked, key) => {
						let value = "0";
						if (checked === true) {
							value = "1";
						}
						this.onChangeValues(value, obj);
					}}
					id={
						this.props.parentKey +
						`switch-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					Key={
						this.props.parentKey +
						`switch-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					obj={obj}
					labelText={obj.displayName}
					fieldKey={obj.dbColumn}
					fullWidth
				/>
			);
		} else if (
			obj.type === controlType.File ||
			obj.type === controlType.FileInput
		) {
			componentToBeRendered = (
				<>
					<div className="row">
						<div className="col-sm-10 no-padding">
							<TextField
								error={isInErrorState}
								helperText={errorMessage}
								disabled={true}
								id={
									this.props.parentKey +
									`text-field-${
										obj.sectionDetailId + obj.dbColumn + obj.sectionId
									}`
								}
								key={
									this.props.parentKey +
									`text-field-${
										obj.sectionDetailId + obj.dbColumn + obj.sectionId
									}`
								}
								fullWidth
								value={obj.attributeValue || ""}
							/>
						</div>
						{isEditable === false && (
							<div className="col-sm-1">
								<Tooltip title="Upload Document">
									<IconButton
										className="action-button"
										variant="contained"
										size="small"
										component="label"
									>
										<CloudUploadOutlined size="small"></CloudUploadOutlined>
										<input
											type="file"
											hidden
											onChange={(event) => {
												this.onFileChange(event, obj);
											}}
											accept="application/pdf"
										/>
									</IconButton>
								</Tooltip>
							</div>
						)}
						<div className="col-sm-1">
							{obj.attributeValue !== null ? (
								<>
									<Tooltip title="View Document">
										<IconButton className="action-button" size="small">
											<VisibilityIcon
												size="small"
												onClick={(e) => {
													this.props.viewPDF(obj, obj.attributeValue);
												}}
											/>
										</IconButton>
									</Tooltip>
								</>
							) : null}
						</div>
					</div>
				</>
			);
		} else if (obj.type === controlType.PhoneNumber) {
			let inpProps = {};
			if (
				_.camelCase(obj.dataFormat) === _.camelCase("PhoneNumber") ||
				_.camelCase(obj.dataFormat) === _.camelCase("PhoneNumber")
			) {
				inpProps = {
					startAdornment: (
						<InputAdornment position="start">(+1)</InputAdornment>
					),
				};
			}
			componentToBeRendered = (
				<Box
				sx={{ display: "flex", alignItems: "center" }}
				className="filter-label"
			>
				<label htmlFor="filled-hidden-label-small">
				{obj.displayName}
				{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
				{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
									this.props.getHistoryDataByField(obj);
								}}
			></AccessAlarmIcon> :null}
				</label>
				<InputMask
					mask="999-999-9999"
					maskChar=" "
					disabled={isEditable}
					id={
						this.props.parentKey +
						`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					key={
						this.props.parentKey +
						`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
					}
					fullWidth
					value={obj.attributeValue || ""}
					onChange={(event) => {
						this.onChangeValues(
							event.target.value,
							obj,
							undefined,
							false,
							source
						);
					}}
				>
					{() => (
							<TextField
								InputProps={inpProps}
								error={isInErrorState}
								helperText={errorMessage}
								margin="normal"
								size="small"
								color="primary"
								className="editable"
								style={{ border: this.props.Editable ? "1px solid" : "0px" ,    width: '100%',height:'30px'}}
								hiddenLabel
							/>						
						
					)} 
				</InputMask>
				</Box>
			);
		} else if (obj.type === controlType.TextArea) {
			componentToBeRendered = (
				<Box
					sx={{ display: "flex", alignItems: "center" }}
					className="filter-label"
				>
					<label htmlFor="filled-hidden-label-small">{obj.displayName}
					{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
					{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							

					</label>

					<TextField
						error={isInErrorState}
						helperText={errorMessage}
						disabled={isEditable}
						id={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						key={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						style={{ border: this.props.Editable ? "1px solid" : "0px" }}
						hiddenLabel
						fullWidth
						value={obj.attributeValue || ""}
						aria-label={obj.displayName}
						multiline
						rows="3"
						className="filter-label"
						onChange={(event) => {
							this.onChangeValues(
								event.target.value,
								obj,
								undefined,
								false,
								source
							);
						}}
					/>
				</Box>
			);
		} else if (obj.type === controlType.Link) {
			componentToBeRendered = (
				<Box
					sx={{ display: "flex", alignItems: "center" }}
					className="filter-label"
				>
					<label htmlFor="filled-hidden-label-small">{obj.displayName}
					{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
					{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							

					</label>
					<br/><br/>
					<a
						href={this.props.downloadURL}
						error={isInErrorState}
						helperText={errorMessage}
						disabled={isEditable}
						id={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						key={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						//style={{ border: this.props.Editable ? "1px solid" : "0px" }}
						hiddenLabel
						fullWidth
						//value={obj.attributeValue || ""}
						aria-label={obj.displayName}
						multiline
						rows="3"
						className="filter-label"
						download="ImportTemplate.xlsx"
						//onChange={(event) => {
						//	this.onChangeValues(
						//		event.target.value,
						//		obj,
						//		undefined,
						//		false,
						//		source
						//	);
						//}}
					>
						{obj.attributeValue}
					</a>
				</Box>
			);
		} else {
			let inpProps = {};
			if (
				_.camelCase(obj.dataFormat) === _.camelCase("MoneyDecimal") ||
				_.camelCase(obj.dataFormat) === _.camelCase("MoneyNumber")
			) {
				inpProps = {
					startAdornment: <InputAdornment position="start">$</InputAdornment>,
				};
			}
			if (_.camelCase(obj.dbColumn) === _.camelCase("AreaSF")) {
				inpProps = {
					startAdornment: <InputAdornment position="end">sf</InputAdornment>,
				};
			}
			if (
				_.camelCase(obj.dataFormat) === _.camelCase("DecimalPercent") ||
				_.camelCase(obj.dataFormat) === _.camelCase("Percent")
			) {
				inpProps = {
					endAdornment: <InputAdornment position="end">%</InputAdornment>,
				};
			}
		
			componentToBeRendered = (
				<Box
					sx={{ display: "flex", alignItems: "center" }}
					className="filter-label"
				>
					<label htmlFor="filled-hidden-label-small">
						{obj.displayName}
						{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
						{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
						
					</label>
					<TextField
						error={isInErrorState}
						helperText={errorMessage}
						disabled={isEditable}
						id={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						key={
							this.props.parentKey +
							`text-field-${obj.sectionDetailId + obj.dbColumn + obj.sectionId}`
						}
						InputProps={inpProps}
						style={{ border: this.props.Editable ? "1px solid" : "0px" }}
						hiddenLabel
						fullWidth
						value={obj.attributeValue || ""}
						onChange={(event) => {
							this.onChangeValues(
								event.target.value,
								obj,
								undefined,
								false,
								source
							);
						}}
					/>
					{/* {obj.isModified == true ? <AccessAlarmIcon></AccessAlarmIcon> :null} */}
				</Box>
			);
		}
		return (
			<div className="row">
				<div
					className={
						"col-xs-" +
						(this.props.innerFieldColumn || 12) +
						" no-padding field-container"
					}
				>
					{componentToBeRendered}
				</div>
			</div>
		);
	};

	onFileChange = (event, obj) => {
		if (event.target && !event.target.files.length) return;
		Array.from(event.target.files).map((file) => {
			if (obj.uploadToSp === true) {
				this.props.getUploadedFile(file, obj.category);
				this.onChangeValues(file.name, obj);
				this.onChangeValues(
					obj.category,
					this.state.formElementFields.find(
						(y) => _.camelCase(y.dbColumn) === _.camelCase(obj.contentKey)
					) || {}
				);
			} else {
				this.getBase64(file, (result) => {
					//console.log(result);
					this.onChangeValues(
						result,
						this.state.formElementFields.find(
							(y) => _.camelCase(y.dbColumn) === _.camelCase(obj.contentKey)
						) || {}
					);
				});
				this.onChangeValues(file.name, obj);
			}
		});
	};

	getBase64 = (file, cb) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {};
	};

	getFileData = (contentKey) => {
		const BlobString = this.state.formElementFields.find(
			(x) => _.camelCase(x.dbColumn) === _.camelCase(contentKey)
		);
		if (BlobString) {
			// const blob = this.base64toBlob(BlobString.attributeValue);
			// const url = URL.createObjectURL(blob);
			return BlobString.attributeValue;
		}
		return undefined;
	};

	base64toBlob = (data) => {
		// Cut the prefix `data:application/pdf;base64` from the raw base 64
		const base64WithoutPrefix = data.substr(
			"data:application/pdf;base64,".length
		);

		const bytes = atob(base64WithoutPrefix);
		let length = bytes.length;
		const out = new Uint8Array(length);

		while (length--) {
			out[length] = bytes.charCodeAt(length);
		}

		return new Blob([out], { type: "application/pdf" });
	};

	onChangeValues = (
		val,
		obj,
		sourceIdField,
		isMultiple,
		source = undefined
	) => {
		const formFieldsObj = [...this.state.formElementFields];
		if (source && source.localChanges === false && source.onChange) {
			source.onChange(val, sourceIdField, isMultiple);
			return;
		}
		(
			formFieldsObj.find(
				(field) => _.camelCase(field.dbColumn) === _.camelCase(obj.dbColumn)
			) || {}
		).attributeValue =
			isMultiple === true
				? val.join("|")
				: typeof val === "object" && val !== undefined
					? (val || {})[_.camelCase(obj.dbColumn)]
					: val;

		obj = isInputValid(
			isMultiple === true
				? val.join("|")
				: typeof val === "object" && val !== undefined
					? (val || {})[_.camelCase(obj.dbColumn)]
					: val,
			obj,
			this.state.formElementFields
		);
		(
			formFieldsObj.find(
				(field) => _.camelCase(field.dbColumn) === _.camelCase(obj.dbColumn)
			) || {}
		).isInErrorState = obj.isInErrorState;
		(
			formFieldsObj.find(
				(field) => _.camelCase(field.dbColumn) === _.camelCase(obj.dbColumn)
			) || {}
		).errorMessage = obj.errorMessage;
		
		
		if (sourceIdField !== undefined && val !== undefined) {
			(
				formFieldsObj.find(
					(obj) => _.camelCase(obj.dbColumn) === _.camelCase(sourceIdField.key)
				) || {}
			).attributeValue =
				isMultiple === true
					? sourceIdField.value.join("|")
					: typeof val === "object" && val !== undefined
						? (val || {})[_.camelCase(sourceIdField.key)]
						: sourceIdField.value;
		}
		
		if (
			source === undefined ||
			source.validateBeforeChange === undefined ||
			source.validateBeforeChange(val, obj.dbColumn, sourceIdField, isMultiple)
		) {
			this.setState(
				{
					formElementFields: formFieldsObj,
				},
				() => {
					source &&
						source.onChange &&
						source.onChange(val, sourceIdField, isMultiple);
					if (this.state.partialForm === true) {
						this.props.saveChanges(formFieldsObj);
					}
				}
			);
		}
	};

	renderAutofill = (
		isEditable,
		obj,
		sourceData,
		source,
		defaultValues,
		isInErrorState,
		errorMessage
	) => {
		// if(source.defaultData != undefined && source.defaultData.defaultOwnerType.length>0 )
		//     {
		//       source.filter(m=>{
		// m.companyRoleId==source.defaultData.defaultOwnerType[0].companyRoleId
		//       })
		//     }
		// defaultValues=source.defaultData.defaultOwnerType;
		return (
			<Autocomplete
				size="small"
				 className={isEditable ? 'disabed-MuiIconButton-label':null}
				error={isInErrorState}
				helperText={errorMessage}
				disabled={isEditable}
				multiple={obj.multiple || false}
				options={(sourceData || []).map((option) => option[source.option])}
				value={obj.multiple === true ? defaultValues : obj.attributeValue || ""}
				key={
					this.state.parentKey +
					"_dropDown_filter" +
					obj.dbColumn +
					obj.sectionId
				}
				id={"dropDown_filter" + obj.dbColumn + obj.sectionId}
				onChange={(event, value) => {
					if (value === source.AddNewOptiontitle) {
						source.onAddClick && source.onAddClick({ ...source });

						// this.setState({ openaddform: true, addformtitle: value, addNewformComponent: source.AddOptionComponent });
					} else {
						let sourceIdField = {};

						if (source.idField && obj.multiple === true) {
							sourceIdField.key = source.idField;
							const SelectedRecordMultiple = [];
							value.forEach((sourceValue) => {
								const filteredDataSource = sourceData.find(
									(x) => x[_.camelCase(source.option)] === sourceValue
								);
								filteredDataSource &&
									SelectedRecordMultiple.push(
										filteredDataSource[
											_.camelCase(source.optionIdField || source.idField)
										]
									);
							});
							
							sourceIdField.key = source.idField;
							sourceIdField.value = SelectedRecordMultiple;
						} else if (source.idField) {
							const selectedValue =
								sourceData.find(
									(x) => x[_.camelCase(source.option)] === value
								) || {};
							if (selectedValue) {
								sourceIdField.key = source.idField;
								sourceIdField.value =
									selectedValue[
										_.camelCase(source.optionIdField || source.idField)
									];
							}
						} else {
							sourceIdField = undefined;
						}
						this.onChangeValues(
							value,
							obj,
							sourceIdField,
							obj.multiple,
							source
						);
						// source.onChange && source.onChange(value, sourceIdField, obj.multiple);
					}
				}}
				//autoHighlight
				renderInput={(params) => (
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">
							{obj.displayName}
							{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
							{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							
						</label>

						<TextField
							{...params}
							error={isInErrorState}
							errorMessage={errorMessage}
							margin="normal"
							style={{ border: this.props.Editable ? "1px solid" : "0px" }}
							hiddenLabel
						/>
						
					</Box>
				)}
			/>
		);
	};


	renderAutofillSearch = (
		isEditable,
		obj,
		sourceData,
		source,
		defaultValues,
		isInErrorState,
		errorMessage
	) => {
		const loader=this.state.loader;
		// if(source.defaultData != undefined && source.defaultData.defaultOwnerType.length>0 )
		//     {
		//       source.filter(m=>{
		// m.companyRoleId==source.defaultData.defaultOwnerType[0].companyRoleId
		//       })
		//     }
		// defaultValues=source.defaultData.defaultOwnerType;
		return (
			<Autocomplete
				size="small"
				freeSolo
				 className={isEditable ? 'disabed-MuiIconButton-label':null}
				error={isInErrorState}
				helperText={errorMessage}
				disabled={isEditable}
				multiple={obj.multiple || false}
				options={(sourceData || []).map((option) => option[source.option])}
				value={obj.multiple === true ? defaultValues : obj.attributeValue || ""}
				key={
					this.state.parentKey +
					"_dropDown_filter" +
					obj.dbColumn +
					obj.sectionId
				}
				id={"dropDown_filter" + obj.dbColumn + obj.sectionId}
				onChange={(event, value) => {
					
					if (value === source.AddNewOptiontitle) {
						source.onAddClick && source.onAddClick({ ...source });

						// this.setState({ openaddform: true, addformtitle: value, addNewformComponent: source.AddOptionComponent });
					} else {
						let sourceIdField = {};

						if (source.idField && obj.multiple === true) {
							sourceIdField.key = source.idField;
							const SelectedRecordMultiple = [];
							value.forEach((sourceValue) => {
								const filteredDataSource = sourceData.find(
									(x) => x[_.camelCase(source.option)] === sourceValue
								);
								filteredDataSource &&
									SelectedRecordMultiple.push(
										filteredDataSource[
											_.camelCase(source.optionIdField || source.idField)
										]
									);
							});

							sourceIdField.key = source.idField;
							sourceIdField.value = SelectedRecordMultiple;
						} else if (source.idField) {
							const selectedValue =
								sourceData.find(
									(x) => x[_.camelCase(source.option)] === value
								) || {};
							if (selectedValue) {
								sourceIdField.key = source.idField;
								sourceIdField.value =
									selectedValue[
										_.camelCase(source.optionIdField || source.idField)
									];
							}
						} else {
							sourceIdField = undefined;
						}
						this.onChangeValues(
							value,
							obj,
							sourceIdField,
							obj.multiple,
							source
						);
						// source.onChange && source.onChange(value, sourceIdField, obj.multiple);
					}
				}}

				onInputChange={(event, value) => {
					if(event && (event.type == 'change' || event.type == 'input' )  && value != "" && value != null )//&& value.length > 2 )//&& event.constructor.name === "SyntheticBaseEvent" 
					{				  event.preventDefault();		
						 //  this.setState({selectedValue:value,loading:true});
						 this.onInputChange(event, value,source)
							//source.onInputChange(value) 
					}
		 
					   }}
				//autoHighlight
				renderInput={(params) => (
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">
							{obj.displayName}
							{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
							{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							
						</label>

						<TextField
							{...params}
							error={isInErrorState}
							errorMessage={errorMessage}
							margin="normal"
							placeholder="Type at least 3 characters to search"
							style={{ border: this.props.Editable ? "1px solid" : "0px" }}
							hiddenLabel
							InputProps={{
								...params.InputProps,
								endAdornment: (
								  <React.Fragment>
									{ loader ? <CircularProgress  size={20} /> : null}
									{params.InputProps.endAdornment}
								  </React.Fragment>
								),
							  }}
						/>
						
					</Box>
				)}
			/>
		);
	};
	Open=() => {
		this.setState({open:true})
	  }
	  Close=() => {
		this.setState({open:false})
	  }

	renderAutoComplete = (
		isEditable,
		obj,
		sourceData,
		source,
		defaultValues,
		isInErrorState,
		errorMessage
	) => 
	{
		const loading = this.state.loading;
		return (
			<Autocomplete
				size="small"
				freeSolo
				className={isEditable ? 'disabed-MuiIconButton-label':null}
				disabled={isEditable}
				error={isInErrorState}
				errorMessage={errorMessage}
				multiple={obj.multiple || false}
				value={defaultValues == [] ? '' : obj.attributeValue}
				key={
					this.state.parentKey +
					"_dropDown_filter" +
					obj.dbColumn +
					obj.sectionId +
					obj.sectionDetailId
				}
				id={
					"dropDown_filter" + obj.dbColumn + obj.sectionId + obj.sectionDetailId
				}
				onChange={(event, value) => {
					if (typeof value === "string") {
						source.onAddClick && source.onAddClick({ ...source }, value);
					} else if (value && value.inputValue) {
						source.onAddClick &&
							source.onAddClick({ ...source }, value.inputValue);
					} else {
						let sourceIdField = {};

						if (source.idField && obj.multiple === true) {
							sourceIdField.key = source.idField;
							const SelectedRecordMultiple = [];
							const SelectedMultiple = [];

							value.forEach((sourceValue) => {
								const filteredDataSource = sourceData.find(
									(x) =>
										x[_.camelCase(source.option)] ===
										(typeof sourceValue === "object"
											? sourceValue[source.option]
											: sourceValue)
								);
								if (filteredDataSource) {
									SelectedRecordMultiple.push(
										filteredDataSource[
											_.camelCase(source.optionIdField || source.idField)
										]
									);
									SelectedMultiple.push(
										filteredDataSource[_.camelCase(source.option)]
									);
								}
							});

							sourceIdField.key = source.idField;
							sourceIdField.value = SelectedRecordMultiple;
							this.onChangeValues(
								SelectedMultiple,
								obj,
								sourceIdField,
								obj.multiple,
								source
							);
							// source.onChange && source.onChange(SelectedMultiple, sourceIdField, obj.multiple);
						} else {
							if (source.idField) {
								const selectedValue =
									sourceData.find(
										(x) =>
											x[_.camelCase(source.option)] ===
											(value || {})[source.option]
									) || {};
								if (selectedValue) {
									sourceIdField.key = source.idField;
									sourceIdField.value =
										selectedValue[
											_.camelCase(source.optionIdField || source.idField)
										];
								}
							} else {
								sourceIdField = undefined;
							}
							this.onChangeValues(
								(value || {})[source.option],
								obj,
								sourceIdField,
								obj.multiple,
								source
							);
							// source.onChange && source.onChange((value || {})[source.option], sourceIdField, obj.multiple);
						}
					}
				}}
				filterOptions={(options, params) => {
					const filtered = FilterOptions(options, params);

					if (params.inputValue !== "") {
						const a = {};
						a[source.option] = `Add "${params.inputValue}"`;
						a.inputValue = params.inputValue;
						filtered.push(a);
					}

					return filtered;
				}}
				onInputChange={(event, value) => {
							if(event && (event.type == 'change' || event.type == 'input' )  && value != "" && value != null)// && value.length > 2 )//&& event.constructor.name === "SyntheticBaseEvent" 
							{				  event.preventDefault();	
								this.onInputChange(event, value,source)
								//this.setLoadingFlag(event, value,source)
								 //  this.setState({selectedValue:value,loading:true});
									//source.onInputChange(value)
									//this.unSetLoadingFlag()
							}
				 
							   }}
							  // onWheel={this.handleScroll}
    //onMouseEnter={this.disableScroll}
    //onMouseLeave={this.enableScroll}
				options={sourceData}
				getOptionLabel={(option) => {
					// e.g value selected with enter, right from the input
					if (typeof option === "string") {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option[source.option];
				}}
			// 	open={this.state.open}
            //   onOpen={() => {
            //     this.Open()
            //   }}
            //   onClose={() => {
            //     this.Close()
            //   }}
				loading={loading}
				//selectOnFocus
				//clearOnBlur
				//handleHomeEndKeys
				renderOption={(option) => option[source.option]}
				// autoHighlight
				renderInput={(params) => (
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">{obj.displayName}
						{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
						{obj.isEditable === true && (
								// <div className="col-xs-1">
									<IconButton
										style={{ backgroundColor: "#ffff", height:"0.8em" }}
										aria-label={"addnewrow" + source.Key}
										size="small"
										onClick={() => {
											source.onAddClick &&
												source.onAddClick({ ...source }, "", false);
											// this.setState({ openaddform: true, addformtitle: source.AddNewOptiontitle, addNewformComponent: source.AddOptionComponent });
										}}
									>
										{obj.isAdded ? (
											<Edit
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										) : (
											<AddCircleOutlined
												style={{ backgroundColor: "#ffff", height:"1rem", marginTop:"0px" }}
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										)}
									</IconButton>
								// </div>
							)}
						{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", height:"1rem", marginBottom:"-4px",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							

						</label>

						<TextField
							error={isInErrorState}
							errorMessage={errorMessage}
							{...params}
							style={{ border: this.props.Editable ? "1px solid" : "0px" }}
							hiddenLabel
							margin="normal"
							placeholder="Type at least 3 characters to search"

							InputProps={{
								...params.InputProps,
								endAdornment: (
								  <React.Fragment>
									{ loading ? <CircularProgress  size={20} /> : null}
									{params.InputProps.endAdornment}
								  </React.Fragment>
								),
							  }}
						/>
					</Box>
				)}
			/>
		);
		// return (
		// 	<Autocomplete
		// 		size="small"
		// 		freeSolo
		// 		error={isInErrorState}
		// 		helperText={errorMessage}
		// 		disabled={isEditable}
		// 		multiple={obj.multiple || false}
		// 		options={(sourceData || []).map((option) => option[source.option])}
		// 		value={obj.multiple === true ? defaultValues : obj.attributeValue || ""}
		// 		key={
		// 			this.state.parentKey +
		// 			"_dropDown_filter" +
		// 			obj.dbColumn +
		// 			obj.sectionId
		// 		}
		// 		id={"dropDown_filter" + obj.dbColumn + obj.sectionId}
		// 		onChange={(event, value) => {
		// 			if (value === source.AddNewOptiontitle) {
		// 				source.onAddClick && source.onAddClick({ ...source });

		// 				// this.setState({ openaddform: true, addformtitle: value, addNewformComponent: source.AddOptionComponent });
		// 			} else {
		// 				let sourceIdField = {};

		// 				if (source.idField && obj.multiple === true) {
		// 					sourceIdField.key = source.idField;
		// 					const SelectedRecordMultiple = [];
		// 					value.forEach((sourceValue) => {
		// 						const filteredDataSource = sourceData.find(
		// 							(x) => x[_.camelCase(source.option)] === sourceValue
		// 						);
		// 						filteredDataSource &&
		// 							SelectedRecordMultiple.push(
		// 								filteredDataSource[
		// 									_.camelCase(source.optionIdField || source.idField)
		// 								]
		// 							);
		// 					});

		// 					sourceIdField.key = source.idField;
		// 					sourceIdField.value = SelectedRecordMultiple;
		// 				} else if (source.idField) {
		// 					const selectedValue =
		// 						sourceData.find(
		// 							(x) => x[_.camelCase(source.option)] === value
		// 						) || {};
		// 					if (selectedValue) {
		// 						sourceIdField.key = source.idField;
		// 						sourceIdField.value =
		// 							selectedValue[
		// 								_.camelCase(source.optionIdField || source.idField)
		// 							];
		// 					}
		// 				} else {
		// 					sourceIdField = undefined;
		// 				}
		// 				this.onChangeValues(
		// 					value,
		// 					obj,
		// 					sourceIdField,
		// 					obj.multiple,
		// 					source
		// 				);
		// 				// source.onChange && source.onChange(value, sourceIdField, obj.multiple);
		// 			}
		// 		}}
		// 		onInputChange={(event, value) => {
		// 		if(value != "" && value != null && value.length > 2 )
		// 		{						
		// 			   this.setState({selectedValue:value,loading:true});
		// 				source.onInputChange(event,value) 
		// 		}
	 
		// 		   }}
				
		// 		renderInput={(params) => (
		// 			<Box
		// 				sx={{ display: "flex", alignItems: "center" }}
		// 				className="filter-label"
		// 			>
		// 				<label htmlFor="filled-hidden-label-small">
		// 					{obj.displayName}
		// 					{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
		// 					{obj.isModified == true ? <AccessAlarmIcon style={{cursor:"pointer", marginLeft:"2px", height:"0.8em",fill: "#003D69"}} onClick={() => {
		// 										this.props.getHistoryDataByField(obj);
		// 									}}
		// 				></AccessAlarmIcon> :null}
							
		// 				</label>

		// 				<TextField
		// 					{...params}
		// 					error={isInErrorState}
		// 					errorMessage={errorMessage}
		// 					margin="normal"
		// 					style={{ border: this.props.Editable ? "1px solid" : "0px" }}
		// 					hiddenLabel
		// 				/>
		// 			</Box>
		// 		)}
		// 	/>
		// );
	};

	renderAutofillWithAdd = (
		isEditable,
		obj,
		sourceData,
		source,
		defaultValues,
		isInErrorState,
		errorMessage
	) => {
		if (sourceData.length <= 0) {
			if (obj.multiple) {
				defaultValues = [];
			} else {
				obj.attributeValue = undefined;
			}
		}
		return (
			<Autocomplete
				size="small"
				className={isEditable ? 'disabed-MuiIconButton-label':null}
				disabled={isEditable}
				error={isInErrorState}
				errorMessage={errorMessage}
				multiple={obj.multiple || false}
				value={defaultValues == [] ? '' : obj.attributeValue}
				key={
					this.state.parentKey +
					"_dropDown_filter" +
					obj.dbColumn +
					obj.sectionId +
					obj.sectionDetailId
				}
				id={
					"dropDown_filter" + obj.dbColumn + obj.sectionId + obj.sectionDetailId
				}
				onChange={(event, value) => {
					if (typeof value === "string") {
						source.onAddClick && source.onAddClick({ ...source }, value);
					} else if (value && value.inputValue) {
						source.onAddClick &&
							source.onAddClick({ ...source }, value.inputValue);
					} else {
						let sourceIdField = {};

						if (source.idField && obj.multiple === true) {
							sourceIdField.key = source.idField;
							const SelectedRecordMultiple = [];
							const SelectedMultiple = [];

							value.forEach((sourceValue) => {
								const filteredDataSource = sourceData.find(
									(x) =>
										x[_.camelCase(source.option)] ===
										(typeof sourceValue === "object"
											? sourceValue[source.option]
											: sourceValue)
								);
								if (filteredDataSource) {
									SelectedRecordMultiple.push(
										filteredDataSource[
											_.camelCase(source.optionIdField || source.idField)
										]
									);
									SelectedMultiple.push(
										filteredDataSource[_.camelCase(source.option)]
									);
								}
							});

							sourceIdField.key = source.idField;
							sourceIdField.value = SelectedRecordMultiple;
							this.onChangeValues(
								SelectedMultiple,
								obj,
								sourceIdField,
								obj.multiple,
								source
							);
							// source.onChange && source.onChange(SelectedMultiple, sourceIdField, obj.multiple);
						} else {
							if (source.idField) {
								const selectedValue =
									sourceData.find(
										(x) =>
											x[_.camelCase(source.option)] ===
											(value || {})[source.option]
									) || {};
								if (selectedValue) {
									sourceIdField.key = source.idField;
									sourceIdField.value =
										selectedValue[
											_.camelCase(source.optionIdField || source.idField)
										];
								}
							} else {
								sourceIdField = undefined;
							}
							this.onChangeValues(
								(value || {})[source.option],
								obj,
								sourceIdField,
								obj.multiple,
								source
							);
							// source.onChange && source.onChange((value || {})[source.option], sourceIdField, obj.multiple);
						}
					}
				}}
				filterOptions={(options, params) => {
					const filtered = FilterOptions(options, params);

					if (params.inputValue !== "") {
						const a = {};
						a[source.option] = `Add "${params.inputValue}"`;
						a.inputValue = params.inputValue;
						filtered.push(a);
					}

					return filtered;
				}}
				options={sourceData}
				getOptionLabel={(option) => {
					// e.g value selected with enter, right from the input
					if (typeof option === "string") {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option[source.option];
				}}
				//selectOnFocus
				//clearOnBlur
				//handleHomeEndKeys
				renderOption={(option) => option[source.option]}
				// autoHighlight
				renderInput={(params) => (
					<Box
						sx={{ display: "flex", alignItems: "center" }}
						className="filter-label"
					>
						<label htmlFor="filled-hidden-label-small">{obj.displayName}
						{obj.isRequired == true && isEditable == false ? <span className="span-mandatory">*</span>:null}
						{obj.isEditable === true && (
								// <div className="col-xs-1">
									<IconButton
										style={{ backgroundColor: "#ffff", height:"0.8em" }}
										aria-label={"addnewrow" + source.Key}
										size="small"
										onClick={() => {
											source.onAddClick &&
												source.onAddClick({ ...source }, "", false);
											// this.setState({ openaddform: true, addformtitle: source.AddNewOptiontitle, addNewformComponent: source.AddOptionComponent });
										}}
									>
										{obj.isAdded ? (
											<Edit
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										) : (
											<AddCircleOutlined
												style={{ backgroundColor: "#ffff", height:"1rem", marginTop:"0px" }}
												fontSize="small"
												size="small"
												title={source.AddNewOptiontitle}
											/>
										)}
									</IconButton>
								// </div>
							)}
						{obj.isModified == true && this.props.Editable === true ? <AccessAlarmIcon style={{cursor:"pointer", height:"1rem", marginBottom:"-4px",fill: "#003D69"}} onClick={() => {
												this.props.getHistoryDataByField(obj);
											}}
						></AccessAlarmIcon> :null}
							

						</label>

						<TextField
							error={isInErrorState}
							errorMessage={errorMessage}
							{...params}
							style={{ border: this.props.Editable ? "1px solid" : "0px" }}
							hiddenLabel
							margin="normal"
						/>
					</Box>
				)}
			/>
		);
	};

	render() {
		// window.onscroll = function (e) {
		// 	if (
		// 	  window.innerHeight + document.documentElement.scrollTop ===
		// 	  document.documentElement.offsetHeight
		// 	) {
		// 	  setPage(page + 1);
		// 	}
		//   };
		const { formElementFields } = this.state;
		let formElement = <></>;
		if (formElementFields && this.state.formType === undefined) {
			formElement = this.renderNormalForm(formElementFields);
		} else {
			formElement = this.rendersectionForm(formElementFields);
		}
		return (
			<>
				<div className="row form-section" style={{ paddingBottom: "10px" }}>
					{formElement}
				</div>
				{this.state.partialForm === false &&
					this.props.saveChanges !== undefined && (
						<div className="row top-xs">
							<div
								className={"col-xs-12 col-lg-12 field-control"}
								style={{ marginTop: "10px" }}
							>
								<Button
									size="small"
									variant="contained"
									onClick={() => {
										this.validateRequiredFields() &&
											this.props.saveChanges(
												_.cloneDeep(this.state.formElementFields)
											);
									}}
								>
									{this.props.submitText ? this.props.submitText : "Submit"}
								</Button>
							</div>
						</div>
					)}
			</>
		);
	}

	validateRequiredFields = () => {
		const { formElementFields } = this.state;
		let IsValid = true;
		formElementFields.forEach((ele) => {
			isInputValid(ele.attributeValue, ele, formElementFields);
			if (ele.isInErrorState === true) {
				IsValid = false;
			}
		});

		if (IsValid) {
			return true;
		} else {
			this.setState({
				formElementFields: formElementFields,
				inErrorState: true,
			});
			return false;
		}
	};

	renderNormalForm(formFields) {
		return formFields
			.filter((x) => x.isVisible === true)
			.map((obj, index) => (
				<>
					{this.props.separatorConfig &&
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorBeforeKey) === _.camelCase(obj.dbColumn)
						) && (
							<>
								<div className="col-sm-12 start-sm innerHeader">
									<SectionHeader
										key={
											this.state.parentKey +
											"_separator-" +
											_.camelCase(obj.dbColumn)
										}
										title={
											this.props.separatorConfig.find(
												(x) =>
													_.camelCase(x.separatorBeforeKey) ===
													_.camelCase(obj.dbColumn)
											).label
										}
									></SectionHeader>
								</div>
							</>
						)}
					<div
						className={
							"col-xs-12 col-lg-" +
							(obj.columnWidth
								? obj.columnWidth
								: this.props.gridColumn > 2
								? this.props.gridColumn
								: 4) +
							" field-control"
						}
					>
						{this.renderField(obj)}
					</div>
					{index % 2 === 0 && this.props.gridColumn <= 2 && (
						<div
							key={
								this.state.parentKey +
								`formEmptyField_${
									obj.sectionDetailId + obj.dbColumn + obj.sectionId
								}`
							}
							className={"col-xs-12 col-sm-offset-1"}
						></div>
					)}
					{this.props.separatorConfig &&
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorAfterKey) === _.camelCase(obj.dbColumn)
						) && (
							<>
								<div className="col-sm-12 start-sm innerHeader">
									<SectionHeader
										key={this.state.parentKey + "_separator-" + obj.dbColumn}
										title={
											this.props.separatorConfig.find(
												(x) =>
													_.camelCase(x.separatorAfterKey) ===
													_.camelCase(obj.dbColumn)
											).label
										}
									></SectionHeader>
								</div>
							</>
						)}
					{this.props.separatorConfig &&
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorAfterKey) === _.camelCase(obj.dbColumn)
						) && (
							<>
								<div className="col-sm-12 start-sm innerHeader">
									<SectionHeader
										key={this.state.parentKey + "_separator-" + obj.dbColumn}
										title={
											this.props.separatorConfig.find(
												(x) =>
													_.camelCase(x.separatorAfterKey) ===
													_.camelCase(obj.dbColumn)
											).label
										}
									></SectionHeader>
								</div>
							</>
						)}
					{this.props.separatorConfig &&
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorAfterKey) === _.camelCase(obj.dbColumn)
						) && (
							<>
								<div className="col-sm-12 start-sm innerHeader">
									<SectionHeader
										key={
											this.state.parentKey +
											"_separator-" +
											_.camelCase(obj.dbColumn)
										}
										title={
											this.props.separatorConfig.find(
												(x) =>
													_.camelCase(x.separatorAfterKey) ===
													_.camelCase(obj.dbColumn)
											).label
										}
									></SectionHeader>
								</div>
							</>
						)}
				</>
			));
	}

	rendersectionForm(fields) {
		let formData = [];
		const finalFormData = [];

		const sectionDataDetails = [];
		let sectionData;
		const sectionRowConfig = [...this.props.sectionRowConfig];

		fields
			.filter((x) => x.isVisible === true)
			.forEach((obj) => {
				if (
					this.props.separatorConfig &&
					this.props.separatorConfig.find(
						(x) =>
							_.camelCase(x.separatorBeforeKey) === _.camelCase(obj.dbColumn)
					)
				) {
					({ formData, sectionData } = this.processSectionData(
						formData,
						finalFormData,
						sectionData,
						sectionDataDetails
					));
					sectionData = {};
					const separatorConfig =
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorBeforeKey) === _.camelCase(obj.dbColumn)
						) || {};
					sectionData.order = separatorConfig.order || 0;
					sectionData.row = separatorConfig.row || 0;
					sectionData.label = separatorConfig.label;
					sectionData.sectionColumn = separatorConfig.sectionColumn || 4;
					sectionData.fields = [];
				}

				if (sectionData !== undefined) {
					sectionData.fields.push(
						<div
							className={
								"col-xs-12 col-lg-" +
								(obj.columnWidth
									? obj.columnWidth
									: sectionData.sectionColumn > 2
									? sectionData.sectionColumn
									: 6) +
								" field-control"
							}
						>
							{this.renderField(obj)}
						</div>
					);
				} else {
					formData.push(
						<div
							className={
								"col-xs-12 col-lg-" +
								(obj.columnWidth
									? obj.columnWidth
									: this.props.gridColumn > 2
									? this.props.gridColumn
									: 4) +
								" field-control"
							}
						>
							{this.renderField(obj)}
						</div>
					);
				}

				if (
					this.props.separatorConfig &&
					this.props.separatorConfig.find(
						(x) =>
							_.camelCase(x.separatorAfterKey) === _.camelCase(obj.dbColumn)
					)
				) {
					({ formData, sectionData } = this.processSectionData(
						formData,
						finalFormData,
						sectionData,
						sectionDataDetails
					));
					sectionData = {};
					const separatorConfig =
						this.props.separatorConfig.find(
							(x) =>
								_.camelCase(x.separatorAfterKey) === _.camelCase(obj.dbColumn)
						) || {};
					sectionData.order = separatorConfig.order || 0;
					sectionData.row = separatorConfig.row || 0;
					sectionData.label = separatorConfig.label;
					sectionData.sectionColumn = separatorConfig.sectionColumn || 4;
					sectionData.fields = [];
				}
			});

		({ formData, sectionData } = this.processSectionData(
			formData,
			finalFormData,
			sectionData,
			sectionDataDetails
		));

		if (
			this.props.otherChildsection &&
			this.props.otherChildsection.length > 0
		) {
			this.props.otherChildsection.forEach((ele, i) => {
				sectionDataDetails.push({
					row: ele.row,
					element: (
						<div
							className="col-xs-12 start-sm top-sm item"
							key={
								this.state.parentKey +
								"_sectionDataDetails-row-" +
								_.camelCase(ele.label) +
								i
							}
						>
							{/* <Paper
                key={
                  this.state.parentKey +
                  '_sectionDataDetails-row-paper' +
                  _.camelCase(ele.label) +
                  i
                }
              > */}
							<div
								className="row top-xs"
								key={
									this.state.parentKey +
									"_sectionDataDetails-row-paper-container" +
									_.camelCase(ele.label) +
									i
								}
							>
								<div
									className="col-xs-12 innerHeader"
									key={
										this.state.parentKey +
										"_sectionDataDetails-row-paper-container-inner" +
										_.camelCase(ele.label) +
										i
									}
								>
									<SectionHeader
										key={this.state.parentKey + "_separator-" + ele.label + i}
										title={ele.label}
									></SectionHeader>
								</div>
								{ele.element}
							</div>
							{/* </Paper> */}
						</div>
					),
				});
			});
		}
		formData = [];
		_.orderBy(sectionDataDetails, ["order", "row"], ["asc"]).forEach((ele) => {
			if (sectionRowConfig) {
				if (
					((sectionRowConfig || []).find((x) => x.row === ele.row) || {})
						.element === undefined
				) {
					(
						(sectionRowConfig || []).find((x) => x.row === ele.row) || {}
					).element = [];
				}
				(
					((sectionRowConfig || []).find((x) => x.row === ele.row) || {})
						.element || []
				).push(ele.element);
			} else {
				formData.push(ele.element);
			}
		});

		if (formData && formData.length > 0) {
			finalFormData.push(
				<div className="col-xs-12">
					<div>
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{formData}
						</Masonry>
					</div>
				</div>
			);
		} else {
			if (sectionRowConfig) {
				
				finalFormData.push(
					<div className="col-xs-12">
						<div className="row">
							{sectionRowConfig.map((x, i) => (	
								
								<div
									key={
										this.state.parentKey +
										"_sectionRowConfig-finalFormData-row" +
										i
									}
									className={"col-xs-12 col-sm-" + x.column}
								>
									{(x.element || []).map((e, index) => (
										<div
											className={"row " + (index > 0 ? "margin-top" : "")}
											key={this.state.parentKey + i + "inner-child_" + index}
										> 
											{e}
										</div>
									))}
								</div>
							))}
						</div>
					</div>
				);
			}
		}
		return finalFormData;
	}

	processSectionData(formData, finalFormData, sectionData, sectionDataDetails) {
		if (formData && formData.length > 0) {
			finalFormData.push(
				<div className="col-xs-12">
					<div className="row">{formData.map((ele) => ele)}</div>
				</div>
			);
			formData = [];
		}
		if (sectionData) {
			sectionDataDetails.push({
				order: sectionData.order,
				row: sectionData.row,
				element: (
					<div className="col-xs-12 start-sm top-sm item">
						{/* <Paper
              key={
                this.state.parentKey +
                '_processSectionData-sectionDataDetails-row-paper' +
                sectionDataDetails.length
              }
            > */}
						<div className="row top-xs">
							<div className="col-xs-12 innerHeader">
								<SectionHeader
									key={
										this.state.parentKey +
										"_processSectionData-sectionDataDetails-row-pageheader" +
										sectionDataDetails.length +
										"separator-" +
										sectionData.label
									}
									title={sectionData.label}
								></SectionHeader>
							</div>
							{sectionData.fields}
						</div>
						{/* </Paper> */}
					</div>
				),
			});
			sectionData = undefined;
		}
		return { formData, sectionData };
	}
}

export default FormGenerator;

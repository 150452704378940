import React from "react";
import "../../public/css/style.less";
import { apiBatchRequest, apiPost, apiPut } from "../../utils/api_service";
import ReportButtons from "../../utils/ReportButtons";
import { API_URL } from "../../constants/api";
import PageFilter from "../common/PageFilter";
import { controlType } from "../../constants/common";
import PageHeader from "../common/PageHeader";
import {
  Tooltip,
  Button,
} from "@material-ui/core";
import GridTable from "../common/Table/Table";
import { companyColumns } from "../../constants/MockData";
import {
  applyFilterOnData,
  generatePDFFromHtml,
  UpdateColumnWithConfiguration,
} from "../../utils/helper";
import EditCompanies from "./EditCompanies";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../common/AutoSearch/SearchBar";

const uploadColumns = [
  {
    key: "companyName",
    name: "Company Name",
  },
  {
    key: "address",
    name: "Address",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "subMarket",
    name: "Sub Market",
  },
  {
    key: "productType",
    name: "Product Type",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export default class Companies extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reloadTable: false,
      companyData: [],
      configuration: [],
      companyMasterDetails: [],
      ownerTypeData: [],
      getIndustrySector: [],
      selectedEditRowId: undefined,
      detailView: false,
      ViewTitle: undefined,
      actionType: undefined,
      getSubMarketMaster: [],
      getProductTypeMaster: [],
      getMarketMaster: [],
      responseCompanyData: [],
      openFilter: false,
      searchInput: "",
      filteredData: [],
      lstActiveFilters: [],
      subMarketData: [],
      subMarketFilteredData: [],
      isInAddMode: false,
      filterSelectedData:[]
    };
  }

  componentDidMount() {
    this.getRequiredData();
  }

  getRequiredData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Companies.companyData,
        key: "companyData",
      },
      {
        url: API_URL.Master.companyMasterDetails,
        key: "companyMasterDetails",
        params: { businessLineId: 1 },
      },
      {
        url: API_URL.Master.ownerTypeData,
        key: "ownerTypeData",
        params:{businessLineId:1},
      },
      {
        url: API_URL.Master.getIndustrySector,
        key: "getIndustrySector",
        params:{businessLineId:1},
      },
      {
        url: API_URL.Master.getProductTypeMaster,
        key: "getProductTypeMaster",
      },
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1 },
      },      
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1 },
      },
    ])
      .then((companyData) => {
        this._asyncRequest = null;
        if (companyData.companyData.success == true) {
          this.setState({
            companyData: camelcaseKeys(companyData.companyData.data) || [],
            responseCompanyData: _.cloneDeep(
              camelcaseKeys(companyData.companyData.data) || []
            ),
            configuration:
              camelcaseKeys(companyData.companyData.configuration) || [],
            companyMasterDetails:
              camelcaseKeys(companyData.companyMasterDetails.data) || [],
            ownerTypeData: camelcaseKeys(companyData.ownerTypeData.data) || [],
            getIndustrySector:
              camelcaseKeys(companyData.getIndustrySector.data) || [],
            getSubMarketMaster:
              camelcaseKeys(companyData.getSubMarketMaster.data) || [],
            subMarketData:
              camelcaseKeys(companyData.getSubMarketMaster.data) || [],
            getProductTypeMaster:
              camelcaseKeys(companyData.getProductTypeMaster.data) || [],
            getMarketMaster:
              camelcaseKeys(companyData.getMarketMaster.data) || [],
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  globalSearch = () => {
    let { searchInput, companyData } = this.state;
    let searchData=this.state.responseCompanyData
    let filteredData = searchData.filter(value => {
      if( value.companyRole !=null 
        && value.companyName !=null
        && value.market !=null
        && value.subMarket !=null
        &&  value.address !=null
        //&& value.productType !=null
        )
    {
    return (
        value.companyName.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.market.toLowerCase().includes(searchInput.toLowerCase())     ||
        value.address.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.subMarket.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.companyRole.toLowerCase().includes(searchInput.toLowerCase()) 
        //||
       // value.productType.toLowerCase().includes(searchInput.toLowerCase()) 
      );
    }
    });
    this.setState({companyData: filteredData });
};

  // globalSearch = () => {
  //   let { searchInput } = this.state;
  //   let searchData = this.state.responseCompanyData;
  //   let filteredData = searchData.filter((value) => {
    
  //       return (
  //         value.companyName != null ? value.companyName.toLowerCase().includes(searchInput.toLowerCase())  ||
  //         value.market != null ? value.market.toLowerCase().includes(searchInput.toLowerCase()) : value ||
  //         value.add1 != null ? value.add1.toLowerCase().includes(searchInput.toLowerCase()) : value ||
  //         value.subMarket != null ? value.subMarket.toLowerCase().includes(searchInput.toLowerCase()) : value ||
  //         value.companyRole !=null ? value.companyRole.toLowerCase().includes(searchInput.toLowerCase()) :value ||
  //         value.productType !=null ? value.productType.toLowerCase().includes(searchInput.toLowerCase()) :value
  //       );
      
  //   });
   // this.setState({ companyData: filteredData });
  //};
  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
              //this.setState({ detailView: true, selectedEditRowId: cellInfo.CompanyId });
            }}
          >
            <span className="default-text span-link">
              {cellInfo.companyName ? cellInfo.companyName : '---'}
            </span>
          </a>
        </Tooltip>
        {/* <span>  {cellInfo.CompanyName}</span> */}
      </>
    );
  };

  getListOfSubMarket()
  {

  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  onFilterChange = (selectedData) => {
    if(selectedData.length > 0)
    {
      let data=[];
      data.push(selectedData);

      this.setState({
        filterSelectedData:data
      });
    }
    // let filterValue=[];
    // if (selectedData && selectedData.length > 0) {
    //   selectedData.forEach((ele) => {
    //     if (ele && ele.selected && ele.selected.length > 0) {
    //       ele.selected.forEach((element) => {
    //         if (ele.key == "market") {
    //           const selectedSubMarket = this.state.subMarketData.filter(
    //             (x) => x.marketId === element.marketId
    //           );
    //           let conditionBreak = false;
    //           if (selectedSubMarket != undefined) {
    //             const filteredData = this.state.subMarketFilteredData;
    //             this.state.subMarketFilteredData.forEach((m) => {
    //               selectedSubMarket.forEach(e=>{
    //                 m.forEach(n=>{
    //                 if (n.subMarketId == e.subMarketId)
    //                 conditionBreak = true;
    //               });
    //               });                 
    //             });
    //             if (conditionBreak == false) {                 
    //               filteredData.push(selectedSubMarket);
    //               const resp=[];
    //               filteredData.forEach(data=>{
    //                 data.forEach(d=>
    //                   {
    //                     resp.push(d);
    //                   }
    //                 );
    //               });
    //               this.setState({
    //                 getSubMarketMaster: resp,
    //                 subMarketFilteredData: filteredData,
    //               });
                 
    //             }
    //           }
    //         }
    //         let FilterName=ele.key;
         
    //         filterValue.push(this.capitalizeFirstLetter(FilterName)+'-'+element[ele.key]);
    //         this.setState({
    //           lstActiveFilters:filterValue
    //         });
    //            });

                     
    
    //     }
    //   });
    // }

    if (selectedData.length > 0) {
      this.filterData(this.state.responseCompanyData, selectedData);
    } else {
      this.setState({
        getSubMarketMaster:this.state.subMarketData
      });
      this.filterData(this.state.responseCompanyData, selectedData);
    }
  };

  filterData(companyData, selectedData) {
    const filteredData = applyFilterOnData([...companyData], selectedData);
    this.setState(
      {
        companyData: filteredData,
        selectedData: selectedData,
        reloadTable: !this.state.reloadTable,
      },
      () => {
        this.forceUpdate();
      }
    );
  }
  resetPage = () => {
    //if()
    let resp = this.state.responseCompanyData;
    this.setState({
      companyData: resp,
      lstActiveFilters:[],
      openFilter:false
    });
  };
  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  onAddNewClick = () => {   
    this.setState({
      isInAddMode: true,
      detailView: true,
      ViewTitle: "Add",
      actionType: "Add",
    });
  };
  onRowClick = (data) => {
    this.setState({
      detailView: true,
      selectedEditRowId: data.companyId,
      ViewTitle: "Edit",
      actionType: "Edit",
    });
  };

  onBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: undefined });
  };

  reloadData=()=>
  {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Companies.companyData,
        key: "companyData",
      },
      
    ])
      .then((companyData) => {
        this._asyncRequest = null;
        if (companyData.companyData.success == true) {
          this.setState({            
            companyData: camelcaseKeys(companyData.companyData.data) || [],
            responseCompanyData: _.cloneDeep(
              camelcaseKeys(companyData.companyData.data) || [],
               ),
            configuration:
              camelcaseKeys(companyData.companyData.configuration) || [],
              detailView : false ,
              selectedEditRowId: undefined
                    
           });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }

  exportPDF = () => {
    const headers = [
      [
        "Company Name",
        "Address",
        "Market",
        "Sub MArket",
        "Owner Type",
        "Product Type",
      ],
    ];

    const data = this.state.companyData.map((elt) => [
      elt.companyName,
      elt.add1,
      elt.market,
      elt.subMarket,
      elt.companyRole,
      elt.productType     
    ]);
    generatePDFFromHtml("Company Detail", "#gridTable", "BuildingOwnerDetail",headers,data);
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true
      },
      window.print()
    );
  };

  resetFilters=()=>
  {
    this.setState({
      filterSelectedData:null
    });
  }
  render() {
    const {
      companyData,
      searchInput,
      getSubMarketMaster,
      companyMasterDetails,
      ownerTypeData,
      getIndustrySector,
      getProductTypeMaster,
      getMarketMaster,
      openFilter,
      lstActiveFilters,
    } = this.state;

    companyData.forEach((item, index) => {
      item.serial = index + 1;
    });

   
    return (
      <>
        {this.state.detailView ? (
          <>
            <EditCompanies
              selectedCompanyId={this.state.selectedEditRowId}
              onBackClick={this.onBackClick}
              getProductTypeMaster={getProductTypeMaster}
              ViewTitle={this.state.ViewTitle}
              actionType={this.state.actionType}
              isInAddMode={this.state.isInAddMode}
              reloadData={this.reloadData}
            />
          </>
        ) : (
          <>
            <div className="row no-print">
              <div className="col-md-2 col-lg-2" >
              <PageHeader title="Companies"></PageHeader>
                {/* <Button
                  aria-label={"addnewrow"}
                  className="action-button"
                  size="small"
                  onClick={() => {
                    this.setState({
                      isInAddMode: true,
                    });
                    this.onAddNewClick();
                  }}
                >
                  <span className="tool-icon-text">Add Company</span>
                </Button> */}
              </div>
              
              <div className="col-md-10 col-lg-10">
                <div className="row">
                <div className="col-md-5 col-lg-5"></div>
                  <div className="col-md-5 col-lg-5">
                    <SearchBar
                      size="large"
                      name="searchInput"
                      value={searchInput || ""}
                      onChange={this.handleChange}
                      label="Search"
                      placeholder="Search Company, address, market, etc."
                    ></SearchBar>
                  </div>
                  <div className="col-md-2 col-lg-2">
                    <ReportButtons
                      resetPage={this.resetPage}
                      openFilter={this.state.openFilter}
                      enableFilter={this.enableFilter}
                      handleSubmit={[]}
                      handleClickOpen={[]}
                      handlePrintClick={this.handlePrintClick}
                      exportPDF={this.exportPDF}
                      data={companyData}
                      columnWidths={columnWidths}
                      uploadColumns={uploadColumns}
                      fileName={"BuildingOwner.xlsx"}
                    />
                  </div>
                  </div>               
                </div>
              
            </div>
            
            {companyData &&  (
               <div className="row" style={{marginTop:'0px'}}>
               <div className="col-sm-12 col-lg-12">
                <PageFilter
                  resetFilters={this.resetFilters}
                  autoFilter={true}
                  openAppliedFilterPane={true}
                  filterSelectedData={this.state.filterSelectedData}
                  filterApplied={true}
                  filterAlwaysVisible={openFilter}
                  openFilter={true}
                  onFilterChanged={this.onFilterChange}
                  filterConfig={[
                    {
                      multiple: true,
                      type: controlType.DropDown,
                      minTag: 1,
                      source: companyMasterDetails,
                      key: "companyName",
                      placeholder: "",
                      title: "Company Name",
                    },
                    {
                      multiple: true,
                      type: controlType.DropDown,
                      source: getMarketMaster,
                      key: "market",
                      placeholder: "",
                      title: "Market",
                    },
                    {
                      multiple: true,
                      type: controlType.DropDown,
                      source: getSubMarketMaster,
                      key: "subMarket",
                      placeholder: "",
                      title: "Sub Market",
                    },
                    // {
                    //   multiple: true,
                    //   type: controlType.DropDown,
                    //   source: getIndustrySector,
                    //   key: 'industryName',
                    //   placeholder: '',
                    //   title: 'Industry'
                    // },
                    {
                      multiple: true,
                      type: controlType.DropDown,
                      source: ownerTypeData,
                      key: "companyRole",
                      placeholder: "",
                      title: "CompanyType",
                    },
                    {
                      multiple: true,
                      type: controlType.DropDown,
                      source: getProductTypeMaster,
                      key: "productType",
                      placeholder: "Stream Property Type",
                      title: "Stream Property Type",
                    },
                   
                  ]}
                  defaultSelectedData={[]}
                  selectedData={[]}
                  gridColumn={4}
                ></PageFilter>
                {/* <Button className="btn-clear" onClick={this.resetPage}>
                          Clear All
                        </Button> */}
              </div></div>
            )}

            {companyData && (
               <div className="row" style={{marginTop:'0px'}}>
               <div className="col-sm-12 col-lg-12">
              <GridTable
                validationMessage={[]}
                columns={UpdateColumnWithConfiguration(
                  companyColumns,
                  this.state.configuration,
                  []
                )}
                selectedEditRowId={this.state.selectedEditRowId}
                data={companyData}
                renderActionColumn={this.renderActionColumn}
                reload={this.state.reloadTable}
                AddNewRecord={{
                  action: this.onAddNewClick,
                  title: 'Add Company',
                  visible: true
                }}
              ></GridTable></div></div>
            )}
          </>
        )}
      </>
    );
  }
}

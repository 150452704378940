import moment from "moment-mini";
import { standardDateFormat, MIN_MONTH_DATE } from "../constants/constants";

Date.prototype.getShortMonthYearName = function() {
	const month = this.getMonth() + 1;
	return (month < 10 ? "0" + month : month) + "/" + this.getFullYear();
};
Date.prototype.getFullDate = function() {
	return this.getMonth() + 1 + "/1/" + this.getFullYear();
};

const today = new Date();

const asOfTodays =
	today.getDate() <= MIN_MONTH_DATE ? getPrevNextDate(today, -1) : today;
const defaultAsofDate = {
	textValue: asOfTodays.getShortMonthYearName(),
	date: asOfTodays.getFullDate()
};

function getTodaysDate() {
	const today = new Date();
	return convertDateToFormat(today);
}

function getAsofDate() {
	const asofDate =
		JSON.parse(localStorage.getItem("ASOFDATE")) || defaultAsofDate;
	return asofDate;
}

function convertDateToFormat(today) {
	const dd = String(today.getDate()).padStart(2, "0");
	const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
	const yyyy = today.getFullYear();
	today = mm + "/" + dd + "/" + yyyy;
	return today;
}

function convertDateToFormatByFormat(date, format) {
	const dd = String(date.getDate()).padStart(2, "0");
	const mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
	const yyyy = date.getFullYear();

	switch (format.toLowerCase()) {
		case "mm/yyyy": {
			return mm + "/" + yyyy;
		}
	}
}

function convertDate(date) {
	return date ? moment.utc(date).format(standardDateFormat) : "";
}

function addYearIntoDate(_date, addYearval = 1) {
	const date = getDate(_date);
	date.setFullYear(date.getFullYear() + addYearval);
	return convertDateToFormat(date);
}

function addDayIntoDate(_date, addDays = 1) {
	const date = getDate(_date);
	date.setDate(date.getDate() + addDays);
	return date;
}

function subtractDayIntoDate(_date, subtractDays = 1) {
	const date = getDate(_date);
	date.setDate(date.getDate() - subtractDays);
	return date;
}

function getMonthsDiff(date1, date2, isWantNegative = false) {
	const d1 = getDate(date1);
	const d2 = getDate(date2);
	let months = 0;
	months = (d2.getFullYear() - d1.getFullYear()) * 12;
	months += d2.getMonth() - d1.getMonth();
	if (isWantNegative) return months;
	return months >= 0 ? months : 0;
}

// Add current timezone offset
function getDate(_date) {
	const date = new Date(_date);
	date.setMinutes(Math.abs(date.getTimezoneOffset()));
	return date;
}

function getAllMonthsInBetween(startDate = new Date(), endDate = new Date()) {
	const start = moment(startDate)
		.format("YYYY-MM-DD")
		.split("-");
	const end = moment(endDate)
		.format("YYYY-MM-DD")
		.split("-");
	const startYear = parseInt(start[0]);
	const endYear = parseInt(end[0]);
	const dates = [];

	for (let i = startYear; i <= endYear; i++) {
		const endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
		const startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
		for (let j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
			const month = j + 1;
			const displayMonth = month < 10 ? "0" + month : month;
			dates.push(moment([i, displayMonth, "01"].join("-")).format("MMM YYYY"));
		}
	}
	return dates;
}

function getAllOpsYears(startDate = new Date(), endDate = new Date()) {
	const start = moment(startDate)
		.format("YYYY-MM-DD")
		.split("-");
	const end = moment(endDate)
		.format("YYYY-MM-DD")
		.split("-");
	const startYear = parseInt(start[0]);
	const endYear = parseInt(end[0]);
	const dates = [];

	for (let i = startYear; i <= endYear; i++) {
		dates.push("Dec " + i);
	}
	return dates;
}

function getPrevNextDate(date, gapVal = 0) {
	date.setMonth(date.getMonth() + gapVal); //gapval should be how many month, +1  - Next 1 month, -1 - Prev 1 month
	return getDate(date.toLocaleDateString());
}

function convertToChartDate(_date) {
	return `${moment(_date).format("YYYY-MM-01")}T00:00:00`;
}

export {
	getTodaysDate,
	convertDate,
	getDate,
	addYearIntoDate,
	getMonthsDiff,
	addDayIntoDate,
	convertDateToFormat,
	convertDateToFormatByFormat,
	subtractDayIntoDate,
	getAsofDate,
	getAllMonthsInBetween,
	getAllOpsYears,
	getPrevNextDate,
	convertToChartDate
};

import React from "react";
import cx from "classnames";
import "./index.less";

function DownloadAnim({ type = "downloading" }) {
	return (
		<span className="downloadingContainer">
			<span className={cx("buttonDownload", type)} />
		</span>
	);
}

export default DownloadAnim;

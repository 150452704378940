import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { apiBatchRequest, apiPost } from "../../utils/api_service";
import camelcaseKeys from "camelcase-keys";
import moment from "moment";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { ShowToast, toastType } from "../../utils/toast-service";
import DeleteIcon from "@material-ui/icons/Delete";
import { UpdateColumnWithConfiguration } from "../../utils/helper";
import { API_URL } from "../../constants/api";
import { GridTable } from "../common/Table/Table";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
import SearchBar from "../common/AutoSearch/SearchBar";
import PageHeader from "../common/PageHeader";

export const marketMasterColumns = [
  {
    name: "Market",
    key: "market",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Market Reference Name",
    key: "marketReferenceName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Primary Research Metric",
    key: "primaryResearchMetric",
    minTag: 2,
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      sourceKey: "getPrimaryResearchMetric",
      updateKey: "primaryResearchMetricId",
      sourceOption: "primaryResearchMetric",
      title: "Primary Research Metric",
      isEditField: true,
      isAddField: true,
      multiple: false,
    },
  },
  {
    name: "Primary Research Metric Notes",
    key: "primaryResearchMetricNotes",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  // {
  //   name: "ModifiedBy",
  //   key: "modifiedBy",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: "TextField",
  //     isEditField: false,
  //     isAddField: false,
  //   },
  // },
  // {
  //   name: "ModifiedOn",
  //   key: "modifiedDate",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "Date",
  //   editConfig: {
  //     type: "TextField",
  //     isEditField: false,
  //     isAddField: false,
  //   },
  // },
  {
    name: "Action",
    key: "marketId",
    renderComponent: "renderActionColumn",
  },
];

export default class ConfigureMarket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      getMarketMaster: [],
      getPrimaryResearchMetric:[],
      getMarketMasterResponse: [],
      isInAddMode: false,
      reloadTable: false,
      selectedEditRowId: undefined,
      configuration: [],
      isInDeleteMode: false,
      updateMasterData: {
        marketId: -1,
        market: null,
        modifiedBy: "",
        modifiedDate: moment().format("YYYY-MM-DD"),
        primaryResearchMetricId:-1,
        primaryResearchMetricNotes:"",
        marketReferenceName:""
      },
      showWarningMessage: false,
      confirmMessage: "",
      searchInput: undefined,
    };
  }

  componentDidMount() {
    this.getRequiredData();
  }

  getRequiredData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
		    params: { businessLineId: 1 },
      },
      {
        url: API_URL.Master.getPrimaryResearchMetric,
        key: "getPrimaryResearchMetric",
		    params: { businessLineId: 1 },
      },
    ])
      .then((getMarketMaster) => {
        this._asyncRequest = null;
        if (getMarketMaster.getMarketMaster.success === true) {
          // const primaryResearchMetricId = camelcaseKeys(
          //   getMarketMaster.getPrimaryResearchMetric.data
          // )
          //   .map((x) => x.primaryResearchMetricId)
          //   .join("|");
          // camelcaseKeys(getMarketMaster.getPrimaryResearchMetric.data).unshift({
          //   marketId: primaryResearchMetricId,
          //   market: "All",
          // });
          this.setState({
            getMarketMaster:
              camelcaseKeys(getMarketMaster.getMarketMaster.data) || [],
            getMarketMasterResponse:
              camelcaseKeys(getMarketMaster.getMarketMaster.data) || [],
            configuration:
              camelcaseKeys(getMarketMaster.getMarketMaster.configuration) ||
              [],
              getPrimaryResearchMetric:
              camelcaseKeys(getMarketMaster.getPrimaryResearchMetric.data) || [],
            reloadTable: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  cancelAddMode = () => {
    this.setState({
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
  };

  cancelEditMode = () => {
    this.setState({
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
    //this.getRequiredData();
  };

  updateRecord = () => {
    const newParam = this.state.updateMasterData;
    // this.state.isInDeleteMode ? newParam.statusId = 0 : newParam.statusId = newParam.statusId;
    apiPost({
      url: API_URL.Master.UpdateMarketMasterDetail,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast("Record updated successfully.", toastType.SUCCESS, 5000, 500);

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData();
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  deleteRecord = (cellInfo) => {
    this.setState({
      showWarningMessage: true,
      confirmMessage: "Do you want to delete this record ?",
      updateMasterData: cellInfo,
    });
  };

  onClickHandle(e) {
    if (e) {
      this.setState(
        {
          isInDeleteMode: true,
          reloadTable: !this.state.reloadTable,
        },
        () => {
          this.forceUpdate();
        }
      );

      const newParam = this.state.updateMasterData;
      newParam.statusId = 0;
      apiPost({
        url: API_URL.Master.UpdateMarketMasterDetail,
        params: { businessLineId: 1 },
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            "Record deleted successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.setState({
            updateData: undefined,
            selectedEditRowId: undefined,
            reloadTable: !this.state.reloadTable,
            isInAddMode: false,
            showWarningMessage: false,
            confirmMessage: "",
          });
          this.getRequiredData();
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    } else {
      this.setState({
        showWarningMessage: false,
        confirmMessage: "",
      });
    }
  }

  saveUpdatedRecord = () => {
    const newParam = this.state.updateMasterData;
    apiPost({
      url: API_URL.Master.AddMarketMasterDetail,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast(
          this.state.isInAddMode
            ? "Record added successfully."
            : "Record updated successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData();
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  renderActionColumn = (cellInfo, key, isInAddMode) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined &&
        cellInfo.marketId === this.state.selectedEditRowId.value ? (
          <>
            <Tooltip title="Save Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {}}
              >
                <SaveIcon
                  fontSize="small"
                  size="small"
                  onClick={
                    this.state.isInAddMode
                      ? this.saveUpdatedRecord
                      : this.updateRecord
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={this.cancelEditMode}
              >
                <CancelIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.setState({
                    selectedEditRowId: {
                      key: "marketId",
                      value: cellInfo.marketId,
                    },
                    isInAddMode: false,
                    updateMasterData: cellInfo,
                  });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
            {/*<Tooltip title="Edit Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.deleteRecord(cellInfo);
                }}
              >
                <DeleteIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>*/}
          </>
        )}
      </>
    );
  };

  handleTableEditChange = (value, fieldName, row, addMode) => {
    
    const param = this.state.updateMasterData;
    if (fieldName === "market") {
      param[fieldName] = value || [];
    }
    if (fieldName === "primaryResearchMetric") {
      let getPrimaryResearchMetric = [];
      getPrimaryResearchMetric = this.state.getPrimaryResearchMetric;
      const primaryResearchMetricId = parseInt(
        getPrimaryResearchMetric.find((x) => x.primaryResearchMetric === value).primaryResearchMetricId
      );
      param.primaryResearchMetricId = parseInt(primaryResearchMetricId);
      param[fieldName] = value || [];
    }
    if (fieldName === "marketReferenceName") {
      param[fieldName] = value || [];
    }
    if (fieldName === "primaryResearchMetricNotes") {
      param[fieldName] = value || [];
    }

    this.setState({ updateMasterData: param });
    this.forceUpdate();
  };

  AddNewRecord = () => {
    if (this.state.isInAddMode === true) {
      ShowToast("You can add one row at a time!", toastType.WARNING, 5000, 500);
      return false;
    }

    const rowNew = {
      marketId: -1,
      market: null,
      modifiedBy: "",
      modifiedDate: moment().format("YYYY-MM-DD"),
      statusId: 1,
      primaryResearchMetricId:-1,
        primaryResearchMetricNotes:"",
        marketReferenceName:""
    };
    this.setState({
      isInAddMode: true,
      selectedEditRowId: { key: "marketId", value: -1 },
      updateMasterData: rowNew,
    });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, getMarketMaster } = this.state;
    let searchData = this.state.getMarketMasterResponse;
    let filteredData = searchData.filter((value) => {
      return value.market
        ? value.market
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : false;
      // }
    });
    this.setState({ getMarketMaster: filteredData });
  };

  render() {
    const { getMarketMaster,getPrimaryResearchMetric } = this.state;
    const EditSource = [];
    EditSource.push({ key: "getPrimaryResearchMetric", data: getPrimaryResearchMetric });
    return (
      <>
        {this.state.showWarningMessage && (
          <ConfirmDialog
            IsOpen={this.state.showWarningMessage}
            Message={this.state.confirmMessage}
            OnHandelClick={(isValid) => {
              this.onClickHandle(isValid);
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <PageHeader title="Configure Market"></PageHeader>
          </div>
          <div className="col-sm-8 col-md-8 col-lg-8">
            <SearchBar
              size="large"
              name="searchInput"
              value={this.state.searchInput || ""}
              onChange={this.handleChange}
              label="Search"
              placeholder="Search Market."
            ></SearchBar>
          </div>
        </div>

        {getMarketMaster && (
          <GridTable
            columns={UpdateColumnWithConfiguration(
              marketMasterColumns,
              this.state.configuration,
              []
            )}
            data={getMarketMaster}
            renderActionColumn={this.renderActionColumn}
            selectedEditRowId={this.state.selectedEditRowId}
            isInAddMode={this.state.isInAddMode}
            EditSource={EditSource}
            handleTableEditChange={this.handleTableEditChange}
            updateData={this.state.updateMasterData}
            AddNewRecord={{
              action: this.AddNewRecord,
              title: "Add New Market",
              visible: true,
            }}
            reload={this.state.reloadTable}
          ></GridTable>
        )}
      </>
    );
  }
}

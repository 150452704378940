import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import PageHeader from "../common/PageHeader";
import { IsSectionVisible } from "../../utils/helper";
import { SECTIONS } from "../../constants/appConstants";

import AnnualAbsorptionToolReport from './AnnualAbortionToolReport';
import MasterCompsIndustrial from './MasterCompsIndustrial';
import VaccancyReport from './VaccancyReport';
import SubMarketBreakdownLeasingStats from './SubMarketBreakdownLeasingStats';
import SubMarketBreakdownPosNegAbsQtr from './SubMarketBreakdownPosNegAbsQtr';
import SubMarketBreakdownSubLeasingStats from './SubMarketBreakdownSubLeasingStats';
import SubMarketBreakdownAnnualAverageAbsorption from './SubMarketBreakdownAnnualAverageAbsorption';
import SubMarketBreakdownHistoricalTotalsAndAverages from './SubMarketBreakdownHistoricalTotalsAndAverages';
import SubMarketBreakdownVacancyCompetitiveOnlyProps from './SubMarketBreakdownVacancyCompetitiveOnlyProps';
import YTDAbsorptionSubmarket from './YTDAbsorptionSubmarket';
import QuarterAndMonthlyResearch from './QuarterAndMonthlyResearch';
import ClassificationBreakdown from './ClassificationBreakdown';
import CovidSubAbs from './CovidSubAbs';
import CurrentDevelopmentPipeline from './CurrentDevelopmentPipeline'
import CovidOfficeDelivered from './CovidOfficeDelivered'
import LeaseCompQAReport from "./LeaseCompQAReport";

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650
//   }
// });

function createData(reportType, report, action) {
  const finalVisibleReports = report.filter((x) => x.isVisible === true);
  if (finalVisibleReports && finalVisibleReports.length > 0) {
    return { reportType, report, action };
  }
}


const Getrows = (selectedMarketId,setNewMarketIdMethod0 ,setNewMarketIdMethod1 ,setNewMarketIdMethod2 ,setNewMarketIdMethod3 ,setNewMarketIdMethod4 ,setNewMarketIdMethod5 ,setNewMarketIdMethod6 ,setNewMarketIdMethod7 ,setNewMarketIdMethod8 ,setNewMarketIdMethod9 ,setNewMarketIdMethod10,setNewMarketIdMethod11,setNewMarketIdMethod12,setNewMarketIdMethod13,setNewMarketIdMethod14) => {
  return [
    createData(
      "Classification Breakdown",
      [
        {
          id:0,
          name: "Market Absorption Report",
          child: (
            <AnnualAbortionToolReport
              globalMarketId={selectedMarketId} 
              setNewMarketId={setNewMarketIdMethod0}
            ></AnnualAbortionToolReport>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Average_Annual_Absorption)

        },
        {
          id:1,
          name: "Vacancy Report",
          child: <VaccancyReport globalMarketId={selectedMarketId} 
          setNewMarketId={setNewMarketIdMethod1}/>,
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Vacancy_Report)

        },
        {
          id:2,
          name: "Classification Breakdown",
          child: (
            <ClassificationBreakdown
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod2}
            ></ClassificationBreakdown>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Classification_Breakdown)

        },
        // { name: 'Positive ABS Top 5 Last Qtr', child: <AnnualAbortionToolReport/> },
        // { name: 'Negative ABS Top 5 Last Qtr', child: <AnnualAbortionToolReport></AnnualAbortionToolReport> }
      ],
      ""
    ),
    createData(
      "Submarket Breakdown",
      [
        {
          id:3,
          name: "Leasing Statistics",
          child: (
            <SubMarketBreakdownLeasingStats
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod3}
            ></SubMarketBreakdownLeasingStats>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Leasing_Statistics)

        },
        {
          id:4,
          name: "Positive And Negative Absorption",
          child: (
            <SubMarketBreakdownPosNegAbsQtr
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod4}
            ></SubMarketBreakdownPosNegAbsQtr>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Positive_and_Negative_Absorption)

        },
        {
          id:5,
          name: "Sublease Statistics",
          child: (
            <SubMarketBreakdownSubLeasingStats
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod5}
            ></SubMarketBreakdownSubLeasingStats>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Sublease_Statistics)

        },
        {
          id:6,
          name: "Average Annual Absorption",
          child: (
            <SubMarketBreakdownAnnualAverageAbsorption
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod6}
            ></SubMarketBreakdownAnnualAverageAbsorption>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Average_Annual_Absorption)

        },
        {
          id:7,
          name: "Historical Totals And Averages",
          child: (
            <SubMarketBreakdownHistoricalTotalsAndAverages
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod7}
            ></SubMarketBreakdownHistoricalTotalsAndAverages>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Historical_Totals_and_Averages)

        },
        {
          id:8,
          name: "Vacancy - Competitive Only Properties",
          child: (
            <SubMarketBreakdownVacancyCompetitiveOnlyProps
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod8}
            ></SubMarketBreakdownVacancyCompetitiveOnlyProps>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Vacancy_Competitive_only_Properties)

        },
      ],
      ""
    ),
    createData(
      "Others",
      [
        {
          id:9,
          name: "YTD Absorption Submarket",
          child: (
            <YTDAbsorptionSubmarket
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod9}
            ></YTDAbsorptionSubmarket>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.YTD_Absorption_Submarket)

        },
        {
          id:10,
          name: "Quarter And Monthly Research",
          child: (
            <QuarterAndMonthlyResearch
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod10}
            ></QuarterAndMonthlyResearch>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Quarterly_and_Monthly_Research)

        },
        {
          id:11,
          name: "COVID - Sub Abs",
          child: (
            <CovidSubAbs globalMarketId={selectedMarketId} 
            setNewMarketId={setNewMarketIdMethod11}></CovidSubAbs>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.COVID_Sub_Abs)

        },
        {
          id:12,
          name: "COVID - Office Space Delivered Since 2015",
          child: (
            <CovidOfficeDelivered
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod12}
            ></CovidOfficeDelivered>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.COVID_Office_Space_Delivered_Since_2015)

        },
        {
          id:13,
          name: "Current Development Pipeline",
          child: (
            <CurrentDevelopmentPipeline
              globalMarketId={selectedMarketId}
              setNewMarketId={setNewMarketIdMethod13}
            ></CurrentDevelopmentPipeline>
          ),
          isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.Current_Development_PipeLine)
        },
      ],
      ""
    ),

     createData(
       "Lease Comp",
       [
         {
           name: "Lease Comp QA Report",
           child: (
             <LeaseCompQAReport
             globalMarketId={selectedMarketId}
             setNewMarketId={setNewMarketIdMethod14}
             ></LeaseCompQAReport>
           ),
           isVisible: IsSectionVisible(SECTIONS.Html_Reports, SECTIONS.YTD_Absorption_Submarket)

         },
           
       ],
       ""
     ),
    // createData(
    //   'Employee Reports',
    //   employeeTabs(),
    //   ''
    // )
  ];
};


class BasicTable extends React.PureComponent {
  constructor(props) {
    super(props);
    
    this.state = {
      p: "",
      reload: this.props.reload,
      selectedMarketId:this.props.globalMarketId,
      selectedLink: this.props.selectedLink
    };
  }

  componentWillReceiveProps(nextProps) {    
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {       
      this.setState({ selectedMarketId: nextProps.globalMarketId });       
      // if (this.state.p.id != undefined && this.state.p.id != null) {
      //   switch (this.state.p.id) {
      //     case 0:
      //       this.setNewMarketId0(nextProps.globalMarketId);
      //       break;
      //     case 1:
      //       this.setNewMarketId1(nextProps.globalMarketId);
      //       break;
      //     case 2:
      //       this.setNewMarketId2(nextProps.globalMarketId);
      //       break;
      //     case 3:
      //       this.setNewMarketId3(nextProps.globalMarketId);
      //       break;
      //     case 4:
      //       this.setNewMarketId4(nextProps.globalMarketId);
      //       break;
      //     case 5:
      //       this.setNewMarketId5(nextProps.globalMarketId);
      //       break;
      //     case 6:
      //       this.setNewMarketId6(nextProps.globalMarketId);
      //       break;
      //     case 7:
      //       this.setNewMarketId7(nextProps.globalMarketId);
      //       break;
      //     case 8:
      //       this.setNewMarketId8(nextProps.globalMarketId);
      //       break;
      //     case 9:
      //       this.setNewMarketId9(nextProps.globalMarketId);
      //       break;
      //     case 10:
      //       this.setNewMarketId10(nextProps.globalMarketId);
      //       break;
      //     case 11:
      //       this.setNewMarketId11(nextProps.globalMarketId);
      //       break;
      //     case 12:
      //       this.setNewMarketId12(nextProps.globalMarketId);
      //       break;
      //     case 13:
      //       this.setNewMarketId13(nextProps.globalMarketId);
      //       break;
      //     default:
      //     // code block
      //   }
      // }
      
    }
  }

  componentDidMount() {}
  // static getDerivedStateFromProps(newProps, state) {
  //   if ((newProps || {}).reload !== (state || {}).reload) {
  //     // this.LoadData();
  //     return {
  //       p: "",
  //       reload: newProps.reload,
  //       globalMarketId:newProps.globalMarketId,
  //     };
  //   }
  //   return null;
  // }

  // }
  // export default function BasicTable (props) {

  // const [p, setPage] = useState('');
  // const [reload, setReload] = useState(props.reload);
  handleClick = (index, i, child) => {
    this.setState({ p: "" });
    this.setState({ p: child });
  };

  render() {
    return this.state.p !== "" ? (
      this.state.p.child
    ) : (
      <>
      
        <PageHeader title={"Reports"}></PageHeader>
        <TableContainer component={Paper} style={{ marginTop: "10px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Report Type </TableCell>
                <TableCell align="left">Report Name</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {((Getrows(this.state.selectedMarketId,this.setNewMarketIdMethod0 ,this.setNewMarketIdMethod1 ,this.setNewMarketIdMethod2 ,this.setNewMarketIdMethod3 ,this.setNewMarketIdMethod4 ,this.setNewMarketIdMethod5 ,this.setNewMarketIdMethod6 ,this.setNewMarketIdMethod7 ,this.setNewMarketIdMethod8 ,this.setNewMarketIdMethod9 ,this.setNewMarketIdMethod10,this.setNewMarketIdMethod11,this.setNewMarketIdMethod12,this.setNewMarketIdMethod13,this.setNewMarketIdMethod14) || []).filter((x) => x !== undefined) || []).map(
                (row, index) => (
                  <TableRow key={row.reportType}>
                    <TableCell component="th" scope="row">
                      {row.reportType}
                    </TableCell>
                    <TableCell align="left">
                      {row.report.map((cal, i) => (
                        <p key={i}>
                          <Link
                            style={{ cursor: "pointer" }}
                            key={i}
                            onClick={() => this.handleClick(index, i, cal)}
                            to=""
                          >
                            {cal.name}
                          </Link>
                        </p>
                      ))}
                    </TableCell>
                    <TableCell align="left">{row.action}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
}

export default BasicTable;


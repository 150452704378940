import React from 'react';
import ChipInput from 'material-ui-chip-input';

export default class ReactChipInput extends React.PureComponent {
  constructor (props) {
    super(props);
    const tag = (this.props.value || []); //.length > 0 ? (this.props.value || '').split('|') : [];
    this.state = {
      tags: tag.length > 0 ? tag : [],
      errors: [],
      isValid: true
    };
  }
  //   componentWillReceiveProps(newProps) {}

  //   handelClose = () => {
  //     this.props.close();
  //   };
  // Add Chips
  validate (chip) {
    const input = chip;
    const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(input)) {
      const isValid = false;

      this.setState({
        tags: null,
        errors: 'Please enter valid email address.'

      });
      //  alert("Please enter valid email address.");
      // }
    }
  }

handleAddChip = (chip) => {
  const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  const isValid = pattern.test(chip);
  if (isValid == false) {
    this.setState({
      tags: [...this.state.tags, null],
      errors: 'Please enter valid email address.'
    });
    this.handleDeleteChip(chip);
  } else {
    this.setState({
      tags: [...this.state.tags, chip]
    }, () => { this.props.onChange(this.state.tags); });
  }
}

// Delete Chips
handleDeleteChip = (chip) => {
  this.setState({
    tags: _.without(this.state.tags, chip)
  }, () => { this.props.onChange(this.state.tags); });
}

render () {
  return (
            <>
      <ChipInput
        color="primary"
        className="chip-input"
            label="Email"
            value={this.state.tags}
            onAdd={(chip) => this.handleAddChip(chip)}
            onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
        />
         <div className="text-danger">{this.state.errors}</div>
        </>
  );
}
}

import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { API_URL } from '../../constants/api';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { apiBatchRequest, apiPost, apiRequest } from '../../utils/api_service';
import SaveIcon from '@material-ui/icons/Save';
import { ShowToast, toastType } from '../../utils/toast-service';
import camelcaseKeys from "camelcase-keys";
import {
  isGridFormValid,
  IsSectionVisible,
  UpdateColumnWithConfiguration
} from '../../utils/helper';
import GridTable from '../common/Table/Table';
import { SECTIONS } from '../../constants/appConstants';
import { userMarketMappingColumnData } from '../../constants/MockData';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../common/Dailogbox/confirmDailog';
import SearchBar from '../common/AutoSearch/SearchBar';
import PageHeader from '../common/PageHeader';

class ConfigureGroupMarket extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      marketMasterData: [],
      isInAddMode: false,
      userMarketData: [],
      configuration: [],
      employeeMasterData: [],
      responseuserMarketData: [],
      selectedEditRowId: undefined,
      updateData: {
        userEmail: null,
        marketId: null,
        market: null,
        employeeId: null
      },
      userMarketMappingColumn: _.clone(userMarketMappingColumnData),
      isDelete: false,
      showWarningMessage: false,
      confirmMessage: '',
      searchInput: ""
    };
  }

  componentDidMount () {
    this.getMasterData();
  }

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();

    });
  };

  globalSearch = () => {
    let { searchInput, userMarketData } = this.state;  
    let searchData = this.state.responseuserMarketData;
    let filteredData = searchData.filter((value) => {     
      return (        
        (value.userEmail
        ? value.userEmail
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : false)
      );
      // }
    });
    this.setState({ userMarketData: filteredData });  
  };

  getMasterData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: 'marketMasterData',
        params: { businessLineId: 1 },
        // fromCache: true,
        // duration: 15
      },
      {
        url: API_URL.Users.GetEmployeeMasterData + '?showInActive=true',
        key: 'employeeMasterData'
      },
      {
        url: API_URL.Users.GetAllMarketAccessPermission,
        key: 'userMarketData'
      }
    ])
      .then(({ marketMasterData, employeeMasterData, userMarketData }) => {
        this._asyncRequest = null;
        if (marketMasterData.success !== true) {
          ShowToast('Something went wrong', toastType.ERROR, 5000, 500, 'Server error');
        }
        function compare(a, b) {
          if (a.email.toLowerCase() < b.email.toLowerCase()) {
            return -1;
          }
          if (a.email.toLowerCase() > b.email.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        camelcaseKeys(employeeMasterData.data).sort(compare);
        // console.log(employeeMasterData.data);
        const marketIds = camelcaseKeys(marketMasterData.data).map(x => x.marketId).join('|');
        // console.log(marketIds);
        // console.log(typeof marketIds);
        camelcaseKeys(marketMasterData.data).unshift({marketId: marketIds,market: 'All'});
        
        this.setState({
          marketMasterData: camelcaseKeys(marketMasterData.data) || [],
          userMarketData: camelcaseKeys(userMarketData.data) || [],
          configuration: camelcaseKeys(userMarketData.configuration) || [],
          employeeMasterData: camelcaseKeys(employeeMasterData.data) || [],
          responseuserMarketData: _.cloneDeep(camelcaseKeys(userMarketData.data) || []),
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  GetAllMarketAccessPermission = () => {
    apiRequest({
      url: API_URL.Users.GetAllMarketAccessPermission
    }).then(({ data: response }) => {
      this.setState({
        userMarketData: response || [],
        responseuserMarketData: _.cloneDeep(camelcaseKeys(response) || [])
      });
      this.globalSearch();
    });
  };

  cancelMode = () => {
    this.setState({ selectedEditRowId: undefined, isInAddMode: false, updateData: undefined });
  };

  isValidForm = () => {
    let IsValid = true;
    const { updateData, userMarketMappingColumn } = this.state;
    userMarketMappingColumn.forEach((ele) => {
      isGridFormValid(updateData[ele.key], ele, updateData);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }
    });
    this.setState({ userMarketMappingColumn }, this.forceUpdate());
    return IsValid;
  };

  saveUpdatedRecord = () => {
    if (!this.isValidForm()) { return false; } else {
      const newParam = this.state.updateData;
      const params = {
        marketId: newParam.marketId,
        userEmail: newParam.userEmail,
        employeeId: newParam.employeeId,
        marketAccessPermissionId: newParam.marketAccessPermissionId
      };
      this.SaveUpdateGroupMarket(params);
    };
  }

  SaveUpdateGroupMarket = (params) => {
    apiPost({
      url: API_URL.Users.InsertMarketAccessPermission,
      params: {},
      postBody: params
    }).then(({ data: response }) => {
      if (response.success === true) {
        ShowToast(this.state.isInAddMode ? 'Record added successfully.' : 'Record updated successfully.', toastType.SUCCESS, 5000, 500);
        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: ''
        }, () => this.GetAllMarketAccessPermission());
      } else {
        ShowToast(response.errorMessage, toastType.ERROR, 5000, 500);
      }
    });
  };

  renderActionColumn = (cellInfo) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined && cellInfo.marketAccessPermissionId === this.state.selectedEditRowId.value
          ? (
            <>
              <Tooltip title="Save Record">
                <IconButton aria-label={'refreshData'} size="small">
                  <SaveIcon fontSize="small" size="small" onMouseDown={this.saveUpdatedRecord} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel">
                <IconButton aria-label={'refreshData'} size="small" onClick={this.cancelMode}>
                  <CancelIcon fontSize="small" size="small" />
                </IconButton>
              </Tooltip>
            </>
            )
          : <>{
            // (IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT)) && (
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={'refreshData'}
                size="small"
                onClick={() => {
                  this.setState({ selectedEditRowId: { key: 'marketAccessPermissionId', value: cellInfo.marketAccessPermissionId }, isInAddMode: false, updateData: { ...cellInfo } });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          // )
          }
            {
            // (IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT)) && (
              <Tooltip title="Edit Record">
                <IconButton
                  aria-label={'refreshData'}
                  size="small"
                  onClick={() => {
                    this.deleteRecord(cellInfo);
                  }}
                >
                  <DeleteIcon fontSize="small" size="small" />
                </IconButton>
              </Tooltip>
            // )
            }
          </>
        }
      </>
    );
  };

  /**
 * delete record
 */
  deleteRecord (cellInfo) {
    this.setState({
      showWarningMessage: true,
      confirmMessage: 'Do you want to delete this record ?',
      updateData: cellInfo
    });
  };

  /**
 * handle dialog box click event
 * @param {*} e
 */
  onClickHandle (e) {
    if (e) {
      const newParam = this.state.updateData;
      const params = {
        marketId: null,
        userEmail: null,
        employeeId: newParam.employeeId,
        marketAccessPermissionId: newParam.marketAccessPermissionId
      };
      this.SaveUpdateGroupMarket(params);
    } else {
      this.setState({
        showWarningMessage: false,
        confirmMessage: ''
      });
    }
  }



  handleTableEditChange = (value, fieldName, row, addMode) => {
    const { userMarketData, marketMasterData, userMarketMappingColumn, employeeMasterData } = this.state;
    const param = { ...this.state.updateData };

    if (fieldName === 'market') {
      const valueOfMarket = [];
      value.forEach(val => {
        marketMasterData.find((x) => x.market === val) &&
          valueOfMarket.push(marketMasterData.find((x) => x.market === val).marketId);
      });
      param.marketId = valueOfMarket.join('|');
      param[fieldName] = value.join('|');
    } else if (fieldName === 'userEmail' && value !== null) {
      const valueOfEmployee = employeeMasterData.find((x) => x.email === value);
      param.employeeId = (valueOfEmployee || {}).employeeId;
      const userMarket = userMarketData.find(x => x.userEmail.toLowerCase().trim() === value.toLowerCase().trim());
      if (userMarket !== undefined) {
        param.marketId = userMarket.marketId;
        param.market = userMarket.market;
      }
      param[fieldName] = value.trim();
    } else {
      param[fieldName] = value;
    }
    isGridFormValid(value, userMarketMappingColumn.find(x => x.key === fieldName), param);

    this.setState({ updateData: param });
  };

  AddNewRecord = () => {
    if (this.state.isInAddMode === true) {
      ShowToast('You can add one row at a time!', toastType.WARNING, 5000, 500);
      return false;
    }

    const rowNew = {
      marketAccessPermissionId: 0,
      userEmail: null,
      marketId: null,
      market: null,
      employeeId: null
    };
    this.setState({ isInAddMode: true, selectedEditRowId: { key: 'marketAccessPermissionId', value: 0 }, updateData: rowNew, userMarketMappingColumn: _.cloneDeep(userMarketMappingColumnData) });
  };

  render () {
    const { marketMasterData, employeeMasterData, configuration, userMarketData, isInAddMode, selectedEditRowId, updateData, userMarketMappingColumn } = this.state;
    const EditSource = [];
    EditSource.push(
      { key: 'employeeMasterData', data: (employeeMasterData || []).filter(x => x.employeeStatus === true) },
      { key: 'marketMasterData', data: marketMasterData }
    );
    return (
      <>
        {this.state.showWarningMessage && (
          <ConfirmDialog
            IsOpen={this.state.showWarningMessage}
            Message={this.state.confirmMessage}
            OnHandelClick={(isValid) => { this.onClickHandle(isValid); }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
         <div className="row">
         <div className="col-md-4 col-lg-4">
                <PageHeader title="Market Access Permission"></PageHeader>
              </div>
              <div className="col-sm-8 col-md-8 col-lg-8">
              <SearchBar
                  size="large"
                  name="searchInput"
                  value={this.state.searchInput || ""}
                  onChange={this.handleChange}
                  label="Search"
                  placeholder="Search employees."
                ></SearchBar>
              </div>
              
         </div>
        
        <Table
          aria-labelledby="tableMenuTitle"
          size={'small'}
          aria-label="configuration table"
        >
          {/* <TableHead>
            <TableRow>
              <TableCell
                key={'configureHeader'}
                className="content-header"
                colSpan={2}
              >
                <div className="content-header d-flex">
                  {' '}
                  <div
                    className="col-xs-11"
                    style={{ display: 'inline-block', alignItems: 'center' }}
                  >
                    Configure Market Access Permissions
                                    </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            <TableRow>
              <TableCell>
                <GridTable
                  columns={UpdateColumnWithConfiguration(userMarketMappingColumn, configuration, [])}
                  data={userMarketData}
                  config={userMarketMappingColumn}
                  renderActionColumn={this.renderActionColumn}
                  selectedEditRowId={selectedEditRowId}
                  isInAddMode={isInAddMode}
                  EditSource={EditSource}
                  handleTableEditChange={this.handleTableEditChange}
                  updateData={updateData}
                  isInline={true}
                  AddNewRecord={{ action: this.AddNewRecord, title: 'Add User Market', 
                  visible:true,
                  // visible: IsSectionVisible(SECTIONS.CONFIGURATION, SECTIONS.CONFIGURE_USER_MARKET_PERMISSION, SECTIONS.ADD_EDIT) 
                }}
                ></GridTable>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  };
}

export default ConfigureGroupMarket;

import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function TableSkeleton({ height = 200 }) {
	return (
		<>
			<Skeleton variant="rect" height={40} />
			<Skeleton variant="rect" height={height} />
		</>
	);
}


import React from "react";
import GridTable from "../common/Table/Table";
import camelcaseKeys from "camelcase-keys";
import { API_URL } from "../../constants/api";
import { apiBatchRequest, apiPost } from "../../utils/api_service";
import {
  
    UpdateColumnWithConfiguration,
  } from "../../utils/helper";
import { chartPocColumns } from "../../constants/MockData";
import { IconButton, Tooltip } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
  
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import { ShowToast, toastType } from "../../utils/toast-service";
export default class ChartPoc extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isInAddMode: false,
            rowsPerPage:20,
            getChartData:[],
            selectedEditRowId:undefined,
            configuration:[],
            updateChartData:{
                buildingClass: null,
rptVacantsfImportId: 0,
vacantSf: 0.0,
yearRecorded: 0,
yoyChange: 0.0,
            }

        }
    }
    componentDidMount() {
        this.getRequiredData();
      }
    
      getRequiredData = () => {
        this._asyncRequest = apiBatchRequest([
          {
            url: API_URL.ChartPoc.getChartData,
            key: "getChartData",
          },
        ])
          .then((getChartData) => {
            this._asyncRequest = null;
            if (getChartData.getChartData.success === true) {
              this.setState({
                getChartData:
                  camelcaseKeys(getChartData.getChartData.data) || [],
                  getChartDataMasterResponse:
                  camelcaseKeys(getChartData.getChartData.data) || [],
                configuration:
                  camelcaseKeys(getChartData.getChartData.configuration) ||
                  [],
                reloadTable: true,
              });
              this.forceUpdate();
            } else {
              ShowToast(
                "Something went wrong",
                toastType.ERROR,
                5000,
                500,
                "Server error"
              );
            }
          })
          .catch(() => {
            this._asyncRequest = null;
          });
      };
      renderActionColumn = (cellInfo, key, isInAddMode) => {
        return (
          <>
            {this.state.selectedEditRowId !== undefined &&
            cellInfo.rptVacantsfImportId === this.state.selectedEditRowId.value ? (
              <>
                <Tooltip title="Save Record">
                  <IconButton
                    aria-label={"refreshData"}
                    size="small"
                    onClick={() => {}}
                  >
                    <SaveIcon
                      fontSize="small"
                      size="small"
                      onClick={
                        this.state.isInAddMode
                          ? this.saveUpdatedRecord
                          : this.saveUpdatedRecord
                      }
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton
                    aria-label={"refreshData"}
                    size="small"
                    onClick={this.cancelEditMode}
                  >
                    <CancelIcon fontSize="small" size="small" />
                  </IconButton>
                </Tooltip>
              </>
            ) : (
              <>
                <Tooltip title="Edit Record">
                  <IconButton
                    aria-label={"refreshData"}
                    size="small"
                    onClick={() => {
                      this.setState({
                        selectedEditRowId: {
                          key: "rptVacantsfImportId",
                          value: cellInfo.rptVacantsfImportId,
                        },
                        isInAddMode: false,
                        updateChartData: cellInfo,
                      });
                    }}
                  >
                    <EditIcon fontSize="small" size="small" />
                  </IconButton>
                </Tooltip>
                {/*<Tooltip title="Edit Record">
                  <IconButton
                    aria-label={"refreshData"}
                    size="small"
                    onClick={() => {
                      this.deleteRecord(cellInfo);
                    }}
                  >
                    <DeleteIcon fontSize="small" size="small" />
                  </IconButton>
                </Tooltip>*/}
              </>
            )}
          </>
        );
      };
    
      handleTableEditChange = (value, fieldName, row, addMode) => {
        const param = this.state.updateChartData;
    if (fieldName === "vacantSf") {
      param[fieldName] = parseFloat(value) || 0.0;
    }
    if (fieldName === "yoyChange") {
      param[fieldName] = parseFloat(value) || 0;
    }
    if (fieldName === "yearRecorded") {
        param[fieldName] = parseInt(value) || 0;
      }
      if (fieldName === "buildingClass") {
        param[fieldName] = value;
      }
        
    
        this.setState({ updateChartData: param },()=>{this.forceUpdate()});
      };
      saveUpdatedRecord = () => {
        const newParam = this.state.updateChartData;
        apiPost({
          url: API_URL.ChartPoc.updateChartData,
          params: { },
          postBody: newParam,
        }).then(({ data: response }) => {
          // debugger;
          if (response.success === true) {
            ShowToast(
              this.state.isInAddMode
                ? "Record added successfully."
                : "Record updated successfully.",
              toastType.SUCCESS,
              5000,
              500
            );
    
            this.setState({
              updateData: undefined,
              selectedEditRowId: undefined,
              reloadTable: !this.state.reloadTable,
              isInAddMode: false,
              showWarningMessage: false,
              confirmMessage: "",
            });
            this.getRequiredData();
            this.forceUpdate();
          } else {
            ShowToast(response.message, toastType.ERROR, 5000, 5000);
          }
        });
      };
    render()
    {
return(
        <>
        <p>test</p>
         {this.state.getChartData && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <GridTable
                    
                    //config={userConfig}
                    columns={UpdateColumnWithConfiguration(
                        chartPocColumns,
                      this.state.configuration,
                      []
                    )}
                    selectedEditRowId={this.state.selectedEditRowId}
                    data={this.state.getChartData}
                    renderActionColumn={this.renderActionColumn}
                    reload={this.state.reloadTable}
                    isInAddMode={this.state.isInAddMode}
                    updateData={this.state.updateChartData}
                   // isProperty={true}
                    EditSource={[]}
                    handleTableEditChange={this.handleTableEditChange}
                    // AddNewRecord={{
                    //   action: this.onAddNewClick,
                    //   title: "Add Company",
                    //   visible: IsSectionVisible(
                    //     SECTIONS.Company,
                    //     SECTIONS.Company_Listing,
                    //     SECTIONS.ADD_EDIT
                    //   ),
                    // }}
                  ></GridTable>
                </div>
              </div>
            )}
          
        </>);
    }
}
import React from "react";
import "./index.less";
import ReactFundTable from "../../common/react-table";
export default function ReactTableList({
	onRowClick,
	cardData,
	columnList,
	idKey,
	sortedObj,
	onSortedChange,
	sortable = true,
	groupingKey,
	isExpandAll,
	onExpandToggle,
	showFooter,
	showHeader,
	isRowClickable = true,
	showTitle = false,
	tableTitle = "",
	rowEditAction
}) {
	return (
		<div className="row">
			<div className="col-xs no-padding">
				<ReactFundTable
					columnList={columnList}
					tableData={cardData}
					onRowClick={onRowClick}
					idKey={idKey}
					sortedObj={sortedObj}
					onSortedChange={onSortedChange}
					sortable={sortable}
					groupingKey={groupingKey}
					isExpandAll={isExpandAll}
					showFooter={showFooter}
					showHeader={showHeader}
					onExpandToggle={onExpandToggle}
					isRowClickable={isRowClickable}
					showTitle={showTitle}
					tableTitle={tableTitle}
					rowEditAction={rowEditAction}
				/>
			</div>
		</div>
	);
}

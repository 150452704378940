import React from "react";
import { AiOutlineCreditCard } from "react-icons/ai";
import {
	FaRegListAlt,
	FaSortAmountDown,
	FaSortAmountUp,
	FaRegObjectUngroup,
	FaRegFileExcel,
	FaRegFilePdf
} from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import "./index.less";
import { cardLandingView, listLandingView } from "../../../constants/constants";
import RadioGroup from "../radio";
import Select from "../select";
import ButtonIcon from "../icon-button";
import ButtonMenu from "../button-menu";
import ButtonPopup from "../button-menu-popup";
import MenuListComposition from "../button-download-popup";
import Button from "../button";
import { routes } from "../../../constants/routes";
import { uniq } from "lodash-es/array";

import { sortAscOrder } from "../../../utils/generic";
import CheckboxWithLabel from "../checkbox";
import AsOfDate from "../as-of-date";
import { IsSectionVisible } from "../../../utils/helper";
import { SECTIONS } from "../../../constants/appConstants";

export default React.memo(function LandingFilter({
	onSortChange,
	sortBy,
	sortByKey,
	onChangeRadio,
	changeCardView,
	onExpandToggle,
	cardView,
	sortAsc,
	downloadOptions,
	downloadReport,
	showAddLoan = false,
	showDownLoadBtn = false,
	showAsOfDate = false,
	onDateSelect,
	selectedDate,
	onAddLoan,
	isHideDownload = false,
	showGroupBy = false,
	groupByList,
	onGroupByChange,
	showDownLoadPdfBtn = false,
	downloadPdf,
	downloadPdfDataTip = "Download Pdf",
	downloadPdfOptions,
	showDownloadPopup = false,
	showSuppressZero = false,
	isSuppressZero = false,
	setStateValues,
	reportList = [],
	currentFundId,
	showCardView = false,
	showSortBy = false,
	propertyInfo,
	showAddProperty = false,
	showAddMarket = false,
	showAddIndustry=false,
	showAddSubMarket = false
}) {
	return (
		<div className="row landingFilterBox">
			
			<div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 content filterGroup">
				{showAsOfDate && (
					<AsOfDate onChange={onDateSelect} selectedDate={selectedDate} />
				)}
				{IsSectionVisible(
						SECTIONS.Spec_Suite,
						SECTIONS.Spec_Suite_Add_Edit,
						SECTIONS.ADD_EDIT
					) && showAddProperty && (
					<Button
						styleClass="simpleButton auto"
						label="Add Spec Suite Information"
						dataTip="Add Spec Suite Information"
						onClick={() => onAddLoan("property", true)}
					/>
				)}
				{/*{showAddMarket && (
					<Button
						styleClass="simpleButton auto"
						label="Add Market"
						dataTip="Add Market"
						onClick={() => onAddLoan("property", true)}
					/>
				)}*/}
				{/*{showAddIndustry && (
					<Button
						styleClass="simpleButton auto"
						label="Add Industry Type"
						dataTip="Add Industry Type"
						onClick={() => onAddLoan("property", true)}
					/>
				)}
				{showAddSubMarket && (
					<Button
						styleClass="simpleButton auto"
						label="Add SubMarket"
						dataTip="Add SubMarket"
						onClick={() => onAddLoan("property", true)}
					/>
				)}
				{showAddLoan && (
					<Button
						styleClass="simpleButton auto"
						label="Add Spec Suite"
						dataTip="Add Spec Suite"
						onClick={() => onAddLoan("spec", true)}
					/>
				)}
				{showSuppressZero && (
					<CheckboxWithLabel
						id="supress"
						label="Suppress zero"
						isChecked={isSuppressZero}
						onChange={() => setStateValues({ isSuppressZero: !isSuppressZero })}
						tooltip="Check the box to hide entries having zero amount."
					/>
				)}*/}
				{showDownloadPopup && (
					<MenuListComposition
						buttonLabel={<FaRegFileExcel size="1.3rem" />}
						dataTip="Download Report"
						fundsList={downloadOptions}
						reportList={reportList}
						menuList={downloadOptions}
						excelDownload={downloadReport}
						pdfDownload={downloadPdf}
						currentFundId={currentFundId}
					/>
				)}
				{showDownLoadBtn && !isHideDownload && (
					<ButtonIcon dataTip="Download Excel" onClick={downloadReport}>
						<FiDownload size="1.3rem" />
					</ButtonIcon>
				)}

				{showDownLoadPdfBtn && (
					<ButtonMenu
						buttonLabel={<FaRegFilePdf size="1.3rem" />}
						dataTip={downloadPdfDataTip}
						menuList={downloadPdfOptions}
						onSelect={downloadPdf}
						styleClass="menuButtonGroupBy"
						menuIconButton
					/>
				)}

				{showGroupBy && (
					<ButtonMenu
						buttonLabel={<FaRegObjectUngroup size="1.9rem" />}
						dataTip="Group by"
						menuList={groupByList}
						onSelect={onGroupByChange}
						styleClass="menuButtonGroupBy"
						menuIconButton
					/>
				)}
				{showCardView && (
					<ButtonIcon
						dataTip={cardView !== cardLandingView ? "Card View" : "List View"}
					>
						{cardView !== cardLandingView ? (
							<AiOutlineCreditCard
								size="1.56rem"
								onClick={() => changeCardView(cardLandingView)}
							/>
						) : (
							<FaRegListAlt
								size="1.46rem"
								onClick={() => changeCardView(listLandingView)}
							/>
						)}
					</ButtonIcon>
				)}
				{showSortBy && (
					<ButtonPopup
						// disabled={isListView}
						dataTip="Sort By"
						buttonIcon={
							sortAsc ? (
								<FaSortAmountUp size="1.3rem" />
							) : (
								<FaSortAmountDown size="1.3rem" />
							)
						}
						item1={
							<div className="popupItem">
								<div>Sort By:</div>
								<div>
									<Select
										onChange={onSortChange}
										optionData={sortAscOrder(sortBy, "value")}
										defaultValue={sortByKey}
									/>
								</div>
							</div>
						}
						item2={
							<div className="popupItem">
								<RadioGroup
									groupName="sortRadio"
									radioArr={[
										{ key: true, label: "Ascending", checked: true },
										{ key: false, label: "Descending" }
									]}
									selected={sortAsc}
									onChangeRadio={onChangeRadio}
								/>
							</div>
						}
					/>
				)}
			</div>
			{/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 content filterGroup propertyInfo">
				{propertyInfo.length > 0 && (
					<>
						<span>
							<span className="titleText">Market:</span>
							{uniq(propertyInfo.map(x => x.market)).toString()}
						</span>
						<span>
							<span className="titleText">Submarket:</span>
							{uniq(propertyInfo.map(x => x.subMarket)).toString()}
						</span>
						<span>
							<span className="titleText">Property:</span>
							{propertyInfo.map(x => x.propertyName).toString()}
						</span>
					</>
				)}
			</div> */}
		</div>
	);
});

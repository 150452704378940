import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { CircularProgress, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: "37ch",
	},

	search: {
		position: "relative",
		borderRadius: "2px",
		//border: "1px solid",
		// backgroundColor: '#EFF1F8',
		"&:hover": {
			backgroundColor: "white",
		},
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			marginTop: "1rem",
			width: "100%",
		},
	},
	searchIcon: {
		// padding: theme.spacing(0, 2),
		height: "100%",
		// position: 'absolute',
		pointerEvents: "none",
		display: "flex",
		float: "left",
		paddingTop: "8px",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		padding: "8px 12px",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "40ch",
			"&:focus": {
				width: "40ch",
			},
		},
	},
}));

AutoSearch.propTypes = {
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	sourceData: PropTypes.array,
	filterConfig: PropTypes.array,
	onSearch: PropTypes.func,
	onInputChange: PropTypes.func,
	isEditEnabled: PropTypes.bool,
	isRedirect: PropTypes.bool,
};

export default function AutoSearch(props) {
	const {
		placeholder,
		value,
		name,
		label,
		sourceData,
		filterConfig,
		onSearch,
		onInputChange,
		isEditEnabled,
		isRedirect,
	} = props;
	const classes = useStyles();
	const [selectedValue, setSelectedValue] = React.useState(null);
	const [open, setOpen] = React.useState(false);
	const loading = open && sourceData.length === 0;

	const onPaste = (event) => {
		var text = event.clipboardData.getData("Text");
		var index = text.substring(0).search(/[A-Za-z]/);
		if (index != -1) {
			const ch = text[index];
			let replacement = "";
			//change case
			if (ch == ch.toLowerCase()) replacement = ch.toUpperCase();
			else if (ch == ch.toUpperCase()) replacement = ch.toLowerCase();

			const newText =
				text.substr(0, index) +
				replacement +
				text.substr(index + replacement.length);
			//let lowerCaseText = text.toLowerCase();
			//event.target.value = lowerCaseText;
			onSearchInputChange(event, newText);
			event.preventDefault();
		}
	};

	const onSearchInputChange = (event, value) => {
		// event.preventDefault();
		const res = [];
		res.push(value);
		setSelectedValue(value);
		onInputChange(value);
		//this.setState({loading:false});
	};
	return (
		<div>
			{/* <TextField
        placeholder={placeholder}
        style={{ border: '1px solid' }}
        hiddenLabel
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
        InputProps={{
          startAdornment: <InputAdornment position="start">
            <img src={FilterImage} alt="logo" className={'stream-logo'} style={{paddingLeft: '10px'}}/>
          </InputAdornment>,
        }}
      /> */}
			{/* <Autocomplete
      id="combo-box-demo"
      options={[]} // .map((option) => option.title_display)
      getOptionLabel={[]}
      style={{ width: 300 }}
      onInputChange={[]}
      renderInput={(params) => (
        <TextField
        placeholder={placeholder}
        style={{ border: '1px solid' }}
        hiddenLabel
        id="standard-start-adornment"
        className={clsx(classes.margin, classes.textField)}
          InputProps={{
            startAdornment: <InputAdornment position="start">
            <img src={FilterImage} alt="logo" className={'stream-logo'} style={{paddingLeft: '10px'}}/>
          </InputAdornment>
          }}
        />
      )}
    /> */}
			<Autocomplete
				size="small"
				underlineStyle={{ display: "none" }}
				limitTags={1}
				freeSolo
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				options={(sourceData || []).map(
					(option) => option[filterConfig[0].key]
				)}
				// options={(sourceData||[]).map((option) => option[filterConfig[0].key])}
				// getOptionLabel={(option) => option[filterConfig[0].key]}
				autoHighlight
				className={isEditEnabled ? "autoSearch-box" : "autoSearchedit-box"}
				value={selectedValue}
				key="dropDown_filter"
				id="dropDown_filter"
				onChange={(event, value) => {
					setSelectedValue(value);
					onSearch(event, value);
					// this.onChange(filter, event, value);
				}}
				onInputChange={(event, value) => {
					// let inputValue=value != "" && this.state.selectedValue.length > 0  ? this.state.selectedValue[0]+value : value;
					isRedirect == true && value != "" && value != null && value.length > 2
						? onSearchInputChange(event, value)
						: null;

					// value != "" && value != null && value.length > 2 ? this.onInputChange(filter, event, value):null;
				}}
				// loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						fullWidth
						className="autosearc-text"
						hiddenLabel
						variant="outlined"
						margin="normal"
						placeholder={placeholder}
						onPaste={(event) => (props.isBuildingOwner ? onPaste(event) : null)}
						// InputProps={{
						//   ...params.InputProps,
						//   endAdornment: (
						//     <React.Fragment>
						//       { loading && isRedirect == true ? <CircularProgress  size={20} /> : null}
						//       {params.InputProps.endAdornment}
						//     </React.Fragment>
						//   ),
						// }}
						// InputProps={{
						//   startAdornment: <InputAdornment position="start">
						//   <img src={FilterImage} alt="logo" className={'stream-logo'} style={{paddingLeft: '10px'}}/>
						// </InputAdornment>
						// }}
					/>
				)}
			/>

			{/* <InputBase
              placeholder={placeholder}
              //onChange={(event)=>props.onChange(event)}
              name={name}
              
              value={value}
              inputProps={{ 'aria-label': 'search' }}
            /> */}
			{/* <div className={classes.searchIcon}>
             <img src={FilterImage} alt="logo" className={'stream-logo'} /> */}

			{/* </div> */}
			{/* <SearchIcon style={{ float: 'left' , paddingTop : '5px'}}/> */}
		</div>
	);
}

import React from "react";
import "./style.less";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client'



export default class PowerBIEmbedMine extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        config: this.props.config,
      };
    }
  
    componentWillReceiveProps(nextProps) {    
      if (nextProps.config !== this.state.config) {      
        this.setState({ config: nextProps.config });        
      }
    }
     
    render() {
      return (
        <div className='fullPage'>
      {this.state.config.hasOwnProperty('accessToken') && (
        <PowerBIEmbed
          embedConfig={{
            type: 'report',   // Supported types: report, dashboard, tile, visual and qna
            id: this.state.config.embedId,
            embedUrl: this.state.config.embedUrl,
            accessToken: this.state.config.accessToken,
            tokenType: models.TokenType.Aad,
            settings: {
              layoutType: models.LayoutType.Custom,
              customLayout: {
                displayOption: models.DisplayOption.FitToPage
              },
              commands: {
                parameterPanel: {
                  enabled: true,
                  expanded: true
                }
              },             
              background: models.BackgroundType.Transparent,
            }
          }}

          eventHandlers={
            new Map([
              ['loaded', function () { console.log('Report loaded'); }],
              ['rendered', function () { console.log('Report rendered'); }],
              ['error', function (event) { console.log(event.detail); }]
            ])
          }

          cssClassName={"fullPage"}

          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />

      )}
      </div>
      );
    }
  }



// function PowerBIEmbedMine({config}) {
//     console.log(config);
//     return (
//     <>
    
//     {/* Dummy */}
     
//       {/* <div className='fullPage'>

//         <PowerBIEmbed
//           embedConfig={{
//             type: 'report',   // Supported types: report, dashboard, tile, visual and qna
//             id: 'e4af8f08-7ea7-4745-8651-b24e32c10f1e',
//             embedUrl: 'https://app.powerbi.com/rdlEmbed?reportId=e4af8f08-7ea7-4745-8651-b24e32c10f1e&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJhbmd1bGFyT25seVJlcG9ydEVtYmVkIjp0cnVlLCJjZXJ0aWZpZWRUZWxlbWV0cnlFbWJlZCI6dHJ1ZSwidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsInNraXBab25lUGF0Y2giOnRydWV9fQ%3d%3d',
//             accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyIsImtpZCI6ImpTMVhvMU9XRGpfNTJ2YndHTmd2UU8yVnpNYyJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvNzI0NDZiZTktYTQ1NC00YmZhLTg3ZDQtOGNmNGY2ZWFhZjM5LyIsImlhdCI6MTY0OTk0NDM5MCwibmJmIjoxNjQ5OTQ0MzkwLCJleHAiOjE2NDk5NDg5MzAsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84VEFBQUFLb0Y0TnZQSlBJcVZNR3oxTWR5MlkzaHg2dW00VDFrbVlzcGlIUXRwOHVlaTk5T1FNVEZjK3JLcjhVUWw4b2pwIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6IjE3MjUyM2M0LTgxNWMtNDQ1Mi05ZDU0LWY0N2ZhNmNkYTI3NSIsImFwcGlkYWNyIjoiMCIsImdpdmVuX25hbWUiOiJTdmMtUG93ZXJCSS1BUEkiLCJpcGFkZHIiOiIxODIuNzAuODQuMTMxIiwibmFtZSI6IlN2Yy1Qb3dlckJJLUFQSSIsIm9pZCI6ImY2ZGM5NWU2LWExMmEtNGU4My1iZGZkLWI0YTU2YTJlZWJiZCIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS02MDk3Nzc3NDUtODA3OTUxMTc4LTE4NDU5MTE1OTctNDM3OTAiLCJwdWlkIjoiMTAwMzIwMDBDOUUyMjk3NiIsInJoIjoiMC5BVnNBNld0RWNsU2sta3VIMUl6MDl1cXZPUWtBQUFBQUFBQUF3QUFBQUFBQUFBQmJBSVUuIiwic2NwIjoiQXBwLlJlYWQuQWxsIERhc2hib2FyZC5SZWFkLkFsbCBSZXBvcnQuUmVhZC5BbGwiLCJzdWIiOiJCakFaVFpPc0dXcy1QTmVWYVgwOUc3UGV6aWZKNC1RR2kyVU9ULWJvcWtVIiwidGlkIjoiNzI0NDZiZTktYTQ1NC00YmZhLTg3ZDQtOGNmNGY2ZWFhZjM5IiwidW5pcXVlX25hbWUiOiJTdmMtUG93ZXJCSS1BUElAc3RyZWFtcmVhbHR5LmNvbSIsInVwbiI6IlN2Yy1Qb3dlckJJLUFQSUBzdHJlYW1yZWFsdHkuY29tIiwidXRpIjoiOWlvTXJYWlJ5MDJINlZqR3Z5TWJBQSIsInZlciI6IjEuMCIsIndpZHMiOlsiYjc5ZmJmNGQtM2VmOS00Njg5LTgxNDMtNzZiMTk0ZTg1NTA5Il19.Bdcsu8uleCTWSfgOwJxDbcRCy4KjRJVFotLKBKHdDcG2sv7A-mRJMZ59vAWw3lOlpNjzkggmqAlgcrlbGopo5QLoU9tMcDm5hR7QKUN6KKQ4YU8oG0RqMKzvSqBcHhUlpVJcnD3TIgxODK6VOXRZfVigJzSnINL44mahyUPM_xIeeDQoYC6W9zJnBLBVuMT0U0aHayDl097MfzVxhAsYNl8pWnV9-57wyXGh4JYyyUskr6SN0CfSzGIjsm7W2y7RNsJXZvzVQu33K8w6WDYgerFxLrSfnmZ44fR05UjYMG-wTwDUVYaL5tRI-EbqXmEVB5VRSXJH1RYS0fI7_tQEnw',
//             tokenType: models.TokenType.Aad,
//             settings: {
//               layoutType: models.LayoutType.Custom,
//               customLayout: {
//                 displayOption: models.DisplayOption.FitToPage
//               },
//               commands: {
//                 parameterPanel: {
//                   enabled: false,
//                   expanded: false
//                 }
//               },
//               background: models.BackgroundType.Transparent,
//             }
//           }}

//           eventHandlers={
//             new Map([
//               ['loaded', function () { console.log('Report loaded'); }],
//               ['rendered', function () { console.log('Report rendered'); }],
//               ['error', function (event) { console.log(event.detail); }]
//             ])
//           }

//           cssClassName={"fullPage"}

//           getEmbeddedComponent={(embeddedReport) => {
//             window.report = embeddedReport;
//           }}
//         />


//       </div> */}
//     </>
//   );
// }

// export default PowerBIEmbedMine;
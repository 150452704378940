import {
	AppBar,
	Button,
	Dialog,
	IconButton,
	Paper,
	Toolbar,
	Typography,
	Box,
	TextField,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../../common/PageHeader";
import { apiBatchRequest, apiPost, apiPut } from "../../../utils/api_service";
import commonLabels from "../../../constants/messageConstants";
import { isInputValid, IsSectionVisible, UpdateFieldsWithConfiguration } from "../../../utils/helper";
import FormGenerator from "../../common/FormGenerator";
import { API_URL } from "../../../constants/api";
import { controlType } from "../../../constants/common";
import SearchBar from "../../common/AutoSearch/SearchBar";
import camelcaseKeys from "camelcase-keys";
import ImageSlider from "../../common/ImageSlider/ImageSlider";
import AutoSearch from "../../common/AutoSearch/AutoSearch";
import { ShowToast, toastType } from "../../../utils/toast-service";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import ViewHistoryData from "../../common/ViewHistoryData/ViewHistoryData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditBuilding from "../Building/EditBuilding";
import EditBuildingOwner from "../BuildingOwner/EditIndBuildingOwners";
import { SECTIONS } from "../../../constants/appConstants";

export default class EditIndTenants extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMarketId:this.props.globalMarketId,
			getTenantDetails: [],
			isEditEnabled: this.props.isInAddMode,
			companyMasterDetails: (props.companyMasterDetails != undefined || props.companyMasterDetails != null || props.companyMasterDetails.length > 0) ? props.companyMasterDetails : [],

			getSubMarketMaster: [],
			getMarketMaster: [],

			getBuildingMaster: (props.getBuildingMaster != undefined || props.getBuildingMaster != null || props.getBuildingMaster.length > 0) ? props.getBuildingMaster : [],
			getAllCompType: [],

			getTenantDetailsResponse: [],
			tenantSummaryData: [],
			isDataSaved: false,
			isSearchedClicked: false,
			showConfirmDialog: false,
			tenantId: -1,
			getHistoryDetailByField: [],
			openHistoryform: false,
			isHistoryByField: false,
			getHistory: [],
			detailView: false,
			selectedEditRowId: -1,
			ViewTitle: "Add",
			actionType: "Add",
			isRedirectedFromTenant: false,
			// companyMasterDetails:[],
			// ownerTypeData:[],
			// getIndustrySector:[],
		};
	}

	enableEdit = () => {
		this.setState({
			isEditEnabled: true,
		});
		this.getRequiredData(this.state.selectedMarketId);
		this.forceUpdate();
	};

	onBackClick = () => {
		this.props.onBackClick();
	};

	getTenantChanges = (formFields) => {
		this.setState(
			{
				getTenantDetails: formFields,
				reloadTenant: !this.state.reloadTenant,
				isDataSaved: true,
				isSearchedClicked: false,
			},
			() => {
				this.forceUpdate();
			}
		);
	};
	validateForm = () => {
		const formData = this.state.getTenantDetails;
		let isValid = true;
		let breakCondition = false;
		if(formData.length ==0 )
		{
			const msg = "No change detected. Please make necessary changes and try again.";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		}
		formData.forEach((x) => {
		  if (
			x.isRequired == true &&
			(x.attributeValue != null ? x.attributeValue.trim() == "" : x.attributeValue == "" ||
			  x.attributeValue == undefined ||
			  x.attributeValue == null) &&
			breakCondition != true
		  ) {
			const msg = "Please fill all the mandatory fields";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		  }
		});
		return isValid;
	  };
	  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
		//console.log(formElementFields[0].attributeValue);
		let IsValid = true;
		formElementFields.forEach((ele) => {
			isInputValid(ele.attributeValue, ele, this.state.getTenantDetails);
			if (ele.isInErrorState === true) {
				IsValid = false;
			}
		});
		//console.log(formElementFields);
		stateToBeUpdate = {
			...stateToBeUpdate,
			getTenantDetails: formElementFields,
			reloadTenant: !this.state.reloadTenant,
		};
		this.setState(stateToBeUpdate);

		return IsValid;
	};
	saveTenantDetails = () => {
		const tenantData = this.state.getTenantDetails;
		let isValid=this.validateForm();
		let isInputValid=this.validateRequiredFields(tenantData);
		if (isValid == false && isInputValid == false 
			|| isValid == true && isInputValid == false
			|| isValid == false && isInputValid == true
			) {
			ShowToast('There is validation error on page.', toastType.ERROR, 5000, 500);
			return false;		
		}
		else
		{	
		tenantData.forEach((x) => {
			if (
				_.camelCase(x.dbColumn) === _.camelCase("CompId") ||
				_.camelCase(x.dbColumn) === _.camelCase("CompanyId") ||
				_.camelCase(x.dbColumn) === _.camelCase("BuildingId") ||
				_.camelCase(x.dbColumn) === _.camelCase("CompTypeId")
			) {
				if (x.attributeValue != null)
					x.attributeValue = x.attributeValue.toString();
			}
		});

		if (this.props.actionType === "Edit") {
			const tenantId = tenantData.filter(
				(x) => _.camelCase(x.dbColumn) === _.camelCase("CompId")
			);

			apiPut(API_URL.IndustrialTenants.tenantUpdate, tenantData, {
				tenantId: tenantId[0].attributeValue,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Record updated successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.setState({
							isDataSaved: false,
						});
						this.props.reloadData();
						//this.getData();
					} else {
						ShowToast("Error", toastType.ERROR, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		} else {
			//companyData[0].attributeValue=-1;

			apiPost({
				url: API_URL.IndustrialTenants.tenantAdd,
				params: {},
				postBody: tenantData,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Record inserted successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.props.reloadData();
					} else {
						ShowToast(response.message, toastType.error, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		}
	}
	};

	getData = (tenantId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.IndustrialTenants.tenantDetail,
				key: "getTenantDetails",
				params: { compId: tenantId },
			},
		])
			.then((getTenantDetails) => {
				this._asyncRequest = null;
				if (getTenantDetails.getTenantDetails.success == true) {
					this.setState({
						getTenantDetails:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	getRequiredData = (selectedMarketId) =>{
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.IndustrialTenants.tenantsSummaryData,
				key: "tenantSummaryData",
				params: { marketId: selectedMarketId },
			},
			{
				url: API_URL.Master.getSubMarketMaster,
				key: "getSubMarketMaster",
				params: { businessLineId: 2 ,marketId: selectedMarketId},
			},
			{
				url: API_URL.Master.getMarketMaster,
				key: "getMarketMaster",
				params: { businessLineId: 2 ,marketId: selectedMarketId},
			},
			{
				url: API_URL.Master.getAllCompType,
				key: "getAllCompType",
			},
		])
		.then((getTenantDetails) => {
			this._asyncRequest = null;
			if (getTenantDetails.tenantSummaryData.success == true) {
				this.setState({
					tenantSummaryData:
							camelcaseKeys(getTenantDetails.tenantSummaryData.data) || [],
							getSubMarketMaster:
							camelcaseKeys(getTenantDetails.getSubMarketMaster.data) || [],
						getMarketMaster:
							camelcaseKeys(getTenantDetails.getMarketMaster.data) || [],
							getAllCompType:
							camelcaseKeys(getTenantDetails.getAllCompType.data) || [],
							reloadTenant:!this.state.reloadTenant
					});
					if(this.state.companyMasterDetails.length == 0 || this.state.companyMasterDetails == undefined)
						this.reloadPropertyOwnerData();
					if(this.state.getBuildingMaster.length == 0 || this.state.getBuildingMaster == undefined)
						this.reloadPropertyData();	
					this.forceUpdate();
					
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}
	componentWillReceiveProps(nextProps) {    
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {      
		  this.getRequiredData(nextProps.globalMarketId);
		  this.setState({ selectedMarketId: nextProps.globalMarketId });
		}
	  }

	componentDidMount() {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.IndustrialTenants.tenantDetail,
				key: "getTenantDetails",
				params: { compId: this.props.selectedTenantsId },
			},
			//{
			//	url: API_URL.IndustrialTenants.tenantsSummaryData,
			//	key: "tenantSummaryData",
			//},
			//{
			//	url: API_URL.Master.getALLTenantCompany,
			//	key: "companyMasterDetails",
			//	params: { businessLineId: 2 },
			//	// url: API_URL.Master.companyMasterDetails,
			//	// key: "companyMasterDetails",
			//},
			//{
			//	url: API_URL.Master.getSubMarketMaster,
			//	key: "getSubMarketMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getMarketMaster,
			//	key: "getMarketMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getBuildingMaster,
			//	key: "getBuildingMaster",
			//	params: { businessLineId: 2 },
			//},
			//{
			//	url: API_URL.Master.getAllCompType,
			//	key: "getAllCompType",
			//},
		])
			.then((getTenantDetails) => {
				this._asyncRequest = null;
				if (getTenantDetails.getTenantDetails.success == true) {
					this.setState({
						getTenantDetails:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
						getHistory:
							camelcaseKeys(getTenantDetails.getTenantDetails.configuration) ||
							[],
						getTenantDetailsResponse:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
						//tenantSummaryData:
						//	camelcaseKeys(getTenantDetails.tenantSummaryData.data) || [],
						//companyMasterDetails:
						//	camelcaseKeys(getTenantDetails.companyMasterDetails.data) || [],
						//getSubMarketMaster:
						//	camelcaseKeys(getTenantDetails.getSubMarketMaster.data) || [],
						//getMarketMaster:
						//	camelcaseKeys(getTenantDetails.getMarketMaster.data) || [],
						//getBuildingMaster:
						//	camelcaseKeys(getTenantDetails.getBuildingMaster.data) || [],
						//getAllCompType:
						//	camelcaseKeys(getTenantDetails.getAllCompType.data) || [],
					});
					//if(this.state.companyMasterDetails.length == 0 || this.state.companyMasterDetails == undefined)
					//	this.reloadPropertyOwnerData();
					//if(this.state.getBuildingMaster.length == 0 || this.state.getBuildingMaster == undefined)
					//	this.reloadPropertyData();	
					if(this.props.isInAddMode == true)
						this.getRequiredData(this.state.selectedMarketId);
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}

	onSearch = (event, value, e) => {
		const { isDataSaved } = this.state;
		let tenantId = this.state.tenantSummaryData.find(
			(m) => m.companyName == value
		).compId;
		this.setState({
			tenantId: tenantId,
			isSearchedClicked: true,
		});

		if (isDataSaved == false) {
			this.getData(tenantId);
		}
		//console.log(event);
	};
	onSearchTenant = () => {
		this.setState({
			isSearchedClicked: false,
			isDataSaved: false,
		});
		this.getData(this.state.tenantId);
	};
	onClickHandel(e) {
		if (e == true) {
			this.onSearchTenant();
			this.setState({
				isSearchedClicked: false,
			});
		} else {
			this.setState({
				isSearchedClicked: false,
			});
		}
	}
	getHistoryDataByField = (val) => {
		const params = {
			entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
			sectionDetailId: val.sectionDetailId,
			sectionId: val.sectionId,
			dbTable: val.dbTable,
			dbColumn: val.dbColumn,
			businessLineId: 2,
		};

		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getHistoryDetailByField,
				key: "getHistoryDetailByField",
				params: params,
				//{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
			},
		])
			.then((getHistoryDetailByField) => {
				this._asyncRequest = null;
				if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
					this.setState({
						getHistoryDetailByField:
							camelcaseKeys(
								getHistoryDetailByField.getHistoryDetailByField.data
							) || [],
						openHistoryform: true,
						isHistoryByField: true,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	handleChange = (e, val) => {
		const params = {
		  entityPrimaryKey: val.entityPrimaryKey,
		  modifiedDate: moment(val.createdDate).format("MM-DD-YYYY HH:mm:ss"),
		  sectionId: val.sectionId,
		  dbTable: val.dbTable,
		  businessLineId:2
		};
		//console.log("e", e, "val", val);
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getHistoryDataByUser,
				key: "getHistoryDataByUser",
				params: params,
				//{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
			},
		])
			.then((getHistoryDataByUser) => {
				this._asyncRequest = null;
				if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
					this.setState({
						getHistoryDataByUser:
							camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
							[],
						openHistoryform: true,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	goToAddBuilding = (source, option, updateoption = true) => {
		this.setState({
			openaddform: true,
			addformtitle: source.AddNewOptiontitle,

			addNewformComponent: EditBuilding,
			// this.getNewPropertydetailForm(
			// 	getMasterDetail,
			// 	this.state.getCity,
			// 	this.state.reloadZipChanges
			// ),
			addnewClientSource: source,
		});
	};

	onPropertyPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openaddform == true) {
			this.setState({ openaddform: false }, () => {
				this.forceUpdate();
			});
		}
	};

	reloadPropertyData = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getBuildingMaster,
				key: "getBuildingMaster",
				params: { businessLineId: 2, marketId: this.state.selectedMarketId },
			},
		])
			.then((getBuildingMaster) => {
				this._asyncRequest = null;
				if (getBuildingMaster.getBuildingMaster.success == true) {
					this.setState(
						{
							getBuildingMaster:
								camelcaseKeys(getBuildingMaster.getBuildingMaster.data) || [],
							openaddform: false,
							addNewformComponent: undefined,
							reloadTenant: !this.state.reloadTenant,
						},
						() => {
							this.forceUpdate();
						}
					);
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	buildingChanges = () => { };

	goToAddBuildingOwner = (source, option, updateoption = true) => {
		this.setState({
			openPropertyOwnerAddForm: true,
			addformtitle: source.AddNewOptiontitle,
			addNewformComponent: EditBuildingOwner,
			addnewClientSource: source,
		});
	};

	onPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openPropertyOwnerAddForm == true) {
			this.setState({ openPropertyOwnerAddForm: false }, () => {
				this.forceUpdate();
			});
		}
	};

	reloadPropertyOwnerData = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getALLTenantCompany,
				key: "companyMasterDetails",
				params: { businessLineId: 2 },
			},
		])
			.then((companyMasterDetails) => {
				this._asyncRequest = null;
				if (companyMasterDetails.companyMasterDetails.success == true) {
					this.setState(
						{
							companyMasterDetails:
								camelcaseKeys(companyMasterDetails.companyMasterDetails.data) ||
								[],
							openPropertyOwnerAddForm: false,
							addNewformComponent: undefined,
							reloadTenant: !this.state.reloadTenant,
						},
						() => {
							this.forceUpdate();
						}
					);
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	buildingOwnerChanges = () => { };

	render() {
		const {
			getTenantDetails,
			tenantSummaryData,
			isEditEnabled,
			companyMasterDetails,
			getAllCompType,
			getMarketMaster,
			getSubMarketMaster,
			getBuildingMaster,
			getHistory,
			getHistoryDataByUser,
			getHistoryDetailByField,
			isHistoryByField,
		} = this.state;

		const filterConfig = [
			{
				multiple: true,
				type: controlType.DropDown,
				minTag: 1,
				source: tenantSummaryData,
				key: "companyName",
				placeholder: "",
				title: "Tenants",
			},
		];
		const companySeparatorConfig = [
			{
				separatorBeforeKey: "CompanyName",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Tenant Details",
			},

			{
				separatorBeforeKey: "Notes",
				order: 1,
				row: 2,
				sectionColumn: 12,
				label: "Other Details",
			},
		];

		const dropDownSource = [
			{
				key: "companyName",
				option: "companyName",
				idField: "companyId",
				source: companyMasterDetails,
				AddNewOption: IsSectionVisible(SECTIONS.Industrial_Company,SECTIONS.Industrial_Company_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Company",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuildingOwner,
				onChange: this.buildingOwnerChanges,
			},
			{
				key: "buildingName",
				option: "buildingName",
				idField: "buildingId",
				source: getBuildingMaster,
				AddNewOption: IsSectionVisible(SECTIONS.Industrial_Property,SECTIONS.Industrial_Property_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Property",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuilding,
				onChange: this.buildingChanges,
			},
			{
				key: "compType",
				option: "compType",
				idField: "compTypeID",
				source: getAllCompType,
			},
		];

		const DetailsFormConfig = [
			{
				key: "compId",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "companyName",
				type: controlType.DropDown,
				multiple: false,
				//formElementFields:true,
			},
			{
				key: "buildingName",
				type: controlType.DropDown,
				multiple: false,
			},
			{
				key: "rsf",
				type: controlType.TextBox,
				multiple: false,
			},

			{
				key: "lcd",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "lxDte",
				type: controlType.DatePicker,
				multiple: false,
			},

			{
				key: "rateAtLcd",
				type: controlType.TextBox,
				multiple: false,
			},

			{
				key: "notes",
				type: controlType.TextArea,
				multiple: false,
			},
			{
				key: "compType",
				type: controlType.DropDown,
				multiple: false,
			},
		];
		var tenantNam = "";
		if (getTenantDetails.length > 0) {
			tenantNam = getTenantDetails.find(
				(x) => x.dbColumn == "CompanyName"
			).attributeValue;
		}
		const tenantName =
			tenantNam != undefined ? tenantNam : this.props.ViewTitle;
		return (
			<>
				{this.state.openHistoryform && (
					<Dialog open={this.state.openHistoryform} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar>
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
								>
									Update History
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.setState({
											openHistoryform: false,
											isHistoryByField: false,
										});
									}}
									aria-label="close"
									style={{padding:"0px", marginBottom:"20px"}}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<ViewHistoryData
								sourceData={getHistoryDataByUser}
								getHistoryDetailByField={getHistoryDetailByField}
								dropDownSource={dropDownSource}
								isHistoryByField={isHistoryByField}
							></ViewHistoryData>
						</div>
					</Dialog>
				)}
				{this.state.isDataSaved && this.state.isSearchedClicked && (
					<ConfirmDialog
						IsOpen={this.state.isSearchedClicked}
						Message={
							"There are unsaved changes.Do you still want to search another Tenant"
						}
						OnHandelClick={(isValid) => {
							if (isValid) {
								this.onClickHandel(isValid);
							} else {
								this.onClickHandel(isValid);
							}
						}}
						AgreeText="Yes"
						DisAgreeText="No"
					></ConfirmDialog>
				)}

				{this.state.openaddform && (
					<Dialog open={this.state.openaddform} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar>
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
								>
									{this.state.addformtitle}
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.reloadPropertyData();
									}}
									aria-label="close"
									style={{padding:"0px", marginBottom:"20px"}}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<EditBuilding
							    globalMarketId={this.props.globalMarketId}
								selectedBuildingId={this.state.selectedEditRowId}
								onBackClick={this.onPropertyPopupBackClick}
								ViewTitle={this.state.ViewTitle}
								actionType={this.state.actionType}
								isInAddMode={true}
								reloadData={this.reloadData}
								isRedirectedFromTenant={true}
								reloadPropertyData={this.reloadPropertyData}
							/>
						</div>
					</Dialog>
				)}

				{this.state.openPropertyOwnerAddForm && (
					<Dialog open={this.state.openPropertyOwnerAddForm} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar>
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
								>
									{this.state.addformtitle}
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.reloadPropertyOwnerData();
									}}
									aria-label="close"
									style={{padding:"0px", marginBottom:"20px"}}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<EditBuildingOwner
							    globalMarketId={this.props.globalMarketId}
								selectedBuildingId={this.state.selectedEditRowId}
								onBackClick={this.onPopupBackClick}
								ViewTitle={this.state.ViewTitle}
								actionType={this.state.actionType}
								isInAddMode={true}
								reloadData={this.reloadData}
								isRedirectedFromTenant={true}
								reloadPropertyOwnerData={this.reloadPropertyOwnerData}
							/>
						</div>
					</Dialog>
				)}

				{isEditEnabled || this.props.isInAddMode ? (
					<div class="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Tenants"}
								onBackClick={this.onBackClick}
								title={
									this.props.isInAddMode
										? "/ " + this.props.ViewTitle
										: "/ " + tenantName
								}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>

						<div className="col-md-4 col-lg-4">
							{tenantSummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another tenant"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={this.state.tenantSummaryData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
							{/* <SearchBar></SearchBar> */}
						</div>
						<div className="col-md-4 col-lg-4 end-sm">
							<Button
								size="small"
								style={{ marginTop: "5px" }}
								className="btn-clear"
								onClick={() => {
									this.setState({
										getTenantDetails: this.state.getTenantDetailsResponse,
										reloadTenant: !this.state.reloadTenant,
									});
								}}
							>
								<span className="tool-icon-text">Clear All</span>
							</Button>
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.props.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							{ IsSectionVisible(SECTIONS.Industrial_Tenant,SECTIONS.Industrial_Tenant_Details, SECTIONS.ADD_EDIT) &&
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.saveTenantDetails();
								}}
							>
								<span className="tool-icon-text">Save</span>
							</Button>
	}
						</div>
					</div>
				) : (
					<div className="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Tenants"}
								onBackClick={this.onBackClick}
								title={"/ " + tenantName}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>
						<div className="col-md-5 col-lg-5">
							{tenantSummaryData.length > 0 && (
								<AutoSearch
									placeholder="Search another tenant"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={this.state.tenantSummaryData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
							{/* <SearchBar></SearchBar> */}
						</div>

						<div className="col-md-3 col-lg-3 end-sm">
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.props.onBackClick();
								}}
							>
								<span className="tool-icon-text">Cancel</span>
							</Button>
							{ IsSectionVisible(SECTIONS.Industrial_Tenant,SECTIONS.Industrial_Tenant_Details, SECTIONS.ADD_EDIT) &&
							<Button
								size="small"
								className="notop-action-button"
								onClick={() => {
									this.enableEdit();
								}}
							>
								<span className="tool-icon-text">Edit</span>
							</Button>
	}
						</div>
					</div>
				)}
				{/* <div className="row">
          <div className="col-sm-7 col-lg-7"></div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
            style={{ marginTop: "1px" }}
          >
            <span>Last Modified:</span>
          </div>
          <div
            className="col-sm-2 col-lg-2 end-sm"
           // style={{ marginTop: "9px", marginLeft: "8px" }}
          >
            <SimpleSelect></SimpleSelect>
          </div>
        </div> */}
		 {getHistory.length > 0 && (
          <div className="row header-container">
            <div className="col-md-8 col-lg-8 "></div>
            <div
              className="col-md-1 col-lg-1"
              style={{ padding: "8px" }}
            >
              <span>Last Modified:</span>
            </div>
            <div className="col-md-3 col-lg-3 end-sm">
              <Autocomplete
                // style={{ width: "374px" }}
                size="small"
                underlineStyle={{ display: "none" }}
                //value={}
                // defaultValue={"Select Record" }
                onChange={(e, val) => this.handleChange(e, val)}
                id="tags-standard"
                options={getHistory}
                getOptionLabel={(option) =>
                  option.createdBy +
                  "," +
                  moment(option.createdDate).format("MM-DD-YYYY HH:mm:ss")
                }
                renderInput={(params) => (
                  // <Box
                  //   sx={{ display: "flex", alignItems: "center" }}
                  //   className="filter-label"
                  // >
                  //   <label htmlFor="filled-hidden-label-small">
                  //
                  //   </label>
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    hiddenLabel
                  />
                  // </Box>
                )}
              />
              {/* <SimpleSelect></SimpleSelect> */}
            </div>
          </div>
        )}
				<div className="row table-list-container">
					{getTenantDetails && (
						<FormGenerator
							gridColumn={4}
							getHistoryDataByField={this.getHistoryDataByField}
							ReloadForm={this.state.reloadTenant}
							formFields={UpdateFieldsWithConfiguration(
								getTenantDetails,
								DetailsFormConfig
							)}
							dropDownSource={dropDownSource}
							validationMessages={[]}
							partialForm={true}
							saveChanges={this.getTenantChanges}
							Editable={this.state.isEditEnabled}
							viewPDF={[]}
							formType={"Section"}
							separatorConfig={companySeparatorConfig}
							sectionRowConfig={[
								{ row: 1, column: 12 },
								{ row: 2, column: 4 },
								{ row: 3, column: 8 },
								//  {row:3,column:4,labelAlign:'alignText-Right'},
								//  {row:2,column:4,labelAlign:'alignText-Right'},
							]}
							otherChildsection={
								[
									// {
									//   label: "",
									//   element: getTenantDetails.length > 0 && (
									//     <ImageSlider></ImageSlider>
									//   ),
									//   row: 3,
									// },
								]
							}
						></FormGenerator>
					)}
					{/* {getTenantDetails.length>0 &&
    (
<div style={{    marginLeft: '36%',marginTop: '-31%'}}>

<ImageSlider></ImageSlider>
</div>

    )   
    } */}
				</div>
			</>
		);
	}
}

import React from "react";
import Select from "../select";


class AsOfDate extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMonth: 1,
			months: []
		};
		this.months = [];
	}

	componentDidMount() {
		Date.prototype.monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December"
		];

		Date.prototype.getMonthName = function() {
			return this.monthNames[this.getMonth()];
		};
		Date.prototype.getShortMonthName = function() {
			return this.getMonthName().substr(0, 3);
		};
		Date.prototype.getShortMonthYearName = function() {
			return this.getMonthName() + " " + this.getFullYear();
		};
		Date.prototype.getFullDate = function() {
			return this.getMonth() + 1 + "/1/" + this.getFullYear();
		};
		const lastMonthCount = 5;
		const ds = new Date();
		this.months.length = 0;
		Array.apply(null, { length: lastMonthCount }).map((e, i) => {
			const pastDate = new Date(ds.getFullYear(), ds.getMonth() - i, 1);
			this.months.push({
				value: pastDate.getShortMonthYearName(),
				id: pastDate.getFullDate()
			});
		});
		setTimeout(() => {
			this.setState({ months: this.months });
		}, 100);
	}

	onChangeAsOfDate = (val) => {
		this.setState({ selectedMonth: val });
		this.props.onChange(val);
	}

	render() {
		const { disabled, selectedDate } = this.props;

		return (
			<Select
				onChange={this.onChangeAsOfDate}
				optionData={this.state.months}
				disabled={disabled}
				defaultValue={selectedDate || this.state.selectedMonth}
			/>
		);
	}
}

export default AsOfDate;

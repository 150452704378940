import React, { Component } from 'react';
import { MdCall, MdMailOutline } from 'react-icons/md';
import data from '../../../public/releases/data-config.json';
import './style.less';
import DenseTable from '../../components/Dashboard/RenderTable';
// import DownloadFile from '../../components/common/download-file';
import DownloadFile from '../../components/common/download-file';
import { getCurrentEnvironment } from '../../constants/api';

export default class industrialAboutUs extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isCollapsedTable: false,
      aboutUsData: null,
      aboutUsProduction: null,

    };
    console.log(this);
  }

  componentDidMount () {
    
  }

  getPreviousVersionColumnList = () => {
    // debugger;
    const columns = [];
    columns.push({
      name: 'Version',
      key: 'version'
    });
    columns.push({
      name: 'Release Date',
      key: 'releaseDate'
    });
    columns.push({
      name: 'Description',
      key: 'description'
    });
    columns.push({
      name: 'Notes',
      key: 'relaseNotesUrl',
      renderComponent: this.renderDownload
    });
    return columns;
  };

  renderDownload = cellInfo => {
    return (
      <DownloadFile
        url={cellInfo.relaseNotesUrl}
        filename={cellInfo.downloadFilename}
      />
    );
  };

  GetPropsForData = responseData => {
    return {
      data: responseData,
      columns: this.getPreviousVersionColumnList()
    };
  };

  render () {
    const x = getCurrentEnvironment();
    let aboutUsData = null;
    if (x.environment === 'prod') {
      aboutUsData = data.industrialproductionAboutUs
    }
    else {
      aboutUsData = data.industrialstageAboutUs
    }
   

    return (
      <div className='about-us-container'>
        <div className="row margin-top-bottom ">
          <div className="col-xs-12">
            <span className="header">About Us</span>
            <hr></hr>
          </div>
        </div>

        <>
        <div className="row margin-top-bottom ">
              <div className="col-xs-12">
                <span className="sub-header">
                  Ground Control
                </span>
              </div>
            </div>
            <div className="row margin-top-bottom ">

              <div className="col-xs-12 margin-top-bottom">
                <div className=" col-xs-12 col-sm-10 col-md-8 col-lg-8 no-padding description">
                  {aboutUsData.description}
                </div>
              </div>
            </div>

            <div className="row margin-top-bottom  start-lg">
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 content no-padding margin-top-bottom">
                <div className="row no-padding  data-margin">
                  <div className="col-xs  ">
                    Last Updated on: &nbsp;
                    <span className="badge">{aboutUsData.releaseDate}</span>
                  </div>
                </div>
              </div>
            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 content no-padding margin-top-bottom">
              <div className="row no-padding  data-margin">
                  <div className="col-xs ">
                    Current version: &nbsp;
                    <span className="badge">{aboutUsData.version}</span>
                  </div>
                </div>
              </div>
           
              <div className="col-xs-12 col-sm-12 col-md-4 col-lg-6 content no-padding">
                <div className="row no-padding  data-margin">
                  <div className="col-xs ">
                    {' '}
                    <span className="badge download">
                      <DownloadFile
                        url={aboutUsData.userManualUrl}
                        filename={aboutUsData.userManualDownloadFileName}
                        labelText={'Download User Manual'}
                      />
                      {/* Download File */}
                    </span>
                  </div>
                </div>
              </div>
              </div>
              <div className="row margin-top-bottom section">
              <div className="col-xs-12">
                <span className="sub-header">Contact Us</span>
                <hr />
              </div>
              <div className="col-xs-12">
                <span className="description">
                  {aboutUsData.contactUs.description}
                </span>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 content no-padding">
                <div className="row col-xs-12 no-padding ">
                  <div className="col-xs-2">
                    <MdMailOutline fontSize={'medium'} />
                  </div>
                  <div className="col-xs-10">
                    <a href={'mailto:' + data.productionAboutUs.contactUs.email}>
                      {aboutUsData.contactUs.email}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-2 col-lg-2 content no-padding">
                <div className="row col-xs-12 no-padding">
                  <div className="col-xs-2 ">
                    <MdCall fontSize={'medium'} />
                  </div>
                  <div className="col-xs-10 ">
                    <a href={'tel:' + aboutUsData.contactUs.phone}>
                      {aboutUsData.contactUs.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row margin-top-bottom section"></div>
            <div className="row margin-top-bottom section">
              
              <div className="col-xs-12">
                <span className="sub-header">Previous Release</span>
                <hr />
              </div>
              </div>
              <div className="row margin-top-bottom section">
              <div className="col-xs-12">
                <DenseTable
                  {...this.GetPropsForData(
                    aboutUsData.previousVersion || []
                  )}
                />
              </div>
            </div>

        </>
      </div>
    );
  }
}

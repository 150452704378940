import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../../common/PageHeader";
import {
  apiBatchRequest,
  apiDelete,
  apiPost,
  apiPut,
  apiRequest,
  apiRequestNoLoadder,
} from "../../../utils/api_service";
import {
  isInputValid,
  IsSectionVisible,
  UpdateFieldsWithConfiguration,
} from "../../../utils/helper";
import FormGenerator from "../../common/FormGenerator";
import { API_URL } from "../../../constants/api";
import { controlType } from "../../../constants/common";
import camelcaseKeys from "camelcase-keys";
import AutoSearch from "../../common/AutoSearch/AutoSearch";
import SimpleSelect from "../../common/AutoSearch/SimpleSelect";
import { ShowToast, toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import ViewHistoryData from "../../common/ViewHistoryData/ViewHistoryData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditBuilding from "../Building/EditBuilding";
import EditBuildingOwner from "../BuildingOwner/EditIndBuildingOwners";
import { SECTIONS } from "../../../constants/appConstants";

export default class EditIndLeaseComps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      getAllCompType: [],
      //  getAllTenancy: [],
      getAllRentType: [],
      getAllRepresentationType: [],
      getAllGeneration: [],
      getLeaseCompDetail: [],
      leaseCompMasterDetail: [],
      ownerTypeData: [],
      getIndustrySector: [],
      getProductTypeMaster: [],
      lastModifiedValue: "",
      isEditEnabled: this.props.isInAddMode,
      getMasterZip: [],
      getSubMarketMaster: [],
      getMarketMaster: [],
      openDialog: true,
      getALLNNNFS: [],
      getALLEscalationMarker: [],
      getBuildingMaster:
        props.getBuildingMaster != undefined ? props.getBuildingMaster : [],
      companyMasterDetails:
        props.companyMasterDetails != undefined
          ? props.companyMasterDetails
          : [],
      getAllRelocationReason: [],
      getBrokerMaster: [],
      getALLBrokerFirm: [],
      getLeaseCompDetailResponse: [],
      leaseCompsSummaryData: [],
      isDataSaved: false,
      isSearchedClicked: false,
      showConfirmDialog: false,
      compId: props.selectedCompId,
      getHistoryDetailByField: [],
      openHistoryform: false,
      isHistoryByField: false,
      getHistory: [],
      detailView: false,
      selectedEditRowId: -1,
      ViewTitle: "Add",
      actionType: "Add",
      isRedirectedFromTenant: false,
      selectedBuildingOwner: undefined,
      reloadCompanyPopup: false,
      selectedBuilding: undefined,
      isAddedFromPopup: false,
      buildingNameSelected: null,
      getAllFreeRentType: [],
      getAllSpaceModification: [],
      getBrokerMaster: [],
      getAllTenantIndustry: [],
      getALLDockHeight: [],
      getAllDockConfiguration:[],
    };
  }

  handleChange = (event) => {
    setState({ lastModifiedValue: event.target.value });
  };

  getData = (compId) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialLeaseComps.getLeaseCompDetail,
        key: "getLeaseCompDetail",
        params: { compId: compId },
      },
    ])
      .then((getLeaseCompDetail) => {
        this._asyncRequest = null;
        if (getLeaseCompDetail.getLeaseCompDetail.success == true) {
          let companyName = getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "CompanyName"
          ).attributeValue;
          let buildingName = getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "BuildingName"
          ).attributeValue;
          const params = {
            businessLineId: 2,
            searchText: companyName,
            compId: this.state.compId,
          };
          const buildingParams = {
            businessLineId: 2,
            searchText: buildingName,
            //compId:this.state.compId
          };
          apiRequest({
            url: API_URL.Master.getALLTenantCompany,
            params,
          })
            .then(({ data: companyMasterDetails }) => {
              this.setState(
                {
                  companyMasterDetails:
                    camelcaseKeys(companyMasterDetails) || [],
                  reloadLeaseComp: !this.state.reloadLeaseComp,
                },
                () => {
                  this.forceUpdate();
                }
              );
            })
            .catch((reason) => {
              this._asyncRequest = null;
              ShowToast(
                commonLabels.somethingWrong,
                toastType.ERROR,
                5000,
                500,
                "Server error"
              );
            });
          apiRequest(
            {
              url: API_URL.Master.getBuildingNameAddressCostarMaster,
              params: {businessLineId: 2,
                searchText: buildingName,
    marketId: this.state.selectedMarketId}
            }
          ).then(({ data: getBuildingMaster }) => {
            this.setState({
              getBuildingMaster:
                camelcaseKeys(getBuildingMaster) || [],
              reloadLeaseComp: !this.state.reloadLeaseComp,
            }, () => { this.forceUpdate() });
          })
            .catch((reason) => {
              this._asyncRequest = null;
              ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
            });
          this.setState({
            getLeaseCompDetail:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompDetail.data) || [],
            getHistory:
              camelcaseKeys(
                getLeaseCompDetail.getLeaseCompDetail.configuration
              ) || [],
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }



  getLeaseCompData() {
    this._asyncRequest = apiBatchRequest([


      {
        url: API_URL.Master.getAllLeaseType,
        key: "getAllLeaseType",
        params: { businessLineId: 2 },
        // fromCache: true,
        // duration: 60
      },

      {
        url: API_URL.Master.getAllCompType,
        key: "getAllCompType",
        // fromCache: true,
        // duration: 60
      },

    ])
      .then((getAllLeaseType) => {
        this._asyncRequest = null;
        if (getAllLeaseType.getAllLeaseType.success == true) {
          this.setState({
            getAllCompType:
              camelcaseKeys(getAllLeaseType.getAllCompType.data) || [],
            getAllLeaseType:
              camelcaseKeys(getAllLeaseType.getAllLeaseType.data) || [],
            
            reloadLeaseComp: !this.state.reloadLeaseComp,
          });

          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }
  getRequiredData() {
    this._asyncRequest = apiBatchRequest([


      {
        url: API_URL.Master.getAllLeaseType,
        key: "getAllLeaseType",
        params: { businessLineId: 2 },
        // fromCache: true,
        // duration: 60
      },

      {
        url: API_URL.Master.getAllCompType,
        key: "getAllCompType",
        // fromCache: true,
        // duration: 60
      },

    ])
      .then((leaseCompsSummaryData) => {
        this._asyncRequest = null;
        if (leaseCompsSummaryData.getAllCompType.success == true) {
          this.setState({

            getAllCompType:
              camelcaseKeys(leaseCompsSummaryData.getAllCompType.data) || [],
            getAllLeaseType:
              camelcaseKeys(leaseCompsSummaryData.getAllLeaseType.data) || [],


            reloadLeaseComp: !this.state.reloadLeaseComp,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }

  componentDidMount() {
    let companyName = null;
    let buildingName = null;
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialLeaseComps.getLeaseCompDetail,
        key: "getLeaseCompDetail",
        params: { compId: this.props.selectedCompId },
      },
      {
        url: API_URL.IndustrialLeaseComps.leaseCompsSummaryData,
        key: "leaseCompsSummaryData",
        params: { marketId: this.state.selectedMarketId },
      },

      {
        url: API_URL.Master.getAllCompType,
        key: "getAllCompType",
        params: { businessLineId: 2 },
        // fromCache: true,
        // duration: 60
      },


      {
        url: API_URL.Master.getLeaseCompsMasters,
        key: "getLeaseCompsMasters",
        params: { businessLineId: 2 },
        //	fromCache: true,
        //duration: 60
      },
      {
        url: API_URL.Master.getCompSourceAndRepresentationTypeMasters,
        key: "getCompSourceAndRepresentationTypeMasters",
        params: { businessLineId: 2 },
        //	fromCache: true,
        //duration: 60
      },
      {
        url: API_URL.Master.getTenantIndustryMaster,
        key: "getAllTenantIndustry",
        params: { businessLineId: 2 },
        // fromCache: true,
        // duration: 60,
      },

    ])
      .then((getLeaseCompDetail) => {
        this._asyncRequest = null;
        if (getLeaseCompDetail.getLeaseCompDetail.success == true) {
          companyName = getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "CompanyName"
          ).attributeValue;
          buildingName = getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "BuildingName"
          ).attributeValue;
          const params = {
            businessLineId: 2,
            searchText: companyName,
            compId: this.state.compId,
          };
          const buildingParams = {
            businessLineId: 2,
            searchText: buildingName,
            //compId:this.state.compId
          };
          apiRequest({
            url: API_URL.Master.getALLTenantCompany,
            params,
          })
            .then(({ data: companyMasterDetails }) => {
              this.setState(
                {
                  companyMasterDetails:
                    camelcaseKeys(companyMasterDetails) || [],
                  reloadLeaseComp: !this.state.reloadLeaseComp,
                },
                () => {
                  this.forceUpdate();
                }
              );
            })
            .catch((reason) => {
              this._asyncRequest = null;
              ShowToast(
                commonLabels.somethingWrong,
                toastType.ERROR,
                5000,
                500,
                "Server error"
              );
            });
          apiRequest(
            {
              url: API_URL.Master.getBuildingNameAddressCostarMaster,
              params: {
                businessLineId: 2,
                searchText: buildingName,
                marketId: this.state.selectedMarketId
              }
            }
          ).then(({ data: getBuildingMaster }) => {
            this.setState({
              getBuildingMaster:
                camelcaseKeys(getBuildingMaster) || [],
              reloadLeaseComp: !this.state.reloadLeaseComp,
            }, () => { this.forceUpdate() });
          })
            .catch((reason) => {
              this._asyncRequest = null;
              ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
            });


          let getCompType = getLeaseCompDetail.getAllCompType.data.filter((m) => {
            if (m.compType === "Lease") return m;
          });


          getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "CompTypeId"
          ).attributeValue = getCompType[0].compTypeID
          getLeaseCompDetail.getLeaseCompDetail.data.find(
            (x) => x.dbColumn == "CompType"
          ).attributeValue = getCompType[0].compType


          this.setState({
            getLeaseCompDetail:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompDetail.data) || [],
            getHistory:
              camelcaseKeys(
                getLeaseCompDetail.getLeaseCompDetail.configuration
              ) || [],
            getLeaseCompDetailResponse:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompDetail.data) || [],
            leaseCompsSummaryData:
              camelcaseKeys(getLeaseCompDetail.leaseCompsSummaryData.data) ||
              [],
            getAllFreeRentType:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.freeRentType.data) || [],
            getAllRentType:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.rentType.data) || [],
            getAllSpaceModification:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.spaceModification.data) || [],
            getAllRepresentationType:
              camelcaseKeys(getLeaseCompDetail.getCompSourceAndRepresentationTypeMasters.representationType.data) ||
              [],
            getAllGeneration:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.generation.data) || [],
            getAllCompSource:
              camelcaseKeys(getLeaseCompDetail.getCompSourceAndRepresentationTypeMasters.compSource.data) || [],
            //getAllSourceSystem:
            //  camelcaseKeys(getLeaseCompDetail.getAllSourceSystem.data) || [],
            getAllDirectSubLease:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.directSubLease.data) || [],

            // getAllLeaseType:
            // 	camelcaseKeys(getLeaseCompDetail.getAllLeaseType.data) || [],
            getALLEscalationMarker:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.escalationMarkers.data) ||
              [],
            getAllTenantIndustry:
              camelcaseKeys(getLeaseCompDetail.getAllTenantIndustry.data) ||
              [],
            getALLNNNFS:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.nnnfs.data) || [],
            getALLDockHeight:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.dockHeight.data) || [],
            getAllDockConfiguration:
              camelcaseKeys(getLeaseCompDetail.getLeaseCompsMasters.dockConfiguration.data) || []
          });

          if (this.state.getBuildingMaster.length == 0 && this.props.isInAddMode) {
            this.getLeaseCompData();

          } else if (this.props.isInAddMode == true) {
            this.getRequiredData(this.state.selectedMarketId);
          }
          // this.getRequiredData(this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }
  getSpsData = () => {
    let env = getCurrentEnvironment().sharePointLibrary;
    if (getSPSTokenData()) {
      this.setState({ spsData: {} });
      getSpsAPiRequest(getSharePointUrl("streamrealty", "getFolder")).then(
        (res) => {
          const folderName = env;
          if (
            res.value !== null &&
            res.value.length > 0 &&
            res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            ) !== undefined
          ) {
            const parentFolder = res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            );
            const driveId = parentFolder.parentReference.driveId;
            const folderKey = parentFolder.id;
            this.setState({ spListkey: folderKey });
            getSpsAPiRequest(
              getSharePointUrl("streamrealty", "getSps", folderKey)
            ).then((spsDataRes) => {
              const subFolder = "LeaseComps";
              if (
                spsDataRes.value !== null &&
                spsDataRes.value.length > 0 &&
                spsDataRes.value.find(
                  (x) => x.name === subFolder || x.displayName === subFolder
                ) !== undefined
              ) {
                const folder = spsDataRes.value.find(
                  (x) => x.name === subFolder || x.displayName === subFolder
                );
                const driveId = folder.parentReference.driveId;
                const key = folder.id;
                let fileName = "";
                if (this.state.getLeaseCompDetail.length > 0) {
                  fileName = this.state.getLeaseCompDetail.find(
                    (x) => x.dbColumn == "ImportFileName"
                  ).attributeValue;
                }
                if (
                  fileName !== "" &&
                  fileName != undefined &&
                  fileName != null
                )
                  this.getList(fileName);
              }
            });
          }
        }
      );
    } else {
      apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
        if (data) {
          setSPSToken(data);
          this.getSpsData();
        }
      });
    }
  };
  enableEdit = () => {
    this.setState({
      isEditEnabled: true,
    });
    this.getRequiredData(this.state.selectedMarketId);
    this.forceUpdate();
  };

  getLeaseCompChanges = (formFields) => {
    let buyerValue = formFields.find(
      (x) => x.dbColumn == "CompanyName"
    ).attributeValue;
    let buildingNameValue = formFields.find(
      (x) => x.dbColumn == "BuildingName"
    ).attributeValue;
    let buyerData = buyerValue;
    if (buyerValue == "" || buyerValue == "" || buyerValue == undefined) {
      buyerData = undefined;
    }
    let buildingData = buildingNameValue;
    if (
      buildingNameValue == "" ||
      buildingNameValue == "" ||
      buildingNameValue == undefined
    ) {
      buildingData = undefined;
    }
    this.setState(
      {
        leaseCompMasterDetail: formFields,
        isDataSaved: true,
        isSearchedClicked: false,
        selectedBuildingOwner: buyerData,
        selectedBuilding: buildingData,
        reloadLeaseComp: !this.state.reloadLeaseComp,
        companyNameSelected: null,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  onSearch = (event, value, e) => {
    const { isDataSaved } = this.state;
    let compId = this.state.leaseCompsSummaryData.find(
      (m) => m.companyName == value
    ).compId;
    this.setState({
      compId: compId,
      isSearchedClicked: true,
    });

    if (isDataSaved == false) {
      this.setState({ getHistory: [] });
      this.getData(compId);
    }
    //console.log(event);
  };
  onSearchLeaseComp = () => {
    this.setState({
      isSearchedClicked: false,
      isDataSaved: false,
      getHistory: [],
    });
    this.getData(this.state.compId);
  };
  onClickHandel(e) {
    if (e == true) {
      this.onSearchLeaseComp();
      this.setState({
        isSearchedClicked: false,
      });
    } else {
      this.setState({
        isSearchedClicked: false,
      });
    }
  }
  onDeleteClickHandel(e) {
    if (e == true) {
      apiDelete({
        url: `${API_URL.Master.softDeleteComps}`,
        params: { compId: this.state.compId, businessLineId: 2 },
      })
        .then(({ data: response }) => {
          if (response.success === true) {
            ShowToast(
              "Lease Comp deleted successfully",
              toastType.SUCCESS,
              5000,
              500
            );
            this.setState({
              isDataSaved: false,
            });

            this.props.reloadData();
          } else {
            ShowToast(
              "Server Error! Lease Comp not deleted",
              toastType.ERROR,
              5000,
              500
            );
          }
        })
        .catch(() => {
          ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
        });
      this.setState({
        isDeleteClicked: false,
      });
    } else {
      this.setState({
        isDeleteClicked: false,
      });
    }
  }
  validateForm = () => {
    const formData = this.state.getLeaseCompDetail;
    let isValid = true;
    let breakCondition = false;
    if (formData.length == 0) {
      //  (formData.length == 0 && this.state.selectedBuildingOwner == undefined ) ||
      //  (formData.length == 0 && this.state.selectedBuilding == undefined)) {
      const msg =
        "No change detected. Please make necessary changes and try again.";
      isValid = false;
      breakCondition = true;
      ShowToast(msg, toastType.ERROR, 5000, 500);
    }
    formData.forEach((x) => {
      if (
        x.isRequired == true &&
        (x.attributeValue != null
          ? x.attributeValue.trim() == ""
          : x.attributeValue == "" ||
          x.attributeValue == undefined ||
          x.attributeValue == null) &&
        breakCondition != true
      ) {
        const msg = "Please fill all the mandatory fields";
        isValid = false;
        breakCondition = true;
        ShowToast(msg, toastType.ERROR, 5000, 500);
      }
    });
    return isValid;
  };
  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
    //console.log(formElementFields[0].attributeValue);
    let IsValid = true;
    formElementFields.forEach((ele) => {
      isInputValid(ele.attributeValue, ele, this.state.leaseCompMasterDetail);
      if (ele.isInErrorState === true) {
        IsValid = false;
      }
    });
    //console.log(formElementFields);
    stateToBeUpdate = {
      ...stateToBeUpdate,
      leaseCompMasterDetail: formElementFields,
      reloadLeaseComp: !this.state.reloadLeaseComp,
    };
    this.setState(stateToBeUpdate);

    return IsValid;
  };
  saveLeaseDetails = () => {
    let companyId = undefined;
    let company = [];
    let buildingId = undefined;
    let building = [];

    const CompanyId =
      this.state.getLeaseCompDetail.length > 0
        ? this.state.getLeaseCompDetail.filter(
          (x) => _.camelCase(x.dbColumn) === _.camelCase("CompanyId")
        ).attributeValue
        : null;
    if (this.state.getBuildingMaster.length > 0) {
      building = this.state.getBuildingMaster.filter(
        (x) => x.buildingName == this.state.selectedBuilding
      );
    }
    if (building.length > 0 && this.state.selectedBuilding !== undefined) {
      buildingId = building[0].buildingId;
    }
    this.state.getLeaseCompDetail.filter((x) => {
      if (x.dbColumn == "BuildingId" && buildingId != undefined)
        x.attributeValue = buildingId;
      if (x.dbColumn == "BuildingName" && building.length > 0)
        x.attributeValue = building[0].buildingName;
    });
    const leaseCompData = this.state.getLeaseCompDetail;
    let isValid = this.validateForm();
    let isInputValid = this.validateRequiredFields(leaseCompData);
    if (
      (isValid == false && isInputValid == false) ||
      (isValid == true && isInputValid == false) ||
      (isValid == false && isInputValid == true) ||
      ((CompanyId == null || CompanyId == undefined) &&
        this.state.companyNameSelected != null)
    ) {
      isInputValid == false
        ? ShowToast(
          "There is validation error on page.",
          toastType.ERROR,
          5000,
          500
        )
        : ShowToast(
          "Please select Tenant Name from the list given.",
          toastType.ERROR,
          5000,
          500
        );

      return false;
    } else {
      leaseCompData.forEach((x) => {
        x.attributeValue =
          x.attributeValue != null
            ? x.attributeValue.toString().trim()
            : x.attributeValue;
        // if((x.dataFormat == 'Number' || x.dataFormat =='MoneyDecimal' ||
        // x.dataFormat =='Decimal') && x.attributeValue != null )
        // {
        // x.attributeValue =x.attributeValue.toString().replace(/ /g, '');
        // }
        //  x != undefined && x != null && x.attributeValue != undefined
        //  && x.attributeValue != null? x.attributeValue.trim():x.attributeValue;
        if (
          _.camelCase(x.dbColumn) === _.camelCase("CompTypeId") ||
          _.camelCase(x.dbColumn) === _.camelCase("LeaseTypeId") ||
          _.camelCase(x.dbColumn) === _.camelCase("EscalationMarkerId") ||
          _.camelCase(x.dbColumn) === _.camelCase("BuildingLeavingId") ||
          _.camelCase(x.dbColumn) === _.camelCase("RelocationReasonId") ||
          _.camelCase(x.dbColumn) === _.camelCase("CompanyId") ||
          _.camelCase(x.dbColumn) === _.camelCase("BrokerId") ||
          _.camelCase(x.dbColumn) === _.camelCase("BrokerFirmId") ||
          _.camelCase(x.dbColumn) === _.camelCase("BuildingId") ||
          _.camelCase(x.dbColumn) === _.camelCase("BuildingLeavingId") ||
          _.camelCase(x.dbColumn) === _.camelCase("LeaseTypeID") ||
          _.camelCase(x.dbColumn) === _.camelCase("NnnfsId") ||
          // _.camelCase(x.dbColumn) === _.camelCase("CompCreatedById") ||
          _.camelCase(x.dbColumn) === _.camelCase("TenantRepBrokerFirmId") ||
          _.camelCase(x.dbColumn) === _.camelCase("RepresentationTypeId") ||
          _.camelCase(x.dbColumn) === _.camelCase("TenancyTypeId") ||
          _.camelCase(x.dbColumn) === _.camelCase("FreeRentTypeId") ||
          _.camelCase(x.dbColumn) === _.camelCase("GenerationId") ||
          _.camelCase(x.dbColumn) === _.camelCase("SourceSystemId") ||
          _.camelCase(x.dbColumn) === _.camelCase("DirectSubLeaseId") ||
          _.camelCase(x.dbColumn) === _.camelCase("CompSourceId") ||
          _.camelCase(x.dbColumn) === _.camelCase("DockHeightId") 
        ) {
          if (x.attributeValue != null)
            x.attributeValue = x.attributeValue.toString();
        }
      });

      if (this.props.actionType === "Edit") {
        const compId = leaseCompData.filter(
          (x) => _.camelCase(x.dbColumn) === _.camelCase("CompId")
        );

        apiPut(
          API_URL.IndustrialLeaseComps.updateLeaseCompDetail,
          leaseCompData,
          {
            CompId: compId[0].attributeValue,
          }
        )
          .then(({ data: response }) => {
            if (response.success === true) {
              ShowToast(
                "Record updated successfully",
                toastType.SUCCESS,
                5000,
                500
              );
              this.setState({
                isDataSaved: false,
              });
              if (
                this.props.isRedirectedFromTenantTab == true ||
                this.props.isRedirectedFromReport == true
              )
                this.props.onBackClick();
              else
                this.props.reloadData();
              //this.getData();
            } else {
              ShowToast(
                "Record not updated successfully",
                toastType.ERROR,
                5000,
                500
              );
            }
          })
          .catch(() => {
            ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
          });
      } else {
        //leaseCompData[0].attributeValue=-1;

        apiPost({
          url: API_URL.IndustrialLeaseComps.addLeaseCompDetail,
          params: {},
          postBody: leaseCompData,
        })
          .then(({ data: response }) => {
            if (response.success === true) {
              ShowToast(
                "Record inserted successfully",
                toastType.SUCCESS,
                5000,
                500
              );
              this.props.reloadData();
            } else {
              ShowToast(response.message, toastType.error, 5000, 500);
            }
          })
          .catch(() => {
            ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
          });
      }
    }
  };
  onDeleteClick = () => {
    this.setState({ isDeleteClicked: true });
  };
  onBackClick = () => {
    this.props.onBackClick();
    if (this.state.isAddedFromPopup) this.props.onCancelClickAfterPopupAdd();
  };
  getHistoryDataByField = (val) => {
    const params = {
      entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
      sectionDetailId: val.sectionDetailId,
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      dbColumn: val.dbColumn,
      businessLineId: 2,
    };

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.getHistoryDetailByField,
        key: "getHistoryDetailByField",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDetailByField) => {
        this._asyncRequest = null;
        if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
          this.setState({
            getHistoryDetailByField:
              camelcaseKeys(
                getHistoryDetailByField.getHistoryDetailByField.data
              ) || [],
            openHistoryform: true,
            isHistoryByField: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  onInputChange = (value, key) => {
    if (key == 'buildingName')
      this.setState({ buildingNameSelected: value });
    else
      this.setState({ companyNameSelected: value });
    if (value.length > 2) {
      if (key == 'buildingName') {
        const buildingParams = {
          businessLineId: 2,
          searchText: value,
          //compId:this.state.compId
        };
        apiRequest(
          {
            url: API_URL.Master.getBuildingNameAddressCostarMaster,
            params: {
              businessLineId: 2,
              searchText: value,
              marketId: this.state.selectedMarketId
            }
          }
        ).then(({ data: getBuildingMaster }) => {
          this.setState({
            getBuildingMaster:
              camelcaseKeys(getBuildingMaster) || [],
            reloadLeaseComp: !this.state.reloadLeaseComp,
          }, () => { this.forceUpdate() });
        })
          .catch((reason) => {
            this._asyncRequest = null;
            ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
          });
      }
      else {
        const params = {
          businessLineId: 2,
          searchText: value,
          compId: this.state.compId,
        };
        apiRequestNoLoadder({
          url: API_URL.Master.getALLTenantCompany,
          params,
        })
          .then(({ data: companyMasterDetails }) => {
            this.setState(
              {
                companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
                reloadLeaseComp: !this.state.reloadLeaseComp,
              },
              () => {
                this.forceUpdate();
              }
            );
          })
          .catch((reason) => {
            this._asyncRequest = null;
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          });
      }
    }
  };

  handleClose = (e, val) => {
    if (val == "blur") return;
    this.setState({ openHistoryform: true });
  };

  handleChange = (e, val) => {
    const params = {
      entityPrimaryKey: val.entityPrimaryKey,
      modifiedDate: moment(val.createdDate).format("MM-DD-YYYY HH:mm:ss"),
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      businessLineId: 2,
    };
    //console.log("e", e, "val", val);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.getHistoryDataByUser,
        key: "getHistoryDataByUser",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDataByUser) => {
        this._asyncRequest = null;
        if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
          this.setState({
            getHistoryDataByUser:
              camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
              [],
            openHistoryform: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  goToAddBuilding = (source, option, updateoption = true) => {
    this.setState({
      openaddform: true,
      addformtitle: source.AddNewOptiontitle,
      addNewformComponent: EditBuilding,
      addnewClientSource: source,
    });
  };

  onPopupBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: -1 });
    if (this.state.openaddform == true) {
      this.setState({ openaddform: false }, () => {
        this.forceUpdate();
      });
    }
  };

  reloadPropertyData = (buildingData) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getBuildingNameAddressCostarMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 2, marketId: this.state.selectedMarketId },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((getLeaseCompDetail) => {
        this._asyncRequest = null;
        if (getLeaseCompDetail.getBuildingMaster.success == true) {
          this.setState(
            {
              getBuildingMaster:
                camelcaseKeys(getLeaseCompDetail.getBuildingMaster.data) || [],
              openaddform: false,
              addNewformComponent: undefined,
              reloadLeaseComp: !this.state.reloadLeaseComp,
              isAddedFromPopup: true,
            },
            () => {
              this.forceUpdate();
            }
          );
          this.setBuilding(buildingData);
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  buildingChanges = () => { };

  goToAddBuildingOwner = (source, option, updateoption = true) => {
    this.setState({
      openPropertyOwnerAddForm: true,
      addformtitle: source.AddNewOptiontitle,
      addNewformComponent: EditBuildingOwner,
      addnewClientSource: source,
    });
  };

  onPropertyOwnerPopupBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: -1 });
    if (this.state.openPropertyOwnerAddForm == true) {
      this.setState({ openPropertyOwnerAddForm: false }, () => {
        this.forceUpdate();
      });
    }
  };

  reloadPropertyOwnerData = (companyData, bit = 0) => {
    let companyName = null;
    let cId = [];
    if (companyData != undefined && companyData.length > 0) {
      companyName = companyData.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }

    let bData = this.state.getLeaseCompDetail;
    const params = {
      businessLineId: 2,
      searchText: companyName != undefined ? companyName : null,
      compId: this.state.compId,
    };

    apiRequest({
      url: API_URL.Master.getALLTenantCompany,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        let breakCondition = false;
        cId = companyMasterDetails.find((x) => {
          if (x.CompanyName === companyName && breakCondition == false) {
            breakCondition = true;
            return x;
          }
        });
        bData.find((x) => x.dbColumn == "CompanyName").attributeValue =
          companyName;
        bData.find((x) => x.dbColumn == "CompanyId").attributeValue =
          cId.CompanyId;
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
            getLeaseCompDetail:
              bit == 1 ? this.state.getLeaseCompDetail : bData,
            reloadLeaseComp: !this.state.reloadLeaseComp,
          },
          () => {
            this.forceUpdate();
          }
        );
        this.setBuildingOwner(companyData);
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
  };

  buildingOwnerChanges = () => { };
  setBuildingOwner = (value) => {
    let companyName = "";
    if (value.length > 0) {
      companyName = value.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }
    this.setState({
      selectedBuildingOwner: companyName,
      reloadLeaseComp: !this.state.reloadLeaseComp,
    });
    this.state.getLeaseCompDetail.filter((x) => {
      if (x.dbColumn == "CompanyName") x.attributeValue = companyName;
    });
    this.forceUpdate();
  };

  setBuilding = (value) => {
    let buildingName = "";
    if (value.length > 0) {
      buildingName = value.find(
        (x) => x.dbColumn == "BuildingName"
      ).attributeValue;
    }
    this.setState({
      selectedBuilding: buildingName,
      reloadLeaseComp: !this.state.reloadLeaseComp,
    });
    this.state.getLeaseCompDetail.filter((x) => {
      if (x.dbColumn == "BuildingName") x.attributeValue = buildingName;
    });
    this.forceUpdate();
  };

  getList = (fileName) => {
    getSpsAPiRequest(
      getSharePointUrl(
        "streamrealty",
        "getInnerFolder",
        this.state.spListkey,
        "Lease"
      )
    ).then((spsDataRes) => {
      if (spsDataRes) {
        const { value } = spsDataRes;
        for (let i = 0; i < value.length; i++) {
          if (fileName === value[i].name) {
            this.setState({
              downloadURL: value[i]["@microsoft.graph.downloadUrl"],
            });
            break;
          }
        }
      }
    });
  };

  render() {
    const {
      getMarketMaster,
      //  getAllTenancy,
      getAllRentType,
      getAllRepresentationType,
      getAllGeneration,
      getAllCompSource,
      getAllDirectSubLease,
      getAllSourceSystem,
      isEditEnabled,
      leaseCompsSummaryData,
      getLeaseCompDetail,
      getAllCompType,
      getAllLeaseType,
      getALLNNNFS,
      getALLEscalationMarker,
      getBuildingMaster,
      companyMasterDetails,
      getAllRelocationReason,
      //getBrokerMaster,
      getALLBrokerFirm,
      getHistory,
      getHistoryDataByUser,
      getHistoryDetailByField,
      isHistoryByField,
      getSubMarketMaster,
      getAllFreeRentType,
      getAllSpaceModification,
      getBrokerMaster,
      getAllTenantIndustry,
      getALLDockHeight,
      getAllDockConfiguration
    } = this.state;
    var ownerName = "";
    if (getLeaseCompDetail.length > 0) {
      ownerName = getLeaseCompDetail.find(
        (x) => x.dbColumn == "CompanyName"
      ).attributeValue;
    }
    let getCompType = getAllCompType.filter((m) => {
      if (m.compType === "Lease") return m;
    });


    let getRepresentionType = getAllRepresentationType.filter((m) => {
      if (
        m.representationType === "Landlord" ||
        m.representationType === "Tenant"
      )
        return m;
    });

    const compSeparatorConfig = [
      {
        separatorBeforeKey: "InternalDeal",
        order: 1,
        row: 1,
        sectionColumn: 12,
        label: "Key Transaction Details",
      },
      {
        separatorBeforeKey: "rsf",
        order: 1,
        row: 1,
        sectionColumn: 12,
        label: "Space Leased",
      },
      {
        separatorBeforeKey: "Free",
        order: 1,
        row: 1,
        sectionColumn: 12,
        label: "Concessions & Additional Details",
      },
      {
        separatorBeforeKey: "companyName",
        order: 1,
        row: 1,
        sectionColumn: 12,
        label: "Deal Party",
      },
      {
        separatorBeforeKey: "CompVerified",
        order: 1,
        row: 1,
        sectionColumn: 12,
        label: "Sourcing",
      },
      // {
      // 	separatorBeforeKey: "dateAddedInExternalSystem",
      // 	order: 1,
      // 	row: 1,
      // 	sectionColumn: 12,
      // 	label: "Sourcing",
      // },
    ];

    const dropDownSource = [
      {
        key: "market",
        option: "market",
        idField: "marketId",
        source: getMarketMaster,
      },
      {
        key: "subMarket",
        option: "subMarket",
        idField: "subMarketId",
        source: getSubMarketMaster,
        filterSourceOn: [{ key: "marketId", filterBy: "marketId" }],
        applyFilter: true,
      },
      // {
      // 	key: "landLordBrokerCompany",
      // 	option: "landLordBrokerCompany",
      // 	idField: "landLordBrokerCompanyId",
      // 	source: getALLBrokerFirm,
      // },
      //{
      //	key: "tenancyType",
      //	option: "tenancyType",
      //	idField: "TenancyTypeId",
      //	source: getAllTenancy,
      //},
      {
        key: "representationType",
        option: "representationType",
        idField: "representationTypeId",
        source: getRepresentionType,
      },
      {
        key: "freeRentType",
        option: "freeRentType",
        idField: "freeRentTypeId",
        source: getAllFreeRentType,
      },
      {
        key: "rentType",
        option: "rentType",
        idField: "rentTypeId",
        source: getAllRentType,
      },
      {
        key: "compModification",
        option: "compModification",
        idField: "compModificationID",
        source: getAllSpaceModification,
      },
      {
        key: "generation",
        option: "generation",
        idField: "generationId",
        source: getAllGeneration,
      },
      {
        key: "compSource",
        option: "compSource",
        idField: "compSourceId",
        source: getAllCompSource,
      },
      {
        key: "compType",
        option: "compType",
        idField: "compTypeId",
        source: getCompType,
      },
      {
        key: "directSublease",
        option: "directSublease",
        idField: "directSubLeaseId",
        source: getAllDirectSubLease,
      },
      {
        key: "srcSysCode",
        option: "srcSysCode",
        idField: "sourceSystemId",
        source: getAllSourceSystem,
      },
      {
        key: "companyName",
        option: "companyName",
        idField: "companyId",
        source: companyMasterDetails,
        AddNewOption: IsSectionVisible(
          SECTIONS.Company, SECTIONS.Company_Details, SECTIONS.ADD_EDIT
        ),
        AddNewOptiontitle: "New Tenant",
        AddOptionType: "Button",
        onAddClick: this.goToAddTenant,
        onChange: this.tenantChanges,
        onInputChange: this.onInputChange,
        value: this.state.selectedBuildingOwner,
      },
      {
        key: "compCreatedBy",
        option: "compCreatedBy",
        idField: "compCreatedById",
        source: getBrokerMaster,
      },
      // {
      // 	key: "brokerFirmName",
      // 	option: "brokerFirmName",
      // 	idField: "tenantRepBrokerFirmId",
      // 	source: getALLBrokerFirm,
      // },
      {
        key: "buildingName",
        option: "buildingName",
        idField: "buildingId",
        source: getBuildingMaster,
        AddNewOption: IsSectionVisible(
          SECTIONS.Property,
          SECTIONS.Property_Details,
          SECTIONS.ADD_EDIT
        ),
        AddNewOptiontitle: "New Property",
        AddOptionType: "Button",
        onAddClick: this.goToAddBuilding,
        onChange: this.buildingChanges,
        onInputChange: this.onInputChange,
        value: this.state.selectedBuilding,
      },
      {
        key: "buildingLeavingName",
        option: "buildingLeavingName",
        idField: "buildingLeavingId",
        source: getBuildingMaster,
      },
      {
        key: "leaseType",
        option: "leaseType",
        idField: "leaseTypeID",
        source: getAllLeaseType,
      },
      {
        key: "relocationReason",
        option: "relocationReason",
        idField: "relocationReasonId",
        source: getAllRelocationReason,
      },
      {
        key: "nnnfs",
        option: "nnnfs",
        idField: "nnnfsId",
        source: getALLNNNFS,
      },
      {
        key: "escalationMarker",
        option: "escalationMarker",
        idField: "escalationMarkerId",
        source: getALLEscalationMarker,
      },
      {
        key: "tenantIndustry",
        option: "tenantIndustry",
        idField: "tenantIndustryId",
        source: getAllTenantIndustry,
      },
      {
        key: "dockHeight",
        option: "dockHeight",
        idField: "dockHeightId",
        source: getALLDockHeight,
      },
      {
        key: "dockConfiguration",
        option: "dockConfiguration",
        idField: "dockConfigurationId",
        source: getAllDockConfiguration,
      },

      
    ];

    const DetailsFormConfig = [
      {
        key: "directSubLease",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "generation",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "compSource",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "market",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "subMarket",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "representationType",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "tenancyType",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "landLordBrokerCompany",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "freeRentType",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "rentType",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "compModification",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "companyName",
        type: controlType.Autofill,
        multiple: false,
      },
      {
        key: "buildingName",
        type: controlType.Autofill,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "buildingLeavingName",
        type: controlType.DropDown,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "rsf",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "compType",
        type: controlType.DropDown,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "compCreatedBy",
        type: controlType.DropDown,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "brokerFirmName",
        type: controlType.TextBox,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "relocationReason",
        type: controlType.DropDown,
        multiple: false,
        //columnWidth:6
      },
      {
        key: "Free",
        type: controlType.TextBox,
        multiple: false,
      },
      {
        key: "notes",
        type: controlType.TextArea,
        multiple: false,
      },
      {
        key: "internalNotes",
        type: controlType.TextArea,
        multiple: false,
      },
      {
        key: "lcd",
        type: controlType.DatePicker,
        multiple: false,
      },
      {
        key: "compCreatedDate",
        type: controlType.DatePicker,
        multiple: false,
      },
      {
        key: "srcSysCode",
        type: controlType.DropDown,
        multiple: false,
      },
      {
        key: "monthsInSpace",
        type: controlType.TextBox,
        multiple: false,
      },
      { key: "leaseType", type: controlType.DropDown, multiple: false },
      { key: "nnnfs", type: controlType.DropDown, multiple: false },
      { key: "importFileName", type: controlType.Link, multiple: false },
      { key: "escalationMarker", type: controlType.DropDown, multiple: false },
      { key: "lxDte", type: controlType.DatePicker, multiple: false },
      { key: "moveInDate", type: controlType.DatePicker, multiple: false },
      { key: "closedate", type: controlType.DatePicker, multiple: false },
      { key: "tenantIndustry", type: controlType.DropDown, multiple: false },
      { key: "DockHeight", type: controlType.DropDown, multiple: false },
      { key: "dockConfiguration", type: controlType.DropDown, multiple: false },
      // { key: "productType", type: controlType.DropDown, multiple: true },
      // { key: "market", type: controlType.DropDown, multiple: false },
      // { key: "subMarket", type: controlType.DropDown, multiple: false }
    ];
    let leaseCompsData = leaseCompsSummaryData.filter((m) => {
      if (m.companyName != null) return m;
    });

    const filterConfig = [
      {
        type: controlType.DropDown,
        minTag: 1,
        source: leaseCompsData,
        key: "companyName",
        placeholder: "",
        title: "Tenant",
      },
    ];
    const ownerNam = ownerName != undefined ? ownerName : this.props.ViewTitle;
    return (
      <>
        {this.state.openHistoryform && (
          <Dialog open={this.state.openHistoryform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  Update History
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({
                      openHistoryform: false,
                      isHistoryByField: false,
                    });
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <ViewHistoryData
                sourceData={getHistoryDataByUser}
                getHistoryDetailByField={getHistoryDetailByField}
                dropDownSource={dropDownSource}
                isHistoryByField={isHistoryByField}
              ></ViewHistoryData>
            </div>
          </Dialog>
        )}
        {this.state.isDataSaved && this.state.isSearchedClicked && (
          <ConfirmDialog
            IsOpen={this.state.isSearchedClicked}
            Message={
              "There are unsaved changes.Do you still want to search another Company"
            }
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandel(isValid);
              } else {
                this.onClickHandel(isValid);
              }
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
        {this.state.isDeleteClicked && (
          <ConfirmDialog
            IsOpen={this.state.isDeleteClicked}
            Message={"Are you sure you want to delete this record?"}
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onDeleteClickHandel(isValid);
              } else {
                this.onDeleteClickHandel(isValid);
              }
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}

        {this.state.openaddform && (
          <Dialog open={this.state.openaddform} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  {this.state.addformtitle}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.reloadPropertyData();
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <EditBuilding
                globalMarketId={this.props.globalMarketId}
                selectedBuildingId={this.state.selectedEditRowId}
                onBackClick={this.onPopupBackClick}
                ViewTitle={this.state.ViewTitle}
                actionType={this.state.actionType}
                isInAddMode={true}
                reloadData={this.reloadData}
                isRedirectedFromTenant={true}
                reloadPropertyData={this.reloadPropertyData}
                setBuilding={this.setBuilding}
              />
            </div>
          </Dialog>
        )}

        {this.state.openPropertyOwnerAddForm && (
          <Dialog open={this.state.openPropertyOwnerAddForm} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar className="custom-toolbar">
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginTop: "15px", flex: "1", color: "#FFFFF" }}
                >
                  {this.state.addformtitle}
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    this.setState({ openPropertyOwnerAddForm: false });
                  }}
                  aria-label="close"
                  style={{ padding: "0px" }}
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <EditBuildingOwner
                globalMarketId={this.props.globalMarketId}
                selectedBuildingId={this.state.selectedEditRowId}
                onBackClick={this.onPropertyOwnerPopupBackClick}
                ViewTitle={this.state.ViewTitle}
                actionType={this.state.actionType}
                isInAddMode={true}
                reloadData={this.reloadData}
                isRedirectedFromTenant={true}
                reloadPropertyOwnerData={this.reloadPropertyOwnerData}
                setBuildingOwner={this.setBuildingOwner}
              />
            </div>
          </Dialog>
        )}

        {isEditEnabled || this.props.isInAddMode ? (
          <div className="row header-container">
            <div className="col-md-4 col-lg-4">
              <div className="row">
                <PageHeader
                  btnText={"Lease Comps"}
                  onBackClick={this.onBackClick}
                  title={
                    this.props.isInAddMode
                      ? "/ " + this.props.ViewTitle
                      : "/ " + ownerNam
                  }
                  style={{ color: "#00B0B9" }}
                ></PageHeader>
              </div>
            </div>

            <div className="col-md-4 col-lg-4">
              {leaseCompsSummaryData.length > 0 && (
                <AutoSearch
                  placeholder="Search Lease Comps"
                  isEditEnabled={this.state.isEditEnabled}
                  sourceData={leaseCompsData}
                  filterConfig={filterConfig}
                  onSearch={this.onSearch}
                ></AutoSearch>
              )}
              {/* <SearchBar></SearchBar> */}
            </div>
            <div className="col-md-4 col-lg-4 end-sm">
              <Button
                size="small"
                style={{ marginTop: "5px" }}
                className={
                  this.props.isInAddMode ? "btn-clear" : "btn-clear btn-small"
                }
                onClick={() => {
                  this.setState({
                    getLeaseCompDetail: JSON.parse(
                      JSON.stringify([...this.state.getLeaseCompDetailResponse])
                    ),
                    reloadLeaseComp: !this.state.reloadLeaseComp,
                  });
                }}
              >
                <span className="tool-icon-text">
                  {this.props.isInAddMode == true ? "Clear All" : "Undo"}
                </span>
              </Button>
              {IsSectionVisible(
                SECTIONS.Industrial_Lease_Comps,
                SECTIONS.Industrial_Lease_Comp_Details,
                SECTIONS.ADD_EDIT
              ) &&
                !this.props.isInAddMode && (
                  <Button
                    size="small"
                    className="notop-action-button btn-small"
                    onClick={() => {
                      this.onDeleteClick();
                    }}
                    style={{ backgroundColor: "red !important" }}
                  >
                    <span className="tool-icon-text">Delete</span>
                  </Button>
                )}
              <Button
                size="small"
                className={
                  this.props.isInAddMode
                    ? "notop-action-button"
                    : "notop-action-button btn-small"
                }
                onClick={this.onBackClick}
              >
                <span className="tool-icon-text">Cancel</span>
              </Button>
              {IsSectionVisible(
                SECTIONS.Industrial_Lease_Comps,
                SECTIONS.Industrial_Lease_Comp_Details,
                SECTIONS.ADD_EDIT
              ) && (
                  <Button
                    size="small"
                    className={
                      this.props.isInAddMode
                        ? "notop-action-button"
                        : "notop-action-button btn-small"
                    }
                    onClick={() => {
                      this.saveLeaseDetails();
                    }}
                  >
                    <span className="tool-icon-text">Save</span>
                  </Button>
                )}
            </div>
          </div>
        ) : (
          <div className="row header-container">
            <div className="col-md-4 col-lg-4">
              <PageHeader
                btnText={"Lease Comps"}
                onBackClick={this.onBackClick}
                title={"/ " + ownerNam}
                style={{ color: "#00B0B9" }}
              ></PageHeader>
            </div>
            <div className="col-md-4 col-lg-4">
              {leaseCompsSummaryData.length > 0 && (
                <AutoSearch
                  placeholder="Search another lease comp"
                  isEditEnabled={this.state.isEditEnabled}
                  sourceData={leaseCompsData}
                  filterConfig={filterConfig}
                  onSearch={this.onSearch}
                ></AutoSearch>
              )}
            </div>
            <div className="col-md-4 col-lg-4 end-sm">
              {IsSectionVisible(
                SECTIONS.Industrial_Lease_Comps,
                SECTIONS.Industrial_Lease_Comp_Details,
                SECTIONS.ADD_EDIT
              ) && (
                  <Button
                    size="small"
                    className="notop-action-button btn-small"
                    onClick={() => {
                      this.onDeleteClick();
                    }}
                    style={{ backgroundColor: "red !important" }}
                  >
                    <span className="tool-icon-text">Delete</span>
                  </Button>
                )}
              <Button
                size="small"
                className="notop-action-button btn-small"
                onClick={this.onBackClick}
              >
                <span className="tool-icon-text">Cancel</span>
              </Button>
              {IsSectionVisible(
                SECTIONS.Industrial_Lease_Comps,
                SECTIONS.Industrial_Lease_Comp_Details,
                SECTIONS.ADD_EDIT
              ) && (
                  <Button
                    size="small"
                    className="notop-action-button btn-small"
                    onClick={() => {
                      this.enableEdit();
                    }}
                  >
                    <span className="tool-icon-text">Edit</span>
                  </Button>
                )}
            </div>
          </div>
        )}
        {getHistory.length > 0 && isEditEnabled && (
          <div className="row header-container">
            <div className="col-md-7 col-lg-7"></div>
            <div
              className="col-md-2 col-lg-2 end-sm"
              style={{ padding: "15px" }}
            >
              <span>Last Modified:</span>
            </div>
            <div className="col-md-3 col-lg-3 end-sm">
              <Autocomplete
                key={`lastModified:${getHistory[0].createdBy}`}
                // style={{ width: "374px" }}
                size="small"
                underlineStyle={{ display: "none" }}
                //value={}
                defaultValue={{
                  createdBy: getHistory[0].createdBy,
                  createdDate: moment(getHistory[0].createdDate).format(
                    "MM-DD-YYYY HH:mm"
                  ),
                }}
                onClose={(e, val) => this.handleClose(e, val)}
                onChange={(e, val) => this.handleChange(e, val)}
                id="tags-standard"
                options={getHistory}
                getOptionLabel={(option) =>
                  option.createdBy +
                  "," +
                  moment(option.createdDate).format("MM-DD-YYYY HH:mm:ss")
                }
                renderInput={(params) => (
                  // <Box
                  //   sx={{ display: "flex", alignItems: "center" }}
                  //   className="filter-label"
                  // >
                  //   <label htmlFor="filled-hidden-label-small">
                  //
                  //   </label>
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
                    hiddenLabel
                  />
                  // </Box>
                )}
              />
              {/* <SimpleSelect></SimpleSelect> */}
            </div>
          </div>
        )}

        <div>
          {getLeaseCompDetail && (
            <FormGenerator
              gridColumn={4}
              ReloadForm={this.state.reloadLeaseComp}
              formFields={UpdateFieldsWithConfiguration(
                getLeaseCompDetail,
                DetailsFormConfig
              )}
              Editable={this.state.isEditEnabled}
              dropDownSource={dropDownSource}
              saveChanges={this.getLeaseCompChanges}
              partialForm={true}
              validationMessages={[]}
              formType={"Section"}
              separatorConfig={compSeparatorConfig}
              sectionRowConfig={[{ row: 1, column: 12 }]}
              getHistoryDataByField={this.getHistoryDataByField}
              // sectionRowConfig={[{row:1,column:5},{row:2,column:4,labelAlign:'alignText-Right'}]}
              viewPDF={[]}
            ></FormGenerator>
          )}
        </div>
      </>
    );
  }
}

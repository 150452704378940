import React from "react";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { DialogContent } from "@material-ui/core";
import "../Dialog/index.less";

class FullScreenDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.url,
      open: false,
    };
  }

  componentDidMount() {
    if (this.state.url !== undefined && this.props.open === true) {
      const pdfWindow = window.open("");
      pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.state.url) + "'></iframe>");
    }
  }

  static getDerivedStateFromProps(newProps, state) {
    if (newProps.url !== state.url || newProps.open !== state.open) {
    //  console.log("file change", newProps.url);
      return {
        url: newProps.url,
        open: newProps.open,
      };
    }
    return null;
  }

  handelClose = () => {
    this.props.close();
  };

  render() {
    return (
      <Dialog fullWidth open={this.props.open} maxWidth="sm">
        <AppBar style={{ position: "relative", backgroundColor: "#064473" }}>
          <Toolbar>
            <Typography variant="subtitle1" style={{ marginLeft: "0px", flex: "1" }}>
              {this.props.title}
            </Typography>
            <IconButton edge="start" color="inherit" onClick={this.handelClose} aria-label="close" style={{padding:"0px", marginBottom:"20px"}}>
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent>
          {this.state.url !== undefined ? (
            <iframe src={this.state.url} style={{ position: "absolute", height: "900px", width: "100%" }} />
          ) : (
            <div>{this.props.children}</div>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

export default FullScreenDialog;

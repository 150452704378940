import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
import _ from 'lodash';

export const Switch = (props) => {
  const {
    className,
    labelText,
    fieldKey,
    noText,
    isChecked,
    obj,
    onChangeValues,
    row,
    isInAddMode, disabled } = props;
  let switchClass = className;
  const id = _.camelCase(props.id);

  if (props.large === true) {
    switchClass += ' switch--large';
  } else {
    switchClass += ' switch--small';
  }
  if (props.noText === true) {
    switchClass += ' switch--no-text';
  }
  if (props.theme === 'success') {
    switchClass += ' switch--success';
  }
  return (
    <>
      <div className="switch__outer__label">{labelText}</div>
      <div aria-label={id} className={switchClass}>
        <label className="switch__label" htmlFor={id}>
          <input
            key={`switch:${id}:${isChecked}`}
            name = {id}
            role="switch"
            type="checkbox"
            defaultChecked={isChecked}
            disabled={disabled}
            onClick={(e) => {
              onChangeValues(e.target.checked, fieldKey, row, isInAddMode, obj);
            }}
            value = {isChecked}
            className="switch__input"
            id={id}
          />
          <span className="switch__text" data-on="Yes" data-off="No"></span>
          <span className="switch__handle"></span>
        </label>
      </div>
    </>
  );
};

Switch.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  label: PropTypes.label,
  noText: PropTypes.boolean,
  isChecked: PropTypes.boolean
};

Switch.defaultProps = {
  className: 'switch',
  noText: false,
  isChecked: false
};

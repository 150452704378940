import React, { Component } from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import fileTypeExcel2 from '@iconify/icons-vscode-icons/file-type-excel2';
import { Button, Grid, IconButton, Link, MenuItem, Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';
import './report.less';
import { Workbook } from 'react-xlsx-workbook-dynamic-column-width';
import Download from '../public/img/common/download.svg';
import Setting from '../public/img/common/settings.svg';
import Printer from '../public/img/common/printer.svg';
import { Icon } from '@iconify/react';
import bxFilterAlt from '@iconify/icons-bx/bx-filter-alt';
import filterIcon from '@iconify/icons-ci/filter';

import RotateLeftIcon from '@material-ui/icons/RotateLeft';

import filterPlusOutline from '@iconify/icons-mdi/filter-plus-outline';
import { FaHourglassEnd, FaSpinner } from 'react-icons/fa';
import { RiFilterLine, RiFilterFill, RiFilterOffLine } from 'react-icons/ri';
import { IsSectionVisible } from './helper';
import { SECTIONS } from '../constants/appConstants';

export default class ReportButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false
    };
  }

  showMenu = (event) => {
    event.preventDefault();
    let show = this.state.showMenu;
    this.setState({
      showMenu: !show,
    });
  }
  static get propTypes() {
    return {
      children: PropTypes.any,
      handleClickOpen: PropTypes.func,
      handleSubmit: PropTypes.func,
      resetPage: PropTypes.func,
      enableFilter: PropTypes.func,
      handlePrintClick: PropTypes.func,
      exportPDF: PropTypes.func,
      data: PropTypes.array,
      columnWidths: PropTypes.array,
      uploadColumns: PropTypes.array,
      fileName: PropTypes.string,
      openFilter: PropTypes.bool,
      isListingPage: PropTypes.bool,
      downloadExcelReport: PropTypes.func,
      enableFilterButton: PropTypes.bool,
      ParentSection:PropTypes.string,
      ChildSectionForPrint:PropTypes.string,
      ChildSectionForDownload:PropTypes.string,
      ChildSectionForSettings:PropTypes.string,
    };
  }

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };
  render() {
    return (
      <Grid container
        className="end-sm"
      >

        <Grid item>
          <div style={{ display: 'flex' }}>

            <>

              {
                this.props.enableFilterButton ? (
                  <div style={{ marginTop: '5px' }}>
                    <Tooltip title={this.props.openFilter ? "Close Filter" :"Open Filter"}>
                      <IconButton size="small" onClick={this.props.enableFilter} style={{ margin: '0px 4px' }}>
                        <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                          {this.props.openFilter ? <RiFilterFill /> : <RiFilterLine />}
                          {/* <FilterIcon /> */}
                        </div>
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  <div style={{ marginTop: '5px' }}>
                    <Tooltip title="Filter disabled till data loads..">
                    <IconButton size="small" style={{ margin: '0px 4px' }}>
                      <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                        <RiFilterOffLine />
                      </div>
                    </IconButton>
                    </Tooltip>
                  </div>

                )
              }
              {/* <div style={{ marginTop: '1rem' }}>
                <Tooltip title="Reset Filters">
                  <IconButton size="small" onClick={this.props.resetPage}>
                    <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                      <RotateLeftIcon />
                    </div>
                  </IconButton>
                </Tooltip>
              </div> */}
				{IsSectionVisible(this.props.ParentSection,this.props.ChildSectionForDownload, SECTIONS.ADD_EDIT) &&

              <div style={{ marginTop: '5px', display: 'none' }} className="dropdown">
                <Tooltip title="Download Reports" placement="left">
                  <IconButton size="small" onClick={this.showMenu} style={{ margin: '0px 4px' }}>
                    <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                      {/* <GetAppIcon /> */}
                      <img src={Download} alt="Download" />

                    </div>
                  </IconButton>
                </Tooltip>

                {
                  this.state.showMenu
                    ? (
                      <div className="menu">
                        <Tooltip title="Export Pdf">
                          <IconButton size="small"
                            onClick={this.props.exportPDF}
                          >
                            <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                              <PictureAsPdfIcon />
                            </div>
                          </IconButton>
                        </Tooltip> <IconButton size="small" onClick={this.props.downloadExcelReport}>
                          <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                            <Icon icon={fileTypeExcel2} />
                          </div>
                        </IconButton>

                      </div>
                    )
                    : (
                      null
                    )
                }
              </div>
  }
				{ !this.props.isReportPage && IsSectionVisible(this.props.ParentSection,this.props.ChildSectionForPrint, SECTIONS.ADD_EDIT) &&
              <div style={{ marginTop: '5px', display: 'none' }}>
                <Tooltip title="Print">
                  <IconButton
                    style={{ margin: '0px 4px' }}
                    size="small"
                    onClick={this.props.handlePrintClick}
                  >
                    <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                      {/* <PrintIcon /> */}
                      <img src={Printer} alt="Printer" />

                    </div>
                  </IconButton>
                </Tooltip>
              </div>
  }
              {/* <div style={{ marginTop: '1rem' }}>
                <Tooltip title="Share">
                  <IconButton
                    size="small"
                    onClick={this.props.handlePrintClick}
                  >
                    <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                      <ShareSharpIcon />
                    </div>
                  </IconButton>
                </Tooltip>
              </div> */}

              {this.props.isListingPage == false ? (<div style={{ marginTop: '5px' }}>

                <Tooltip title="Advance Filter">
                  <IconButton size="small" onClick={this.props.handleClickOpen} style={{ margin: '0px 4px' }}>
                    <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                      {/* <SettingsOutlinedIcon /> */}
                      <Icon icon={filterPlusOutline} />
                    </div>
                  </IconButton>
                </Tooltip>
              </div>) :

                (
                  // <div>
                  IsSectionVisible(this.props.ParentSection,this.props.ChildSectionForSettings, SECTIONS.ADD_EDIT) &&

                  <div style={{ marginTop: '5px' }}>
                    <Tooltip title="Setting">
                      <IconButton size="small" onClick={this.props.handleClickOpen} style={{ margin: '0px 4px' }}>
                        <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                          {/* <SettingsOutlinedIcon /> */}
                          <img src={Setting} alt="Setting" />

                        </div>
                      </IconButton>
                    </Tooltip>
                  </div>
                  

                  // </div>
                )}

              {this.props.isReportPage == true &&
                <div style={{ marginTop: '5px' }}>
                  <Tooltip title="Reset Filters">
                    <IconButton size="small" onClick={this.props.resetPage}>
                      <div style={{ fontSize: '1.5rem', color: '#00B0B9' }}>
                        <RotateLeftIcon />
                      </div>
                    </IconButton>
                  </Tooltip>
                </div>
              }
              {/* <div style={{ marginTop: '1rem' }}>
                <Tooltip title="Export Pdf">
                  <IconButton size="small"
                //   onClick={this.props.exportPDF}
                   >
                    <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                      <PictureAsPdfIcon />
                    </div>
                  </IconButton>
                </Tooltip>
              </div>
              <div style={{ marginTop: '1rem' }}>
                <Workbook
                  filename={this.props.fileName}
                  element={
                    <Tooltip title="Export Excel">
                      <IconButton size="small">
                        <div style={{ fontSize: '1.5rem', color: '#05375E' }}>
                          <AssignmentIcon />
                        </div>
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <Workbook.Sheet
                    data={this.props.data}
                    name="Record"
                    columsWidths={this.props.columnWidths}
                  >
                    {this.props.uploadColumns.map((column, index) => (
                      <Workbook.Column
                        key={index}
                        label={column.name}
                        value={column.key}
                      />
                    ))}
                  </Workbook.Sheet>
                </Workbook>
              </div> */}
            </>
          </div>
        </Grid>
      </Grid>
    );
  }
}

import React from "react";
import "../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../node_modules/react-resizable/css/styles.css";
import { controlType } from "../../../constants/common";
import { API_URL, getCurrentEnvironment } from "../../../constants/api";
import {
  getSPSTokenData,
  setSPSToken,
  standardDateFormat,
} from "../../../constants/constants";

import {
  apiBatchRequest,
  apiBatchRequestWithoutLoader,
  apiPost,
  apiPostNoLoader,
  apiRequest,
  CancelRequest,
  getSpsAPiRequest,
  RenewCancelToken,
  spsApiPost,
  uploadSpsAPiRequest,
  uploadSpsMetadataAPiRequest,
} from "../../../utils/api_service";
import { convertToChartDate, getDate } from "../../../utils/dateForSlider";
import * as XLSX from "xlsx";
import PageHeader from "../../common/PageHeader";
import PowerBIEmbedControl from "../../common/PowerBIEmbedControl";
import powerBI from "../../common/PowerBIEmbedControl/power-bi-config.json";
import moment from "moment-mini";
import RadioGroup from "../../common/radio";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Icon } from "@iconify/react";
import importIcon from "@iconify/icons-fontisto/import";
import exportIcon from "@iconify/icons-fontisto/export";
//import axios from 'axios';
import {
  AppBar,
  Box,
  Dialog,
  Grid,
  IconButton,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import camelcaseKeys from "camelcase-keys";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { DateFormat, DateFormatImport, SECTIONS } from "../../../constants/appConstants";
import {
  checkFileValidation,
  downloadExcelFile,
  fileDateAppender,
  getSectionAttributeValueByKey,
  IsSectionVisible,
  renameKey,
} from "../../../utils/helper";
import { IoMdCloseCircleOutline } from "react-icons/io";
import DownloadTemplate from "../../common/ExportImport/DownloadTemplate";
import {
  downloadImportFileData,
  downloadStatsExcelReport,
  generateString,
  setDownloadingStatus,
} from "../../../utils/generic";
import { ShowToast, toastType } from "../../../utils/toast-service";
import ClearImport from "./ClearImport";
import ExportData from "./ExportData";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import { getSharePointUrl } from "../../../constants/sharepointApi";
//import { ValidationError } from "webpack";

//import importFile from '../../../public/ImportTemplate/MarketStats_ImportTemplate.xlsx'
const radioArr = [
  { key: "mtdRadio", label: "Date Range", checked: true },
  { key: "ytdRadio", label: "YTD" },
  { key: "yearRadio", label: "Yearly" },
  //{ key: "ttmRadio", label: "TTM" }
];

const reportStyle = {
  // style object for report component
  background: "transparent",
  height: "800px",
  overflow: "hidden",
  width: "100%",
  border: "0px solid transparent",
  marginTop: "5px",
  //marginLeft:"15%"
};

const extraSettings = {
  filterPaneEnabled: false, //true
  navContentPaneEnabled: false, //true
  hideErrors: false, // Use this *only* when you want to overide error experience i.e, use onError
  background: 1, // ... more custom settings,
};

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      enableFilterIcon: false,
      openFilter: false,
      houstonOfficeStatsChartData: {},
      submarketBreakdown1ChartData: {},
      submarketBreakdown2ChartData: {},
      submarketSpecificChartData: {},
      developmentPipelineChartData: {},
      annualAbsorptionChartData: {},
      covidSubAbsChartData: {},
      quaterAndMonthlyResearchChartData: {},
      ytdAbsorptionSubmarketChartData: {},
      mappingFields: null,
      selectedPropertyKey: props.selectedPropertyKey,
      openProformaModel: false,
      isCollapse: false,
      sliderRadioSelected: "mtdRadio",
      getSubMarketMaster: [],
      getMarketMaster: [],
      getBuildingClass: [],
      startDate: moment("1999-01-01").startOf("month").format(DateFormat),
      endDate: moment().endOf("month").format(DateFormat),
      specSubmarket: "CBD",
      openDialog: false,
      //openExportDialog: false,
      isUpload: false,
      getImportfileData: [],
      listOfColumns: [],
      listOfHeader: [],
      lstRequiredField: [],
      listOfDbColumn: [],
      market: null,
      isClearClicked: false,
      enableClear: false,
      openConfirmDialog: false,

      importMonth: null,
      formattedData: [],
      clearImport: false,
      dataExport: false,
      selectedFile: null,
      selectedFileName: null,

      selectedFileSize: null,
      upKey: "",
      upfileName: "",
      selectedTimeFrame: moment().startOf("month").format(DateFormat),
      selectedMarket: null,
      selectedProduct: "2",
      selectedSubMarket: null,
      showMonthly: true,
      showYtd: false,
      showYearly: false,
      currentSeletedTab: "tab1",
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5: false,
      tab17: false,
      tab18: false,
      tab19: false,
      tab20: false,
      tab21: false,
      spsData: [],
      spListkey: null,
      downloadFileUrl: null,
      breakCondition: false,
      uploadingKey: null,
      selectedValue: null,
    };
  }
  componentDidMount() {
    RenewCancelToken();
    this.getRequiredData(this.state.selectedMarketId);
  }

  componentWillUnmount() {
    CancelRequest();
  }

  getRequiredData = (selectedMarketId) => {
    if (selectedMarketId != "") {
      const env = getCurrentEnvironment().environment;
      const x = "industrial" + env;
      const reportConfig = powerBI[x];
      const houstonOfficeStatsChart = reportConfig.reports.find(
        (x) => x.reportName == "houstonOfficeStats"
      );
      const submarketBreakdown1Chart = reportConfig.reports.find(
        (x) => x.reportName == "submarketBreakdown1"
      );
      const submarketBreakdown2Chart = reportConfig.reports.find(
        (x) => x.reportName == "submarketBreakdown2"
      );
      const annualAbsorptionChart = reportConfig.reports.find(
        (x) => x.reportName == "annualAbsorption"
      );
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "houstonOfficeStatsChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: houstonOfficeStatsChart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "submarketBreakdown1ChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: submarketBreakdown1Chart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "submarketBreakdown2ChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: submarketBreakdown2Chart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "annualAbsorptionChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: annualAbsorptionChart.reportId,
          },
        },
        {
          url: API_URL.Master.getMarketMaster,
          key: "getMarketMaster",
          params: { businessLineId: 2, marketId: selectedMarketId },
          fromCache: true,
          duration: 60,
        },
      ])
        .then((houstonOfficeStatsChartData) => {
          this._asyncRequest = null;
          if (
            houstonOfficeStatsChartData.houstonOfficeStatsChartData.success ==
            true
          ) {
            this.getRequiredData2(selectedMarketId, env, reportConfig);
            this.setState({
              fromDate: moment("1999-01-01")
                .startOf("month")
                .format(DateFormat),
              toDate: moment().endOf("month").format(DateFormat),
              houstonOfficeStatsChartData: {
                ...houstonOfficeStatsChartData.houstonOfficeStatsChartData.data,
                configuration: houstonOfficeStatsChart,
              },
              submarketBreakdown1ChartData: {
                ...houstonOfficeStatsChartData.submarketBreakdown1ChartData
                  .data,
                configuration: submarketBreakdown1Chart,
              },
              submarketBreakdown2ChartData: {
                ...houstonOfficeStatsChartData.submarketBreakdown2ChartData
                  .data,
                configuration: submarketBreakdown2Chart,
              },
              annualAbsorptionChartData: {
                ...houstonOfficeStatsChartData.annualAbsorptionChartData.data,
                configuration: annualAbsorptionChart,
              },
              getMarketMaster:
                camelcaseKeys(
                  houstonOfficeStatsChartData.getMarketMaster.data
                ) || [],
              marketFilter:
                houstonOfficeStatsChartData.getMarketMaster.data.filter(
                  (x) => x.marketId == selectedMarketId
                )[0].market,
              specSubmarket: "CBD",
            });
            //this.forceUpdate();
          } else {
            ShowToast(
              "Something went wrong",
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  getRequiredData2 = (selectedMarketId, env, reportConfig) => {
    if (selectedMarketId != "") {
      const submarketSpecificChart = reportConfig.reports.find(
        (x) => x.reportName == "specificSubmarket"
      );
      const developmentPipelineChart = reportConfig.reports.find(
        (x) => x.reportName == "developmentPipeline"
      );
      const quaterAndMonthlyResearchChart = reportConfig.reports.find(
        (x) => x.reportName == "quaterAndMonthlyResearch"
      );
      const ytdAbsorptionSubmarketChart = reportConfig.reports.find(
        (x) => x.reportName == "ytdAbsorptionSubmarket"
      );

      const covidSubAbsChart = reportConfig.reports.find(
        (x) => x.reportName == "covidSubAbs"
      );
      this._asyncRequest = apiBatchRequestWithoutLoader([
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "developmentPipelineChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: developmentPipelineChart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "quaterAndMonthlyResearchChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: quaterAndMonthlyResearchChart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "ytdAbsorptionSubmarketChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: ytdAbsorptionSubmarketChart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "covidSubAbsChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: covidSubAbsChart.reportId,
          },
        },
        {
          url: API_URL.powerBi.houstonOfficeStats,
          key: "submarketSpecificChartData",
          params: {
            groupId: reportConfig.groupId,
            reportId: submarketSpecificChart.reportId,
          },
        },
      ])
        .then((developmentPipelineChartData) => {
          this._asyncRequest = null;
          if (
            developmentPipelineChartData.developmentPipelineChartData.success ==
            true
          ) {
            this.getRequiredDataWithNoLoader(selectedMarketId);
            this.setState({
              developmentPipelineChartData: {
                ...developmentPipelineChartData.developmentPipelineChartData
                  .data,
                configuration: developmentPipelineChart,
              },
              quaterAndMonthlyResearchChartData: {
                ...developmentPipelineChartData
                  .quaterAndMonthlyResearchChartData.data,
                configuration: quaterAndMonthlyResearchChart,
              },
              ytdAbsorptionSubmarketChartData: {
                ...developmentPipelineChartData.ytdAbsorptionSubmarketChartData
                  .data,
                configuration: ytdAbsorptionSubmarketChart,
              },
              submarketSpecificChartData: {
                ...developmentPipelineChartData.submarketSpecificChartData.data,
                configuration: submarketSpecificChart,
              },

              covidSubAbsChartData: {
                ...developmentPipelineChartData.covidSubAbsChartData.data,
                configuration: covidSubAbsChart,
              },
            });
            //this.forceUpdate();
          } else {
            ShowToast(
              "Something went wrong",
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  getRequiredDataWithNoLoader = (selectedMarketId) => {
    this._asyncRequest = apiBatchRequestWithoutLoader([
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 2, marketId: selectedMarketId },
        fromCache: true,
        duration: 60,
      },
      {
        url: API_URL.Master.buildingMasterData,
        key: "getBuildingClass",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getSubMarketMaster) => {
        this._asyncRequest = null;
        if (getSubMarketMaster.getSubMarketMaster.success == true) {
          this.setState({
            getSubMarketMaster:
              camelcaseKeys(getSubMarketMaster.getSubMarketMaster.data) || [],

            getBuildingClass:
              camelcaseKeys(getSubMarketMaster.getBuildingClass.data) || [],
            submarket: [
              ...new Set(
                camelcaseKeys(getSubMarketMaster.getSubMarketMaster.data).map(
                  (item) => item.subMarket
                )
              ),
            ],
            buildingclass: [
              ...new Set(
                camelcaseKeys(getSubMarketMaster.getBuildingClass.data).map(
                  (item) => item.buildingClass
                )
              ),
            ],
            enableFilterIcon: false,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPropertyKey != this.props.selectedPropertyKey) {
      this.setState({ selectedPropertyKey: nextProps.selectedPropertyKey });
    }
    if (nextProps.isOpsMTDView != this.props.isOpsMTDView) {
      this.setState({ isOpsMTDView: nextProps.isOpsMTDView });
    }
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
      this.setState({ selectedValue: nextProps.globalMarketId });
    }
  }

  onChangeGCRadio = (currentSelection) => {
    if (currentSelection === "mtdRadio") {
      this.setState({
        sliderRadioSelected: currentSelection,
        showMonthly: true,
        showYtd: false,
        showYearly: false,
      });
    } else if (currentSelection === "ytdRadio") {
      this.setState({
        sliderRadioSelected: currentSelection,
        showMonthly: false,
        showYtd: true,
        showYearly: false,
      });
    } else if (currentSelection === "yearRadio") {
      this.setState({
        sliderRadioSelected: currentSelection,
        showMonthly: false,
        showYtd: false,
        showYearly: true,
      });
    }
  };

  onChangeMonthRange = (startMonth, endMonth) => {
    const startDate = moment(getDate(startMonth));
    const endDate = moment(getDate(endMonth));

    //console.log(convertToChartDate(getPrevNextDate(getDate(moment(startDate.format("YYYYMM01"))))))
    //console.log(convertToChartDate(endDate.format("YYYYMM01")))
    this.setState({
      fromDate: startDate.format("YYYYMM01"),
      toDate: endDate.format("YYYYMM01"),
    });
  };

  handleChange = (e, value, name) => {
    if (name === "specSubmarket") {
      // if (value.length == 0)
      //   this.setState({
      //     specSubmarket: ["CBD"]
      //   });
      // else {
      //   let sm = [];
      //   for (let i = 0; i < value.length; i++) {
      //     sm.push(value[i].subMarket);
      //   }
      //   this.setState({ specSubmarket: sm });
      // }
      if (value) {
        let sm = [];
        sm.push(value.subMarket);
        this.setState({ specSubmarket: value.subMarket });
      } else {
        this.setState({ specSubmarket: "CBD" });
      }
    } else if (name === "subMarket") {
      if (value.length == 0)
        this.setState({
          submarket: [
            ...new Set(
              this.state.getSubMarketMaster.map((item) => item.subMarket)
            ),
          ],
        });
      else {
        let sm = [];
        for (let i = 0; i < value.length; i++) {
          sm.push(value[i].subMarket);
        }
        this.setState({ submarket: sm });
      }
    } else if (name === "buildingClass") {
      if (value.length == 0)
        this.setState({
          buildingclass: [
            ...new Set(
              this.state.getBuildingClass.map((item) => item.buildingClass)
            ),
          ],
        });
      else {
        let buildingClass = [];
        for (let i = 0; i < value.length; i++) {
          buildingClass.push(value[i].buildingClass);
        }
        this.setState({ buildingclass: buildingClass });
      }
    } else if (name === "market") {
      if (value === null) this.setState({ selectedValue: null });
      else {
        this.setState({ selectedValue: value.marketId });
      }
    }
  };

  handleDateChange = (date, name, mode) => {
    if (moment(date).isValid()) {
      if (mode === "mn") {
        if (name === "startDate") {
          this.setState({
            fromDate: moment(date).format("YYYYMM01"),
            [name]: moment(date).format(DateFormat),
          });
        } else if (name === "endDate") {
          this.setState({
            toDate: moment(date).format("YYYYMM01"),
            [name]: moment(date).format(DateFormat),
          });
        }
      } else if (mode === "yr") {
        const selectedAsOfDateYear = date.getFullYear();
        const sd = moment(getDate(`1/1/${selectedAsOfDateYear}`));
        const ed = moment(getDate(`12/31/${selectedAsOfDateYear}`));
        //console.log(sd, ed);
        this.setState({
          fromDate: sd.format("YYYYMM01"),
          toDate: ed.format("YYYYMM01"),
          startDate: moment(sd).format(DateFormat),
          [name]: moment(date).format(DateFormat),
        });
      } else if (mode === "yt") {
        // const currentYear =  moment().year();
        // const sd = moment(getDate(`1/1/${selectedAsOfDateYear}`));
        // const ed = selectedAsOfDateYear === currentYear ?  moment() :  moment(getDate(`12/31/${selectedAsOfDateYear}`));

        const selectedAsOfDateYear = date.getFullYear();
        const sd = moment(getDate(`1/1/${selectedAsOfDateYear}`));
        const ed = moment(date);
        //console.log(sd, ed);
        this.setState({
          fromDate: sd.format("YYYYMM01"),
          toDate: ed.format("YYYYMM01"),
          startDate: moment(sd).format(DateFormat),
          [name]: moment(date).format(DateFormat),
        });
      } else if (mode === "fulldate") {
        let startDateForImport = moment(date) //.utc()
        //  .startOf("month")
          .format(DateFormat);
        console.log("startDateForImport", startDateForImport);
        this.setState({ importMonth: startDateForImport });
      }
    }
  };

  enableFilter = () => {
    if (this.state.enableFilterIcon === true) {
      //let openFilter = this.state.openFilter;
      this.setState({ openFilter: !this.state.openFilter });
    }
  };
  handleTabChange = (e, value, name, current) => {
    if (name != current) {
      this.setState({
        [name]: true,
        [current]: false,
        currentSeletedTab: name,
      });
    }
  };
  enableClear = () => {
    // this.getMarketData();
    this.setState({
      clearImport: true,
      isClearClicked: true,
      openDialog: false,
    });
  };
  onCancel = () => {
    this.setState({
      openDialog: false,
     // openExportDialog: false,
    });
  };
  // enableExport = () => {
  //   this.state.selectedMarket = this.state.selectedMarketId.toString();
  //   this.state.selectedSubMarket = null;
  //   this.state.selectedProduct = null;
  //   this.setState({
  //     openExportDialog: true,
  //   });
  // };
  enableDownload = () => {
    this.state.selectedMarket = this.state.selectedMarketId.toString();
    this.state.selectedSubMarket = null;
    this.state.selectedProduct = null;
    this.setState({
      openDialog: true,
    });
    //downloadExcelFile('src//public//ImportTemplate//MarketStats_ImportTemplate.xlsx');
  };

  downloadTemplate = () => {
    //downloadExcelFile('src//components//Dashboard//PropertyOwnersExport.xlsx','MarketStats_ImportTemplate');
    downloadExcelFile(
      "public//ImportTemplate//MarketStats_ImportTemplate.xlsx",
      "MarketStats_ImportTemplate"
    );
  };
  importFile = () => {
    this.setState({ isUpload: true, openDialog: false });
  };
  onFileChange = (event) => {
    // Update the state

    this.setState({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
      selectedFileSize: event.target.files[0].size,
    });
    this.getImportFileDetails();
  };
  handleCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    //return this.state.listOfHeader.includes(val);
    return this.state.listOfHeader.some(
      (item) => item.toLowerCase() == val.toLowerCase()
    );
  }
  handleRequiredFieldCheck(val) {
    //return this.state.listOfHeader.some((item) => item === val);
    return this.state.lstRequiredField.includes(val);
  }
  updateHeaderColumn = (headers) => {
    //const newList = headers.splice(headers.indexOf("ID"), 1);
    this.setState({
      listOfHeader: headers.sort(),
    });
  };

  getUIColumn = (val) => {
    return this.state.getImportfileData.find(
      (x) => x.dbColumn.toLowerCase() == val.toLowerCase()
    ).uiColumn;
    //return this.state.listOfHeader.some((item) => item === val);
  };
  validateExcelFile = (data, uploadedFileName) => {
    const uploadingKey = generateString(6);
    this.setState({ uploadingKey: uploadingKey });
    setDownloadingStatus(
      uploadingKey,
      uploadedFileName,
      "processing",
      "",
      "Processing import file"
    );
    this.setState({ upKey: uploadingKey, upfileName: uploadedFileName });
    this.setState({ breakCondition: false });
    let isSubletNull = false;
    let errorMessage = "";
    const workbook = XLSX.read(data, {
      type: "binary",
      cellDates: true,
      cellText: false,
    });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_row_object_array(
      workbook.Sheets[firstSheet],
      { defval: null, raw: false, dateNF: "mm/dd/yyyy" }
    );
    let invalidDate = false;
    const excelData = excelRows.filter((m) => {
      //m.PropertyID = m.PropertyID;
      m.Date = moment(this.state.importMonth).startOf(month).format(DateFormatImport);
      //moment(m.Date).startOf(month).format(DateFormat);
      if (
        !moment(m.Date, DateFormat, true).isValid() &&
        this.state.breakCondition === false
      ) {
        this.setState({ breakCondition: true });
        invalidDate = true;
      }
      return m;
    });
    if (invalidDate === true) {
      errorMessage =
        "Please fill valid data in field: [Date]. Please re-import the file with valid data";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    let headers = [];

    if (excelData.length == 0) {
      this.setState({ breakCondition: true });
      //ShowToast("The import file does not contain a column with same market as selected for clear.Please re-import the file with correct value included", toastType.ERROR, 5000);
      errorMessage =
        "The import file is empty. Please upload file with valid data";
      setDownloadingStatus(
        uploadingKey,
        uploadedFileName,
        "failed",
        errorMessage,
        "Validation Failure"
      );
      return false;
    }
    let dateData = excelData[0].Date;
    var check = moment(dateData, DateFormat);
    var month = check.format("M");
    var year = check.format("Y");
    var startDate = moment([year, month - 1]).format(DateFormat);
    //alert(startDate);
    this.setState({ importMonth: startDate });
    // console.log('startDate', startDate);

    // if (this.state.isClearClicked == true && excelData[0]["Stream Market"] != this.state.market) {
    //   this.setState({ breakCondition: true, });
    //   //ShowToast("The import file does not contain a column with same market as selected for clear.Please re-import the file with correct value included", toastType.ERROR, 5000);
    //   errorMessage = "The import file does not contain a column with same market as selected for clear.Please re-import the file with correct value included";
    //   setDownloadingStatus(uploadingKey, uploadedFileName, "failed", errorMessage, "Validation Failure");
    //   return false;
    // }
    excelData.forEach(function (e, i) {
      // Iterate over the keys of object
      Object.keys(e).forEach(function (key) {
        // Copy the value
        i == 0 ? headers.push(key) : null;
        // var val = e[key],
        //   newKey = key.replace(/\s+/g, "_").replace('(', '').replace(')', '');
        // //if (val != undefined) {
        // delete excelData[i][key];
        // excelData[i][newKey] = val.toString();
        //}
      });
    });
    this.updateHeaderColumn(headers);
    let count = this.state.listOfColumns.length;
    let isColumnPresent = true;
    for (var i = 0; i < count; i++) {
      if (this.state.breakCondition != true)
        isColumnPresent = this.handleCheck(this.state.listOfColumns[i]);
      if (isColumnPresent == false) {
        this.setState({ breakCondition: true });
        //ShowToast("The import file does not contain a column specifiying the following data: [" + this.state.listOfColumns[i] + "].Please re-import the file with this column included", toastType.ERROR, 5000, 500);
        errorMessage =
          "The import file does not contain a column specifiying the following data: [" +
          this.state.listOfColumns[i] +
          "].Please re-import the file with this column included";

        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          errorMessage,
          "Validation Failure"
        );
        return false;
      }
    }
    excelData.forEach((m) => {
      if (m.RBA == null && this.state.breakCondition == false) {
        this.setState({ breakCondition: true });
        //ShowToast("The import file does not contain rentable building areas for all imported buildings. Please re-import the file with RSF included for all buildings to be included in analysis.", toastType.ERROR, 5000, 500);

        errorMessage =
          "The import file does not contain rentable building areas for all imported buildings. Please re-import the file with RBA included for all buildings to be included in analysis.";
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          errorMessage,
          "Validation Failure"
        );
        return false;
      }
      if (m["Total Sublet Space (SF)"] == null) {
        m["Total Sublet Space (SF)"] = "0";
        isSubletNull = true;
      }
      if (m.Date != dateData && this.state.breakCondition == false) {
        this.setState({ breakCondition: true });
        //ShowToast("The import process is limited to updating a single import timeframe. Please re-import the file and include only one specified date in the Import Date column", toastType.ERROR, 5000, 500);

        errorMessage =
          "The import process is limited to updating a single import timeframe. Please re-import the file and include only one specified date in the Import Date column";
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          errorMessage,
          "Validation Failure"
        );
        return false;
      }
    });

    if (this.state.breakCondition == false) {
      if (isSubletNull == true) {
        excelData.forEach((ele) => {
          ele.Date = startDate;
        });
      }
      //let validatioError=false;
      excelData.forEach((obj) => {
        if (this.state.breakCondition == false) {
          for (var i = 0; i < this.state.listOfDbColumn.length; i++) {
            let oldColName = this.state.listOfHeader[i];
            let newColName = this.getDbColumn(this.state.listOfHeader[i]);
            renameKey(obj, oldColName, newColName);

            // renameKey(obj, ["PropertyId", "PropertyID", "propertyid"], "CostarId");
            // renameKey(obj, "Total_Vacant_Avail_Relet_Space_SF", "VacantSF");
            // renameKey(obj, "RSF", "RSF");
            // renameKey(obj, "Date", "MonthRecorded");
            // renameKey(obj, "Total_Sublet_Space_SF", "SubletSF");
            // renameKey(obj, ["Stream_Market","stream_market","Stream_market"], "Market");
            // renameKey(obj, ["Stream_SubMarket","Stream_Submarket","stream_submarket"], "SubMarket");
            let currentDate = excelData[0].MonthRecorded;
            var check = moment(currentDate, DateFormat);
            var month = check.format("M");
            var year = check.format("Y");
            var startDate = moment([year, month - 1]).format(DateFormat);
            if (startDate != currentDate) {
              obj.MonthRecorded = startDate;
            }
            let returnObj = checkFileValidation(
              obj[this.state.listOfDbColumn[i]],
              this.state.getImportfileData[i].dataFormat
            );
            if (returnObj === false) {
              //ValidationError=true;
              this.setState({ breakCondition: true });
              errorMessage =
                "Please fill valid data in field: [" +
                this.state.getImportfileData[i].uiColumn +
                "].Please re-import the file with valid data";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              return false;
            }
          }
          for (var i = 0; i < this.state.lstRequiredField.length; i++) {
            if (
              obj[this.state.lstRequiredField[i]] == null &&
              this.state.breakCondition == false
            ) {
              this.setState({ breakCondition: true });
              errorMessage =
                "The import file does not contain data for the following column: [" +
                this.getUIColumn(this.state.lstRequiredField[i]) +
                "].Please re-import the file with data for this column";
              setDownloadingStatus(
                uploadingKey,
                uploadedFileName,
                "failed",
                errorMessage,
                "Validation Failure"
              );
              return false;
            }
          }
        }
      });
      // if(validatioError == true)
      // {
      //   setDownloadingStatus(
      //     uploadingKey,
      //     uploadedFileName,
      //     "failed",
      //     errorMessage,
      //     "Validation Failure"
      //   );
      // }
      this.setState({
        formattedData: excelData,
      });
      if (this.state.isClearClicked != true) {
        //this.saveImport(excelData, uploadingKey, uploadedFileName);
      } else {
        this.setState({
          enableClear: true,
        });
      }
    }
    return true;
  };

  saveImport = (formattedData, uploadingKey, uploadedFileName) => {
    formattedData[formattedData.length] = this.state.downloadFileUrl;
    formattedData[formattedData.length] = uploadedFileName;
    apiPostNoLoader({
      url: API_URL.ImportExport.AddFileImportDetailIndustrial,
      params: { marketId: this.state.selectedValue },
      postBody: formattedData,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            importMonth: null,
            selectedFile: null,
            fileData: [],
            uploadingKey: null,
            uploadedFileName: null,
          });
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "completed",
            "",
            "Upload Complete"
          );
          const downloadingKey = generateString(6);
          const fileName = "ImportResult" + fileDateAppender();
          setDownloadingStatus(
            downloadingKey,
            fileName,
            "downloading",
            "",
            "Processing"
          );
          downloadImportFileData(response, fileName);
          setDownloadingStatus(
            downloadingKey,
            fileName,
            "completed",
            "",
            "Import Process Complete"
          );
        } else {
          setDownloadingStatus(
            uploadingKey,
            uploadedFileName,
            "failed",
            response.message,
            "Import Process Failed"
          );
        }
      })
      .catch(() => {
        setDownloadingStatus(
          uploadingKey,
          uploadedFileName,
          "failed",
          "",
          "Import Process Failed"
        );
      });
  };
  // On file upload (click the upload button)
  onFileUpload = (isClear = false) => {
    if (
      this.state.selectedFile == null ||
      this.state.selectedFile == undefined
    ) {
      ShowToast("Please select a file before upload.", toastType.ERROR, 5000);
    } else if (
      this.state.selectedValue == null ||
      this.state.selectedValue == undefined ||
      this.state.selectedValue == ""
    ) {
      ShowToast("Please select market before import.", toastType.ERROR, 5000);
    } else if (
      this.state.importMonth == null ||
      this.state.importMonth == undefined ||
      this.state.importMonth == ""
    ) {
      ShowToast("Please select date before import.", toastType.ERROR, 5000);
    } else {
      // Create an object of formData
      const formData = new FormData();
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            if (
              this.validateExcelFile(
                reader.result,
                this.state.selectedFileName
              ) &&
              this.state.breakCondition == false
            ) {
              if (isClear == true) {
                this.ClearImportData();
              }
              this.getSpsData();
            }
          };
          reader.readAsBinaryString(this.state.selectedFile);
        }
      }
      this.setState({ isUpload: false, openDialog: false });
    }
  };

  getImportFileDetails = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.ImportExport.getImportfileData,
        key: "getImportfileData",
        params: { sectionId: 211 },
        fromCache: true,
        duration: 60,
      },
    ])
      .then((getImportfileData) => {
        this._asyncRequest = null;
        if (getImportfileData.getImportfileData.success == true) {
          let temp = [];
          let dbColumn = [];
          let requiredColumn = [];
          getImportfileData.getImportfileData.data
            .sort((a, b) => (a.UIColumn > b.UIColumn ? 1 : -1))
            .filter((m) => {
              if (m.IsVisible == 1) {
                temp.push(m.UIColumn);
                dbColumn.push(m.DBColumn);
              }
              if (m.IsRequired == 1) {
                requiredColumn.push(m.DBColumn);
              }
            });

          this.setState({
            getImportfileData: camelcaseKeys(
              getImportfileData.getImportfileData.data
            ).sort((a, b) => (a.uiColumn > b.uiColumn ? 1 : -1)),
            listOfColumns: temp.sort(),
            listOfDbColumn: dbColumn,
            lstRequiredField: requiredColumn.sort(),
            // listOfColumns: getImportfileData.getImportfileData.data.filter(
            //   (m) => {
            //     if (m.isVisible == true) return
            //     {
            //       m.UIColumn;
            //     }
            //   }
            // ),
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something Went Wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };
  handleClearChange = (event, value) => {
    this.setState({
      market: value,
    });
  };
  getDbColumn(val) {
    let dbColumn = this.state.getImportfileData.find(
      (x) => x.uiColumn.toLowerCase() == val.toLowerCase()
    ).dbColumn;
    //return this.state.listOfHeader.some((item) => item === val);
    return dbColumn;
  }
  acceptClearImport = () => {
    this.setState({
      openConfirmDialog: true,
      clearImport: false,
      isClearClicked: true,
    });
  };

  onClickHandel = (e) => {
    if (e == true) {
      this.setState({
        openConfirmDialog: false,
      });
	  this.ClearImportData();
    //  this.onFileUpload(true);
    } else {
      this.setState({
        openConfirmDialog: false,
        isClearClicked: false,
        enableClear: false,
      });
    }
  };
  ClearImportData = () => {
    const ClearKey = generateString(6);

    //const clearFileName =
    //  "Clear " +
    //  moment(this.state.importMonth).startOf("month").format("MMMYY") +
    //  " data";
    //const startOfMonth = moment().startOf("month").format("MM-DD-YYYY");
    if (this.state.market != null) {
      setDownloadingStatus(
        //ClearKey,
        //clearFileName,
		"",
		moment(this.state.importMonth).format("MMMM-YYYY"),
        "processing",
        "",
        "Clearing Data"
      );
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.ImportExport.clearImportIndustrial,
          key: "clearImport",
          params: {
            market: this.state.market,
            monthRecorded: moment(this.state.importMonth).startOf("month").format(DateFormatImport),
          },
        },
      ])
        .then((clearImport) => {
          this._asyncRequest = null;
          this.setState({
            openConfirmDialog: false,
            isClearClicked: false,
            enableClear: false,
            importMonth: null,
            // selectedFile: null,
            // fileData: [],
            // uploadingKey: null,
            // uploadedFileName: null,
          });
          if (clearImport.clearImport.success == true) {
            // ShowToast(
            //   "Cleared imported data successfully",
            //   toastType.SUCCESS,
            //   5000
            // );

            setDownloadingStatus(
            //  ClearKey,
            //  clearFileName,
			"",
			moment(this.state.importMonth).format("MMMM-YYYY"),
              "completed",
              "",
              "Data Cleared"
            );
            //this.saveImport(this.state.formattedData, this.state.upKey, this.state.upfileName);
          } else {
            setDownloadingStatus(
            //  ClearKey,
            //  clearFileName,
			"",
			moment(this.state.importMonth).startOf("month").format("MMMM-YYYY"),
              "failed",
              "",
              "Data Clear"
            );
            // ShowToast(
            //   "Something went wrong",
            //   toastType.ERROR,
            //   5000,
            //   500,
            //   "Server error"
            // );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    } else {
      ShowToast("Please select market.", toastType.ERROR, 5000);
    }
  };
  cancelClearImport = () => {
    this.setState({
      clearImport: false,
      isClearClicked: false,
      enableClear: false,
    });
  };
  exportData = () => {
    this.state.selectedMarket = this.state.selectedMarketId.toString();
    this.state.selectedSubMarket = null;
    this.state.selectedProduct = null;
    this.setState({
      dataExport: true,
      openDialog: false,
    });
  };
  cancelExport = () => {
    this.setState({
      dataExport: false,
    });
  };
  exportImportedData = () => {
    this.setState({ dataExport: false });
    const fileNameMonth = moment(this.state.selectedTimeFrame)
      .startOf("month")
      .format("MMMYY");
    downloadStatsExcelReport(
      API_URL.SSRSExport.IndustrialStats,
      "MarketStatsExport_" + fileNameMonth + ".xls",
      this.state.selectedMarket,
      this.state.selectedSubMarket,
      "2",
      moment(this.state.selectedTimeFrame).startOf("month").format("MM-DD-YYYY")
    );
  };
  handleDateChangeForExport = (value) => {
    this.setState({
      selectedTimeFrame: value,
    });
  };
  handleProductChange = (e, value) => {
    let x = "";

    if (value === "Office") x = "1";
    else if (value === "Industrial") x = "2";
    this.setState({
      selectedProduct: x,
    });
  };
  handleSubMarketChange = (e, value) => {
    let x = this.state.getSubMarketMaster.filter(
      (s) => s.subMarket === value
    )[0].subMarketId;
    this.setState({
      selectedSubMarket: x.toString(),
    });
  };

  handleMarketChange = (e, value) => {
    let x = this.state.getMarketMaster.filter((m) => m.market === value)[0]
      .marketId;
    this.setState({
      selectedMarket: x.toString(),
    });
  };
  onClearFileChange = () => {};
  onClearFileUpload = () => {};

  setCategoryWiseData = (spsDataRes) => {
    if (!spsDataRes) return;
    const { value } = _.cloneDeep(spsDataRes);
    const spsCategories = {
      Property: [],
      OtherDocs: [],
    };
    const categoriesKeysList = Object.keys(spsCategories);
    value &&
      value.forEach((obj) => {
        const catKeyByName =
          categoriesKeysList.indexOf(obj.name.split("_")[1]) !== -1
            ? obj.name.split("_")[1]
            : "";
        const caseKey = obj.listItem.fields.FileType || "" || catKeyByName;
        switch (caseKey) {
          case "Property":
            spsCategories[caseKey].push(obj);
            break;
          default:
            spsCategories.OtherDocs.push(obj);
            break;
        }
      });
    this.setState({
      spsData: spsCategories,
      spsAllData: spsDataRes,
      reloadBuilding: !this.state.reloadBuilding,
    });
  };

  getUploadedFile = (file, category) => {
    let { filesToBeUploaded } = this.state || [];
    filesToBeUploaded =
      (filesToBeUploaded || []).filter((x) => x.category !== category) || [];
    filesToBeUploaded.push({ category: category, file: file });
    this.setState({
      filesToBeUploaded,
      reloadBuilding: !this.state.reloadBuilding,
    });
  };

  cleanName = (name) => {
    name = (name || "").replace(/\s+/gi, "-"); // Replace white space with dash
    return name.replace(/[^a-zA-Z0-9]/gi, ""); // Strip any special charactere
  };

  getSpsData = () => {
    let env = getCurrentEnvironment().sharePointLibrary;
    if (getSPSTokenData()) {
      this.setState({ spsData: {} });
      getSpsAPiRequest(getSharePointUrl("streamrealty", "getFolder")).then(
        (res) => {
          const fileName = this.state.selectedFileName;
          const folderName = env;
          if (
            res.value !== null &&
            res.value.length > 0 &&
            res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            ) !== undefined
          ) {
            const folder = res.value.find(
              (x) => x.displayName === folderName || x.name === folderName
            );
            const driveId = folder.parentReference.driveId;
            const key = folder.id;
            this.setState({ spListkey: key });
            this.uploadFilesToSharePoint();
          } else {
            if (folderName.length > 0) {
              spsApiPost({
                url: getSharePointUrl("streamrealty", "createFolder"),
                postBody: {
                  displayName: folderName,
                  columns: [
                    {
                      name: "FileType",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                    {
                      name: "UserEmail",
                      text: {},
                    },
                  ],
                  list: {
                    template: "documentLibrary",
                  },
                },
              }).then((data) => {
                if (data) {
                  this.getSpsData();
                }
              });
            }
          }
        }
      );
    } else {
      apiRequest({ url: API_URL.powerBi.AcquireSPToken }).then(({ data }) => {
        if (data) {
          setSPSToken(data);
          this.getSpsData();
        }
      });
    }
  };

  uploadFilesToSharePoint() {
    //        this.state.filesToBeUploaded.forEach(x => {
    let d = new Date();
    let dformat = `${
      d.getMonth() + 1
    }${d.getDate()}${d.getFullYear()}_${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    let fileName = this.state.selectedFileName.split(".")[0];
    let ext = this.state.selectedFileName.split(".")[1];
    fileName = fileName + "_" + dformat + "." + ext;
    spsApiPost({
      url: getSharePointUrl(
        "streamrealty",
        "uploadFileSession",
        this.state.spListkey,
        fileName,
        "MarketStats"
      ),
      postBody: {
        fileSize: this.state.selectedFileSize, //x.file.size,
        item: {
          "@microsoft.graph.conflictBehavior": "replace",
        },
      },
    }).then((data) => {
      if (data) {
        uploadSpsAPiRequest(
          data.data.uploadUrl,
          this.state.selectedFile,
          true
        ).then((data) => {
          if (data) {
            ShowToast(
              "File uploaded successfully on share point.",
              toastType.SUCCESS
            );
            this.setState({
              selectedFile: null,
              downloadFileUrl: data["@content.downloadUrl"],
            });
            // console.log(this.state.downloadFileUrl);
            if (
              this.state.breakCondition == false &&
              this.state.downloadFileUrl != null
            ) {
              this.saveImport(
                this.state.formattedData,
                this.state.uploadingKey,
                fileName
              );
            }
          } else {
            let errorMessage =
              "The import file could not be uploaded to the server.";
            setDownloadingStatus(
              this.state.uploadingKey,
              this.state.selectedFileName,
              "failed",
              errorMessage,
              "Processing Failure"
            );
          }
          //                this.props.onViewChange();
        });
      }
    });
    //        });
  }

  getList = (fileName, emailId, x) => {
    getSpsAPiRequest(
      getSharePointUrl("streamrealty", "getSps", this.state.spListkey)
    ).then((spsDataRes) => {
      if (spsDataRes) {
        const { value } = spsDataRes;
        for (let i = 0; i < value.length; i++) {
          if (fileName === value[i].name) {
            this.updateMetaData(value[i].listItem.id, emailId, x.category);
            break;
          }
        }
      }
    });
  };

  updateMetaData = (itemid, emailId = "", category) => {
    const metadata = {
      FileType: category,
      UserEmail: emailId,
    };
    uploadSpsMetadataAPiRequest(
      getSharePointUrl(
        "streamrealty",
        "uploadMetadata",
        this.state.spListkey,
        itemid
      ),
      metadata
    ).then(() => {
      // this.setState({ filesToUpload: [], selectedFile: [] });
      // this.getSpsData();
    });
  };

  getBase64Content = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error);
    };
  };

  viewFileData = async (obj, title) => {
    let url = "";
    if (obj.uploadToSp) {
      const { filesToBeUploaded, spsData } = this.state;
      const file = (filesToBeUploaded || []).find(
        (x) => x.category === obj.category
      );
      if (file && file.file) {
        this.getBase64Content(file.file, (result) => {
          // console.log(result);
          url = result;
          this.showFile(url, title);
        });
      } else if (
        spsData &&
        spsData[obj.category] &&
        spsData[obj.category].length > 0
      ) {
        const spFile = _.orderBy(
          spsData[obj.category].filter((x) => x.name === title) || [],
          ["createdDateTime"],
          ["desc"]
        );
        if (spFile && spFile[0]) {
          this.showFile(
            await this.getSpBlob(spFile[0]["@microsoft.graph.downloadUrl"]),
            title,
            true
          );

          // this.generateShareLinkAndShow(spFile);
        } else {
          ShowToast("File not found!", toastType.INFO, 2000, 2000);
        }
      } else {
        ShowToast("File not found!", toastType.INFO, 2000, 2000);
      }
    } else {
      url = getSectionAttributeValueByKey(
        this.state.getBuildingDetails,
        obj.contentKey
      );
      this.showFile(url, title);
    }
  };

  getSpBlob = (url) => {
    return new Promise(function (resolve, reject) {
      fetch(url)
        .then((response) => response.blob())
        .then(function (blob) {
          const dataUrl = URL.createObjectURL(blob);
          return resolve(dataUrl);
        })
        .catch(() => {
          return resolve("");
        });
    });
  };

  b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  showFile = (url, title, isSpsFile = false) => {
    const contentType = "application/pdf";
    let blobUrl;
    if (isSpsFile === false) {
      const blob = this.b64toBlob(
        url.replace("data:application/pdf;base64,", ""),
        contentType
      );
      blobUrl = URL.createObjectURL(blob);
    } else {
      blobUrl = url;
    }
    this.setState({
      openaddform: true,
      addformtitle: title,
      addNewformComponent: (
        <div style={{ width: "800px", height: "530px", overflow: "hidden" }}>
          {/* <iframe src={url} width="700" height="700"></iframe> */}
          {/* <embed src={`data:application/pdf;base64,${url}`} type="application/pdf" width="100%"></embed> */}
          <iframe
            id="iframe"
            onLoad={(ev) => {
              const x = document.getElementById("iframe");
              (
                ((x.contentWindow || {}).document.getElementsByTagName(
                  "embed"
                ) || [])[0] || {}
              ).style.height = "500px";
            }}
            src={blobUrl}
            style={{
              width: "100%",
              height: "100%",
              overflow: "hidden",
              display: "block",
            }}
            frameBorder="0"
            allowFullScreen={true}
          ></iframe>
        </div>
      ),
    });
  };

  render() {
    const {
      onSaveClick,
      tableAllData,
      isOpsEditable,
      selectedDate,
      isLoading,
      userHasEditPermission,
      domainName,
    } = this.props;
    const {
      houstonOfficeStatsChartData,
      submarketBreakdown1ChartData,
      submarketBreakdown2ChartData,
      developmentPipelineChartData,
      quaterAndMonthlyResearchChartData,

      ytdAbsorptionSubmarketChartData,
      annualAbsorptionChartData,
      covidSubAbsChartData,
      fromDate,
      toDate,
      sliderRadioSelected,
      submarket,
      specSubmarket,
      getSubMarketMaster,
      getMarketMaster,
      getBuildingClass,
      buildingclass,
      submarketSpecificChartData,
      startDate,
      endDate,
      openFilter,
      enableFilterIcon,
      marketFilter,
    } = this.state;
    const sourceProduct = [
      { id: 1, product: "Office" },
      { id: 2, product: "Industrial" },
    ];
    const filterConfig = [
      {
        multiple: false,
        type: controlType.DropDown,
        minTag: 1,
        source: this.state.getMarketMaster,
        key: "market",
        placeholder: "",
        title: "Market",
      },
    ];

    const filterConfigForExport = [
      {
        multiple: false,
        type: controlType.DropDown,
        minTag: 1,
        source: this.state.getMarketMaster,
        key: "market",
        placeholder: "",
        title: "Market",
      },
      {
        multiple: false,
        type: controlType.DropDown,
        source: this.state.getSubMarketMaster,
        key: "subMarket",
        placeholder: "",
        title: "Sub Market",
      },
      {
        multiple: false,
        type: controlType.DropDown,
        source: sourceProduct,
        key: "product",
        placeholder: "",
        title: "Select Product",
      },
    ];

	const importMonth = this.state.importMonth == undefined ? "" : moment(this.state.importMonth).add(1, 'days').format(DateFormat);

    return (
      <>
        <div className="row">
          <div className="col-md-10 col-lg-10">
            {openFilter == true && (
              <Grid
                container
                spacing={1}
                className="no-print row bottom-xs report-filter"
              >
                <Grid item xs={12} lg={4}>
                  {/* <Autocomplete
               
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    //value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "market")
                    }
                    options={getMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.market}
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                        <label htmlFor="filled-hidden-label-small">Market</label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Market"
                        />
                      </Box>
                    )}

                  /> */}

                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    //value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "subMarket")
                    }
                    options={getSubMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.subMarket}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Sub Market
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Sub Market"
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    //value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "buildingClass")
                    }
                    options={getBuildingClass}
                    limitTags={2}
                    getOptionLabel={(option) => option.buildingClass}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">Class</label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Class"
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  {/* <div className="slider-radio-group">
              <RadioGroup
                groupName="opsRadio"
                radioArr={radioArr}
                selected={sliderRadioSelected}
                onChangeRadio={this.onChangeOpsRadio}
              />
            </div>
            <MonthSlider onChangeMonthRange={this.onChangeMonthRange}
              selectedDate={new Date().toISOString().slice(0, 300)}
              sliderView={sliderRadioSelected === "yearRadio" ? "year" : sliderRadioSelected}
            /> */}

                  <div className="slider-radio-group">
                    <RadioGroup
                      groupName="gcRadio"
                      radioArr={radioArr}
                      selected={sliderRadioSelected}
                      onChangeRadio={this.onChangeGCRadio}
                    />
                  </div>
                  {this.state.showMonthly == true && (
                    <div className="row">
                      <div className="col-md-5 col-lg-5">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            className="filter-label"
                          >
                            <label htmlFor="filled-hidden-label-small">
                              Year and Month
                            </label>
                            <Grid container>
                              <KeyboardDatePicker
                                style={{
                                  border: "1px solid #cecdcd",
                                  borderRadius: "4px",
                                }}
                                // variant="inline"
                                views={["year", "month"]}
                                margin="normal"
                                id="date-picker-dialog"
                                variant="inline"
                                format="MM/yyyy"
                                name="startDate"
                                autoOk
                                value={startDate}
                                onChange={(e) =>
                                  this.handleDateChange(e, "startDate", "mn")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                className="w-100"
                              />
                            </Grid>
                          </Box>
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-md-5 col-lg-5">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            className="filter-label"
                          >
                            <label htmlFor="filled-hidden-label-small">
                              Year and Month
                            </label>
                            <Grid container>
                              <KeyboardDatePicker
                                style={{
                                  border: "1px solid #cecdcd",
                                  borderRadius: "4px",
                                }}
                                // variant="inline"
                                views={["year", "month"]}
                                margin="normal"
                                id="date-picker-dialog"
                                variant="inline"
                                format="MM/yyyy"
                                name="endDate"
                                value={endDate}
                                autoOk
                                onChange={(e) =>
                                  this.handleDateChange(e, "endDate", "mn")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                className="w-100"
                              />
                            </Grid>
                          </Box>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}

                  {this.state.showYtd == true && (
                    <div className="row">
                      <div className="col-md-5 col-lg-5">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            className="filter-label"
                          >
                            <label htmlFor="filled-hidden-label-small">
                              Year and Month
                            </label>
                            <Grid container>
                              <KeyboardDatePicker
                                style={{
                                  border: "1px solid #cecdcd",
                                  borderRadius: "4px",
                                }}
                                // variant="inline"
                                views={["year", "month"]}
                                margin="normal"
                                id="date-picker-dialog"
                                variant="inline"
                                format="MM/yyyy"
                                name="endDate"
                                value={endDate}
                                autoOk
                                onChange={(e) =>
                                  this.handleDateChange(e, "endDate", "yt")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                className="w-100"
                              />
                            </Grid>
                          </Box>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}

                  {this.state.showYearly == true && (
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Box
                            sx={{ display: "flex", alignItems: "center" }}
                            className="filter-label"
                          >
                            <label htmlFor="filled-hidden-label-small">
                              Year
                            </label>
                            <Grid container>
                              <KeyboardDatePicker
                                style={{
                                  border: "1px solid #cecdcd",
                                  borderRadius: "4px",
                                }}
                                // variant="inline"
                                views={["year"]}
                                margin="normal"
                                id="date-picker-dialog"
                                variant="inline"
                                format="yyyy"
                                name="endDate"
                                value={endDate}
                                autoOk
                                onChange={(e) =>
                                  this.handleDateChange(e, "endDate", "yr")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                className="w-100"
                              />
                            </Grid>
                          </Box>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}

            <div
              className="tabs-nomargin header-container"
              style={{ paddingTop: "7px", paddingRight: "1px" }}
            >
              <div class="scroll">
                <Tooltip title="Market Overview">
                  <a
                    className={
                      this.state.tab1 ? "tab-capsule selected" : "tab-capsule"
                    }
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab1",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Market Overview
                  </a>
                </Tooltip>
                <Tooltip title="Annual Absorption">
                  <a
                    className={
                      this.state.tab19 ? "tab-capsule selected" : "tab-capsule"
                    } //"gc-bar-item gc-button"
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab19",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Annual Absorption
                  </a>
                </Tooltip>
                <Tooltip title="Submarket Overview">
                  <a
                    className={
                      this.state.tab2 ? "tab-capsule selected" : "tab-capsule"
                    }
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab2",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Submarket Overview
                  </a>
                </Tooltip>

                <Tooltip title="Submarket Analysis">
                  <a
                    className={
                      this.state.tab3 ? "tab-capsule selected" : "tab-capsule"
                    }
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab3",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Submarket Analysis
                  </a>
                </Tooltip>

                <Tooltip title="Submarket Breakdown">
                  <a
                    className={
                      this.state.tab5 ? "tab-capsule selected" : "tab-capsule"
                    } //"gc-bar-item gc-button"
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,

                        "tab5",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Submarket Breakdown
                  </a>
                </Tooltip>

                <Tooltip title="Development Pipeline">
                  <a
                    className={
                      this.state.tab4 ? "tab-capsule selected" : "tab-capsule"
                    }
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,

                        "tab4",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Development Pipeline
                  </a>
                </Tooltip>

                <Tooltip title="Quarter & Monthly Research">
                  <a
                    className={
                      this.state.tab17 ? "tab-capsule selected" : "tab-capsule"
                    } //"gc-bar-item gc-button"
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab17",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Quarter & Monthly Research
                  </a>
                </Tooltip>

                <Tooltip title="YTD Absorption - Submarket">
                  <a
                    className={
                      this.state.tab18 ? "tab-capsule selected" : "tab-capsule"
                    } //"gc-bar-item gc-button"
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab18",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    YTD Absorption - Submarket
                  </a>
                </Tooltip>

                <Tooltip title="Sublease Absorption">
                  <a
                    className={
                      this.state.tab20 ? "tab-capsule selected" : "tab-capsule"
                    } //"gc-bar-item gc-button"
                    aria-label={"refreshData"}
                    size="small"
                    onClick={(e, val) =>
                      this.handleTabChange(
                        e,
                        val,
                        "tab20",
                        this.state.currentSeletedTab
                      )
                    }
                  >
                    Sublease Absorption
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-lg-2">
            <PageHeader
              key={"Reports"}
              title={""}
              style={{ color: "#00B0B9" }}
              actionsConfig={[
                {
                  icon: IsSectionVisible(
                    SECTIONS.Ind_Dashboard,
                    SECTIONS.Import_Export_Ind_Stats,
                    SECTIONS.ADD_EDIT
                  ) && (
                    <div style={{ fontSize: "1.5rem", color: "#00B0B9" }}>
                      <Icon icon={importIcon} style={{width:'25px'}}/>
                    </div>
                  ),
                  onClick: this.enableDownload,
                  title: "Import Data",
                },
                {
                  icon: IsSectionVisible(
                    SECTIONS.Ind_Dashboard,
                    SECTIONS.Import_Export_Ind_Stats,
                    SECTIONS.ADD_EDIT
                  ) && (
                    <div style={{ fontSize: "1.5rem", color: "#00B0B9" }}>
                      {<Icon icon={exportIcon} style={{width:'25px'}} />}
                    </div>
                  ),
                  onClick: this.exportData,
                  title: "Export Data",
                },
              ]}
            ></PageHeader>
          </div>
        </div>

        {this.state.openDialog == true && (
          <Dialog open={this.state.openDialog} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar>
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginLeft: "-11px", flex: "1", color: "#FFFFF" }}
                >
                  Manage Data
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ marginTop: "-18px", marginRight: "-30px" }}
                  onClick={() => {
                    this.setState({
                      openDialog: false,
                    });
                  }}
                  aria-label="close"
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <DownloadTemplate
                downloadTemplate={this.downloadTemplate}
                importFile={this.importFile}
                enableClear={this.enableClear}
                onCancel={this.onCancel}
                exportData={this.exportData}
                hideExportClearButton={false}
                isImport={true}
                templateMessage={"Import Market Stats.Click"}
              />
            </div>
          </Dialog>
        )}

        {/* {this.state.openExportDialog == true && (
          <Dialog open={this.state.openExportDialog} maxWidth="lg">
            <AppBar
              style={{ position: "relative", backgroundColor: "#064473" }}
            >
              <Toolbar>
                <Typography
                  variant="subtitle1"
                  className="app-header-white"
                  style={{ marginLeft: "-11px", flex: "1", color: "#FFFFF" }}
                >
                  Manage Data
                </Typography>
                <IconButton
                  edge="start"
                  color="inherit"
                  style={{ marginTop: "-18px", marginRight: "-30px" }}
                  onClick={() => {
                    this.setState({
                      openExportDialog: false,
                    });
                  }}
                  aria-label="close"
                >
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Toolbar>
            </AppBar>
            <div>
              <DownloadTemplate
                isExport={true}
                downloadTemplate={this.downloadTemplate}
                importFile={this.importFile}
                enableClear={this.enableClear}
                onCancel={this.onCancel}
                exportData={this.exportData}
                hideExportClearButton={false}
                templateMessage={"Export Market Stats.Click"}
              />
            </div>
          </Dialog>
        )} */}
        {this.state.isUpload == true && (
          <Dialog open={this.state.isUpload} maxWidth="lg">
            <AppBar
              style={{
                position: "relative",
                backgroundColor: "#064473",
                height: "38px",
              }}
            >
              <div className="row">
                {/* <Typography
                  variant="h1"
                  className="app-header-white"
                  style={{ marginLeft: "10px", flex: "1", color: "#FFFFF" }}
                > */}
                <div
                  className="col-md-6 col-lg-6"
                  style={{ marginTop: "13px" }}
                >
                  <span> Upload File</span>
                </div>
                {/* </Typography> */}
                <div className="col-md-6 col-lg-6">
                  <IconButton
                    style={{
                      float: "right",
                      marginTop: "-6px",
                      marginRight: "-15px",
                    }}
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        isUpload: false,
                      });
                    }}
                    aria-label="close"
                  >
                    <IoMdCloseCircleOutline />
                  </IconButton>
                </div>
              </div>
            </AppBar>
            <div style={{ padding: "8px" }}>
              <Autocomplete
                size="small"
                underlineStyle={{ display: "none" }}
                id="tags-standard"
                //value={this.state.subMarket}
                onChange={(e, val) => this.handleChange(e, val, "market")}
                options={getMarketMaster}
                limitTags={2}
                getOptionLabel={(option) => option.market}
                renderInput={(params) => (
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    className="filter-label"
                  >
                    <label htmlFor="filled-hidden-label-small">
                      Market<span className="span-mandatory">*</span>
                    </label>
                    <TextField
                      {...params}
                      variant="outlined"
                      margin="normal"
                      hiddenLabel
                      placeholder="Market"
                    />
                  </Box>
                )}
              />
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                key={"exportDatePicker_filter"}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center" }}
                  className="filter-label"
                >
                  <label htmlFor="filled-hidden-label-small">
                    Select Date<span className="span-mandatory">*</span>
                  </label>
                  <Grid container>
                    <DatePicker
                      //variant="inline"
                      openTo="year"
                      views={["year", "month"]}
                      //label="Sel"
                      //helperText="Start from year selection"
                      value={this.state.importMonth} // == null ? null : importMonth}
                      maxDate={this.state.endDate}
                      onChange={(event, value) => {
                        this.handleDateChange(event, value, "fulldate");
                      }}
                      emptyLabel="Select Date"
                      // {this.props.handleDateChange}
                    />
                  </Grid>
                </Box>
              </MuiPickersUtilsProvider>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  paddingBottom: "5px !important",
                }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Import File</label>
              </Box>
              <input
                type="file"
                style={{ padding: "24px", backgroundColor: "lightgray" }}
                onChange={this.onFileChange}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <button className="import-button" onClick={this.onFileUpload}>
                Upload
              </button>
            </div>
          </Dialog>
        )}

        {this.state.clearImport == true && (
          <Dialog open={this.state.clearImport} maxWidth="lg">
            <AppBar
              style={{
                position: "relative",
                backgroundColor: "#064473",
                height: "38px",
              }}
            >
              <div className="row" style={{ width: "291px" }}>
                <div
                  className="col-md-9 col-lg-9"
                  style={{ marginTop: "11px" }}
                >
                  <span> Clear Market Stats</span>
                </div>

                <div className="col-md-3 col-lg-3">
                  <IconButton
                    style={{
                      float: "right",
                      marginTop: "-6px",
                      marginRight: "-15px",
                    }}
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        clearImport: false,
                        isClearClicked: false,
                        enableClear: false,
                      });
                    }}
                    aria-label="close"
                  >
                    <IoMdCloseCircleOutline />
                  </IconButton>
                </div>
              </div>
            </AppBar>
            <ClearImport
              handleDateChange={this.handleDateChange}
              acceptClearImport={this.acceptClearImport}
              cancelClearImport={this.cancelClearImport}
              handleClearChange={this.handleClearChange}
              filterConfig={filterConfig}
              sourceData={this.state.getMarketMaster}
              onClearFileChange={this.onFileChange}
              // onClearFileUpload={this.onFileUpload}
              placeholder="Select Market"
              enableClear={this.state.enableClear}
            ></ClearImport>
          </Dialog>
        )}

        {this.state.openConfirmDialog == true && (
          <ConfirmDialog
            IsOpen={this.state.openConfirmDialog}
            Message={
              this.state.importMonth != null &&
              this.state.importMonth != undefined
                ? "This will clear " +
                  moment(this.state.importMonth).startOf("month").format(DateFormatImport) +
                  " data. Do you still want to continue ?"
                : "This will clear data. Do you still want to continue ?"
            }
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandel(isValid);
              } else {
                this.onClickHandel(isValid);
              }
            }}
            AgreeText="Accept"
            DisAgreeText="Cancel"
          ></ConfirmDialog>
        )}
        {this.state.dataExport == true && (
          <Dialog open={this.state.dataExport} maxWidth="lg">
            <AppBar
              style={{
                position: "relative",
                backgroundColor: "#064473",
                height: "38px",
              }}
            >
              <div className="row" style={{ width: "291px" }}>
                <div
                  className="col-md-6 col-lg-6"
                  style={{ marginTop: "15px" }}
                >
                  <span> Export</span>
                </div>

                <div className="col-md-6 col-lg-6">
                  <IconButton
                    style={{
                      float: "right",
                      marginTop: "-6px",
                      marginRight: "-15px",
                    }}
                    edge="start"
                    color="inherit"
                    onClick={() => {
                      this.setState({
                        dataExport: false,
                        //openDialog:false,
                      });
                    }}
                    aria-label="close"
                  >
                    <IoMdCloseCircleOutline />
                  </IconButton>
                </div>
              </div>
            </AppBar>
            <div>
              <ExportData
                exportImportedData={this.exportImportedData}
                cancelExport={this.cancelExport}
                sourceDataForSubMarket={this.state.getSubMarketMaster}
                filterConfigForExport={filterConfigForExport}
                sourceData={this.state.getMarketMaster}
                sourceProduct={sourceProduct}
                handleDateChange={this.handleDateChangeForExport}
                handleMarketChange={this.handleMarketChange}
                handleSubMarketChange={this.handleSubMarketChange}
                handleProductChange={this.handleProductChange}
                placeholder="Select Submarket"
              ></ExportData>
            </div>
          </Dialog>
        )}

        <div className="row">
          <div className="col-md-12 col-lg-12">
            {this.state.tab1 == true && (
              <PowerBIEmbedControl
                key="houstonOfficeStatsChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={houstonOfficeStatsChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}

            {this.state.tab2 == true && (
              <>
                <div className="row">
                  <div className="col-md-4 col-lg-4"></div>
                  <div className="col-md-4 col-lg-4">
                    <Autocomplete
                      size="small"
                      underlineStyle={{ display: "none" }}
                      id="tags-standard"
                      //value={getSubMarketMaster.filter(x => x.subMarket.includes('CBD'))[0]}
                      onChange={(e, val) =>
                        this.handleChange(e, val, "specSubmarket")
                      }
                      options={getSubMarketMaster}
                      limitTags={2}
                      getOptionLabel={(option) => option.subMarket}
                      //defaultValue={this.state.specSubmarket}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Sub Market
                          </label>
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            hiddenLabel
                            placeholder="Sub Market"
                          />
                        </Box>
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4"></div>
                </div>
                <PowerBIEmbedControl
                  key="submarketBreakdown1ChartData"
                  type={2}
                  tokenType={"Aad"} //"Embed" // Embed or "Aad"
                  config={submarketBreakdown1ChartData}
                  style={reportStyle}
                  extraSettings={extraSettings}
                  params={{
                    Market: marketFilter,
                    KeepMarketStats: 1,
                    ProductType: "Industrial",
                    StatusId: 1,
                    Submarket: specSubmarket,
                    ExistingUCId: 1,
                  }}
                />
              </>
            )}

            {this.state.tab3 == true && (
              <PowerBIEmbedControl
                key="submarketBreakdown2ChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={submarketBreakdown2ChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}
            {this.state.tab4 == true && (
              <PowerBIEmbedControl
                key="developmentPipelineChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={developmentPipelineChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  fromDate: convertToChartDate(fromDate),
                  toDate: convertToChartDate(toDate),
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}

            {this.state.tab5 == true && (
              <>
                <div className="row">
                  <div className="col-md-4 col-lg-4"></div>
                  <div className="col-md-4 col-lg-4">
                    <Autocomplete
                      size="small"
                      underlineStyle={{ display: "none" }}
                      id="tags-standard"
                      //value={getSubMarketMaster.filter(x => x.subMarket.includes('CBD'))[0]}
                      onChange={(e, val) =>
                        this.handleChange(e, val, "specSubmarket")
                      }
                      options={getSubMarketMaster}
                      limitTags={2}
                      getOptionLabel={(option) => option.subMarket}
                      //defaultValue={this.state.specSubmarket}
                      renderInput={(params) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center" }}
                          className="filter-label"
                        >
                          <label htmlFor="filled-hidden-label-small">
                            Sub Market
                          </label>
                          <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            hiddenLabel
                            placeholder="Sub Market"
                          />
                        </Box>
                      )}
                    />
                  </div>
                  <div className="col-md-4 col-lg-4"></div>
                </div>
                <PowerBIEmbedControl
                  key="submarketSpecificChartData"
                  type={2}
                  tokenType={"Aad"} //"Embed" // Embed or "Aad"
                  config={submarketSpecificChartData}
                  style={reportStyle}
                  extraSettings={extraSettings}
                  params={{
                    ProductType: "Industrial",
                    fromDate: convertToChartDate(fromDate),
                    toDate: convertToChartDate(toDate),
                    Submarket: specSubmarket,
                    Market: marketFilter,
                    KeepMarketStats: 1,
                    StatusId: 1,
                    ExistingUCId: [1, 2],
                  }}
                />
              </>
            )}

            {this.state.tab17 == true && (
              <PowerBIEmbedControl
                key="quaterAndMonthlyResearchChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={quaterAndMonthlyResearchChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  fromDate: convertToChartDate(fromDate),
                  toDate: convertToChartDate(toDate),
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}

            {this.state.tab18 == true && (
              <PowerBIEmbedControl
                key="ytdAbsorptionSubmarketChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={ytdAbsorptionSubmarketChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}

            {this.state.tab19 == true && (
              <PowerBIEmbedControl
                key="annualAbsorptionChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={annualAbsorptionChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  fromDate: convertToChartDate(fromDate),
                  toDate: convertToChartDate(toDate),
                  Market: marketFilter,
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}

            {this.state.tab20 == true && (
              <PowerBIEmbedControl
                key="covidSubAbsChartData"
                type={2}
                tokenType={"Aad"} //"Embed" // Embed or "Aad"
                config={covidSubAbsChartData}
                style={reportStyle}
                extraSettings={extraSettings}
                params={{
                  Market: marketFilter,
                  KeepMarketStats: 1,
                  ProductType: "Industrial",
                  StatusId: 1,
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

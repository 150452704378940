import {
  TableHead,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Box,
  TablePagination,
} from "@material-ui/core";
import React, { Component } from "react";
import { apiBatchRequest, apiBatchRequestWithoutLoader, CancelRequest, RenewCancelToken } from "../../../utils/api_service";
import { API_URL } from "../../../constants/api";
import commonLabels from "../../../constants/messageConstants";
import { ShowToast, toastType } from "../../../utils/toast-service";

import IndReports from "./IndReports";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "./report.less";
import DateFnsUtils from "@date-io/date-fns";
import { DateFormat } from "../../../constants/appConstants";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageHeader from "../../common/PageHeader";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  EnhancedTableHead,
  generatePDFFromHtml,
  getComparator,
  stableSort,
  getCurrentPayCycleEndDateByDate,
  getCurrentPayCycleStartDateByDate,
  removeDuplicates,
} from "../../../utils/helper";
import { Icon } from '@iconify/react';
import bxFilterAlt from '@iconify/icons-bx/bx-filter-alt';
import filterIcon from '@iconify/icons-ci/filter';
import camelcaseKeys from "camelcase-keys";
import AdvancedFilter from './AdvancedFilter';
import { getMultilevelGroupedData } from "../../../utils/generic";
import { getFormattedValuesForReports } from "../../../utils/formatData";
import ReportButtons from "../../../utils/ReportButtons";
import SearchBar from "../../common/AutoSearch/SearchBar";

const uploadColumns = [
  {
    key: "buildingClass",
    name: "BuildingClass",
  },
  {
    key: "market",
    name: "Department",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "buildingOwner",
    name: "BuildingOwner",
  },
  {
    key: "subMarket",
    name: "SubMarket",
  },
  {
    key: "buildingName",
    name: "buildingName",
  },
  {
    key: "floorplate",
    name: "Hire date",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
const headCells = [ 
  {
    id: "buildingName",
    numeric: false,
    disablePadding: false, //JT
    label: "Property Name",
  },
  {
    id: "monthRecorded",
    numeric: false,
    disablePadding: false, //JT
    label: "Month Recorded",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "coStarId",
    numeric: false,
    disablePadding: false,
    label: "CoStarID",
  },
  {
    id: "subsAbsMarch15",
    numeric: true, //JT
    disablePadding: false,
    label: "Sub Abs since Mar 15",
  },
 
 
];
export default class IndCovidSubAbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId:this.props.globalMarketId,
      openFilter: false,
      showMain: false,
      enableFilterButton:false,
      marketAbsorptionReport: [],
      employeeSummary: [],
      startDate: moment(this.props.monthRecorded).format(DateFormat), 
      // endDate: moment('2021-02-01').endOf("month").format(DateFormat),
      //startDate: moment(this.props.monthRecorded).format(DateFormat), 
       endDate: moment(this.props.monthRecorded).format(DateFormat), 
      showReport: false,
      getMarketMaster: [],
     // companyMasterDetails: [],
      getSubMarketMaster: [],
      getBuildingMaster: [],
      market: [],
      subMarket: [],
      buildingName: [],
      companyName: [],
      openSlidingPanel: false,
      isTotal:false,
      searchInput : '',
      rowsPerPage: 100,
      rowsPerPageOptions: [100, 200, 300, 400, 500],
      page: 0,
      order: "asc",
      orderBy: "nam",
      topvalue: 68,
      selected: [],
      dense: false,
      advanceFilterConfig: {
        groupBy: {
          masterData: [          
             {
              key: "Stream Submarket",
              value: "subMarket",
              id: "subMarket",
              numeric: false,
              disablePadding: false,
              label: "Stream Submarket",
            },
                                  
          ],
          selected: [
            { key: "Stream Market", value: "market" },           
          ],
        },
        selectedDate: {},
        suppressGraph: false,
        suppressZero: true,
        summaryRows: false,
        showZeroNGraph: true,
       
      },
      summaryCols: ['subsAbsMarch15'],
    };

    this.baseState = this.state;
  }

  componentDidMount() {
    RenewCancelToken();
    //this.props.setNewMarketId --todo(this.GetMasterData);
    this.GetMasterData(this.state.selectedMarketId );
  }
  componentWillUnmount()
  {
    CancelRequest();
  }
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.forceUpdate();
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };
  goBack = () => {
    this.setState({
      showMain: true,
    });
  };
  setIsTotal=()=>{
    this.setState({isTotal:true});
  }
  handleDateChange = (date, name) => {
    if (name === "startDate") {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    } else {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    }
  };

  GetMasterData = (selectedMarketId ) => {   
    this.getReport(selectedMarketId);            
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 2,marketId:selectedMarketId  },
        fromCache: true,
				duration: 60
      },
      // {
      //   url: API_URL.Master.companyMasterDetails,
      //   key: "companyMasterDetails",
      //   params: { businessLineId: 2},
      //   fromCache: true,
			// 	duration: 60
      // },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 2 ,marketId:selectedMarketId },
        fromCache: true,
				duration: 60
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 2, marketId: this.state.selectedMarketId },
        fromCache: true,
				duration: 60
      },
    ])
      .then(
        ({
          getMarketMaster,
          getSubMarketMaster,
          getBuildingMaster,
          //companyMasterDetails,
        }) => {
          this._asyncRequest = null;
          if (getMarketMaster.success !== true) {
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
          this.setState(
            {
              getMarketMaster: camelcaseKeys(getMarketMaster.data) || [],
              getSubMarketMaster: camelcaseKeys(getSubMarketMaster.data) || [],
              getBuildingMaster: camelcaseKeys(getBuildingMaster.data) || [],
              // companyMasterDetails:
              //   camelcaseKeys(companyMasterDetails.data) || [],
                enableFilterButton:true,
            }
          );
        }
      )
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getReport = (marketId) => {
    const { market, buildingName, subMarket, companyName } = this.state;
    //const marketId =this.state.selectedMarketId ;// _.cloneDeep(market.map((x) => x.marketId));
    const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = _.cloneDeep(companyName.map((x) => x.companyId));

    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
   const params = {
     startDate: moment(startDate).format(DateFormat),
     endDate: moment(endDate).format(DateFormat),
      marketId: marketId,//.join("|"),
      buildingId: buildingId.join("|"),
      subMarketId: subMarketId.join("|"),
      companyId: companyId.join("|"),
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialReports.covidSubAbs,
        params,
        key: " marketAbsorptionReport",
      },
    ])
      .then(({ marketAbsorptionReport }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport: camelcaseKeys(
            marketAbsorptionReport.data || []
          ),
          responseMarketAbsorptionReport:camelcaseKeys(
            marketAbsorptionReport.data || []
          ),
          showReport: true,
          page:0
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  renderTable = (marketAbsorptionReport) => {
    const groupSequence = this.getColumnSequence();
    return (
      <Table className="reportTable" id="rptTable" stickyHeader aria-label="sticky table">
          <EnhancedTableHead
          numSelected={this.state.selected.length}
          order={this.state.order}
          orderBy={this.state.orderBy}
          onRequestSort={this.handleRequestSort}
          rowCount={marketAbsorptionReport.length}
          columnSequence={groupSequence}
          headCells={headCells}
        />
        {/* <TableHead>
          <TableRow>
            {groupSequence.map((col, index) => (
              <TableCell key={"th" + col.value + index}>{col.key}</TableCell>
            ))}            
          
            <TableCell>Property Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>CoStarID</TableCell>            
            <TableCell style={{textAlign:'right'}}>Sub Abs since Mar 15</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>{
          marketAbsorptionReport.length == 0 ?( <TableRow >
            <TableCell><span>No Data Available</span></TableCell></TableRow>
                    ):(this.renderTableRow(this.state.rowsPerPage > 0
              ? stableSort(
                  marketAbsorptionReport || [],
                  getComparator(this.state.order, this.state.orderBy)
                ).slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
              : marketAbsorptionReport))}</TableBody>
      </Table>
    );
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };

  resetPage = () => {
    this.setState({...this.baseState},this.componentDidMount);
    //this.componentDidMount();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    this.getReport(this.state.selectedMarketId);
  };

  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  handleChange = (e, value, name) => {
    this.setState({ [name]: value });
  };

  onAddvanceFilterChange = (advanceFilterConfig) => {
    this.setIsTotal();
    this.setState(
      {
        advanceFilterConfig: advanceFilterConfig,
        openSlidingPanel: false,
        // startDate: getCurrentPayCycleStartDateByDate(
        //   this.state.advanceFilterConfig.startDate
        // ),
        // endDate: getCurrentPayCycleEndDateByDate(
        //   this.state.advanceFilterConfig.end
        // ),
      },
      () => {
        this.getReport(this.state.selectedMarketId);
      }
    );
  };

  getColumnSequence = () => {
    return removeDuplicates(
      [
        ...this.state.advanceFilterConfig.groupBy.selected,
        ...this.state.advanceFilterConfig.groupBy.masterData,
      ],
      "key"
    );
  };

  adjustColumnSequence = (currentGroupedRow) => {
    this.state.advanceFilterConfig.groupBy.masterData.forEach((x) => {
      currentGroupedRow.push({ key: x.value });
    });
    return currentGroupedRow;
  };

  renderTableRow = (data, onlydata = false) => {
    return this.renderTableCell(
  getMultilevelGroupedData(
        data,
        this.state.advanceFilterConfig.groupBy.selected.map((x) => x.value),
        this.state.isTotal,
        this.state.summaryCols
      ),
      onlydata,[]
    );
  };

  exportPDF = () => {
    generatePDFFromHtml(
      "Employee New Hire Report",
      "#rptTable",
      "EmployeeNewHireReport"
    );
  };

  // Export PDF
  exportPDFUsingData = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);
    const title = "Market Absorption Report";
    const sequence = this.getColumnSequence();
    const columns = this.getColumnSequence().map((x) => x.key);
    const headers = [
      [
        ...columns,  
        "Property Name",
        "Vac %",
        "Abs Prev Qtr",
        "Abs YTD",
        "Abs Prev Month",
        "RSF"
      ],
    ];
    
    const data = this.renderTableRow(
      this.state.marketAbsorptionReport,
      true
    ).map((m) => [
      m[sequence[0].value],
      m[sequence[1].value],
      m.buildingName,
      m.monthRecorded,
      m.address,
      m.coStarId,
      m.subsAbsMarch15      
    ]);

    const content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.setFontSize(9);
    doc.autoTable(content);
    // doc.fromHTML(ReactDOMServer.renderToStaticMarkup(this.render()));
    doc.save("NewHireEmployeeReport.pdf");
  };

  renderTableCell = (
    groupedData,
    onlydata,
    currentGroupedRow = [],
    tableCell = [],
    rowCount = 0
  ) => {
    let column1 = "";
    let column2 = '';
    
  //  let column4 = '';
    // let column5 = '';
    groupedData.map((d, i) => {
      if (d.data !== undefined) {
        currentGroupedRow = currentGroupedRow.filter((x) => x.key !== d.key);
        currentGroupedRow.push({ key: d.key, value: d[d.key] });
        this.renderTableCell(
          d.data,
          onlydata,
          currentGroupedRow,
          tableCell,
          rowCount
        );
      }
      const keyvalue = this.adjustColumnSequence(
        _.cloneDeep(currentGroupedRow)
      );
      const sequence = this.getColumnSequence();
      const evenOddClass =
        rowCount % 2 === 0 ? " rpt rpt-even " : " rpt rpt-odd ";
      const topBorderClass =
        column1 !==
          (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? ""
          ? " rpt-topBorder "
          : " ";

      const key = d.market + d.submarket + "_" + rowCount + "_" + i;
      if(d.data && d.summaryData != undefined )
      {
      if (d.summaryData.length > 0 && (d.summaryData[0].isSummary === undefined || (d.summaryData.length > 0 && d.summaryData[0].isSummary === true && this.state.advanceFilterConfig.summaryRows === true))) {
        tableCell.push(
        <TableRow
          key={key}
          className={topBorderClass + (d.isGroup === true ? ' parent-row' : '')}
        >
          <TableCell
            className={'st sticky ' + evenOddClass + topBorderClass}
          >
            {getFormattedValuesForReports(column1 !== d[sequence[0].value] && d[sequence[0].value], 'Text') }{' '}
          </TableCell>
          <TableCell
            className={
              'st sticky-1 ' +
              evenOddClass +
              (column2 !== d[sequence[1].value]
                ? ' rpt-topBorder '
                : ' ')
            }
          >
            {getFormattedValuesForReports(column2 !== d[sequence[1].value] && d[sequence[1].value], 'Text')}{' '}
          </TableCell>
         
          <TableCell
            className={'st sticky rpt-topBorder ' + evenOddClass + topBorderClass}
          >
            {' '}
          </TableCell>
         
          {d.summaryData.map((m, ind) => (
            this.renderSummaryColumn(d.summaryData, m, key + ind, evenOddClass)
          ))}
        </TableRow>
        );
      }
    }
      if (d.data === undefined) {
        if (onlydata) {
          const rowData = {};
          rowData[sequence[0].value] = getFormattedValuesForReports(
            column1 !== d[sequence[0].value] ? d[sequence[0].value] : "",
            "Text",
            true
          );
          rowData[sequence[1].value] = getFormattedValuesForReports(column2 !== d[sequence[1].value] ? d[sequence[1].value] : '', 'Text', true);
          
          
          rowData.buildingName = d.buildingName;
          rowData.monthRecorded=d.monthRecorded;
          rowData.address = d.address;
          rowData.coStarId = d.coStarId;
          rowData.subsAbsMarch15 = d.subsAbsMarch15;  
         
       
          tableCell.push(rowData);
        } else {
          tableCell.push(
            <TableRow key={key} className={topBorderClass}>
              <TableCell className={evenOddClass + topBorderClass}>
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>
              <TableCell
                className={
                  evenOddClass +
                  (column2 !== d[sequence[1].value]
                    ? ' rpt-topBorder '
                    : ' ')
                }
              >
                {getFormattedValuesForReports(column2 !== d[sequence[1].value] && d[sequence[1].value], 'Text')}{' '}
              </TableCell>
            
              {/* <TableCell
                className={
                  evenOddClass +
                  (column4 !== d[sequence[3].value]
                    ? ' rpt-topBorder '
                    : ' ')
                }
              >
                {getFormattedValuesForReports(column4 !== d[sequence[3].value] && d[sequence[3].value], 'Text')}{' '}
              </TableCell> */}
             {/*  <TableCell
                className={
                  evenOddClass +
                  (column5 !== d[sequence[4].value]
                    ? ' rpt-topBorder '
                    : ' ')
                }
              >
                {getFormattedValuesForReports(column5 !== d[sequence[4].value] && d[sequence[4].value], 'Text')}{' '}
              </TableCell> */}
              {/* <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.submarket === null
                  ? "NA"
                  : getFormattedValuesForReports(d.submarket, "Text")}
              </TableCell> */}

            
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.buildingName === null
                  ? "NA"
                  : getFormattedValuesForReports(d.buildingName, "Text")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.monthRecorded === null
                  ? "NA"
                  : getFormattedValuesForReports(d.monthRecorded, "Date")}
              </TableCell>
              
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.address === null
                  ? "NA"
                  : getFormattedValuesForReports(d.address, "Text")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.coStarId === null
                  ? "NA"
                  : getFormattedValuesForReports(d.coStarId, "Text")}
              </TableCell>
              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.subsAbsMarch15, "Number")}
              </TableCell>
                     
             
            </TableRow>
          );
        }
      }
      rowCount++;
      column1 =
        (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? "";
      column2 = (keyvalue.find(x => x.key === sequence[1].value) || {}).value ?? '';
      
    
    });
    return tableCell;
  };

  renderSummaryColumn = (summaryDetails, n, index, evenOddClass) => {
    const summaryDetail = summaryDetails.filter(x => x ===n);
    const tableCell = [];
  //  summaryDetail.forEach(m=>{
      tableCell.push(<>     
         <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "monthRecorded"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "monthRecorded" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.monthRecorded === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.monthRecorded, "Date")
              )}
            </div>
          ))}
        </TableCell>              
       <TableCell className={(evenOddClass + 'right-border  rpt-topBorder')} key={index + 'subsAbsMarch15'}>
      {
         summaryDetail.map((n, ind) =>
         <div key = {index + 'subsAbsMarch15' + ind} className = {((summaryDetail.length) > 1 && ind !== (summaryDetail.length - 1)) ? 'multi-pay-period' : '' }> { n.subsAbsMarch15 === null ? <span className="number-text">-</span> : getFormattedValuesForReports(n.subsAbsMarch15, 'Number')}</div>
         )
      }
       </TableCell>
       <TableCell className={(evenOddClass + 'right-border  rpt-topBorder')} key={index + 'subSf'}>
      {
         summaryDetail.map((n, ind) =>
         <div key = {index + 'subSf' + ind} className = {((summaryDetail.length) > 1 && ind !== (summaryDetail.length - 1)) ? 'multi-pay-period' : '' }> { n.subSf === null ? <span className="number-text">-</span> : getFormattedValuesForReports(n.subSf, 'Number')}</div>
         )
      }
       </TableCell>     
      
     
      </>);
    //});
 
    return tableCell;
  }

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let searchData = this.state.responseMarketAbsorptionReport;
    let filteredData = searchData.filter((value) => {
      // if (
      //   value.market != null
      
      // ) {
        return (         
          
          (value.market ? value.market
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.subMarket ? value.subMarket
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.address ? value.address
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.buildingName ? value.buildingName
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.monthRecorded ? value.monthRecorded
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
			(value.coStarId ? value.coStarId
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase()) : false) ||
        (value.subsAbsMarch15 ? value.subsAbsMarch15
                  .toString()
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) : false)        
         
        );
      //}
    });

    if (searchInput.length == 0)
      this.setState({ marketAbsorptionReport: this.state.responseMarketAbsorptionReport });
    else this.setState({ marketAbsorptionReport: filteredData,page:0 });
  };

  render() {
    const {
      showMain,
      openFilter,
      marketAbsorptionReport,
      startDate,
      endDate,
      getMarketMaster,
      getSubMarketMaster,
      //companyMasterDetails,
      getBuildingMaster,
      showReport,
      advanceFilterConfig,
      openSlidingPanel,
      searchInput
    } = this.state;

    return (
      <div style={{marginTop:`${this.props.marginTop}px`}}>
        {showMain ? (
          <IndReports globalMarketId={this.state.selectedMarketId}></IndReports>
        ) : (
          <>
           <div className="row">
           <div className="col-sm-3 col-md-3 col-lg-6">
             {/* <PageHeader
              isReportPage={true}
              key={"Market_Report"} 
              btnText={"Reports"}
              onBackClick={this.goBack}
              title={"/ COVID - Sub Abs"}             
            >
              Market Absorption Report
            </PageHeader> */}

            </div>
            <div className="col-sm-5 col-md-5 col-lg-4">
            <SearchBar
                      size="large"
                      name="searchInput"
                      value={searchInput || ""}
                      onChange={this.handleSearch}
                      label="Search"
                      placeholder="Search property name,market,etc."
                    ></SearchBar>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-2">
 
            <AdvancedFilter
           filterConfig={advanceFilterConfig}
           onApplyFilter={this.onAddvanceFilterChange}
           openSlidingPanel={openSlidingPanel}
           handleClose={this.handleClose}
           setIsTotal={this.setIsTotal}
         /> 
          <ReportButtons
           resetPage={this.resetPage}
           handleSubmit={this.handleSubmit}
           handleClickOpen={this.handleClickOpen}
           handlePrintClick={this.handlePrintClick}
           exportPDF={this.exportPDF}
           data={marketAbsorptionReport}
           columnWidths={columnWidths}
           uploadColumns={uploadColumns}
           openFilter={this.state.openFilter}
           enableFilter={this.enableFilter}
           isReportPage={true}
           isListingPage={false}
           fileName={'AnnualAbsorptionReport.xlsx'}
           enableFilterButton={this.state.enableFilterButton}
         />
            </div>


</div>

            {getMarketMaster.length > 0 && openFilter == true &&(
              <div style={{ width : "98%" }}>
              <Grid
                container
                spacing={2}
                className="no-print row bottom-xs report-filter"
                
              >
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    value={this.state.market}
                    onChange={(e, val) => this.handleChange(e, val, "market")}
                    id="tags-standard"
                    options={getMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.market}
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                      <label htmlFor="filled-hidden-label-small">Stream Market</label>
                    
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                       hiddenLabel
                        placeholder="Stream Market"
                      />
                      </Box>
                    )}
                  />
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.buildingName}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "buildingName")
                    }
                    options={getBuildingMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.buildingName}
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                      <label htmlFor="filled-hidden-label-small">Property Name</label>
                    
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        hiddenLabel
                        placeholder="Property Name"
                      />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "subMarket")
                    }
                    options={getSubMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.subMarket}
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                      <label htmlFor="filled-hidden-label-small">Stream Submarket</label>
                    
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                       hiddenLabel
                        placeholder="Stream Submarket"
                      />
                      </Box>
                    )}
                  />
                  {/* <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.companyName}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "companyName")
                    }
                    options={companyMasterDetails}
                    limitTags={2}
                    getOptionLabel={(option) => option.companyName}
                    renderInput={(params) => (
                      <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                      <label htmlFor="filled-hidden-label-small">Company</label>
                    
                      <TextField
                        {...params}
                        variant="outlined"
                        margin="normal"
                        hiddenLabel
                        placeholder="Company"
                      />
                      </Box>
                    )}
                  />                  
                  */}

<MuiPickersUtilsProvider utils={DateFnsUtils}>
          
          <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                     <label htmlFor="filled-hidden-label-small">Month Recorded</label>
                   
          <Grid container>
            <KeyboardDatePicker
             style={{  border:'1px solid #cecdcd',borderRadius:'4px' }}
            // variant="inline" 
             margin="normal"
             views={["year", "month"]}
              id="date-picker-dialog"
              //label="Start Date"
              variant="inline"
              format="MM/01/yyyy"
              autoOk
              
              name="startDate"
              value={startDate}
              onChange={(e) => this.handleDateChange(e, 'startDate')}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              className="w-100"
            />
          
          </Grid>
          </Box>
        </MuiPickersUtilsProvider>

                </Grid>

               {/*  <Grid item xs={12} lg={3}  >
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
          
             <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                        <label htmlFor="filled-hidden-label-small">Month Recorded</label>
                      
             <Grid container>
               <KeyboardDatePicker
                style={{  border:'1px solid #cecdcd',borderRadius:'4px' }}
               // variant="inline" 
                margin="normal"
                views={["year", "month"]}
                 id="date-picker-dialog"
                 //label="Start Date"
                 variant="inline"
                 format="MM/01/yyyy"
                 autoOk
                 
                 name="startDate"
                 value={startDate}
                 onChange={(e) => this.handleDateChange(e, 'startDate')}
                 KeyboardButtonProps={{
                   'aria-label': 'change date'
                 }}
                 className="w-100"
               />
             
             </Grid>
             </Box>
           </MuiPickersUtilsProvider> */}
           {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
           <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                        <label htmlFor="filled-hidden-label-small">End Date</label>
                      
             <Grid container>
               <KeyboardDatePicker
                 style={{  border:'1px solid #cecdcd',borderRadius:'4px' }}
                 margin="normal"
                 id="date-picker-dialog"
                // label="End Date"
                 format="MM/dd/yyyy"
                 autoOk
                 minDate={startDate}
                 name="endDate"
                 variant="inline"
                 value={endDate}
                 onChange={(e) => this.handleDateChange(e, 'endDate')}
                 KeyboardButtonProps={{
                   'aria-label': 'change date'
                 }}
                 className="w-100"
               />
             </Grid>
             </Box>
           </MuiPickersUtilsProvider> 
         </Grid>               */}
               <Grid item xs={12} lg={3}  >
               <button
                      className="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                      onClick={() => this.handleSubmit()}
                    >
                      <span class="MuiButton-label"> <span class="tool-icon-text">FILTER REPORT</span></span>
                      
                      <span class="MuiTouchRipple-root"></span>
                    </button>
                </Grid>
              </Grid>
              </div>
            )}

            {showReport && marketAbsorptionReport == null && (
              <Grid container spacing={4}>
                <Grid
                  container
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                >
                  <label className="item-big">No Data Available.</label>
                </Grid>
              </Grid>
            )}
            {showReport && (
              <>
                {marketAbsorptionReport !== null && (
                  <div className="container" style={{ marginTop: "1rem" }}>
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={this.handleChangePage}
                      onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    <TableContainer className="report-table-scroll" component={Paper}>
                      {this.renderTable(marketAbsorptionReport)}
                    </TableContainer>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

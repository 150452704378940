import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { standardDateFormat } from "../../../constants/constants";
import { getDate } from "../../../utils/date";
import "./react-datepicker.less";
import "./style.less";

export default function InputDatePicker({
	valueDate,
	onEdit,
	valueKey,
	minDate,
	maxDate,
	className = "",
	withPortal = false,
	setDateFocus = () => {}
}) {
	const [startDate, setStartDate] = useState(
		valueDate ? new Date(valueDate) : null
	);

	function selectDate(date) {
		setStartDate(date);
		onEdit(date ? moment(date).format(standardDateFormat) : "", valueKey);
	}

	return (
		<DatePicker
			placeholderText="Select a date..."
			className={className}
			selected={startDate}
			onChange={selectDate}
			onFocus={() => setDateFocus(true)}
			onBlur={() => setDateFocus(false)}
			minDate={minDate && getDate(minDate)}
			maxDate={maxDate && getDate(maxDate)}
			withPortal={withPortal}
			peekNextMonth
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			popperPlacement="top"
		/>
	);
}

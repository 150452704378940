import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
// import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
// import Typography from "@material-ui/core/Typography";

export const LightTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: theme.palette.common.white,
		color: "rgba(0, 0, 0, 0.87)",
		boxShadow: theme.shadows[1],
		fontSize: 14
	}
}))(Tooltip);

export const useStylesBootstrap = makeStyles(theme => ({
	arrow: {
		color: theme.palette.common.black
	},
	tooltip: {
		backgroundColor: theme.palette.common.black,
		fontSize: 14,
		maxWidth: 650
	}
}));

export function DarkTooltip(props) {
	const classes = useStylesBootstrap();

	return <Tooltip arrow classes={classes} {...props} />;
}

export const HtmlTooltip = withStyles(theme => ({
	tooltip: {
		backgroundColor: "#f5f5f9",
		color: "rgba(0, 0, 0, 0.87)",
		maxWidth: 220,
		fontSize: theme.typography.pxToRem(12),
		border: "1px solid #dadde9"
	}
}))(Tooltip);

// export default function CustomizedTooltips() {
// 	return (
// 		<div>
// 			<LightTooltip title="Add">
// 				<Button>Light</Button>
// 			</LightTooltip>
// 			<DarkTooltip title="Add">
// 				<Button>Bootstrap</Button>
// 			</DarkTooltip>
// 			<HtmlTooltip
// 				title={
// 					<React.Fragment>
// 						<Typography color="inherit">Tooltip with HTML</Typography>
// 						<em>And here's</em> <b>some</b> <u>amazing content</u>.{" "}
// 						{"It's very engaging. Right?"}
// 					</React.Fragment>
// 				}
// 			>
// 				<Button>HTML</Button>
// 			</HtmlTooltip>
// 		</div>
// 	);
// }

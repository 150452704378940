import { Box, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-mini";
import { DateFormat } from "../../../constants/appConstants";
export default class ExportData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketValue: null,
      selectedSubMarketValue: null,
      selectedProduct: null,
     // selectedDate: moment().format("MM/YYYY"),
     selectedDate:moment().startOf("month").format(DateFormat),//new Date(),
     endDate: moment().format(DateFormat),
    };
  }
  handleChange=(e)=>
  {
this.setState({
    selectedDate:new Date(e)
});
this.props.handleDateChange(e);
  }
  render() {
    return (
      <>
        <div style={{ padding: "8px" }}>
          {/* <Autocomplete
            size="small"
            multiple={this.props.filterConfigForExport[0].isMultiple}
            underlineStyle={{ display: "none" }}
            limitTags={1}
            options={(this.props.sourceData || []).map(
              (option) => option[this.props.filterConfigForExport[0].key]
            )}
            autoHighlight
            value={this.state.selectedMarketValue}
            key={"dropDown_filter_forexport"}
            id={"dropDown_filter_forexport"}
            onChange={(event, value) => {
              this.setState({
                selectedMarketValue: value,
              });
              this.props.handleMarketChange(event, value);
              // this.onChange(filter, event, value);
            }}
            renderInput={(params) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Market</label>
                <TextField
                  {...params}
                  fullWidth
                  className="autosearc-text"
                  hiddenLabel
                  variant="outlined"
                  margin="normal"
                  placeholder={this.props.placeholder}
                />
              </Box>
            )}
          /> */}

          <Autocomplete
            size="small"
            multiple={this.props.filterConfigForExport[1].isMultiple}
            underlineStyle={{ display: "none" }}
            limitTags={1}
            options={(this.props.sourceDataForSubMarket || []).map(
              (option) => option[this.props.filterConfigForExport[1].key]
            )}
            autoHighlight
            value={this.state.selectedSubMarketValue}
            key={"dropDown_filter"}
            id={"dropDown_filter"}
            onChange={(event, value) => {
              this.setState({
                selectedSubMarketValue: value,
              });
              this.props.handleSubMarketChange(event, value);
              // this.onChange(filter, event, value);
            }}
            renderInput={(params) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Submarket</label>
                <TextField
                  {...params}
                  fullWidth
                  className="autosearc-text"
                  hiddenLabel
                  variant="outlined"
                  margin="normal"
                  placeholder={this.props.placeholder}
                />
              </Box>
            )}
          />
{/* 
          <Autocomplete
            size="small"
            multiple={this.props.filterConfigForExport[2].isMultiple}
            underlineStyle={{ display: "none" }}
            limitTags={1}
            options={(this.props.sourceProduct || []).map(
              (option) => option[this.props.filterConfigForExport[2].key]
            )}
            autoHighlight
            value={this.state.selectedProduct}
            key={"dropDown_filter"}
            id={"dropDown_filter"}
            onChange={(event, value) => {
              this.setState({
                selectedProduct: value,
              });
              this.props.handleProductChange(event, value);
              // this.onChange(filter, event, value);
            }}
            renderInput={(params) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Product Type</label>
                <TextField
                  {...params}
                  fullWidth
                  className="autosearc-text"
                  hiddenLabel
                  variant="outlined"
                  margin="normal"
                  placeholder="Product Type"
                />
              </Box>
            )}
          /> */}

          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            key={"exportDatePicker_filter"}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="filter-label"
            >
              <label htmlFor="filled-hidden-label-small">Select Date</label>
              <Grid container>
                <DatePicker
                  //variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  //label="Sel"
                  //helperText="Start from year selection"
                  value={this.state.selectedDate}
                  maxDate={this.state.endDate}
                  onChange={(event, value) => {
                    this.handleChange(event,value)
                    // this.setState({
                    //   selectedDate: moment(event).format("MM/YYYY"),
                    // });
                    // this.forceUpdate();
                    // this.props.handleDateChange(event, value);
                    // this.onChange(filter, event, value);
                  }}

                  // {this.props.handleDateChange}
                />
              </Grid>
            </Box>
          </MuiPickersUtilsProvider>
        </div>
        <div className="row" style={{ float: "right" }}>
          <div className="col-md-6 col=lg-6">
            <button
              className="import-button"
              style={{ float: "right" }}
              onClick={() => {
                this.props.exportImportedData();
              }}
            >
              Export
            </button>
          </div>
          <div className="col-md-6 col=lg-6">
            <button
              className="import-button"
              style={{ float: "left" }}
              onClick={() => {
                this.props.cancelExport();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    );
  }
}

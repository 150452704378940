export const Operators = {
	GreaterThan: (a, b) => {
		const result = a > b;
		let message = () => {};
		if (result === false) {
			message = message = (a, b) => {
				return a + " should be Greater than " + b;
			};
		}
		return { result, message };
	},
	LessThan: (a, b) => {
		const result = a < b;
		let message = () => {};
		if (result === false) {
			message =
				message =
				message =
					(a, b) => {
						return a + " should be Less than " + b;
					};
		}
		return { result, message };
	},
	EqualsTo: (a, b) => {
		const result = a === b;
		let message = () => {};
		if (result === false) {
			message =
				message =
				message =
					(a, b) => {
						return a + " should be Equal to " + b;
					};
		}
		return { result, message };
	},
	LessThanEqualsTo: (a, b) => {
		const result = a <= b;
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return `${a} should be Less than or equal to ${b}`;
			};
		}
		return { result, message };
	},
	GreaterThanEqualsTo: (a, b) => {
		const result = a >= b;
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return `${a} should be Greater than or equal to ${b}`;
			};
		}
		return { result, message };
	},
	NotEquals: (a, b) => {
		const result = a !== b;
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return `${a} should be Not equals to ##fieldNameB## ${b}`;
			};
		}
		return { result, message };
	},
	NotIn: (a, b = []) => {
		const result = (b || []).find((x) => x === a) === undefined;
		let message = () => {};
		if (result === false) {
			message = "##fieldNameB## should not contain value " + a;
		}
		return { result, message };
	},
	In: (a, b = []) => {
		const result = (b || []).find((x) => x === a) !== undefined;
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return a + " should exist in ##fieldNameB## ";
			};
		}
		return { result, message };
	},
	Between: (a, b = []) => {
		const result = a > b[0] && a < b[1];
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return a + " should be between ##fieldNameB## ";
			};
		}
		return { result, message };
	},
	BetweenInclusive: (a, b = []) => {
		const result = a >= b[0] && a <= b[1];
		let message = () => {};
		if (result === false) {
			message = (a, b) => {
				return a + " should be between  ##fieldNameB## ";
			};
		}
		return { result, message };
	},
};

export const marketRequiredField = [
	"marketName",
	"preferredName",
	"departmentName",
	"allocationStartDate",
	"allocationEndDate",
	"billingStartDate",
	"billingEndDate",
	"marketBillingPercent",
	"marketAllocationPercent",
];

export const propertyRequiredField = [
	"marketName",
	"preferredName",
	"departmentName",
	"allocationStartDate",
	"allocationEndDate",
	"billingStartDate",
	"billingEndDate",
	"marketBillingPercent",
	"marketAllocationPercent",
	"propertyName",
	"legalEntity",
	"propertyBillingPercent",
	"additionalFixedAmount",
	"flatrateType",
	"propertyAllocationPercent",
];
export const DateFormat = "YYYY-MM-DD";

export const DateFormatImport = "YYYY-MM-01";

export const propertyDisabledField = [
	"marketName",
	"preferredName",
	"departmentName",
	"isAllocatedToLegalEntity",
	"propertyName",
	"legalEntity",
	"isReimbursed",
	"flatrateType",
];

export const SECTIONS = {
	AdminSection: 32,
	Average_Annual_Absorption: 46,
	Classification_Breakdown: 42,
	Company: 58,
	Company_Details: 2,
	Company_Download: 61,
	Company_Listing: 1,
	Company_Print: 60,
	Company_Settings: 62,
	Configure_Market_Access_Permissions: 29,
	COVID_Office_Space_Delivered_Since_2015: 52,
	COVID_Sub_Abs: 51,
	Current_Development_PipeLine: 53,
	Dashboard: 35,
	Download: 38,
	Employee: 33,
	Historical_Totals_and_Averages: 47,
	Html_Reports: 39,
	Import_Export: 36,
	Import_Export_Office_Stats: 28,
	Ind_Dashboard:210,
	Import_Export_Ind_Stats: 211,
	Lease_Comp_Details: 8,
	Lease_comp_Download: 69,
	Lease_Comp_Listing: 7,
	Lease_Comp_Print: 68,
	Lease_Comp_Settings: 70,
	Lease_Comps: 67,
	Leasing_Statistics: 43,
	Market_Absorption_Report: 40,
	Html_Reports_Download: 208,
	Positive_and_Negative_Absorption: 44,
	Property: 57,
	Property_Details: 4,
	Property_Download: 55,
	Property_Listing: 3,
	Property_Of_Interest: 31,
	Property_Owner_History: 26,
	Property_Print: 54,
	Property_Settings: 56,
	Quarterly_and_Monthly_Research: 50,
	Sales_Comp_Details: 10,
	Sales_Comp_Listing: 9,
	Sales_Comps: 71,
	Sales_Comps_Download: 73,
	Sales_Comps_Print: 72,
	Sales_Comps_Settings: 74,
	Sublease_Statistics: 45,
	Tenant: 63,
	Tenant_Details: 6,
	Tenant_Download: 65,
	Tenant_Listing: 5,
	Tenant_Listing_Page: 27,
	Tenant_Print: 64,
	Tenant_Settings: 66,
	Vacancy_Competitive_only_Properties: 48,
	Vacancy_Report: 41,
	YTD_Absorption_Submarket: 49,
	Configuration: 191,
	Configure_Group_Access_Permission: 192,
	Configure_Add_Market_Details: 25,
	Configure_Add_Submarket_Details: 218,
	Configure_Add_Building_Class_Details: 219,

	Ind_Absorption_Report: 197,
	Ind_Configuration: 194,
	Ind_Configure_Group_Access_Permission: 195,
	Ind_Configure_Market_Access_Permissions: 193,
	Ind_Html_Reports: 196,
	Ind_Vacancy_Report: 198,
	Industrial_Company: 83,
	Industrial_Company_Details: 16,
	Industrial_Company_Download: 85,
	Industrial_Company_Listing: 15,
	Industrial_Company_Print: 84,
	Industrial_Company_Settings: 86,
	Industrial_Lease_Comp_Details: 20,
	Industrial_Lease_comp_Download: 93,
	Industrial_Lease_Comp_Listing: 19,
	Industrial_Lease_Comp_Print: 92,
	Industrial_Lease_Comp_Settings: 94,
	Industrial_Lease_Comps: 91,
	Industrial_Property: 82,
	Industrial_Property_Details: 12,
	Industrial_Property_Download: 80,
	Industrial_Property_Listing: 11,
	Industrial_Property_Print: 79,
	Industrial_Property_Settings: 81,
	Industrial_Sales_Comp_Details: 22,
	Industrial_Sales_Comp_Listing: 21,
	Industrial_Sales_Comps: 95,
	Industrial_Sales_Comps_Download: 97,
	Industrial_Sales_Comps_Print: 96,
	Industrial_Sales_Comps_Settings: 98,
	Industrial_Tenant: 87,
	Industrial_Tenant_Details: 18,
	Industrial_Tenant_Download: 89,
	Industrial_Tenant_Listing: 17,
	Industrial_Tenant_Print: 88,
	Industrial_Tenant_Settings: 90,
	Spec_Suite: 204,
	Spec_Suite_Listing: 205,
	Spec_Suite_Add_Edit: 206,
	IndustrialAdminSection: 220,
	Industrial_Add_Market_Details: 221,
	Industrial_Add_Submarket_Details: 222,
	Industrial_Add_Building_Class_Details: 223,

	ADD_EDIT: "Add OR Edit",
	VIEW: "View",
};

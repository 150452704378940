import * as actionType from './actionTypes';
import { API_URL, API_HEADERS } from '../../constants/api';
import { apiRequest, apiBatchRequest } from '../../utils/api_service';

export const getUserData = (query) => dispatch => {
 let group=window.sessionStorage.getItem("changedGroup") ;
 let businessLineId=1;
 
 if(group == "Office" || group == null )
 businessLineId=1;
 else
 businessLineId=2;

  apiBatchRequest(
    [
    //{
    //  url: API_URL.Users.GetUserOrganizationDetails,
    //  key:'userConfigurationdata'
    //},
    {
      url: API_URL.Users.GetUserConfig,
      key: 'userConfig',
      params:{        
        businessLineId:businessLineId}
    }
  ]
  ).then(( userConfig ) => {
    const cloudOrg = ((userConfig.userConfig.data.cloudOrgnization || [])[0] || {});
    localStorage.setItem(
      API_HEADERS.CID, cloudOrg.cloudOrgId
    );
    dispatch({
      type:actionType.GET_USER_CONFIG,
      payload:userConfig.userConfig.data,
      
    });

    apiBatchRequest([
      {
        url: API_URL.Master.GetUserConfigurations,
        key: 'userData',
        params: { sectionId: -1 },
      },
      // {
      //   url: API_URL.Users.GetUserConfig,
      //   key: 'userConfig',
      // }
    ]).then(({ userData,userConfig }) => {
      window.sessionStorage.setItem("userConfigurations", JSON.stringify(userData.data));
     // window.sessionStorage.setItem("userMarketbasedAccess", JSON.stringify(userConfig.data.userMarket));
      dispatch({
        type: actionType.GET_USER_DATA,
        payload: userData.data,
        // type:actionType.GET_USER_CONFIG,
        // payload:userConfig.data,
        
      });
    

    });
  });
};

export const setLoadingScreen = isLoading => dispatch => {
  return dispatch({
    type: actionType.SET_LOADER_SCREEN,
    payload: isLoading
  });
};

// export const setSelectedMarketId = selectedMarketId => dispatch => {
//   return dispatch({
//     type: actionType.GET_SELECTED_MARKET,
//     payload: selectedMarketId
//   });
// };
import { Table, TableCell, TableRow } from '@material-ui/core';
import moment from "moment-mini";
import React from 'react';
import { API_URL } from '../../../constants/api';
import { DateFormat } from '../../../constants/appConstants';
import { apiBatchRequest } from '../../../utils/api_service';

export default class DownloadTemplate extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      marketStatsRefreshData:[],
      lastRefreshed:'',
      isRefreshInProgress:''
    }
  }

  getMarketStatsRefreshData = () =>  {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.GetMarketStatsRefreshData,
        params: { businessLineId: 1 },
        key: "marketStatsRefreshData",
      },
    ])
      .then(({ marketStatsRefreshData }) => {
        this._asyncRequest = null;
        if (marketStatsRefreshData.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        
        this.setState({
          lastRefreshed: marketStatsRefreshData.data[0].lastRefreshed || moment().startOf("month").format(DateFormat),
          isRefreshInProgress: marketStatsRefreshData.data[0].isRefreshInProgress //if refresh is in progress, the refresh button should be disabled - hence is flag is empty
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }

  componentDidMount() {
    this.getMarketStatsRefreshData();
  }

  GetDifference(refreshedDate){
    
    if(refreshedDate)
    {
      //     var date = new Date().toUTCString();
      //     var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      //                 date.getUTCDate(), date.getUTCHours(),
      //                 date.getUTCMinutes(), date.getUTCSeconds());

      //     var today = new Date(now_utc);
      //     var rd = new Date(refreshedDate);
      //     console.log(today)
      //     console.log(rd)

      // var diffMs = (rd - today); // milliseconds between now & Christmas
      // var diffDays = Math.floor(diffMs / 86400000); // days
      // var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
      // var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      // return "Last Refreshed: " + diffDays + " days, " + diffHrs + " hours, " + diffMins + " minutes)";

      let today = moment(new Date(new Date().toUTCString().slice(0, -3)));

      // const diffDays = today.diff(rd,'days');
      // const diffHours = today.diff(rd,'hours');
      // const diffMinutes = today.diff(rd,'minutes');
      if(this.state.isRefreshInProgress == "True")
      document.getElementById("btnRefreshNow").disabled = true; 
      else
      document.getElementById("btnRefreshNow").disabled = false; 
      return "Last Refreshed: " + moment(refreshedDate).from(today);

    }
    return "Last Refreshed: -"

  }

  render() {
    return (
      <>
        <div>
          <Table key="import-export-table">
            {(this.props.isImport || this.props.importData) && <TableRow key="import-export-row1">
              <TableCell key="import-export-col1" className="download-cell-header"><button className="import-export-btn" onClick={() => { this.props.importFile() }}>Import</button></TableCell>
              <TableCell key="import-export-col2" className="download-cell">{this.props.templateMessage}
                <button className="import-anchor" onClick={() => {
                  this.props.downloadTemplate()
                }}>here</button>
                to download import template.</TableCell>
            </TableRow>}
            {this.props.hideExportClearButton == false && this.props.isExport == true &&
              <TableRow key="import-export-row2">
                <TableCell key="import-export-col3" className="download-cell-header"><button className="import-export-btn" onClick={() => {
                  this.props.exportData()
                }}>Export</button></TableCell>
                <TableCell key="import-export-col4" className="download-cell">Export Market Stats for offline review.</TableCell>
              </TableRow>
            }
            {this.props.hideExportClearButton == false && this.props.isImport == true &&
              <TableRow key="import-export-row3">
                <TableCell key="import-export-col5" className="download-cell-header"><button className="import-export-btn" onClick={() => {
                  this.props.enableClear()
                }}>Clear</button></TableCell>
                <TableCell key="import-export-col6" className="download-cell">If current market stats must be removed and re-imported, select this option to clear current the current month's import.</TableCell>
              </TableRow>

            }
            {this.props.showRefreshButton == true &&
            <TableRow key="import-export-row4">
                <TableCell key="import-export-col7" className="download-cell-header"><button id="btnRefreshNow" className="import-export-btn" onClick={() => {
                  this.props.refreshMarketstatsData()
                }}>Refresh</button></TableCell>
                <TableCell key="import-export-col8" className="download-cell">{this.GetDifference(this.state.lastRefreshed)}</TableCell>
              </TableRow>
              }
          </Table>
        </div>
        <div style={{ marginRight: "10px" }}>
          <button className="import-button" onClick={() => {
            this.props.onCancel()
          }}>Cancel</button>
        </div>
      </>);
  }
}
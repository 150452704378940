import {
  TableHead,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TextField,
  Box,
  TablePagination,
} from "@material-ui/core";
import React, { Component } from "react";
import { apiBatchRequest, apiBatchRequestWithoutLoader, CancelRequest, RenewCancelToken } from "../../utils/api_service";
import { API_URL } from "../../constants/api";
import commonLabels from "../../constants/messageConstants";
import { ShowToast, toastType } from "../../utils/toast-service";

import Reports from "./Reports";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "../Reports/report.less";
import DateFnsUtils from "@date-io/date-fns";
import { DateFormat, SECTIONS } from "../../constants/appConstants";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import jsPDF from "jspdf";
import "jspdf-autotable";
import PageHeader from "../common/PageHeader";
import BackspaceIcon from "@material-ui/icons/Backspace";
import {
  EnhancedTableHead,
  generatePDFFromHtml,
  getComparator,
  stableSort,
  getCurrentPayCycleEndDateByDate,
  getCurrentPayCycleStartDateByDate,
  removeDuplicates,
  calculateAvgerage,
  getTotalCount,
} from "../../utils/helper";
import { Icon } from "@iconify/react";
import bxFilterAlt from "@iconify/icons-bx/bx-filter-alt";
import filterIcon from "@iconify/icons-ci/filter";
import camelcaseKeys from "camelcase-keys";
import AdvancedFilter from "./AdvancedFilter";
import { downloadExcelReportByAPI, getMultilevelGroupedData } from "../../utils/generic";
import { getFormattedValuesForReports } from "../../utils/formatData";
import ReportButtons from "../../utils/ReportButtons";
import SearchBar from "../common/AutoSearch/SearchBar";

const uploadColumns = [
  {
    key: "buildingClass",
    name: "BuildingClass",
  },
  {
    key: "market",
    name: "Department",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "buildingOwner",
    name: "BuildingOwner",
  },
  {
    key: "subMarket",
    name: "SubMarket",
  },
  {
    key: "buildingName",
    name: "buildingName",
  },
  {
    key: "floorplate",
    name: "Hire date",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];

const headCells = [
  {
    id: "rsf",
    numeric: true,
    disablePadding: false, //JT
    label: "RSF",
  },
  {
    id: "vaccSf",
    numeric: true,
    disablePadding: false, //JT
    label: "Vac SF",
  },

  {
    id: "vacPercent",
    numeric: true, //JT
    disablePadding: false,
    label: "Vac %",
  },
  {
    id: "vacPercenthistAvg",
    numeric: true,
    disablePadding: false, //JT
    label: "Vac % Hist Avg",
  },
  {
    id: "subSf",
    numeric: true,
    disablePadding: false, //JT
    label: "Sub SF",
  },

  {
    id: "subletPercent",
    numeric: true, //JT
    disablePadding: false,
    label: "Sub %",
  },
  {
    id: "subsAbsYtd",
    numeric: true, //JT
    disablePadding: false,
    label: "Sub % Hist Avg",
  },
  {
    id: "ucRsf",
    numeric: true, //JT
    disablePadding: false,
    label: "UC- RSF",
  },
  {
    id: "ucVac",
    numeric: true, //JT
    disablePadding: false,
    label: "UC- Vac SF",
  },
  {
    id: "ucVacPercent",
    numeric: true, //JT
    disablePadding: false,
    label: "UC - Vac %",
  },
  {
    id: "effVacPercent",
    numeric: true, //JT
    disablePadding: false,
    label: "Eff Vac %",
  },
];
export default class ClassificationBreakdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId:this.props.globalMarketId,
      openFilter: false,
      showMain: false,
      enableFilterButton:false,
      marketAbsorptionReport: [],
      marketAbsorptionReport2: [],
      marketAbsorptionReport3: [],
      employeeSummary: [],
      startDate: moment(this.props.monthRecorded).format(DateFormat),
      endDate: moment(this.props.monthRecorded).format(DateFormat),
      // startDate: moment("2020-12-01").startOf("month").format(DateFormat),
      // endDate: moment("2021-02-01").endOf("month").format(DateFormat),
      // startDate: moment().subtract(8, 'month').format(DateFormat),
      // endDate: moment().subtract(5, 'month').format(DateFormat),
      showReport: false,
      getMarketMaster: [],
      companyMasterDetails: [],
      getSubMarketMaster: [],
      getBuildingMaster: [],
      market: [],
      subMarket: [],
      buildingName: [],
      companyName: [],
      openSlidingPanel: false,
      isTotal: false,
      searchInput: "",
      rowsPerPage: 1,
      rowsPerPageOptions: [1 ,2, 300, 400, 500],
      page: 0,
      order: "asc",
      orderBy: "nam",
      rowsPerPageCOne: 100,
      pageCOne: 0,
      orderCOne: "asc",
      orderByCOne: "nam",
      rowsPerPageCTwo: 100,
      pageCTwo: 0,
      orderCTwo: "asc",
      orderByCTwo: "nam",
      topvalue: 68,
      selected: [],
      dense: false,
      responseMarketAbsorptionReport: [],
	  responseMarketAbsorptionReport2: [],
	  responseMarketAbsorptionReport3: [],
      advanceFilterConfig: {
        groupBy: {
          masterData: [],
          selected: [{ key: "Stream Market", value: "market" }],
        },
        selectedDate: {},
        suppressGraph: false,
        suppressZero: true,
        summaryRows: false,
        showZeroNGraph: true,
      },
      summaryCols: [
        "rsf",
        "vaccSf",
        "vacPercent",
        "vacPercenthistAvg",
        "subSf",
        "subletPercent",
        "subsAbsYtd",
        "ucRsf",
        "ucVac",
        "ucVacPercent",
        "effVacPercent",
      ],
    };

    this.baseState = this.state;
  }

  componentDidMount() {
    RenewCancelToken();
    //this.props.setNewMarketId --todo(this.GetMasterData);
    this.GetMasterData(this.state.selectedMarketId);
  }
  componentWillUnmount()
  {
    CancelRequest();
  }
  componentWillReceiveProps(nextProps) {    
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {    
      this.GetMasterData(nextProps.globalMarketId); 
		  this.setState({ selectedMarketId: nextProps.globalMarketId });
		}
	  }
  handleRequestSort = (e, property) => {
    if (e == "Two") {
      const isAsc =
        this.state.orderByCTwo === property && this.state.orderCTwo === "asc";
      this.setState({
        orderCTwo: isAsc ? "desc" : "asc",
        orderByCTwo: property,
      });
    } else if (e == "One") {
      const isAsc =
        this.state.orderByCOne === property && this.state.orderCOne === "asc";
      this.setState({
        orderCOne: isAsc ? "desc" : "asc",
        orderByCOne: property,
      });
    } else {
      const isAsc =
        this.state.orderBy === property && this.state.order === "asc";
      this.setState({
        order: isAsc ? "desc" : "asc",
        orderBy: property,
      });
    }
  };
  handleChangePage = (event, newPage, e) => {
    if (newPage != undefined) {
      if (e == "One") {
        this.setState({ pageCOne: newPage }, () => {
          this.forceUpdate();
        });
      } else if (e == "Two") {
        this.setState({ pageCTwo: newPage }, () => {
          this.forceUpdate();
        });
      } else {
        this.setState({ page: newPage }, () => {
          this.forceUpdate();
        });
      }
    }
  };

  handleChangeRowsPerPage = (event, e) => {
    if (e == "One") {
      this.setState({ rowsPerPageCOne: parseInt(event.target.value, 10), 
        pageCOne: 0 });
    } 
    else if(e=="Two")
    {
     
        this.setState({ rowsPerPageCTwo: parseInt(event.target.value, 10), pageCTwo: 0 });
      }
    else {
      this.setState({
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
      });
    }
  };
  goBack = () => {
    this.setState({
      showMain: true,
    });
  };
  setIsTotal = () => {
    this.setState({ isTotal: true });
  };
  handleDateChange = (date, name) => {
    if (name === "startDate") {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    } else {
      this.setState({
        [name]: moment(date).format(DateFormat),
      });
    }
  };

  GetMasterData = (selectedMarketId) => {
  
    this.getReport(selectedMarketId);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1,marketId:selectedMarketId },
        fromCache: true,
				duration: 60
      },
      // {
      //   url: API_URL.Master.companyMasterDetails,
      //   key: "companyMasterDetails",
      //   params: { businessLineId: 1 },
      //   fromCache: true,
			// 	duration: 60
      // },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1,marketId:selectedMarketId },
        fromCache: true,
				duration: 60
      },
      {
        url: API_URL.Master.getBuildingMaster,
        key: "getBuildingMaster",
        params: { businessLineId: 1 , marketId: selectedMarketId},
        fromCache: true,
				duration: 60
      },
    ])
      .then(
        ({
          getMarketMaster,
          getSubMarketMaster,
          getBuildingMaster,
         // companyMasterDetails,
        }) => {
          this._asyncRequest = null;
          if (getMarketMaster.success !== true) {
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
          this.setState(
            {
              getMarketMaster: camelcaseKeys(getMarketMaster.data) || [],
              getSubMarketMaster: camelcaseKeys(getSubMarketMaster.data) || [],
              getBuildingMaster: camelcaseKeys(getBuildingMaster.data) || [],
              // companyMasterDetails:
              //   camelcaseKeys(companyMasterDetails.data) || [],
                enableFilterButton:true,
            }
           
          );
        }
      )
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getReport = (marketId) => {
    const { market, buildingName, subMarket, companyName } = this.state;
    //const marketId =this.state.selectedMarketId ;// _.cloneDeep(market.map((x) => x.marketId));
    const buildingId = _.cloneDeep(buildingName.map((x) => x.buildingId));
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));
    const companyId = _.cloneDeep(companyName.map((x) => x.companyId));

    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const params = {
      startDate: moment(startDate).format(DateFormat),
      endDate: moment(endDate).format(DateFormat),
      marketId: marketId,//.join("|"),
      buildingId: buildingId.join("|"),
      subMarketId: subMarketId.join("|"),
      companyId: companyId.join("|"),
    };
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.classificationBreakdownByCompetetive,
        params,
        key: " marketAbsorptionReport",
      },
    ])
      .then(({ marketAbsorptionReport }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport: camelcaseKeys(
            marketAbsorptionReport.data || []
          ),
		  responseMarketAbsorptionReport:camelcaseKeys(
			marketAbsorptionReport.data || []
		),
          // employeeSummary:   marketAbsorptionReport.config,
          showReport: true,
          page:0,
          pageCTwo: 0,
          pageCOne: 0,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.classificationBreakdownByClassTier,
        params,
        key: " marketAbsorptionReport2",
      },
    ])
      .then(({ marketAbsorptionReport2 }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport2.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport2: camelcaseKeys(
            marketAbsorptionReport2.data || []
          ),
		  responseMarketAbsorptionReport2:camelcaseKeys(
			marketAbsorptionReport2.data || []
		),
          // employeeSummary:   marketAbsorptionReport.config,
          showReport: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.classificationBreakdownBySubmarket,
        params,
        key: " marketAbsorptionReport3",
      },
    ])
      .then(({ marketAbsorptionReport3 }) => {
        this._asyncRequest = null;
        if (marketAbsorptionReport3.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          marketAbsorptionReport3: camelcaseKeys(
            marketAbsorptionReport3.data || []
          ),
		  responseMarketAbsorptionReport3:camelcaseKeys(
			marketAbsorptionReport3.data || []
		),
          // employeeSummary:   marketAbsorptionReport.config,
          showReport: true,
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  getClassificationOrder(e) {
    if (e === "One") {
      return this.state.orderCOne;
    } else if (e === "Two") {
      return this.state.orderCTwo;
    } else return this.state.order;
  }
  getClassificationOrderBy(e) {
    if (e === "One") {
      return this.state.orderByCOne;
    } else if (e === "Two") {
      return this.state.orderByCTwo;
    } else return this.state.orderBy;
  }
  getClassificationPage(e) {
    if (e === "One") {
      return this.state.pageCOne;
    } else if (e === "Two") {
      return this.state.pageCTwo;
    } else return this.state.page;
  }
  getClassificationRowsPerPage(e) {
    if (e === "One") {
      return this.state.rowsPerPageCOne;
    } else if (e === "Two") {
      return this.state.rowsPerPageCTwo;
    } else return this.state.rowsPerPage;
  }
  renderTable = (marketAbsorptionReport, e, count) => {
    const groupSequence = this.getColumnSequence();

    const columnName =
      count == 1 ? "Competitive" : count == 2 ? "Class Tier" : "Submarket";
    const conditionalHeadCells = [
      {
        id: "classTier",
        numeric: false,
        disablePadding: false, //JT
        label: columnName,
      },
    ];
    return (
      <Table
        className="reportTable"
        id={"rptTable" + count}
        stickyHeader
        aria-label="sticky table"
      >
        <EnhancedTableHead
          numSelected={this.state.selected.length}
          order={this.getClassificationOrder(e)}
          orderBy={this.getClassificationOrderBy(e)}
          onRequestSort={(event,property)=>{this.handleRequestSort(e,property)}}
          rowCount={marketAbsorptionReport.length}
          columnSequence={groupSequence}
          headCells={headCells}
          conditionalHeadCells={conditionalHeadCells}
        />
        {/* <TableHead>
            <TableRow>
              {groupSequence.map((col, index) => (
                <TableCell key={"th" + col.value + index}>{col.key}</TableCell>
              ))}            
              <TableCell>{columnName}</TableCell> 
              <TableCell style={{ textAlign: 'right' }}>RSF</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Vac SF</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Vac %</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Vac % Hist Avg</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Sub SF</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Sub %</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Sub % Hist Avg</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>UC- RSF</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>UC- Vac SF</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>UC - Vac %</TableCell>  
              <TableCell style={{ textAlign: 'right' }}>Eff Vac %</TableCell>  
                          
            </TableRow>
          </TableHead> */}
        <TableBody>
          {
            marketAbsorptionReport.length == 0 ?( <TableRow >
              <TableCell><span>No Data Available</span></TableCell></TableRow>
                      ):(this.renderTableRow(
            this.state.rowsPerPage > 0
              ? stableSort(
                  marketAbsorptionReport || [],
                  getComparator(this.getClassificationOrder(e), this.getClassificationOrderBy(e))
                ).slice(
                  this.getClassificationPage(e) * this.getClassificationRowsPerPage(e),
                  this.getClassificationPage(e) * this.getClassificationRowsPerPage(e) +
                  this.getClassificationRowsPerPage(e)
                )
              : marketAbsorptionReport
          ))}
        </TableBody>
      </Table>
    );
  };

  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };

  resetPage = () => {
    this.setState({...this.baseState},this.componentDidMount);
    //this.componentDidMount();
  };

  handleSubmit = (e) => {
    // e.preventDefault();
    this.getReport(this.state.selectedMarketId);
  };

  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  handleChange = (e, value, name) => {
    this.setState({ [name]: value });
  };

  onAddvanceFilterChange = (advanceFilterConfig) => {
    this.setIsTotal();
    this.setState(
      {
        advanceFilterConfig: advanceFilterConfig,
        openSlidingPanel: false,
        // startDate: getCurrentPayCycleStartDateByDate(
        //   this.state.advanceFilterConfig.startDate
        // ),
        // endDate: getCurrentPayCycleEndDateByDate(
        //   this.state.advanceFilterConfig.end
        // ),
      },
      () => {
        this.getReport(this.state.selectedMarketId);
      }
    );
  };

  getColumnSequence = () => {
    return removeDuplicates(
      [
        ...this.state.advanceFilterConfig.groupBy.selected,
        ...this.state.advanceFilterConfig.groupBy.masterData,
      ],
      "key"
    );
  };

  adjustColumnSequence = (currentGroupedRow) => {
    this.state.advanceFilterConfig.groupBy.masterData.forEach((x) => {
      currentGroupedRow.push({ key: x.value });
    });
    return currentGroupedRow;
  };

  renderTableRow = (data, onlydata = false) => {
    return this.renderTableCell(
      getMultilevelGroupedData(
        data,
        this.state.advanceFilterConfig.groupBy.selected.map((x) => x.value),
        this.state.isTotal,
        this.state.summaryCols
      ),
      onlydata,
      []
    );
  };

  exportPDF = () => {
	const { subMarket } = this.state;
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));

	let startDate = moment(this.state.startDate).format("MM/01/YYYY");

	let SelectedData = [];

	  if (subMarketId && subMarketId.length) {
		let temp = subMarketId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			subMarketId: temp[i],
			subMarket: "",
			market: "",
			marketId: ""
		  });
		}
		let propObj = {
		  key: "subMarket",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	  
	  if (startDate != null && startDate != undefined) {
		let temp = startDate;
		let selectedProps = [];
		  selectedProps.push({
			startDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "startDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

	  downloadExcelReportByAPI(
		API_URL.SSRSExport.ClassficationBreakdownReport,
		"ClassificationBreakdownReportExport.pdf",
		SelectedData,
		this.state.selectedMarketId,
		"application/pdf",
		"report"
	  );  
  };

  downloadReport = () => {
	const { subMarket } = this.state;
    const subMarketId = _.cloneDeep(subMarket.map((x) => x.subMarketId));

	let startDate = moment(this.state.startDate).format("MM/01/YYYY");

	let SelectedData = [];

	  if (subMarketId && subMarketId.length) {
		let temp = subMarketId;
		let selectedProps = [];
		for (var i = 0; i < temp.length; i++) {
		  selectedProps.push({
			subMarketId: temp[i],
			subMarket: "",
			market: "",
			marketId: ""
		  });
		}
		let propObj = {
		  key: "subMarket",
		  type: "Dropdown",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }
	  
	  if (startDate != null && startDate != undefined) {
		let temp = startDate;
		let selectedProps = [];
		  selectedProps.push({
			startDate: temp,
			businessLineId: 1,
		  });
		
		let propObj = {
		  key: "startDate",
		  type: "datePicker",
		  isComaSeprated: false,
		  selected: selectedProps
		}
		SelectedData.push(propObj);
	  }

    downloadExcelReportByAPI(
		API_URL.SSRSExport.ClassficationBreakdownReport,
      "ClassificationBreakdownReportExport.xls",
      SelectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "report"
    );
  };

  renderTableCell = (
    groupedData,
    onlydata,
    currentGroupedRow = [],
    tableCell = [],
    rowCount = 0
  ) => {
    let column1 = "";

    //  let column4 = '';
    // let column5 = '';
    groupedData.forEach((d, i) => {
      if (d.data !== undefined) {
        currentGroupedRow = currentGroupedRow.filter((x) => x.key !== d.key);
        currentGroupedRow.push({ key: d.key, value: d[d.key] });
        this.renderTableCell(
          d.data,
          onlydata,
          currentGroupedRow,
          tableCell,
          rowCount
        );
      }
      const keyvalue = this.adjustColumnSequence(
        _.cloneDeep(currentGroupedRow)
      );
      const sequence = this.getColumnSequence();
      const evenOddClass =
        rowCount % 2 === 0 ? " rpt rpt-even " : " rpt rpt-odd ";
      const topBorderClass =
        column1 !==
          (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? ""
          ? " rpt-topBorder "
          : " ";

      const key = d.market + d.submarket + "_" + rowCount + "_" + i;
      if (d.data && d.summaryData != undefined) {
        if (
          d.summaryData.length > 0 &&
          (d.summaryData[0].isSummary === undefined ||
            (d.summaryData.length > 0 &&
              d.summaryData[0].isSummary === true &&
              this.state.advanceFilterConfig.summaryRows === true))
        ) {
          tableCell.push(
            <TableRow
              key={key}
              className={
                topBorderClass + (d.isGroup === true ? " parent-row" : "")
              }
            >
              <TableCell
                className={"st sticky " + evenOddClass + topBorderClass}
              >
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>

              {d.summaryData.map((m, ind) =>
                this.renderSummaryColumn(
                  d.summaryData,
                  m,
                  key + ind,
                  evenOddClass,
                  d.data.length,
                  d.data
                )
              )}
            </TableRow>
          );
        }
      }
      if (d.data === undefined) {
        if (onlydata) {
          const rowData = {};
          rowData[sequence[0].value] = getFormattedValuesForReports(
            column1 !== d[sequence[0].value] ? d[sequence[0].value] : "",
            "Text",
            true
          );

          rowData.classTier = d.classTier;
          rowData.rsf = d.rsf;
          rowData.vaccSf = d.vaccSf;
          rowData.vacPercent = d.vacPercent;
          rowData.vacPercenthistAvg = d.vacPercenthistAvg;
          rowData.subSf = d.subSf;
          rowData.subletPercent = d.subletPercent;
          rowData.subsAbsYtd = d.subsAbsYtd;
          rowData.ucRsf = d.ucRsf;
          rowData.ucVac = d.ucVac;
          rowData.ucVacPercent = d.ucVacPercent;
          rowData.effVacPercent = d.effVacPercent;
          tableCell.push(rowData);
        } else {
          tableCell.push(
            <TableRow key={key} className={topBorderClass}>
              <TableCell className={evenOddClass + topBorderClass}>
                {getFormattedValuesForReports(
                  column1 !== d[sequence[0].value] && d[sequence[0].value],
                  "Text"
                )}{" "}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.classTier === null
                  ? "NA"
                  : getFormattedValuesForReports(d.classTier, "Text")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.rsf, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.vaccSf, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.vacPercent === null
                  ? "NA"
                  : getFormattedValuesForReports(d.vacPercent, "Percent")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.vacPercenthistAvg === null
                  ? "NA"
                  : getFormattedValuesForReports(d.vacPercenthistAvg, "Percent")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.subSf, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.subletPercent === null
                  ? "NA"
                  : getFormattedValuesForReports(d.subletPercent, "Percent")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.subsAbsYtd, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                { getFormattedValuesForReports(d.ucRsf, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {getFormattedValuesForReports(d.ucVac, "Number")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.ucVacPercent === null
                  ? "NA"
                  : getFormattedValuesForReports(d.ucVacPercent, "Percent")}
              </TableCell>

              <TableCell className={evenOddClass + "rpt-topBorder"}>
                {d.effVacPercent === null
                  ? "NA"
                  : getFormattedValuesForReports(d.effVacPercent, "Percent")}
              </TableCell>
            </TableRow>
          );
        }
      }
      rowCount++;
      column1 =
        (keyvalue.find((x) => x.key === sequence[0].value) || {}).value ?? "";
    });
    return tableCell;
  };

  renderSummaryColumn = (summaryDetails, n, index, evenOddClass,count,data) => {
    const summaryDetail = summaryDetails.filter((x) => x === n);
    const tableCell = [];
    //  summaryDetail.forEach(m=>{
    tableCell.push(
      <>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "rsf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "rsf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.rsf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.rsf, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "vaccSf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "vaccSf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.vaccSf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.vaccSf, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "vacPercent"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "vacPercent" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.vacPercent === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.vacPercent,getTotalCount(data,count,'vacPercent')), "Percent")

                //getFormattedValuesForReports(n.vacPercent, "Percent")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "vacPercenthistAvg"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "vacPercenthistAvg" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.vacPercenthistAvg === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.vacPercenthistAvg,getTotalCount(data,count,'vacPercenthistAvg')), "Percent")

               // getFormattedValuesForReports(n.vacPercenthistAvg, "Percent")
              )}
            </div>
          ))}
        </TableCell>

        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "subSf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "subSf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.subSf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.subSf, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "subletPercent"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "subletPercent" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.subletPercent === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.subletPercent,getTotalCount(data,count,'subletPercent')), "Percent")

               // getFormattedValuesForReports(n.subletPercent, "Percent")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "subsAbsYtd"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "subsAbsYtd" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.subsAbsYtd === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.subsAbsYtd,getTotalCount(data,count,'subsAbsYtd')), "Percent")

               // getFormattedValuesForReports(n.subsAbsYtd, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "ucRsf"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "ucRsf" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.ucRsf === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.ucRsf, "Number")
              )}
            </div>
          ))}
        </TableCell>

        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "ucVac"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "ucVac" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.ucVac === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(n.ucVac, "Number")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "ucVacPercent"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "ucVacPercent" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.ucVacPercent === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.ucVacPercent,getTotalCount(data,count,'ucVacPercent')), "Percent")
                
               // getFormattedValuesForReports(n.ucVacPercent, "Percent")
              )}
            </div>
          ))}
        </TableCell>
        <TableCell
          className={evenOddClass + "right-border  rpt-topBorder"}
          key={index + "effVacPercent"}
        >
          {summaryDetail.map((n, ind) => (
            <div
              key={index + "effVacPercent" + ind}
              className={
                summaryDetail.length > 1 && ind !== summaryDetail.length - 1
                  ? "multi-pay-period"
                  : ""
              }
            >
              {" "}
              {n.effVacPercent === null ? (
                <span className="number-text">-</span>
              ) : (
                getFormattedValuesForReports(calculateAvgerage(n.effVacPercent,getTotalCount(data,count,'effVacPercent')), "Percent")

                //getFormattedValuesForReports(n.effVacPercent, "Percent")
              )}
            </div>
          ))}
        </TableCell>
      </>
    );
    //});

    return tableCell;
  };

  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  handleSearch = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput } = this.state;
    let searchData = this.state.responseMarketAbsorptionReport;
	let searchData2 = this.state.responseMarketAbsorptionReport2;
	let searchData3 = this.state.responseMarketAbsorptionReport3;
    let filteredData = searchData.filter((value) => {
        return (
			(value.classTier ? value.classTier.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
		//	(value.submarket ? value.submarket.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.market ? value.market.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||

			(value.rsf ? value.rsf.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.vaccSf ? value.vaccSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercent ? value.vacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercenthistAvg ? value.vacPercenthistAvg.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subSf ? value.subSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subletPercent ? value.subletPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subsAbsYtd ? value.subsAbsYtd.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucRsf ? value.ucRsf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVac ? value.ucVac.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVacPercent ? value.ucVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.effVacPercent ? value.effVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false)
		);
    });

	let filteredData2 = searchData2.filter((value) => {
		return (
      (value.classTier ? value.classTier.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
      //	(value.submarket ? value.submarket.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
        (value.market ? value.market.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.rsf ? value.rsf.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.vaccSf ? value.vaccSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercent ? value.vacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercenthistAvg ? value.vacPercenthistAvg.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subSf ? value.subSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subletPercent ? value.subletPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subsAbsYtd ? value.subsAbsYtd.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucRsf ? value.ucRsf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVac ? value.ucVac.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVacPercent ? value.ucVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.effVacPercent ? value.effVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false)
		);
	});

	let filteredData3 = searchData3.filter((value) => {
		return (
      (value.classTier ? value.classTier.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
      //	(value.submarket ? value.submarket.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
        (value.market ? value.market.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.rsf ? value.rsf.toString().toLowerCase().includes(searchInput.toLocaleLowerCase()) : false) ||
			(value.vaccSf ? value.vaccSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercent ? value.vacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.vacPercenthistAvg ? value.vacPercenthistAvg.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subSf ? value.subSf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subletPercent ? value.subletPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.subsAbsYtd ? value.subsAbsYtd.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucRsf ? value.ucRsf.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVac ? value.ucVac.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.ucVacPercent ? value.ucVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false) ||
      		(value.effVacPercent ? value.effVacPercent.toString().toLowerCase().includes(searchInput.toLowerCase()) : false)
		);
	});

    if (searchInput.length == 0){
		this.setState({ marketAbsorptionReport: this.state.responseMarketAbsorptionReport });
		this.setState({ marketAbsorptionReport2: this.state.responseMarketAbsorptionReport2 });
		this.setState({ marketAbsorptionReport3: this.state.responseMarketAbsorptionReport3 });
	}
    else{
		this.setState({ marketAbsorptionReport: filteredData,page:0, pageCTwo: 0,
      pageCOne: 0, });
		this.setState({ marketAbsorptionReport2: filteredData2 ,page:0, pageCTwo: 0,
      pageCOne: 0,});
		this.setState({ marketAbsorptionReport3: filteredData3 ,page:0, pageCTwo: 0,
      pageCOne: 0,});
	} 
  };

  render() {
    const {
      showMain,
      openFilter,
      marketAbsorptionReport,
      marketAbsorptionReport2,
      marketAbsorptionReport3,
      startDate,
      endDate,
      getMarketMaster,
      getSubMarketMaster,
     // companyMasterDetails,
      getBuildingMaster,
      showReport,
      searchInput,
      advanceFilterConfig,
      openSlidingPanel,
    } = this.state;

    return (
      <div style={{marginTop:`${this.props.marginTop}px`}}>
        {showMain ? (
          <Reports globalMarketId={this.state.selectedMarketId}></Reports>
        ) : (
          <>
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-6">
                {/* <PageHeader
                  isReportPage={true}
                  key={"Market_Report"}
                  btnText={"Reports"}
                  onBackClick={this.goBack}
                  title={"/ Classification Breakdown"}                 
                >
                  Market Absorption Report
                </PageHeader> */}
              </div>
              <div className="col-sm-5 col-md-5 col-lg-4">
                <SearchBar
                  size="large"
                  name="searchInput"
                  value={searchInput || ""}
                  onChange={this.handleSearch}
                  label="Search"
                  placeholder="Search market,submarket,etc."
                ></SearchBar>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-2">
                <AdvancedFilter
                  filterConfig={advanceFilterConfig}
                  onApplyFilter={this.onAddvanceFilterChange}
                  openSlidingPanel={openSlidingPanel}
                  handleClose={this.handleClose}
                  setIsTotal={this.setIsTotal}
                />
                <ReportButtons
                  resetPage={this.resetPage}
                  handleSubmit={this.handleSubmit}
                  handleClickOpen={this.handleClickOpen}
                  handlePrintClick={this.handlePrintClick}
                  exportPDF={this.exportPDF}
				  downloadExcelReport={this.downloadReport}
                  data={marketAbsorptionReport}
                  columnWidths={columnWidths}
                  uploadColumns={uploadColumns}
                  openFilter={this.state.openFilter}
                  enableFilter={this.enableFilter}
                  isReportPage={true}
                  isListingPage={false}
                  fileName={"AnnualAbsorptionReport.xlsx"}
                  enableFilterButton={this.state.enableFilterButton}
				  ParentSection={SECTIONS.Html_Reports}
				  ChildSectionForDownload={SECTIONS.Html_Reports_Download}
                />
              </div>
            </div>
            {getMarketMaster.length > 0 && openFilter == true && (
              <div style={{ width : "98%" }}>
              <Grid
                container
                spacing={2}
                className="no-print row bottom-xs report-filter"
              >
                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    value={this.state.market}
                    onChange={(e, val) => this.handleChange(e, val, "market")}
                    id="tags-standard"
                    options={getMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.market}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Stream Market
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Market"
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid item xs={12} lg={3}>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple
                    id="tags-standard"
                    value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "subMarket")
                    }
                    options={getSubMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.subMarket}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                        Stream Submarket
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Submarket"
                        />
                      </Box>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                   
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      className="filter-label"
                    >
                      <label htmlFor="filled-hidden-label-small">
                        Qtr ending
                      </label>

                      {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                       */}
                      <Grid container>
                        <KeyboardDatePicker
                          style={{
                            border: "1px solid #cecdcd",
                            borderRadius: "4px",
                          }}
                          // variant="inline"
                          margin="normal"
                          id="date-picker-dialog"
                          //label="Start Date"
                          variant="inline"
                          format="MM/dd/yyyy"
                          autoOk
                          maxDate={endDate}
                          name="startDate"
                          value={startDate}
                          onChange={(e) =>
                            this.handleDateChange(e, "startDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          className="w-100"
                        />
                      </Grid>
                    </Box>
                    {/* </Box> */}
                  </MuiPickersUtilsProvider>

                  {/* <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                      <label htmlFor="filled-hidden-label-small">End Date</label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  
           
                      <Grid container>
                        <KeyboardDatePicker
                          style={{ border: '1px solid #cecdcd', borderRadius: '4px' }}
                          margin="normal"
                          id="date-picker-dialog"
                          // label="End Date"
                          format="MM/dd/yyyy"
                          minDate={startDate}
                          name="endDate"
                          variant="inline"
                          value={endDate}
                          onChange={(e) => this.handleDateChange(e, 'endDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                          className="w-100"
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                    </Box> */}
                </Grid>

                <Grid
                  item
                  xs={12}
                  lg={3}
                   
                >
                  <button
                    className="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => this.handleSubmit()}
                  >
                    <span class="MuiButton-label">
                       <span class="tool-icon-text">FILTER REPORT</span>
                    </span>

                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </Grid>
              </Grid>
              </div>
            )}

            {showReport && marketAbsorptionReport == null && (
              <Grid container spacing={4}>
                <Grid
                  container
                  direction="row-reverse"
                  justify="center"
                  alignItems="stretch"
                >
                  <label className="item-big">No Data Available.</label>
                </Grid>
              </Grid>
            )}
            {showReport && (
              <>
                {marketAbsorptionReport !== null && (
                  <div
                    className="container"
                    style={{
                      marginTop: "1rem",
                      float: "left",
                      marginRight: "2rem",
                    }}
                  >
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport.length}
                      rowsPerPage={this.state.rowsPerPage}
                      page={this.state.page}
                      onChangePage={(event,newPage,e)=>{this.handleChangePage(event,newPage,null)}}
                      onChangeRowsPerPage={(event,e)=>{
                        this.handleChangeRowsPerPage(event,null)}}
                    />
                    <TableContainer
                      className="report-table-scroll"
                      component={Paper}
                    >
                      {this.renderTable(marketAbsorptionReport,null, 1)}
                    </TableContainer>
                  </div>
                )}
                {marketAbsorptionReport2 !== null && (
                  <div
                    className="container"
                    style={{ marginTop: "1rem", float: "left" }}
                  >
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport2.length}
                      rowsPerPage={this.state.rowsPerPageCOne}
                      page={this.state.pageCOne}
                      onChangePage={(event,newPage,e)=>{this.handleChangePage(event,newPage,"One")}}
                      onChangeRowsPerPage={(event,e)=>{
                        this.handleChangeRowsPerPage(event,"One")}}
                    />
                    <TableContainer
                      className="report-table-scroll"
                      component={Paper}
                    >
                      {this.renderTable(marketAbsorptionReport2,"One", 2)}
                    </TableContainer>
                  </div>
                )}

                {marketAbsorptionReport3 !== null && (
                  <div
                    className="container"
                    style={{ marginTop: "1rem", float: "left" }}
                  >
                    <TablePagination
                      className="pager no-print"
                      rowsPerPageOptions={this.state.rowsPerPageOptions}
                      component="div"
                      count={marketAbsorptionReport3.length}
                      rowsPerPage={this.state.rowsPerPageCTwo}
                      page={this.state.pageCTwo}
                      onChangePage={(event,newPage,e)=>{this.handleChangePage(event,newPage,"Two")}}
                      onChangeRowsPerPage={(event,e)=>{
                        this.handleChangeRowsPerPage(event,"Two")}}
                    />
                    <TableContainer
                      className="report-table-scroll"
                      component={Paper}
                    >
                      {this.renderTable(marketAbsorptionReport3, "Two",3)}
                    </TableContainer>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    );
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
import _ from 'lodash';
import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxLabel = (props) => {
  const {
    className,
    labelText,
    fieldKey,   
    isChecked,
    obj,
    onChangeValues,
    row,
    isEditable,
    isInAddMode, disabled } = props;
  let switchClass = className;
  const id = _.camelCase(props.id);

  if (props.large === true) {
    switchClass += ' switch--large';
  } else {
    switchClass += ' switch--small';
  }
  if (props.noText === true) {
    switchClass += ' switch--no-text';
  }
  if (props.theme === 'success') {
    switchClass += ' switch--success';
  }
  return (
    <>
     
      <div aria-label={id} className={switchClass}>
      
         {/* {isEditable == false && */}
          <Checkbox
            key={`checkbox:${isChecked}:${id}`}
            name = {id}   
            className={isEditable ? 'disabed-checkbox-MuiIconButton-label':null}         
            checked={isChecked}
            disabled={isEditable}
            onChange={(e) => {
              onChangeValues(e.target.checked, fieldKey, row, isInAddMode, obj);
            }}
            value = {isChecked}
           // className="switch__input"
            id={id}
          />
{/* } */}
          <div className="switch__outer__label">
          {labelText} 
           {/* <label>	{obj.isRequired == true ? <span className="span-mandatory">*</span>:null}
						</label> */}
          {/* <span className="switch__text" data-on="Yes" data-off="No"></span>
          <span className="switch__handle"></span> */}
       </div>
      </div>
    </>
  );
};

CheckboxLabel.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  label: PropTypes.label,
  noText: PropTypes.boolean,
  isChecked: PropTypes.boolean
};

CheckboxLabel.defaultProps = {
  className: 'switch',
  noText: false,
  isChecked: false
};

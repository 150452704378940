import React from "react";
import "./style.less";
import cx from "classnames";

export default function InputField({
	maxLength = 30,
	className = "",
	inputValue = "",
	onEdit,
	valueKey,
	type = "text",
	isValueToRight,
	placeholder = "",
	disabled = false
}) {
	return (
		<input
			type={type}
			maxLength={maxLength}
			placeholder={placeholder}
			className={cx(
				className,
				"form_editable_text_field",
				isValueToRight && "rightAlign"
			)}
			value={inputValue !== null ? inputValue : ""}
			onChange={e => onEdit(e.target.value, valueKey)}
			disabled={disabled}
		/>
	);
}

import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import SlidingPanel from "react-sliding-side-panel";
import { isDesktop } from "react-device-detect";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { removeDuplicates } from "../../../utils/helper";
import  "./setting.less";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default class SettingScreenDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMenu: {},

      configuration: this.props.filterSetting,
      pageSize: 20,
      rowPerSize: 0,
      masterData:  this.props.filterSetting.filter((m) => {
        if (m.isVisible == false) {
          return m;
        }
      }),
      selected:this.props.filterSetting.filter((m) => {
        if (m.isVisible == true) {
          return m;
        }
      }),
      selectedData: [],
    };
  }

  componentWillReceiveProps(prevProps) {
    if(prevProps.filterSetting !== this.props.filterSetting) {
      this.setState({configuration: this.props.filterSetting,
        selectedMenu:{},
        masterData:this.props.filterSetting.filter((m) => {
          if (m.isVisible == false) {
            return m;
          }
        }),
        selected:this.props.filterSetting.filter((m) => {
          if (m.isVisible == true) {
            return m;
          }
        })
      });
    }
  }
  componentWillUnmount() {
    this.setState({
      configuration:null,
      selected:null,
      masterData:null
    });
  }
  static propTypes = {
    prop: PropTypes,
    leftTextArea: [],
    handleRightArrow: PropTypes.func,
    handleDoubleRight: PropTypes.func,
    handleDoubleLeft: PropTypes.func,
    handleLeftArrow: PropTypes.func,
    rightTextArea: [],

    handleClose: PropTypes.func,
  };
  componentDidMount()
  {
   let masterData= this.props.filterSetting.filter((m) => {
      if (m.isVisible == false) {
        return m;
      }
    });
   let selected= this.props.filterSetting.filter((m) => {
      if (m.isVisible == true) {
        return m;
      }
    });
    this.setState({
      masterData:masterData,
      selected:selected
    });
  }
  handleChange = (e) => {
    this.setState({
      pageSize: e.target.value,
      rowPerSize: e.target.value,
    });
  };
  handleSettingClose=()=>
  {
    this.setState({
      masterData: this.props.filterSetting.filter((m) => {
        if (m.isVisible == false) {
          return m;
        }
      }),
      selected: this.props.filterSetting.filter((m) => {
        if (m.isVisible == true) {
          return m;
        }
      }),
    });
this.props.handleClose();
  }
  handleKeyChange = (dir) => {
    const { configuration, selectedMenu } = this.state;
    // let keyColumn=configuration.filter((m)=>{
    //     if(m.renderComponent)
    //     return m;
    // });
    // console.log('renderComponent',keyColumn);
    let masterData = this.state.masterData;
    let selected = this.state.selected;
    if (selectedMenu) {
      if (dir === "Left" || dir === "Right") {
        masterData = (masterData || []).filter(
          (x) => x.name !== selectedMenu.name
        );
        let masterLastElement=masterData.length > 0 ? masterData[masterData.length-1] : undefined;
        //console.log('masterLastElement',masterLastElement);
        selected = (selected || []).filter((x) => x.name !== selectedMenu.name);
        let selectedLastElement=selected.length > 0 ? selected[selected.length-1] : undefined;
        //console.log('selectedLastElement',selectedLastElement);
        let maxAttributeSortOrder=0;
        if(masterLastElement == undefined && selectedLastElement != undefined)
        {
          maxAttributeSortOrder=selectedLastElement.attributeSortOrder+1;
        }
        else if(masterLastElement != undefined && selectedLastElement == undefined)
        {
          maxAttributeSortOrder=masterLastElement.attributeSortOrder+1;
        }
        else 
        {
          if(masterLastElement != undefined && selectedLastElement != undefined)
          {
          if(masterLastElement.attributeSortOrder > selectedLastElement.attributeSortOrder)
          maxAttributeSortOrder=masterLastElement.attributeSortOrder+1;
          else
          maxAttributeSortOrder=selectedLastElement.attributeSortOrder+1;
          }
        }
        if (dir === "Right") {
          selectedMenu.isVisible = true;
          selectedMenu.attributeSortOrder= selected.length > 0 ? maxAttributeSortOrder : selectedMenu.attributeSortOrder;
          selected.push(selectedMenu);

        } else {
          selectedMenu.isVisible = false;
          selectedMenu.attributeSortOrder= masterData.length > 0 ? maxAttributeSortOrder : selectedMenu.attributeSortOrder;

          masterData.push(selectedMenu);
        }
      }
    }
    if (dir === "AllLeft") {
      if(masterData.length !=0)
      {var i=0;
        masterData.forEach((m)=>{
          i++;
m.attributeSortOrder=i;
        });
        let selectedAllLeft=masterData.length > 0 ? masterData[masterData.length-1] : undefined;
        var j=0;
        selected.forEach(m=>{
          j++;
          m.attributeSortOrder=selectedAllLeft.attributeSortOrder+j;
        });
      }
     
      masterData = removeDuplicates(
        [...(masterData || []), ...(selected || [])],
        "name"
      );
      selected = [];
    }
    if (dir === "AllRight") {
      if(selected.length !=0)
      {let i=0;
        selected.forEach((m)=>{
          i++;
m.attributeSortOrder=i;
        });
        let selectedAllRight=selected.length > 0 ? selected[selected.length-1] : undefined;
        let j=0;
        masterData.forEach(m=>{
          j++;
          m.attributeSortOrder=selectedAllRight.attributeSortOrder+j;
        });
      }
      selected = removeDuplicates(
        [...(selected || []), ...(masterData || [])],
        "name"
      );
      let selectedRecord = selected.filter((m) => {
        m.isVisible = true;
        return m;
      });
      selected = selectedRecord;
      masterData = [];
    }
    if (dir === "UP") {
      let findFirstElem = this.state.selected[0];
      if (
        selectedMenu.isVisible == true &&
        selectedMenu.attributeSortOrder > 1 &&
        selectedMenu.key != findFirstElem.key
      ) {
        selected = this.state.selected;
        let selectedMenuKey=selectedMenu.attributeSortOrder;
        let order = selected.filter((m) => {
          if (m.attributeSortOrder < selectedMenu.attributeSortOrder) return m;
        });
        let sortOrder = order != undefined ? order[order.length - 1].attributeSortOrder : null;
        let key = order[order.length - 1].key;
        selected = selected.filter((m) => {
          if (m.key == selectedMenu.key && sortOrder != null)
            //m.attributeSortOrder== selectedMenu.attributeSortOrder
            m.attributeSortOrder = sortOrder;
          if (m.key == key && sortOrder != null)
            //m.attributeSortOrder==sortOrder
            m.attributeSortOrder = selectedMenuKey;
          return m;
        });
        selected.sort((a, b) =>
          a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
        );
        //console.log("order", selected);
      }
      //let changeSortOrder=
    }
    if (dir === "Down") {
      let lastElement = this.state.selected.length - 1;
      let findLastElem = this.state.selected[lastElement];
      if (
        selectedMenu.isVisible == true &&
        selectedMenu.key != findLastElem.key
      ) {
        selected = this.state.selected;
        let order = selected.filter((m) => {
          if (m.attributeSortOrder > selectedMenu.attributeSortOrder) return m;
        });
        let sortOrder = order.length > 0 ? order[0].attributeSortOrder : 0;
        let key = order.length > 0 ? order[0].key : null;
        let selectedMenuKey=selectedMenu.attributeSortOrder;
        selected = selected.filter((m) => {
          if (m.key == selectedMenu.key && sortOrder != null && sortOrder != 0)
            //m.attributeSortOrder== selectedMenu.attributeSortOrder
            m.attributeSortOrder = sortOrder;
          if (m.key == key && sortOrder != null && sortOrder != 0)
            //m.attributeSortOrder==sortOrder
            m.attributeSortOrder = selectedMenuKey;
          return m;
        });
        selected.sort((a, b) =>
          a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
        );
        //console.log("order", selected);
      }
      //let changeSortOrder=
    }
    this.setState(
      {
        masterData: masterData.sort((a, b) =>
        a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
      ),
        selected: selected.sort((a, b) =>
        a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
      ),
        selectedData: selected,
      },
      () => this.forceUpdate()
    );
  };

  render() {
    const { configuration, selectedMenu, masterData, selected } = this.state;
    let masterDataIsVisible = configuration.filter((m) => {
      if (m.isVisible == false) {
        return m;
      }
    });
    let selectedDataIsVisible = configuration.filter((m) => {
      if (m.isVisible == true) {
        return m;
      }
    });

    return (
      <>
      { this.props.openSlidingPanel == true ?( <div className="advanced-filters" style={{marginTop: '35px'}}>
   
   {/* <div className="row">
   <div className="col lg-6 md-6">
     </div> */}
    <div className="menuSetting"
         // type={"right"}
         // open={this.props.openSlidingPanel}
          //size={isDesktop ? 31 : 36}
        >
          <div className="setting-container">
            <div className="header1 row">
              {" "}
              <div className="col-xs-11">Settings</div>
              <div className="col-xs-1 center-xs">
                {" "}
                <Tooltip title="Close">
                  <IconButton
                    aria-label={"CloseIconAddWidgetPanel"}
                    size="small"
                    onClick={this.handleClose}
                  >
                    {/* <CloseIcon fontSize="small" size="small" /> */}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="row widget-content">
              <div className="widget-holder col-lg-11 end-lg">
                <div
                  className="row col-lg-12"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <div className="group_all">
                    <div className="row">
                      <div className="col-sm-9" style={{ padding: "10px" }}>
                        <label id="demo-simple-select-filled-label">
                          Rows Per Page
                        </label>
                      </div>

                      {/* <FormControl variant="outlined" > */}
                      {/* <InputLabel id="demo-simple-select-filled-label">RowsPer Page</InputLabel> */}
                      <div className="col-sm-2">
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          value={this.state.pageSize}
                          onChange={this.handleChange}
                          style={{ fontSize: "14px", paddingTop: "4px" }}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                          <MenuItem value={35}>35</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                        </Select>
                      </div>
                      {/* </FormControl> */}
                    </div>

                    <div className="sub-heading" style={{marginRight:"40px"}}>
                      {/* <div className="group_available"> */}
                      <h4 style={{ marginRight: "60px" }}>Available</h4>
                      {/* </div> */}
                      {/* <div className="group_applied"> */}
                      <h4>Applied</h4>
                      {/* </div> */}
                    </div>

                    <div className="setting__area" style={{ height: "270px",marginLeft:'0em !important', marginRight:"40px" }}>
                      {/* style={{height:'100%',marginTop:'0px'}} */}
                      <div className="settingbox__shadow setting-screen">
                        {/* style={{height:'100%'}} */}
                        {masterData
                          .filter((y) => y.name !== undefined)
                          .map((x, i) => (
                            <ListItem
                              name={i}
                              button
                              selected={x.name === this.state.selectedMenu.name}
                              onClick={() => this.setState({ selectedMenu: x })}
                            >
                              {x.name}
                            </ListItem>
                          ))}
                      </div>

                      <div className="arrow__buttons">
                        <button
                          className="arrow__button"
                          onClick={() => {
                            this.handleKeyChange("Right");
                          }}
                        >
                          {">"}
                        </button>
                        <button
                          className="arrow__button"
                          onClick={() => {
                            this.handleKeyChange("AllRight");
                          }}
                        >
                          {">>"}
                        </button>
                        <button
                          className="arrow__button"
                          onClick={() => {
                            this.handleKeyChange("AllLeft");
                          }}
                        >
                          {"<<"}
                        </button>
                        <button
                          className="arrow__button"
                          onClick={() => {
                            this.handleKeyChange("Left");
                          }}
                        >
                          {"<"}
                        </button>
                        <button
                          className="arrow__button"
                          onClick={() => {
                            this.handleKeyChange("UP");
                          }}
                        >
                          <KeyboardArrowUpIcon style={{fontSize:'1.2rem'}}></KeyboardArrowUpIcon>
                        </button>
                        <button
                          className="arrow__button"
                          style={{ fontSize: "13px" }}
                          onClick={() => {
                            this.handleKeyChange("Down");
                          }}
                        >
                          <KeyboardArrowDownIcon style={{fontSize:'1.2rem'}}></KeyboardArrowDownIcon>
                        </button>
                      </div>
                      <div className="settingbox__shadow setting-screen">
                        {/* style={{height:'100%'}} */}
                        {selected
                          .filter((y) => y.name !== undefined)
                          .map((x, i) => (
                            <ListItem
                              key={i}
                              button
                              selected={x.name === this.state.selectedMenu.name}
                              onClick={() => this.setState({ selectedMenu: x })}
                            >
                              {x.name}
                            </ListItem>
                          ))}
                      </div>
                    </div>
                  </div>

                  <Grid
                    //container
                    //justify="center"
                    className="advanced__buttons"
                    style={{ marginTop: "2em",marginLeft: '2px' }}
                  >
                    <div style={{ display: "flex" }}>
                      <Button color="primary" onClick={this.props.handleClose}>
                        Cancel
                      </Button>
                      
                      <Button
                       style={{padding:'4px',marginRight:'5px'}}
                        variant="contained"
                        onClick={() => {
                          this.props.onRestoreToDefault(
                              this.state.masterData.length  > 0 ? this.state.masterData : this.state.selected                    
                          );
                        }}
                        color="primary"
                      >
                        Restore To Default
                      </Button>
                      <Button
                       style={{padding:'4px'}}
                        variant="contained"
                        onClick={() => {
                          this.props.onApplySetting(
                            this.state.selected,
                            this.state.rowPerSize
                          );
                        }}
                        color="primary"
                      >
                        Apply
                      </Button>
                    </div>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      // </div>
      ):null
  }
  </>
    );
  }
}

import * as actionType from './actionTypes';

const initialState = {
  userData: null,
  isLoading: false,
  userConfig:null
  //,selectedMarketId: ''
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_USER_DATA:
      return { ...state, userData: action.payload };
      case actionType.GET_USER_CONFIG:
        return { ...state, userConfig: action.payload };

    case actionType.SET_LOADER_SCREEN:
      return { ...state, isLoading: action.payload };

      // case actionType.GET_SELECTED_MARKET:
      //   return { ...state, selectedMarketId: action.payload };

    default:
      return state;
  }
};

export default reducer;

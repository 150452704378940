import React from "react";
import "./SpecSuiteDashboardStyle.less";
import {
  apiBatchRequestWithoutLoader,
  apiRequest,
} from "../../utils/api_service";
import { API_URL } from "../../constants/api";
import Divider from "@material-ui/core/Divider";
import PageHeader from "../common/PageHeader";
import {
  convertIntoDDFormat,
  getAllKeysValue,
  scrollToTop,
} from "../../utils/generic";
import camelcaseKeys from "camelcase-keys";
import { ShowToast } from "../../utils/toast-service";
import AutocompleteMultiSelect from "../common/AutocompleteMultiselect/autocomplete-multiselect";
import { getDate, getPrevNextDate } from "../../utils/date";
import RadioGroup from "../common/radio";
import MonthSlider from "../common/month-slider";
import moment from "moment";
import SpecSummaryInfo from "./spec-summary";

const radioArr = [
  { key: "mtdRadio", label: "Monthly", checked: true },
  { key: "yearRadio", label: "Yearly" },
];

export default class SpecSuiteDashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedMarketId: this.props.globalMarketId,
      marketInfo: "",
      subMarketInfo: "",
      isSelectAllMarket: true,
      sliderRadioSelected: "mtdRadio",
      specSummaryData: null,
      costPsfChart: [],
      tenantRsfChart: [],
      selectedDate: moment(new Date()).format("MM/03/YYYY"),
      selectedMinDate: moment(getDate(new Date())).format("MM/03/YYYY"),
      selectedMaxDate: moment(getPrevNextDate(getDate(new Date()), -6)).format(
        "MM/03/YYYY"
      ),
      getMarketMaster: [],
      getSubMarketMaster: [],
    };
  }

  componentWillMount() {
    this.getMasterData(this.state.selectedMarketId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getMasterData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  componentDidMount() {
    scrollToTop();
  }

  getAllPropertyList = (_startDate, _endDate) => {
    const {
      marketInfo,
      subMarketInfo,
      selectedMinDate,
      selectedMaxDate,
      sliderRadioSelected,
    } = this.state;
    const startDate = _startDate ? _startDate : selectedMinDate;
    const endDate = _endDate ? _endDate : selectedMaxDate;

    this.setState({
      specSummaryData: null,
      costPsfChart: [],
      tenantRsfChart: [],
    });
    const mIds = getAllKeysValue(marketInfo, "id");
    const subIds = getAllKeysValue(subMarketInfo, "id");

    apiRequest({
      url: API_URL.SpecSuites.specSummary,
      params: {
        marketIds: mIds.toString(),
        ...(subIds.length && { subMarketIds: subIds.toString() }),
        startDate,
        endDate,
      },
    }).then((data) => {
      if (data) {
        this.setState({ specSummaryData: data.data });
      } else {
        this.setState({ specSummaryData: [] });
      }
    });

    apiRequest({
      url: API_URL.SpecSuites.specChartData,
      params: {
        marketIds: mIds.toString(),
        ...(subIds.length && { subMarketIds: subIds.toString() }),
        startDate,
        endDate,
        type: sliderRadioSelected === "yearRadio" ? "yearly" : "monthly",
      },
    }).then((data) => {
      if (data && data.data) {
        this.setState({
          costPsfChart: data.data.costPsf,
          tenantRsfChart: data.data.tenantRsf,
        });
      }
    });
  };

  getMasterData = (selectedMarketId) => {
    this._asyncRequest = apiBatchRequestWithoutLoader([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1 },
      },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1 },
      },
    ])
      .then((getMarketMaster) => {
        this._asyncRequest = null;
        if (getMarketMaster.getMarketMaster.success == true) {
          this.setState({
            getMarketMaster: convertIntoDDFormat(
              camelcaseKeys(getMarketMaster.getMarketMaster.data),
              "marketId",
              "market"
            ),
            marketInfo: [
              {
                id: convertIntoDDFormat(
                  camelcaseKeys(getMarketMaster.getMarketMaster.data),
                  "marketId",
                  "market"
                )
                  .map((x) => x.id)
                  .toString(),
                value: "All Markets",
              },
            ],
            getSubMarketMaster: convertIntoDDFormat(
              camelcaseKeys(getMarketMaster.getSubMarketMaster.data),
              "subMarketId",
              "subMarket",
              "marketId"
            ),
          });
        } else {
          ShowToast(
            getMarketMaster.getMarketMaster.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  onJumpToPage = (page) => {
    this.props.history.push(page);
  };

  setDDValues = (p, key) => {
    this.setState({ [key]: p && p.length ? p : "" }, () => {
      if (key === "marketInfo") {
        const isAllMarket =
          p && p.length && p[p.length - 1].value === "All Markets";
        this.setState({ subMarketInfo: "", isSelectAllMarket: isAllMarket });
      }
      this.getAllPropertyList();
    });
  };

  onChangeOpsRadio = (currentSelection) => {
    this.setState({ sliderRadioSelected: currentSelection });
  };

  onChangeMonthRange = (startMonth, endMonth) => {
    const { sliderRadioSelected } = this.state;
    const startDate = moment(getDate(startMonth));
    const endDate = moment(getDate(endMonth));
    this.setState({
      selectedMinDate: startDate.format("MM/03/YYYY"),
      selectedMaxDate: endDate.format("MM/03/YYYY"),
    });
    switch (sliderRadioSelected) {
      case "yearRadio":
        this.getAllPropertyList(
          startDate.format("01/02/YYYY"),
          endDate.format("12/02/YYYY")
        );
        break;
      case "mtdRadio":
        this.getAllPropertyList(
          startDate.format("MM/03/YYYY"),
          endDate.format("MM/03/YYYY")
        );
        break;
      case "ytdRadio":
        this.getAllPropertyList(
          startDate.format("01/02/YYYY"),
          endDate.format("MM/03/YYYY")
        );
        break;
    }
  };

  render() {
    const {
      marketInfo,
      subMarketInfo,
      isSelectAllMarket,
      specSummaryData,
      costPsfChart,
      tenantRsfChart,
      sliderRadioSelected,
      selectedDate,
      getMarketMaster,
      getSubMarketMaster,
    } = this.state;

    return (
      <>
        <PageHeader title="Spec Suite Dashboard"></PageHeader>
        {/* <Divider className="divider-bg" orientation="vertical" flexItem /> */}
        <div className="row spec-summary">
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 select-boxes">
            <AutocompleteMultiSelect
              lbl="Market/Major Service Area"
              labelKey="value"
              configData={
                getMarketMaster.length
                  ? [
                      {
                        id: getMarketMaster.map((x) => x.id).toString(),
                        value: "All Markets",
                      },
                      ...getMarketMaster,
                    ]
                  : []
              }
              value={marketInfo ? marketInfo : []}
              setProperty={this.setDDValues}
              defaultValue=""
              extraParam="marketInfo"
            />
            <AutocompleteMultiSelect
              lbl="SubMarket"
              labelKey="value"
              configData={
                isSelectAllMarket
                  ? getSubMarketMaster
                  : getSubMarketMaster.filter(
                      (x) =>
                        getAllKeysValue(marketInfo, "id").indexOf(
                          parseInt(x.marketId)
                        ) !== -1
                    )
              }
              value={subMarketInfo ? subMarketInfo : []}
              setProperty={this.setDDValues}
              defaultValue=""
              extraParam="subMarketInfo"
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6 custom-slider-grp">
            <div className="slider-radio-group">
              <RadioGroup
                groupName="opsRadio"
                radioArr={radioArr}
                selected={sliderRadioSelected}
                onChangeRadio={this.onChangeOpsRadio}
              />
            </div>
            {getMarketMaster.length > 0 ? (
              <MonthSlider
                onChangeMonthRange={this.onChangeMonthRange}
                selectedDate={selectedDate}
                sliderView={
                  sliderRadioSelected === "yearRadio"
                    ? "year"
                    : sliderRadioSelected
                }
              />
            ) : (
              ""
            )}
          </div>
          <div>
            {specSummaryData ? (
              <SpecSummaryInfo
                specSummaryData={specSummaryData}
                costPsfChart={costPsfChart}
                tenantRsfChart={tenantRsfChart}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

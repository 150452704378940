import { combineReducers } from 'redux';
import layoutReducer from './layout/reducer';
import loaderReducer from './loader/reducer';
import userReducer from './user/reducer';
import authReducer from './auth/authReducer';
import localCache from './localCache/reducer';

const rootReducer = combineReducers({
  layoutConfig: layoutReducer,
  loaderConfig: loaderReducer,
  user: userReducer,
  auth: authReducer,
  localCache: localCache
});

const initialState = rootReducer({}, {});

const combineReducer = (state, action) => {
  if (action.type === 'AUTH_LOGOUT') {
    state = initialState;
  }
  return rootReducer(state, action);
};

export default combineReducer;

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import _ from 'lodash';
import { getFormattedValues } from '../../utils/formatData';

function descendingComparator (a, b, orderBy, convertToNumber) {
  const aValue = convertToNumber ? parseInt(a[orderBy] || 0) : a[orderBy] || '';
  const bValue = convertToNumber ? parseInt(b[orderBy] || 0) : b[orderBy] || '';

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy, convertToNumber) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy, convertToNumber)
    : (a, b) => -descendingComparator(a, b, orderBy, convertToNumber);
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead (props) {
  const {
    classes,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    headCells,
    headcellClass,
    tableConfig
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
//  console.log(headCells);
  return (
    <TableHead>
      <TableRow>
        {(headCells || []).map(headCell => {
          let tableHeadName = headCell.name;
          const cellObj = tableConfig.find(x => _.camelCase(x.dbColumn).toLowerCase() === headCell.key.toLocaleLowerCase());
          if (cellObj !== undefined) {
            tableHeadName = cellObj.uiColumn;
          }
          return (
            <TableCell
              key={headCell.key}
              sortDirection={orderBy === headCell.key ? order : false}
              className={headcellClass || ''}
              style={{ minWidth: headCell.width + 'px' }}
            >
              <TableSortLabel
                active={orderBy === headCell.key}
                style={{ textTransform: 'capitalize' }}
                direction={orderBy === headCell.key ? order : 'asc'}
                onClick={createSortHandler(headCell.key)}
              >
                {tableHeadName}
                {orderBy === headCell.key
                  ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                    )
                  : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: '1 1 100%'
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0
        ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
          )
        : (
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
          )}

      {numSelected > 0
        ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
          )
        : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
          )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '5px'
  },
  paper: {
    width: '100%',
    marginBottom: '5px',
    marginTop: '5px'
  },
  table: {
    minWidth: 750
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

export default function DenseTable ({
  columns,
  data,
  config = {},
  ignoreColumns = ['data'],
  tableConfig = []
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('nam');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);

  const [rowsPerPage, setRowsPerPage] = React.useState(
    config.PageSize ||
      (config != {} &&
        config.minRows != undefined &&
        data.length < config.minRows)
      ? data.length
      : 5
  );
  const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([
    5,
    10,
    25,
    50
  ]);
  const [showPager, setShowPager] = React.useState(
    !(config != {} && config.minRows != undefined && data.length < config.minRows)
  );

  const handleRequestSort = (event, property) => {
    // console.log(event);
    // console.log(orderBy);

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (data.length == 1) {
    let hasValue = false;
    Object.entries(data[0]).forEach(obj => {
      if (obj[1] !== null) hasValue = true;
    });
    if (hasValue !== true) {
      data = [];
    }
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  columns = columns.filter(
    ar =>
      (ignoreColumns || []).find(rm => _.camelCase(rm) === _.camelCase(ar.key)) === undefined &&
        _.camelCase(ar.key) !== _.camelCase('data') &&
        _.camelCase(ar.key).includes('Id', ar.key.length - 2) !== true
  );

  tableConfig = (tableConfig != null && tableConfig.length > 0)
    ? tableConfig.filter(
      ar =>
        (ignoreColumns || []).find(rm => _.camelCase(rm) === _.camelCase(ar.key)) === undefined &&
        _.camelCase(ar.key) !== _.camelCase('data') &&
        _.camelCase(ar.key).includes('Id', ar.dataFormat) !== true
    )
    : [];
//  console.log(tableConfig);
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              headCells={columns}
              headcellClass={config.headcellClass}
              tableConfig = {tableConfig}
            />
            <TableBody>
              {showPager &&
                stableSort(
                  data,
                  getComparator(order, orderBy, config.convertToNumber || false)
                )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        className={
                          index % 2 == 0
                            ? config.evenRowClass
                            : config.oddRowClass
                        }
                        key={'table_data' + index}
                      >
                        {(columns || []).map((col, i) => (
                          <TableCell
                            key={'tableRowCell' + index + i + col.key}
                            style={{ minWidth: col.width + 'px' }}
                          >
                            {col.renderComponent !== undefined
                              ? col.renderComponent(row, col.key)
                              : getFormattedData(tableConfig, row[col.key], col.key)}
                          </TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
              {showPager == false &&
                stableSort(
                  data,
                  getComparator(order, orderBy, config.convertToNumber || false)
                ).map((row, index) => {
                  return (
                    <TableRow
                      className={
                        index % 2 == 0
                          ? config.evenRowClass
                          : config.oddRowClass
                      }
                      key={'pagerfalsetable_data' + index}
                    >
                      {(columns || []).map((col, i) => (
                        <TableCell
                          key={'pagerfalsetableRowCell' + index + i + col.key}
                          style={{ minWidth: col.width + 'px' }}
                        >
                          {col.renderComponent !== undefined
                            ? col.renderComponent(row, col.key)
                            : getFormattedData(tableConfig, row[col.key], col.key)
                            }
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {data == [] ||
                (data.length == 0 && (
                  <TableRow key="emptyrow" style={{ height: dense ? 33 : 53 }}>
                    <TableCell key="emptyrowcell" colSpan={6}>
                      No data available
                    </TableCell>
                  </TableRow>
                ))}
              {showPager && emptyRows > 0 && (
                <TableRow
                  key="emptyrow1"
                  style={{ height: (dense ? 33 : 53) * emptyRows }}
                >
                  <TableCell key="emptyrowcell1" colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!config?.pagination && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </div>
  );
}

function getFormattedData (tableConfig, value, key) {
  const cellObj = tableConfig.find(x => _.camelCase(x.dbColumn).toLowerCase() === key.toLocaleLowerCase());
  if (cellObj !== undefined) {
    value = getFormattedValues(value, cellObj.dataFormat);
  }
  return value;
}

// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

// const useStyles = makeStyles({
//     table: {
//         minWidth: 650,
//     },
// });

// export default function DenseTable({ columns, data }) {
//     const classes = useStyles();

//     return (
//         <TableContainer component={Paper}>
//             <Table className={classes.table} size="small" aria-label="a dense table">
//                 <TableHead>
//                     <TableRow>
//                         {columns.map((col, i) => (
//                             <TableCell key={"tableCell" + i}>{col.name}</TableCell>))
//                         }
//                     </TableRow>
//                 </TableHead>
//                 <TableBody>
//                     {data.map((row, ir) => (
//                         <TableRow key={row.name}>
//                             {columns.map((col, i) => (
//                                 <TableCell key={"tableRowCell" + ir + i}>{row[col.key]}</TableCell>))
//                             }
//                         </TableRow>
//                     ))}
//                 </TableBody>
//             </Table>
//         </TableContainer>
//     );
// }

import camelcaseKeys from "camelcase-keys";
import _ from "lodash";
import React from "react";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { API_URL, getCurrentEnvironment } from "../../constants/api";
import {
  DataSheetGrid,
  checkboxColumn,
  textColumn,
  keyColumn,
  intColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";

import { apiBatchRequest, apiPost, apiRequest } from "../../utils/api_service";
import { ShowToast, toastType } from "../../utils/toast-service";
import { Box, Button, Grid, Paper, TextField, Tooltip, } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import PowerBIEmbedMine from "../common/PowerBIEmbed-PaginatedReport";
import powerBI from "../common/PowerBIEmbedControl/paginatedReportConfig.json";
import PageHeader from "../common/PageHeader";
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import moment from "moment-mini";
//simport { bigDealsColumns, leasingColumns } from "../../constants/MockData";
const dataSheetColumns = [

  // textColumn({ 
  //   title: 'Kulak No', 
  //   width: 1, 
  //   minWidth: 200, 
  //   key: 'statusId', 
  //   columnName: 'statusId', 
  //   render: d=> renderStyle(d.rowData['statusId']) })  ,
  // textColumn({ title: 'Album ID', key: 'statusId', render:  d=> this.renderStyle(d.rowData['statusId']) }),
  //{ key: "action", name: "Action" },
  // {
  //   key:'statusId',
  //   title: 'Active',
  //   render: ({rowData, rowIndex, columnIndex, setRowData}) => this.renderStyle(rowData, rowIndex, columnIndex, setRowData), 
  // },

  {
    ...keyColumn("assetClassification", textColumn),
    title: "Asset Classification",
  },
  {
    ...keyColumn("leasingInventory", textColumn),
    title: "Inventory",
  },
  {
    ...keyColumn("leasingAvailableSf", textColumn),
    title: "Available SF",
  },
  {
    ...keyColumn("leasingCurrentAvailability", textColumn),
    title: "Current Availability(%)",
  },
  {
    ...keyColumn("leasingAbsorption", textColumn),
    title: "Absorption",
  },
  {
    ...keyColumn("subleaseAvailableSf", textColumn),
    title: "Available SF",
  },
  {
    ...keyColumn("subleasePercentofInventory", textColumn),
    title: "Percent of Inventory(%)",
  },
  {
    ...keyColumn("subleaseAbsorption", textColumn),
    title: "Absorption",
  },
  //   {
  //     ...keyColumn('market', textColumn),
  //     title: 'Stream Market',
  //   },
  //   {
  //       ...keyColumn('submarket', textColumn),
  //       title: 'Stream Submarket',
  //     },
  //     {
  //         ...keyColumn('yearRecorded', textColumn),
  //         title: 'Year Recorded',
  //       },
  //       {
  //           ...keyColumn('quarterRecorded', textColumn),
  //           title: 'Quarter',
  //         },
];


const bigDealsColumns = [
  // {
  //   ...keyColumn('statusId', checkboxColumn),
  //   title: 'Active',
  // },
  {
    ...keyColumn("tenant", textColumn),
    title: "Tenant",
  },
  {
    ...keyColumn("sf", textColumn),
    title: "SF",
  },

];
const leasingColumns = [
  // {
  //   ...keyColumn('statusId', checkboxColumn),
  //   title: 'Active',
  // },
  {
    ...keyColumn("company", textColumn),
    title: "Company",
  },
  {
    ...keyColumn("buildingName", textColumn),
    title: "BuildingName",
  },
  {
    ...keyColumn("sf", textColumn),
    title: "SF",
  },

];

const investementColumns = [
  // {
  //   ...keyColumn('statusId', checkboxColumn),
  //   title: 'Active',
  // },
  {
    ...keyColumn("building", textColumn),
    title: "Building",
  },

  {
    ...keyColumn("sf", textColumn),
    title: "SF",
  },
  {
    ...keyColumn("percentLeased", textColumn),
    title: "Leased(%)",
  },
  {
    ...keyColumn("yearBuilt", textColumn),
    title: "YearBuilt",
  },
  {
    ...keyColumn("classTier", textColumn),
    title: "ClassTier",
  },
  {
    ...keyColumn("broker", textColumn),
    title: "Broker",
  },
  {
    ...keyColumn("ownerSeller", textColumn),
    title: "OwnerSeller",
  },
  {
    ...keyColumn("details", textColumn),
    title: "Details",
  },


];
const developmentColumns = [
  // {
  //   ...keyColumn('statusId', checkboxColumn),
  //   title: 'Active',
  // },
  {
    ...keyColumn("building", textColumn),
    title: "Building",
  },
  {
    ...keyColumn("sf", textColumn),
    title: "SF",
  },
  {
    ...keyColumn("percentLeased", textColumn),
    title: "Leased",
  },

  {
    ...keyColumn("deliveryMonthYear", textColumn),
    title: "Delivery (Month & Year)",
  },
  {
    ...keyColumn("developer", textColumn),
    title: "Developer",
  },
  {
    ...keyColumn("tenants", textColumn),
    title: "Tenants",
  },


];

export const vacancyColumns = [
  // {
  //   ...keyColumn('statusId', checkboxColumn),
  //   title: 'Active',
  // },
  {
    ...keyColumn("classTier", textColumn),
    title: "Class Tier",
  },
  {
    ...keyColumn("q2VacancyPercent", textColumn),
    title: "Q2 2021(%)",
  },
  {
    ...keyColumn("q3VacancyPercent", textColumn),
    title: "Q3 2021(%)",
  },
  {
    ...keyColumn("q4VacancyPercent", textColumn),
    title: "Q4 2021(%)",
  },

  {
    ...keyColumn("q1VacancyPercent", textColumn),
    title: "Q1 2022(%)",
  },

];


// const col=[{"assetClassification"},{"leasingInventory"},{leasingAvailableSf},{leasingCurrentAvailability},{quarterRecorded}
// ,{yearRecorded},{submarket},{market},{subleaseAbsorption},{subleasePercentofInventory},{subleaseAvailableSf},{leasingAbsorption}
// ];

export default class OfficeStatistics extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
     
      selectedMarketId: this.props.globalMarketId,
      houstonOfficeStatsChartData: {},
      submarketBreakdown1ChartData: {},
      getStatsData: [],
      getVacancyData: [],
      getBigDealData: [],
      getLeasingData: [],
      lstUIColumn: [],
      lstBigDeals: [],
      lstLeasingCol: [],
      lstDevelopmentCol: [],
      lstVacancyCol: [],
      //market:[],
      market: {
        market: "",
        marketId: this.props.globalMarketId
      },
      subMarket: [],
      quarter: null,
      yearRecorded: { yearRecorded: "" + moment().year() },
      quarterRecorded: { quarterRecorded: "Q"+moment().quarter() },
      getSubMarketMaster: [],
      getMarketMaster: [],
      getYearData: [],
      getQuarterData: [],
      getInvestementData: [],
      getDevelopmentData: [],
      currentSeletedTab: "tabStatistics",
      tabStatistics: true,
      tabPowerBi: false,
      bigDealsReport: false,
      leasingReport: false,
      investementReport: false,
      developmentReport: false,
      vacancyReport: false,
      grid: [
        [
          { readOnly: true, value: "" },
          { value: "Asset Classification", readOnly: true },
          { value: "Leasing Inventory", readOnly: true },
          { value: "Leasing Available SF", readOnly: true },
          { value: "Leasing Current Availability(%)", readOnly: true },
          { value: "Leasing Absorption", readOnly: true },
          { value: "Sublease Available SF", readOnly: true },
          { value: "Sublease Percent of Inventory(%)", readOnly: true },
          { value: "Sublease Absorption", readOnly: true },
          { value: "Stream Market", readOnly: true },
          { value: "Stream Submarket", readOnly: true },
          { value: "Year", readOnly: true },
          { value: "Quarter", readOnly: true },
        ],
        [
          { readOnly: true, value: 1 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
        ],
        [
          { readOnly: true, value: 2 },
          { value: 2 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
        ],
        [
          { readOnly: true, value: 3 },
          { value: 1 },
          { value: 3 },
          { value: 3 },
          { value: 3 },
        ],
        [
          { readOnly: true, value: 4 },
          { value: 2 },
          { value: 4 },
          { value: 4 },
          { value: 4 },
        ],
      ],
    };
  }

  handleTabChange = (e, value, name, current) => {
    if (name != current) {
      this.setState({
        [name]: true,
        [current]: false,
        currentSeletedTab: name,
      });
    }
    if (name == 'leasingReport') {
      //this.getLeasingData();
    }
  };
  valueRenderer = (cell) => cell.value;
  onCellsChanged = (changes) => {
    // const grid = this.state.grid;
    // changes.forEach(({ cell, row, col, value }) => {
    //   grid[row][col] = { ...grid[row][col], value };
    // });
    // this.setState({ grid });
    const grid = this.state.getStatsData;
    if (grid.length > 0) {
      changes.forEach(({ cell, row, col, value }) => {
        grid[row][col] = { ...grid[row][col], value };
      });
      this.setState({ getStatsData });
    }
  };

  setStatsData = (data, e) => {
    
    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getStatsData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      //this.setState({getStatsData:delData},()=>{this.forceUpdate()});
      this.saveUpdatedRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getStatsData: data });
    }
    else {
      //RptStatisticsImportId
      //this.setState({ getStatsData: data });
      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptStatisticsImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getStatsData: upData });
    }
  };
  setBigDealData = (data, e) => {

    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getBigDealData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      this.saveBigDealRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getBigDealData: data });
    }
    else {
      //RptBigDealsImportId
      //this.setState({ getBigDealData: data });
      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptBigDealsImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getBigDealData: upData });
    }
  };
  setLeasingData = (data, e) => {

    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getLeasingData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      this.saveLeasingRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getLeasingData: data });
    }
    else {
      //RptLeasingActivityImportId
      //this.setState({ getLeasingData: data });

      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptLeasingActivityImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getLeasingData: upData });
    }
  };
  setInvestementData = (data, e) => {

    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getInvestementData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      this.saveInvestementRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getInvestementData: data });
    }
    else {
      //RptInvestmentSalesImportId
      //this.setState({ getInvestementData: data });

      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptInvestmentSalesImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getInvestementData: upData });
    }
  };
  setDevelopmentData = (data, e) => {

    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getDevelopmentData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      this.saveDevelopmentRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getDevelopmentData: data });
    }
    else {
      //RptDevelopmentImportId
      //this.setState({ getDevelopmentData: data });

      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptDevelopmentImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getDevelopmentData: upData });
    }
  };
  setVacancyData = (data, e) => {

    if (e[0].type === 'DELETE') {
      let delData = JSON.parse(
        JSON.stringify([...this.state.getVacancyData]));
      delData[e[0].fromRowIndex].statusId = 'False';
      this.saveVacancyRecord(delData, 1);
    }
    else if(e[0].type === 'UPDATE')
    {
      this.setState({ getVacancyData: data });
    }
    else {
      let upData = JSON.parse(
        JSON.stringify([...data]));
        upData[e[0].fromRowIndex].rptVacanyAtGlanceImportId = null; //Math.floor(Math.random() * 10000) * -1;
      
      this.setState({ getVacancyData: upData });
    }
  };
  onContextMenu = (e, cell, i, j) =>
    cell.readOnly ? e.preventDefault() : null;

  componentDidMount() {    
    this.GetMasterData(this.state.selectedMarketId);
  }

  getLeasingData = (initialLoad) => {
    
    let marketId = this.state.selectedMarketId;
    let subMarketId = -1;
    let YearRecorded = moment().year();
    let quarterRecorded = 'Q' + moment().quarter();
    if (initialLoad != 1) {
      // if (this.state.market != undefined && this.state.market != null) {
      //   marketId = this.state.getMarketMaster.find(
      //     (x) => x.market == this.state.market.market
      //   ).marketId;
      // }
      if (this.state.subMarket != undefined &&
        this.state.subMarket != null
      ) {
        if (this.state.subMarket.length == 0) {

        }
        else {
          subMarketId = this.state.getSubMarketMaster.find(
            (x) => x.subMarket == this.state.subMarket.subMarket
          ).subMarketId;
        }
      }
      if (this.state.yearRecorded != undefined && this.state.yearRecorded != null)
        YearRecorded = this.state.yearRecorded.yearRecorded
      if (this.state.quarterRecorded != undefined && this.state.quarterRecorded != null)
        quarterRecorded = this.state.quarterRecorded.quarterRecorded

    }
    apiRequest(
      {
        url: API_URL.ChartPoc.getLeasingData,
        key: "getLeasingData",
        params: { marketId: initialLoad == 1 ? marketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
    ).then(({ data: getLeasingData }) => {
      this.setState({
        getLeasingData:
          camelcaseKeys(getLeasingData) || [],
      });
    })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      //alert(nextProps.globalMarketId);
      //this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });   
      this.setState({ marketId: nextProps.globalMarketId });   
      this.GetMasterData(nextProps.globalMarketId);
    }
  }

  getRequiredData = (initialLoad, selectedMarketId) => {
    if (selectedMarketId != "") 
    {
    let marketId = selectedMarketId;
    let subMarketId = -1;
    let YearRecorded = moment().year(); // '2022';
    
    let quarterRecorded = 'Q' + moment().quarter(); //'Q1';
    if (initialLoad != 1) {
      // if (this.state.market != undefined && this.state.market != null) {
      //   marketId = this.state.getMarketMaster.find(
      //     (x) => x.market == this.state.market.market
      //   ).marketId;
      // }
      if (this.state.subMarket != undefined &&
        this.state.subMarket != null
      ) {
        if (this.state.subMarket.length == 0) {

        }
        else {
          subMarketId = this.state.getSubMarketMaster.find(
            (x) => x.subMarket == this.state.subMarket.subMarket
          ).subMarketId;
        }
      }
      if (this.state.yearRecorded != undefined && this.state.yearRecorded != null)
        YearRecorded = this.state.yearRecorded.yearRecorded
      if (this.state.quarterRecorded != undefined && this.state.quarterRecorded != null)
        quarterRecorded = this.state.quarterRecorded.quarterRecorded

    }
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.ChartPoc.getStatsData,
        key: "getStatsData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
      {
        url: API_URL.ChartPoc.getBigDealData,
        key: "getBigDealData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
      {
        url: API_URL.ChartPoc.getLeasingData,
        key: "getLeasingData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
      {
        url: API_URL.ChartPoc.getInvestementData,
        key: "getInvestementData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
      {
        url: API_URL.ChartPoc.getDevelopmentData,
        key: "getDevelopmentData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
      {
        url: API_URL.ChartPoc.getVacancyData,
        key: "getVacancyData",
        params: { marketId: initialLoad == 1 ? selectedMarketId : marketId, submarketId: initialLoad == 1 ? -1 : subMarketId, year: initialLoad == 1 ? YearRecorded : YearRecorded, quarter: initialLoad == 1 ? quarterRecorded : quarterRecorded }
      },
    ])
      .then((getStatsData) => {
        this._asyncRequest = null;
        if (getStatsData.getStatsData.success === true) {
          let columns = []; let dataFormat = [];
          let columns1 = []; let dataFormat1 = [];
          let columns2 = []; let dataFormat2 = [];
          let columns3 = []; let dataFormat3 = [];
          let columns4 = []; let dataFormat4 = [];
          let columns5 = []; let dataFormat5 = [];
          camelcaseKeys(getStatsData.getStatsData.configuration).forEach(
            (m) => {              
              if (m.isVisible == 1) {
                columns.push(m.dbColumn);
                dataFormat.push(m.dataFormat);
              }
            }
          );
          camelcaseKeys(getStatsData.getBigDealData.configuration).forEach(
            (m) => {
              if (m.isVisible == 1) {
                columns1.push(m.dbColumn);
                dataFormat1.push(m.dataFormat);
              }
            }
          );
          camelcaseKeys(getStatsData.getLeasingData.configuration).forEach(
            (m) => {
              if (m.isVisible == 1) {
                columns2.push(m.dbColumn);
                dataFormat2.push(m.dataFormat);
              }
            }
          );
          camelcaseKeys(getStatsData.getInvestementData.configuration).forEach(
            (m) => {
              if (m.isVisible == 1) {
                columns3.push(m.dbColumn);
                dataFormat3.push(m.dataFormat);
              }
            }
          );
          camelcaseKeys(getStatsData.getDevelopmentData.configuration).forEach(
            (m) => {
              if (m.isVisible == 1) {
                columns4.push(m.dbColumn);
                dataFormat4.push(m.dataFormat);
              }
            }
          );
          camelcaseKeys(getStatsData.getVacancyData.configuration).forEach(
            (m) => {
              if (m.isVisible == 1) {
                columns5.push(m.dbColumn);
                dataFormat5.push(m.dataFormat);
              }
            }
          );
          this.setState({
            lstUIColumn: columns,
            lstDataFormat: dataFormat,
            lstBigDeals: columns1,
            lstBigDealDF: dataFormat1,
            lstLeasingCol: columns2,
            lstLeasingDF: dataFormat2,
            lstInvestementCol: columns3,
            lstInvestementDF: dataFormat3,
            lstDevelopmentCol: columns4,
            lstDevelopmentDF: dataFormat4,
            lstVacancyCol: columns5,
            lstVacancyDF: dataFormat5,
            getStatsData: camelcaseKeys(getStatsData.getStatsData.data) || [],
            getStatsDataMasterResponse:
              camelcaseKeys(getStatsData.getStatsData.data) || [],
            configuration:
              camelcaseKeys(getStatsData.getStatsData.configuration) || [],
            getBigDealData: camelcaseKeys(getStatsData.getBigDealData.data) || [],
            getLeasingData: camelcaseKeys(getStatsData.getLeasingData.data) || [],
            getInvestementData: camelcaseKeys(getStatsData.getInvestementData.data) || [],
            getDevelopmentData: camelcaseKeys(getStatsData.getDevelopmentData.data) || [],
            getVacancyData: camelcaseKeys(getStatsData.getVacancyData.data) || [],
            reloadTable: true,
          }, () => { this.forceUpdate() });


        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
    }
  };
  lowerFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
  deleteRecord = (newParam) => {
    apiPost({
      url: API_URL.ChartPoc.updateStatsData,
      params: {},
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast(
          "Record deleted successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.getRequiredData(0, this.state.selectedMarketId);
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  }
  saveUpdatedRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getStatsData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptStatisticsImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarket = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.market = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstUIColumn.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstUIColumn[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstUIColumn[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstUIColumn[i])] =
              m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().replace('%', '');
            if (
              m[this.lowerFirstLetter(this.state.lstUIColumn[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstUIColumn[i])] = m[
                this.lowerFirstLetter(this.state.lstUIColumn[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");
            if (m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().includes(",") && this.state.lstDataFormat[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstUIColumn[i])] = m[
                this.lowerFirstLetter(this.state.lstUIColumn[i])
              ].replaceAll(",", "");
          }
          // if (
          //   m[this.lowerFirstLetter(this.state.lstUIColumn[i])]
          //     .toString()
          //     .includes("(") &&
          //   m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().includes(")")
          // )
          //   m[this.lowerFirstLetter(this.state.lstUIColumn[i])] = m[
          //     this.lowerFirstLetter(this.state.lstUIColumn[i])
          //   ].toString()
          //     .replace("(", "-")
          //     .replace(")", "");
          // if (m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().includes(","))
          //   m[this.lowerFirstLetter(this.state.lstUIColumn[i])] = m[
          //     this.lowerFirstLetter(this.state.lstUIColumn[i])
          //   ].replace(",", "");
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateStatsData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };

  saveBigDealRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getBigDealData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptBigDealsImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarketId = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.marketId = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstBigDeals.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstBigDeals[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstBigDeals[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstBigDeals[i])] =
              m[this.lowerFirstLetter(this.state.lstBigDeals[i])].toString().replace('%', '');
            if (
              m[this.lowerFirstLetter(this.state.lstBigDeals[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstBigDeals[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstBigDeals[i])] = m[
                this.lowerFirstLetter(this.state.lstBigDeals[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");
            if (m[this.lowerFirstLetter(this.state.lstBigDeals[i])].toString().includes(",") && this.state.lstBigDealDF[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstBigDeals[i])] = m[
                this.lowerFirstLetter(this.state.lstBigDeals[i])
              ].replaceAll(",", "");
          }
          // if (
          //   m[this.lowerFirstLetter(this.state.lstBigDeals[i])]
          //     .toString()
          //     .includes("(") &&
          //   m[this.lowerFirstLetter(this.state.lstBigDeals[i])].toString().includes(")")
          // )
          //   m[this.lowerFirstLetter(this.state.lstBigDeals[i])] = m[
          //     this.lowerFirstLetter(this.state.lstBigDeals[i])
          //   ].toString()
          //     .replace("(", "-")
          //     .replace(")", "");
          // if (m[this.lowerFirstLetter(this.state.lstUIColumn[i])].toString().includes(","))
          //   m[this.lowerFirstLetter(this.state.lstUIColumn[i])] = m[
          //     this.lowerFirstLetter(this.state.lstUIColumn[i])
          //   ].replace(",", "");
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateBigDealData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };

  saveLeasingRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getLeasingData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptLeasingActivityImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarketId = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.marketId = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstLeasingCol.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstLeasingCol[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstLeasingCol[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstLeasingCol[i])] =
              m[this.lowerFirstLetter(this.state.lstLeasingCol[i])].toString().replace('%', '');

            if (
              m[this.lowerFirstLetter(this.state.lstLeasingCol[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstLeasingCol[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstLeasingCol[i])] = m[
                this.lowerFirstLetter(this.state.lstLeasingCol[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");
            if (m[this.lowerFirstLetter(this.state.lstLeasingCol[i])].toString().includes(",") && this.state.lstLeasingDF[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstLeasingCol[i])] = m[
                this.lowerFirstLetter(this.state.lstLeasingCol[i])
              ].replaceAll(",", "");
          }
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateLeasingData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };
  saveInvestementRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getInvestementData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptInvestmentSalesImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarketId = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.marketId = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstInvestementCol.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstInvestementCol[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstInvestementCol[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstInvestementCol[i])] =
              m[this.lowerFirstLetter(this.state.lstInvestementCol[i])].toString().replace('%', '');
            if (
              m[this.lowerFirstLetter(this.state.lstInvestementCol[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstInvestementCol[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstInvestementCol[i])] = m[
                this.lowerFirstLetter(this.state.lstInvestementCol[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");
            if (m[this.lowerFirstLetter(this.state.lstInvestementCol[i])].toString().includes(",") && this.state.lstInvestementDF[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstInvestementCol[i])] = m[
                this.lowerFirstLetter(this.state.lstInvestementCol[i])
              ].replaceAll(",", "");
          }
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateInvestementData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };

  saveDevelopmentRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getDevelopmentData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptDevelopmentImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarketId = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.marketId = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstDevelopmentCol.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])] =
              m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])].toString().replace('%', '');
            if (
              m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])] = m[
                this.lowerFirstLetter(this.state.lstDevelopmentCol[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");
            if (m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])].toString().includes(",") && this.state.lstDevelopmentDF[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])] = m[this.lowerFirstLetter(this.state.lstDevelopmentCol[i])
              ].replaceAll(",", "");

          }
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateDevelopmentData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };
  saveVacancyRecord = (data, bit = 0) => {
    if (this.state.market.length == 0 || this.state.quarterRecorded.length == 0 || this.state.yearRecorded.length == 0) {
      ShowToast("Please select market,quarter and year recorded.", toastType.ERROR, 5000);
    }
    else {
      const newParam = bit == 0 ? JSON.parse(JSON.stringify([...this.state.getVacancyData])) : data;
      newParam.map((m) => {
        if (m != undefined && m.rptVacanyAtGlanceImportId == undefined) {
          m.yearRecorded = parseInt(this.state.yearRecorded.yearRecorded);
          m.submarketId = this.state.subMarket.length == 0 ? null : this.state.subMarket.subMarketId.toString();
          m.quarterRecorded = this.state.quarterRecorded.quarterRecorded;
          m.marketId = this.state.selectedMarketId.toString();
        }
        for (var i = 0; i < this.state.lstVacancyCol.length; i++) {
          if (
            m[this.lowerFirstLetter(this.state.lstVacancyCol[i])] != undefined &&
            m[this.lowerFirstLetter(this.state.lstVacancyCol[i])] != null
          ) {
            m[this.lowerFirstLetter(this.state.lstVacancyCol[i])] =
              m[this.lowerFirstLetter(this.state.lstVacancyCol[i])].toString().replace('%', '');
            if (
              m[this.lowerFirstLetter(this.state.lstVacancyCol[i])]
                .toString()
                .includes("(") &&
              m[this.lowerFirstLetter(this.state.lstVacancyCol[i])].toString().includes(")")
            )
              m[this.lowerFirstLetter(this.state.lstVacancyCol[i])] = m[
                this.lowerFirstLetter(this.state.lstVacancyCol[i])
              ].toString()
                .replace("(", "-")
                .replace(")", "");

            if (m[this.lowerFirstLetter(this.state.lstVacancyCol[i])].toString().includes(",") && this.state.lstVacancyDF[i] != 'Text')
              m[this.lowerFirstLetter(this.state.lstVacancyCol[i])] = m[this.lowerFirstLetter(this.state.lstVacancyCol[i])
              ].replaceAll(",", "");
          }
        }
      });
      apiPost({
        url: API_URL.ChartPoc.updateVacancyData,
        params: {},
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            this.state.isInAddMode
              ? "Record added successfully."
              : "Record updated successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.getRequiredData(0, this.state.selectedMarketId);
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    }
  };
  getReport = () => {
    this.getRequiredData(0, this.state.selectedMarketId);
  }
  GetMasterData = (selectedMarketId) => {
    if (selectedMarketId != "") 
    {
		const env = getCurrentEnvironment().environment;
      const reportConfig = powerBI[env];
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
        params: { businessLineId: 1, marketId: selectedMarketId },
        fromCache: true,
        duration: 60
      },
      {
        url: API_URL.ChartPoc.getYearData,
        key: "getYearData",
        // params: { businessLineId: 1 },
        fromCache: true,
        duration: 60
      },
      {
        url: API_URL.ChartPoc.getQuarterData,
        key: "getQuarterData",
        //params: { businessLineId: 1 },
        fromCache: true,
        duration: 60
      },
      // {
      //   url: API_URL.Master.companyMasterDetails,
      //   key: "companyMasterDetails",
      //   params: { businessLineId: 1 },
      //   fromCache: true,
      // 	duration: 60
      // },
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1, marketId: selectedMarketId },
        fromCache: true,
        duration: 60
      },
      // {
      //   url: API_URL.powerBi.houstonOfficeStats,
      //   key: "houstonOfficeStatsChartData",
      //   params: {
      //     groupId: "c8e9573b-64e0-4d74-9ebd-b50a7cf6ca25",
      //     reportId: "e4af8f08-7ea7-4745-8651-b24e32c10f1e",
      //   },
      // },
      {
        url: API_URL.powerBi.houstonOfficeStats,
        key: "submarketBreakdown1ChartData",
        params: {
          groupId: reportConfig.groupId,
          reportId: reportConfig.reportId,
        },
      },
    ])
      .then(
        ({
          getMarketMaster,
          getSubMarketMaster,
          getYearData,
          getQuarterData,
          //houstonOfficeStatsChartData,
          submarketBreakdown1ChartData
        }) => {
          this._asyncRequest = null;
          if (getMarketMaster.success !== true) {
            ShowToast(
              commonLabels.somethingWrong,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
          this.setState(
            {
              getMarketMaster: camelcaseKeys(getMarketMaster.data) || [],
              getSubMarketMaster: camelcaseKeys(getSubMarketMaster.data) || [],
              getYearData: camelcaseKeys(getYearData.data) || [],
              getQuarterData: camelcaseKeys(getQuarterData.data) || [],
              // houstonOfficeStatsChartData: {
              //   ...houstonOfficeStatsChartData.data
              // },
              submarketBreakdown1ChartData: {
                ...submarketBreakdown1ChartData
                  .data
              },
              market: getMarketMaster.data[0]
            });
          this.getRequiredData(1, selectedMarketId);
        }
      )
      .catch(() => {
        this._asyncRequest = null;
      });
    }
  };
  handleSubmit = (e) => {
    //     console.log('tab',this.state.currentSeletedTab);
    //     // e.preventDefault();
    //     if(this.state.currentSeletedTab == 'leasingReport')
    //     {
    // this.getLeasingData(0);
    //     }
    //     else
    this.getRequiredData(0, this.state.selectedMarketId);
  };
  handleChange = (e, value, name) => {
    this.setState({ [name]: value });
  };
  getCellActions = (column, row) => {
    const cellActions = [
      {
        icon: <span className="glyphicon glyphicon-remove" />,
        callback: () => {
          const rows = [...this.state.getStatsData];
          rows.splice(row.index, 1); //
          this.setState({ rows: rows });
        }
      }
    ];
    return column.key === "action" ? cellActions : null;
  };
  deleteRow = (eve) => {
    console.log('del', eve);
  }
  render() {
    let getYearRecord = this.state.getYearData.filter((m) => {
      if (m.yearRecorded != null) return m;
    })
    let getQuarter = this.state.getQuarterData.filter((m) => {
      if (m.quarterRecorded != null) return m;
    })
    return (
      <div>
        <PageHeader title="Office Statistics"></PageHeader>
        {/* <div style={{ width: "50%", float:"left" }}> */}

        <div>
        <div className="row">
        <div style={{width:"97%"}}>
          <Grid
            container
            spacing={2}
            className="no-print row bottom-xs report-filter"
          >
            <Grid item xs={12} lg={3}>
              {this.state.getMarketMaster.length > 0 && (
                <div>
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple={false}
                    value={this.state.market}
                    onChange={(e, val) => this.handleChange(e, val, "market")}
                    id="tags-standard"
                    // defaultValue={{
                    //   market: "SHO - Houston",                     

                    // }}
                    options={this.state.getMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.market}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Stream Market
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Market"
                        />
                      </Box>
                    )}
                  />
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple={false}
                    id="tags-standard"
                    value={this.state.subMarket}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "subMarket")
                    }
                    options={this.state.getSubMarketMaster}
                    limitTags={2}
                    getOptionLabel={(option) => option.subMarket}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Stream Submarket
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Stream Submarket"
                        />
                      </Box>
                    )}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={12} lg={3}>
              {getYearRecord.length > 0 && (
                <div>

                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple={false}
                    id="tags-standard"
                    value={this.state.yearRecorded}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "yearRecorded")
                    }
                    options={this.state.getYearData}
                    limitTags={2}
                    getOptionLabel={(option) => option.yearRecorded}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Year Recorded
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Year Recorded"
                        />
                      </Box>
                    )}
                  />
                  <Autocomplete
                    size="small"
                    underlineStyle={{ display: "none" }}
                    multiple={false}
                    id="tags-standard"
                    value={this.state.quarterRecorded}
                    onChange={(e, val) =>
                      this.handleChange(e, val, "quarterRecorded")
                    }
                    options={getQuarter}
                    limitTags={2}
                    getOptionLabel={(option) => option.quarterRecorded}
                    renderInput={(params) => (
                      <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        className="filter-label"
                      >
                        <label htmlFor="filled-hidden-label-small">
                          Quarter Recorded
                        </label>
                        <TextField
                          {...params}
                          variant="outlined"
                          margin="normal"
                          hiddenLabel
                          placeholder="Quarter Recorded"
                        />
                      </Box>
                    )}
                  />
                </div>

              )}
            </Grid>


            <Grid
              item
              xs={12}
              lg={3}

            >
              {getYearRecord.length > 0 && (
                <button
                  className="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                  onClick={() => this.handleSubmit()}
                >
                  <span class="MuiButton-label">
                    <span class="tool-icon-text">FILTER REPORT</span>
                  </span>

                  <span class="MuiTouchRipple-root"></span>
                </button>
              )}
            </Grid>
          </Grid>
          </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-10">
              <div
                className="tabs-forStats header-container"
                style={{paddingRight: "1px" }}
              >
                <div class="scroll">
                  
                    
                    <Tooltip title="Statistics">
                      <a
                        className={
                          this.state.tabStatistics ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "tabStatistics",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Statistics
                      </a>
                    </Tooltip>

                    <Tooltip title="Vacancy at a Glance">
                      <a
                        className={
                          this.state.vacancyReport ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "vacancyReport",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Vacancy at a Glance
                      </a>
                    </Tooltip>
                 
                    {/* // this.state.subMarket != null && this.state.subMarket != undefined 
                    // && this.state.subMarket.subMarket != 'Houston' */}

                    <Tooltip title="Big Deals">
                      <a
                        className={
                          this.state.bigDealsReport ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "bigDealsReport",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Big Deals
                      </a>
                    </Tooltip>
                  
                  
                    <Tooltip title="Leasing Activity(20,000SF +)">
                      <a
                        className={
                          this.state.leasingReport ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "leasingReport",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Leasing Activity(20,000SF +)
                      </a>
                    </Tooltip>
                  
                  
                    <Tooltip title="Investment Sales">
                      <a
                        className={
                          this.state.investementReport ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "investementReport",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Investment Sales
                      </a>
                    </Tooltip>
                  
                  
                    <Tooltip title="Development">
                      <a
                        className={
                          this.state.developmentReport ? "tab-capsule selected" : "tab-capsule"
                        }
                        aria-label={"refreshData"}
                        size="small"
                        onClick={(e, val) =>
                          this.handleTabChange(
                            e,
                            val,
                            "developmentReport",
                            this.state.currentSeletedTab
                          )
                        }
                      >
                        Development
                      </a>
                    </Tooltip>
                  
					<Tooltip title="Paginated Reports">
                    <a
                      className={
                        this.state.tabPowerBi ? "tab-capsule selected" : "tab-capsule"
                      }
                      aria-label={"refreshData"}
                      size="small"
                      onClick={(e, val) =>
                        this.handleTabChange(
                          e,
                          val,
                          "tabPowerBi",
                          this.state.currentSeletedTab
                        )
                      }
                    >
                      Paginated Reports
                    </a>
                  </Tooltip>
                   
                  
                </div>
              </div>
            </div>
          </div>
          <Paper variant="outlined" square>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              {/* {
                 this.state.getStatsData.length == 0 && (
                  <div style={{width:"97%"}}>
                    No data available
                  </div>
                )
              } */}
              {this.state.tabStatistics == true && (
                <div>
                  <DataSheetGrid
                    deleteRow={this.deleteRow}
                    getCellActions={this.getCellActions}
                    value={this.state.getStatsData}
                    onChange={(e, data, event) => { this.setStatsData(e, data, event) }}
                    columns={dataSheetColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveUpdatedRecord();
                    }}
                  >
                     <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE STATISTICS DATA</span>
                    </span>

                    <span class="MuiTouchRipple-root"></span>
                    
                  </button>
                </div>
              )}
              {this.state.tabPowerBi == true && (
               <div>
               <PowerBIEmbedMine config={this.state.submarketBreakdown1ChartData}></PowerBIEmbedMine>
                </div>
              )}

              {this.state.bigDealsReport == true && (
                <div>
                  <DataSheetGrid
                    value={this.state.getBigDealData}
                    onChange={this.setBigDealData}
                    columns={bigDealsColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveBigDealRecord();
                    }}
                  >
                    <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE BIG DEALS</span>
                    </span>

                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              )}
              {this.state.leasingReport == true && (
                <div>
                  <DataSheetGrid
                    value={this.state.getLeasingData}
                    onChange={this.setLeasingData}
                    columns={leasingColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveLeasingRecord();
                    }}
                  >
                    <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE LEASING DATA</span>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              )}
              {this.state.investementReport == true && (
                <div>
                  <DataSheetGrid
                    value={this.state.getInvestementData}
                    onChange={this.setInvestementData}
                    columns={investementColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveInvestementRecord();
                    }}
                  >
                     <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE INVESTMENT DATA</span>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              )}

              {this.state.developmentReport == true && (
                <div>
                  <DataSheetGrid
                    value={this.state.getDevelopmentData}
                    onChange={this.setDevelopmentData}
                    columns={developmentColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveDevelopmentRecord();
                    }}
                  >
                    <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE DEVELOPMENT DATA</span>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              )}
              {this.state.vacancyReport == true && (
                <div>
                  <DataSheetGrid
                    value={this.state.getVacancyData}
                    onChange={this.setVacancyData}
                    columns={vacancyColumns}
                  />
                  <button class="MuiButtonBase-root MuiButton-root MuiButton-text action-button no-print MuiButton-textSizeSmall MuiButton-sizeSmall"
                    onClick={() => {
                      this.saveVacancyRecord();
                    }}
                  >
                   <span class="MuiButton-label">
                       <span class="tool-icon-text">SAVE VACANCY DATA</span>
                    </span>
                    <span class="MuiTouchRipple-root"></span>
                  </button>
                </div>
              )}
            </div>
          </div>
          </Paper>
          
          {/* <PageHeader title="Report:"></PageHeader>
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <PowerBIEmbedMine config={this.state.submarketBreakdown1ChartData}></PowerBIEmbedMine>
            </div>
          </div>         */}
          
        </div>



      </div>
    );
    // return (
    //   <ReactDataSheet
    //     data={this.state.grid}
    //     valueRenderer={this.valueRenderer}
    //     onContextMenu={this.onContextMenu}
    //     onCellsChanged={this.onCellsChanged}
    //   />
    // );
  }
}

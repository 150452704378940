import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Box, TextField } from '@material-ui/core';

class NumericRange extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      startValue: this.props.startValue,
      endValue: this.props.endValue,
      dataString: this.props.startValue + ' - ' + this.props.endValue,
      index:this.props.index
    };
    //this.handleEvent = this.handleEvent.bind(this);
  }

  // static getDerivedStateFromProps (newpros, state) {
    
  //   if (
  //     newpros.startValue !== state.startValue ||
  //     newpros.endValue !== state.endValue
  //   ) {
  //     return {
  //       startValue: newpros.startValue,
  //       endValue: newpros.endValue,
  //       dataString: newpros.startValue + ' - ' + newpros.endValue,
  //       index:newpros.index
  //     };
  //   }
  //   return null;
  // }  

  onValueChange (event, picker,bit) {
      let value=event.target.value != '' ? parseInt(event.target.value):0;
    const FormatedstartValue =bit == 0 ? value : this.state.startValue;
    
    const FormatedendValue = bit == 1 ? value : this.state.endValue;
    const StartendValue = FormatedstartValue !=0 && FormatedendValue !=0 ? (
    FormatedstartValue + ' - ' + FormatedendValue) : (FormatedstartValue !=0 ? FormatedstartValue : FormatedendValue);
    if (this.state.dataString !== StartendValue) {
      this.setState({
        startValue: FormatedstartValue,
        endValue: FormatedendValue,
        dataString: StartendValue
      });
      this.props.onValueChange(FormatedstartValue, FormatedendValue);
    }
  }

  render () {
    return (
       
        <div className='row'>
           <div className='col-lg-5 col-md-5'  style={{padding:'0px'}}>
           <Box>
           <label htmlFor="filled-hidden-label-small">Start Value</label>

 <TextField
                 size="small"                 
                  fullWidth
                  key={'numericrange' +this.state.index}
                  id={'numericrange' +this.state.index}
                  //value={this.state.startValue}
                  onChange={(event, value) => {
                    this.onValueChange( event, value,0);
                  }}
                  // style={{ border: '1px solid' }} 
                  hiddenLabel
                  variant="outlined" 
                  margin="normal" />
                  </Box>
                   </div>
                   <div className='col-lg-2 col-md-2' style={{padding:'1px'}}><span style={{padding: '4px 23px',fontWeight: 'bold'}}>-</span></div>
                   <div className='col-lg-5 col-md-5'  style={{padding:'0px'}}>
                       <Box>
                       <label htmlFor="filled-hidden-label-small">End Value</label>
<TextField
                 size="small"
                
        //value={this.state.endValue}
                 
                  fullWidth
                  key={'numericrangeendvalue' +this.state.index}
                  id={'numericrangeendvalue'+this.state.index }
                  onChange={(event, value) => {
                    this.onValueChange( event, value,1);
                  }}
                  // style={{ border: '1px solid' }} 
                  hiddenLabel
                  variant="outlined" 
                  margin="normal" />
                    </Box>
                     </div>
                     </div>
            
          
     
     
       
      
    );
  }
}
NumericRange.propTypes =
{
  startValue: PropTypes.string.isRequired,
  endValue: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  index:PropTypes.number,
};
export default NumericRange;

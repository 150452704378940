import React from "react";
import { connect } from "react-redux";
import TopNav from "../../components/common/TopNav/TopNav";
import SideNav from "../../components/common/SideNav/SideNav";
import Footer from "../../components/common/Footer/Footer";
import "./layout.less";
import FullPageLoader from "../loader/fullPage-loader";
import ToastMessage from "../../components/common/toastMsg";
import { getUserData } from "../../store/user/actions";
import PropTypes from "prop-types";
import { Paper } from "@material-ui/core";
import WorkSpace from "../workspace";
import { routes } from "../../constants/routes";
import ErrorPage from "../ErrorPage";
import DownloadProgress from "../../components/common/download-progress-bar";
import { setDownloaderVisibility } from "../../store/loader/actions";
import IdleSessionTimer from "../../components/common/idleTimer";

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // currentPage: '',
      userData: this.props.userData,
      userConfig: this.props.userConfig,
      globalMarketId: '',
      aI: this.props.accountInfo,
      group: [],
    };
  }

  componentDidMount() {
    if (this.props.userData === null || this.props.userData === undefined) {
      // let group = "";
      // let isChangeGroup = false;
      // if (this.state.aI.grp != accountInfo.grp) {
      //   group = this.state.aI.grp;
      //   this.props.accountInfo.grp = this.state.aI.grp;
      //   isChangeGroup = true;
        window.sessionStorage.setItem("changedGroup", this.state.aI.grp);
      //} else group = accountInfo.grp;
      this.props.getUserData();
      //console.log(this.props);
    }
  }

  static getDerivedStateFromProps(newprops, state) {    
    if (JSON.stringify(newprops.userData) !== JSON.stringify(state.userData)) {
      return {
        userData: newprops.userData,
      };
    }
    return null;
  }
  setIntialMarketId = (e) => {
    if(this.state.globalMarketId === '')
    {
      this.setState({
        globalMarketId: e
      });
      window.sessionStorage.setItem("MarketGlobalId", JSON.stringify(e));
    }
  }
  onMarketChange = (e) => {
    this.setState({
      globalMarketId: e
    });
    window.sessionStorage.setItem("MarketGlobalId", JSON.stringify(e));
    //this.props.setSelectedMarketId(e);
  };
  onGroupChange = (e) => {
    this.setState({
      aI: {
        ...this.state.aI,
        grp: e,
      },
    });
  };
  render() {
    const { accountInfo, layout, requestCount, showDownlodProgress,  setDownloaderVisibility, downloadingFiles } = this.props;
    let group = "";
    let isChangeGroup = false;
    if (this.state.aI.grp != accountInfo.grp) {
      group = this.state.aI.grp;
      this.props.accountInfo.grp = this.state.aI.grp;
      isChangeGroup = true;
      window.sessionStorage.setItem("changedGroup", this.state.aI.grp);
    } else group = accountInfo.grp;
    return (
      <>
        <ToastMessage />
        <IdleSessionTimer></IdleSessionTimer>
        <FullPageLoader requestCount={requestCount}   />
        <DownloadProgress downloadingFiles={downloadingFiles} showDownlodProgress={showDownlodProgress} setDownloaderVisibility={setDownloaderVisibility} />
        <div className="layout-header-container no-print">
          <TopNav onGroupChange={this.onGroupChange} onMarketChange={this.onMarketChange} setInitialValue={this.setIntialMarketId} userConfig={this.state.userConfig} {...this.props} />
        </div>
        <div className="layout-body-wrapper">
          <div className="layout-sidebar no-print">
            <SideNav group={group} {...this.props} />
          </div>
          {/* {layout.isInErrorState == true
            ? (
                  <ErrorPage details={layout.commonLabel} />
              )
            : null}
          {this.props.userData !== null && this.props.userData !== undefined
            ? (<>
          {layout.isInErrorState === true
            ? (
                  <ErrorPage details={layout.commonLabel} />
              )
            : null} */}
          {isChangeGroup ? (
            group == "Office" ? (
              (window.location = "/officeproperties")
            ) : (
              (window.location = "/industrialproperties")
            )
          ) : (
            <div className="layout-selected-route-container">
              <Paper style={{ height: "100%" }}>
                {group == "Office" ? (
                  <WorkSpace defaultPage={routes.dashboard} globalMarketId={this.state.globalMarketId.toString()}></WorkSpace>
                ) : (
                  <WorkSpace defaultPage={routes.inddashboard} globalMarketId={this.state.globalMarketId.toString()}></WorkSpace>
                )}
              </Paper>
            </div>
          )}
          {/* </>)
            : '' } */}
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    user: { isLoading, userData, userConfig },
    loaderConfig: { requestCount,downloadingFiles, showDownlodProgress },
  } = state;
  return {
    isLoading,
    layout: state.layoutConfig,
    requestCount,
    userData,
    userConfig,
    //marketId,
    //downloadingFiles: downloadingFiles.slice(0, 4),
    downloadingFiles: downloadingFiles,
		showDownlodProgress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (param) => dispatch(getUserData(param)),
    setDownloaderVisibility: param => dispatch(setDownloaderVisibility(param))
    //setSelectedMarketId: (param) => dispatch(setSelectedMarketId(param))
  };
};

Layout.propTypes = {
  requestCount: PropTypes.number,
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);

import moment from "moment";
import React from "react";
import {
	standardDateFormat,
	dateMonthFormat,
	DATA_FORMAT
} from ".././constants/constants";

const {
	Text,
	NumberFormat,
	DecimalNumber,
	Header,
	Currency,
	DateFormat,
	Month,
	MonthYear,
	Percent,
	PerDecimal,
	PercentOnly,
	Factor,
	Year,
	TotalCurrency,
	TotalCurrencyDecimal,
	AvgRentCurrencyDecimal,
	DecimalCurrency,
	MultiSelect,
	DispositionMonth,
	CurrencyDecimalPSF,
	BlankRow,
	KWNumber,
	LRate,
	BooleanFormat,
	FormatSF
} = DATA_FORMAT;

const formatter = new Intl.NumberFormat("en-US", {
	style: "currency",
	currency: "USD",
	minimumFractionDigits: 0
});

function formattedCurrency(curVal) {
	return formatter.format(curVal).substring(1);
}

function isNegative(number) {
	return number < 0;
}

function isDecimal(number) {
	return Number(number) % 1 !== 0;
}

function toFixed(number) {
	return isDecimal(number) ? Number(number).toFixed(2) : number;
}

function getFormattedCellData(
	data,
	format,
	isIgnoreZero = false,
	isActualValue = true
) {
	const isNan = isNaN(data);
	format = format ? format.toLowerCase() : Text;
	if (format === Header) return "";
	else if (
		!isIgnoreZero &&
		!isNan &&
		!Number(data) &&
		data !== "" &&
		format !== BlankRow
	)
		return "-";
	else if ((!data && format === Text) || data === undefined) {
		return "N/A";
	} else if (data === "") {
		return "-";
	}

	switch (format) {
		case Header:
			return "";
		case BlankRow:
			return "";
		case Text:
			return data;
		case Year:
			return data;
		case Month:
			return data;
		case Percent:
			return percentRoundOff(data, isActualValue);
		case Factor:
			return formatDecimalNumber(data) + "x";
		case DispositionMonth:
			return `Month ${data}`;
		case PerDecimal:
			return percentDecimal(data, 2, isActualValue);
		case PercentOnly:
			return percentDecimal(data, 4, isActualValue);
		case Currency:
			return formatCurrency(data);
		case DecimalCurrency:
			return formatCurrency(data, false);
		case CurrencyDecimalPSF:
			return formatCurrencyDecimalPSF(data);
		case NumberFormat:
		case TotalCurrency:
			return formatNumber(data);
		case TotalCurrencyDecimal:
		case DecimalNumber:
			return formatDecimalNumber(data);
		case DateFormat:
			return data ? moment(data).format(standardDateFormat) : "";
		case MonthYear:
			return data ? moment.utc(data).format(dateMonthFormat) : "";
		case MultiSelect:
			return formatMultiSelect(data);
		case AvgRentCurrencyDecimal:
			return formatAvgRentDecimal(data);
		case KWNumber:
			return formatKWNumber(data);
		case FormatSF:
			return formatSF(data);
		case BooleanFormat:
			return data ? "Yes" : "No";
		default:
			return data;
	}
}

function convertUsingFormat(data, format) {
	if (data === "") return data;
	format = format.toLowerCase();

	switch (format) {
		case Text:
		case DateFormat:
		case Factor:
			return data.toString();
		case Year:
		case Month:
		case Percent:
		case PerDecimal:
		case PercentOnly:
		case Currency:
		case NumberFormat:
		case MonthYear:
			return Number(data);
		default:
			return data;
	}
}

function percentRoundOff(data, isActualValue = true) {
	let value = Number(data.toString().replace("%", ""));
	if (value > 100 && !isActualValue) value = 100;
	return isNegative(value)
		? `(${Math.round(value * -1)})%`
		: `${Math.round(value)}%`;
}

function percentDecimal(data, toFixedVal = 2, isActualValue = true) {
	const value = Number(data.toString().replace("%", ""));
	return isNegative(value)
		? isActualValue
			? `(${(value * -1).toFixed(toFixedVal)})%`
			: `0.00%`
		: !isActualValue && value > 100
		? `100.00%`
		: `${value.toFixed(toFixedVal)}%`;
}

function formatCurrency(data, isRound = true) {
	const value = formattedString(data, isRound);
	return isNegative(value)
		? `$(${formattedCurrency(value * -1)})`
		: `$${formattedCurrency(value)}`;
}

function formatCurrencyDecimalPSF(data, isRound = true) {
	const formatedValue = (Math.round(data * 100) / 100).toFixed(2);
	const value =
		"$" +
		formatedValue.toLocaleString(navigator.language, {
			maximumFractionDigits: 2
		});
	return `${value}`;
}

function formatNumber(data) {
	const value = formattedString(data);
	// if (!value) return "-";
	return isNegative(value)
		? `(${formattedCurrency(value * -1)})`
		: `${formattedCurrency(value)}`;
}

function formatKWNumber(data) {
	const value = formattedString(data);
	return isNegative(value)
		? `(${formattedCurrency(value * -1)}) kW`
		: `${formattedCurrency(value)} kW`;
}

function formatSF(data) {
	const value = data.toLocaleString();
	return `${value}` + " SF";
}

function formatDecimalNumber(data) {
	const value = Number(formattedString(data, false));
	return isNegative(value)
		? `(${formattedCurrency((value * -1).toFixed(2))})`
		: `${formattedCurrency(value.toFixed(2))}`;
}

function formatAvgRentDecimal(data) {
	return `$${formatDecimalNumber(data)}/SF NNN`;
}

function formattedString(data, isRound = true) {
	const roundedVal =
		data && Math.round(Number(data.toString().replace(/[$,]/g, "")));
	return isRound
		? roundedVal === -0
			? 0
			: roundedVal
		: Number(data.toString().replace(/[$,]/g, "")).toFixed(2);
}

function formatMultiSelect(data) {
	return data;
}

function getTotalOfColumns(rowValues, key) {
	if (!rowValues.data || (rowValues.data && !rowValues.data.length)) return "";
	const total = React.useMemo(
		() => rowValues.data.reduce((sum, row) => row[key] + sum, 0),
		[rowValues.data]
	);
	return total;
}

function getAvgOfColumns(rowValues, key) {
	if (!rowValues.data || (rowValues.data && !rowValues.data.length)) return "";
	const total = React.useMemo(
		() => rowValues.data.reduce((sum, row) => row[key] + sum, 0),
		[rowValues.data]
	);
	return Number(total) / rowValues.data.length;
}

export {
	getFormattedCellData,
	convertUsingFormat,
	isDecimal,
	getTotalOfColumns,
	getAvgOfColumns
};

import React from 'react';
import '../CustomTabs/style.less';

class CustomTabs extends React.PureComponent {
  constructor (props) {
    super(props);
    this.state = {
      selectedTab: 1,
      childComponent: (this.props.tabsList || []).find(x => x.id === this.props.currentSelectedTab).childComponent || <></>,
      currentSelectedTab: this.props.currentSelectedTab
    };
  }

  render () {
    const list = this.props.tabsList;
    //console.log(list);
    return (
      <>
      <div className="col-sm-12 col-lg-12 tabs" >
        {(list || []).map((row, index) => (
          <div className={'tab-capsule ' + (this.state.selectedTab === row.id ? 'selected' : '')} key={index + 'list'} onClick={() =>
            this.setState({ selectedTab: row.id, childComponent: row.childComponent })}>
            {row.name}
          </div>
        ))}
        </div>
        <div className="col-xs-12">
          {this.state.childComponent}
        </div>
        </>
    );
  }
}

export default CustomTabs;

import React, { useEffect, useState, useCallback } from "react";
import { TiPlus, TiMinus } from "react-icons/ti";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import cx from "classnames";
import { useSticky } from "react-table-sticky";
import { sortBy } from "lodash-es/collection";
import {
	useTable,
	useSortBy,
	useResizeColumns,
	useBlockLayout,
	useGroupBy,
	useExpanded,
	usePagination
} from "react-table";
import "./style.less";
import { SECTIONS } from "../../../constants/appConstants";
import { IsSectionVisible } from "../../../utils/helper";
let isMoved = false;
let isDown = false;
const nonJumpedDomElt = ["svg", "path"];

function useControlledState(state, { instance }) {
	return React.useMemo(() => {
		if (!state.groupBy.length) {
			return {
				...state
				// sortBy: [{ id: "location", desc: false }]
				// groupBy: ["productType"],
				// expanded: {
				// 	"productType:Retail": true,
				// 	"productType:Office": true,
				// 	"productType:Mixed Use": true
				// }
			};
		}
		return state;
	}, [state]);
}

function scrollInit() {
	const slider = document.querySelector("table");

	let startX;
	let scrollLeft;
	if (slider) {
		slider.addEventListener("mousedown", e => {
			isDown = true;
			isMoved = false;
			slider.classList.add("active");
			startX = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
			e.preventDefault();
		});
		slider.addEventListener("mouseleave", e => {
			isDown = false;
			isMoved = true;
			slider.classList.remove("active");
			e.preventDefault();
		});
		slider.addEventListener("mouseup", e => {
			isDown = false;
			slider.classList.remove("active");
			e.preventDefault();
		});
		slider.addEventListener("mousemove", e => {
			if (!isDown) return;
			isMoved = true;
			e.preventDefault();
			const x = e.pageX - slider.offsetLeft;
			const walk = (x - startX) * 3; //scroll-fast
			slider.scrollLeft = scrollLeft - walk;
		});
	}
}

function getExpandedByTypes(tableData = [], groupKey = [], isExpand) {
	let grouping = {};
	const productTypeList = [];
	let column2TypedList = [];
	// let rowsExpandGroup = {};
	// Below are sample for expand rows.
	// 11: true
	// parentFundDisplayName:SRP Fund B: true
	// parentFundDisplayName:SRP Fund C>productType:Industrial: true
	tableData.forEach((row, index) => {
		// rowsExpandGroup = { ...rowsExpandGroup, [index]: true };
		// if (row.subRows && row.subRows.length) {
		// 	const level3Group = `${index}.0`;
		// 	rowsExpandGroup = { ...rowsExpandGroup, [level3Group]: true };
		// }
		if (productTypeList.indexOf(row[groupKey[0]]) === -1) {
			column2TypedList = [];
			productTypeList.push(row[groupKey[0]]);
			column2TypedList.push(row[groupKey[1]]);
			const column1GroupType = `${groupKey[0]}:${row[groupKey[0]]}`;
			const column2GroupType =
				groupKey.length > 1 &&
				`${groupKey[0]}:${row[groupKey[0]]}>${groupKey[1]}:${row[groupKey[1]]}`;

			grouping = {
				...grouping,
				[column1GroupType]: isExpand,
				...(column2GroupType && { [column2GroupType]: isExpand })
			};
		} else if (
			groupKey.length > 1 &&
			column2TypedList.indexOf(row[groupKey[1]]) === -1
		) {
			column2TypedList.push(row[groupKey[1]]);
			const column2GroupType =
				groupKey.length > 1 &&
				`${groupKey[0]}:${row[groupKey[0]]}>${groupKey[1]}:${row[groupKey[1]]}`;

			grouping = {
				...grouping,
				...(column2GroupType && { [column2GroupType]: isExpand })
			};
		}
	});

	// return { ...grouping, ...rowsExpandGroup };
	return { ...grouping };
}

function getSubRowCount(row, count = 0) {
	if (row.subRows && row.subRows.length) {
		row.subRows.forEach(item => {
			count = getSubRowCount(item, item.subRows.length ? count : count + 1);
		});
	}
	return count;
}

function Table({
	trClassName,
	columns,
	data,
	onRowClick,
	idKey,
	groupingKey,
	sortedObj,
	showFooter,
	showHeader,
	onCustomSort,
	onSortedChange,
	// onExpandToggle,
	isExpandAll,
	isRowClickable = true,
	showTitle = false,
	tableTitle = "",
	rowEditAction, 
	rowsPerPage = 20
}) {
	const {
		getTableProps,
		getTableBodyProps,
		toggleAllRowsExpanded,
		// getToggleAllRowsExpandedProps,
		headerGroups,
		footerGroups,
		rows,
		setSortBy,
		prepareRow,
		state,
		canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
		state: { groupBy, expanded, sortBy, pageIndex, pageSize }
	} = useTable(
		{
			columns,
			data,
			manualSortBy: groupingKey.length ? true : false,
			initialState: {
				...(sortedObj && { sortBy: [sortedObj] }),
				...(groupingKey.length &&
					data.length && {
						groupBy: groupingKey ? groupingKey : [],
						expanded: getExpandedByTypes(data, groupingKey, isExpandAll)
					}),
				pageIndex: 1
			},
		},
		useBlockLayout,
		useResizeColumns,
		useGroupBy,
		useSortBy,
		useExpanded, // useGroupBy would be pretty useless without useExpanded ;),
		useSticky,
		usePagination
		// hooks => {
		// 	hooks.useControlledState.push(useControlledState);
		// }
	);
	// console.log(state, "state123", expanded);
	const [count, setCount] = useState(0);
	useEffect(() => {
		onCustomSort && onCustomSort(sortBy);
		sortBy.length && onSortedChange && onSortedChange(sortBy);
	}, [onCustomSort, sortBy]);
	useEffect(() => {
		sortedObj && setSortBy([sortedObj]);
	}, [sortedObj && sortedObj.id, sortedObj && sortedObj.desc]);
	// useEffect(() => {
	// 	groupingKey.length && toggleAllRowsExpanded(isExpandAll);
	// }, [isExpandAll]);
	useEffect(() => {
		scrollInit();
	}, [count]);

	//const calculateRange = (data, rowsPerPage) => {
	//	const range = [];
	//	const num = Math.ceil(data.length / rowsPerPage);
	//	let i = 1;
	//	for (let i = 1; i <= num; i++) {
	//	  range.push(i);
	//	}
	//	return range;
	//  };
	  
	//  const sliceData = (data, page, rowsPerPage) => {
	//	return data.slice((page - 1) * rowsPerPage, page * rowsPerPage);
	//  };  
	
	//  const useTable = (data, page, rowsPerPage) => {
	//	const [tableRange, setTableRange] = useState([]);
	//	const [slice, setSlice] = useState([]);
	  
	//	useEffect(() => {
	//	  const range = calculateRange(data, rowsPerPage);
	//	  setTableRange([...range]);
	  
	//	  const slice = sliceData(data, page, rowsPerPage);
	//	  setSlice([...slice]);
	//	}, [data, setTableRange, page, setSlice]);
	  
	//	return { slice, range: tableRange };
	//  };
	
	//  const TableFooter = ({ range, setPage, page, slice }) => {
	//	useEffect(() => {
	//	  if (slice.length < 1 && page !== 1) {
	//		setPage(page - 1);
	//	  }
	//	}, [slice, page, setPage]);
	//	return (
	//	  <div className="tableFooter">
	//		{range.map((el, index) => (
	//		  <button
	//			key={index}
	//			className={`"button" ${
	//			  page === el ? "activeButton" : "inactiveButton"
	//			}`}
	//			onClick={() => setPage(el)}
	//		  >
	//			{el}
	//		  </button>
	//		))}
	//	  </div>
	//	);
	//  };

	//const [page, setPage] = useState(1);
  	////const { slice, range } = useTable(data, page, rowsPerPage);
	//const [range] = React.useState([5, 10, 20,35, 50]);
	////let slice = [];
	////for(let i=0; i<data.length;i++){
	////	slice.push(data.length / rowsPerPage);
	////}
	//const [slice] = React.useState([1, 2, 3, 4, 5]);

	return (
		<>
			<table {...getTableProps()} className="sticky">
				{showTitle && <caption>{tableTitle}</caption>}
				{showHeader && (
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									// Add the sorting props to control sorting. For this example
									// we can add them into the header props
									return (
										<th
											{...(!column.avoidSorting
												? column.getHeaderProps(column.getSortByToggleProps())
												: column.getHeaderProps())}
											className={column.headerClassName || ""}
											title=""
										>
											{column.render("Header")}
											<span>
												{column.isSorted
													? column.isSortedDesc
														? " ⬇"
														: " ⬆"
													: ""}
											</span>
											<span
												{...column.getResizerProps()}
												className={`resizer ${
													column.isResizing ? "isResizing" : ""
												}`}
												onClick={event => event.stopPropagation()}
											/>
										</th>
									);
								})}
							</tr>
						))}
					</thead>
				)}

				<tbody {...getTableBodyProps()}>
					{rows.length ? (
						rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									onClick={e =>
										nonJumpedDomElt.indexOf(e.target && e.target.tagName) ===
											-1 &&
										row.original &&
										!isMoved &&
										onRowClick
											? onRowClick(row.original[idKey])
											: {}
									}
									className={cx(
										row.isGrouped && "exapandableRow",
										row.original &&
											row.original.parentPropertyKey &&
											"expandedSubRowsLevel1",
										row.original &&
											row.original.parentPropertyKey &&
											(row.id.match(/\./g) || []).length > 1 &&
											"expandedSubRowsLevel2",
										!isRowClickable && "non-clickable",
										trClassName
									)}
								>
									{row.cells.map(cell => {
										return (
											<td
												// For educational purposes, let's color the
												// cell depending on what type it is given
												// from the useGroupBy hook
												{...cell.getCellProps()}
												className={cx(
													cell.column.className || "",
													cell.column.highLight && "columnHighlighter"
												)}
											>
												{cell.column.id !== "actionBlock" ? (
													cell.isGrouped ? (
														// If it's a grouped cell, add an expander and row count
														<span className="expandGroupTd">
															<span
																{...row.getToggleRowExpandedProps()}
																className="expandedIcon"
															>
																{row.isExpanded ? <TiMinus /> : <TiPlus />}
															</span>{" "}
															{cell.render("Cell")} ({getSubRowCount(row)})
														</span>
													) : cell.isAggregated ? (
														// If the cell is aggregated, use the Aggregated
														// renderer for cell
														cell.render("Aggregated")
													) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
														// Otherwise, just render the regular cell
														cell.render("Cell")
													)
												) : !cell.isAggregated && IsSectionVisible(
													SECTIONS.Spec_Suite,
													SECTIONS.Spec_Suite_Add_Edit,
													SECTIONS.ADD_EDIT
												) ? (
													<span>
														<span className="editIcon">
															<FaEdit
															style={{color:'#00b0b9'}}
																size="1.3rem"
																onClick={() =>
																	rowEditAction("edit", row.original)
																}
															/>
														</span>
														<span>
															<MdDelete
															style={{color:'#00b0b9'}}
																size="1.3rem"
																onClick={() =>
																	rowEditAction("delete", row.original)
																}
															/>
														</span>
														{cell.render("Cell")}
													</span>
												) : (
													""
												)}
											</td>
										);
									})}
								</tr>
							);
						})
					) : (
						<tr role="row" className="no-data no-tr-hover">
							<td>No Data Available.</td>
						</tr>
					)}
				</tbody>
				{showFooter && (
					<tfoot>
						{footerGroups.map(group => (
							<tr {...group.getHeaderGroupProps()}>
								{group.headers.map(column => (
									<td
										className={column.headerClassName || ""}
										{...column.getHeaderProps()}
									>
										{column.render("Footer")}
									</td>
								))}
								<td {...group.headers[0].getHeaderProps()} />
							</tr>
						))}
					</tfoot>
				)}
			</table>
			{/*<TableFooter range={range} slice={slice} setPage={setPage} page={page}/>*/}
			{/*<div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex} of {pageOptions.length}
          </strong>{' '}
        </span>
        {/*<span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}*/}
        {/*<select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>*/}
			<br />
		</>
	);
}

function ReactFundTable({
	trClassName = "",
	columnList = [],
	tableData = [],
	onRowClick,
	idKey = "propertyKey",
	groupingKey = [],
	sortedObj,
	showFooter = true,
	showHeader = true,
	onSortedChange,
	onExpandToggle,
	isExpandAll,
	isRowClickable,
	showTitle = false,
	tableTitle = "",
	rowEditAction
}) {
	const [data, setData] = useState(tableData);
	useEffect(() => {
		setData(tableData);
	}, [tableData.length]);

	const handleSort = useCallback(sortByObj => {
		setTimeout(() => {
			// Doing multisort
			const sorted = tableData.slice();
			tableSort(sorted, sortByObj);
			setData(sorted);
		}, 200);
	}, []);

	function tableSort(tableData, sortByObj) {
		tableData.sort((a, b) => {
			for (let i = 0; i < sortByObj.length; ++i) {
				if (a.subRows && a.subRows.length) {
					tableSort(a.subRows, sortByObj);
				}
				if (b.subRows && b.subRows.length) {
					tableSort(b.subRows, sortByObj);
				}

				if (a[sortByObj[i].id] > b[sortByObj[i].id])
					return sortByObj[i].desc ? -1 : 1;
				if (a[sortByObj[i].id] < b[sortByObj[i].id])
					return sortByObj[i].desc ? 1 : -1;
			}
			return 0;
		});
	}
	return (
		<div className="react-table-landing">
			{data.length ? (
				<Table
					trClassName={trClassName}
					columns={columnList}
					data={groupingKey.length ? sortBy(data, [groupingKey[0]]) : data}
					onRowClick={onRowClick}
					idKey={idKey}
					groupingKey={groupingKey}
					sortedObj={sortedObj}
					showFooter={showFooter}
					showHeader={showHeader}
					onSortedChange={onSortedChange}
					onCustomSort={groupingKey.length && handleSort}
					isExpandAll={isExpandAll}
					onExpandToggle={onExpandToggle}
					isRowClickable={isRowClickable}
					showTitle={showTitle}
					tableTitle={tableTitle}
					rowEditAction={rowEditAction}
				/>
			) : (
				<div className="no-data-found-block">No data available.</div>
			)}
		</div>
	);
}

export default ReactFundTable;

import React from "react";
import "./style.less";
import cx from "classnames";
import { getDate } from "../../../utils/dateForSlider";
import CustomSlider from "./slider";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";



export default class MonthSlider extends React.PureComponent {
	constructor(props) {
		super(props);
		const selectedAsOfDateYear = props.selectedDate ? new Date(props.selectedDate).getFullYear() : new Date().getFullYear();
		this.state = {
			monthArrowShiftVa: 3,
			selectedDate: props.selectedDate,
			currentYear: new Date().getFullYear(),
			selectedYear: selectedAsOfDateYear,
			minSliderVal: getDate(`1/1/${selectedAsOfDateYear}`),
      		maxSliderVal: getDate(`12/10/${selectedAsOfDateYear}`)
		};
	}

	setPrevYear = () => {
		const { sliderView } = this.props;
		const { selectedYear, minSliderVal, maxSliderVal, monthArrowShiftVa } = this.state;

		const minVal = new Date(minSliderVal.setDate(10));
		const maxVal = new Date(maxSliderVal.setDate(10));

		if(sliderView === "mtdRadio") {
			this.setState({ minSliderVal: this.getPrevNextDate(minVal, monthArrowShiftVa * -1), maxSliderVal: this.getPrevNextDate(maxVal, monthArrowShiftVa * -1) });
		} else {
			this.setState({ selectedYear: selectedYear - 1 });
		}
	}

	setNextYear = () => {
		const { sliderView } = this.props;
		const { selectedYear, minSliderVal, maxSliderVal, monthArrowShiftVa } = this.state;
		const minVal = new Date(minSliderVal.setDate(10));
		const maxVal = new Date(maxSliderVal.setDate(10));

		if(sliderView === "mtdRadio") {
			this.setState({ minSliderVal: this.getPrevNextDate(minVal, monthArrowShiftVa), maxSliderVal: this.getPrevNextDate(maxVal, monthArrowShiftVa) });
		} else {
			this.setState({ selectedYear: selectedYear + 1 });
		}
	}

	getPrevNextDate = (date, gapVal) => {
		date.setMonth(date.getMonth() + gapVal);
		return getDate(date.toLocaleDateString());
	}

	getArrowDisable = (sliderView) => {
		const { selectedYear, currentYear } = this.state;
		switch(sliderView) {
			case "year":
				return { leftArrowDisable: true, rightArrowDisable: true };
			case "mtdRadio":
				return { leftArrowDisable: false, rightArrowDisable: false };
			case "ytdRadio":
			case "ttmRadio":
				const yearGap = currentYear - selectedYear;
				return { leftArrowDisable: yearGap === 1, rightArrowDisable: yearGap === 0 };
			default:
				return { leftArrowDisable: true, rightArrowDisable: true };
		}
	}
	onChangeMonthRange1 = (startMonth, endMonth) => {
		this.props.onChangeMonthRange(startMonth, endMonth)
		
	  }
	  


	render() {
		const { selectedYear, selectedDate, minSliderVal, maxSliderVal } = this.state;
		const { onChangeMonthRange, sliderView, singleTickSelection } = this.props;
		const arrow = this.getArrowDisable(sliderView);
		return (
			<div className="row slider-container">
				<div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 yearChanger no-padding">
					<IoIosArrowBack size="2rem" onClick={this.setPrevYear} className={cx(arrow.leftArrowDisable && "disabled")} />
				</div>
				<div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 sliderBox">
					<CustomSlider selectedYear={selectedYear} onChangeMonthRange={this.onChangeMonthRange1} selectedDate={selectedDate} sliderView={sliderView} minSliderVal={minSliderVal} maxSliderVal={maxSliderVal} singleTickSelection={singleTickSelection} />
				</div>
				<div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 yearChanger no-padding">
					<IoIosArrowForward size="2rem" onClick={this.setNextYear} className={cx(arrow.rightArrowDisable && "disabled")} />
				</div>
			</div>
		);
	}
}

import React from 'react';
import { getTodaysDate } from '../../utils/date';
import { listLandingView } from '../../constants/constants';
import LandingFilter from '../common/landing-filter';
import { scrollToTop } from '../../utils/generic';
import LandingCardView from '../common/landing-card-view';

class PropertyLandingScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedPropertyId: '',
			selectedFundId: '',
			selectedProperty: {},
			selectedDate: props.asOfDate || getTodaysDate(),
			sortByKey: "leasedPercentage",
			sortAsc: false,
			searchText: "",
			isSearching: false,
			selectedLoanKey: 1,
			isExpandAll: true,
			cardView: localStorage.getItem('landingView') || listLandingView
		}
		this.iconColor = "#FFFFFF";
		this.iconSize = "1.3em";
	}

	componentWillReceiveProps(nextProps) {
		if ((nextProps.fundKey !== this.props.fundKey) || (nextProps.selectedDate !== this.props.selectedDate)) {
		  this.setState({ searchText: "" });
		}

		if(nextProps.asOfDate !== this.props.asOfDate)
		{
			this.setState({ selectedDate: nextProps.asOfDate });
		}
	  }

	getFilterData = (data, searchText = "") => {
		let searchKey = searchText.toLowerCase();
		if (data === undefined) return;
		return data.filter(function (item, i) {
			return (searchKey === ''
				|| item["property"].toLowerCase().includes(searchKey));
		});
	}

	onSortChange = (sortBy) => {
		this.setState({
			sortByKey: sortBy,
		});
	}

	dynamicSort = (property) => {
		let sortOrder = 1;
		if (this.state.sortAsc === false) {
			sortOrder = -1;
		}
		return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
			let properties = property.split(".");
			let obj1 = a;
			let obj2 = b;

			properties.forEach((prop) => {
				if (obj1 != undefined) {
					obj1 = obj1[prop];
				}
				if (obj1 == undefined) {
					return;
				}
			});
			properties.forEach((prop) => {
				if (obj2 != undefined) {
					obj2 = obj2[prop];
				}
				if (obj2 == undefined) {
					return;
				}
			});
			let value1 = obj1 === undefined ? 0 : obj1;
			let value2 = obj2 === undefined ? 0 : obj2;
			const result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
			return result * sortOrder;
		}
	}

	componentDidMount() {
		scrollToTop();
	}

	mergeArrays = (...arrays) => {
		let jointArray = []

		arrays.forEach(array => {
			jointArray = [...jointArray, ...array]
		});
		return Array.from(new Set([...jointArray]))
	}

	setPropertyCardSort = (flag) => {
		this.setState({ sortAsc: flag });
	}

	searchCardByInput = (searchText) => {
		this.setState({ searchText, isSearching: true }, () => {
			this.setState({ isSearching: false });
		});
	}

	changeCardView = (view) => {
		localStorage.setItem("landingView", view);
		this.setState({ cardView: view });
	}

	onSortedChange = (newSorted) => {
		this.setState({ sortAsc: !newSorted[0].desc, sortByKey: newSorted[0].id });
	}

	onExpandToggle = (isExpand = !this.state.isExpandAll) => {
		this.setState({ isExpandAll: isExpand });
	}

	render() {
		const { searchText, cardView, sortByKey, sortAsc, isExpandAll, isSearching } = this.state;
		const { rowEditAction, showPropertyFilter = true, onOpenPopup, tableData, columnList, getCardData, fundKey, downloadExcel, showAddRecord, propertyInfo, showAddProperty = false, showAddMarket = false,showAddIndustry=false, showAddSubMarket = false, noDataMsg = "", groupingKey = [] } = this.props;
		let sortedPropertyData = tableData ? (tableData.length ? tableData.sort(this.dynamicSort(sortByKey)) : []) : null;
		const filteredData = sortedPropertyData ? (sortedPropertyData.length ? this.getFilterData(sortedPropertyData, searchText) : []) : null;
		const isListView = cardView === listLandingView;

		return (
			<>
				{
					<>
						{
							showPropertyFilter &&
								<LandingFilter
									searchCardByInput={this.searchCardByInput}
									searchText={searchText}
									onSortChange={this.onSortChange}
									isListView={isListView}
									onChangeRadio={this.setPropertyCardSort}
									downloadReport={downloadExcel}
									isExpandAll={isExpandAll}
									showDownLoadBtn={downloadExcel}
									downloadPdf={this.downloadPdf}
									onAddLoan={onOpenPopup}
									showAddProperty={showAddProperty}
									showAddLoan={showAddRecord}
									propertyInfo={propertyInfo}
									showAddMarket={showAddMarket}
									showAddIndustry={showAddIndustry}
									showAddSubMarket={showAddSubMarket}
								/>
						}
						{
							!isSearching && <LandingCardView filteredData={filteredData} columnList={columnList} cardView={cardView} createCardObj={getCardData} idKey="propertyKey" sortByKey={sortByKey} sortAsc={sortAsc} onSortedChange={this.onSortedChange} searchText={searchText} groupingKey={groupingKey} isExpandAll={isExpandAll} onExpandToggle={this.onExpandToggle} rowEditAction={rowEditAction} showFooter={false} noDataMsg={noDataMsg} />
						}
					</>
				}
			</>
		);
	}
}

export default PropertyLandingScreen;

import React from 'react';
import './style.less';
import { FaBuilding } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { authProvider } from '../../authProvider';

export default class ErrorPage extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      errorCode: props.details.code,
      heading: props.details.heading,
      message: props.details.message,
      goToHomeEnabled: props.details.goToHomeEnabled
    };
  }

  render () {
    const { errorCode, heading, message, goToHomeEnabled } = this.state;
    return (
      <div id="notfound" key={errorCode + 'errorPageContainer'}>
        <div className="notfound">
          <div className="notfound-404">
            <FaBuilding size="140px" width="5em" />
          </div>
          <h1>{errorCode}</h1>
          <h2>{heading}</h2>
          <p>{message}</p>
          {goToHomeEnabled && errorCode === 401
            ? (
            <NavLink to={'/officeproperties'} onClick={() => {
              window.location = '/officeproperties';
            }}>
                Sign In
            </NavLink>
              )
            : (
              <NavLink key={errorCode + 'errorPageHome'} to={'/officeproperties'} onClick={() => {
                window.location = '/officeproperties';
              }}>
               Back to homepage
              </NavLink>
              )}
        </div>
      </div>
    );
  }
}

import { DOWNLOAD_STATUS } from '../../constants/constants';
import { visibilityDownloaderProgress } from '../../utils/generic';
import { getArrayIndex } from '../../utils/lodash';
import * as actionType from './actionTypes';

const initialState = {
	requestCount: 0,
	showDownlodProgress: false,
	downloadingFiles: []
};

let dTimeout = null;

function removeCompltedDownload(fileLlist = []) {
	if (!fileLlist.length) return [];
	return fileLlist.filter(x => x.status !== DOWNLOAD_STATUS.completed);
}

const reducer = (state = initialState, action) => {

	let count = state.requestCount;
	switch (action.type) {
		case actionType.SET_VISIBILITY:
			// if (action.payload == null) {				
			// 	count = 0;
			// }
			// else 
			if (action.payload === true) {				
				count++;
			} else {
				count--;
			}
			if (count < 0) {
				count = 0;
			}
			//console.log(count);
			return {
				...state,
				requestCount: count
			};
		case actionType.SET_FILEDOWNLOADING:
			// const dFileList = removeCompltedDownload(state.downloadingFiles);
			const dFileList = state.downloadingFiles;

			if (
				action.payload.details.status === DOWNLOAD_STATUS.completed ||
				action.payload.details.status === DOWNLOAD_STATUS.failed
			) {
				const index = getArrayIndex(dFileList, {
					dKey: action.payload.details.dKey
				});
				const inProgressList = dFileList.filter(
					x => x.status === DOWNLOAD_STATUS.downloading
				);

				if (inProgressList.length === 1) {
					dTimeout = setTimeout(() => visibilityDownloaderProgress(), 10000000);
				}

				dFileList[index].status = action.payload.details.status;
				dFileList[index].errorMessage = action.payload.details.errorMessage;
				dFileList[index].statusMessage= action.payload.details.statusMessage;
				return {
					...state,
					downloadingFiles: [...dFileList]
				};
			}
			clearTimeout(dTimeout);
			return {
				...state,
				showDownlodProgress: true,
				downloadingFiles: [action.payload.details, ...dFileList]
			};
		case actionType.SET_DOWNLOADING_VISIBILITY:
			return {
				...state,
				showDownlodProgress: action.payload
			};
		default:
			return state;
	}
};

export default reducer;

import React from "react";
import "./style.less";
import { IoMdClose } from "react-icons/io";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import DialogContentText from "@material-ui/core/DialogContentText";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	}
});

const DialogTitle = withStyles(styles)(props => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
				>
					<IoMdClose />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles(theme => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

export default function ConfirmPopup({
	openModel = false,
	header = "",
	confirmMsg = "",
	closeModal,
	onModelSubmit
}) {
	return (
		<div>
			<Dialog
				onClose={closeModal}
				aria-labelledby="customized-dialog-title"
				open={openModel}
				transitionDuration={0}
				className="confirmPopup"
			>
				<DialogTitle id="customized-dialog-title" onClose={closeModal}>
					{header}
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>{confirmMsg}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button  autoFocus onClick={onModelSubmit} color="primary">
						<span style={{color:'white'}}>Ok</span>
					</Button>
					<Button  autoFocus onClick={closeModal} color="primary">
					<span style={{color:'white'}}>Cancel</span>
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

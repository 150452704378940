import React from "react";
import "./addSpec.less";
import { API_URL } from "../../constants/api";
import { apiPost, apiPut } from "../../utils/api_service";
import InputField from "../common/input-field";
import InputDatePicker from "../common/input-date-picker";
import Select from "../common/select";
import Button from "../common/button";
import RadioGroup from "../common/radio";
import "../common/PageHeader/style.less";
import { ShowToast, toastType } from "../../utils/toast-service";
import { convertIntoFloat, convertIntoInt } from "../../utils/generic";
import { DateFormat } from "../../constants/appConstants";
import moment from "moment";
import PageHeader from "../common/PageHeader";

const booleanDDOptions = [
  { id: "true", value: "Yes" },
  { id: "false", value: "No" },
];

const validateFieldData = [
  { key: "led", isRequired: false },
  { key: "constructionType", isRequired: false },
  { key: "priorPremisesCondition", isRequired: false },
  { key: "constructionMonths", isRequired: false },
  { key: "downtimeValue", isRequired: false },
];

class AddSpecSuite extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      propertyId: props.editedRowData.propertyId || props.propertyId,
      leaseSpecId: props.editedRowData.leaseSpecId,

      compTypeIdValue: props.editedRowData.compTypeIdValue
        ? props.editedRowData.compTypeIdValue
        : "",

      // Spec suite State
      costPsf: props.editedRowData.costPsf,
      constructionMonths: props.editedRowData.constructionMonths,
      timeFromCompletionToLCD: props.editedRowData.timeFromCompletionToLCD
        ? props.editedRowData.timeFromCompletionToLCD
        : "",
      monthsVacantPrior: props.editedRowData.monthsVacantPrior,
      downtimeValue: props.editedRowData.downtimeValue
        ? props.editedRowData.downtimeValue
        : "",
      elevatorLobbyExposurer: props.editedRowData.elevatorLobbyExposurer
        ? props.editedRowData.elevatorLobbyExposurer + ""
        : "true",
      constructionType: props.editedRowData.constructionType
        ? props.editedRowData.constructionType
        : "",
      priorPremisesCondition: props.editedRowData.priorPremisesCondition
        ? props.editedRowData.priorPremisesCondition
        : "",
      relocationReason: props.editedRowData.relocationReason
        ? props.editedRowData.relocationReason
        : "",
      completionToLCD: props.editedRowData.completionToLCD,
      priorPermission: props.editedRowData.priorPermission,
      msa: props.editedRowData.msa,
      // marketId: props.editedRowData.marketId,
      // subMarketId: props.editedRowData.subMarketId,
      preWired: props.editedRowData.preWired
        ? props.editedRowData.preWired + ""
        : "true",
      furnished: props.editedRowData.furnished
        ? props.editedRowData.furnished + ""
        : "true",
      percentageOfficevsOpen: props.editedRowData.percentageOfficevsOpen
        ? props.editedRowData.percentageOfficevsOpen
        : "",
      rapidUtilized: props.editedRowData.rapidUtilized
        ? props.editedRowData.rapidUtilized + ""
        : "true",
      propertyName:
        props.editedRowData.propertyName || props.editedRowData.buildingName,

      // Lease State
      lcd: props.editedRowData.lcd,
      led: props.editedRowData.led,
      tenantRSF: props.editedRowData.tenantRSF,
      termsInMonth: props.editedRowData.termsInMonth,
      startingRate: props.editedRowData.startingRate,
      quotedRate: props.editedRowData.quotedRate,
      nnnPerFS: isNaN(props.editedRowData.nnnPerFS)
        ? ""
        : props.editedRowData.nnnPerFS,
      freeRentInMonths: props.editedRowData.freeRentInMonths,
      additionalTI: props.editedRowData.additionalTI,
      industryTypeId: props.editedRowData.industryTypeId,
      annualEscalation: props.editedRowData.annualEscalation,
      annualEscalationType: props.editedRowData.annualEscalationType
        ? props.editedRowData.annualEscalationType
        : "Percentage",

      // Property Info
      propertyName:
        props.editedRowData.propertyName || props.editedRowData.buildingName,
      cityId: props.editedRowData.cityId,
      stateId: props.editedRowData.stateId,
      address: props.editedRowData.address,
      marketId: props.editedRowData.marketId
        ? props.editedRowData.marketId
        : props.propertyInfo.marketId
        ? props.propertyInfo.marketId
        : "",
      subMarketId: props.editedRowData.subMarketId
        ? props.editedRowData.subMarketId
        : props.propertyInfo.subMarketId
        ? props.propertyInfo.subMarketId
        : "",
      buildingRSF: props.editedRowData.buildingRSF,
      class: props.editedRowData.class,

      inProgress: false,
    };
  }

  onChangeField = (value, valueKey) => {
    this.setState({
      [valueKey]: value,
    });
  };

  onChangeDate = (value, valueKey) => {
    this.setState({
      [valueKey]: value,
    });
  };

  onChangeSelect = (value, valueKey) => {
    let propertyName = undefined;
    let propertyData = this.props.propertyMasterData;
    if (valueKey == "propertyId") {
      if (propertyData.length > 0) {
        propertyName = propertyData.find((x) => x.id == value).value;
      }
      if (propertyName != undefined)
        this.setState({ propertyName: propertyName });
    }
    this.setState({
      [valueKey]: value,
      ...(valueKey === "stateId" && { cityId: "" }),
      ...(valueKey === "marketId" && { subMarketId: "" }),
    });
  };

  onChangeRadio = (value, valueKey) => {
    this.setState(
      {
        [valueKey]: value,
        annualEscalationType: value,
      },
      () => {
        this.forceUpdate();
      }
    );
  };

  onSaveSpecSuite = () => {
    if (this.state.inProgress) return;

    const redFields = document.getElementsByClassName("outlineAnim");
    for (let i = redFields.length - 1; i >= 0; i--) {
      redFields[i].classList.remove("outlineAnim");
    }

    const { isShowAddProeprty } = this.props;

    let propId = undefined;
    let propertyData = this.props.propertyMasterData;
    if (this.state.propertyName == undefined || this.state.propertyName == '---') {	}
	else{
      if (propertyData.length > 0) {
        propId = propertyData.find(
          (x) => x.value == this.state.propertyName
        ).id;
      }
    }

	let propertyName = undefined;
  if (this.props.propertyId == undefined || this.props.propertyId == "") { }
  else{
      if (propertyData.length > 0) {
        propertyName = propertyData.find((x) => x.id == this.props.propertyId).value;
      }
      if (propertyName != undefined)
        this.setState({ propertyName: propertyName });
    }

    const specBody = {
      propertyId:
        this.state.propertyId == undefined
          ? propId == undefined
            ? undefined
            : convertIntoInt(propId)
          : convertIntoInt(this.state.propertyId),
      leaseSpecId: this.state.leaseSpecId
        ? parseInt(this.state.leaseSpecId)
        : 0,
      lcd: (this.state.lcd == "" || this.state.lcd == undefined) ? null : moment(this.state.lcd).format(DateFormat),
      led: (this.state.led == "" || this.state.led == undefined) ? null : moment(this.state.led).format(DateFormat),
      tenantRSF: convertIntoInt(this.state.tenantRSF),
      termsInMonth: convertIntoInt(this.state.termsInMonth),
      startingRate: convertIntoFloat(this.state.startingRate),
      quotedRate: convertIntoFloat(this.state.quotedRate),
      nnnPerFS: this.state.nnnPerFS,
      compTypeIdValue: convertIntoInt(this.state.compTypeIdValue),
      freeRentInMonths: convertIntoInt(this.state.freeRentInMonths),
      additionalTI: convertIntoFloat(this.state.additionalTI),
      industryTypeId: convertIntoInt(this.state.industryTypeId),
      annualEscalation: convertIntoInt(this.state.annualEscalation),
      annualEscalationType: this.state.annualEscalationType,
      costPsf: convertIntoFloat(this.state.costPsf),
      constructionMonths: convertIntoInt(this.state.constructionMonths),
      timeFromCompletionToLCD: convertIntoInt(
        this.state.timeFromCompletionToLCD
      ),
      monthsVacantPrior: convertIntoInt(this.state.monthsVacantPrior),
      elevatorLobbyExposurer:
        this.state.elevatorLobbyExposurer == "true" ? true : false,
      downtimeValue: this.state.downtimeValue + "",
      constructionType: this.state.constructionType,
      priorPremisesCondition: this.state.priorPremisesCondition,
      relocationReason: this.state.relocationReason,

      msa: "0",
      subMarketId: Number(this.state.subMarketId),
      marketId: Number(this.state.marketId),
      preWired: this.state.preWired == "true" ? true : false,
      furnished: this.state.furnished == "true" ? true : false,
      percentageOfficevsOpen: convertIntoFloat(
        this.state.percentageOfficevsOpen
      ),
      rapidUtilized: this.state.rapidUtilized == "true" ? true : false,
      propertyName:
        this.state.propertyName == undefined
          ? "New Property"
          : this.state.propertyName,
    };

    const propertyBody = {
      propertyId: this.state.propertyId ? parseInt(this.state.propertyId) : undefined,
      propertyName: this.state.propertyName,
      //  cityId: parseInt(this.state.cityId) ? parseInt(this.state.cityId) : "",
      //  stateId: parseInt(this.state.stateId),
      //  address: this.state.address,
      subMarketId: convertIntoInt(this.state.subMarketId),
      marketId: convertIntoInt(this.state.marketId),
      //  buildingRSF: parseInt(this.state.buildingRSF),
      //  class: this.state.class,
    };

    if (
      isShowAddProeprty
        ? this.checkIsEmptyData({ ...specBody, ...propertyBody })
        : this.checkIsEmptyData({ ...specBody })
    ) {
      return;
    }

    // if (!this.checkIsEmptyData(specBody) && (isShowAddProeprty ? !this.checkIsEmptyData(propertyBody) : true)) {
    if (true) {
      this.setState({ inProgress: true });

      if (specBody.leaseSpecId) {
        //   Update Records code here-----
        apiPost({
          url: API_URL.SpecSuites.updateSpecLease,
          postBody: specBody,
        }).then((res) => {
          if (res.data.success) {
            ShowToast("Record Updated", toastType.SUCCESS);
            this.props.getSpesuiteTableData();
          } else {
            ShowToast("Something Went Wrong", toastType.ERROR);
          }
          this.setState({ inProgress: false });
        });
      } else {
        // -----  Insert code here-----

        if (isShowAddProeprty) {
          apiPost({
            url: API_URL.SpecSuites.insertPropertySpecLease,
            postBody: {
              propertyModel: propertyBody,
              speacLeaseModel: specBody,
            },
          }).then((res) => {
            if (res.data.success) {
				if(res.data.message != null && res.data.message.includes('Duplicate Record')){
					ShowToast("Duplicate Record", toastType.ERROR);
				}
				else{
					ShowToast(
						"Spec Suite Record Successfully Added.",
						toastType.SUCCESS
					  );
					  this.props.getAllPropertyList();
					  this.props.getSpesuiteTableData();
				}
            } else {
              ShowToast("Something Went Wrong", toastType.ERROR);
            }
            this.setState({ inProgress: false });
          });
        } else {
          apiPut({
            url: API_URL.SpecSuites.insertSpecLease,
            postBody: specBody,
          }).then((res) => {
            if (res.data.success) {
              ShowToast(
                "Spec Suite Record Successfully Added.",
                toastType.SUCCESS
              );
              this.props.getSpesuiteTableData();
            } else {
              ShowToast("Something Went Wrong", toastType.ERROR);
            }
            this.setState({ inProgress: false });
          });
        }
      }
    } else {
      // ShowToast("All fields are mandatory.", toastType.WARNING);
    }
  };

  checkIsEmptyData = (passedObj) => {
    let isEmpty = false;
    for (let key in passedObj) {
      let isRequired =
        validateFieldData.filter((x) => x.key == key).length > 0
          ? validateFieldData.filter((x) => x.key == key).find((x) => x.key)
              .isRequired
          : true;
      if (
        isRequired &&
        // passedObj[key] === 0 ||
        // passedObj[key] ==='NaN' ||
        // passedObj[key] === "0" ||
        (passedObj[key] === "" ||
          passedObj[key] === null ||
          passedObj[key] === undefined) //||
          //passedObj[key] === 0)
      ) {
        const parent = document.getElementById(key);
        if (parent) {
          const labelCell = parent.getElementsByClassName("labelCell");
          const valueInput = parent.getElementsByClassName("addSpecEdit");
          const valueSelect = parent.getElementsByTagName("select");
          const innerText = labelCell[0].innerText;
          const fType = valueInput.length
            ? valueInput[0]
            : valueSelect.length
            ? valueSelect[0]
            : "";

          if (fType != undefined) {
            fType.classList.add("outlineAnim");
            // setTimeout(() => fType.classList.remove("outlineAnim"), 2500);
          }
          ShowToast(`${innerText} is mandatory fields.`, toastType.ERROR);
        }
        isEmpty = true;
      }
    }
    if (isEmpty) {
      return isEmpty;
    }
    return false;
  };

  render() {
    const {
      jumpToPage,
      ddMasterData,
      propertyMasterData,
      marketMasterData,
      subMarketMasterData,
      propertyInfo,
      editedRowData,
      popupTitle,
      onOpenPopup,
      isShowAddProeprty = false,
      industryMasterData,
      percentageOfficevsMaster,
      priorPremisesMaster,
      constructionTypeMaster,
      nnnPerFSMaster,
      isMarketEditable,
      relocationReasonMaster,
      downtimeMaster,
      compTypeMaster,
    } = this.props;
    const { inProgress } = this.state;

    return (
      <>
        <div className="spec-suite-info">
          <div className="row">
            <div className="col-md-8 col-lg-8">
            <PageHeader									
									title={
										editedRowData && editedRowData.propertyName
											? editedRowData.propertyName
											: ""
									}
									style={{ color: "#00B0B9" }}
								></PageHeader>              			  
            </div>
            <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2 end-xs end-sm end-md end-lg">
              {" "}
              <Button
                styleClass="simpleButton auto"
                label="Cancel"
                onClick={() => onOpenPopup("", false)}
              />
            </div>
            <div className="col-xs-6 col-sm-3 col-md-2 col-lg-2 end-sm end-md end-lg">
              <Button
                styleClass="simpleButton auto"
                label={
                  !inProgress
                    ? !this.state.leaseSpecId
                      ? "Save"
                      : "Update"
                    : !this.state.leaseSpecId
                    ? "Saving"
                    : "Updating"
                }
                onClick={this.onSaveSpecSuite}
              />
            </div>
          </div>
          <div className="info-text"></div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="compTypeIdValue">*/}
                  <div>
                    <label>Comp Type<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="compTypeIdValue">
                    <div className="labelCell" style={{ display: "none" }}>
                      Comp Type
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={compTypeMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.compTypeIdValue}
                          valueKey="compTypeIdValue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------Start Property Info */}
          {isShowAddProeprty && (
            <>
              <div className="row sectionheader-container">
                <div
                  className="col-xs-auto sectionheader"
                  style={{ marginLeft: "12px", paddingBottom: "35px" }}
                >
                  Property Info
                </div>
              </div>
              <div className="row start-xs start-sm">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row start-xs start-sm start-md start-lg">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      {/*<div className="addRow" id="propertyId">*/}
                      <div>
                        <label>Property Name<span className="span-mandatory">*</span></label>
                      </div>
                      {/*</div>*/}
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      {/*<div className="addRow" id="marketId">*/}
                      <div>
                        <label>Market<span className="span-mandatory">*</span></label>
                      </div>
                      {/*</div>*/}
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      {/*<div className="addRow" id="subMarketId">*/}
                      <div>
                        <label>Submarket<span className="span-mandatory">*</span></label>
                      </div>
                      {/*</div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row start-xs start-sm">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="row start-xs start-sm start-md start-lg">
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="addRow" id="propertyId">
                        <div className="labelCell" style={{ display: "none" }}>
                          Property Name
                        </div>
                        <div className="row valueCell">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                            <Select
                              optionData={propertyMasterData}
                              onChange={this.onChangeSelect}
                              defaultValue={this.state.propertyId}
                              valueKey="propertyId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="addRow" id="marketId">
                        <div className="labelCell" style={{ display: "none" }}>
                          Market
                        </div>
                        <div className="row valueCell">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                            <Select
                              optionData={marketMasterData}
                              onChange={this.onChangeSelect}
                              defaultValue={this.state.marketId}
                              valueKey="marketId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="addRow" id="subMarketId">
                        <div className="labelCell" style={{ display: "none" }}>
                          Submarket
                        </div>
                        <div className="row valueCell">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                            <Select
                              optionData={subMarketMasterData.filter(
                                (x) => x.marketId == this.state.marketId
                              )}
                              onChange={this.onChangeSelect}
                              defaultValue={this.state.subMarketId}
                              valueKey="subMarketId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* ---------------------Finish Property Info */}

          <div className="row sectionheader-container">
            <div
              className="col-xs-auto sectionheader"
              style={{ marginLeft: "12px", paddingBottom: "35px" }}
            >
              Spec Suite Info
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="costPsf">*/}
                  <div>
                    <label>Cost PSF<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="furnished">*/}
                  <div>
                    <label>Furnished<span className="span-mandatory">*</span></label>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="preWired">*/}
                  <div>
                    <label>Pre-Wired<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="costPsf">
                    <div className="labelCell" style={{ display: "none" }}>
                      Cost PSF
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.costPsf}
                          valueKey="costPsf"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="furnished">
                    <div className="labelCell" style={{ display: "none" }}>
                      Furnished
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={booleanDDOptions}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.furnished}
                          valueKey="furnished"
                          inputValue={this.state.furnished}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="preWired">
                    <div className="labelCell" style={{ display: "none" }}>
                      Pre-Wired
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={booleanDDOptions}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.preWired}
                          valueKey="preWired"
                          inputValue={this.state.preWired}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add Specs Item */}
          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="monthsVacantPrior">*/}
                  <div>
                    <label>Months Vacant Prior<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="rapidUtilized">*/}
                  <div>
                    <label>Rapid Utilized<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="percentageOfficevsOpen">*/}
                  <div>
                    <label>Layout Configuration<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="monthsVacantPrior">
                    <div className="labelCell" style={{ display: "none" }}>
                      Months Vacant Prior
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.monthsVacantPrior}
                          valueKey="monthsVacantPrior"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="rapidUtilized">
                    <div className="labelCell" style={{ display: "none" }}>
                      Rapid Utilized
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={booleanDDOptions}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.rapidUtilized}
                          valueKey="rapidUtilized"
                          inputValue={this.state.rapidUtilized}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="percentageOfficevsOpen">
                    <div className="labelCell" style={{ display: "none" }}>
                      Layout Configuration
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={percentageOfficevsMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.percentageOfficevsOpen}
                          valueKey="percentageOfficevsOpen"
                          inputValue={this.state.percentageOfficevsOpen}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Add two Specs Item */}
          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="timeFromCompletionToLCD">*/}
                  <div>
                    <label>Downtime<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="downtimeValue">*/}
                  <div>
                    <label>Downtime Value</label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="relocationReason">*/}
                  <div>
                    <label>Relocation Reason<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="timeFromCompletionToLCD">
                    <div className="labelCell" style={{ display: "none" }}>
                      Downtime
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={downtimeMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.timeFromCompletionToLCD}
                          valueKey="timeFromCompletionToLCD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="downtimeValue">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Downtime Value
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.downtimeValue}
                          valueKey="downtimeValue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="relocationReason">
                    <div className="labelCell" style={{ display: "none" }}>
                      Relocation Reason
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={relocationReasonMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.relocationReason}
                          valueKey="relocationReason"
                          inputValue={this.state.relocationReason}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="constructionMonths">*/}
                  <div>
                    <label>Construction Months</label>
                  </div>
                  {/*</div>*/}
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow">*/}
                  <div>
                    <label>Construction Type</label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow">*/}
                  <div>
                    <label>Prior Premises Condition</label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="constructionMonths">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Construction Months
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.constructionMonths}
                          valueKey="constructionMonths"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Construction Type
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={constructionTypeMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.constructionType}
                          valueKey="constructionType"
                          inputValue={this.state.constructionType}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Prior Premises Condition
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={priorPremisesMaster}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.priorPremisesCondition}
                          valueKey="priorPremisesCondition"
                          inputValue={this.state.priorPremisesCondition}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="marketId">*/}
                  <div>
                    <label>Market</label>
                  </div>
                  {/*</div>*/}
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow">*/}
                  <div>
                    <label>Submarket</label>
                  </div>
                  {/*</div>*/}
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="elevatorLobbyExposurer">*/}
                  <div>
                    <label>Elevator ID<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          {/*</div>*/}
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="marketId">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Market
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={marketMasterData}
                          onChange={this.onChangeSelect}
                          defaultValue={
                            this.state.marketId
                              ? this.state.marketId
                              : propertyInfo.marketId
                          }
                          valueKey="marketId"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      Submarket
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={subMarketMasterData}
                          onChange={this.onChangeSelect}
                          defaultValue={
                            this.state.subMarketId
                              ? this.state.subMarketId
                              : propertyInfo.subMarketId
                          }
                          valueKey="subMarketId"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="elevatorLobbyExposurer">
                    <div className="labelCell" style={{ display: "none" }}>
                      Elevator ID
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={booleanDDOptions}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.elevatorLobbyExposurer}
                          valueKey="elevatorLobbyExposurer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row sectionheader-container">
            <div
              className="col-xs-auto sectionheader"
              style={{ marginLeft: "12px", paddingBottom: "35px" }}
            >
              Lease Info
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="lcd">*/}
                  <div>
                    <label>LCD (Lease Commencement Date)<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="tenantRSF">*/}
                  <div>
                    <label>Tenant RSF<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="termsInMonth">*/}
                  <div>
                    <label>Term (Months)<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="lcd">
                    <div className="labelCell" style={{ display: "none" }}>
                      LCD (Lease Commencement Date)
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputDatePicker
                          className="addSpecEdit"
                          onEdit={this.onChangeDate}
                          valueDate={this.state.lcd}
                          valueKey="lcd"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="tenantRSF">
                    <div className="labelCell" style={{ display: "none" }}>
                      Tenant RSF
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.tenantRSF}
                          valueKey="tenantRSF"
                          //maxDate={subtractDayIntoDate(initialMaturity)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="termsInMonth">
                    <div className="labelCell" style={{ display: "none" }}>
                      Term (Months)
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.termsInMonth}
                          valueKey="termsInMonth"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Lease Add Item */}
          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="startingRate">*/}
                  <div>
                    <label>Starting Rate (Annual)<span className="span-mandatory">*</span></label>{" "}
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="quotedRate">*/}
                  <div>
                    <label>Quoted Rate<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="freeRentInMonths">*/}
                  <div>
                    <label>Free Rent<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="startingRate">
                    <div className="labelCell" style={{ display: "none" }}>
                      Starting Rate (Annual){" "}
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.startingRate}
                          valueKey="startingRate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="quotedRate">
                    <div className="labelCell" style={{ display: "none" }}>
                      Quoted Rate
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.quotedRate}
                          valueKey="quotedRate"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="freeRentInMonths">
                    <div className="labelCell" style={{ display: "none" }}>
                      Free Rent
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.freeRentInMonths}
                          valueKey="freeRentInMonths"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row start-xs start-sm" style={{ marginTop: "10px" }}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="additionalTI">*/}
                  <div>
                    <label> Additional TI<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="industryTypeId">*/}
                  <div>
                    {" "}
                    <label>Tenant Industry<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="annualEscalation">*/}
                  <div>
                    <label>Annual Rent Escalation<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="additionalTI">
                    <div className="labelCell" style={{ display: "none" }}>
                      Additional TI
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.additionalTI}
                          valueKey="additionalTI"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="industryTypeId">
                    <div className="labelCell" style={{ display: "none" }}>
                      Tenant Industry
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <Select
                          optionData={industryMasterData}
                          onChange={this.onChangeSelect}
                          defaultValue={this.state.industryTypeId}
                          valueKey="industryTypeId"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="annualEscalation">
                    <div className="labelCell" style={{ display: "none" }}>
                      Annual Rent Escalation
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <RadioGroup
                          radioArr={[
                            { label: "Percentage", key: "Percentage" },
                            { label: "PSF", key: "PSF" },
                          ]}
                          selected={this.state.annualEscalationType}
                          valueKey="annualEscalationType"
                          onChangeRadio={this.onChangeRadio}
                        />
                      </div>
                      <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.annualEscalation}
                          valueKey="annualEscalation"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row start-xs start-sm">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="led">*/}
                  <div>
                    <label>LED (Lease Execution Date)</label>
                  </div>
                  {/*</div>*/}
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  {/*<div className="addRow" id="nnnPerFS">*/}
                  <div>
                    <label>Net Rent<span className="span-mandatory">*</span></label>
                  </div>
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
          <div
            className="row start-xs start-sm"
            style={{ marginBottom: "10px" }}
          >
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div className="row start-xs start-sm start-md start-lg">
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="led">
                    <div
                      className="labelCell removeContent"
                      style={{ display: "none" }}
                    >
                      LED (Lease Execution Date)
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputDatePicker
                          onEdit={this.onChangeDate}
                          valueDate={this.state.led}
                          valueKey="led"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <div className="addRow" id="nnnPerFS">
                    <div className="labelCell" style={{ display: "none" }}>
                      Net Rent
                    </div>
                    <div className="row valueCell">
                      <div className="col-xs-11 col-sm-12 col-md-12 col-lg-12 no-padding">
                        <InputField
                          type="number"
                          className="addSpecEdit"
                          onEdit={this.onChangeField}
                          inputValue={this.state.nnnPerFS}
                          valueKey="nnnPerFS"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
		</div>
      </>
    );
  }
}

export default AddSpecSuite;

import { Box, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment-mini";
import { DateFormat } from "../../../constants/appConstants";
export default class ClearImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: null,
      selectedDate:null,
     endDate:moment().endOf("month").format(DateFormat),//new Date(),

    };
  }
  handleChange=(e,value,mode)=>
  {
this.setState({
    selectedDate:new Date(e)
});
this.props.handleDateChange(e,value,mode);
  }
  render() {
    return (
      <>
        <div style={{ padding: "8px" }}>
          <Autocomplete
            size="small"
            multiple={this.props.filterConfig[0].isMultiple}
            underlineStyle={{ display: "none" }}
            limitTags={1}
            options={(this.props.sourceData || []).map(
              (option) => option[this.props.filterConfig[0].key]
            )}
            autoHighlight
            value={this.state.selectedValue}
            key={"dropDown_filter_forclear_import"}
            id={"dropDown_filter_forclear_import"}
            onChange={(event, value) => {
              this.setState({
                selectedValue: value,
              });
              this.props.handleClearChange(event, value);
              // this.onChange(filter, event, value);
            }}
            renderInput={(params) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Market <span className="span-mandatory">*</span></label>
                <TextField
                  {...params}
                  fullWidth
                  className="autosearc-text"
                  hiddenLabel
                  variant="outlined"
                  margin="normal"
                  placeholder={this.props.placeholder}
                />
              </Box>
            )}
          />
           <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            key={"exportDatePicker_filter"}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
              className="filter-label"
            >
              <label htmlFor="filled-hidden-label-small">Select Date<span className="span-mandatory">*</span></label>
              <Grid container>
                <DatePicker
                  //variant="inline"
                  openTo="year"
                  views={["year", "month"]}
                  //label="Sel"
                  //helperText="Start from year selection"
                  //defaultValue={null}
                  value={this.state.selectedDate}
                  maxDate={this.state.endDate}
                  onChange={(event, value) => {
                    this.handleChange(event,value,"fulldate")                    
                  }}
                  emptyLabel="Select Date"
                  // {this.props.handleDateChange}
                />
              </Grid>
            </Box>
          </MuiPickersUtilsProvider>
           {/*<Box
                sx={{ display: "flex", alignItems: "center",paddingBottom: '5px !important' }}
                className="filter-label"
              >
                   <label htmlFor="filled-hidden-label-small">Import File</label>
                   </Box>
          <input
          id="file_forclear_import"
            type="file"
            style={{ padding: "24px", backgroundColor: "lightgray" }}
            onChange={this.props.onClearFileChange}
          />*/}
      
        </div>
        <div className="row" style={{ float: "right" }}>
          {/* <div className="col-md-4 col=lg-4">
            <button
              className="import-button"
              style={{ float: "right" }}
              onClick={() => {
                this.props.onClearFileUpload();
              }}
            >
              Upload
            </button>
          </div> */}
          <div className="col-md-10 col-lg-10">
            <button
            id="cancelbutton_forclear_import"
              className="import-button"
              style={{ float: "right" }}
              onClick={() => {
                this.props.cancelClearImport();
              }}
            >
              Cancel
            </button>
          </div>
          <div className="col-md-2 col-lg-2">
            <button
            id="replacebutton_forclear_import"
            //   className={
            //     this.props.enableClear == true
            //       ? "import-button"
            //       : "disableimport-button"
            //   }
              className="import-button"
              style={{ float: "right" }}
              //disabled={!this.props.enableClear}
              onClick={() => {
                this.props.acceptClearImport();
              }}
            >
              Clear
            </button>
          </div>
          
        </div>
      </>
    );
  }
}

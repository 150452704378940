import React from 'react'
import './index.less'
import { ToastContainer } from 'react-toastify'
// import "react-toastify/dist/ReactToastify.css";
import PropTypes from 'prop-types'

ToastMessage.propTypes = {
  position: PropTypes.string,
  autoClose: PropTypes.number,
  hideProgressBar: PropTypes.bool
}

export default function ToastMessage ({
  position = 'top-center',
  autoClose = 6000,
  hideProgressBar = true
}) {
  return (
    <ToastContainer
      position={position}
      autoClose={autoClose}
      hideProgressBar={hideProgressBar}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable={false}
      pauseOnHover={false}
    />
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import './style.less';
import { controlType } from '../../../constants/common';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ResetIcon from '@material-ui/icons/RestorePageOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Chip, Tooltip, IconButton, Paper, Badge, FormControlLabel, Checkbox, FormControl, RadioGroup, Radio, makeStyles, Box, CircularProgress } from '@material-ui/core';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FilterIcon from '@material-ui/icons/FilterListOutlined';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { applyFilterOnData } from '../../../utils/helper';
import _ from 'lodash';
import { CloseOutlined } from '@material-ui/icons';
import { withStyles } from "@material-ui/core/styles";
import DatePicker from '../DatePicker/DatePicker';
import NumericRange from '../NumericRange/NumericRange';

const styles = theme => ({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

class PageFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filterConfig: [],
      selectedData: [],
      autoFilter: true,
      onlySearch: true,
      openFilterPane: false,
      openFilter: false,
      filterAlwaysVisible: true,
      internalSelectedData: [],
      defaultSelectedData: [],
      filterSelectedData:[],
      startDate: "",
      endDate: "",
      
      startValue:0,
      endValue:0,
      selectedValue:[],
      setSelectedValue:[],
	  selectedBuilding:[],
      value:null,
      inputValue:"",
      options:[],
      loading:false,
      open:false,
      loader:false,
	  loadBuilding: false
    };
  }

  componentDidMount() {
    this.setState({
      // selectedData: this.props.selectedData || [],
      internalSelectedData: this.props.selectedData || [],
      defaultSelectedData: this.props.defaultSelectedData || [],
      filterAlwaysVisible: this.props.filterAlwaysVisible || true
    });
  }

  static getDerivedStateFromProps(newpros, state) {
    if (
      JSON.stringify(newpros.filterConfig) !== JSON.stringify(state.filterConfig) ||
      JSON.stringify(newpros.selectedData) !== JSON.stringify(state.internalSelectedData) ||
      JSON.stringify(newpros.defaultSelectedData) !== JSON.stringify(state.defaultSelectedData) ||
      newpros.autoFilter !== state.autoFilter ||
      newpros.filterAlwaysVisible !== state.filterAlwaysVisible || 
      newpros.filterSelectedData !== state.filterSelectedData 

      // newpros.onlySearch !== state.onlySearch
    ) {
      return {
        defaultSelectedData: newpros.defaultSelectedData || [],
        filterSelectedData: newpros.filterSelectedData || [],
        filterConfig: newpros.filterConfig,
        autoFilter: newpros.autoFilter,
        //  onlySearch:newprops.onlySearch,
        selectedData: newpros.selectedData || [],
        internalSelectedData: newpros.selectedData || [],
        filterAlwaysVisible: newpros.filterAlwaysVisible === undefined ? true : newpros.filterAlwaysVisible
      };
    }
    return null;
  }

  render() {
    const { filterConfig } = this.props;
    const selectData = this.props.filterSelectedData;    
    if(selectData != null && selectData.length>0)
    {
    this.setState({
      selectedData:selectData[0]
    });
  }
    let isSelected=false;
    
    const { internalSelectedData, openFilterPane, filterAlwaysVisible, openFilter ,selectedData} = this.state;
    if(selectedData != null && selectedData.length>0 )    
    {
      let breakCondition=false;
      selectedData.forEach(m=>{
      if(m.selected !='' && breakCondition ==false)
      {
        isSelected=true;
        breakCondition=true;
      }
    });
  }
    return (

      <>
      {/* Active filter chip */}
        {isSelected == true && selectedData != null && selectedData.length > 0 && this.props.openAppliedFilterPane &&  (
          <div style={{
            backgroundColor: '#ffff',
            paddingBottom: '7px'
          }}>

            <div className="col-xs-12">

              <div className="row start-xs">
               
                  <span className="applied-filter-header" style={{ paddingTop: '8px' }}>Active Filters : &nbsp;</span>

                {selectedData.map((data) => {
                  return this.renderFilterAppliedPane(data);
                })}

                <Button                  
                  className="btn-clear"                  
                  onClick={() => {
                    this.setState({
                      selectedData: [],
                      internalSelectedData: [],
                      selectedValue:[],
                      setSelectedValue:[],
                      defaultSelectedData:[],
					  selectedBuilding:[]
                      
                    });
                    this.props.resetFilters();
                    this.props.onFilterChanged(this.state.defaultSelectedData);
                  }}
                >
                  Clear All
                </Button>               
              </div>

            </div>
          </div>
        )}


        {filterConfig && (
          <div className={this.props.filterAlwaysVisible ? 'row pageFilter-container bottom-xs start-xs' : 'row bottom-xs start-xs'}>
            {filterConfig &&
              this.props.autoFilter &&
              (filterAlwaysVisible || openFilter) &&
              filterConfig.map((filter, index) => {
                return this.renderFitter(filter, index);
              })}

            {/* {filterConfig && !this.props.autoFilter && this.props.onlySearch && (filterAlwaysVisible || openFilter) && (
              <>
                <div className="col-xs-12 col-lg-12">
                  <div className="row bottom-xs divRow" >
                    {filterConfig.map((filter, index) => {
                      return this.renderFitter(filter, index);
                    })}
                  </div>
                </div>
                <div className="col-xs-12 col-lg-12 filter-action-group">
                  <div className="row start-xs end-xs">
                    <div className="col-xs-4 col-sm-3">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SearchIcon />}
                        onClick={() => {
                          this.setState({
                            internalSelectedData: this.getcleanData(),
                            openFilter: false
                          });
                          this.props.onFilterChanged(this.getcleanData());
                        }}
                      >
                        Search
                      </Button>
                    </div>


                  </div>
                </div>
              </>
            )} */}

            {filterConfig && !this.props.autoFilter && (filterAlwaysVisible || openFilter) && (
              <>
                <div className="col-xs-12 col-lg-12">
                  <div className="row bottom-xs divRow" >
                    {filterConfig.map((filter, index) => {
                      return this.renderFitter(filter, index);
                    })}
                  </div>
                </div>
                <div className="col-xs-12 col-lg-12 filter-action-group">
                  <div className="row start-xs end-xs">
                    <div className="col-xs-4 col-sm-3">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<SearchIcon />}
                        onClick={() => {
                          this.setState({
                            internalSelectedData: this.getcleanData(),
                            openFilter: false
                          });
                          this.props.onFilterChanged(this.getcleanData());
                        }}
                      >
                        Search
                      </Button>
                    </div>
                    <div className="col-xs-4 col-sm-1">
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        startIcon={<ResetIcon />}
                        onClick={() => {
                          this.setState({
                            selectedData: [],
                            internalSelectedData: []
                          });
                          this.props.onFilterChanged(this.state.defaultSelectedData);
                        }}
                      >
                        reset
                      </Button>
                    </div>
                    {openFilter && !filterAlwaysVisible && (
                      <div className="col-xs-12 col-sm-1">
                        <Button
                          variant="contained"
                          color="default"
                          size="small"
                          startIcon={<CloseOutlined />}
                          onClick={() => {
                            this.setState({
                              openFilter: false
                            });
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {!this.props.openFilter && filterAlwaysVisible === false && this.props.autoFilter == false
              ? (
                this.renderFilterPane(openFilterPane, internalSelectedData)
              )
              : filterAlwaysVisible &&
                internalSelectedData &&
                internalSelectedData.length > 0 && this.props.autoFilter == false &&              
                internalSelectedData.filter((x) => (x.selected || []).length > 0).length
                ? (
                  this.renderFilterPane(openFilterPane, internalSelectedData)
                )
                : (
                  <></>
                )}
          </div>
        )}
      </>
    );
  }

  renderFilterAppliedPane = (data) => {
    console.log(data);
    const { filterConfig } = this.props;
    const appliedFilter = [];
    if (data.selected && data.selected.length > 0) {
      let filterTitle=filterConfig.find((y) => y.key === data.key).title;
      const values = [];
      if(data.key=='lender' || data.key=='ownerOrBorrower')
      {
        values.push(
          <div className="col-xs-auto filter-pane">
          <Chip
            label={this.capitalizeFirstLetter(filterTitle) + ':' + data.selected}
            disabled={filterConfig.find((y) => y.key === data.key).hiddenWhenChild ||
              filterConfig.find((y) => y.key === data.key).allowreset === false}
            // onDelete={() => {

            //   this.handleDelete(x, data);
            // }}
            color="primary"
            variant="outlined"
            className="filter-chip"
          />
        </div>
        );

      }
     
     else
     {
      data.selected.forEach((x) => {

        values.push(
          <div className="col-xs-auto filter-pane">
          <Chip
            label={ (data.key =='lender' || data.key=='ownerOrBorrower') ? this.capitalizeFirstLetter(filterTitle) + ': ' + x : this.capitalizeFirstLetter(filterTitle) + ': ' + x[data.key]}
            disabled={filterConfig.find((y) => y.key === data.key).hiddenWhenChild ||
              filterConfig.find((y) => y.key === data.key).allowreset === false}
            onDelete={() => {

              this.handleDelete(x, data);
            }}
            color="primary"
            variant="outlined"
            className="filter-chip"
          />
        </div>
        );

      });
   }
  //  appliedFilter.push(<span className="applied-filter-value">{values} </span>);
      appliedFilter.push(<>{values} </>);


    }
    // return <div className="col-xs-auto filter-pane">{appliedFilter}</div>;
    return <>{appliedFilter}</>;

  }

  renderAppliedFilter = (data) => {
    const { filterConfig } = this.props;
    const appliedFilter = [];
    if (data.selected && data.selected.length > 0) {
      appliedFilter.push(<span className="applied-filter-text">{filterConfig.find((y) => y.key === data.key).title}:</span>);
      const values = [];
      data.selected.forEach((x) => {
        values.push(
          <Chip
            label={x[data.key]}
            disabled={filterConfig.find((y) => y.key === data.key).hiddenWhenChild ||
              filterConfig.find((y) => y.key === data.key).allowreset === false}
            onDelete={() => {
              this.handleDelete(x, data);
            }}
            color="primary"
            variant="outlined"
          />
        );
      });
      appliedFilter.push(<span className="applied-filter-value">{values}</span>);
    }
    return <div className="col-xs-auto ">{appliedFilter}</div>;
  };

  handleDelete = (item, data) => {
    const { internalSelectedData,selectedData } = this.state;
    if (internalSelectedData.length == 0)
      this.setState({
        internalSelectedData: _.cloneDeep(this.state.defaultSelectedData)
      });

    (internalSelectedData.find((x) => x.key === data.key) || []).selected = data.selected.filter((x) => {
      return x[data.key] !== item[data.key];
    });
    (selectedData.find((x) => x.key === data.key) || []).selected = data.selected.filter((x) => {
      return x[data.key] !== item[data.key];
    });
    // (this.props.filterSelectedData.find((x) => x.key === data.key) || []).selected = data.selected.filter((x) => {
    //   return x[data.key] !== item[data.key];
    // });
    var selectedItem=selectedData; // make a separate copy of the array
    var index = selectedItem.indexOf(selectedData.find(m=>
      m.selected==''))
    if (index !== -1) {
      selectedItem.splice(index, 1);
     
     }
    this.props.onFilterChanged(selectedData);
  };


  renderFitter = (filter, index) => {
    let controlToBind;
    let sDate = new moment().startOf('month').format('MM/DD/YYYY');
    let eDate = new moment().endOf('month').format('MM/DD/YYYY');
    const { selectedData } = this.state;
    const isVisible=filter.isVisible;
    if (filter !== undefined && filter.hideFilter !== true && !this.isHidden(filter)) {
      const defaultValues = [];
      const initialValue = [];
      if (selectedData && selectedData.length > 0) {
        const selectedItems = selectedData.find((x) => x.key === filter.key) || {};
        if (selectedItems && selectedItems.selected && selectedItems.selected.length > 0) {
          if(selectedItems.key=='lender' || selectedItems.key=='ownerOrBorrower')
          {
            defaultValues.push(selectedItems.selected);
          }
          else
          {
          selectedItems.selected.forEach((element) => {
            defaultValues.push(element[filter.key]);
          });
        }
        }
      }

      const sourceData = applyFilterOnData(filter.source || [], selectedData, filter.key);
      // console.log(filter.key, sourceData, filter.type);
      const {loading,loader, loadBuilding} = this.state;

      switch (filter.type) {
        case controlType.Autofill:
          controlToBind = (
            <Autocomplete
              freeSolo
              //autoSelect
              // selectOnFocus
              // clearOnBlur
              // handleHomeEndKeys
              id="free-solo-with-text-demo"
              //filterSelectedOptions     
              size="small"
              //autoComplete 
              includeInputInList
              //disableOpenOnFocus
              underlineStyle={{ display: 'none' }}
              multiple={false}
              limitTags={1}
              options={(sourceData || []).map((option) => option[filter.key])}
              autoHighlight
              //async={true}
              //filterOptions={(x) => x}
              //autoComplete
              //includeInputInList
              //filterSelectedOptions
              // getOptionLabel={(option) =>
              //   typeof option === 'string' ? this.state.selectedValue : (sourceData || []).map((option) => option[filter.key])
              // }
              // autoComplete
              // includeInputInList
              // filterSelectedOptions
              onChange={(event, value) => {
                this.onChange(filter, event, value);
              }}
              onInputChange={(event, value) => {
                // let inputValue=value != "" && this.state.selectedValue.length > 0  ? this.state.selectedValue[0]+value : value;
                event && (event.type == 'change' || event.type == 'input') && value != "" && value != null && value.length > 2 
                ? this.onInputChange(filter, event, value) 
                : null;

                // value != "" && value != null && value.length > 2 ? this.onInputChange(filter, event, value):null;
              }}
              value={filter.key == 'buyer' ? (defaultValues != null && defaultValues.length > 0 ? defaultValues[0] :
                (this.state.selectedValue != null && this.state.selectedValue.length > 0 ? this.state.selectedValue[0] : [])) :
				filter.key == 'buildingName' ? (defaultValues != null && defaultValues.length > 0 ? defaultValues[0] :
					(this.state.selectedBuilding != null && this.state.selectedBuilding.length > 0 ? this.state.selectedBuilding[0] : [])) :
                (defaultValues != null && defaultValues.length > 0 ? defaultValues[0] : this.state.setSelectedValue != null && this.state.setSelectedValue.length > 0 ? this.state.setSelectedValue[0] : [])}// != null && defaultValues.length >0 ? defaultValues : (this.state.selectedValue != null && this.state.selectedValue.length > 0 ? this.state.selectedValue : [])
              key={'dropDown_filter' + index}
              id={'dropDown_filter' + index}
              loading={filter.key == 'buyer' ? loading : filter.key == 'buildingName' ? loadBuilding : loader}
              // onChange={(event, value) => {
              //   this.onChange(filter, event, value);
              // }}
              //               open={this.state.open}
              //               onOpen={() => {
              //                 this.setState({open:true})
              //               }}
              //               onClose={() => {
              //                 this.setState({open:false})
              //               }}
              // loading={loading}
              renderInput={(params) => <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                <label htmlFor="filled-hidden-label-small">{filter.title}</label>
                <TextField {...params}
                  //style={{ border: '1px solid' }} 
                  //  value={ this.state.selectedValue != null && this.state.selectedValue.length > 0 ? this.state.selectedValue[0] : null}// != null && defaultValues.length >0 ? defaultValues : (this.state.selectedValue != null && this.state.selectedValue.length > 0 ? this.state.selectedValue : [])
                  onPaste={(event) => this.onPaste(filter, event)}
                  hiddenLabel
                  variant="outlined" margin="normal"
                  placeholder="Type at least 3 characters to search"

                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {filter.key == 'buyer' ? (loading ? <CircularProgress size={20} /> : null) :
							filter.key == 'buildingName' ? (loadBuilding ? <CircularProgress size={20} /> : null) :
                          (loader ? <CircularProgress size={20} /> : null)
                        }
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              </Box>
              }
            />
          );
          break;
        case controlType.DropDown:         
          controlToBind = (
            <Autocomplete          
              size="small"
              underlineStyle={{ display: 'none' }}
              multiple={filter.multiple || false}
              limitTags={1}
              options={(sourceData || []).map((option) => option[filter.key])}
              autoHighlight
              value={defaultValues}
              key={'dropDown_filter' + index}
              id={'dropDown_filter' + index}
              onChange={(event, value) => {
                this.onChange(filter, event, value);
              }}

              renderInput={(params) => <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
                <label htmlFor="filled-hidden-label-small">{filter.title}</label>
                <TextField {...params} 
                //style={{ border: '1px solid' }} 
                hiddenLabel
                  variant="outlined" margin="normal" />
              </Box>
              }
            />
          )
          break;
        case controlType.MUIDatePicker:
          controlToBind = (
            <MuiPickersUtilsProvider utils={DateFnsUtils} key={'datePicker_filter' + index}>
              <Grid container>
                <KeyboardDatePicker
                  size="small"
                  margin="normal"
                  id={'datePicker_filter' + index}
                  label={filter.title}
                  value={new Date('2014-08-18T21:11:54')}
                  onChange={(date) => {
                    this.handleDateChange(filter, date);
                  }}
                  format="MM/dd/yyyy"
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                />{' '}
              </Grid>
            </MuiPickersUtilsProvider>
          );
          break;
        case controlType.DatePicker:
          controlToBind = (
            
            <MuiPickersUtilsProvider utils={DateFnsUtils} key={'datePicker_filter' + index}>
              <label htmlFor="filled-hidden-label-small">{filter.title}</label>
              <Grid container>
                <KeyboardDatePicker                  
                  clearable={true.toString()}
                  //disableToolbar
                  autoOk
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  fullWidth
                  id="date-picker-inline"
                  //style={{ border: '1px solid' }} 
                  variant="outlined" 
                  margin="normal" 
                  hiddenLabel
                  //label={filter.title}
                  value={defaultValues[0] || null}
                  onChange={(date) => {
                    this.handleDateChange(filter, date);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  InputProps={{
                    endAdornment: defaultValues[0] && (
                      <IconButton
                        size="small"
                        onClick={() => {
                          this.handleDateChange(filter, null);
                        }}
                      >
                        <ClearIcon size="small" />
                      </IconButton>
                    )
                  }}
                  InputAdornmentProps={{
                    position: 'start'
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          );
          break;
        case controlType.DateRangePicker:
          controlToBind = (            
            <MuiPickersUtilsProvider utils={DateFnsUtils} key={'dateRangePicker_filter_' + index}>
              <label htmlFor="filled-hidden-label-small" className="filter-label">{filter.title}</label>
              <Grid container>
                <div style={{width:"100%",paddingTop: "5px"}}>
              <DatePicker  
                key={index}
                componentKey={index}
                componentId={index}
                onDateChange={(startDate,endDate) => {this.onDateRangeChange(filter,startDate,endDate)}}
                //startDate={this.state.startDate || sDate}
                //endDate={this.state.endDate || eDate}
              />
              </div>
              </Grid>
            </MuiPickersUtilsProvider>
          );
          break;
          case controlType.Checkbox: {
            const internalSelectedData =
              (selectedData || []).find(function (ele) {
                return ele.key === filter.key;
              }) || {};
            controlToBind = filter.hiddenWhenChild
              ? (
                <></>
                )
              : (
                <>
                  <FormControlLabel
                    key={'checkbox_' + filter.value}
                    value={(internalSelectedData !== undefined && internalSelectedData.selected !== undefined && internalSelectedData.selected.length > 0) ? internalSelectedData.selected[0][filter.key] : false}
                    control={
                      <Checkbox
                        color="primary"
                        checked={(internalSelectedData !== undefined && internalSelectedData.selected !== undefined && internalSelectedData.selected.length > 0) ? internalSelectedData.selected[0][filter.key] : false}
                        onChange={(event) => {
                          const temp = { ...filter };
                          this.onCheckboxChange(temp, filter.value, event.target.checked);
                        }}
                      />
                    }
                    label={filter.title}
                    labelPlacement="end"
                  />
                </>
                );
            break;
          }
        case controlType.RadioButton: {
          const internalSelectedData =
            (selectedData || []).find(function (ele) {
              return ele.key === filter.key;
            }) || {};
          // console.log('internalSelectedData', internalSelectedData);
          controlToBind = filter.hiddenWhenChild
            ? (
              <></>
            )
            : (
              <>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={((internalSelectedData.selected || [])[0] || {})[filter.key] || 'Market'}
                    onChange={(event) => {
                      const temp = { ...filter };
                      this.onRadioboxChange(temp, event.target.value, true);
                    }}
                  >
                    {filter.options.map((item) => (
                      <FormControlLabel
                        key={'radio' + item.value}
                        value={item.value}
                        control={<Radio color="primary" />}
                        label={item.title}
                        labelPlacement="end"
                      />
                    ))
                    }

                  </RadioGroup>
                </FormControl>

              </>
            );
          break;
        }
        case controlType.NumericRange:
          controlToBind = (  
            <Grid container>          
              <label htmlFor="filled-hidden-label-small" className="filter-label">{filter.title}</label>
             
                <div style={{width:"100%",paddingTop: "5px"}}>
              <NumericRange
              index={index}
                onValueChange={(startValue,endValue) => {this.onValueRangeChange(filter,startValue,endValue)}}
                startValue={this.state.startValue}
                endValue={this.state.endValue}
              />
              </div>
              </Grid>
            
          );
          break;
        case controlType.TextBox:
          controlToBind = (
            <Box sx={{ display: 'flex', alignItems: 'center' }} className="filter-label">
              <label htmlFor="filled-hidden-label-small">{filter.title}</label>
              <TextField
               size="small"
                //className="filter-text-field"
                fullWidth
                key={'Text_filter' + index}
                id={'Text_filter' + index}
                onChange={(event, value) => {
                  this.handleOnChange(filter, event, event.target.value);
                }}
                // style={{ border: '1px solid' }} 
                hiddenLabel
                variant="outlined" 
                margin="normal" />
            </Box>



          );
          break;

               default:
          break;
      }
      return (

       <>
       {filter.isVisible == true &&
         <div key={'container_filter' + index} className={`col-xs-12 col-lg${this.props.gridColumn ? `-${this.props.gridColumn}` : ''} start-xs`}>
          {controlToBind}
          {/* <div className="row"><div className={'col-xs-12 col-sm-5 filter-label'} style={{marginTop: 11}}>{filter.title}</div>
        <div className={'col-xs-7'} > {controlToBind}</div>
       </div> */}
        </div>
    }
        </>
      );
    }
  };

  getcleanData = () => {
    const { selectedData } = this.state;
    return selectedData
      .filter(x => !this
        .isHidden(this.props.filterConfig
          .find(y => y.key === x.key)));
  }

  isHidden = (filter) => {
    if (filter.hidewhen === undefined) {
      return false;
    }
    if (filter.hidewhen && filter.hidewhen.key) {
      const { selectedData } = this.state;
      const internalSelectedData =
        (selectedData || []).find(function (ele) {
          return ele.key === filter.hidewhen.key;
        }) || {};
      if ((internalSelectedData.selected || [])
        .find(x => x[filter.hidewhen.key] === filter.hidewhen.value) !== undefined) {
        return true;
      }
    }
    return false;
  }

  handleDateChange = (filter, date) => {
    
    const dateSelected = {};
    if (date !== undefined && date != null) {
      dateSelected[filter.key] = moment(date).format('MM/DD/YYYY');
      this.applyNewFilterData(filter, [dateSelected]);
      return;
    }
    this.applyNewFilterData(filter, []);
  };

  onDateRangeChange = (filter,startDate, endDate) => {   
    console.log(filter);
    //this.setState({ startDate: startDate, endDate: endDate });
    const dateSelected = {};
    if (startDate !== undefined && startDate != null && endDate !== undefined && endDate != null) {
      dateSelected[filter.key] = moment(startDate).format('MM/DD/YYYY') + ' - ' + moment(endDate).format('MM/DD/YYYY');
      this.applyNewFilterData(filter, [dateSelected]);
      return;
    }
    this.applyNewFilterData(filter, []);
  };

  onValueRangeChange = (filter,startValue, endValue) => {   

    this.setState({ startValue: startValue, endValue: endValue });
    const dataSelected = {};
    if (startValue !== undefined && startValue != null  && startValue !=0 && endValue !== undefined && endValue != null && endValue !=0) {
      dataSelected[filter.key] = startValue + ' - ' + endValue;
      this.applyNewFilterData(filter, [dataSelected]);
      return;
    }
    else  if (startValue !== undefined && startValue != null  && startValue !=0 && endValue !== undefined && endValue != null && endValue ==0) {
      dataSelected[filter.key] = startValue ;
      this.applyNewFilterData(filter, [dataSelected]);
      return;
    }
    else  if (startValue !== undefined && startValue != null  && startValue ==0 && endValue !== undefined && endValue != null && endValue !=0) {
      dataSelected[filter.key] = endValue ;
      this.applyNewFilterData(filter, [dataSelected]);
      return;
    }
    this.applyNewFilterData(filter, []);
  };

  //DateRangeChange = () => { };

  onRadioboxChange = (filter, event, value) => {
    const newValues = [];

    const a = {};
    a[filter.key] = event;
    newValues.push(a);

    this.applyNewFilterData(filter, newValues);
  };

  onCheckboxChange = (filter, event, value) => {
    const { selectedData } = this.state;
    const newSelectedData =
      (selectedData || []).find(function (ele) {
        return ele.key === filter.key;
      }) || {};
    let newValues = newSelectedData.selected || [];
    if (value === true) {
      if (newValues.find((x) => x[filter.key] === event) === undefined) {
        const a = {};
        a[filter.key] = event;
        newValues.push(a);
      }
    } else {
      newValues = newValues.filter((x) => x[filter.key] !== event);
    }
    this.applyNewFilterData(filter, newValues);
  };

  
  onPaste(filter,event){
     var text = event.clipboardData.getData('Text');
     var index = text.substring(0).search(/[A-Za-z]/);
     if(index != -1)
     {
       let ch = text[index];
       let replacement = '';
       //change case
       if (ch == ch.toLowerCase())
         replacement = ch.toUpperCase();
       else if (ch == ch.toUpperCase())
         replacement = ch.toLowerCase();
         
       let newText = text.substr(0, index) + replacement + text.substr(index + replacement.length);
       //let lowerCaseText = text.toLowerCase();
       //event.target.value = lowerCaseText;
       this.onInputChange(filter, event, newText);
       //this.onChange(filter,event,text);
       event.preventDefault();   
     }  
    //this.value = text + " "; //  text.substring(0, text.length - 1);
 }

  onChange = (filter, event, value) => {
    this.setState({
      selectedValue: [],
      setSelectedValue: [],
	  selectedBuilding:[]
    });
    let breakCondition = false;
    const selected = filter.source.filter((x) => {
      if (filter.multiple) {
        return value.find((j) => j === x[filter.key]);
      } else {
        if (x[filter.key] == value && breakCondition == false) {
          breakCondition = true;
          return x;
        }
      }
    });
    this.applyNewFilterData(filter, selected);
  };

  onInputChange = async (filter, event, value) => {
   // event.preventDefault();
    let res=[];
    res.push(value);
    if(filter.key=='buyer')
   this.setState({selectedValue:res,loading:true},()=>{this.forceUpdate()});//loading:true
   if(filter.key == 'buildingName')
   this.setState({selectedBuilding:res,loadBuilding:true},()=>{this.forceUpdate()});
   else
   this.setState({setSelectedValue:res,loader:true},()=>{this.forceUpdate()});
    await this.props.onInputChange(filter,value);
    this.setState({loading:false,
    loader:false, loadBuilding: false},()=>{this.forceUpdate()});
  };
  handleOnChange(filter, event, value) {
   
    console.log(filter);
    console.log(event);
    console.log(event.target.value);

    this.applyNewFilterData(filter, event.target.value);
  }

  renderFilterPane(openFilterPane, internalSelectedData) {
    const { openFilter, filterAlwaysVisible } = this.state;
    return (
      <div className="col-xs-12">
        <Paper>
          {/* {(internalSelectedData.length > 0) && ( */}
          <div className="row applied-filter-container" >
            <div className="col-xs-12" >
              <Tooltip title="Expand">
                <IconButton aria-label="expand row" size="small" onClick={() => this.setState({ openFilterPane: !openFilterPane })}>
                  {openFilterPane ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Tooltip>
              <Badge color="primary" badgeContent={(internalSelectedData || []).filter((x) => x.selected.length > 0).length} >
                <span className="applied-filter-header">Applied Filters</span>
              </Badge>

              <span className="applied-filter-count"></span>
              {!openFilter && !filterAlwaysVisible && (
                <p className="filterPane-action">
                  <Tooltip title="Show All Filters">
                    <IconButton key={'filterApply'} aria-label="Apply filter" size="small" onClick={() => this.setState({ openFilter: !openFilter })}>
                      <FilterIcon size="small" /> Open filter Pane
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Reset Filters">
                    <IconButton
                      key={'filterReset'}
                      aria-label="Reset filter"
                      size="small"
                      onClick={() => {
                        this.setState({
                          selectedData: _.cloneDeep(this.state.defaultSelectedData),
                          internalSelectedData: _.cloneDeep(this.state.defaultSelectedData)
                        });
                        this.props.onFilterChanged(this.state.defaultSelectedData);
                      }}
                    >
                      <ResetIcon size="small" /> Reset filter(s)
                    </IconButton>
                  </Tooltip>
                </p>
              )}
            </div>
            {openFilterPane && (
              <div className="col-xs-12">
                <hr></hr>
                <div className="row start-xs">
                  {internalSelectedData.map((data) => {
                    return this.renderAppliedFilter(data);
                  })}
                </div>
              </div>
            )}
          </div>
          {/* )} */}
        </Paper>
      </div>
    );
  }
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  applyNewFilterData(filter, selected) {
    const { selectedData } = this.state;
    const newSelectedData = (selectedData || []).filter(function (ele) {
      return ele.key !== filter.key;
    });
    newSelectedData.push({
      key: filter.key,
      type: filter.type,
      operator: filter.operator,
      isComaSeprated: filter.isComaSeprated || false,
      selected: selected
    });
    this.setState({ selectedData: newSelectedData });
    if (this.props.autoFilter) {
      // this.setState({ internalSelectedData: newSelectedData });
      this.props.onFilterChanged(newSelectedData);
    }
  }
}

PageFilter.propTypes = {
  filterConfig: PropTypes.array.isRequired,
  filterApplied: PropTypes.bool,
  autoFilter: PropTypes.bool,
  onlySearch: PropTypes.bool,
  onFilterChanged: PropTypes.func.isRequired,
  onInputChange:PropTypes.func,
  gridColumn: PropTypes.number,
  selectedData: PropTypes.array,
  filterAlwaysVisible: PropTypes.bool,
  filterSelectedData:PropTypes.array,
  resetFilters:PropTypes.func,
  isVisible:PropTypes.bool,
};
export default PageFilter;

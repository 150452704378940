import React from 'react';
import Slider from '@material-ui/core/Slider';
import {
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
  Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import moment from 'moment';
import DatePicker from '../common/DatePicker/DatePicker';
import { DateFormat } from '../../constants/appConstants';
import { getCurrentPayCycleEndDateByDate } from '../../utils/helper';

class SliderDatePicker extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      RadioSelect: 'MTD',
      ShowRadio: true,
      SelectedYear:
        localStorage.getItem('sliderYear') === null
          ? moment().format('YYYY')
          : localStorage.getItem('sliderYear'),
      MTDDefault: moment().format('MM'),
      // moment(localStorage.getItem('selectedendDate')) >
      // moment().endOf('month')
      //   ? moment().format('MM')
      //   : localStorage.getItem('MTDDefault'),
      YTDDefault:
        localStorage.getItem('selectedendDate') != null
          ? moment(localStorage.getItem('selectedendDate')).format('MM')
          : moment().format('MM'),
      yearDefault:
        localStorage.getItem('yearDefault') === null
          ? [moment().format('YYYY'), moment().format('YYYY')]
          : localStorage.getItem('yearDefault').split(',')
    };
  }

  componentDidMount () {}

  valueText = (value) => {
    return `${value} `;
  };

  SetMothValues = (event, value, Type) => {
    let startDate;
    let endDate;
    const dateDigit = Math.round(parseInt(value)) > 9 ? value : value;

    const output = moment(this.state.SelectedYear + '-' + dateDigit, 'YYYY-MM');
    if (event === null ? Type : this.state.RadioSelect === 'MTD') {
      startDate = output.startOf('month').format('L');
      this.setState({ MTDDefault: output.startOf('month').format('MM') });
    } else {
      startDate = '01/01/' + this.state.SelectedYear;
    }
    endDate = output.endOf('month').format('L');

    let Radio;
    if (
      event === null
        ? Type
        : this.state.RadioSelect === 'YEAR' || event === null
          ? Type
          : this.state.RadioSelect === 'YTD'
    ) {
      Radio = 'YTD';
    } else {
      Radio = 'Month';
    }

    localStorage.setItem('MTDDefault', value);
    localStorage.setItem('selectedstartDate', startDate);
    localStorage.setItem('selectedendDate', endDate);

    this.props.handelDateChangesSlideDate(
      startDate,
      endDate,
      Radio,
      this.state.RadioSelect
    );
  };

  yearDateSelection = (event, value) => {
    let startDate;
    let endDate;

    if (value[0] === value[1]) {
      const output = moment(value[0], 'YYYY');
      startDate = output.startOf('year').format('L');
      endDate = output.endOf('year').format('L');
    } else {
      const outputStart = moment(value[0], 'YYYY');
      const outputEnd = moment(value[1], 'YYYY');
      startDate = outputStart.startOf('year').format('L');
      endDate = outputEnd.endOf('year').format('L');
    }
    let Radio;
    if (this.state.RadioSelect === 'YEAR' || this.state.RadioSelect === 'YTD') {
      Radio = 'YTD';
    } else {
      Radio = 'Month';
    }

    localStorage.setItem('yearDefault', [value[0], value[1]]);
    localStorage.setItem('selectedstartDate', startDate);
    localStorage.setItem('selectedendDate', endDate);
    this.setState({
      yearDefault: [value[0], value[1]],
      SelectedYear: value[1]
    });
    this.props.handelDateChangesSlideDate(startDate, endDate, 'YTD');
  };

  onDateRange = (GetStatDate, GetEndDate) => {
    const Radio = 'YTD';
    localStorage.setItem('selectedstartDate', GetStatDate);
    localStorage.setItem('selectedendDate', GetEndDate);
    localStorage.setItem('MTDDefault', moment(GetEndDate).format('MM'));
    localStorage.setItem('sliderYear', moment(GetEndDate).format('YYYY'));
    this.setState({
      SelectedYear: moment(GetEndDate).format('YYYY')
    });
    this.props.handelDateChangesSlideDate(GetStatDate, GetEndDate, Radio);
  };

  onRadioChange = (value) => {
    let startDate;
    let endDate;
    localStorage.setItem('DateRadioSelect', value);
    const output = moment();
    if (value === 'MTD') {
      startDate = moment(localStorage.getItem('selectedendDate'))
        .startOf('month')
        .format('L');
      endDate = moment(localStorage.getItem('selectedendDate'))
        .endOf('month')
        .format('L');
      // this.SetMothValues(null, moment().format("MM"), "MTD");
      const endOfMonth = moment().endOf('month').format('MM/DD/YYYY');

      if (moment(endOfMonth) > moment(endDate)) {
      } else {
        startDate = moment().startOf('month').format('L');
        endDate = moment().endOf('month').format('L');
      }

      localStorage.setItem('MTDDefault', moment(endDate).format('MM'));

      localStorage.setItem('selectedstartDate', startDate);
      localStorage.setItem('selectedendDate', endDate);
      localStorage.setItem('sliderYear', moment(endDate).format('YYYY'));

      this.setState({
        MTDDefault: moment(endDate).format('MM'),
        SelectedYear: moment(endDate).format('YYYY')
      });
      let Radio;
      if (this.state.RadioSelect === 'YEAR' || this.state.RadioSelect === 'YTD') {
        Radio = 'YTD';
      } else {
        Radio = 'Month';
      }
      this.props.handelDateChangesSlideDate(
        startDate,
        endDate,
        Radio,
        this.state.RadioSelect
      );
    } else if (value === 'YTD') {
      const startDate = '01/01/' + this.state.SelectedYear;
      //   const endDate = moment(localStorage.getItem('selectedendDate'))
      //     .endOf('month')
      //     .format('L');
      const endDate = moment().endOf('month');
      localStorage.setItem('selectedstartDate', startDate);
      localStorage.setItem('selectedendDate', endDate.format('MM/DD/YYYY'));
      this.props.handelDateChangesSlideDate(startDate, endDate, 'YTD');
    } else if (value === 'YEAR') {
      this.yearDateSelection(null, [
        moment().format('YYYY'),
        moment().format('YYYY')
      ]);
      localStorage.setItem('sliderYear', moment().format('YYYY'));
    } else if (value === 'CDR') {
      startDate = moment().startOf('month').format('L');
      endDate = moment().endOf('month').format('L');
      localStorage.setItem('selectedstartDate', startDate);
      localStorage.setItem('selectedendDate', endDate);
    }
    this.setState({
      RadioSelect: value,
      ShowRadio: false,
      YTDDefault: moment(localStorage.getItem('selectedendDate')).format('MM'),
      SelectedYear:
        value === 'YEAR' ? moment().format('YYYY') : this.state.SelectedYear
    });
  };

  setYear = (type) => {
    let year;
    let startDate;
    let endDate;

    if (type === 'add') {
      year = moment(localStorage.getItem('selectedendDate'))
        .subtract(1, 'years')
        .format('YYYY');

      startDate = moment(localStorage.getItem('selectedstartDate'))
        .subtract(1, 'years')
        .format('MM/DD/YYYY');
      endDate = moment(localStorage.getItem('selectedendDate'))
        .subtract(1, 'years')
        .format('MM/DD/YYYY');
    } else {
      year = moment(localStorage.getItem('selectedendDate'))
        .add(1, 'years')
        .format('YYYY');
      startDate = moment(localStorage.getItem('selectedstartDate'))
        .add(1, 'years')
        .format('MM/DD/YYYY');
      endDate = moment(localStorage.getItem('selectedendDate'))
        .add(1, 'years')
        .format('MM/DD/YYYY');
    }

    if (moment().format('YYYY') < year) {
      return false;
    }

    localStorage.setItem('selectedstartDate', startDate);
    localStorage.setItem('selectedendDate', endDate);

    this.setState({
      SelectedYear: year
    });
    localStorage.setItem('sliderYear', year);
    this.props.handelDateChangesSlideDate(startDate, endDate, 'YTD');
  };

  render () {
    const YTD = [
      {
        value: 1,
        label: 'Jan'
      },
      {
        value: 2,
        label: 'Feb'
      },
      {
        value: 3,
        label: 'Mar'
      },
      {
        value: 4,
        label: 'Apr'
      },
      {
        value: 5,
        label: 'May'
      },
      {
        value: 6,
        label: 'Jun'
      },
      {
        value: 7,
        label: 'Jul'
      },
      {
        value: 8,
        label: 'Aug'
      },
      {
        value: 9,
        label: 'Sep'
      },
      {
        value: 10,
        label: 'Oct'
      },
      {
        value: 11,
        label: 'Nov'
      },
      {
        value: 12,
        label: 'Dec'
      }
    ];
    const YEARLY = [
      {
        value: moment().subtract(3, 'year').format('YYYY'),
        label: moment().subtract(3, 'year').format('YYYY')
      },
      {
        value: moment().subtract(2, 'year').format('YYYY'),
        label: moment().subtract(2, 'year').format('YYYY')
      },
      {
        value: moment().subtract(1, 'year').format('YYYY'),
        label: moment().subtract(1, 'year').format('YYYY')
      },
      {
        value: moment().format('YYYY'),
        label: moment().format('YYYY')
      }
    ];

    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        style={{ justifyContent: 'center' }}
        pt={2}
        pb={2}
      >
        <Grid item xs={12} style={{ justifyContent: 'center' }} className="sliderDatePicker-container">
          <RadioGroup
            style={{ justifyContent: 'center' }}
            aria-label="gender"
            name="gender1"
            value={this.state.RadioSelect}
            onChange={(e) => {
              this.onRadioChange(e.target.value);
            }}
            row
            size="small"
          >
              <>
                <FormControlLabel
                  value="YTD"
                  size="small"
                  className={'radio-label'}
                  control={<Radio className={'rpt radio'} size='small' />}
                  label={<span className={'rpt radio-label'} style={{ fontSize: '0.768rem' }}>YTD</span>}
                />
                <FormControlLabel
                  value="YEAR"
                  size="small"
                  control={<Radio className={'rpt radio'} size='small'/>}
                  label={<span className={'rpt radio-label'} style={{ fontSize: '0.768rem' }}>Yearly</span>}
                />

                <FormControlLabel
                  value="MTD"
                  size="small"
                  control={<Radio className={'rpt radio'} size='small' />}
                  label={<span className={'rpt radio-label'} style={{ fontSize: '0.768rem' }}>Monthly</span>}
                />
                <FormControlLabel
                  value="CDR"
                  size="small"
                  control={<Radio className={'rpt radio'} size='small'/>}
                  label={<span className={'rpt radio-label'} style={{ fontSize: '0.768rem' }}>Date Range</span>}
                />
              </>
          </RadioGroup>
        </Grid>

        {this.state.RadioSelect === 'YTD'
          ? (<Grid item xs={12}>
            <div className="d-flex">
              <div className="left-icon" style={{ width: '10%' }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.setYear('add')}
                >
                  <ArrowLeftIcon size="small" />
                </IconButton>
              </div>
              <div className="slider-bar" style={{ padding: '4px', width: '80%' }}>
                <Slider
                  track={'normal'}
                  className="slider-mark"
                  defaultValue={this.state.YTDDefault}
                  getAriaValueText={this.valueText}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  min={1}
                  max={12}
                  valueLabelDisplay="auto"
                  marks={YTD}
                  onChangeCommitted={this.SetMothValues}
                />
              </div>
              <div className="right-icon" style={{ width: '10%' }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.setYear('Subtract')}
                >
                  <ArrowRightIcon size="small" />
                </IconButton>
              </div>
            </div>
            <div className="selected-value" style={{ textAlign: 'center' }}>{this.state.SelectedYear}</div>
          </Grid>)
          : null}
        {this.state.RadioSelect === 'MTD'
          ? (<div style={{ width: '100%' }}>
            <div className="d-flex">
              <div style={{ width: '10%' }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.setYear('add')}
                >
                  <ArrowLeftIcon size="small" />
                </IconButton>
              </div>
              <div style={{ padding: '4px', width: '80%' }}>
                <Slider
                  track={false}
                  value={this.state.MTDDefault}
                  getAriaValueText={this.valueText}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  min={1}
                  max={12}
                  valueLabelDisplay="auto"
                  marks={YTD}
                  onChangeCommitted={this.SetMothValues}
                />
              </div>
              <div style={{ width: '10%' }}>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => this.setYear('Subtract')}
                >
                  <ArrowRightIcon size="small" />
                </IconButton>
              </div>
            </div>
            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
              {this.state.SelectedYear}
            </div>
          </div>
            )
          : null}
        {this.state.RadioSelect === 'YEAR'
          ? (
          <div style={{ width: '100%' }}>
            <div style={{ padding: '4px', width: '90%' }}>
              <Slider
                // track={false}
                defaultValue={this.state.yearDefault}
                getAriaValueText={this.valueText}
                aria-labelledby="discrete-slider-custom"
                step={1}
                min={2018}
                max={2021}
                valueLabelDisplay="auto"
                marks={YEARLY}
                onChangeCommitted={this.yearDateSelection}
              />
            </div>
          </div>
            )
          : null}

        {this.state.RadioSelect === 'CDR'
          ? (
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            {' '}
            <Tooltip title="Date Range" aria-label="Date Range">
              <DatePicker
                onDateChange={this.onDateRange}
                startDate={localStorage.getItem('selectedstartDate')}
                endDate={localStorage.getItem('selectedendDate')}
              />
            </Tooltip>
          </div>
            )
          : null}
      </Grid>
    );
  }
}
export default SliderDatePicker;

import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { apiBatchRequest, apiPost } from "../../../utils/api_service";
import camelcaseKeys from "camelcase-keys";
import moment from "moment";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { ShowToast, toastType } from "../../../utils/toast-service";
import DeleteIcon from "@material-ui/icons/Delete";
import { UpdateColumnWithConfiguration } from "../../../utils/helper";
import { API_URL } from "../../../constants/api";
import { GridTable } from "../../common/Table/Table";
import ConfirmDialog from "../../common/Dailogbox/confirmDailog";
import SearchBar from "../../common/AutoSearch/SearchBar";
import PageHeader from "../../common/PageHeader";

export const unmappedCompsColumns = [
  {
    name: "CoStar ID",
    key: "coStarId",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
    
  {
    name: "Property Name",
    key: "prStreetAddress",
    type: "Child",
    isRequired: true,
    isVisible: true,    
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "SF",
    key: "rsf",
    type: "Child",
    isRequired: true,
    isVisible: true,
    headcellClass: "headcell-number-text",
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
 
  {
    name: "City",
    key: "prCity",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "State",
    key: "prState",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },
  {
    name: "Import File",
    key: "importFileName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: false,
      isAddField: false,
    },
  },  
  {
    name: "Action",
    key: "compId",
    renderComponent: "renderActionColumn",
  },
];

export default class UnmappedComps extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      getUnmappedCompMaster: [],
      getUnmappedCompMasterResponse: [],
      isInAddMode: false,
      reloadTable: false,
      selectedEditRowId: undefined,
      configuration: [],
      isInDeleteMode: false,
      updateMasterData: {
        compId: -1,
        coStarId: -1,
        prName: null,
        address: "",
        city: "",
        compIsActive:0
      },
      showWarningMessage: false,
      confirmMessage: "",
      searchInput: undefined,
    };
  }

  componentDidMount() {
    this.getRequiredData();
  }

  getRequiredData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialLeaseComps.getUnmappedLeaseComps,
        key: "getUnmappedCompMaster",
		
      },
    ])
      .then((getUnmappedCompMaster) => {
        this._asyncRequest = null;
        if (getUnmappedCompMaster.getUnmappedCompMaster.success === true) {
          this.setState({
            getUnmappedCompMaster:
              camelcaseKeys(getUnmappedCompMaster.getUnmappedCompMaster.data) || [],
            getUnmappedCompMasterResponse:
              camelcaseKeys(getUnmappedCompMaster.getUnmappedCompMaster.data) || [],
            configuration:
              camelcaseKeys(getUnmappedCompMaster.getUnmappedCompMaster.configuration) ||
              [],
            reloadTable: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  cancelAddMode = () => {
    this.setState({
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
  };

  cancelEditMode = () => {
    this.setState({
        updateData: undefined,
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
    //this.getRequiredData();
  };

  updateRecord = () => {
    const newParam = this.state.updateMasterData;
    //this.state.isInDeleteMode ? newParam.compIsActive = 0 : newParam.compIsActive = newParam.compIsActive;
    apiPost({
      url: API_URL.IndustrialLeaseComps.updateUnmappedLeaseComp,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      if (response.success === true) {
        ShowToast("Record updated successfully.", toastType.SUCCESS, 5000, 500);

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData();
        this.forceUpdate();
      } else {
        ShowToast(response.message.replace("Error in SP-api.UpdateCostarForUnmappedComp",""), toastType.ERROR, 5000, 5000);
      }
    });
  };

  deleteRecord = (cellInfo) => {    
    this.setState({
      showWarningMessage: true,
      confirmMessage: "Do you want to delete this record ?",
      updateMasterData: cellInfo,
    });
  };

  onClickHandle(e) {
    if (e) {
      this.setState(
        {
          isInDeleteMode: true,
          reloadTable: !this.state.reloadTable,
        },
        () => {
          this.forceUpdate();
        }
      );

      const newParam = this.state.updateMasterData;
      newParam.compIsActive = 0;
            
      apiPost({
        url: API_URL.IndustrialLeaseComps.updateUnmappedLeaseComp,
        params: { businessLineId: 1 },
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            "Record deleted successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.setState({
            updateData: undefined,
            selectedEditRowId: undefined,
            reloadTable: !this.state.reloadTable,
            isInAddMode: false,
            showWarningMessage: false,
            confirmMessage: "",
          });
          this.getRequiredData();
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    } else {
      this.setState({
        showWarningMessage: false,
        confirmMessage: "",
      });
    }
  }

  saveUpdatedRecord = () => {
    const newParam = this.state.updateMasterData;
    apiPost({
      url: API_URL.Master.AddMarketMasterDetail,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      
      if (response.success === true) {
        ShowToast(
          this.state.isInAddMode
            ? "Record added successfully."
            : "Record updated successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData();
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  renderActionColumn = (cellInfo, key, isInAddMode) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined &&
        cellInfo.compId === this.state.selectedEditRowId.value ? (
          <>
            <Tooltip title="Save Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {}}
              >
                <SaveIcon
                  fontSize="small"
                  size="small"
                  onClick={
                    this.state.isInAddMode
                      ? this.saveUpdatedRecord
                      : this.updateRecord
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={this.cancelEditMode}
              >
                <CancelIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.setState({
                    selectedEditRowId: {
                      key: "compId",
                      value: cellInfo.compId,
                    },
                    isInAddMode: false,
                    updateMasterData: cellInfo,
                  });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.deleteRecord(cellInfo);
                }}
              >
                <DeleteIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    );
  };

  handleTableEditChange = (value, fieldName, row, addMode) => {
    const param = this.state.updateMasterData;
    if (fieldName === "market") {
      param[fieldName] = value || [];
    }
    if (fieldName === "coStarId") {
        param[fieldName] = parseInt(value);
      }  
    this.setState({ updateMasterData: param });
    this.forceUpdate();
  };

  AddNewRecord = () => {
    if (this.state.isInAddMode === true) {
      ShowToast("You can add one row at a time!", toastType.WARNING, 5000, 500);
      return false;
    }

    const rowNew = {
      compId: -1,
      coStarId: -1,
      prName: null,
      address: "",
      city: "",
    };
    this.setState({
      isInAddMode: true,
      selectedEditRowId: { key: "compId", value: -1 },
      updateMasterData: rowNew,
    });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, getUnmappedCompMaster } = this.state;
    let searchData = this.state.getUnmappedCompMasterResponse;
    let filteredData = searchData.filter((value) => {
      return value.prName
        ? value.prName
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : false;
      // }
    });
    this.setState({ getUnmappedCompMaster: filteredData });
  };

  render() {
    const { getUnmappedCompMaster } = this.state;

    return (
      <>
        {this.state.showWarningMessage && (
          <ConfirmDialog
            IsOpen={this.state.showWarningMessage}
            Message={this.state.confirmMessage}
            OnHandelClick={(isValid) => {
              this.onClickHandle(isValid);
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
        <div className="row">
          <div className="col-md-2 col-lg-2">            
          </div>
          <div className="col-sm-8 col-md-8 col-lg-8">
            <SearchBar
              size="large"
              name="searchInput"
              value={this.state.searchInput || ""}
              onChange={this.handleChange}
              label="Search"
              placeholder="Search Property Name."
            ></SearchBar>
          </div>
          <div className="col-md-2 col-lg-2">            
          </div>
        </div>

        {getUnmappedCompMaster && (
          <GridTable
            columns={UpdateColumnWithConfiguration(
              unmappedCompsColumns,
              this.state.configuration,
              []
            )}
            rowCount={50}
            data={getUnmappedCompMaster}
            renderActionColumn={this.renderActionColumn}
            selectedEditRowId={this.state.selectedEditRowId}
            isInAddMode={this.state.isInAddMode}
            EditSource={[]}
            handleTableEditChange={this.handleTableEditChange}
            updateData={this.state.updateMasterData}
            // AddNewRecord={{
            //   action: this.AddNewRecord,
            //   title: "Add New Market",
            //   visible: true,
            // }}
            reload={this.state.reloadTable}
          ></GridTable>
        )}
        
      </>
    );
  }
}

import React from "react";
import moment from "moment";
import {
  TextField,
  Grid,
  Chip,
  IconButton,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactChipInput from "../components/common/ChipInput/ReactChipInput";
import _ from "lodash";
import { removeDuplicates, isGridFormValid } from "./helper";
import { Switch } from "../components/common/Switch/index";
import InputMask from "react-input-mask";
import { Add, Remove } from "@material-ui/icons";

function getFormattedData(
  row,
  column,
  isInAddMode,
  EditSource,
  selectedEditRowId,
  handleTableValueChange,
  showLabel = false,
  props,
  index = 0,
  viewTagData,
  showCollapsible,
  handelCollapsible,
  selected
) {
  let value = (row || {})[column.key];
  if (
    (column.editConfig !== undefined && column.editConfig.multiple) ||
    _.camelCase(column.dataFormat) === _.camelCase("MultiText")
  ) {
    value = value ? (value || "").split("|") : [];
  }

  let label = <></>;
  if (showLabel === true) {
    label = <div> {column.name} </div>;
  }
  const notEditTypeValue = (column.notEditWhen || {}).value;
  const notEditTypeKey = (column.notEditWhen || {}).key;
  // console.log(row, column, isInAddMode, EditSource, selectedEditRowId, handleTableValueChange);
  if (
    (selectedEditRowId !== undefined &&
      // column.isEditable === true &&
      column.editConfig !== undefined &&
      row[selectedEditRowId.key] === selectedEditRowId.value &&
      column.editConfig.isEditField === true &&
      isInAddMode === false &&
      (column.notEditWhen === undefined ||
        notEditTypeValue !== row[notEditTypeKey])) ||
    (selectedEditRowId !== undefined &&
      // column.isEditable === true &&
      column.editConfig !== undefined &&
      row[selectedEditRowId.key] === selectedEditRowId.value &&
      column.editConfig.isAddField === true &&
      isInAddMode === true &&
      (column.notEditWhen === undefined ||
        notEditTypeValue !== row[notEditTypeKey]))
  ) {
    const dataSource = _.cloneDeep(
      EditSource.find((x) => x.key === column.editConfig?.sourceKey)
    );
    if (column.filterSourceOn) {
      let dropdownSource = [];
      column.filterSourceOn.forEach((ele) => {
        const idValue = row[ele.key];
        if (idValue) {
          dropdownSource = dataSource.data.filter(
            (x) =>
              (x[ele.filterBy] || "").toString() === (idValue || "").toString()
          );
          dataSource.data = dropdownSource.filter(
            (ele, ind) =>
              ind ===
              dropdownSource.findIndex(
                (elem) => elem[column.fieldId] === ele[column.fieldId]
              )
          );
        }
      });
    }
    const isInErrorState = column.isInErrorState;
    const errorMessage = column.errorMessage;
    const isEditable = column.disabled;
    //  console.log(value);
    switch ((column.editConfig.type || "").trim()) {
      case "DropDown":
        return (
          <>
            {label}
            <Autocomplete
              size="small"
              disabled={isEditable}
              error={isInErrorState}
              helperText={errorMessage}
              multiple={column.editConfig.multiple || false}
              limitTags={2}
              options={(dataSource.data || []).map(
                (option) => option[column.editConfig.sourceOption]
              )}
              value={column.editConfig.multiple === true ? value || [] : value}
              className="editable"
              key={"dropDown_filter" + column.editConfig.title}
              id={"dropDown_filter" + column.editConfig.title}
              onChange={(event, value) => {
                handleTableValueChange(
                  getFormattedValues(value, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                );
              }}
              autoHighlight
              renderInput={(params) => (
                <TextField
                  error={isInErrorState}
                  helperText={errorMessage}
                  {...params}
                  margin="normal"
                  className="table-text"
                />
              )}
            />
          </>
        );
      case "TextField":
        return (
          <>
            {label}
            <TextField
              disabled={isEditable}
              error={isInErrorState}
              helperText={errorMessage}
              margin="normal"
              size="small"
              color="primary"
              className="editable"
              value={getFormattedValues(value, column.dataFormat, true)}
              onChange={(e) =>
                handleTableValueChange(
                  getFormattedValues(e.target.value, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                )
              }
            />
          </>
        );
        case "TextArea":
        return (
          <>
            {label}
            <TextField
              disabled={isEditable}
              error={isInErrorState}
              helperText={errorMessage}
              multiline
              maxRows={3}
              margin="normal"
              size="small"
              color="primary"
              className="editable"
              value={getFormattedValues(value, column.dataFormat, true)}
              onChange={(e) =>
                handleTableValueChange(
                  getFormattedValues(e.target.value, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                )
              }
            />
          </>
        );
      case "PhoneNumber":
        return (
          <>
            <InputMask
              mask="999-999-9999"
              value={getFormattedValues(value, column.dataFormat, true)}
              disabled={isEditable}
              maskChar=" "
              onChange={(e) =>
                handleTableValueChange(
                  getFormattedValues(e.target.value, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                )
              }
            >
              {() => (
                <TextField
                  error={isInErrorState}
                  helperText={errorMessage}
                  margin="normal"
                  size="small"
                  color="primary"
                  className="editable"
                />
              )}
            </InputMask>
          </>
        );

      case "DatePicker":
        return (
          <>
            {label}
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              key={"datePicker_filter" + value}
            >
              <Grid container>
                <KeyboardDatePicker
                  disabled={isEditable}
                  error={isInErrorState}
                  clearable={true.toString()}
                  helperText={errorMessage}
                  minDate={column.minDate}
                  maxDate={column.maxDate}
                  className="editable"
                  size="small"
                  margin="normal"
                  id={"datePickerFilter" + value}
                  value={value}
                  onChange={(date) => {
                    handleTableValueChange(
                      getFormattedValues(date, column.dataFormat, true),
                      column.key,
                      row,
                      isInAddMode,
                      index
                    );
                  }}
                  format="MM/dd/yyyy"
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </>
        );
      case "ChipInput":
        return (
          <>
            <ReactChipInput
              required={column.isRequired}
              value={value}
              onChange={(value) => {
                handleTableValueChange(
                  getFormattedValues(value, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                );
              }}
            />
          </>
        );
      case "Switch":
        return (
          <>
            <Switch
              disabled={isEditable}
              isChecked={value}
              onChangeValues={(e, key) =>
                handleTableValueChange(
                  getFormattedValues(e, column.dataFormat, true),
                  column.key,
                  row,
                  isInAddMode,
                  index
                )
              }
              id={`label-${column.key}`}
              key={column.key}
              fieldkey={column.key}
              obj={column}
              labelText={column.name}
              fullWidth
              row={row}
              column={column}
              isInAddMode={isInAddMode}
            />
          </>
        );
        case "CheckBox":
         return (
            <>     
            {label}         
                    <Checkbox
                     disabled={isEditable}
                     checked={value}
                     onChange={(e, key) =>
                       handleTableValueChange(
                         getFormattedValues(e.target.checked, column.dataFormat, true),
                         column.key,
                         row,
                         isInAddMode,
                         index
                       )
                     }
                     id={`cb-${column.key}`}
                     key={column.key}
                     fieldkey={column.key}
                     obj={column}
                     
                     label={column.name}
                     fullWidth
                     row={row}
                     column={column}
                     isInAddMode={isInAddMode}
                    />
               
            </>
          );
      default:
        return false;
    }
  } else {
    if (column.concatFields) {
      column.concatFields.forEach((field) => {
        const concatValue = row[field] || "";
        if (concatValue.length > 0) {
          value = value + " " + concatValue;
        }
      });
    }
    const component = getFormattedValues(
      value,
      column.dataFormat,
      false,
      column,
      props,
      viewTagData
    );
    const collapsibleComponent =
      showCollapsible &&
      props.config &&
      selected.key === column.key &&
      showCollapsible ? (
        getCollapsibleIcon(
          row,
          column,
          handelCollapsible,
          index,
          selected.isOpen
        )
      ) : (
        <></>
      );
    if (column.applyColorBadge) {
      return (
        <>
          {collapsibleComponent}
          <>
            {label}
            {applyBadge(component, value)}
          </>
        </>
      );
    } else {
      return (
        <>
          {collapsibleComponent} {label} {component}
        </>
      );
    }
  }
}

export {
  getFormattedData,
  getFormattedValues,
  getFormattedValuesForReports,
  getFormattedValuesForFileImport,
  getFormattedValuesForHistory,
};

function getFormattedValues(
  value,
  format,
  noFormatting = false,
  column = undefined,
  prop = undefined,
  viewTagData = undefined,
  appendText = ""
) {
  let component = <></>;
  switch ((format || "").trim()) {
    case "DateTime":
      if (noFormatting === false) {
        if (
          value !== null &&
          value !== "" &&
          value !== undefined &&
          value !== "0001-01-01T00:00:00"
        ) {
          component = (
            <span className="date-text">
              {moment(value).format("MM/DD/YYYY HH:mm:ss") + " " + appendText}{" "}
            </span>
          );
        } else {
          component = <span className="date-text">-</span>;
        }
      } else {
        if (value !== undefined && value !== "0001-01-01T00:00:00") {
          return moment(value).format("YYYY-MM-DD HH:mm:ss");
        } else {
          return null;
        }
      }
      break;
    case "Date":
      if (noFormatting === false) {
        if (
          value !== null &&
          value !== "" &&
          value !== undefined &&
          value !== "0001-01-01T00:00:00"
        ) {
          component = (
            <span className="date-text">
              {moment(value).format("MM/DD/YYYY") + " " + appendText}{" "}
            </span>
          );
        } else {
          component = <span className="date-text">-</span>;
        }
      } else {
        if (value !== undefined && value !== "0001-01-01T00:00:00") {
          return moment(value).format("YYYY-MM-DD");
        } else {
          return null;
        }
      }
      break;

      case "ShortDate":
        if (noFormatting === false) {
          if (
            value !== null &&
            value !== "" &&
            value !== undefined &&
            value !== "0001-01-01T00:00:00"
          ) {
            if(
            moment(value).month() === 2 ||
            moment(value).month() === 5 || 
            moment(value).month() === 8 || 
            moment(value).month() === 11 ){
            component = (
              <span className="date-text">
                {moment(value).format("MM/YYYY") + " (Q" + moment(value).quarter() + ")"}{" "}
              </span>
            );
            }
            else
            {
              component = (
                <span className="date-text">
                  {moment(value).format("MM/YYYY")}{" "}
                </span>
              );
            }
          } else {
            component = <span className="date-text">-</span>;
          }
        } else {
          if (value !== undefined && value !== "0001-01-01T00:00:00") {
            return moment(value).format("MM/YYYY");
          } else {
            return null;
          }
        }
        break;

    case "Number":
      if (noFormatting === false) {
        if (prop.isProperty) {
          if (value !== null && isNaN(value) !== true) {
            component = (
              <span
                className={
                  prop.selectedEditRowId !== undefined
                    ? "edit-number-text"
                    : "number-text"
                }
              >
                {parseInt(value).toLocaleString() + " " + appendText}
              </span>
            );
          } else {
            component = (
              <span
                className={
                  prop.selectedEditRowId !== undefined
                    ? "edit-number-text"
                    : "number-text"
                }
              >
                -
              </span>
            );
          }
        } else {
          if (value !== null && isNaN(value) !== true) {
            component = (
              <span className="number-text">
                {parseInt(value).toLocaleString() + " " + appendText}
              </span>
            );
          } else {
            component = <span className="number-text">-</span>;
          }
        }
      } else {
        if (value === "" || value === []) return "";
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toString();
        } else {
          return 0;
        }
      }
      break;

    case "Decimal":
      if (noFormatting === false) {
        if (prop.isProperty) {
          if (value !== null && isNaN(value) !== true) {
            component = (
              <span
                className={
                  prop.selectedEditRowId !== undefined
                    ? "edit-number-text"
                    : "number-text"
                }
              >
                {parseFloat(value).toFixed(2).toLocaleString() +
                  " " +
                  appendText}
              </span>
            );
          } else {
            component = (
              <span
                className={
                  prop.selectedEditRowId !== undefined
                    ? "edit-number-text"
                    : "number-text"
                }
              >
                -
              </span>
            );
          }
        } else {
          if (value !== null && isNaN(value) !== true) {
            component = (
              <span className="number-text">
                {parseFloat(value).toFixed(2).toLocaleString() +
                  " " +
                  appendText}
              </span>
            );
          } else {
            component = <span className="number-text">-</span>;
          }
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Latitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;
    case "Longitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;

    case "ZipCode":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseInt(value).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        //       	if(value != undefined && value.includes('-'))
        //  value= value.replace('-','');
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toLocaleString();
        } else {
          return 0;
        }
      }
      break;

    case "MoneyNumber":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="number-text">-</span>;
          } else if (value >= 0) {
            component = (
              <span className="number-text">
                ${parseInt(value).toLocaleString() + " " + appendText}
              </span>
            );
          }
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "MoneyDecimal":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="number-text">-</span>;
          } else if (value >= 0) {
            component = (
              <span className="number-text">
                $
                {parseFloat(parseFloat(value).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
              </span>
            );
          } else {
            var newValue = value.toString();
            var oldValue = newValue.substr(1);
            component = (
              <span className="number-text">
                $(
                {parseFloat(parseFloat(oldValue).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
                )
              </span>
            );
          }
        } else {
          component = <span className="number-text">-</span>;
        }
      } else if (value === "" || isNaN(value) === true) {
        return 0;
      } else {
        if (isNaN(value) !== true) {
          if (value.toString().substr(value.length - 1) === ".") {
            return value;
          }
          return parseFloat(parseFloat(value).toFixed(2).toLocaleString());
        } else {
          return 0;
        }
      }
      break;

    case "Percent":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          component = (
            <span className="percent-text">
              {value}% {" " + appendText}
            </span>
          );
        } else {
          component = <span className="percent-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "DecimalPercent":
      if (noFormatting === false) {
        if (prop.isProperty) {
          if (isNaN(value) !== true) {
            if (value == null) {
              component = (
                <span
                  className={
                    prop.selectedEditRowId !== undefined
                      ? "edit-number-text"
                      : "number-text"
                  }
                >
                  {0}%
                </span>
              );
            } else if (value >= 0) {
              component = (
                <span
                  className={
                    prop.selectedEditRowId !== undefined
                      ? "edit-number-text"
                      : "number-text"
                  }
                >
                  {parseFloat(parseFloat(value).toFixed(2)).toLocaleString()}%
                </span>
              );
            } else {
              var newValue = value.toString();
              var oldValue = newValue.substr(1);
              component = (
                <span
                  className={
                    prop.selectedEditRowId !== undefined
                      ? "edit-number-text"
                      : "number-text"
                  }
                >
                  ({parseFloat(parseFloat(oldValue)).toLocaleString()})%{" "}
                  {" " + appendText}
                </span>
              );
            }
          } else {
            component = (
              <span
                className={
                  prop.selectedEditRowId !== undefined
                    ? "edit-number-text"
                    : "number-text"
                }
              >
                -
              </span>
            );
          }
        } else {
          if (isNaN(value) !== true) {
            if (value == null) {
              component = <span className="percent-text">{0}%</span>;
            } else if (value >= 0) {
              component = (
                <span className="percent-text">
                  {parseFloat(parseFloat(value).toFixed(2)).toLocaleString()}%
                </span>
              );
            } else {
              var newValue = value.toString();
              var oldValue = newValue.substr(1);
              component = (
                <span className="percent-text">
                  ({parseFloat(parseFloat(oldValue)).toLocaleString()})%{" "}
                  {" " + appendText}
                </span>
              );
            }
          } else {
            component = <span className="percent-text">-</span>;
          }
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Boolean":
    case "Radio":
      if (noFormatting === false) {
        if (value !== undefined) {
          component = (
            <span className="boolean-text">{value ? "Yes" : "No"}</span>
          );
        } else {
          component = <span className="boolean-text">-</span>;
        }
      } else {
        if (value !== undefined) {
          return value;
        } else {
          return false;
        }
      }
      break;
    case "MultiText": {
      const tags = removeDuplicates(value || []);
      const tagsCount = tags.length - 1;
      const displayLabel = "+" + tagsCount;
      if (noFormatting === false) {
        component = (
          <>
            {tags.map(
              (chip, i) =>
                (column === undefined ||
                  column.minTag === undefined ||
                  i < column.minTag) &&
                chip &&
                chip !== "" && (
                  <Chip
                    key={chip + i}
                    className="info chip"
                    size="small"
                    label={chip}
                  ></Chip>
                )
            )}
            {column && column.minTag && tags.length > column.minTag && (
              <span
                style={{ cursor: "pointer" }}
                className={column.viewTagClasses}
                onClick={() =>
                  viewTagData !== undefined &&
                  viewTagData(
                    column.name,
                    tags.map(
                      (chip, i) =>
                        chip &&
                        chip !== "" && (
                          <Chip
                            key={chip + i}
                            className="info chip"
                            size="small"
                            label={chip}
                          ></Chip>
                        )
                    )
                  )
                }
              >
                {column.tagCount == true
                  ? displayLabel
                  : column.minTagLabel || "... View all"}{" "}
              </span>
            )}
          </>
        );
      } else {
        return value;
      }
      break;
    }
    case "CityState": {
      if (noFormatting === false) {
        component = (
          <span className="default-text">
            {(column || {}).prefixChild}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
      break;
    }
    default:
      if (noFormatting === false) {
        // if( column.key == 'serial')
        // {
        //   let len=value.toString().length;
        //   component = (
        //     <span className="default-text-span" style={{width:len+'px'}}>
        //       {(column || {}).prefixChild}
        //       {value}
        //     </span>
        //   );
        // }
        // else
        // {
        component = (
          <span className="default-text">
            {/* {(column || {}).prefixChild} */}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
  }
  return component;
}

function getFormattedValuesForHistory(
  value,
  format,
  noFormatting = false,
  column = undefined,
  prop = undefined,
  viewTagData = undefined,
  appendText = ""
) {
  let component = <></>;
  switch ((format || "").trim()) {
    case "DateTime":
    case "Date":
      if (noFormatting === false) {
        if (
          value !== null &&
          value !== "" &&
          value !== undefined &&
          value !== "0001-01-01T00:00:00"
        ) {
          component = (
            <span>
              {moment(value).format("MM/DD/YYYY") + " " + appendText}{" "}
            </span>
          );
        } else {
          component = <span>-</span>;
        }
      } else {
        if (value !== undefined && value !== "0001-01-01T00:00:00") {
          return moment(value).format("YYYY-MM-DD");
        } else {
          return null;
        }
      }
      break;

    default:
      if (noFormatting === false) {
        // if( column.key == 'serial')
        // {
        //   let len=value.toString().length;
        //   component = (
        //     <span className="default-text-span" style={{width:len+'px'}}>
        //       {(column || {}).prefixChild}
        //       {value}
        //     </span>
        //   );
        // }
        // else
        // {
        component = (
          <span className="default-text">
            {/* {(column || {}).prefixChild} */}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
  }
  return component;
}

function getFormattedValuesForFileImport(
  value,
  format,
  noFormatting = false,
  column = undefined,
  prop = undefined,
  viewTagData = undefined,
  appendText = ""
) {
  let component = <></>;
  switch ((format || "").trim()) {
    case "DateTime":
    case "Date":
      if (noFormatting === false) {
        if (
          value !== null &&
          value !== "" &&
          value !== undefined &&
          value !== "0001-01-01T00:00:00"
        ) {
          component = (
            <span className="date-text">
              {moment(value).format("MM/DD/YYYY") + " " + appendText}{" "}
            </span>
          );
        } else {
          component = <span className="date-text">-</span>;
        }
      } else {
        if (value !== undefined && value !== "0001-01-01T00:00:00") {
          return moment(value).format("YYYY-MM-DD");
        } else {
          return null;
        }
      }
      break;

    case "Number":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseInt(value).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toString();
        } else {
          return 0;
        }
      }
      break;

    case "Decimal":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseFloat(value).toFixed(2).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Latitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;
    case "Longitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;

    case "ZipCode":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="number-text">
              {parseInt(value).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value != undefined && value.includes("-"))
          value = value.replace("-", "");
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toString();
        } else {
          return 0;
        }
      }
      break;

    case "MoneyNumber":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="number-text">${0}</span>;
          } else if (value >= 0) {
            component = (
              <span className="number-text">
                ${parseInt(value).toLocaleString() + " " + appendText}
              </span>
            );
          }
        } else {
          component = <span className="number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "MoneyDecimal":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value >= 0) {
            component = (
              <span className="number-text">
                $
                {parseFloat(parseFloat(value).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
              </span>
            );
          } else {
            var newValue = value.toString();
            var oldValue = newValue.substr(1);
            component = (
              <span className="number-text">
                $(
                {parseFloat(parseFloat(oldValue).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
                )
              </span>
            );
          }
        } else {
          component = <span className="number-text">-</span>;
        }
      } else if (value === "" || isNaN(value) === true) {
        return 0;
      } else {
        if (isNaN(value) !== true) {
          if (value.toString().substr(value.length - 1) === ".") {
            return value;
          }
          return parseFloat(parseFloat(value).toFixed(2).toLocaleString());
        } else {
          return 0;
        }
      }
      break;

    case "Percent":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          component = (
            <span className="percent-text">
              {value}% {" " + appendText}
            </span>
          );
        } else {
          component = <span className="percent-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "DecimalPercent":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="percent-text">{0}%</span>;
          } else if (value >= 0) {
            component = (
              <span className="percent-text">
                {parseFloat(parseFloat(value).toFixed(2)).toLocaleString()}%
              </span>
            );
          } else {
            var newValue = value.toString();
            var oldValue = newValue.substr(1);
            component = (
              <span className="percent-text">
                ({parseFloat(parseFloat(oldValue)).toLocaleString()})%{" "}
                {" " + appendText}
              </span>
            );
          }
        } else {
          component = <span className="percent-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Boolean":
    case "Radio":
      if (noFormatting === false) {
        if (value !== undefined) {
          component = (
            <span className="boolean-text">{value ? "Yes" : "No"}</span>
          );
        } else {
          component = <span className="boolean-text">-</span>;
        }
      } else {
        if (value !== undefined) {
          return value;
        } else {
          return false;
        }
      }
      break;
    case "MultiText": {
      const tags = removeDuplicates(value || []);
      const tagsCount = tags.length - 1;
      const displayLabel = "+" + tagsCount;
      if (noFormatting === false) {
        component = (
          <>
            {tags.map(
              (chip, i) =>
                (column === undefined ||
                  column.minTag === undefined ||
                  i < column.minTag) &&
                chip &&
                chip !== "" && (
                  <Chip
                    key={chip + i}
                    className="info chip"
                    size="small"
                    label={chip}
                  ></Chip>
                )
            )}
            {column && column.minTag && tags.length > column.minTag && (
              <span
                style={{ cursor: "pointer" }}
                className={column.viewTagClasses}
                onClick={() =>
                  viewTagData !== undefined &&
                  viewTagData(
                    column.name,
                    tags.map(
                      (chip, i) =>
                        chip &&
                        chip !== "" && (
                          <Chip
                            key={chip + i}
                            className="info chip"
                            size="small"
                            label={chip}
                          ></Chip>
                        )
                    )
                  )
                }
              >
                {column.tagCount == true
                  ? displayLabel
                  : column.minTagLabel || "... View all"}{" "}
              </span>
            )}
          </>
        );
      } else {
        return value;
      }
      break;
    }
    case "CityState": {
      if (noFormatting === false) {
        component = (
          <span className="default-text">
            {(column || {}).prefixChild}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
      break;
    }
    default:
      if (noFormatting === false) {
        // if( column.key == 'serial')
        // {
        //   let len=value.toString().length;
        //   component = (
        //     <span className="default-text-span" style={{width:len+'px'}}>
        //       {(column || {}).prefixChild}
        //       {value}
        //     </span>
        //   );
        // }
        // else
        // {
        component = (
          <span className="default-text">
            {/* {(column || {}).prefixChild} */}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
  }
  return component;
}
function getFormattedValuesForReports(
  value,
  format,
  noFormatting = false,
  column = undefined,
  prop = undefined,
  viewTagData = undefined,
  appendText = ""
) {
  let component = <></>;
  switch ((format || "").trim()) {
    case "DateTime":
    case "Date":
      if (noFormatting === false) {
        if (
          value !== null &&
          value !== "" &&
          value !== undefined &&
          value !== "0001-01-01T00:00:00"
        ) {
          component = (
            <span className="date-text">
              {moment(value).format("MM/DD/YYYY") + " " + appendText}{" "}
            </span>
          );
        } else {
          component = <span className="date-text">-</span>;
        }
      } else {
        if (value !== undefined && value !== "0001-01-01T00:00:00") {
          return moment(value).format("YYYY-MM-DD");
        } else {
          return null;
        }
      }
      break;

    case "Number":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseInt(value).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toLocaleString();
        } else {
          return 0;
        }
      }
      break;

    case "Year":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseInt(value) + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "Decimal":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseFloat(value).toFixed(2).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Latitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;
    case "Longitude":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseFloat(value).toFixed(7).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(7));
        } else {
          return 0;
        }
      }
      break;

    case "ZipCode":
      if (noFormatting === false) {
        if (value !== null && isNaN(value) !== true) {
          component = (
            <span className="report-number-text">
              {parseInt(value).toLocaleString() + " " + appendText}
            </span>
          );
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value).toLocaleString();
        } else {
          return 0;
        }
      }
      break;

    case "MoneyNumber":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="report-number-text">${0}</span>;
          } else if (value >= 0) {
            component = (
              <span className="report-number-text">
                ${parseInt(value).toLocaleString() + " " + appendText}
              </span>
            );
          }
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "MoneyDecimal":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value >= 0) {
            component = (
              <span className="report-number-text">
                $
                {parseFloat(parseFloat(value).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
              </span>
            );
          } else {
            var newValue = value.toString();
            var oldValue = newValue.substr(1);
            component = (
              <span className="report-number-text">
                $(
                {parseFloat(parseFloat(oldValue).toFixed(2)).toLocaleString() +
                  " " +
                  appendText}
                )
              </span>
            );
          }
        } else {
          component = <span className="report-number-text">-</span>;
        }
      } else if (value === "" || isNaN(value) === true) {
        return 0;
      } else {
        if (isNaN(value) !== true) {
          if (value.toString().substr(value.length - 1) === ".") {
            return value;
          }
          return parseFloat(parseFloat(value).toFixed(2).toLocaleString());
        } else {
          return 0;
        }
      }
      break;

    case "Percent":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          component = (
            <span className="report-percent-text">
              {value}% {" " + appendText}
            </span>
          );
        } else {
          component = <span className="report-percent-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseInt(value);
        } else {
          return 0;
        }
      }
      break;

    case "DecimalPercent":
      if (noFormatting === false) {
        if (isNaN(value) !== true) {
          if (value == null) {
            component = <span className="report-percent-text">{0}%</span>;
          } else if (value >= 0) {
            component = (
              <span className="report-percent-text">
                {parseFloat(parseFloat(value).toFixed(2)).toLocaleString()}%
              </span>
            );
          } else {
            var newValue = value.toString();
            var oldValue = newValue.substr(1);
            component = (
              <span className="report-percent-text">
                ({parseFloat(parseFloat(oldValue)).toLocaleString()})%{" "}
                {" " + appendText}
              </span>
            );
          }
        } else {
          component = <span className="report-percent-text">-</span>;
        }
      } else {
        if (value !== null && isNaN(value) !== true) {
          return parseFloat(parseFloat(value).toFixed(2));
        } else {
          return 0;
        }
      }
      break;
    case "Boolean":
    case "Radio":
      if (noFormatting === false) {
        if (value !== undefined) {
          component = (
            <span className="boolean-text">{value ? "Yes" : "No"}</span>
          );
        } else {
          component = <span className="boolean-text">-</span>;
        }
      } else {
        if (value !== undefined) {
          return value;
        } else {
          return false;
        }
      }
      break;
    case "MultiText": {
      const tags = removeDuplicates(value || []);
      const tagsCount = tags.length - 1;
      const displayLabel = "+" + tagsCount;
      if (noFormatting === false) {
        component = (
          <>
            {tags.map(
              (chip, i) =>
                (column === undefined ||
                  column.minTag === undefined ||
                  i < column.minTag) &&
                chip &&
                chip !== "" && (
                  <Chip
                    key={chip + i}
                    className="info chip"
                    size="small"
                    label={chip}
                  ></Chip>
                )
            )}
            {column && column.minTag && tags.length > column.minTag && (
              <span
                style={{ cursor: "pointer" }}
                className={column.viewTagClasses}
                onClick={() =>
                  viewTagData !== undefined &&
                  viewTagData(
                    column.name,
                    tags.map(
                      (chip, i) =>
                        chip &&
                        chip !== "" && (
                          <Chip
                            key={chip + i}
                            className="info chip"
                            size="small"
                            label={chip}
                          ></Chip>
                        )
                    )
                  )
                }
              >
                {column.tagCount == true
                  ? displayLabel
                  : column.minTagLabel || "... View all"}{" "}
              </span>
            )}
          </>
        );
      } else {
        return value;
      }
      break;
    }
    case "CityState": {
      if (noFormatting === false) {
        component = (
          <span className="default-text">
            {(column || {}).prefixChild}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
      break;
    }
    default:
      if (noFormatting === false) {
        // if( column.key == 'serial')
        // {
        //   let len=value.toString().length;
        //   component = (
        //     <span className="default-text-span" style={{width:len+'px'}}>
        //       {(column || {}).prefixChild}
        //       {value}
        //     </span>
        //   );
        // }
        // else
        // {
        component = (
          <span className="default-text">
            {/* {(column || {}).prefixChild} */}
            {value ? value : "---"}
          </span>
        );
        //}
      } else {
        return value;
      }
  }
  return component;
}

function getCollapsibleIcon(row, col, handelClick, key, isOpen) {
  return (
    <IconButton
      key={col.key + key + row[col.key]}
      aria-label="expand row"
      size="small"
      style={{ padding: "0px" }}
      onClick={(e) => {
        e.stopPropagation();
        handelClick(col.key, row[col.key], !isOpen);
      }}
    >
      {isOpen ? <Remove /> : <Add />}
    </IconButton>
  );
}

function getFieldValueByFormat(obj) {
  return getFormattedValues(obj.attributeValue, obj.dataFormat, true);
}

export { getFieldValueByFormat };
function applyBadge(component, value) {
  if (value === undefined || isNaN(value)) {
    return component;
  }
  const val = parseInt(value);
  let colorClass = "standardError";
  if (val > 0 && val <= 30) {
    colorClass = "message";
  }
  if (val > 31 && val <= 70) {
    colorClass = "info";
  }
  if (val > 71 && val <= 100) {
    colorClass = "success";
  }
  if (val > 100) {
    colorClass = "warning";
  }
  return <Chip className={colorClass} size="small" label={component}></Chip>;
}

export function getColumnControl(row, column, handleTableHeaderEditChange) {
  const value = (row || {})[column.key];
  const editConfig = column.editConfig;
  switch ((editConfig.type || "").trim()) {
    case "Checkbox":
      return (
        <>
          <Tooltip title={!value ? editConfig.toolTip1 : editConfig.toolTip2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) =>
                    handleTableHeaderEditChange(e.target.checked, column.key)
                  }
                  name={editConfig.name}
                  color="default"
                  size="small"
                  className={"white-checkbox"}
                />
              }
            />
          </Tooltip>
        </>
      );
    default:
      return false;
  }
}

// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store/appStore';
import './style/main.less';
import 'regenerator-runtime/runtime';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider, authenticationParameters } from './authProvider';
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AzureAD provider={authProvider} reduxStore={store} forceLogin={true}>
        {
          ({ login, logout, authenticationState, error, accountInfo }) => {
            const isAuthenticated = authenticationState === AuthenticationState.Authenticated;
            //console.log(authProvider);
            if (isAuthenticated) {
              let isOffice = false;
              let isIndustrial = false;
              let isBoth = false;
              let roles = accountInfo.account.idTokenClaims.roles;
              console.log(roles);
              let selectedRole = window.sessionStorage.getItem("changedGroup");
              
              for (var i = 0; i < roles.length; i++) {
                if (roles[i] == "6e1215b3-1b90-41bd-bb91-a1003d9dc2bd" 
                || roles[i] == "229a931c-16f8-4e68-a5ff-5d110762452c"
                || roles[i] == "e2cd64e7-3a0c-4552-8942-e37a1f0e7935"
                || roles[i] == "92fbfc9d-0b31-4752-97a9-f19c8956351c"
                || roles[i] == "957908d5-eb53-4848-9a59-e2339076ea1f"
                || roles[i] == "27481387-b941-4230-aaf4-236fdfb4ac49"
                || roles[i] == "a250a416-82a5-41b8-b5a8-11d8e4aa7f21"                
                || roles[i] == "f1adcbf0-4aaa-4cd8-958b-fdfdb1b10306"
                || roles[i] == "478e7e05-c306-4a36-b964-68998c271645"
                || roles[i] == "a2189de9-b393-4429-9896-b583b3b71819")
                  isOffice = true;
 
                if (roles[i] == "845756e8-687d-4a34-8839-80191001f188"
                || roles[i] == "2ffde209-46d2-4abd-b870-0089cd9544d6"
                || roles[i] == "60e67522-bf8e-4217-a683-f7a9de1cc16a"
                || roles[i] == "e6264929-a0e6-4e1e-84c2-50646d0b682f"
                || roles[i] == "0588882d-571d-4d15-8b54-96807aea46e8"
                || roles[i] == "fb5426d0-4c22-47f2-97cf-eeafaf96c20c"
                || roles[i] == "e629a06c-b328-4d87-bb8c-54b67fc24074"                
                || roles[i] == "259f9922-1e2c-4915-be3c-050d550fd4e1"
                || roles[i] == "6bb06291-44d4-4c39-b635-d6543036beb4"
                || roles[i] == "fd3ca376-9a55-483a-b545-3b629280483b")
                  isIndustrial = true;
              }
              console.log(isOffice);
              if (isOffice && isIndustrial)
                isBoth = true;
              if(selectedRole)
              {
                accountInfo.grp = selectedRole;
                accountInfo.isboth = isBoth;
              }
              else
              {
              if (isBoth) {
                accountInfo.grp = 'Office';
                accountInfo.isboth = true;
              }
              else if (isOffice) {
                accountInfo.grp = 'Office';
                accountInfo.isboth = false;
              }
              else if (isIndustrial) {
                accountInfo.grp = 'Industrial';
                accountInfo.isboth = false;
              }
            }
              return (<BrowserRouter>
                <App logout={logout} accountInfo={accountInfo} />
              </BrowserRouter>);
            } else {
              authProvider.loginRedirect();
              return null;
            }
          }}
      </AzureAD>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import SearchIcon from '@material-ui/icons/Search';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import StreamLogo from '../../../public/img/common/Logo.png';

import Autocomplete from "@material-ui/lab/Autocomplete";
import './TopNav.less';
import { parseJwt } from '../../../utils/generic';
import { NavLink } from 'react-router-dom';
import store from '../../../store/appStore';
import { apiRequest, apiRequestNoLoadder } from '../../../utils/api_service';
import { API_URL } from '../../../constants/api';
import { GetUserMarketAccess } from '../../../utils/helper';
import { FormControl, InputLabel, Select } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  search: {
    display: 'none',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  }
}));

export default function PrimarySearchAppBar(props) {
  const classes = useStyles();
  const [selectGroup, setSelectGroup] = React.useState(props.accountInfo.grp);
  let [selectMarket, setMarket] = React.useState(props.selectMarket);

  const handleChange = (event) => {
    setSelectGroup(event.target.value);
    props.onGroupChange(event.target.value);
  };

  const handleMarketChange = (event) => {
    
    if(event.target.value == 0)
    {
      const marketId =_.cloneDeep(assignedMarkets.map((x) => x.marketId));
      const allMarketId= marketId.join("|");
       setMarket(event.target.value);
       props.onMarketChange(allMarketId);
      
    }
    else
    {
    setMarket(event.target.value);
    props.onMarketChange(event.target.value);
    }
  }




  // const handleMarketChange = (event,value) => {    
  //   setSelectedMarket(event.target.value);
  //   window.sessionStorage.setItem("selectedMarket", JSON.stringify(event.target.value));
  // };

  const [selectedMarket, setSelectedMarket] = React.useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  //select
  // const [anchorE2, setAnchorE2] = React.useState(null);
  // const [selectMoreAnchorE2, setSelectMoreAnchorE2] = React.useState(null);

  // const isSelectOpen = Boolean(anchorE2);
  // const isSelectMenuOpen = Boolean(selectMoreAnchorE2);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  // const handleSelectMenuOpen = (event) => {
  //   setAnchorE2(event.currentTarget);
  // };

  // const handleSelectMenuClose = () => {
  //   setSelectMoreAnchorE2(null);
  // };

  // const handleSelectClose = () => {
  //   setAnchorE2(null);
  //   handleSelectMenuClose();
  // };

  const handelLogOut = () => {
    window.location = '/';
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  let UserInitial = 'NA';
  const UserName = (((store.getState() || {}).auth || {}).account || {}).name || '';
  const emailId = (((store.getState() || {}).auth || {}).account || {}).userName || '';
  const groupName = (((((store.getState() || {}).user || {}).userData || {}).groups || [])[0] || {}).configurationName || '';
  const assignedMarkets = (props.userConfig || []).userMarket || [];
  if(assignedMarkets.length > 1)
  {
    if (assignedMarkets.filter(e => e.market === "All").length == 0) {
        assignedMarkets.push({ market:"All",marketId:0,isDefaultAccess:false });
    }
  }

  if (assignedMarkets.length > 0) {
    if (selectMarket === undefined) {
      let obj = assignedMarkets.find(m => m.isDefaultAccess === true)
      selectMarket = obj.marketId;
      props.setInitialValue(selectMarket);
    }
  }

  if (UserName !== '' || UserName !== '') {
    const matches = UserName.match(/\b(\w)/g);
    UserInitial = matches.join('');
  } else {
    UserInitial = '';
  }
  var title = '';
  if (props.accountInfo.grp == 'Industrial') {
    title = 'Ground Control Industrial';
  }
  else if (props.accountInfo.grp == 'Office') {
    title = 'Ground Control Office'
  }
  const menuId = 'primary-search-account-menu';
  const selectMenuId = 'primary-search-account-menu';

  //   const renderSelectOption=(
  // <Menu
  //       anchorE1={anchorE2}
  //       anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //       id={selectMenuId}
  //       keepMounted
  //       transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  //       open={isSelectOpen}
  //       onClose={handleSelectMenuClose}
  //       style={{ marginTop: '30px' }}
  //     >
  //       <div className="user-initial-inner"><span>{UserInitial}</span></div>
  //       {UserName && <div className="user-details-inner">{UserName}</div>}
  //       <div className="user-details-inner">{emailId}</div>      
  //       <MenuItem
  //         //onClick={}
  //         style={{ paddingLeft: '35%' }}
  //       >
  //        Office
  //       </MenuItem>
  //       <MenuItem
  //        // onClick={}
  //         style={{ paddingLeft: '35%' }}
  //       >
  //        Industrial
  //       </MenuItem>
  //     </Menu>
  //   );
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: '30px' }}
    >
      <div className="user-initial-inner">{UserInitial}</div>
      {UserName && <div className="user-details-inner">{UserName}</div>}
      <div className="user-details-inner">{emailId}</div>

      <MenuItem
        onClick={(handleMenuClose, props.logout)}
        style={{ paddingLeft: '35%' }}
      >
        Sign Out
      </MenuItem>
    </Menu>
  );
  const getMarketData = JSON.parse(window.sessionStorage.getItem("userMarketbasedAccess"));

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const selectdMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          {/* <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge> */}
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      {/* <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        Hi
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Sign Out</p>
      </MenuItem>
    </Menu>
  );

  return (

    <div className={classes.grow}>
      <AppBar position="static" className={'appbar-background'}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton> */}
          <NavLink
            to={
              '/officeProperties'
            }
          >
            <img src={StreamLogo} alt="logo" className={'stream-logo'} />
          </NavLink>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <div
            className={classes.grow}
            style={{
              fontSize: '18px',
              textAlign: 'center',
              textTransform: 'uppercase',
              fontWeight: '500'
            }}
          >
            {title}
          </div>
          <div className={classes.sectionDesktop}>
            {/* <div>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
              >
                 <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
               </Badge>
              </IconButton>
            </div> */}

            {/* <Autocomplete
            size="small"
            multiple={true}
            underlineStyle={{ display: "none" }}
            limitTags={1}
            options={(getMarketData || []).map(
              (option) => option[JSON.parse(window.sessionStorage.getItem("userMarketbasedAccess"))[0].key]
            )}
            autoHighlight
            value={selectedMarket}
            key={"dropDown_filter"}
            id={"dropDown_filter"}
            onChange={(event, value) => {
              setSelectedMarket(value);
              
              handleMarketChange(event, value);
              // this.onChange(filter, event, value);
            }}
            renderInput={(params) => (
              <Box
                sx={{ display: "flex", alignItems: "center" }}
                className="filter-label"
              >
                <label htmlFor="filled-hidden-label-small">Market</label>
                <TextField
                  {...params}
                  fullWidth
                  className="autosearc-text"
                  hiddenLabel
                  variant="outlined"
                  margin="normal"
                  placeholder="Product Type"
                />
              </Box>
            )}
          /> */}
            <div style={{ paddingRight: "5px" }}>
              {assignedMarkets.length > 0 &&
                <FormControl variant="outlined" >
                  <InputLabel id="select-label"></InputLabel>
                  <Select
                    labelId="select-label"
                    id="mrkt-select"
                    value={selectMarket}
                    onChange={handleMarketChange}
                    style={{ fontSize: '14px', backgroundColor: 'white', width: '188px', height: '35px', paddingTop: '4px', marginTop: '6px' }}
                  >
                    {/* <MenuItem value={"Industrial"}>Industrial</MenuItem>
   <MenuItem value={"Office"}>Office</MenuItem>
    */}

                    {assignedMarkets.map((m, index) =>
                      <MenuItem key={m.marketId} value={m.marketId} primaryText={m.market} selected={m.isDefaultAccess}>{m.market}</MenuItem>
                    )}
                    {/* {assignedMarkets.length > 1 &&
                      <MenuItem key={0} value={0} primaryText={"All"} >All</MenuItem>
                    
                    } */}

                  </Select>
                </FormControl>
              }
            </div>
            {props.accountInfo.isboth &&
              <FormControl variant="outlined" >
                <InputLabel id="select-label-accInfo"></InputLabel>
                <Select
                  labelId="select-label-accInfo"
                  id="accInfo-select"
                  value={selectGroup}
                  onChange={handleChange}
                  style={{ fontSize: '14px', backgroundColor: 'white', width: '114px', height: '35px', paddingTop: '4px', marginTop: '6px' }}
                >
                  <MenuItem value={"Industrial"}>Industrial</MenuItem>
                  <MenuItem value={"Office"}>Office</MenuItem>

                </Select>
              </FormControl>
            }
            <div>
              <div>

                {/* <FormControl variant="outlined" >
        <InputLabel id="demo-simple-select-filled-label"></InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectGroup}
          onChange={handleChange}
          style={{fontSize: '14px',backgroundColor:'white'}}
        >
           <MenuItem  value={"Office"}>Office</MenuItem>
          <MenuItem  value={"Industrial"}>Industrial</MenuItem>
        </Select>
      </FormControl> */}
              </div>

              <IconButton
                edge="end"
                aria-label="User Profile"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                size="medium"
                className="iconbutton-extend"
              >
                <span className="user-initial">{UserInitial}</span>
              </IconButton>
            </div>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
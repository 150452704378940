// @flow weak

import React, { Fragment } from "react";
import PropTypes from "prop-types";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
	position: "absolute",
	width: "100%",
	height: 42,
	transform: "translate(0%, -50%)",
	borderRadius: 7,
	cursor: "pointer"
	// border: '1px solid white',
};

const railInnerStyle = {
	position: "absolute",
	width: "100%",
	height: 10,
	transform: "translate(0%, -50%)",
	borderRadius: 7,
	pointerEvents: "none",
	border: "2px solid #05375E",
	backgroundColor: "rgb(255,255,255)"
};

export function SliderRail({ getRailProps }) {
	return (
		<Fragment>
			<div style={railOuterStyle} {...getRailProps()} />
			<div style={railInnerStyle} />
		</Fragment>
	);
}

SliderRail.propTypes = {
	getRailProps: PropTypes.func.isRequired
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export function Handle({
	domain: [min, max],
	handle: { id, value, percent },
	disabled,
	getHandleProps
}) {
	return (
		<Fragment>
			<div
				style={{
					left: `${percent}%`,
					position: "absolute",
					transform: "translate(-50%, -50%)",
					WebkitTapHighlightColor: "rgba(0,0,0,0)",
					zIndex: 5,
					width: 28,
					height: 42,
					cursor: "pointer",
					// border: '1px solid white',
					backgroundColor: "none"
				}}
				{...getHandleProps(id)}
			/>
			<div
				role="slider"
				aria-valuemin={min}
				aria-valuemax={max}
				aria-valuenow={value}
				style={{
					left: `${percent}%`,
					position: "absolute",
					transform: "translate(-50%, -50%)",
					zIndex: 2,
					width: 18,
					height: 18,
					borderRadius: "50%",
					// boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
					backgroundColor: disabled ? "#666" : "#043154"
				}}
			/>
		</Fragment>
	);
}

Handle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Handle.defaultProps = {
	disabled: false
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses a button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
	domain: [min, max],
	handle: { id, value, percent },
	disabled,
	getHandleProps
}) {
	return (
		<button
			role="slider"
			aria-valuemin={min}
			aria-valuemax={max}
			aria-valuenow={value}
			style={{
				left: `${percent}%`,
				position: "absolute",
				transform: "translate(-50%, -50%)",
				zIndex: 2,
				width: 20,
				height: 20,
				borderRadius: "50%",
				// boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
				backgroundColor: disabled ? "#666" : "#ffc400"
			}}
			{...getHandleProps(id)}
		/>
	);
}

KeyboardHandle.propTypes = {
	domain: PropTypes.array.isRequired,
	handle: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getHandleProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

KeyboardHandle.defaultProps = {
	disabled: false
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps, disabled }) {
	return (
		<div
			style={{
				position: "absolute",
				transform: "translate(0%, -50%)",
				height: 10,
				zIndex: 1,
				backgroundColor: disabled ? "#999" : "#05375E",
				borderRadius: 7,
				cursor: "pointer",
				left: `${source.percent}%`,
				width: `${target.percent - source.percent}%`
			}}
			{...getTrackProps()}
		/>
	);
}

Track.propTypes = {
	source: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	target: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	getTrackProps: PropTypes.func.isRequired,
	disabled: PropTypes.bool
};

Track.defaultProps = {
	disabled: false
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, count, format }) {
	return (
		<div>
			<div
				style={{
					position: "absolute",
					marginTop: 14,
					width: 1,
					height: 5,
					// backgroundColor: "#27608E",
					left: `${tick.percent}%`
				}}
				className="tick"
			/>
			<div
				style={{
					position: "absolute",
					marginTop: 22,
					fontSize: 11,
					fontWeight: 500,
					textAlign: "center",
					marginLeft: `${-(100 / count) / 2}%`,
					width: `${100 / count}%`,
					left: `${tick.percent}%`
					// color: "#27608E"
				}}
			>
				{format(tick.value)}
			</div>
		</div>
	);
}

Tick.propTypes = {
	tick: PropTypes.shape({
		id: PropTypes.string.isRequired,
		value: PropTypes.number.isRequired,
		percent: PropTypes.number.isRequired
	}).isRequired,
	count: PropTypes.number.isRequired,
	format: PropTypes.func.isRequired
};

Tick.defaultProps = {
	format: d => d
};

// import React, { useEffect ,useState} from 'react';
// import ReportTable from './IndReportTable';

// function Reports (props) {
//   console.log(props);
//    const [selectedMarketId,setSelectedMarketId]=useState(props.globalMarketId);


//   useEffect(()=>{
//        setSelectedMarketId(props.globalMarketId); 
//   //}
//    }, [props.globalMarketId]);

//   return <div>
//       <ReportTable globalMarketId={props.globalMarketId}></ReportTable>
//   </div>;
// }

// export default Reports;

import React from 'react';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "../../../public/css/style.less";
import Paper from "@material-ui/core/Paper";
import { Link } from "@material-ui/core";
import PageHeader from "../../common/PageHeader";
import { IsSectionVisible } from "../../../utils/helper";
import { DateFormat, SECTIONS } from "../../../constants/appConstants";

import IndAnnualAbsorptionToolReport from './IndAnnualAbortionToolReport';
import IndVaccancyReport from './IndVaccancyReport';
import IndSubMarketBreakdownLeasingStats from './IndSubMarketBreakdownLeasingStats';
import IndSubMarketBreakdownPosNegAbsQtr from './IndSubMarketBreakdownPosNegAbsQtr';
import IndSubMarketBreakdownSubLeasingStats from './IndSubMarketBreakdownSubLeasingStats';
import IndSubMarketBreakdownAnnualAverageAbsorption from './IndSubMarketBreakdownAnnualAverageAbsorption';
import IndSubMarketBreakdownHistoricalTotalsAndAverages from './IndSubMarketBreakdownHistoricalTotalsAndAverages';
import IndSubMarketBreakdownVacancyCompetitiveOnlyProps from './IndSubMarketBreakdownVacancyCompetitiveOnlyProps';
import IndYTDAbsorptionSubmarket from './IndYTDAbsorptionSubmarket';
import IndQuarterAndMonthlyResearch from './IndQuarterAndMonthlyResearch';
import IndClassificationBreakdown from './IndClassificationBreakdown';
import IndCovidSubAbs from './IndCovidSubAbs';
import IndCurrentDevelopmentPipeline from './IndCurrentDevelopmentPipeline'
import IndCovidOfficeDelivered from './IndCovidOfficeDelivered'
import IndLeaseCompQAReport from "./IndLeaseCompQAReport";
import { apiBatchRequest } from '../../../utils/api_service';
import { API_URL } from '../../../constants/api';
import moment from 'moment';

export default class IndReports extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      monthRecordeddata:[],
      monthRecorded:'',
      reload: (((this.props || {}).location || {}).navProps || {}).reload,
      selectedMarketId: this.props.globalMarketId,
    };
  }

  componentWillReceiveProps(nextProps) {    
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {      
      this.setState({ selectedMarketId: nextProps.globalMarketId });
      this.getMonthRecorded(nextProps.globalMarketId)
    }
  }
  getMonthRecorded = (selectedMarketId) =>  {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Reports.getMonthRecorded,
        params: { businessLineId: 2, marketId: selectedMarketId },
        key: "monthRecordeddata",
      },
    ])
      .then(({ monthRecordeddata }) => {
        this._asyncRequest = null;
        if (monthRecordeddata.success !== true) {
          ShowToast(
            commonLabels.somethingWrong,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
        this.setState({
          monthRecorded: monthRecordeddata.data[0].MonthRecorded || moment().startOf("month").format(DateFormat)
        });
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  }

  componentDidMount() {     
    //this.forceUpdate();
    this.getMonthRecorded(this.state.selectedMarketId)
  }

  clickOnLink = (id = '',title = '') =>  {
    this.setState({ selectedLink: id, selectedTitle: title });
  }

  // clickOnLink (id = '') {
  //   this.setState({ selectedLink: id });
  // }
  
  // static getDerivedStateFromProps(newProps, state) {
  //   if ((((newProps || {}).location || {}).navProps || {}).reload !== (state || {}).reload) {
  //     // this.LoadData();
  //     return {
  //       reload: ((newProps.location || {}).navProps || {}).reload,
  //       selectedMarketId: newProps.globalMarketId
  //     };
  //   }
  //   return null;
  // }

  render() {
    const { selectedLink,selectedTitle } = this.state;
    const CompName = selectedLink; //this.state.p.child;
    return (
      <div>
        {/* <BasicTable reload={this.state.reload} globalMarketId={this.state.selectedMarketId}></BasicTable>
        <div>{selectedLink} report loaded</div> */}
        {/* <div>
          <span onClick={() => this.clickOnLink(Ind'')}>Reports  {selectedLink}</span>
        </div> */}
        {
        selectedTitle ? 
        <div className="row pageheader-container start-xs pageheader-padding no-print" style={{position:"sticky"}}>
        <div className="col-sm-3 col-md-3 col-lg-6">
          <div className="col-xs-auto pageheader">
            <span onClick={() => this.clickOnLink('')} className="header-btn">Reports</span>
            <span>/</span>            
            <span className="report-name">{this.state.selectedTitle}</span>
            </div>
        </div>
        </div> : 
        <div className="row pageheader-container start-xs pageheader-padding no-print" style={{position:"sticky"}}>
        <div className="col-sm-3 col-md-3 col-lg-6">
          <div className="col-xs-auto pageheader">
            <span className="header-btn">Reports</span>
            </div>
        </div>
        </div>
        }
        {selectedLink
          ?  <CompName globalMarketId={this.state.selectedMarketId} monthRecorded={this.state.monthRecorded} marginTop={-38}/>
          : 
          <div style={{padding:"16px"}}>
          <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Report Type </TableCell>
                <TableCell align="left">Report Name</TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                  <TableRow key="Classification Breakdown">
                    <TableCell component="th" scope="row">
                    Classification Breakdown
                    </TableCell>
                    <TableCell align="left">                      
                        <p key="0">
                          <Link style={{ cursor: "pointer" }} key="00" onClick={() => this.clickOnLink(IndAnnualAbsorptionToolReport,'Market Absorption Report')} to="">
                            Market Absorption Report
                          </Link>
                        </p>  
                        <p key="1">
                          <Link style={{ cursor: "pointer" }} key="01" onClick={() => this.clickOnLink(IndVaccancyReport,'Vacancy Report')} to="">
                          Vacancy Report
                          </Link>
                        </p>  
                        <p key="2">
                          <Link style={{ cursor: "pointer" }} key="02" onClick={() => this.clickOnLink(IndClassificationBreakdown,'Classification Breakdown')} to="">
                          Classification Breakdown
                          </Link>
                        </p>                      
                    </TableCell>
                    <TableCell>                    
                    </TableCell>                    
                  </TableRow>

                  <TableRow key="Submarket Breakdown">
                    <TableCell component="th" scope="row">
                    Submarket Breakdown
                    </TableCell>
                    <TableCell align="left">                      
                        <p key="3">
                          <Link style={{ cursor: "pointer" }} key="03" onClick={() => this.clickOnLink(IndSubMarketBreakdownLeasingStats,'Leasing Statistics')} to="">
                          Leasing Statistics
                          </Link>
                        </p>  
                        <p key="4">
                          <Link style={{ cursor: "pointer" }} key="04" onClick={() => this.clickOnLink(IndSubMarketBreakdownPosNegAbsQtr,'Positive And Negative Absorption')} to="">
                          Positive And Negative Absorption
                          </Link>
                        </p>  
                        <p key="5">
                          <Link style={{ cursor: "pointer" }} key="05" onClick={() => this.clickOnLink(IndSubMarketBreakdownSubLeasingStats,'Sublease Statistics')} to="">
                          Sublease Statistics
                          </Link>
                        </p>      
                        <p key="6">
                          <Link style={{ cursor: "pointer" }} key="06" onClick={() => this.clickOnLink(IndSubMarketBreakdownAnnualAverageAbsorption,'Average Annual Absorption')} to="">
                          Average Annual Absorption
                          </Link>
                        </p>  
                        <p key="7">
                          <Link style={{ cursor: "pointer" }} key="07" onClick={() => this.clickOnLink(IndSubMarketBreakdownHistoricalTotalsAndAverages,'Historical Totals And Averages')} to="">
                          Historical Totals And Averages
                          </Link>
                        </p>  
                        <p key="8">
                          <Link style={{ cursor: "pointer" }} key="08" onClick={() => this.clickOnLink(IndSubMarketBreakdownVacancyCompetitiveOnlyProps,'Vacancy - Competitive Only Properties')} to="">
                          Vacancy - Competitive Only Properties
                          </Link>
                        </p>                      
                    </TableCell>   
                    <TableCell>                    
                    </TableCell>                                     
                  </TableRow>
                
                  <TableRow key="Others">
                    <TableCell component="th" scope="row">
                    Others
                    </TableCell>
                    <TableCell align="left">                      
                        <p key="9">
                          <Link style={{ cursor: "pointer" }} key="09" onClick={() => this.clickOnLink(IndYTDAbsorptionSubmarket,'YTD Absorption Submarket')} to="">
                          YTD Absorption Submarket
                          </Link>
                        </p>  
                        <p key="10">
                          <Link style={{ cursor: "pointer" }} key="010" onClick={() => this.clickOnLink(IndQuarterAndMonthlyResearch,'Quarter And Monthly Research')} to="">
                          Quarter And Monthly Research
                          </Link>
                        </p>  
                        <p key="11">
                          <Link style={{ cursor: "pointer" }} key="011" onClick={() => this.clickOnLink(IndCovidSubAbs,'COVID - Sub Abs')} to="">
                          COVID - Sub Abs
                          </Link>
                        </p>      
                        <p key="12">
                          <Link style={{ cursor: "pointer" }} key="012" onClick={() => this.clickOnLink(IndCovidOfficeDelivered,'COVID - Office Space Delivered Since 2015')} to="">
                          COVID - Office Space Delivered Since 2015
                          </Link>
                        </p>  
                        <p key="13">
                          <Link style={{ cursor: "pointer" }} key="013" onClick={() => this.clickOnLink(IndCurrentDevelopmentPipeline,'Current Development Pipeline')} to="">
                          Current Development Pipeline
                          </Link>
                        </p>  
                                         
                    </TableCell>   
                    <TableCell>                    
                    </TableCell>                                     
                  </TableRow>

                  <TableRow key="LeaseComps">
                    <TableCell component="th" scope="row">
                    Lease Comps
                    </TableCell>
                    <TableCell align="left">                                             
                        <p key="14">
                          <Link style={{ cursor: "pointer" }} key="014" onClick={() => this.clickOnLink(IndLeaseCompQAReport,'Lease Comp QA Report')} to="">
                          Lease Comp QA Report
                          </Link>
                        </p>  
                                         
                    </TableCell>  
                    <TableCell>                    
                    </TableCell>                                      
                  </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
        </div>
        }
      </div>
    );
  }
}

//export default Reports;

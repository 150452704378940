import axios from "axios";
import _ from "lodash";
import {
	apiBatchRequest,
	apiBatchRequest2,
	apiBatchRequestForExport,
	apiPost,
	apiPostForExport,
	apiRequest,
	apiRequestForExport,
} from "./api_service";
import { removeDuplicates } from "./helper";
import { saveAs } from "file-saver";
import store from "../store/appStore";
import xlsx from "xlsx";
import moment from "moment-mini";
import {
	SET_DOWNLOADING_VISIBILITY,
	SET_FILEDOWNLOADING,
} from "../store/loader/actionTypes";

import { DOWNLOAD_STATUS } from "../constants/constants";
import { API_URL } from "../constants/api";
const getMultilevelGroupedData = (data, groupBy, isTotal, colList = []) => {
	//
	let groupedData = _.cloneDeep(data);
	if (groupBy !== undefined && groupBy[0] !== undefined) {
		groupedData = getGroupValues(
			groupedData,
			groupBy[0],
			groupBy,
			isTotal,
			colList
		); //isTotal,
	}
	//  console.log('groupBy', groupBy, 'groupedData', groupedData);
	return groupedData;
};

const getGroupValues = (data, key, groupBy, isTotal, colList) => {
	//isTotal,
	groupBy = groupBy.filter((x) => x !== key);
	const nextKey = groupBy[0];
	return _.chain(data)
		.groupBy(key)
		.map(function (v, i) {
			if (isTotal) {
				return {
					key: key,
					[key]: i,
					data:
						nextKey !== undefined
							? getGroupValues(v, nextKey, groupBy, isTotal, colList)
							: v,
					summaryData: getSummaryData(v, colList),
					isGroup: true,
				};
			} else {
				return {
					key: key,
					[key]: i,
					data:
						nextKey !== undefined
							? getGroupValues(v, nextKey, groupBy, colList)
							: v,
				};
			}
		})
		.value();
};

// function downloadExcelReportByAPI(
// 	exportAbsoluteURL
// ) {
//   apiBatchRequestForExport([
//     {
//     url: exportAbsoluteURL,
//     key:"response"
//   }
// ])
//   .then((response) => {
//     let filename = response.response.headers['content-disposition']
//     .split(';')
//     .find((n) => n.includes('filename='))
//     .replace('filename=', '')
//     .trim();

//     //let filename = "BuildingExport.xlsx";
//     let url = window.URL
//     .createObjectURL(new Blob([response.response.data]));

//     saveAs(url, filename);
// });
// }

// function downloadImportFileData(importData,fileName)
// {
// 	const wb = xlsx.utils.book_new();
// 	var data = importData.data;
// 	//data.map((ele) => {delete ele.A; return ele});
// 	var wsData = xlsx.utils.json_to_sheet(data);
// 	var data1 = importData.data1;
// 	//data1.map((ele) => {delete ele.A; return ele});
// 	var wsData1 = xlsx.utils.json_to_sheet(data1);
// 	xlsx.utils.book_append_sheet(wb, wsData, "Full Market Stats");
// 	xlsx.utils.book_append_sheet(wb, wsData1, "New Modified Market Stats");
// 	xlsx.writeFile(wb, fileName+".xlsx");
// }
function downloadImportFileData(importData, fileName) {
	const wb = xlsx.utils.book_new();
	importData.data.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data1.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data2.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data3.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data4.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data5.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data6.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	importData.data7.map((obj) => {
		obj.MonthRecorded = moment(obj.MonthRecorded).format("MM-DD-YYYY");
		delete obj.Result;
	});
	var data = importData.data;

	//data.map((ele) => {delete ele.A; return ele});
	var wsData = xlsx.utils.json_to_sheet(data);
	var data1 = importData.data1;
	var wsData1 = xlsx.utils.json_to_sheet(data1);
	var data2 = importData.data2;
	var wsData2 = xlsx.utils.json_to_sheet(data2);
	var data3 = importData.data3;
	var wsData3 = xlsx.utils.json_to_sheet(data3);
	var data4 = importData.data4;
	var wsData4 = xlsx.utils.json_to_sheet(data4);
	var data5 = importData.data5;
	var wsData5 = xlsx.utils.json_to_sheet(data5);
	var data6 = importData.data6;
	var wsData6 = xlsx.utils.json_to_sheet(data6);
	var data7 = importData.data7;
	var wsData7 = xlsx.utils.json_to_sheet(data7);
	xlsx.utils.book_append_sheet(wb, wsData6, "Net New Properties");
	xlsx.utils.book_append_sheet(wb, wsData, "Full Market Stats");
	xlsx.utils.book_append_sheet(wb, wsData1, "New Modified Market Stats");
	xlsx.utils.book_append_sheet(wb, wsData2, "Properties Tracked");
	xlsx.utils.book_append_sheet(wb, wsData3, "Delta from Prior Import");
	xlsx.utils.book_append_sheet(wb, wsData4, "Changes in Total SF");
	xlsx.utils.book_append_sheet(wb, wsData5, "Fully Vacant Buildings");
	xlsx.utils.book_append_sheet(wb, wsData7, "Building Status");
	xlsx.writeFile(wb, fileName + ".xlsx");
}

function downloadStatsExcelReport(
	exportAbsoluteURL,
	filename,
	market,
	submarket,
	product,
	date
) {
	//console.log(selectedData);
	const postBodyParams = {
		AbsoluteUri: exportAbsoluteURL,
		MarketStatsDate: date,
		Market: market,
		MarketStatsSubmarket: submarket,
		MarketStatsProduct: product,
		SelectedData: undefined,
		FileMimeType: "application/vnd.ms-excel",
		IsMarketStats: true,
	};
	const downloadingKey = generateString(6);
	setDownloadingStatus(downloadingKey, filename, "downloading");
	apiPostForExport({
		url: API_URL.Export.GetReport,
		params: {},
		postBody: postBodyParams,
	}).then((response) => {
		if (response.success === true) {
			const temp = new Blob([response.data]);
			if (temp instanceof Blob) {
				const url = window.URL.createObjectURL(temp);
				saveAs(url, filename);
				setDownloadingStatus(downloadingKey, filename, "completed");
			} else
				setDownloadingStatus(
					downloadingKey,
					filename,
					"failed",
					"",
					"Data Not Found"
				);
		} else {
			setDownloadingStatus(
				downloadingKey,
				filename,
				"failed",
				"",
				"Data Not Found"
			);
		}
	});
}

const counter = 1;
function downloadExcelReportByAPI(
	exportAbsoluteURL,
	filename,
	selectedData,
	market,
	fileMimeType,
	entity
) {
	//console.log(selectedData);
	const postBodyParams = {
		AbsoluteUri: exportAbsoluteURL,
		SelectedData: selectedData,
		Market: market.toString(),
		FileMimeType: fileMimeType,
		IsMarketStats: false,
		Entity: entity,
	};
	const downloadingKey = generateString(6);
	setDownloadingStatus(downloadingKey, filename, "downloading");
	apiPostForExport({
		url: API_URL.Export.GetReport,
		params: {},
		postBody: postBodyParams,
	}).then((response) => {
		if (response.success === true) {
			const temp = new Blob([response.data]);
			if (temp instanceof Blob) {
				const url = window.URL.createObjectURL(temp);
				saveAs(url, filename);
				setDownloadingStatus(downloadingKey, filename, "completed");
			} else
				setDownloadingStatus(
					downloadingKey,
					filename,
					"failed",
					"",
					"Data Not Found"
				);
		} else {
			setDownloadingStatus(
				downloadingKey,
				filename,
				"failed",
				"",
				"Data Not Found"
			);
		}
	});
}

function setDownloadingStatus(
	downloadingKey = "",
	fileName = "",
	status = "downloading",
	errorMessage = "",
	statusMessage = ""
) {
	store.dispatch({
		type: SET_FILEDOWNLOADING,
		payload: {
			details: {
				dKey: downloadingKey,
				fileName: fileName,
				status: DOWNLOAD_STATUS[status],
				errorMessage: errorMessage,
				statusMessage: statusMessage,
			},
		},
	});
}

function isObjectEquivalent(a, b) {
	// Create arrays of property names
	// If number of properties is different,
	// objects are not equivalent

	const aProps = Object.getOwnPropertyNames(a);
	const bProps = Object.getOwnPropertyNames(b);

	if (!aProps || !bProps || aProps.length != bProps.length) {
		return false;
	}

	for (let i = 0; i < aProps.length; i++) {
		const propName = aProps[i];

		// If values of same property are not equal,
		// objects are not equivalent
		if (a[propName] !== b[propName]) {
			return false;
		}
	}

	// If we made it this far, objects
	// are considered equivalent
	return true;
}

function generateString(length) {
	const characters =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let result = " ";
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

function getSummaryData(data, colList) {
	let resultArray;
	if (colList) {
		const summaryData = data.map((obj) => obj);
		const merged = [].concat.apply([], summaryData);
		const uniqueData = removeDuplicates(data.map((x) => x));
		const column1 = colList[0];
		const column2 = colList[1];
		const column3 = colList[2];
		const column4 = colList[3];
		let column5 = null;
		colList.length > 4 ? (column5 = colList[4]) : null;

		const detailObj = {};
		uniqueData.forEach((m) => {
			detailObj.key = m;
			detailObj.isSummary = true;
			// detailObj.floorplate=_.chain(merged).sumBy(x => x[column1]).value();
			// detailObj.totalRsf = _.chain(merged).sumBy(x => x[column2]).value();
			// detailObj.leasedRsf = _.chain(merged).sumBy(x => x[column3]).value();
			// detailObj.startingLeasedRsf=_.chain(merged).sumBy(x => x[column4]).value();
			// detailObj.absorption=_.chain(merged).sumBy(x => x[column5]).value();
			detailObj[column1] = _.chain(merged)
				.sumBy((x) => x[column1])
				.value();
			detailObj[column2] = _.chain(merged)
				.sumBy((x) => x[column2])
				.value();
			detailObj[column3] = _.chain(merged)
				.sumBy((x) => x[column3])
				.value();
			detailObj[column4] = _.chain(merged)
				.sumBy((x) => x[column4])
				.value();
			colList.length > 4
				? (detailObj[column5] = _.chain(merged)
						.sumBy((x) => x[column5])
						.value())
				: null;
		});
		if (resultArray === undefined) {
			resultArray = [];
		}
		resultArray.push(detailObj);
	}
	return resultArray;
}
function getReimbursementSummary(data, isReimbursed) {
	let resultArray;
	if (isReimbursed) {
		const reimburseData = data.map((obj) => obj);
		const merged = [].concat.apply([], reimburseData);
		const uniqueData = removeDuplicates(data.map((x) => x.market));
		uniqueData.forEach((cycle) => {
			const detailObj = {};
			detailObj.market = cycle;
			detailObj.isSummary = true;
			detailObj.leasedRsf = _.chain(merged.filter((x) => x.market === cycle))
				.sumBy((x) => x.leasedRsf)
				.value();
			if (resultArray === undefined) {
				resultArray = [];
			}
			resultArray.push(detailObj);
		});
	}
	return resultArray;
}

function visibilityDownloaderProgress(flag = false) {
	store.dispatch({
		type: SET_DOWNLOADING_VISIBILITY,

		payload: flag,
	});
}

function getAllKeysValue(arr = [], key = "") {
	const selectArr = [];
	arr &&
		arr.forEach((obj) => {
			selectArr.push(obj[key]);
		});
	return selectArr;
}

function scrollToTop() {
	const elt = document.getElementById("scrollableBody");
	elt &&
		setTimeout(() => {
			elt.scrollTo(0, 0);
		}, 500);
}

function convertIntoDDFormat(
	arr = [],
	idKey = "id",
	valueKey = "value",
	extraParame = ""
) {
	const selectArr = [];
	arr.forEach((obj) => {
		selectArr.push({
			id: obj[idKey],
			value: obj[valueKey],
			...(extraParame && { [extraParame]: obj[extraParame] }),
		});
	});
	return selectArr;
}

function convertHexToRgb(hex, opacity = 100) {
	if (!hex) return "rgba(0,0,0,1)";
	hex = hex.replace("#", "");
	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	const result = "rgba(" + r + "," + g + "," + b + "," + opacity / 100 + ")";
	return result;
}

function convertIntoInt(val) {
	return val ? parseInt(val) : val;
}

function convertIntoFloat(val) {
	return val ? parseFloat(val) : val;
}

function convertIntoBoolean(val) {
	return val && val == "true" ? true : false;
}

function orderKey(obj, keyOrder) {
	keyOrder.forEach((k) => {
		const v = obj[k];
		delete obj[k];
		obj[k] = v;
	});
}
export {
	orderKey,
	convertIntoInt,
	convertIntoFloat,
	convertIntoBoolean,
	getMultilevelGroupedData,
	isObjectEquivalent,
	generateString,
	downloadStatsExcelReport,
	downloadExcelReportByAPI,
	downloadImportFileData,
	setDownloadingStatus,
	visibilityDownloaderProgress,
	getAllKeysValue,
	scrollToTop,
	convertIntoDDFormat,
	convertHexToRgb,
};

// parseJwt(localStorage.getItem("adal.idtoken"));

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SliderDatePicker from './SlideDatePicker';
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItem,
  Tooltip
} from '@material-ui/core';
import SlidingPanel from 'react-sliding-side-panel';
import moment from 'moment';
import { removeDuplicates } from '../../utils/helper';
import { isDesktop } from 'react-device-detect';

export default class AdvancedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      endDate: moment().startOf('month').format('L'),
      // moment(localStorage.getItem('selectedendDate'))
      //   .endOf('month')
      //   .format('L'),
      startDate: moment().endOf('month').format('L'),
      // moment(localStorage.getItem('selectedendDate'))
      //   .startOf('month')
      //   .format('L'),
      typeOfDate: 'Month',
      RadioSelect: 'MTD',
      selectedMenu: {},
      configuration: this.props.filterConfig || {}
    };
  }

  static propTypes = {
    advanceFilterConfig: PropTypes.object,
    //prop: PropTypes,
    openSlidingPanel: PropTypes.bool,
    handelDateChangesSlideDate: PropTypes.func,
    leftTextArea: PropTypes.array,
    handleRightArrow: PropTypes.func,
    handleDoubleRight: PropTypes.func,
    handleDoubleLeft: PropTypes.func,
    handleLeftArrow: PropTypes.func,
    rightTextArea: PropTypes.array,
    advancedFilter: {
      checkedA: PropTypes.bool,
      checkedB: PropTypes.bool,
      checkedC: PropTypes.bool
    },
    handleCheckChange: PropTypes.func,
    handleClose: PropTypes.func
  };

  handelDateChangesSlideDate = (start, end, typeOfDate, RadioSelect) => {
    const { configuration } = this.state;
    configuration.startDate = start;
    configuration.end = end;
    configuration.typeOfDate = typeOfDate;
    configuration.RadioSelect = RadioSelect;
    this.setState({
      startDate: start,
      endDate: end,
      typeOfDate: typeOfDate,
      RadioSelect: RadioSelect,
      configuration
    });
  };

  handleGroupingChanges = (dir) => {
    const { configuration, selectedMenu } = this.state;
    if (selectedMenu) {
      if (dir === 'Left' || dir === 'Right') {
        configuration.groupBy.masterData = ((configuration.groupBy || {})
          .masterData || []).filter(x => x.value !== selectedMenu.value);
        configuration.groupBy.selected = ((configuration.groupBy || {})
          .selected || []).filter(x => x.value !== selectedMenu.value);
        if (dir === 'Right') {
          configuration.groupBy.selected.push(selectedMenu);
        } else {
          configuration.groupBy.masterData.push(selectedMenu);
        }
      }
    }
    if (dir === 'AllLeft') {
      configuration.groupBy.masterData = removeDuplicates([...((configuration.groupBy || {})
        .masterData || []), ...((configuration.groupBy || {})
          .selected || [])], 'key');
      configuration.groupBy.selected = [];
    }
    if (dir === 'AllRight') {
      configuration.groupBy.selected = removeDuplicates([...((configuration.groupBy || {})
        .selected || []), ...((configuration.groupBy || {})
          .masterData || [])], 'key');
      configuration.groupBy.masterData = [];
    }

    this.setState({
      configuration
    });
  }

  handleCheckChange = (e) => {
    this.setState({
      configuration: {
        ...this.state.configuration,
        [e.target.name]: e.target.checked
      }
    });
    //this.props.setIsTotal();
  };

  render() {
    return (
      <>
      { this.props.openSlidingPanel == true ?(
      <div className='advanced-filters'>
        <div className="menuSetting"
          // type={'right'}
          // isOpen={this.props.openSlidingPanel}
          // size={(isDesktop ? 31 : 36)}
        >
          <div className="setting-container">
            <div className="header1 row">
              {' '}
              <div className="col-xs-11">Advanced Filters</div>
              <div className="col-xs-1 center-xs">
                {' '}
                <Tooltip title="Close">
                  <IconButton
                    aria-label={'CloseIconAddWidgetPanel'}
                    size="small"
                    onClick={this.handleClose}
                  >
                    {/* <CloseIcon fontSize="small" size="small" /> */}
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div className="row widget-content">
              <div className="widget-holder col-lg-12 end-lg">
                <div className="row col-lg-12" style={{display: 'flex', justifyContent: 'space-around'}}>
                  {/* <div className="pay-periodtwo">
                    <h3>Pay Period</h3>
                    <div className='slide_dateppicker'>
                      <SliderDatePicker
                        payPeriodStartDate={this.props.payPeriodStartDate}
                        payPeriodEndDate={this.props.payPeriodEndDate}
                        handelDateChangesSlideDate={
                          this.handelDateChangesSlideDate
                        }
                      />
                      <hr className="horizontal__line" />
                    </div>
                  </div> */}
                  <div className='group_all'>
                      <div className="group__title">
                        <h3>Groupings</h3>
                        <div className='sub-heading'>
                          {/* <div className="group_available"> */}
                          <h4 style={{marginRight: '100px'}}>Available</h4>
                          {/* </div> */}
                          {/* <div className="group_applied"> */}
                          <h4 style={{marginRight: '30px'}}>Applied</h4>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="text__area">
                        <div className="box__shadow">
                          {this.state.configuration.groupBy.masterData.filter(y => y.value !== undefined).map((x, i) => (
                            <ListItem key={i}
                              button
                              selected={x.value === this.state.selectedMenu.value}
                              onClick={() => this.setState({ selectedMenu: x })}
                            >{x.key}</ListItem>
                          ))}
                        </div>

                        <div
                          className="arrow__buttons"
                        >
                          <button
                            className="arrow__button"
                            onClick={() => { this.handleGroupingChanges('Right'); }}
                          >
                            {'>'}
                          </button>
                          <button
                            className="arrow__button"
                            onClick={() => { this.handleGroupingChanges('AllRight'); }}
                          >
                            {'>>'}
                          </button>
                          <button
                            className="arrow__button"
                            onClick={() => { this.handleGroupingChanges('AllLeft'); }}
                          >
                            {'<<'}
                          </button>
                          <button
                            className="arrow__button"
                            onClick={() => { this.handleGroupingChanges('Left'); }}
                          >
                            {'<'}
                          </button>
                        </div>
                        <div className="box__shadow">
                          {this.state.configuration.groupBy.selected.filter(y => y.value !== undefined).map((x, i) => (
                            <ListItem
                              key={i}
                              button
                              selected={x.value === this.state.selectedMenu.value}
                              onClick={() => this.setState({ selectedMenu: x })}
                            >{x.key}</ListItem>
                          ))}
                        </div>
                      </div>
                      <div className='advance-checkBox'>
                        {/* {this.state.configuration.showZeroNGraph && (<>
                          <div className='advance-checkBox'>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.configuration.suppressGraph}
                                  onChange={this.handleCheckChange}
                                  name="suppressGraph"
                                  color="primary"
                                />
                              }
                              label="Suppress Graph"
                            />
                          </FormGroup>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.configuration.suppressZero}
                                  onChange={this.handleCheckChange}
                                  name="suppressZero"
                                  color="primary"
                                />
                              }
                              label="Suppress Zero"
                            />
                          </FormGroup>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.configuration.summaryRows}
                                  onChange={this.handleCheckChange}
                                  name="summaryRows"
                                  color="primary"
                                />
                              }
                              label="Summary Rows"
                            />
                          </FormGroup>
                          </div>
                        </>)} */}
                      </div>
                    </div>               
                </div>
              </div>
            </div>
            <div className="row widget-content">
              <div className="widget-holder col-lg-12 end-lg">
                <div className="row col-lg-12" style={{display: 'flex', justifyContent: 'space-around'}}>
            <Grid
                    container
                    justify="center"
                    className="advanced__buttons"
                    style={{ marginTop: '30px', paddingRight:'4px' }}
                  >
                    <div style={{ display: 'flex', marginRight:'10px' }}>
                      <Button color="primary" onClick={this.props.handleClose}>
                        Cancel
                      </Button>
                      <Button
                      style={{padding:'4px'}}
                        variant="contained"
                        onClick={() => { this.props.onApplyFilter(this.state.configuration); }}
                        color="primary"
                      >
                        Apply
                      </Button>
                    </div>
                  </Grid>
                  </div>
                  </div>
                  </div>
          </div>
        </div>
      </div>
      ):null}
   </> );
  }
}

import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import { getCurrentEnvironment } from './constants/api';
import '@babel/polyfill';
// Msal Configurations
export const config = {
  auth: {
    authority: 'https://login.microsoftonline.com/72446be9-a454-4bfa-87d4-8cf4f6eaaf39',
    clientId: getCurrentEnvironment().clientId,
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
    validateAuthority: true,

    // After being redirected to the "redirectUri" page, should user
    // be redirected back to the Url where their login originated from?
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  }
};

// Authentication Parameters
export const authenticationParameters = {
  scopes: [
    'openId',
    'profile',
    'user.read'
  ]
};

// Options
export const options = {
  loginType: LoginType.redirectUri,
  tokenRefreshUri: window.location.origin + '/auth.html'
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

import {
	AppBar,
	Button,
	Dialog,
	IconButton,
	Paper,
	Toolbar,
	Typography,
	Box,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../common/PageHeader";
import { apiBatchRequest, apiPost, apiPut } from "../../utils/api_service";
import commonLabels from "../../constants/messageConstants";
import { isInputValid, UpdateFieldsWithConfiguration,UpdateColumnWithConfiguration, IsSectionVisible } from "../../utils/helper";
import FormGenerator from "../common/FormGenerator";
import { API_URL } from "../../constants/api";
import { controlType } from "../../constants/common";
import SearchBar from "../common/AutoSearch/SearchBar";
import camelcaseKeys from "camelcase-keys";
import ImageSlider from "../common/ImageSlider/ImageSlider";
import AutoSearch from "../common/AutoSearch/AutoSearch";
import { ShowToast, toastType } from "../../utils/toast-service";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
import SimpleSelect from "../common/AutoSearch/SimpleSelect";
import EditBuildings from "../Buildings/EditBuildings";
import { IoMdCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import ViewHistoryData from "../common/ViewHistoryData/ViewHistoryData";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditBuildingOwners from "../BuildingOwners/EditBuildingOwners";
import GridTable from "../common/Table/Table";
import AddEditLeaseComps from "../LeaseComps/AddEditLeaseComps";

import { buildingColumnsForDetail, leaseCompsColumnsForDetails } from "../../constants/MockData";
import { SECTIONS } from "../../constants/appConstants";

export default class EditTenants extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMarketId:this.props.globalMarketId,
			getTenantDetails: [],
			isEditEnabled: this.props.isInAddMode,
			companyMasterDetails: props.companyMasterDetails || [],

			getSubMarketMaster: props.getSubMarketMaster || [],
			getMarketMaster: props.getMarketMaster || [],

			getBuildingMaster: props.getBuildingMaster || [],
			getAllCompType: [],

			getTenantDetailsResponse: [],
			tenantSummaryData: props.tenantSummaryData || [],
			isDataSaved: false,
			isSearchedClicked: false,
			showConfirmDialog: false,
			tenantId: -1,
			detailView: false,
			selectedEditRowId: -1,
			selectedEditPropertyRowId: -1,
			ViewTitle: "Add",
			actionType: "Add",
			isRedirectedFromTenant: false,
			getHistoryDetailByField: [],
			openHistoryform: false,
			isHistoryByField: false,
			getHistory: [],
			// companyMasterDetails:[],
			// ownerTypeData:[],
			// getIndustrySector:[],
			showLeaseComps: false,      
      		showEdit: true,
			getHistory: [],
			leaseCompSummaryData: [],
			selectedTenantRowId: -1,
			ViewLeaseTitle:"Edit",
			actionLeaseType:"Edit",
			isLeaseInAddMode:false,
			openaddform:false,
			openCompAddform:false,
			selectedBuildingOwner: undefined,
			reloadCompanyPopup: false,
			selectedBuilding: undefined,
		};
	}

	enableEdit = () => {
		this.setState({
			isEditEnabled: true,
		});
		this.forceUpdate();
		this.getRequiredData();
	};

	onBackClick = () => {
		this.props.onBackClick();
	};

	onTenantBackClick = () => {
		this.setState({ openCompAddform: false, selectedTenantRowId: undefined });
	};

	getTenantChanges = (formFields) => {
		this.setState(
			{
				getTenantDetails: formFields,
				reloadTenant: !this.state.reloadTenant,
				isDataSaved: true,
				isSearchedClicked: false,
			},
			() => {
				this.forceUpdate();
			}
		);
	};

	// reloadBuildingData = () => {
	// 	this._asyncRequest = apiBatchRequest([
	// 		{
	// 			url: API_URL.Master.getBuildingMaster,
	// 			key: "getBuildingMaster",
	// 			params: { businessLineId: 1 },
	// 		},
	// 	])
	// 		.then((getTenantDetails) => {
	// 			this._asyncRequest = null;
	// 			if (getTenantDetails.getBuildingMaster.success == true) {
	// 				this.setState({
	// 					getBuildingMaster:
	// 						camelcaseKeys(getTenantDetails.getBuildingMaster.data) || [],
	// 					reloadTenant: !this.state.reloadTenant,
	// 				});
	// 				this.forceUpdate();
	// 				// this.onBackClick();
	// 			} else {
	// 				ShowToast(
	// 					"Something went wrong",
	// 					toastType.ERROR,
	// 					5000,
	// 					500,
	// 					"Server error"
	// 				);
	// 			}
	// 		})
	// 		.catch(() => {
	// 			this._asyncRequest = null;
	// 		});
	// };
	validateForm = () => {
		const formData = this.state.getTenantDetails;
		let isValid = true;
		let breakCondition = false;
		if(formData.length ==0 )
		{
			const msg = "No change detected. Please make necessary changes and try again.";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		}
		formData.forEach((x) => {
		  if (
			x.isRequired == true &&
			(x.attributeValue == undefined || x.attributeValue == null || x.attributeValue.trim() == "" ) &&
			breakCondition != true
		  ) {
			const msg = "Please fill all the mandatory fields";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		  }
		});
		return isValid;
	  };
	  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
		//console.log(formElementFields[0].attributeValue);
		let IsValid = true;
		formElementFields.forEach((ele) => {
			isInputValid(ele.attributeValue, ele, this.state.getTenantDetails);
			if (ele.isInErrorState === true) {
				IsValid = false;
			}
		});
		//console.log(formElementFields);
		stateToBeUpdate = {
			...stateToBeUpdate,
			getTenantDetails: formElementFields,
			reloadTenant: !this.state.reloadTenant,
		};
		this.setState(stateToBeUpdate);

		return IsValid;
	};
	saveTenantDetails = () => {
		let companyId = undefined;
		let company = [];
		let buildingId = undefined;
		let building = [];
		if (this.state.companyMasterDetails.length > 0) {
			company = this.state.companyMasterDetails.filter(
			  (x) => x.companyName == this.state.selectedBuildingOwner);
		}
		if(company.length > 0 && this.state.selectedBuildingOwner !== undefined){
			companyId = company[0].companyId
		}
		if (this.state.getBuildingMaster.length > 0) {
			building = this.state.getBuildingMaster.filter(
			  (x) => x.buildingName == this.state.selectedBuilding);
		}
		if(building.length > 0 && this.state.selectedBuilding !== undefined){
			buildingId = building[0].buildingId
		}
		this.state.getTenantDetails.filter((x)=>{
			if(x.dbColumn == "CompanyId" && companyId != undefined)
				x.attributeValue = companyId;
			if(x.dbColumn == "BuildingId" && buildingId != undefined)
				x.attributeValue = buildingId;		
		})
		const tenantData = this.state.getTenantDetails;
		let isValid = this.validateForm();
		let isInputValid=this.validateRequiredFields(tenantData);
		if (isValid == false && isInputValid == false 
			|| isValid == true && isInputValid == false
			|| isValid == false && isInputValid == true
			) {
				isInputValid == false ? ShowToast('There is validation error on page.', toastType.ERROR, 5000, 500) : null;
			return false; 
			}else {
		tenantData.forEach((x) => {
			if (
				_.camelCase(x.dbColumn) === _.camelCase("CompId") ||
				_.camelCase(x.dbColumn) === _.camelCase("CompanyId") ||
				_.camelCase(x.dbColumn) === _.camelCase("BuildingId") ||
				_.camelCase(x.dbColumn) === _.camelCase("CompTypeId")
			) {
				if (x.attributeValue != null)
					x.attributeValue = x.attributeValue.toString();
			}
		});

		if (this.props.actionType === "Edit") {
			const tenantId = tenantData.filter(
				(x) => _.camelCase(x.dbColumn) === _.camelCase("CompId")
			);

			apiPut(API_URL.Tenants.tenantUpdate, tenantData, {
				tenantId: tenantId[0].attributeValue,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Tenant updated successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.setState({
							isDataSaved: false,
						});
						this.props.reloadData();

						//this.getData();
					} else {
						ShowToast("Error", toastType.ERROR, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		} else {
			//companyData[0].attributeValue=-1;

			apiPost({
				url: API_URL.Tenants.tenantAdd,
				params: {},
				postBody: tenantData,
			})
				.then(({ data: response }) => {
					if (response.success === true) {
						ShowToast(
							"Tenant added successfully",
							toastType.SUCCESS,
							5000,
							500
						);
						this.props.reloadData();
					} else {
						ShowToast(response.message, toastType.error, 5000, 500);
					}
				})
				.catch(() => {
					ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
				});
		}
	}
	};

	getData = (tenantId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Tenants.tenantDetail,
				key: "getTenantDetails",
				params: { tenantId: tenantId },
			},
		])
			.then((getTenantDetails) => {
				this._asyncRequest = null;
				if (getTenantDetails.getTenantDetails.success == true) {
					this.setState({
						getTenantDetails:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	getPropertyCompanyMasterDetail=()=>
	{
		this._asyncRequest = apiBatchRequest([
			
			{
				url: API_URL.Tenants.tenantsSummaryData,
				key: "tenantSummaryData",
				params: { buildingId: "-1", marketId: this.state.selectedMarketId },
			},
			{
				url: API_URL.Master.getALLTenantCompany,
				key: "companyMasterDetails",
				params: { businessLineId: 1 },
			
			},			
			{
				url: API_URL.Master.getBuildingMaster,
				key: "getBuildingMaster",
				params: { businessLineId: 1, marketId: this.state.selectedMarketId },
			},
			
		])
			.then((tenantSummaryData) => {
				this._asyncRequest = null;
				if (tenantSummaryData.tenantSummaryData.success == true) {
					this.setState({
					tenantSummaryData:
							camelcaseKeys(tenantSummaryData.tenantSummaryData.data) || [],
						companyMasterDetails:
							camelcaseKeys(tenantSummaryData.companyMasterDetails.data) || [],
												getBuildingMaster:
							camelcaseKeys(tenantSummaryData.getBuildingMaster.data) || [],
							reloadTenant: !this.state.reloadTenant,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}
getRequiredData()
{
	this._asyncRequest = apiBatchRequest([		
		{
			url: API_URL.Master.getAllCompType,
			key: "getAllCompType",
		},
	])
		.then((getTenantDetails) => {
			this._asyncRequest = null;
			if (getTenantDetails.getAllCompType.success == true) {
				this.setState({
				
					getAllCompType:
						camelcaseKeys(getTenantDetails.getAllCompType.data) || [],
						reloadTenant: !this.state.reloadTenant,
				});
				this.forceUpdate();
			} else {
				ShowToast(
					"Something went wrong",
					toastType.ERROR,
					5000,
					500,
					"Server error"
				);
			}
		})
		.catch(() => {
			this._asyncRequest = null;
		});
		if(this.state.tenantSummaryData.length == 0 || this.state.companyMasterDetails.length ==0 || this.state.getBuildingMaster.length ==0)
			{
				this.getPropertyCompanyMasterDetail();
			}
		//this.getTenantData(this.props.selectedCompanyId);

}
	componentDidMount() {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Tenants.tenantDetail,
				key: "getTenantDetails",
				params: { tenantId: this.props.selectedTenantsId },
			},
			// {
			// 	url: API_URL.Tenants.tenantsSummaryData,
			// 	key: "tenantSummaryData",
			// 	params: { buildingId: "-1" },
			// },
			// {
			// 	url: API_URL.Master.getALLTenantCompany,
			// 	key: "companyMasterDetails",
			// 	params: { businessLineId: 1 },
			// 	// url: API_URL.Master.companyMasterDetails,
			// 	// key: "companyMasterDetails",
			// },
			// {
			// 	url: API_URL.Master.getSubMarketMaster,
			// 	key: "getSubMarketMaster",
			// 	params: { businessLineId: 1 },
			// },
			// {
			// 	url: API_URL.Master.getMarketMaster,
			// 	key: "getMarketMaster",
			// 	params: { businessLineId: 1 },
			// },
			// {
			// 	url: API_URL.Master.getBuildingMaster,
			// 	key: "getBuildingMaster",
			// 	params: { businessLineId: 1 },
			// },
			// {
			// 	url: API_URL.Master.getAllCompType,
			// 	key: "getAllCompType",
			// },
		])
			.then((getTenantDetails) => {
				this._asyncRequest = null;
				if (getTenantDetails.getTenantDetails.success == true) {
					this.setState({
						getTenantDetails:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
						getHistory:
							camelcaseKeys(getTenantDetails.getTenantDetails.configuration) ||
							[],
						getTenantDetailsResponse:
							camelcaseKeys(getTenantDetails.getTenantDetails.data) || [],
						// tenantSummaryData:
						// 	camelcaseKeys(getTenantDetails.tenantSummaryData.data) || [],
						// companyMasterDetails:
						// 	camelcaseKeys(getTenantDetails.companyMasterDetails.data) || [],
						// getSubMarketMaster:
						// 	camelcaseKeys(getTenantDetails.getSubMarketMaster.data) || [],
						// getMarketMaster:
						// 	camelcaseKeys(getTenantDetails.getMarketMaster.data) || [],
						// getBuildingMaster:
						// 	camelcaseKeys(getTenantDetails.getBuildingMaster.data) || [],
						// getAllCompType:
						// 	camelcaseKeys(getTenantDetails.getAllCompType.data) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
			if(this.props.isInAddMode == true)
			this.getRequiredData();
			
			//this.getTenantData(this.props.selectedCompanyId);
	}

	onSearch = (event, value, e) => {
		const { isDataSaved } = this.state;
		let tenantId = this.state.tenantSummaryData.find(
			(m) => m.companyName == value
		).compId;
		this.setState({
			tenantId: tenantId,
			isSearchedClicked: true,
		});

		if (isDataSaved == false) {
			this.getData(tenantId);
			this.getTenantData(this.props.selectedCompanyId);
		}
		//console.log(event);
	};
	onSearchTenant = () => {
		this.setState({
			isSearchedClicked: false,
			isDataSaved: false,
		});
		this.getData(this.state.tenantId);
		this.getTenantData(this.props.selectedCompanyId);
	};
	onClickHandel(e) {
		if (e == true) {
			this.onSearchTenant();
			this.setState({
				isSearchedClicked: false,
			});
		} else {
			this.setState({
				isSearchedClicked: false,
			});
		}
	}
	goToAddBuilding = (source, option, updateoption = true) => {
		this.setState({
			openaddform: true,
			addformtitle: source.AddNewOptiontitle,

			addNewformComponent: EditBuildings,
			// this.getNewPropertydetailForm(
			// 	getMasterDetail,
			// 	this.state.getCity,
			// 	this.state.reloadZipChanges
			// ),
			addnewClientSource: source,
		});
	};
	onPropertyPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditPropertyRowId: -1 });
		if (this.state.openaddform == true) {
			this.setState({ openaddform: false }, () => {
				this.forceUpdate();
			});
		}
	};

	reloadPropertyData = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getBuildingMaster,
				key: "getBuildingMaster",
				params: { businessLineId: 1, marketId: this.state.selectedMarketId },
			},
		])
			.then((getBuildingMaster) => {
				this._asyncRequest = null;
				if (getBuildingMaster.getBuildingMaster.success == true) {
					this.setState(
						{
							getBuildingMaster:
								camelcaseKeys(getBuildingMaster.getBuildingMaster.data) || [],
							openaddform: false,
							addNewformComponent: undefined,
							reloadTenant: !this.state.reloadTenant,
						},
						() => {
							this.forceUpdate();
						}
					);
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	buildingChanges = () => {};

  getHistoryDataByField = (val) => {
    const params = {
      entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
      sectionDetailId: val.sectionDetailId,
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      dbColumn:val.dbColumn,
      businessLineId:1
    };

    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.getHistoryDetailByField,
        key: "getHistoryDetailByField",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDetailByField) => {
        this._asyncRequest = null;
        if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
          this.setState({
            getHistoryDetailByField:
              camelcaseKeys(
                getHistoryDetailByField.getHistoryDetailByField.data
              ) || [],
            openHistoryform: true,
            isHistoryByField:true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  handleChange = (e, val) => {
    const params = {
      entityPrimaryKey: val.entityPrimaryKey,
      modifiedDate: moment(val.createdDate).format("MM-DD-YYYY HH:mm:ss"),
      sectionId: val.sectionId,
      dbTable: val.dbTable,
      businessLineId:1
    };
    //console.log("e", e, "val", val);
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Building.getHistoryDataByUser,
        key: "getHistoryDataByUser",
        params: params,
        //{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
      },
    ])
      .then((getHistoryDataByUser) => {
        this._asyncRequest = null;
        if (getHistoryDataByUser.getHistoryDataByUser.success == true) {
          this.setState({
            getHistoryDataByUser:
              camelcaseKeys(getHistoryDataByUser.getHistoryDataByUser.data) ||
              [],
            openHistoryform: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };


	goToAddBuildingOwner = (source, option, updateoption = true) => {
		this.setState({
			openPropertyOwnerAddForm: true,
			addformtitle: source.AddNewOptiontitle,
			addNewformComponent: EditBuildingOwners,
			addnewClientSource: source,
		});
	};

	onPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openPropertyOwnerAddForm == true) {
			this.setState({ openPropertyOwnerAddForm: false }, () => {
				this.forceUpdate();
			});
		} else {
			this.props.onBackClick();
		}
	};

	reloadPropertyOwnerData = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Master.getALLTenantCompany,
				key: "companyMasterDetails",
				params: { businessLineId: 1 },
			},
		])
			.then((companyMasterDetails) => {
				this._asyncRequest = null;
				if (companyMasterDetails.companyMasterDetails.success == true) {
					this.setState(
						{
							companyMasterDetails:
								camelcaseKeys(companyMasterDetails.companyMasterDetails.data) ||
								[],
							openPropertyOwnerAddForm: false,
							addNewformComponent: undefined,
							reloadTenant: !this.state.reloadTenant,
						},
						() => {
							this.forceUpdate();
						}
					);
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	buildingOwnerChanges = () => { };

	getHistoryDataByField = (val) => {
		const params = {
			entityPrimaryKey: this.state.getHistory[0].entityPrimaryKey,
			sectionDetailId: val.sectionDetailId,
			sectionId: val.sectionId,
			dbTable: val.dbTable,
			dbColumn: val.dbColumn,
			businessLineId: 1,
		};

		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getHistoryDetailByField,
				key: "getHistoryDetailByField",
				params: params,
				//{ entityPrimarykey :val.entityPrimarykey,ModifiedDate :val.modifiedDate, },
			},
		])
			.then((getHistoryDetailByField) => {
				this._asyncRequest = null;
				if (getHistoryDetailByField.getHistoryDetailByField.success == true) {
					this.setState({
						getHistoryDetailByField:
							camelcaseKeys(
								getHistoryDetailByField.getHistoryDetailByField.data
							) || [],
						openHistoryform: true,
						isHistoryByField: true,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};
	
	getTenantData = (companyId) => {
		
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.LeaseComps.leaseCompsSummaryData,
				key: "leaseCompSummaryData",
				params: { companyId: companyId , marketId: this.state.selectedMarketId },
			},
		])
			.then((leaseCompSummaryData) => {
				this._asyncRequest = null;
				if (leaseCompSummaryData.leaseCompSummaryData.success == true) {
					this.setState({
						leaseCompSummaryData:
							camelcaseKeys(leaseCompSummaryData.leaseCompSummaryData.data) || [],
						configuration:
							camelcaseKeys(
								leaseCompSummaryData.leaseCompSummaryData.configuration
							) || [],
						reloadTable: !this.state.reloadTable,
						detailView: false,
						selectedEditRowId: undefined,
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	onRowClick = (data) => {
		this.setState({
			openCompAddform:true,
			selectedTenantRowId: data.compId,
			ViewLeaseTitle: "Edit",
			actionLeaseType: "Edit",
		});
	};
	renderActionColumn = (cellInfo) => {
		return (
		  <>
			<Tooltip title="View">
			  <a
				className="default-text"
				aria-label={"refreshData"}
				size="small"
				onClick={() => {
				  this.setState({
					isInAddMode: false,
					
				  });
				  this.onRowClick(cellInfo);
				}}
			  >
				<span className="default-text span-link">
				  {cellInfo.companyName ? cellInfo.companyName : '---'}
				</span>
			  </a>
			</Tooltip>
		  </>
		);
	  };
	renderActionColumn = (cellInfo) => {
		return (
		  <>
			<Tooltip title="View">
			  <a
				className="default-text"
				aria-label={"refreshData"}
				size="small"
				onClick={() => {
				  this.setState({
					isInAddMode: false,
					
				  });
				  this.onRowClick(cellInfo);
				}}
			  >
				<span className="default-text span-link">
				  {cellInfo.companyName ? cellInfo.companyName : '---'}
				</span>
			  </a>
			</Tooltip>
		  </>
		);
	  };

	  setBuildingOwner = (value) =>{
		let companyName = "";
		if (value.length > 0) {
			companyName = value.find(
			  (x) => x.dbColumn == "CompanyName"
			).attributeValue;
			}	
		this.setState({selectedBuildingOwner : companyName, reloadTenant: !this.state.reloadCompanyPopup,});
			this.state.getTenantDetails.filter((x)=>{
				if(x.dbColumn == "CompanyName")
					x.attributeValue = companyName;
		})
		this.forceUpdate();
	};

	setBuilding = (value) =>{
		let buildingName = "";
		if (value.length > 0) {
			buildingName = value.find(
			  (x) => x.dbColumn == "BuildingName"
			).attributeValue;
			}	
		this.setState({selectedBuilding : buildingName, reloadTenant: !this.state.reloadCompanyPopup,});
			this.state.getTenantDetails.filter((x)=>{
				if(x.dbColumn == "BuildingName")
					x.attributeValue = buildingName;
		})
		this.forceUpdate();
	};

	render() {
		const {
			getTenantDetails,
			tenantSummaryData,
			isEditEnabled,
			companyMasterDetails,
			getAllCompType,
			getMarketMaster,
			getSubMarketMaster,
			getBuildingMaster,
			getHistory,
			getHistoryDataByUser,
			getHistoryDetailByField,
			isHistoryByField,
		} = this.state;

		// let companyMasterDetail=companyMasterDetails.filter(m=>{
		//   m.companyRole.toLowerCase()=='tenant'
		// });		

		let tenantData = tenantSummaryData.filter((m) => {
            if (m.companyName != null) return m;
        });

		const filterConfig = [
			{
				multiple: true,
				type: controlType.DropDown,
				minTag: 1,
				source: tenantData,
				key: "companyName",
				placeholder: "",
				title: "Tenants",
			},
		];
		const companySeparatorConfig = [
			{
				separatorBeforeKey: "CompanyName",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Tenant Details",
			},

			{
				separatorBeforeKey: "Notes",
				order: 1,
				row: 2,
				sectionColumn: 12,
				label: "Other Details",
			},
		];

		const dropDownSource = [
			{
				key: "companyName",
				option: "companyName",
				idField: "companyId",
				source: companyMasterDetails,
				AddNewOption: IsSectionVisible(SECTIONS.Company,SECTIONS.Company_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Company",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuildingOwner,
				onChange: this.buildingOwnerChanges,
				value: this.state.selectedBuildingOwner,
			},
			{
				key: "buildingName",
				option: "buildingName",
				idField: "buildingId",
				source: getBuildingMaster,
				AddNewOption: IsSectionVisible(SECTIONS.Property,SECTIONS.Property_Details, SECTIONS.ADD_EDIT),
				AddNewOptiontitle: "New Property",
				AddOptionType: "Button",
				onAddClick: this.goToAddBuilding,
				onChange: this.buildingChanges,
				value: this.state.selectedBuilding,
			},
			{
				key: "compType",
				option: "compType",
				idField: "compTypeID",
				source: getAllCompType,
			},
		];

		const DetailsFormConfig = [
			{
				key: "compId",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "companyName",
				type: controlType.DropDown,
				multiple: true,
			},
			{
				key: "buildingName",
				type: controlType.DropDown,
				multiple: true,
			},
			{
				key: "rsf",
				type: controlType.TextBox,
				multiple: false,
			},

			{
				key: "lcd",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "lxDte",
				type: controlType.DatePicker,
				multiple: false,
			},

			{
				key: "rateAtLcd",
				type: controlType.TextBox,
				multiple: false,
			},

			{
				key: "notes",
				type: controlType.TextArea,
				multiple: false,
			},
			{
				key: "compType",
				type: controlType.DropDown,
				multiple: false,
			},
		];
		var tenantNam = "";
		if (getTenantDetails.length > 0) {
			tenantNam = getTenantDetails.find(
				(x) => x.dbColumn == "CompanyName"
			).attributeValue;
		}
		const tenantName =
			tenantNam != undefined ? tenantNam : this.props.ViewTitle;
		return (
			<>
				{/* {this.state.detailView == true ? (
					<>
						<EditBuildings
							selectedBuildingId={this.state.selectedEditRowId}
							onBackClick={this.onBackClick}
							ViewTitle={this.state.ViewTitle}
							actionType={this.state.actionType}
							isInAddMode={true}
							reloadData={this.reloadData}
							redirectionRequire={true}
							reloadBuildingData={this.reloadBuildingData}
						/>
					</> */}
				{/* ) : ( */}
				<>
					{this.state.openHistoryform && (
						<Dialog open={this.state.openHistoryform} maxWidth="lg">
							<AppBar
								style={{ position: "relative", backgroundColor: "#064473" }}
							>
								<Toolbar>
									<Typography
										variant="subtitle1"
										className="app-header-white"
										style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
									>
										Update History
									</Typography>
									<IconButton
										edge="start"
										color="inherit"
										onClick={() => {
											this.setState({
												openHistoryform: false,
												isHistoryByField: false,
											});
										}}
										aria-label="close"
										style={{padding:"0px", marginBottom:"20px"}}
									>
										<IoMdCloseCircleOutline />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div>
								<ViewHistoryData
									sourceData={getHistoryDataByUser}
									getHistoryDetailByField={getHistoryDetailByField}
									dropDownSource={dropDownSource}
									isHistoryByField={isHistoryByField}
								></ViewHistoryData>
							</div>
						</Dialog>
					)}
					{this.state.isDataSaved && this.state.isSearchedClicked && (
						<ConfirmDialog
							IsOpen={this.state.isSearchedClicked}
							Message={
								"There are unsaved changes.Do you still want to search another Tenant"
							}
							OnHandelClick={(isValid) => {
								if (isValid) {
									this.onClickHandel(isValid);
								} else {
									this.onClickHandel(isValid);
								}
							}}
							AgreeText="Yes"
							DisAgreeText="No"
						></ConfirmDialog>
					)}

					{this.state.openaddform && (
						<Dialog open={this.state.openaddform} maxWidth="lg">
							<AppBar
								style={{ position: "relative", backgroundColor: "#064473" }}
							>
								<Toolbar>
									<Typography
										variant="subtitle1"
										className="app-header-white"
										style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
									>
										{this.state.addformtitle}
									</Typography>
									<IconButton
										edge="start"
										color="inherit"
										onClick={() => {
											this.reloadPropertyData();
										}}
										aria-label="close"
										style={{padding:"0px", marginBottom:"20px"}}
									>
										<IoMdCloseCircleOutline />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div>
								<EditBuildings
								 globalMarketId={this.props.globalMarketId}
									selectedBuildingId={this.state.selectedEditPropertyRowId}
									onBackClick={this.onPropertyPopupBackClick}
									ViewTitle={this.state.ViewTitle}
									actionType={this.state.actionType}
									isInAddMode={true}
									reloadData={this.reloadData}
									isRedirectedFromTenant={true}
									reloadPropertyData={this.reloadPropertyData}
									getSubMarketMaster={getSubMarketMaster}
              getMarketMaster={getMarketMaster}
              companyMasterDetails={companyMasterDetails}
			  setBuilding={this.setBuilding}
			 // isPropertyOfInterest={true}
								/>
							</div>
						</Dialog>
					)}

					{this.state.openPropertyOwnerAddForm && (
						<Dialog open={this.state.openPropertyOwnerAddForm} maxWidth="lg">
							<AppBar
								style={{ position: "relative", backgroundColor: "#064473" }}
							>
								<Toolbar>
									<Typography
										variant="subtitle1"
										className="app-header-white"
										style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
									>
										{this.state.addformtitle}
									</Typography>
									<IconButton
										edge="start"
										color="inherit"
										onClick={() => {
											this.reloadPropertyOwnerData();
										}}
										aria-label="close"
										style={{padding:"0px", marginBottom:"20px"}}
									>
										<IoMdCloseCircleOutline />
									</IconButton>
								</Toolbar>
							</AppBar>
							<div>
								<EditBuildingOwners
								 globalMarketId={this.props.globalMarketId}
									selectedBuildingId={this.state.selectedEditRowId}
									onBackClick={this.onPopupBackClick}
									ViewTitle={this.state.ViewTitle}
									actionType={this.state.actionType}
									isInAddMode={true}
									reloadData={this.reloadData}
									isRedirectedFromTenant={true}
									reloadPropertyOwnerData={this.reloadPropertyOwnerData}
									// companyMasterDetails={companyMasterDetails}
									setBuildingOwner={this.setBuildingOwner}
								/>
							</div>
						</Dialog>
					)}

					{isEditEnabled || this.props.isInAddMode ? (
						<div class="row header-container">
							<div className="col-md-4 col-lg-4">
								<PageHeader
									btnText={"Tenants"}
									onBackClick={this.onBackClick}
									title={
										this.props.isInAddMode
											? "/ " + this.props.ViewTitle
											: "/ " + tenantName
									}
									style={{ color: "#00B0B9" }}
								></PageHeader>
							</div>

							<div className="col-md-4 col-lg-4">
								{tenantSummaryData.length > 0 && this.state.showEdit == true && (
									<AutoSearch
										placeholder="Search another tenant"
										isEditEnabled={this.state.isEditEnabled}
										sourceData={tenantData}
										filterConfig={filterConfig}
										onSearch={this.onSearch}
									></AutoSearch>
								)}
								{/* <SearchBar></SearchBar> */}
							</div>
							<div className="col-md-4 col-lg-4 end-sm">
							{this.state.showEdit == true && (
								<>
								<Button
									size="small"
									style={{ marginTop: "5px" }}
									className="btn-clear"
									onClick={() => {
										this.setState({
											getTenantDetails: this.state.getTenantDetailsResponse,
											reloadTenant: !this.state.reloadTenant,
										});
									}}
								>
									<span className="tool-icon-text">Clear All</span>
								</Button>
								<Button
									size="small"
									className="notop-action-button"
									onClick={() => {
										this.props.onBackClick();
									}}
								>
									<span className="tool-icon-text">Cancel</span>
								</Button>
								{ IsSectionVisible(SECTIONS.Tenant,SECTIONS.Tenant_Details, SECTIONS.ADD_EDIT) &&
									<Button
									size="small"
									className="notop-action-button"
									onClick={() => {
										this.saveTenantDetails();
									}}
								>
									<span className="tool-icon-text">Save</span>
								</Button>
	}
							</>
							)}
							</div>
						</div>
					) : (
						<div className="row header-container">
							<div className="col-md-4 col-lg-4">
								<PageHeader
									btnText={"Tenants"}
									onBackClick={this.onBackClick}
									title={"/ " + tenantName}
									style={{ color: "#00B0B9" }}
								></PageHeader>
							</div>
							<div className="col-md-5 col-lg-5">
								{tenantSummaryData.length > 0 && this.state.showEdit == true &&  (
									<AutoSearch
										placeholder="Search another tenant"
										isEditEnabled={this.state.isEditEnabled}
										sourceData={tenantData}
										filterConfig={filterConfig}
										onSearch={this.onSearch}
									></AutoSearch>
								)}
								{/* <SearchBar></SearchBar> */}
							</div>

							<div className="col-md-3 col-lg-3 end-sm">
							{this.state.showEdit == true && (
							<>
								<Button
									size="small"
									className="notop-action-button"
									onClick={() => {
										this.props.onBackClick();
									}}
								>
									<span className="tool-icon-text">Cancel</span>
								</Button>
								{ IsSectionVisible(SECTIONS.Tenant,SECTIONS.Tenant_Details, SECTIONS.ADD_EDIT) &&
							<Button
									size="small"
									className="notop-action-button"
									onClick={() => {
										this.enableEdit();
									}}
								>
									<span className="tool-icon-text">Edit</span>
								</Button>
	}
							</>)}
							</div>
						</div>
					)}
				 {getHistory.length > 0 && (
          <div className="row header-container">
            <div className="col-sm-8 col-lg-8 tabs-nomargin">
						{!this.props.isInAddMode && (
							<><div>
								<Tooltip title="Show Company">
									{this.state.leaseCompSummaryData && <a
										className={this.state.showEdit ? 'tab-capsule selected' : 'tab-capsule'} //"tab-capsule selected"
										
										aria-label={"refreshData"}
										size="small"
										onClick={() => {
											this.setState({
												showLeaseComps: false,
												showEdit: true,												
											});

										}}
									>
										<span style={{ color:'white' }}>
										Tenant
										</span>
									</a>}
								</Tooltip>
							</div><div>
									<Tooltip title="Show Lease Comps">
										{this.state.leaseCompSummaryData && <a
											className={this.state.showLeaseComps ? 'tab-capsule selected' : 'tab-capsule'} //"tab-capsule"                  
											aria-label={"refreshData"}
											size="small"
											onClick={() => {
												this.setState({
													showLeaseComps: true,
													showEdit: false
													
												});

											}}
										>
											<span style={{ color:'white' }}>
												Lease Comps
											</span>
										</a>}
									</Tooltip>
								</div>								
							</>
						)}
					</div>

				{	this.state.showEdit == true && (
					<>
            <div
              className="col-md-1 col-lg-1"
			  style={{ padding: "8px" }}
            >
              <span>Last Modified:</span>
            </div>
            <div
             className="col-md-3 col-lg-3 end-sm"
              // style={{ marginTop: "9px", marginLeft: "8px" }}
            >
              <Autocomplete
               // style={{ width: "374px" }}
                size="small"
                underlineStyle={{ display: "none" }}
                //value={}
                // defaultValue={"Select Record" }
				defaultValue={{createdBy: getHistory[0].createdBy 
					, createdDate :
					moment(getHistory[0].createdDate)
						.format("MM-DD-YYYY HH:mm")}}
                onChange={(e, val) => this.handleChange(e, val)}
                id="tags-standard"
                options={getHistory}
                getOptionLabel={(option) =>
                  option.createdBy +
                  "," +
                  moment(option.createdDate).format("MM-DD-YYYY HH:mm:ss")
                }
                renderInput={(params) => (
                  // <Box
                  //   sx={{ display: "flex", alignItems: "center" }}
                  //   className="filter-label"
                  // >
                  //   <label htmlFor="filled-hidden-label-small">
                  //
                  //   </label>
                  <TextField
                    {...params}
                    variant="outlined"
                    margin="normal"
					defaultValue={{createdBy: getHistory[0].createdBy 
						, createdDate :
						moment(getHistory[0].createdDate)
							.format("MM-DD-YYYY HH:mm")}}
                    hiddenLabel
                  />
                  // </Box>
                )}
              />
              {/* <SimpleSelect></SimpleSelect> */}
            </div>
				</> )}

          </div>
        )}
					
					<div className="row table-list-container">
						{getTenantDetails && this.state.showEdit == true && (
							<FormGenerator
								gridColumn={4}
								getHistoryDataByField={this.getHistoryDataByField}
								ReloadForm={this.state.reloadTenant}
								formFields={UpdateFieldsWithConfiguration(
									getTenantDetails,
									DetailsFormConfig
								)}
								dropDownSource={dropDownSource}
								validationMessages={[]}
								partialForm={true}
								saveChanges={this.getTenantChanges}
								Editable={this.state.isEditEnabled}
								viewPDF={[]}
								formType={"Section"}
								separatorConfig={companySeparatorConfig}
								sectionRowConfig={[
									{ row: 1, column: 12 },
									{ row: 2, column: 4 },
									{ row: 3, column: 8 },
									//  {row:3,column:4,labelAlign:'alignText-Right'},
									//  {row:2,column:4,labelAlign:'alignText-Right'},
								]}
								otherChildsection={
									[
										// {
										//   label: "",
										//   element: getTenantDetails.length > 0 && (
										//     <ImageSlider></ImageSlider>
										//   ),
										//   row: 3,
										// },
									]
								}
							></FormGenerator>
						)}
					</div>

					
{this.state.openCompAddform && (
					<Dialog open={this.state.openCompAddform} maxWidth="lg">
						<AppBar
							style={{ position: "relative", backgroundColor: "#064473" }}
						>
							<Toolbar>
								<Typography
									variant="subtitle1"
									className="app-header-white"
									style={{ marginLeft: "0px", flex: "1", color: "#FFFFF" }}
								>
									Edit Lease Comp
								</Typography>
								<IconButton
									edge="start"
									color="inherit"
									onClick={() => {
										this.setState({
											openCompAddform:false,
										});
										this.forceUpdate();
									}}
									aria-label="close"
									style={{padding:"0px", marginBottom:"20px"}}
								>
									<IoMdCloseCircleOutline />
								</IconButton>
							</Toolbar>
						</AppBar>
						<div>
							<AddEditLeaseComps
								selectedCompId={this.state.selectedTenantRowId}
								onBackClick={this.onTenantBackClick}
								ViewTitle={this.state.ViewLeaseTitle}
							actionType={this.state.actionLeaseType}
							isInAddMode={this.state.isLeaseInAddMode}
								//reloadData={this.reloadData}
								isRedirectedFromTenantTab={true}
								getSubMarketMaster={getSubMarketMaster}
							getMarketMaster={getMarketMaster}
							getBuildingMaster={getBuildingMaster}
							// getALLNNNFS={getALLNNNFS}
							//getAllLeaseType={getAllLeaseType}
							getAllCompType={getAllCompType}
							//leaseCompsSummaryData={leaseCompsSummaryData}
								//reloadPropertyData={this.reloadPropertyData}
							/>
						</div>
					</Dialog>
				)}

					{this.state.leaseCompSummaryData && this.state.showLeaseComps == true && (
					<div className="row" style={{ marginTop: "0px" }}>
						<div className="col-sm-12 col-lg-12">
							<GridTable
								validationMessage={[]}
								columns={UpdateColumnWithConfiguration(
									leaseCompsColumnsForDetails,
									this.state.configuration,
									[]
								)}
								renderActionColumn={this.renderActionColumn}
								reload={this.state.reloadTable}
								data={this.state.leaseCompSummaryData}
							></GridTable>
						</div>
					</div>
				)}
				</>
			</>
		);
	}
}

import React from "react";
import cx from "classnames";
import "./index.less";

export default function SummaryCard({
	onClick,
	cardData: { header, cardKey, group1, group2, boxBorder }
}) {
	return (
		<div className="row">
			<div className="col-xs no-padding">
				<div
					className={cx("card-main-body", boxBorder)}
					onClick={e => onClick(cardKey)}
				>
					<div className="col-xs no-padding card-header">
						<label className="card-header-title">{header}</label>
					</div>
					<hr />
					<div className="col-xs-12 no-padding">
						{group1.map((field, i) => {
							return (
								<div
									className="row start-xs row-data"
									key={`${header}_${field.label}`}
								>
									<div className="col-xs-12 no-padding card-row">
										<span className="fieldLabel">{field.label}</span>
										<span className="pull-right">{field.value}</span>
									</div>
								</div>
							);
						})}
						<div className="row start-xs row-data">
							<div className="col-xs-12 no-padding card-row">
								<hr className="light-hr" />
							</div>
						</div>
						{group2.map((field, i) => {
							return (
								<div
									className={cx("row start-xs row-data", field.textColor)}
									key={`${header}_${field.label}`}
								>
									<div className="col-xs-12 no-padding card-row">
										<span className="fieldLabel">{field.label}</span>
										<span className="pull-right">{field.value}</span>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

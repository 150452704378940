import React from "react";
import "./fundwise.less";
import { remove } from "lodash-es/array";
import PropertyLandingScreen from "./property-landing";

export default React.memo(function FundWiseLandingView({
	columnList,
	onRowClick,
	tableData,
	selectedDate,
	fundKey,
	//fundsNameList,
	getCardData,
	onOpenPopup,
	downloadExcel,
	showAddRecord = false,
	propertyInfo,
	showPropertyFilter = false,
	showAddProperty = false,
	NoDataInitial = false,
	rowEditAction
}) {
	// function getColumnList(colList, fundKey) {
	// 	const cList = colList.slice(0);
	// 	fundKey !== 100 && remove(cList, { accessor: "parentFundDisplayName" });
	// 	return cList;
	// }
	return (
		<>
			<div
				id="stickytypeheader"
				className="row content start-xs start-sm start-md start-lg"
			>
				<div className="content col-xs-12 col-md-12 col-md-12 col-lg-12">
					<PropertyLandingScreen
						columnList={columnList}
						tableData={tableData}
						jumpToPage={onRowClick}
						selectedDate={selectedDate}
						fundKey={fundKey}
						//fundsNameList={fundsNameList}
						getCardData={getCardData}
						onOpenPopup={onOpenPopup}
						downloadExcel={downloadExcel}
						showAddRecord={showAddRecord}
						showAddProperty={showAddProperty}
						propertyInfo={propertyInfo}
						showPropertyFilter={showPropertyFilter}
						noDataMsg={
							NoDataInitial
								? "No data available."
								: "Please Select Market/Major Service Area."
						}
						rowEditAction={rowEditAction}
						groupingKey={["propertyName"]}
					/>
				</div>
			</div>
		</>
	);
});

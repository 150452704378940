import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import './style.less';
import PropTypes from 'prop-types';
import { routes } from '../../constants/routes';
import Dashboard from '../../components/Dashboard/DashBoard';
import { pure } from 'recompose';
import ErrorPage from '../ErrorPage';
import commonLabels from '../../constants/messageConstants';
import Buildings from '../../components/Buildings/Buildings';
import Companies from '../../components/Companies/Companies';
import BuildingOwners from '../../components/BuildingOwners/BuildingOwners';
import Tenants from '../../components/Tenants/Tenants';
import LeaseComps from '../../components/LeaseComps/LeaseComps';
import SalesComps from '../../components/SalesComps/SalesComps';
import SpecSuite from '../../components/SpecSuite/SpecSuite';
import Reports from '../../components/Reports/Reports';
import Building from '../../components/Industrial/Building/Building';


import IndBuildingOwners from '../../components/Industrial/BuildingOwner/IndBuildingOwners';
import IndLeaseComps from '../../components/Industrial/LeaseComps/IndLeaseComps';
import IndSalescomps from '../../components/Industrial/SalesComps/IndSalesComps.js'
import IndTenants from '../../components/Industrial/Tenant/IndTenants';
import IndReports from '../../components/Industrial/Reports/IndReports';
import IndustrialDashboard from '../../components/Industrial/Dashboard/IndustrialDashboard';
import Configurations from '../../components/Configuration/Configuration';
import { IsSectionVisible } from '../../utils/helper';
import { SECTIONS } from '../../constants/appConstants';
import AboutUs from '../aboutUs';

import IndConfigurations from '../../components/Industrial/Configuration/IndConfiguration';
import SpecSuiteDashboard from '../../components/SpecSuite/SpecSuiteDashboard';
import industrialAboutUs from '../aboutUs/industrialAboutUs';
import ChartPoc from '../../components/POC/ChartPoc';
import OfficeStatistics from '../../components/POC/DataSheetPoc';
import CompSets from '../../components/CompSets/CompSets';
import RcaLoan from '../../components/RcaLoan/RcaLoan';
// import BlankPage from '../ErrorPage/BlankFile';

WorkSpace.propTypes = {
  defaultPage: PropTypes.string.isRequired
};
const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return React.createElement(component, finalProps);
};

const PropsRoute = ({ component, ...rest }) => {  
//  console.log(rest, window.location);
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return renderMergedProps(component, routeProps, rest);
      }}
    />
  );
};
PropsRoute.propTypes = {
  component: PropTypes.element,
  globalMarketId:PropTypes.string
};

function WorkSpace({ defaultPage,globalMarketId }) {
  console.log(defaultPage);
  return (
    <>
      <Switch>
        <Redirect exact from="/" to={`/${routes.buildings}`} />
        <PropsRoute
          path={`/${routes.error}`}
          component={ErrorPage}
          details={commonLabels.serverError}

        />
        <PropsRoute
          path={`/${routes.unAuthorizedAccess}`}
          component={ErrorPage}
          details={commonLabels.unAuthorizedAccess}
        />
        {/* <PropsRoute
          path={`/${routes.blankPage}`}
          component={BlankPage}
         
        /> */}
        <PropsRoute
          path={`/${routes.notfound}`}
          component={ErrorPage}
          details={commonLabels.pageNotFound}
        />
        {/* <PropsRoute path={`/${routes.dashboard}`} component={Dashboard} globalMarketId={globalMarketId} /> */}
        <PropsRoute
          path={`/${routes.buildings}`}
          component={Buildings}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.companies}`}
          component={Companies}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.buildingowners}`}
          component={BuildingOwners}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.tenants}`}
          component={Tenants}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.leasecomps}`}
          component={LeaseComps}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.salescomps}`}
          component={SalesComps}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.specsuite}`}
          component={SpecSuite}
          globalMarketId={globalMarketId}
        />
		<PropsRoute
          path={`/${routes.specsuitedashboard}`}
          component={SpecSuiteDashboard}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.reports}`}
          component={Reports}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.building}`}
          component={Building}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.indbuildingowners}`}
          component={IndBuildingOwners}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.indleasecomps}`}
          component={IndLeaseComps}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.indsalescomps}`}
          component={IndSalescomps}
          globalMarketId={globalMarketId}
        />

        <PropsRoute
          path={`/${routes.indtenants}`}
          component={IndTenants}
          globalMarketId={globalMarketId}
        />
         <PropsRoute
          path={`/${routes.indreports}`}
          component={IndReports}
          globalMarketId={globalMarketId}
        />
         <PropsRoute
          path={`/${routes.inddashboard}`}
          component={IndustrialDashboard}
          globalMarketId={globalMarketId}
        />
         
         <PropsRoute
         
          //  path={`/${IsSectionVisible(SECTIONS.Configuration)
          //   ? routes.officeconfiguration
          //   : routes.blankPage
          //   }`}
          // component={IsSectionVisible(SECTIONS.Configuration) ? Configurations : BlankPage}
          // globalMarketId={globalMarketId}
          path={`/${routes.officeconfiguration}`}
          component={Configurations}
          globalMarketId={globalMarketId}
        />

		<PropsRoute
			path={`/${routes.indconfiguration}`}
			component={IndConfigurations}
          	globalMarketId={globalMarketId}
		/>

<PropsRoute
           path={`/${routes.aboutUs}`}
           component={AboutUs}
        />
        <PropsRoute
           path={`/${routes.industrialAboutUs}`}
           component={industrialAboutUs}
        />
        <PropsRoute
          path={`/${routes.chartPoc}`}
          component={ChartPoc}
         
        />
        <PropsRoute
          path={`/${routes.OfficeStatistics}`}
          component={OfficeStatistics}
          globalMarketId={globalMarketId}
        />
        <PropsRoute
          path={`/${routes.CompSets}`}
          component={CompSets}
          globalMarketId={globalMarketId}
        />
         <PropsRoute
          path={`/${routes.RcaLoan}`}
          component={RcaLoan}
          globalMarketId={globalMarketId}
        />
        {/* <PropsRoute
          path={`/${routes.company}`}
          component={Company }
        /> */}
        {/* <PropsRoute
            path={`/${
              IsSectionVisible(SECTIONS.MARKETS)
                ? routes.market
                : routes.unAuthorizedAccess
            }`}
            component={IsSectionVisible(SECTIONS.MARKETS) ? Market : ErrorPage}
        />
        <PropsRoute
          path={`/${
            IsSectionVisible(SECTIONS.EMPLOYEES)
              ? routes.employee
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.EMPLOYEES) ? Employees : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.PROPERTIES)
              ? routes.property
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.PROPERTIES) ? Property : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.PROPERTY_TRANSITION)
              ? routes.transition
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.PROPERTY_TRANSITION) ? Transition : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.ASSIGNMENTS)
              ? routes.assignments
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.ASSIGNMENTS) ? Assignments : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.ALLOCATIONS)
              ? routes.allocations
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.ALLOCATIONS) ? Allocations : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.CONFIGURATION)
              ? routes.configurations
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.CONFIGURATION) ? Configurations : ErrorPage}
        />
         <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.DASHBOARD, SECTIONS.PENDING_ACTION)
              ? routes.pendingRequests
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.DASHBOARD, SECTIONS.PENDING_ACTION) ? PendingRequest : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.REIMBURSEMENT_VARIANCE_ANALYSIS)
              ? routes.reimbursementVarianceAnalysis
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.REIMBURSEMENT_VARIANCE_ANALYSIS) ? ReimbursementVarianceAnalysis : ErrorPage}
        />
        <PropsRoute
           path={`/${
            IsSectionVisible(SECTIONS.REPORTS)
              ? routes.reports
              : routes.unAuthorizedAccess
          }`}
          component={IsSectionVisible(SECTIONS.REIMBURSEMENT_VARIANCE_ANALYSIS) ? Reports : ErrorPage}
        /> */}
        <Route
          component={() => <ErrorPage details={commonLabels.pageNotFound} />}
        />
      </Switch>
      {/* <IdleSessionTimer /> */}
    </>
  );
}
export default pure(WorkSpace);

/* eslint-disable quotes */
export const routes = {
   unAuthorizedAccess: "unAuthorizedAccess",
   notfound: "notfound",
   error: "error",
   aboutUs: "aboutUs",
  
  //dashboard: "officedashboard",
  buildings:"officeproperties",
  companies:"officecompanies1",
  buildingowners:"officecompanies",
  tenants:"officetenants",
  leasecomps:"officeleasecomps",
  salescomps:"officesalescomps",
  specsuite:"officespecsuite",
  specsuitedashboard:"officespecsuitedashboard",
  reports:"officereports",
  building:"industrialproperties",
  company:"industrialcompanies",
  indbuildingowners:"industrialpropertyowners",  
  indleasecomps:"industrialleasecomps",
  indsalescomps:"industrialsalescomps",
  indtenants:"industrialtenants",
  indreports:"industrialreports",
  inddashboard:"industrialdashboard",
  officeconfiguration:"officeconfiguration",
  indconfiguration:"industrialconfiguration",
  industrialAboutUs:"IndustrialAboutUs",
 // blankPage:"blankPage",
 chartPoc:"chartPoc",
 OfficeStatistics:'OfficeStatistics',
 CompSets:'CompSets',
 RcaLoan:'RcaLoan'
};

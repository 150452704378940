import React from "react";
import "./style.less";

export default function Select({
	optionData = [],
	onChange,
	disabled,
	valueKey,
	defaultText = "----Select----",
	defaultValue = 0,
	isSelectEmpty = false,
	extraParam = {}
}) {
	return (
		<select
			className="select-css"
			onChange={e => onChange(e.target.value, valueKey, extraParam)}
			disabled={disabled}
			value={defaultValue}
		>
			{(!defaultValue || isSelectEmpty) && (
				<option value="">{defaultText}</option>
			)}
			{optionData &&
				optionData.map((item, i) => (
					<option key={i} value={item.id}>
						{item.value}
					</option>
				))}
		</select>
	);
}

import {
	AppBar,
	Button,
	Dialog,
	IconButton,
	Paper,
	Toolbar,
	Typography,
	Box,
	TextField,
	Tooltip,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../common/PageHeader";
import { apiBatchRequest, apiBatchRequestWithoutLoader, apiPost, apiPut, apiRequest } from "../../utils/api_service";
import commonLabels from "../../constants/messageConstants";
import { isGridFormValid, isInputValid, IsSectionVisible, UpdateFieldsWithConfiguration } from "../../utils/helper";
import FormGenerator from "../common/FormGenerator";
import { API_URL } from "../../constants/api";
import { controlType } from "../../constants/common";
import camelcaseKeys from "camelcase-keys";

import { ShowToast, toastType } from "../../utils/toast-service";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
import { SECTIONS } from "../../constants/appConstants";
import AutoSearch from "../common/AutoSearch/AutoSearch";

export default class EditBuildingsDetails extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedMarketId:this.props.globalMarketId,
			getBuildingDetails: [],
			isEditEnabled: this.props.isInAddMode,
            isCostarIdChecked:false,
            buildingSummaryData:this.props.buildingSummaryData,
            selectedCoStarId:0,
				getBuildingDetailsResponse: [],
				isDataSaved: false,
			isSearchedClicked: false,
			showConfirmDialog: false,
			//buildingId: -1,			
			configuration: [],
			reloadTable: false,
			showEdit: true,
			detailView: false,
			selectedEditRowId: -1,
			selectedBuildingId:-1,
			buildingId:this.props.selectedBuildingId,
			ViewLeaseTitle:"Edit",
			actionLeaseType:"Edit",
			ViewTitle: "Add",
			actionType: "Add",
			reloadBuilding: false,
			openaddform:false,
			openCompAddform:false,
coStarData:[],
			
		};
	}

	enableEdit = () => {
        if(this.state.selectedCoStarId !=0 
            && this.state.selectedCoStarId !=null
             && this.state.selectedCoStarId != undefined 
             && !isNaN(this.state.selectedCoStarId) )
             {
        this.getCostarDetail(this.state.selectedCoStarId);		
             }
             else
             {
                ShowToast('Please enter valid CoStarId', toastType.ERROR, 5000, 500);
             }
	};
	componentWillReceiveProps(nextProps) {    
		if (nextProps.globalMarketId !== this.state.selectedMarketId) {      
		 // this.getMarketSubMarket(nextProps.globalMarketId);
		  this.setState({ selectedMarketId: nextProps.globalMarketId });
		}
	  }
	onBackClick = () => {
		this.props.onBackClick();
	};

	

	getBuildingChanges = (formFields) => {
        const CostarId = formFields.filter(
            (x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
        );
		this.setState(
			{
				getBuildingDetails: formFields,
                isDataSaved: true,
				selectedCoStarId:CostarId[0].attributeValue,
				reloadBuilding: !this.state.reloadBuilding,
			},
			() => {
				this.forceUpdate();
			}
		);

		// if(this.props.isRedirectedFromReport != undefined && this.props.isRedirectedFromReport == true)
		// {
		// 	const CostarId = formFields.filter(
		// 		(x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
		// 	);
        //    let x=this.getCostarDetail(CostarId[0].attributeValue);
		// }
	};

	validateForm = () => {
		const buildingData = this.state.getBuildingDetails;
let isValid=true;
let breakCondition=false;
if(buildingData.length ==0 )
		{
			const msg = "No change detected. Please make necessary changes and try again.";
			isValid = false;
			breakCondition = true;
			ShowToast(msg, toastType.ERROR, 5000, 500);
		}
		buildingData.forEach((x) => {
			if (x.isRequired == true && ( x.attributeValue == null || x.attributeValue == undefined || x.attributeValue.trim() == "" ) && breakCondition != true)
				
			{
					const msg = "Please fill all the mandatory fields";
					isValid=false;
					breakCondition=true;
					ShowToast(msg, toastType.ERROR, 5000, 500);
				
			}
			
			
		});
		return isValid;
	};
	isValidForm = () => {
		let IsValid = true;
		const {  getBuildingDetails } = this.state;
		getBuildingDetails.forEach((ele) => {
		  isGridFormValid(ele.attributeValue, ele, getBuildingDetails);
		  if (ele.isInErrorState === true) {
			IsValid = false;
		  }
		});
		this.setState({ getBuildingDetails }, this.forceUpdate());
		return IsValid;
	  };
	  validateRequiredFields = (formElementFields, stateToBeUpdate = {}) => {
		//console.log(formElementFields[0].attributeValue);
		let IsValid = true;
		formElementFields.forEach((ele) => {
			isInputValid(ele.attributeValue, ele, this.state.getBuildingDetails);
			if (ele.isInErrorState === true) {
				IsValid = false;
			}
		});
	
		stateToBeUpdate = {
			...stateToBeUpdate,
			getBuildingDetails: formElementFields,
			reloadBuilding: !this.state.reloadBuilding,
		};
		this.setState(stateToBeUpdate);

		return IsValid;
	};

	getCostarDetail =(CostarId)=>
	{
		const params={
			// propertyId: 6,
			costarId: CostarId,
			businessLineId :1
		  };
		 apiRequest(
			{
			url: API_URL.Building.checkIfCoStarExist,
			params
			
		  }
		).then(({data:coStarData}) => {				
				this.setState({
				  coStarData: camelcaseKeys(coStarData) || [],
                  //isEditEnabled: false,
                  isCostarIdChecked:true,
				  reloadBuilding:!this.state.reloadBuilding,
				});
                if(coStarData.length>0)
{
    this.setState({
		openCoStarConfirmDialog :true
	   });
}
else{
    this.saveBuildingDetails();
}
			
			})
			.catch((reason) => {
			  this._asyncRequest = null;
			  ShowToast(commonLabels.somethingWrong, toastType.ERROR, 5000, 500, 'Server error');
			});

			return null;
	}
	saveBuildingDetails = () => {
		const buildingData = this.state.getBuildingDetails;
		const CostarId = buildingData.filter(
			(x) => _.camelCase(x.dbColumn) === _.camelCase("CoStarPropID")
		);
  
	const params={
		OldBuildingId:-1,
		NewBuildingId:this.state.buildingId,
		CoStarPropId:parseInt(CostarId[0].attributeValue),
	}
	   this.saveBuildingDataForReport(params);
  

	};

	saveBuildingDataForReport=(params)=>
	{	
		apiPut(API_URL.Building.UpdateCoStarId, params
		)
			.then(({ data: response }) => {
				if (response.success === true) {
					ShowToast(
						"Property updated successfully",
						toastType.SUCCESS,
						5000,
						500
					);
					this.setState({
						isDataSaved: false,
					});
					this.props.onBackClick();
				} else {
					ShowToast("Error", toastType.ERROR, 5000, 500);
				}
			})
			.catch(() => {
				ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
			});

	}
	getData = (buildingId) => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getBuildingDetailsForQAReport,
				key: "getBuildingDetails",
				params: { buildingID: buildingId },
					
			},
		])
			.then((getBuildingDetails) => {
				this._asyncRequest = null;
				if (getBuildingDetails.getBuildingDetails.success == true) {
					this.setState({
						getBuildingDetails:
							camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
							getHistory:
							camelcaseKeys(
								getBuildingDetails.getBuildingDetails.configuration
							) || [],
					});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	};

	componentDidMount() {
		this.getPropertyDetails();
			
	}

	getPropertyDetails = () => {
		this._asyncRequest = apiBatchRequest([
			{
				url: API_URL.Building.getBuildingDetailsForQAReport,
				key: "getBuildingDetails",
				params: { buildingID: this.props.selectedBuildingId  },
					// fromCache: true,
					// duration: 60
			},
			// {
			// 	url: API_URL.Building.buildingSummaryData,
			// 	key: "buildingSummaryData",
			// 	params: { companyId: '-1',marketId: this.state.selectedMarketId  },
			// 	fromCache: true,
			// 	duration: 60
			// },	
		])
			.then((getBuildingDetails) => {
				this._asyncRequest = null;				
				if (getBuildingDetails.getBuildingDetails.success == true) {
					
					this.setState({
						getBuildingDetails:
							camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
						getHistory:
							camelcaseKeys(
								getBuildingDetails.getBuildingDetails.configuration
							) || [],
						getBuildingDetailsResponse:
							camelcaseKeys(getBuildingDetails.getBuildingDetails.data) || [],
                            // buildingSummaryData:
                            // camelcaseKeys(getBuildingDetails.buildingSummaryData.data) || [],
                    
                            reloadBuilding:!this.state.reloadBuilding,
					});
                  //  this.getBuildingSummaryMasterData();
					this.forceUpdate();
					
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	}

    onSearch = (event, value, e) => {
		const { isDataSaved } = this.state;
		let buildingId = this.state.buildingSummaryData.find(
			(m) => m.property == value
		).buildingId;
		this.setState({
			buildingId: buildingId,
			isSearchedClicked: true,
		});

		if (isDataSaved == false) {
			
			this.getData(buildingId);
		
			//this.getTenantData(buildingId);
			//this.getHistoricalData(buildingId);
		}
		//console.log(event);
	};
	onSearchBuilding = () => {
		this.setState({
			isSearchedClicked: false,
			isDataSaved: false,
		});
		
		this.getData(this.state.buildingId);
	};
	onClickHandel(e) {
		if (e == true) {
			this.onSearchBuilding();
			this.setState({
				isSearchedClicked: false,
			});
		} else {
			this.setState({
				isSearchedClicked: false,
			});
		}
	}
	onClickHandelCostar(e) {
		const params={
			OldBuildingId:this.state.coStarData[0].buildingId,
			NewBuildingId:this.state.buildingId,
			CoStarPropId:this.state.coStarData[0].costarPropId,
		}
		if (e == true) {
			this.saveBuildingDataForReport(params);
			this.setState({
				openCoStarConfirmDialog: false,
			});
		} else {
			this.setState({
				openCoStarConfirmDialog: false,
			});
		}
	}

	onPopupBackClick = () => {
		this.setState({ detailView: false, selectedEditRowId: -1 });
		if (this.state.openaddform == true) {
			this.setState({ openaddform: false }, () => {
				this.forceUpdate();
			});
		}
	};
	getBuildingSummaryMasterData ()
	{
		this._asyncRequest = apiBatchRequestWithoutLoader([
			
			{
				url: API_URL.Building.buildingSummaryData,
				key: "buildingSummaryData",
				params: { companyId: '-1',marketId: this.state.selectedMarketId  },
				fromCache: true,
				duration: 60
			},				
			
		])
			.then((buildingSummaryData) => {
				this._asyncRequest = null;
				if (buildingSummaryData.buildingSummaryData.success == true) {
					this.setState({
						 buildingSummaryData:
						camelcaseKeys(buildingMasterData.buildingSummaryData.data) || [],
					reloadBuilding: !this.state.reloadBuilding,
							
						});
					this.forceUpdate();
				} else {
					ShowToast(
						"Something went wrong",
						toastType.ERROR,
						5000,
						500,
						"Server error"
					);
				}
			})
			.catch(() => {
				this._asyncRequest = null;
			});
	
	}
	render() {
		const {
			
			getBuildingDetails,
			isEditEnabled,	
            isCostarIdChecked,
            buildingSummaryData		
		
		} = this.state;
        const filterConfig = [
			{
				multiple: false,
				type: controlType.DropDown,
				minTag: 1,
				source: buildingSummaryData,
				key: "property",
				placeholder: "",
				title: "Property",
			},
		];
		const companySeparatorConfig = [
			{
				separatorBeforeKey: "BuildingName",
				order: 1,
				row: 1,
				sectionColumn: 3,
				label: "Stream Market Details",
				sizeConfig: {
					labelSize: 7,
					controlSize: 5,
				},
			},
			
			// {
			// 	separatorBeforeKey: "CompanyName",
			// 	order: 1,
			// 	row: 2,
			// 	sectionColumn: 3,
			// 	label: "Ownership & Management",
			// },
			// {
			// 	separatorBeforeKey: "Add1",
			// 	order: 1,
			// 	row: 3,
			// 	sectionColumn: 3,
			// 	label: "Location Details",
			// },
			// {
			// 	separatorBeforeKey: "Tenancy",
			// 	order: 1,
			// 	row: 4,
			// 	sectionColumn: 3,
			// 	label: "Asset Details",
			// },
			{
				separatorBeforeKey: "CoStarMarket",
				order: 1,
				row: 5,
				sectionColumn: 3,
				label: "Imported Details",
			},
			//{
			//	separatorBeforeKey: "BuildingNotes",
			//	order: 1,
			//	row: 1,
			//	sectionColumn: 3,
			//	label: "Other Details",
			//},
				
			
		];

		const dropDownSource = [
			
			
			// {
			// 	key: "market",
			// 	option: "market",
			// 	idField: "marketId",
			// 	source: getMarketMaster,
			// },
			// {
			// 	key: "subMarket",
			// 	option: "subMarket",
			// 	idField: "subMarketId",
			// 	source: getSubMarketMaster,
			// 	filterSourceOn: [{ key: "marketId", filterBy: "marketId" }],
			// 	applyFilter: true,
			// },
			
		];

		const DetailsFormConfig = [
			
			
			{
				key: "buildingName",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "add1",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "add2",
				type: controlType.TextBox,
				multiple: false,
			},
			
			{
				key: "city",
				type: controlType.TextBox,
				multiple: false,
				//columnWidth:2
			},
			{
				key: "state",
				type: controlType.TextBox,
				multiple: false,
				//columnWidth:2
			},
		
			{
				key: "market",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "subMarket",
				type: controlType.TextBox,
				multiple: false,
			},
			
			{
				key: "propertyType",
				type: controlType.TextBox,
				multiple: false,
			},
		
			{
				key: "coStarSubmarket",
				type: controlType.TextBox,
				multiple: false,
			},
			{
				key: "coStarMarket",
				type: controlType.TextBox,
				multiple: false,
			},
			
		];
		var buildingNam = "";
		if (getBuildingDetails.length > 0) {
			buildingNam = getBuildingDetails.find(
				(x) => x.dbColumn == "BuildingName"
			).attributeValue;
		}
		const buildingName =
			buildingNam != undefined ? buildingNam : this.props.ViewTitle;
	
		return (
			<>		
	{this.state.isDataSaved && this.state.isSearchedClicked && (
					<ConfirmDialog
						IsOpen={this.state.isSearchedClicked}
						Message={
							"There are unsaved changes .Do you still want to search another Company"
						}
						OnHandelClick={(isValid) => {
							if (isValid) {
								this.onClickHandel(isValid);
							} else {
								this.onClickHandel(isValid);
							}
						}}
						AgreeText="Yes"
						DisAgreeText="No"
					></ConfirmDialog>
				)}
{this.state.openCoStarConfirmDialog == true && (
          <ConfirmDialog
            IsOpen={this.state.openCoStarConfirmDialog}
            Message={
              "CoStar Id with following data [ Property :" + this.state.coStarData[0].property + ", Address : " + this.state.coStarData[0].address + "] already exist .Do you still want to map the comps with existing Property ?"
            }
			confirmData={this.state.coStarData}
            OnHandelClick={(isValid) => {
              if (isValid) {
                this.onClickHandelCostar(isValid);
              } else {
                this.onClickHandelCostar(isValid);
              }
            }}
            AgreeText="Accept"
            DisAgreeText="Cancel"
          ></ConfirmDialog>
        )}

				{ isEditEnabled ? (
					<div class="row header-container">
						<div className="col-md-4 col-lg-4">
							<PageHeader
								btnText={"Properties"}
								onBackClick={this.onBackClick}
								title={
									this.props.isInAddMode
										? "/ " + this.props.ViewTitle
										: "/ " + buildingName
								}
								style={{ color: "#00B0B9" }}
							></PageHeader>
						</div>
						<div className="col-md-4 col-lg-4">
                        {buildingSummaryData.length > 0 && this.state.showEdit == true && (
								<AutoSearch
									placeholder="Search another Property"
									isEditEnabled={this.state.isEditEnabled}
									sourceData={this.state.buildingSummaryData}
									filterConfig={filterConfig}
									onSearch={this.onSearch}
								></AutoSearch>
							)}
						</div>
						<div className="col-md-4 col-lg-4 end-sm">
							{this.state.showEdit == true && (
								<><Button
									size="small"
									style={{ marginTop: "5px" }}
									className="btn-clear"
									onClick={() => {
										this.setState({
											getBuildingDetails: JSON.parse(JSON.stringify([...this.state.getBuildingDetailsResponse])),
											reloadBuilding: !this.state.reloadBuilding,
										});
									}}
								>
									<span className="tool-icon-text">{this.props.isInAddMode == true ? 'Clear All' :'Undo'}</span>
								</Button><Button
									size="small"
									className="notop-action-button"
									onClick={() => {
										this.props.onBackClick();
									}}
								>
										<span className="tool-icon-text">Cancel</span>
									</Button>
									{ IsSectionVisible(SECTIONS.Property,SECTIONS.Property_Details, SECTIONS.ADD_EDIT) &&
									<Button
										size="small"
										className="notop-action-button"
										onClick={() => {
											this.enableEdit();
										}}
									>
										<span className="tool-icon-text">Save</span>
									</Button>
	}</>
							)}
						</div>
					</div>
				) : (
					null
				)}



				{getBuildingDetails && this.state.showEdit == true && (
					<div className="row table-list-container">
						<FormGenerator
							gridColumn={3}
							//getHistoryDataByField={this.getHistoryDataByField}
							ReloadForm={this.state.reloadBuilding}
							formFields={UpdateFieldsWithConfiguration(
								getBuildingDetails,
								DetailsFormConfig
							)}
							dropDownSource={[]}
							validationMessages={[]}
							partialForm={true}
							saveChanges={this.getBuildingChanges}
							Editable={this.state.isEditEnabled}
							viewPDF={[]}
							formType={"Section"}
							separatorConfig={companySeparatorConfig}
							//otherChildsection={[
							//	{
							//	 	label: "",
							//	 	element: getBuildingDetails.length > 0 && (
							//			<div className="col-xs-12 col-sm-5">
							//				<ImageSlider></ImageSlider>
							//			</div>
							//	 	),
							//		order:1,	
							//	 	row: 2,
							//		sectionColumn:1,
							//	}
							//]}
							sectionRowConfig={[
								//{ row: 1, column: 7 },
								{ row: 1, column: 12 },
								//{ row: 2, column: 5 },
								{ row: 2, column: 12 },
								{ row: 3, column: 12 },
								{ row: 4, column: 12 },
								{ row: 5, column: 12 },
								{ row: 6, column: 4 },
								//  {row:3,column:4,labelAlign:'alignText-Right'},
								//  {row:2,column:4,labelAlign:'alignText-Right'},
							]}
						></FormGenerator>
					</div>
				)}





			
			</>
		);
	}
}

import React, { Component } from "react";
import { Rail, Handles, Tracks, Ticks, Slider } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./components"
import { getDate } from "../../../utils/dateForSlider";
import { deviceDetection } from '../../../constants/constants';
import moment from "moment-mini";


const { isMobile } = deviceDetection;

const sliderStyle = {
  position: "relative",
  width: "100%"
};

export default class CustomSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selectedDate || getDate(`1/10/${props.selectedYear}`),
      min: getDate(`1/10/${props.selectedYear}`),
      max: getDate(`12/5/${props.selectedYear}`),
      tickValues: [getDate(props.selectedDate), getDate(props.selectedDate)],
      dateTicks: [],
      stepValue: 1000 * 60 * 60 * 24 * 30,
      isSliderValueSet: false
    };
  }

  componentDidMount() {
    this.setSliderValueOnProps(this.props.sliderView, this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectedYear !== nextProps.selectedYear || this.props.sliderView !== nextProps.sliderView || this.props.minSliderVal !== nextProps.minSliderVal) {
      this.setSliderValueOnProps(nextProps.sliderView, nextProps);
    }
  }

  formatTick = (ms) => {
    if (isMobile)
      return moment(getDate(ms)).format(this.props.sliderView === "year" ? "YYYY" : "MMM YY");
    return moment(getDate(ms)).format(this.props.sliderView === "year" ? "YYYY" : "MMM YY");
  }

  getPrevNextDate = (date, gapVal) => {
    const curMonth = date.getMonth() + gapVal;
    date.setMonth(curMonth);
    return date.getTime();
  }

  setSliderValueOnProps = (sliderType, props) => {
    const { selectedDate, selectedYear, minSliderVal, maxSliderVal, singleTickSelection = false } = props;
    let sliderObj = {};
    switch (sliderType) {
      case "year":
        sliderObj = {
          min: getDate(`1/10/${selectedYear - 2}`),
          max: getDate(`1/10/${selectedYear}`),
          tickValues: [getDate(`1/10/${selectedYear}`), getDate(`1/10/${selectedYear}`)],
          dateTicks: [getDate(`1/10/${selectedYear - 2}`).getTime(), getDate(`1/10/${selectedYear - 1}`).getTime(), getDate(`1/10/${selectedYear}`).getTime()],
          stepValue: 1000 * 60 * 60 * 24 * 365,
          isSliderValueSet: true
        }
        break;

      case "mtdRadio":
        let monthInc = 1;
        sliderObj = {
          min: getDate(minSliderVal),
          max: getDate(maxSliderVal),
          // tickValues: !singleTickSelection ? [getDate(selectedDate), getDate(selectedDate)] : [getDate(selectedDate)],
          tickValues: !singleTickSelection ? [getDate(`1/10/${selectedYear}`), getDate(selectedDate)] : [getDate(selectedDate)],
          dateTicks: [getDate(minSliderVal).getTime(), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), this.getPrevNextDate(getDate(minSliderVal), monthInc++), getDate(maxSliderVal).getTime()],
          stepValue: 1000 * 60 * 60 * 24 * 30.4,
          isSliderValueSet: true
        };
        break;

      default:
        sliderObj = {
          min: getDate(`1/10/${selectedYear}`),
          max: getDate(`12/16/${selectedYear}`),
          tickValues: [getDate(selectedDate)],
          dateTicks: [getDate(`1/10/${selectedYear}`).getTime(), getDate(`2/9/${selectedYear}`).getTime(), getDate(`3/10/${selectedYear}`).getTime(), getDate(`4/11/${selectedYear}`).getTime(), getDate(`5/12/${selectedYear}`).getTime(), getDate(`6/12/${selectedYear}`).getTime(), getDate(`7/13/${selectedYear}`).getTime(), getDate(`8/14/${selectedYear}`).getTime(), getDate(`9/14/${selectedYear}`).getTime(), getDate(`10/15/${selectedYear}`).getTime(), getDate(`11/15/${selectedYear}`).getTime(), getDate(`12/16/${selectedYear}`).getTime()],
          stepValue: 1000 * 60 * 60 * 24 * 31,
          isSliderValueSet: true
        };
    }
    this.setState({ ...sliderObj });
  }

  onChange = (tickValues) => {
    const { sliderView, singleTickSelection = false } = this.props;
    const { isSliderValueSet } = this.state;
    if (isSliderValueSet) {
      this.setState({
        tickValues
      }, () => {
        let startDate = tickValues[0];
        let endDate = tickValues[0];
        switch (sliderView) {
          case "year":
            const lastMonthDate = new Date(`12/2/${new Date(tickValues[1]).getFullYear()}`).getTime();
            endDate = lastMonthDate;
            break;
          case "mtdRadio":
            endDate = !singleTickSelection ? tickValues[1] : tickValues[0];
            break;
          case "ytdRadio":
            endDate = tickValues[0];
            break;
          case "ttmRadio":
            startDate = this.getPrevNextDate(getDate(tickValues[0]), -11); //Last 12 months data
            break;
        }
        // const lastMonthDate = new Date(`12/2/${new Date(tickValues[0]).getFullYear()}`).getTime();
        // const endDate = sliderView === "year" ? lastMonthDate : (sliderView === "mtdRadio" ? tickValues[1] : tickValues[0]);
        this.props.onChangeMonthRange(startDate, endDate);
      });
    }
  };

  render() {
    const { min, max, tickValues, dateTicks, stepValue } = this.state;

    return (
      <>
        <div style={sliderStyle}>
          <Slider
            mode={1}
            step={stepValue}
            domain={[+min, +max]}
            rootStyle={sliderStyle}
            onChange={this.onChange}
            values={tickValues}
          >
            
            <Rail>
              {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
            </Rail>
            <Handles>
              {({ handles, getHandleProps }) => (
                <div>
                  {handles.map(handle => (
                    <Handle
                      key={handle.id}
                      handle={handle}
                      domain={[+min, +max]}
                      getHandleProps={getHandleProps}
                    />
                  ))}
                </div>
              )}
            </Handles>
            <Tracks left={false} right={false}>
              {({ tracks, getTrackProps }) => (
                <div>
                  {tracks.map(({ id, source, target }) => (
                    <Track
                      key={id}
                      source={source}
                      target={target}
                      getTrackProps={getTrackProps}
                    />
                  ))}
                </div>
              )}
            </Tracks>
            <Ticks values={dateTicks}>
              {({ ticks }) => (
                <div className="ticksGroup">
                  {ticks.map(tick => (
                    <Tick
                      key={tick.id}
                      tick={tick}
                      count={ticks.length}
                      format={this.formatTick}
                    />
                  ))}
                </div>
              )}
            </Ticks>
          </Slider>
        </div>
      </>
    );
  }
}


import React from 'react'
import Button from '@material-ui/core/Button'
import CheckBox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { PaperComponent } from '../../../utils/helper'
import PropTypes from 'prop-types'
import { FormControlLabel } from '@material-ui/core'

ConfirmDialog.propTypes = {
  IsOpen: PropTypes.bool.isRequired,
  Message: PropTypes.string.isRequired,
  OnHandelClick: PropTypes.func.isRequired,
  AgreeText: PropTypes.string,
  DisAgreeText: PropTypes.string,
  CheckBoxMessage: PropTypes.string,
  OnChange: PropTypes.func,
  confirmData: PropTypes.array,
}

export default function ConfirmDialog({ IsOpen, confirmData, Message, OnHandelClick, AgreeText = 'Agree', DisAgreeText = 'Disagree', CheckBoxMessage, OnChange }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={IsOpen}
        onClose={() => OnHandelClick(false)}
        aria-labelledby="responsive-dialog-title"
        PaperComponent={PaperComponent}>
        <DialogTitle style={{ cursor: 'move' }} id="responsive-dialog-title">{'Confirm'}</DialogTitle>
        <DialogContent>
          {/* {confirmData.length> 0 &&
  <div className="row">
<div className="col-md-6 col-lg-6"><span>
  Property : {confirmData[0].property} Address : {confirmData[0].address}  </span></div>
  </div>
} */}

          <DialogContentText> {/*style={{width:'724px'}}*/}
            {<div style={{ whiteSpace: 'pre-line' }}>{Message}</div>}
          </DialogContentText>
          {CheckBoxMessage != null &&          
          <FormControlLabel
            control={<CheckBox onChange={(e) => OnChange(e)} />}
            label={CheckBoxMessage}
          />
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => OnHandelClick(false)} color="primary">
            {DisAgreeText}
          </Button>
          <Button onClick={() => { OnHandelClick(true) }} color="primary" autoFocus>
            {AgreeText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

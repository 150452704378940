import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { apiBatchRequest, apiPost, clearCacheData } from "../../utils/api_service";
import camelcaseKeys from "camelcase-keys";
import moment from "moment";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import { ShowToast, toastType } from "../../utils/toast-service";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  isGridFormValid,
  UpdateColumnWithConfiguration,
} from "../../utils/helper";
import { API_URL } from "../../constants/api";
import { GridTable } from "../common/Table/Table";
import ConfirmDialog from "../common/Dailogbox/confirmDailog";
import SearchBar from "../common/AutoSearch/SearchBar";
import PageHeader from "../common/PageHeader";

export const subMarketMasterColumns = [
  {
    name: "Submarket",
    key: "subMarket",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  {
    name: "Market",
    key: "market",
    minTag: 2,
    viewTagData: "viewTagData",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "DropDown",
      sourceKey: "getMarketMaster",
      updateKey: "marketId",
      sourceOption: "market",
      title: "Market",
      isEditField: true,
      isAddField: true,
      multiple: false,
    },
  },
  {
    name: "Submarket Reference Name",
    key: "submarketReferenceName",
    type: "Child",
    isRequired: true,
    isVisible: true,
    editConfig: {
      type: "TextField",
      isEditField: true,
      isAddField: true,
    },
  },
  // {
  //   name: "ModifiedBy",
  //   key: "modifiedBy",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   editConfig: {
  //     type: "TextField",
  //     isEditField: false,
  //     isAddField: false,
  //   },
  // },
  // {
  //   name: "ModifiedOn",
  //   key: "modifiedDate",
  //   type: "Child",
  //   isRequired: true,
  //   isVisible: true,
  //   dataFormat: "Date",
  //   editConfig: {
  //     type: "TextField",
  //     isEditField: false,
  //     isAddField: false,
  //   },
  // },
  {
    name: "Action",
    key: "subMarketId",
    renderComponent: "renderActionColumn",
  },
];

export default class ConfigureSubMarket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      getSubMarketMaster: [],
      getSubMarketMasterResponse: [],
      getMarketMaster: [],
      isInAddMode: false,
      reloadTable: false,
      selectedEditRowId: undefined,
      configuration: [],
      isInDeleteMode: false,
      updateMasterData: {
        subMarketId: -1,
        subMarket: null,
        marketId: 0,
        market: null,
        modifiedBy: "",
        modifiedDate: moment().format("YYYY-MM-DD"),
        submarketReferenceName:""
      },
      showWarningMessage: false,
      confirmMessage: "",
      searchInput: undefined,
	  selectedMarketId: this.props.globalMarketId,
    };
  }

  componentDidMount() {
    this.getRequiredData(false);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);     
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  getRequiredData = (clearCache) => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.getSubMarketMaster,
        key: "getSubMarketMaster",
        params: { businessLineId: 1 },
		fromCache: true,
		duration: 60,
		clearCache: clearCache == true ? true : false
      },
      {
        url: API_URL.Master.getMarketMaster,
        key: "getMarketMaster",
      },
    ])
      .then((getSubMarketMaster) => {
        this._asyncRequest = null;
        if (getSubMarketMaster.getSubMarketMaster.success === true) {
          const marketIds = camelcaseKeys(
            getSubMarketMaster.getMarketMaster.data
          )
            .map((x) => x.marketId)
            .join("|");
          camelcaseKeys(getSubMarketMaster.getMarketMaster.data).unshift({
            marketId: marketIds,
            market: "All",
          });
          this.setState({
            getSubMarketMaster:
              camelcaseKeys(getSubMarketMaster.getSubMarketMaster.data) || [],
            getSubMarketMasterResponse:
              camelcaseKeys(getSubMarketMaster.getSubMarketMaster.data) || [],
            configuration:
              camelcaseKeys(
                getSubMarketMaster.getSubMarketMaster.configuration
              ) || [],
            getMarketMaster:
              camelcaseKeys(getSubMarketMaster.getMarketMaster.data) || [],
            reloadTable: true,
          });
          this.forceUpdate();
        } else {
          ShowToast(
            "Something went wrong",
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  cancelAddMode = () => {
    this.setState({
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
  };

  cancelEditMode = () => {
    this.setState({
      selectedEditRowId: undefined,
      isInAddMode: false,
      updateMasterData: undefined,
    });
    //this.getRequiredData();
  };

  updateRecord = () => {
    const newParam = this.state.updateMasterData;
    // this.state.isInDeleteMode ? newParam.statusId = 0 : newParam.statusId = newParam.statusId;
	newParam.statusId = 1;
    apiPost({
      url: API_URL.Master.UpdateSubMarketMasterDetail,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast("Record updated successfully.", toastType.SUCCESS, 5000, 500);

		clearCacheData(
			API_URL.Master.getSubMarketMaster +
			  '{"businessLineId":1,"marketId":"'+ this.state.selectedMarketId +'"}'
		  );

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: this.state.reloadTable == true ? this.state.reloadTable : !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData(true);
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  deleteRecord = (cellInfo) => {
    this.setState({
      showWarningMessage: true,
      confirmMessage: "Do you want to delete this record ?",
      updateMasterData: cellInfo,
    });
  };

  onClickHandle(e) {
    if (e) {
      this.setState(
        {
          isInDeleteMode: true,
          reloadTable: !this.state.reloadTable,
        },
        () => {
          this.forceUpdate();
        }
      );

      const newParam = this.state.updateMasterData;
      newParam.statusId = 0;
      apiPost({
        url: API_URL.Master.UpdateSubMarketMasterDetail,
        params: { businessLineId: 1 },
        postBody: newParam,
      }).then(({ data: response }) => {
        // debugger;
        if (response.success === true) {
          ShowToast(
            "Record deleted successfully.",
            toastType.SUCCESS,
            5000,
            500
          );

          this.setState({
            updateData: undefined,
            selectedEditRowId: undefined,
            reloadTable: !this.state.reloadTable,
            isInAddMode: false,
            showWarningMessage: false,
            confirmMessage: "",
          });
          this.getRequiredData();
          this.forceUpdate();
        } else {
          ShowToast(response.message, toastType.ERROR, 5000, 5000);
        }
      });
    } else {
      this.setState({
        showWarningMessage: false,
        confirmMessage: "",
      });
    }
  }

  saveUpdatedRecord = () => {
    const newParam = this.state.updateMasterData;
    apiPost({
      url: API_URL.Master.AddSubMarketMasterDetail,
      params: { businessLineId: 1 },
      postBody: newParam,
    }).then(({ data: response }) => {
      // debugger;
      if (response.success === true) {
        ShowToast(
          this.state.isInAddMode
            ? "Record added successfully."
            : "Record updated successfully.",
          toastType.SUCCESS,
          5000,
          500
        );

		clearCacheData(
			API_URL.Master.getSubMarketMaster +
			  '{"businessLineId":1,"marketId":"'+ this.state.selectedMarketId +'"}'
		  );

        this.setState({
          updateData: undefined,
          selectedEditRowId: undefined,
          reloadTable: this.state.reloadTable == true ? this.state.reloadTable : !this.state.reloadTable,
          isInAddMode: false,
          showWarningMessage: false,
          confirmMessage: "",
        });
        this.getRequiredData(true);
        this.forceUpdate();
      } else {
        ShowToast(response.message, toastType.ERROR, 5000, 5000);
      }
    });
  };

  renderActionColumn = (cellInfo, key, isInAddMode) => {
    return (
      <>
        {this.state.selectedEditRowId !== undefined &&
        cellInfo.subMarketId === this.state.selectedEditRowId.value ? (
          <>
            <Tooltip title="Save Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {}}
              >
                <SaveIcon
                  fontSize="small"
                  size="small"
                  onClick={
                    this.state.isInAddMode
                      ? this.saveUpdatedRecord
                      : this.updateRecord
                  }
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={this.cancelEditMode}
              >
                <CancelIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip title="Edit Record">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.setState({
                    selectedEditRowId: {
                      key: "subMarketId",
                      value: cellInfo.subMarketId,
                    },
                    isInAddMode: false,
                    updateMasterData: cellInfo,
                  });
                }}
              >
                <EditIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>
            {/*<Tooltip title="Delete">
              <IconButton
                aria-label={"refreshData"}
                size="small"
                onClick={() => {
                  this.deleteRecord(cellInfo);
                }}
              >
                <DeleteIcon fontSize="small" size="small" />
              </IconButton>
            </Tooltip>*/}
          </>
        )}
      </>
    );
  };

  handleTableEditChange = (value, fieldName, row, addMode) => {
    const param = this.state.updateMasterData;
    if (fieldName === "subMarket") {
      param[fieldName] = value || [];
    }
    if (fieldName === "market") {
      let getMarketMaster = [];
      getMarketMaster = this.state.getMarketMaster;
      const marketId = parseInt(
        getMarketMaster.find((x) => x.market === value).marketId
      );
      param.marketId = parseInt(marketId);
      param[fieldName] = value || [];
    }
    if (fieldName === "submarketReferenceName") {
      param[fieldName] = value || [];
    }
    //isGridFormValid(value, subMarketMasterColumns.find(x => x.key === fieldName), param);
    this.setState({ updateMasterData: param });
    this.forceUpdate();
  };

  AddNewRecord = () => {
    if (this.state.isInAddMode) {
      ShowToast("You can add one row at a time!", toastType.WARNING, 5000, 500);
      return false;
    }

    const rowNew = {
      subMarketId: -1,
      subMarket: null,
      marketId: 0,
      market: null,
      modifiedBy: "",
      modifiedDate: moment().format("YYYY-MM-DD"),
      statusId: 1,
      submarketReferenceName:""
    };
    this.setState({
      isInAddMode: true,
      selectedEditRowId: { key: "subMarketId", value: -1 },
      updateMasterData: rowNew,
    });
  };

  handleChange = (event) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, getSubMarketMaster } = this.state;
    let searchData = this.state.getSubMarketMasterResponse;
    let filteredData = searchData.filter((value) => {
      return value.subMarket
        ? value.subMarket
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        : false;
      // }
    });
    this.setState({ getSubMarketMaster: filteredData });
  };

  render() {
    const { getSubMarketMaster, getMarketMaster } = this.state;
    const EditSource = [];
    EditSource.push({ key: "getMarketMaster", data: getMarketMaster });
    return (
      <>
        {this.state.showWarningMessage && (
          <ConfirmDialog
            IsOpen={this.state.showWarningMessage}
            Message={this.state.confirmMessage}
            OnHandelClick={(isValid) => {
              this.onClickHandle(isValid);
            }}
            AgreeText="Yes"
            DisAgreeText="No"
          ></ConfirmDialog>
        )}
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <PageHeader title="Configure Submarket"></PageHeader>
          </div>
          <div className="col-sm-8 col-md-8 col-lg-8">
            <SearchBar
              size="large"
              name="searchInput"
              value={this.state.searchInput || ""}
              onChange={this.handleChange}
              label="Search"
              placeholder="Search Submarket."
            ></SearchBar>
          </div>
        </div>

        {getSubMarketMaster && (
          <GridTable
            columns={UpdateColumnWithConfiguration(
              subMarketMasterColumns,
              this.state.configuration,
              []
            )}
            data={getSubMarketMaster}
            renderActionColumn={this.renderActionColumn}
            selectedEditRowId={this.state.selectedEditRowId}
            isInAddMode={this.state.isInAddMode}
            EditSource={EditSource}
            handleTableEditChange={this.handleTableEditChange}
            updateData={this.state.updateMasterData}
            AddNewRecord={{
              action: this.AddNewRecord,
              title: "Add New Submarket",
              visible: true,
            }}
            reload={this.state.reloadTable}
          ></GridTable>
        )}
      </>
    );
  }
}

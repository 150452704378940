import React from "react";
import "../../../public/css/style.less";
import {
  apiBatchRequest,
  apiBatchRequestWithoutLoader,
  apiPost,
  apiRequestNoLoadder,
  CancelRequest,
  RenewCancelToken,
} from "../../../utils/api_service";
import ReportButtons from "../../../utils/ReportButtons";
import { API_URL } from "../../../constants/api";
import PageFilter from "../../common/PageFilter";
import { controlType } from "../../../constants/common";
import PageHeader from "../../common/PageHeader";
import { Tooltip } from "@material-ui/core";
import GridTable from "../../common/Table/Table";
import {
  applyFilterOnData,
  generatePDFFromHtml,
  IsSectionVisible,
  UpdateColumnWithConfiguration,
} from "../../../utils/helper";
import { ShowToast, toastType } from "../../../utils/toast-service";
import commonLabels from "../../../constants/messageConstants";
import camelcaseKeys from "camelcase-keys";
import SearchBar from "../../common/AutoSearch/SearchBar";
import EditIndBuildingOwners from "./EditIndBuildingOwners";
import SettingScreenDialog from "../../common/SettingScreenDialog/SettingScreenDialog";
import { downloadExcelReportByAPI } from "../../../utils/generic";
import { SECTIONS } from "../../../constants/appConstants";

const uploadColumns = [
  {
    key: "companyName",
    name: "Company Name",
  },
  {
    key: "companyRole",
    name: "Owner Type",
  },
  {
    key: "address",
    name: "Address",
  },
  {
    key: "market",
    name: "Market",
  },
  {
    key: "subMarket",
    name: "Sub Market",
  },
  {
    key: "productType",
    name: "Product Type",
  },
  {
    key: "industryName",
    name: "Industry",
  },
];

const columnWidths = [
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
  { wch: 20 },
];
export default class IndBuildingOwners extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isStateChanged:false,
      selectedMarketId: this.props.globalMarketId,
      reloadTable: false,
      buildingOwnerData: [],
      configuration: [],
      companyMasterDetails: [],
      ownerTypeData: [],
      getIndustrySector: [],
      selectedEditRowId: undefined,
      detailView: false,
      ViewTitle: undefined,
      actionType: undefined,
      getSubMarketMaster: [],
      getProductTypeMaster: [],
      getMarketMaster: [],
      responseCompanyData: [],
      openFilter: false,
      //searchInput: "",
      filteredData: [],
      lstActiveFilters: [],
      subMarketData: [],
      subMarketFilteredData: [],
      isInAddMode: false,
      filterSelectedData: [],
      userConfiguration: [],
      openSlidingPanel: false,
      dbColumnList: [],
      rowsPerPage: 20,
      listOfColumns: [],
      enableFilterButton: false,
      defaultSelectedData:[],
      valueOfCompanyRole:[],
      companyRoleId:null,
      loading:false
    };
  }

  onRestoreToDefault = (config) => {
    let param = config;
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: { restoreDefault: 1 },
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId();
          ShowToast(commonLabels.settingRestored, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(
            commonLabels.settingNotRestored,
            toastType.error,
            5000,
            500
          );
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };

  onSettingChange = (config, pageSize) => {
    let param = [];
    if (config.length > 0) {
      param = config.filter((m) => {
        if (pageSize != 0) m.rowPerPage = pageSize;
        return m;
      });
    } else {
      param = this.state.userConfiguration.filter((m) => {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (m.dataFormat == "Number") {
          m.headcellClass = "headcell-number-text";
        }
        if (
          m.sectionId == 15 &&
          m.isVisible == true &&
          m.key == "companyName"
        ) {
          m.renderComponent = "renderActionColumn";

          // this.setState({
          if (pageSize != 0) m.rowPerPage = pageSize;
          //pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
      });
    }
    apiPost({
      url: API_URL.Master.addUserConfiguration,
      params: {},
      postBody: param,
    })
      .then(({ data: response }) => {
        if (response.success === true) {
          this.setState({
            // userConfiguration: camelcaseKeys(response.data),
            // rowsPerPage: response.data[0].rowPerPage,
            reloadTable: !this.state.reloadTable,
            openSlidingPanel: false,
          });
          // this.forceUpdate();
          this.getUserConfigurationBySectionId(this.state.selectedMarketId);
          ShowToast(commonLabels.settingUpdated, toastType.SUCCESS, 5000, 500);
          this.getRequiredData(this.state.selectedMarketId);
        } else {
          ShowToast(commonLabels.settingNotUpdated, toastType.error, 5000, 500);
        }
      })
      .catch(() => {
        ShowToast(commonLabels.somethingWrong, toastType.error, 5000, 500);
      });
  };
  getUserConfigurationBySectionId = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.GetUserConfigurations,
        key: "userConfiguration",
        params: { sectionId: 15 },
		fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ])
      .then((userConfiguration) => {
        this._asyncRequest = null;
        if (userConfiguration.userConfiguration.success == true) {
          this.setState({
            userConfiguration:
              camelcaseKeys(userConfiguration.userConfiguration.data).sort(
                (a, b) => (a.attributeSortOrder > b.attributeSortOrder ? 1 : -1)
              ) || [],
            rowsPerPage: userConfiguration.userConfiguration.data[2].rowPerPage,
            //reloadRequired:true,
            reloadTable: !this.state.reloadTable,
            listOfColumns: userConfiguration.userConfiguration.data.filter(
              (m) => {
                if (m.isVisible == true) return m.key;
              }
            ),
          });
          this.forceUpdate();
          //this.child.setRowsPerPage(this.state.rowsPerPage);
          //  this.childRef.current.handleChangeRowsPerPage(this.state.rowsPerPage);
        } else {
          ShowToast(
            userConfiguration.userConfiguration.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  componentDidMount() {
    RenewCancelToken();
    this.getRequiredData(this.state.selectedMarketId);
    //this.getRequiredDataWithoutLoader();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.globalMarketId !== this.state.selectedMarketId) {
      this.getRequiredData(nextProps.globalMarketId);
      this.setState({ selectedMarketId: nextProps.globalMarketId });
    }
  }

  componentWillUnmount() {
    CancelRequest();
  }

  reloadPropertyCompanyData = () => {
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.Master.buildingOwnerMasterDetails,
        key: "companyMasterDetails",
        params: { businessLineId: 2 },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
      {
        url: API_URL.Master.industrialBuildingMasterData,
        key: "getBuildingMaster",
        params: { businessLineId: 2, marketId: this.state.selectedMarketId },
        fromCache: true,
        duration: 60,
        clearCache: true,
      },
    ]).then((companyMasterDetails) => {
      this._asyncRequest = null;
      if (companyMasterDetails.companyMasterDetails.success == true) {
        this.setState({
          companyMasterDetails:
            camelcaseKeys(companyMasterDetails.companyMasterDetails.data) || [],
          getBuildingMaster:
            camelcaseKeys(companyMasterDetails.getBuildingMaster.data) || [],
        });
        this.forceUpdate();
      } else {
        ShowToast("Error", toastType.ERROR, 5000, 500, "Server error");
      }
    });
  };

  getRequiredDataWithoutLoader = (selectedMarketId) => {
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequestWithoutLoader([
        {
          url: API_URL.Master.buildingOwnerMasterDetails,
          key: "companyMasterDetails",
          params: { businessLineId: 2 },
        },
        {
          url: API_URL.Master.ownerTypeData,
          key: "ownerTypeData",
          params: { businessLineId: 2 },
        },
      ])
        .then((buildingOwnerData) => {
          this._asyncRequest = null;
          if (buildingOwnerData.companyMasterDetails.success == true) {
            this.setState({
              companyMasterDetails:
                camelcaseKeys(buildingOwnerData.companyMasterDetails.data) ||
                [],
              ownerTypeData:
                camelcaseKeys(buildingOwnerData.ownerTypeData.data) || [],
              enableFilterButton: true,
            });
            this.forceUpdate();
          } else {
            ShowToast(
              buildingOwnerData.buildingOwnerData.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };

  getRequiredData = (selectedMarketId) => {
    if (selectedMarketId != "") {
      this._asyncRequest = apiBatchRequest([
        {
          url: API_URL.IndustrialBuildingOwners.buildingOwnersSummaryData,
          key: "buildingOwnerData",
          params: { marketId: selectedMarketId,companyRoleId:"1",defaultMode:1 },
        },
        {
          url: API_URL.Master.GetUserConfigurations,
          key: "userConfiguration",
          params: { sectionId: 15 },
		  fromCache: true,
          duration: 60,
        },
      ])
        .then((buildingOwnerData) => {
          this._asyncRequest = null;
          if (buildingOwnerData.buildingOwnerData.success == true) {
            this.getRequiredDataWithoutLoader(selectedMarketId);
            const selectedData=[];
              selectedData.push({
                key: 'companyRole',
                selected: [{ companyRole: "Owner",CompanyRoleId:1 }]
              });
            this.setState({
              defaultSelectedData:selectedData,
              buildingOwnerData:
                camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || [],
              responseCompanyData: _.cloneDeep(
                camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || []
              ),
              configuration:
                camelcaseKeys(
                  buildingOwnerData.buildingOwnerData.configuration
                ) || [],
              userConfiguration: camelcaseKeys(
                buildingOwnerData.userConfiguration.data != null
                  ? buildingOwnerData.userConfiguration.data
                  : JSON.parse(
                      window.sessionStorage.getItem("userConfigurations")
                    )
              ),
              listOfColumns: buildingOwnerData.userConfiguration.data.filter(
                (m) => {
                  if (m.isVisible == true) return m.key;
                }
              ),
              rowsPerPage:
                buildingOwnerData.userConfiguration.data[2].rowPerPage,
                
            });
            this.forceUpdate();
          } else {
            ShowToast(
              buildingOwnerData.buildingOwnerData.message,
              toastType.ERROR,
              5000,
              500,
              "Server error"
            );
          }
        })
        .catch(() => {
          this._asyncRequest = null;
        });
    }
  };



  renderActionColumn = (cellInfo) => {
    return (
      <>
        <Tooltip title="View">
          <a
            className="default-text"
            aria-label={"refreshData"}
            size="small"
            onClick={() => {
              this.setState({
                isInAddMode: false,
              });
              this.onRowClick(cellInfo);
              //this.setState({ detailView: true, selectedEditRowId: cellInfo.CompanyId });
            }}
          >
            <span className="default-text span-link">
              {cellInfo.companyName ? cellInfo.companyName : "---"}
            </span>
          </a>
        </Tooltip>
        {/* <span>  {cellInfo.CompanyName}</span> */}
      </>
    );
  };

  getListOfSubMarket() {}

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  onInputChange = (filter, value) => {
    this.setState({
      loading:true
    });
    const params = {
      businessLineId: 2,
      searchText: value,
    };
    apiRequestNoLoadder({
      url: API_URL.Master.buildingOwnerMasterDetails,
      params,
    })
      .then(({ data: companyMasterDetails }) => {
        this.setState(
          {
            companyMasterDetails: camelcaseKeys(companyMasterDetails) || [],
            loading:false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((reason) => {
        this._asyncRequest = null;
        ShowToast(
          commonLabels.somethingWrong,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      });
    //}
  };

  onFilterChange = (selectedData) => {
    if (selectedData.length > 0) {
      let data = [];
      data.push(selectedData);

      this.setState({
        filterSelectedData: data,
      });
    }
    selectedData.length== 0 ?  this.setState({
      
      defaultSelectedData:  [],
    }): null;
    let companyRoleId=[];
    if(selectedData && selectedData.length == 0){
      companyRoleId.push('-1');
    }
    if (selectedData && selectedData.length > 0) {
      selectedData.forEach((ele) => {
        // if(ele && ele.selected && ele.selected.length == 0 && ele.key == "companyRole"){
        //   companyRoleId.push('-1');
        // }
        if (ele && ele.selected && ele.selected.length > 0) {
    	        if (ele.key == "companyRole") {
              
                ele.selected.forEach((element) => {

                companyRoleId.push(element.companyRoleId);
              });
              }
    			
    		}
    	});
    }
    if(companyRoleId.length >0)
    {
      this.onCompanyLoad(companyRoleId.join('|'),0);
    }
   
    if (selectedData.length > 0) {
      this.filterData(this.state.responseCompanyData, selectedData);
    } else {
      this.setState({
        getSubMarketMaster: this.state.subMarketData,
      });
      this.filterData(this.state.responseCompanyData, selectedData);
    }
  };

  filterData(buildingOwnerData, selectedData) {
    const filteredData = applyFilterOnData(
      [...buildingOwnerData],
      selectedData
    );
    this.setState(
      {
        buildingOwnerData: filteredData,
        selectedData: selectedData,
        reloadTable: !this.state.reloadTable,
      },
      () => {
        this.forceUpdate();
      }
    );
  }
  resetPage = () => {
    //if()
    let resp = this.state.responseCompanyData;
    this.setState({
      buildingOwnerData: resp,
      lstActiveFilters: [],
      openFilter: false,
    });
  };
  enableFilter = () => {
    let openFilter = this.state.openFilter;
    this.setState({ openFilter: !openFilter });
  };

  onAddNewClick = () => {
    this.setState({
      isInAddMode: true,
      detailView: true,
      ViewTitle: "Add",
      actionType: "Add",
    });
  };
  onRowClick = (data) => {
    this.setState({
      detailView: true,
      selectedEditRowId: data.companyId,
      ViewTitle: "Edit",
      actionType: "Edit",
    });
  };

  onBackClick = () => {
    this.setState({ detailView: false, selectedEditRowId: undefined });
  };
resetAppliedFilter=()=>
{
  this.setState({ ["searchInput"]:null,defaultSelectedData:[],  filterSelectedData: null,
  isStateChanged:!this.state.isStateChanged},()=>{this.forceUpdate()});
  this.reloadData();
}

onCompanyLoad=(companyRoleId,defaultMode=1,searchText=null)=>{  
  this._asyncRequest = apiBatchRequest([
    {
      url: API_URL.IndustrialBuildingOwners.buildingOwnersSummaryData,
      key: "buildingOwnerData",
      params: {  marketId: this.state.selectedMarketId,
        companyRoleId:companyRoleId,defaultMode:defaultMode,
        searchText:searchText != null ? searchText : null },
    },
  ])
    .then((buildingOwnerData) => {
      this._asyncRequest = null;
      if (buildingOwnerData.buildingOwnerData.success == true) {
        this.setState({
          buildingOwnerData:
            camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || [],
          responseCompanyData: _.cloneDeep(
            camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || []
          ),
          configuration:
            camelcaseKeys(
              buildingOwnerData.buildingOwnerData.configuration
            ) || [],         
          companyRoleId:companyRoleId,
         
        });
        this.forceUpdate();
      } else {
        ShowToast(
          buildingOwnerData.buildingOwnerData.message,
          toastType.ERROR,
          5000,
          500,
          "Server error"
        );
      }
    })
    .catch(() => {
      this._asyncRequest = null;
    });
}
  reloadData = (companyRoleIds,defaultMode=0,searchText=null,clearData=1) => {
    let companyRoleId=this.state.companyRoleId == null && this.state.defaultSelectedData.length > 0
      ? "1" : this.state.companyRoleId;
    this._asyncRequest = apiBatchRequest([
      {
        url: API_URL.IndustrialBuildingOwners.buildingOwnersSummaryData,
        key: "buildingOwnerData",
        params: {  marketId: this.state.selectedMarketId,
          companyRoleId:companyRoleId,defaultMode:defaultMode,
          searchText:searchText != null ? searchText : null },
      },
    ])
      .then((buildingOwnerData) => {
        this._asyncRequest = null;
        if (buildingOwnerData.buildingOwnerData.success == true) {
          this.setState({
            buildingOwnerData:
              camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || [],
            responseCompanyData: _.cloneDeep(
              camelcaseKeys(buildingOwnerData.buildingOwnerData.data) || []
            ),
            configuration:
              camelcaseKeys(
                buildingOwnerData.buildingOwnerData.configuration
              ) || [],
            detailView: false,
            selectedEditRowId: undefined,
            companyMasterDetails:clearData == 0 ? this.state.companyMasterDetails:[],
            companyRoleId:companyRoleIds,
           
          });
          this.forceUpdate();
        } else {
          ShowToast(
            buildingOwnerData.buildingOwnerData.message,
            toastType.ERROR,
            5000,
            500,
            "Server error"
          );
        }
      })
      .catch(() => {
        this._asyncRequest = null;
      });
  };

  exportPDF = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.Company,
      "CompaniesExport.pdf",
      this.state.selectedData == undefined ? this.state.defaultSelectedData : this.state.selectedData,
      this.state.selectedMarketId,
      "application/pdf",
      "company"
    );
  };
  handleSearchChange = (e, value, name) => {
    this.setState({ [name]: value.companyName,isStateChanged:!this.state.isStateChanged });
    let searchTest=value != null && value != undefined ? value.companyName :null
    this.handleChange(e,searchTest,1);
  };
  handleChange = (event,value,bit) => {
      this.setState({ defaultSelectedData:[],companyRoleId:null,  filterSelectedData: null},()=>{this.forceUpdate()});

     bit == 1 ? this.onCompanyLoad(null,0,value,0):null;
    //});
    if(value == '' || value == null || value == undefined)
    this.onCompanyLoad(null,0,value,0);
  };

  handlePrintClick = () => {
    this.setState(
      {
        printPage: true,
      },
      window.print()
    );
  };

  downloadReport = () => {
    downloadExcelReportByAPI(
      API_URL.SSRSExport.Company,
      "CompaniesExport.xls",
      this.state.selectedData == undefined ? this.state.defaultSelectedData : this.state.selectedData,
      this.state.selectedMarketId,
      "application/vnd.ms-excel",
      "company"
    );
  };

  resetFilters = () => {
    this.setState({
      filterSelectedData: null,
      //getSubMarketMaster:[],
    });
  };
  handleClickOpen = () => {
    this.setState({
      openSlidingPanel: true,
    });
  };
  handleCheck(val) {
    return this.state.listOfColumns.some((item) => item.key === val);
  }
  handleClose = () => {
    this.setState({
      openSlidingPanel: false,
    });
  };
  render() {
    const {
      loading,
      buildingOwnerData,
      searchInput,
      getSubMarketMaster,
      companyMasterDetails,
      ownerTypeData,
      getIndustrySector,
      getProductTypeMaster,
      getMarketMaster,
      openFilter,
      lstActiveFilters,
      userConfiguration,
      openSlidingPanel,
      settingConfig,
      listOfColumns,
    } = this.state;
    let pageSize = 0;
    let userConfig = userConfiguration.filter((m) => {
      if (m.sectionId == 15 && m.isVisible == true) {
        if (m.dataFormat == "MultiText") {
          m.minTag = 1;
          m.tagCount = true;
        }
        if (m.dataFormat == "Number") {
          m.headcellClass = "headcell-number-text";
        }
        if (m.key == "companyName") {
          m.renderComponent = "renderActionColumn";
          // this.setState({
          pageSize = m.rowPerPage;
          //reloadTable:!this.state.reloadTable,
          //});
          return m;
        }
        return m;
      }
      //this.refs.gridTable.setRowsPerPage(pageSize);
    });
    userConfig.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );
    const dbColumnList = userConfiguration.filter((m) => {
      if (m.sectionId == 15 && m.attributeSortOrder != -1 && !m.renderComponent)
        return m; //
    });
    dbColumnList.sort((a, b) =>
      a.attributeSortOrder > b.attributeSortOrder ? 1 : -1
    );

    buildingOwnerData.forEach((item, index) => {
      item.serial = index + 1;
    });

    return (
      <>
        {this.state.detailView ? (
          <>
            <EditIndBuildingOwners
              globalMarketId={this.props.globalMarketId}
              selectedCompanyId={this.state.selectedEditRowId}
              onBackClick={this.onBackClick}
              getProductTypeMaster={getProductTypeMaster}
              ViewTitle={this.state.ViewTitle}
              actionType={this.state.actionType}
              isInAddMode={this.state.isInAddMode}
              reloadData={this.reloadData}
              getMarketMaster={this.state.getMarketMaster}
              getSubMarketMaster={this.state.getSubMarketMaster}
              //getProductTypeMaster={this.state.getProductTypeMaster}
              ownerTypeData={this.state.ownerTypeData}
            />
          </>
        ) : (
          <>
            <div className="row no-print">
              <div className="col-md-4 col-lg-4">
                <PageHeader title="Companies"></PageHeader>
                {/* <Button
                  aria-label={"addnewrow"}
                  className="action-button"
                  size="small"
                  onClick={() => {
                    this.setState({
                      isInAddMode: true,
                    });
                    this.onAddNewClick();
                  }}
                >
                  <span className="tool-icon-text">Add Company</span>
                </Button> */}
              </div>
              <div>
                {dbColumnList.length > 0 && (
                  <SettingScreenDialog
                    filterSetting={JSON.parse(
                      JSON.stringify([...dbColumnList])
                    )}
                    onRestoreToDefault={this.onRestoreToDefault}
                    onApplySetting={this.onSettingChange}
                    openSlidingPanel={openSlidingPanel}
                    handleClose={this.handleClose}
                  />
                )}
              </div>
              <div className="col-md-5 col-lg-5">
                <SearchBar
              //  isStateChanged={this.state.isStateChanged}
                reloadData={this.resetAppliedFilter}
                loading={loading}
                handleChange={this.handleSearchChange}
                onInputChange={this.onInputChange}
                searchInput={searchInput}
                companyMasterDetails={this.state.companyMasterDetails}
                isOwner={true}                  
                  size="large"
                  name="searchInput"
                  value={searchInput || ""}
                  onChange={this.handleChange}
                  label="Search"
                  placeholder="Click on icon to Search Company."
                ></SearchBar>
              </div>

              <div className="col-md-3 col-lg-3">
                <ReportButtons
                  resetPage={this.resetPage}
                  openFilter={this.state.openFilter}
                  enableFilter={this.enableFilter}
                  handleSubmit={[]}
                  handleClickOpen={this.handleClickOpen}
                  handlePrintClick={this.handlePrintClick}
                  exportPDF={this.exportPDF}
                  data={buildingOwnerData}
                  columnWidths={columnWidths}
                  uploadColumns={uploadColumns}
                  fileName={"BuildingOwner.xlsx"}
                  downloadExcelReport={this.downloadReport}
                  enableFilterButton={this.state.enableFilterButton}
                  ParentSection={SECTIONS.Industrial_Company}
                  ChildSectionForDownload={SECTIONS.Industrial_Company_Download}
                  ChildSectionForPrint={SECTIONS.Industrial_Company_Print}
                  ChildSectionForSettings={SECTIONS.Industrial_Company_Settings}
                />
              </div>
            </div>

            {buildingOwnerData && listOfColumns && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <PageFilter
                    resetFilters={this.resetFilters}
                    onInputChange={this.onInputChange}
                    autoFilter={true}
                    openAppliedFilterPane={true}
                    filterSelectedData={this.state.filterSelectedData}
                    filterApplied={true}
                    filterAlwaysVisible={openFilter}
                    openFilter={true}
                    onFilterChanged={this.onFilterChange}
                    filterConfig={[
                      // {
                      //   multiple: false,
                      //   type: controlType.Autofill,
                      //   minTag: 1,
                      //   source: companyMasterDetails,
                      //   key: "companyName",
                      //   placeholder: "",
                      //   title: "Company Name",
                      //   isVisible: this.handleCheck("companyName"),
                      // },
                      {
                        multiple: true,
                        type: controlType.DropDown,
                        source: ownerTypeData,
                        key: "companyRole",
                        placeholder: "",
                        title: "Company Type",
                        isVisible: this.handleCheck("companyRole"),
                      },
                    ]}
                    defaultSelectedData={[]}
                    selectedData={this.state.defaultSelectedData}
                    gridColumn={4}
                  ></PageFilter>
                  {/* <Button className="btn-clear" onClick={this.resetPage}>
                          Clear All
                        </Button> */}
                </div>
              </div>
            )}

            {buildingOwnerData && userConfig.length > 0 && (
              <div className="row" style={{ marginTop: "0px" }}>
                <div className="col-sm-12 col-lg-12">
                  <GridTable
                    validationMessage={[]}
                    rowCount={
                      this.state.rowsPerPage > 0 ? this.state.rowsPerPage : 20
                    }
                    config={userConfig}
                    columns={UpdateColumnWithConfiguration(
                      userConfig,
                      this.state.configuration,
                      []
                    )}
                    selectedEditRowId={this.state.selectedEditRowId}
                    data={buildingOwnerData}
                    renderActionColumn={this.renderActionColumn}
                    reload={this.state.reloadTable}
                    AddNewRecord={{
                      action: this.onAddNewClick,
                      title: "Add Company",
                      visible: IsSectionVisible(
                        SECTIONS.Industrial_Company,
                        SECTIONS.Industrial_Company_Listing,
                        SECTIONS.ADD_EDIT
                      ),
                    }}
                  ></GridTable>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

import React from 'react'
// import { downloadFile } from '../../../utils/download-service'
import { downloadFile } from '../../../utils/download-service'
// import PropTypes from 'prop-types'
// import { prototype } from 'html-webpack-plugin'

export default function DownloadFile (props) {
  const { labelText } = props
  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={() => downloadFile(props.url, props.filename)}
    >
      {labelText || 'Download'}
    </span>
  )
}

// DownloadFile.propTypes = {
//   url: PropTypes.string.isRequired,
//   filename: PropTypes.string.isRequired,
//   labelText: prototype.string
// }

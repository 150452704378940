import React from "react";
import "./styles.less";
import "./index.less";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";
import { DarkTooltip } from "../material-tooltip";
export default function ButtonMenu({
	buttonLabel = "Default",
	menuList = [],
	maxChar = 16,
	onSelect,
	styleClass = "menuButton",
	dataTip = "",
	menuIconButton = false
}) {
	return (
		<div className={styleClass}>
			<Menu>
				<DarkTooltip title={dataTip} arrow>
					<MenuButton>
						{buttonLabel} {menuIconButton ? "" : <span aria-hidden>▾</span>}
					</MenuButton>
				</DarkTooltip>
				<MenuList>
					{menuList.length &&
						menuList.map(({ id, label }, i) => {
							return (
								<MenuItem key={`menulist${i}`} onSelect={() => onSelect(id)}>
									{label && label.length > maxChar
										? `${label.substring(0, maxChar)}...`
										: label}
								</MenuItem>
							);
						})}
				</MenuList>
			</Menu>
		</div>
	);
}

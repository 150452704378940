import { deviceDetection } from "../constants/constants";
const { isMobile } = deviceDetection;

const RESIZE_FACTOR = isMobile ? 1 : window.innerWidth / 1200 || 1;

export const PIE_MIN_RADIUS = 90;
export const PIE_MAX_RADIUS = 120;
export const PIE_MAX_WIDTH = 430;
export const PIE_HEIGHT = 280;
export const PIE_COLOR = [
	{
		colorLabel: "green",
		arcColor: "#81bd41",
		lineColor: "#81bd41",
		textColor: "#81bd41"
	},
	{
		colorLabel: "orange",
		arcColor: "#e59400",
		lineColor: "#e59400",
		textColor: "#e59400"
	},
	{
		colorLabel: "blue",
		arcColor: "#1a8fbf",
		lineColor: "#1a8fbf",
		textColor: "#1a8fbf"
	},
	{
		colorLabel: "magenta",
		arcColor: "#64518A",
		lineColor: "#64518A",
		textColor: "#64518A"
	},
	{
		colorLabel: "havelock blue",
		arcColor: "#1E74B1",
		lineColor: "#1E74B1",
		textColor: "#1E74B1"
	}
];
export const DONUT_COLOR = PIE_COLOR;

export const BARCHART_HEIGHT = 240;
export const BARCHART_MAX_WIDTH = 560;
export const BAR_COLOR = [
	{
		colorLabel: "green",
		barColor: "#81bd41",
		textColor: "#81bd41"
	},
	{
		colorLabel: "orange",
		barColor: "#e59400",
		textColor: "#e59400"
	},
	{
		colorLabel: "blue",
		barColor: "#1a8fbf",
		textColor: "#1a8fbf"
	},
	{
		colorLabel: "magenta",
		barColor: "#64518A",
		textColor: "#64518A"
	},
	{
		colorLabel: "havelock blue",
		barColor: "#1E74B1",
		textColor: "#1E74B1"
	}
];

export const LINECHART_HEIGHT = 260 * RESIZE_FACTOR;
export const LINECHART_WIDTH = 500 * RESIZE_FACTOR;

export const GROUPCHART_HEIGHT = 260 * RESIZE_FACTOR;
export const GROUPCHART_WIDTH = 500 * RESIZE_FACTOR;

import React from "react";
import { cardLandingView } from "../../../constants/constants";
import SummaryCard from "../summary-card";
import ReactTableList from "../card-list-view";
import TableSkeleton from "../table-skeleton";

export default React.memo(function LandingCardView({
	filteredData = [],
	jumpToPage,
	cardView,
	columnList,
	createCardObj,
	idKey,
	sortByKey,
	sortAsc = true,
	onSortedChange,
	searchText = "",
	showFooter,
	showHeader,
	groupingKey,
	onExpandToggle,
	isExpandAll,
	isRowClickable,
	showTitle = false,
	tableTitle = "",
	rowEditAction,
	noDataMsg = "No data available."
}) {
	return (
		<div className="row content start-xs start-sm start-md start-lg">
			{filteredData && filteredData.length ? (
				cardView === cardLandingView ? (
					filteredData.map((obj, i) => (
						<div
							key={"propertyCard" + i}
							className="col-xs-12 col-sm-6 col-md-4 col-lg-3"
						>
							<SummaryCard cardData={createCardObj(obj)} onClick={jumpToPage} />
						</div>
					))
				) : (
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
						<ReactTableList
							cardData={filteredData}
							onRowClick={jumpToPage}
							columnList={columnList}
							idKey={idKey}
							sortedObj={{
								id: sortByKey,
								desc: !sortAsc
							}}
							onSortedChange={onSortedChange}
							groupingKey={groupingKey}
							isExpandAll={isExpandAll}
							onExpandToggle={onExpandToggle}
							showFooter={showFooter}
							showHeader={showHeader}
							isRowClickable={isRowClickable}
							showTitle={showTitle}
							tableTitle={tableTitle}
							rowEditAction={rowEditAction}
						/>
					</div>
				)
			) : filteredData ? (
				searchText ? (
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
						<div className="no-data-found-block">No data found.</div>
					</div>
				) : (
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
						<div className="no-data-found-block">{noDataMsg}</div>
					</div>
				)
			) : (
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ">
					<TableSkeleton />
				</div>
			)}
		</div>
	);
});
